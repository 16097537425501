import React, { useState } from 'react';
import {
  Button, Form, Input, Select, Spin, Image,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import styles from './clubs-create-screen.module.scss';
import { User } from '../../models/User';
import { fallBackUrl } from '../../utils/imageData';
import { createClub } from '../../services-api/clubService';

const { Option } = Select;
function ClubsCreateScreen() {
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState('');
  const [timeStamp] = useState(new Date());
  const [limit] = useState(24);
  const [value, setValue] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (value: any) => {
    setValue(value);
  };

  const onBackClick = () => {
    history.goBack();
  };

  const {
    data,
    isFetching,
  } = useInfiniteQuery(
    `new-club-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/users/friends?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onSearch = (data: any) => {
    setSearchQuery(data);
  };

  const onDeleteClick = (currVal: string) => {
    setValue(value.filter((x: any) => x.value !== currVal));
  };

  const onFinish = (values: any) => {
    dispatch(createClub(values, () => {
      history.goBack();
    }));
  };

  const onFinishFailed = (error: any) => {
  };

  return (
    <div className={styles.ClubsCreateScreen}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Button
            className={styles.goBack}
            size="large"
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={onBackClick}
          />
          <div className={styles.name}>
            Create Story Club
          </div>
        </div>
        <div className={styles.form}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Club Name"
              name="name"
              required
            >
              <Input
                size="large"
                placeholder="Enter story club name"
              />
            </Form.Item>
            <Form.Item
              label="Privacy"
              required
              name="privacy"
            >
              <Select size="large" defaultValue="public">
                <Select.Option value="public">Public</Select.Option>
                <Select.Option value="private">Private</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Invite friends"
              name="friends"
            >
              <Select
                mode="multiple"
                size="large"
                labelInValue
                value={value}
                placeholder="Select users"
                notFoundContent={isFetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={onSearch}
                tagRender={(item) => {
                  const user: User = JSON.parse(item.value as string);
                  return (
                    <div className={styles.friendLabel}>
                      <Image
                        className={styles.image}
                        src={user.image}
                        fallback={fallBackUrl}
                      />
                      <div className={styles.name}>{user.username}</div>
                      <CloseOutlined className={styles.closeIcon} onClick={() => onDeleteClick(item.value as string)} />
                    </div>
                  );
                }}
                onChange={handleChange}
                style={{ width: '100%' }}
              >
                {data?.map((page) => page.users).flat().map((user: User, index) => (
                  <Option key={user?.image} value={JSON.stringify({ image: user.image, username: user.username, _id: user._id })}>
                    <div className={styles.friendLabelOption}>
                      <Image
                        className={styles.image}
                        src={user?.image}
                        alt={user?.image}
                        fallback={fallBackUrl}
                      />
                      <div className={styles.name}>{user.username}</div>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ClubsCreateScreen;
