import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import styles from './club-about-screen.module.scss';

type AboutScreenProps = {

};

function ClubAboutScreen(props: AboutScreenProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const {
    isLoading, error, data, isFetching,
  } = useQuery(`club-screen-${id}`, () => axios.get(`/api/clubs/${id}`)
    .then((response) => response.data));

  const onBackAction = () => {
    history.goBack();
  };

  return (
    <div className={styles.ClubAboutScreen}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.backButton}>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={onBackAction}
            />
          </div>
          <div className={styles.title}>
            About
          </div>
        </div>
        <div className={styles.body}>
          {data?.club?.about || (<span className={styles.emptyText}>N/A</span>)}
        </div>
      </div>
    </div>
  );
}

export default ClubAboutScreen;
