import React from 'react';
import styles from './quiz-questions.module.scss';
import {Button, Collapse, Form, Input, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {CreateQuiz} from "../../../models/create-models/CreateQuiz";
import {Answer, Question} from "../../../models/create-models/Question";
import {ActionTypes} from "../../../actions/types";

const { Panel } = Collapse;
const { Option } = Select;
function QuizQuestions() {
    const createQuiz = useSelector<StoreState, CreateQuiz>((state) => state.createQuiz);
    const quizCounter = useSelector<StoreState, number>((state) => state.createQuizCounter);
    const quizAnswersCounter = useSelector<StoreState, { [key: string]: number }>((state) => state.createQuizAnswersCounter);
    const dispatch = useDispatch();

    const onAddQuestionClick = () => {
        const newQuestion: Question = {
            id: `${quizCounter}`,
            question: `Question ${quizCounter}`,
            answers: [],
        };

        const updatedQuiz = { ...createQuiz, questions: [...createQuiz?.questions, newQuestion] };
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ_COUNTER, value: quizCounter + 1 });
    };

    const onAddOptionClick = (question: Question) => {
        const updatedQuiz = {
            ...createQuiz,
            questions: [...createQuiz?.questions.map((quest, index) => {
                if (quest.id === question.id) {
                    const newAnswer: Answer = {
                        id: `${quizAnswersCounter[question.id] ? quizAnswersCounter[question.id] + 1 : 1}`,
                        text: `Option ${quizAnswersCounter[question.id] ? quizAnswersCounter[question.id] + 1 : 1}`,
                    };
                    return { ...quest, answers: [...quest.answers, newAnswer] };
                }
                return quest;
            })],
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ_ANSWERS_COUNTER, value: { ...quizAnswersCounter, [`${question.id}`]: quizAnswersCounter[question.id] ? quizAnswersCounter[question.id] + 1 : 1 } });
    };

    const onOptionChange = (e: any, question: Question, answer: Answer) => {
        const updatedQuiz = {
            ...createQuiz,
            questions: [...createQuiz?.questions.map((quest, questIndex) => {
                if (quest.id === question.id) {
                    return {
                        ...quest,
                        answers: [...quest.answers.map((ans, ansIndex) => {
                            if (ans.id === answer.id) {
                                return { ...ans, text: e.target.value };
                            }
                            return ans;
                        })],
                    };
                }
                return quest;
            })],
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
    };

    const onQuestionChange = (e: any, question: Question) => {
        console.log('data: ', e.target.value);
        console.log('data - question: ', question);
        const updatedQuiz = {
            ...createQuiz,
            questions: createQuiz.questions.map((val) => {
                if (val.id === question.id) {
                    return { ...val, question: e.target.value };
                }
                return val;
            }),
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
    };

    const onAnswerChange = (data: any, question: Question) => {
        const updatedQuiz = {
            ...createQuiz,
            questions: createQuiz.questions.map((val) => {
                if (val.id === question.id) {
                    return { ...val, correctAnswerId: `${data}`, correctAnswer: question.answers.find((x) => x.id === `${data}`) };
                }
                return val;
            }),
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
    };

    const onQuestionTitleChange = (data: any) => {
        const updatedQuiz = {
            ...createQuiz,
            title: data.target.value,
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
    };

    return (
        <div className={styles.QuizQuestions}>
            <Collapse collapsible="header" defaultActiveKey={['1']}>
                {
                    createQuiz?.questions.map((val, index) => (
                        <Panel header={val.question} key={index}>
                            <div>
                                <Form.Item
                                    label="Question"
                                    name={`question${index + 1}`}
                                    initialValue={val.question}
                                    rules={[{ required: true, message: 'Please input a quiz title!' }]}
                                >
                                    <Input
                                        size="large"
                                        // defaultValue={val.question}
                                        onChange={(e) => onQuestionChange(e, val)}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={styles.answerItem}
                                    label="Answer"
                                    name={`answer${val.id}`}
                                    rules={[{ required: true, message: 'Please select an answer!' }]}
                                >
                                    <Select
                                        disabled={val.answers.length === 0}
                                        onChange={(e) => onAnswerChange(e, val)}
                                        size="large"
                                    >
                                        {
                                            val.answers.map((tq, opIndex) => (
                                                <Option
                                                    key={`question-${val.id}-${opIndex}`}
                                                    value={tq.id}
                                                >
                                                    {tq.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                {
                                    val.answers.map((answer, ansIndex) => (
                                        <Form.Item
                                            key={`question${index}-answer${ansIndex}`}
                                            label={`Option ${ansIndex + 1}`}
                                            name={`question${index}-answer${ansIndex}`}
                                            initialValue={answer.text}
                                            rules={[{ required: true, message: 'Please input a quiz title!' }]}
                                        >
                                            <Input
                                                size="large"
                                                value={answer.text}
                                                onChange={(e) => onOptionChange(e, val, answer)}
                                                allowClear
                                            />
                                        </Form.Item>
                                    ))
                                }
                                {
                                    val.answers.length < 4
                                    && (
                                        <Button
                                            className={styles.addOption}
                                            type="dashed"
                                            size="large"
                                            onClick={() => onAddOptionClick(val)}
                                        >
                                            Add Option
                                        </Button>
                                    )
                                }
                            </div>
                        </Panel>
                    ))
                }
            </Collapse>
            <Button
                className={styles.addQuestion}
                type="dashed"
                size="large"
                onClick={onAddQuestionClick}
            >
                Add Question
            </Button>
        </div>
    );
}

export default QuizQuestions;