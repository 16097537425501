import { ActionTypes } from '../actions/types';
import { User } from '../models/User';
import { ChatRoom } from '../models/ChatRoom';

interface setCurrentChatUser {
  type: typeof ActionTypes.SET_CURRENT_CHAT_USER;
  value: User;
}

interface setCurrentChatRoom {
  type: typeof ActionTypes.SET_CURRENT_CHAT_ROOM;
  value: ChatRoom;
}

interface setSelectedUsers {
  type: typeof ActionTypes.SET_SELECTED_USERS;
  value: User[];
}

type chatsActions = setCurrentChatUser | setSelectedUsers | setCurrentChatRoom;

export const currentChatUser = (state: User | null = null, action: chatsActions) => {
  if (action.type === ActionTypes.SET_CURRENT_CHAT_USER) {
    return action.value;
  }
  return state;
};

export const currentChatRoom = (state: ChatRoom | null = null, action: chatsActions) => {
  if (action.type === ActionTypes.SET_CURRENT_CHAT_ROOM) {
    return action.value;
  }
  return state;
};

export const selectedUsers = (state: User[] = [], action: chatsActions) => {
  if (action.type === ActionTypes.SET_SELECTED_USERS) {
    return action.value;
  }
  return state;
};
