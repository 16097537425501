import React, { useState } from 'react';
import {
  Button, Drawer, Progress, Slider, Input, Tag, Modal, Typography, Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './Rating10.module.scss';
import { rateMedium } from '../../services-api/mediumService';
import { Medium } from '../../models/Medium';
import { convertRatingToColor, getNumberOfRatings, getRatingsAverage } from '../../helpers/helpers';
import { Post } from '../../models/Post';
import { StoreState } from '../../reducers/_RootReducers';
import { ActionTypes } from '../../actions/types';
import reviewTagsData from '../../helpers/data/reviewTagsData.json';

interface Rating10Props {
  medium: Medium;
  isOnShelf: boolean;
}

const { TextArea } = Input;
const { CheckableTag } = Tag;
const { Paragraph } = Typography;
function Rating10(props: Rating10Props) {
  const { medium, isOnShelf } = props;
  // @ts-ignore
  const tagsData = reviewTagsData[`${medium.typeTitle}`];
  const [hasSpoilers, setHasSpoilers] = useState(false);
  const [isMoreFeedbackModalOpen, setIsMoreFeedbackModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [overallRatingCount, setOverallRatingCount] = useState(getNumberOfRatings(medium));
  const [overallRatingAverage, setOverallRatingAverage] = useState(getRatingsAverage(medium));
  const [yourRating, setYourRating] = useState<Post | undefined>(medium.currentUserPosts?.find((x) => x.typeValue === 3 && x.medium._id === medium._id));
  const [inputRatingValue, setInputRatingValue] = useState(yourRating ? yourRating.rating : 5);
  const [inputTitleValue, setInputTitleValue] = useState(yourRating ? yourRating.title : '');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const dispatch = useDispatch();

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const onSpoilerChange = (checked: boolean) => {
    setHasSpoilers(checked);
  };
  // const stats = () => (
  //   <div>
  //     <div>
  //       #1 - Ranked in comedy
  //     </div>
  //     <div>
  //       2M - people put it in their top 10
  //     </div>
  //     <div>
  //       #1 - show time
  //     </div>
  //     <div>
  //       #1 - show time
  //     </div>
  //   </div>
  // );
  //
  // const awards = () => (
  //   <div>
  //     <div>
  //       Best in this category
  //     </div>
  //     <div>
  //       Great story
  //     </div>
  //     <div>
  //       Action packed
  //     </div>
  //     <div>
  //       Surprise and suspense
  //     </div>
  //   </div>
  // );

  const convertRatingToTitle = (rating: number): any => {
    switch (rating) {
      case 10:
        return { title: 'diamond', image: 'https://i.ibb.co/pz9DMjt/diamond-10.png', description: 'Beyond Excellent' };
      case 9:
        return { title: 'platinum', image: 'https://i.ibb.co/PNZf0Rr/platinum-9.png', description: 'Excellent' };
      case 8:
        return { title: 'gold', image: 'https://i.ibb.co/6sYnC2n/gold-8-2.png', description: 'Great' };
      case 7:
        return { title: 'silver', image: 'https://i.ibb.co/Xyzdt7K/silver-7.png', description: 'Very Good' };
      case 6:
        return { title: 'bronze', image: 'https://i.ibb.co/VmDP3G2/bronze-6.png', description: 'Good' };
      case 5:
        return { title: 'wood', image: 'https://i.ibb.co/WtMVQg0/wood-5.png', description: 'Just Okay' };
      case 4:
        return { title: 'water', image: 'https://i.ibb.co/5Gxc0Yd/water-4.png', description: 'Below average' };
      case 3:
        return { title: 'plastic', image: 'https://i.ibb.co/ZGFXwf1/plastic-3-2.png', description: 'Pretty bad' };
      case 2: //
        return { title: 'trash', image: 'https://i.ibb.co/9sj7DtF/garbage-image-10.png', description: 'Terrible' };
      case 1:
        return { title: 'coronavirus', image: 'https://i.ibb.co/WcdzZDB/disease-1-2.png', description: 'At your own risk' };
      default:
        return '#1750c6';
    }
  };

  const rateThisMedium = () => (
    <div>
      <div className={styles.progressRatingContainer}>
        <div className={styles.progressTitle}>
          Your rating
        </div>
        <Progress
          type="circle"
          width={70}
          strokeColor={{
            '0%': convertRatingToColor(yourRating?.rating),
            '100%': convertRatingToColor(yourRating?.rating),
          }}
          percent={(((yourRating ? yourRating.rating : 0) / 10) * 100)}
          format={() => `${yourRating ? yourRating.rating : '-'}/10`}
        />
        {
                    yourRating
                      ? <div className={styles.ratingsCount}>{convertRatingToTitle(yourRating.rating).description}</div>
                      : (
                        <Button type="primary" className={styles.rateButton} onClick={onRateNowClick}>
                          Rate now
                        </Button>
                      )
                }
      </div>
    </div>
  );

  const onRateNowClick = () => {
    setIsDrawerOpen(true);
  };

  // const yourRatingView = () => (
  //   <div>
  //     <div className={styles.progressRatingContainer}>
  //       <div className={styles.progressTitle}>
  //         Your rating
  //       </div>
  //       <Progress
  //         type="circle"
  //         width={70}
  //         strokeColor={{
  //           '0%': convertRatingToColor(rating),
  //           '100%': convertRatingToColor(rating),
  //         }}
  //         percent={((rating / 10) * 100)}
  //         format={(percent) => `${rating}/10`}
  //       />
  //       <div className={styles.ratingsTitle}>
  //         {convertRatingToTitle(rating).description}
  //       </div>
  //     </div>
  //   </div>
  // );

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const onSliderChange = (data: any) => {
    setInputRatingValue(data);
  };

  const marks = {
    1: {
      style: {
        color: '#827f88',
      },
      label: <span>1</span>,
    },
    10: {
      style: {
        color: '#827f88',
      },
      label: <strong>10</strong>,
    },
  };

  const onSaveClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(rateMedium(inputRatingValue, inputTitleValue, medium._id, selectedTags, hasSpoilers, () => {
      const updatedRating: any = { ...yourRating, rating: inputRatingValue };
      setYourRating(updatedRating);
      setOverallRatingAverage((overallRatingAverage + inputRatingValue) / (overallRatingCount + 1));
      setOverallRatingCount(overallRatingCount + 1);
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      setIsDrawerOpen(false);
    }));
  };

  const onTextChange = (data: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputTitleValue(data.target.value);
  };

  const onMoreFeedBackClick = () => {
    setIsMoreFeedbackModalOpen(true);
  };

  const handleOk = () => {

  };

  const handleCancel = () => {
    setIsMoreFeedbackModalOpen(false);
  };

  return (
    <div className={styles.Rating10}>
      <Modal
        visible={isMoreFeedbackModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className={styles.TextModal}
        bodyStyle={{ padding: '24px', paddingTop: '36px', height: '240px' }}
        closable={false}
        centered
        title={null}
        footer={null}
      >
        <div className={styles.textModalBody}>
          <div className={styles.textModalContainer}>
            <TextArea
              className={styles.textArea}
              defaultValue={inputTitleValue}
              placeholder="Leave more feedback"
              onChange={onTextChange}
              autoSize={{ minRows: 2, maxRows: 5 }}
              allowClear
            />
          </div>
          <div className={styles.actions}>
            <Button className={styles.saveButton} type="primary" onClick={handleCancel}>Save</Button>
          </div>
        </div>
      </Modal>
      <Drawer
        title="Rating"
        placement="bottom"
        closable
        onClose={onClose}
        visible={isDrawerOpen}
        height="580px"
      >
        <div className={styles.ratingDisplay}>
          <Progress
            type="circle"
            width={100}
            strokeColor={{
              '0%': convertRatingToColor(inputRatingValue),
              '100%': convertRatingToColor(inputRatingValue),
            }}
            percent={((inputRatingValue / 10) * 100)}
            format={() => (
              <span className={styles.ratingDisplayValue}>
                {inputRatingValue}
                <span className={styles.outOfDisplay}>/10</span>
              </span>
            )}
          />
        </div>
        <div className={styles.ratingSliderContainer}>
          <Slider
            className={styles.ratingSlider}
            min={1}
            max={10}
            marks={marks}
            defaultValue={inputRatingValue}
            onChange={onSliderChange}
            value={inputRatingValue}
          />
        </div>
        <div className={styles.tagsContainer}>
          {/* <div>Select tags that apply:</div> */}
          {tagsData?.map((tag: string) => (
            <CheckableTag
              className={styles.checkTag}
              key={tag}
              checked={selectedTags.indexOf(tag) > -1}
              onChange={(checked) => handleChange(tag, checked)}
            >
              {tag}
            </CheckableTag>
          ))}
        </div>
        <div className={styles.moreFeedbackContainer}>
          {
            inputTitleValue
              ? (
                <div className={styles.feedBackContainer}>
                  <div className={styles.feedbackTextContainer}>
                    <Paragraph className={styles.feedbackText} ellipsis={{ rows: 1, expandable: false, symbol: 'more' }}>
                      {inputTitleValue}
                    </Paragraph>
                    <Button type="link" className={styles.editButton} onClick={onMoreFeedBackClick}>
                      Edit
                    </Button>
                  </div>
                  <div className={styles.spoilerContainer}>
                    <div>
                      Spoiler alert?
                    </div>
                    <Switch
                      className={styles.spoilerSwitch}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      defaultChecked={hasSpoilers}
                      onChange={onSpoilerChange}
                    />
                  </div>
                </div>
              )
              : (
                <Button type="link" onClick={onMoreFeedBackClick}>
                  Leave more feedback
                </Button>
              )
          }
        </div>
        <div className={styles.actions}>
          <Button
            type="primary"
            size="large"
            className={styles.saveButton}
            loading={isLoading}
            onClick={onSaveClick}
          >
            SUBMIT
          </Button>
        </div>
      </Drawer>
      <div className={styles.leftContainer}>
        <div className={styles.progressRatingContainer}>
          <div className={styles.progressTitle}>
            Overall
          </div>
          <Progress
            type="circle"
            width={70}
            strokeColor={{
              '0%': convertRatingToColor(Math.floor(overallRatingAverage)),
              '100%': convertRatingToColor(Math.floor(overallRatingAverage)),
            }}
            percent={((overallRatingAverage / 10) * 100)}
            format={() => `${overallRatingAverage || '-'}/10`}
          />
          <div className={styles.ratingsCount}>
            {overallRatingCount || '0'}
            {' '}
            {overallRatingCount > 1 ? 'ratings' : 'rating'}
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        {isOnShelf && rateThisMedium()}
      </div>
    </div>
  );
}

export default Rating10;
