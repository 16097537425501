import React, {useEffect} from 'react';
import styles from './create-list.module.scss';
import {Button, Form, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers/_RootReducers";
import {useHistory} from "react-router-dom";
import {Medium} from "../../models/Medium";
import {ActionTypes} from "../../actions/types";
import CreateProgress from "../../components/CreateProgress/CreateProgress";
import ListInfo from "./list-info/list-info";
import {CreateList as CreateListModel} from "../../models/create-models/create-list";
import PreviewList from "./preview-list/preview-list";
import CustomizeQuote from "../create-quote-screen/CustomizeQuote/CustomizeQuote";
import ListStories from "./list-stories/list-stories";
import * as htmlToImage from "html-to-image";
import {createNewQuizPost} from "../../services-api/quizService";
import {createPostList} from "../../services-api/createPostService";
import {useQueryCache} from "react-query";


/*
*
* currentPage: 1
stories: []
template: {id: "0", title: "Classic", uniqueName: "classic", layers: Array(2), fonts: Array(2), …}
text: ""
totalPages: 3
type: "list"
values: []
visibility: "public"
*
* */

const { Paragraph, Text } = Typography;
function CreateList() {
    const [form] = Form.useForm();
    const createList = useSelector<StoreState, CreateListModel>((state) => state.createList);
    const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
    const history = useHistory();
    const dispatch = useDispatch();
    const queryCache = useQueryCache();

    useEffect(() => {
        console.log("createList: ",createList);
    }, [])

    const pages = [
        {
            page: 1,
            showNavigation: false,
            title: 'list info',
            content: <ListInfo />,
            // content: <PreviewList />
        },
        {
            page: 2,
            showNavigation: false,
            title: 'thumbnail',
            content: <CustomizeQuote createList={createList} actionType={ActionTypes.SET_CREATE_LIST} />
        },
        {
            page: 3,
            showNavigation: false,
            title: 'list',
            content: <ListStories />,
        },
        {
            page: 4,
            showNavigation: true,
            title: 'preview list',
            content: <PreviewList />,
        },
    ];

    const onPreviousClick = () => {
        if (createList.currentPage > 1) {
            const newCreateList = {
                ...createList, currentPage: createList.currentPage - 1, totalPages: pages.length, type: 'list',
            };
            dispatch({ type: ActionTypes.SET_CREATE_LIST, value: newCreateList });
        } else {
            history.replace('/create')
        }
    };

    const saveAndGoToNext = () => {
        const item = document.getElementById('my-node');

        htmlToImage.toPng(item!, {pixelRatio: 2})
            .then((dataUrl) => {
                console.log("dataUrl: ", dataUrl);
                fetch(dataUrl)
                    .then(resD => {
                        console.log("dataUrl - fet: ", resD);
                        resD.blob().then((blob) => {
                            console.log("dataUrl - blob: ", blob);
                            const newCreateList = {
                                ...createList,
                                dataUrl: blob,
                                currentPage: createList.currentPage + 1
                            };
                            dispatch({type: ActionTypes.SET_CREATE_LIST, value: newCreateList });
                        });
                    })
                    .then(console.log)
            })
    }

    const onFinish = async (values: any) => {

        if (createList.currentPage === 1) {
            const title = values.title;
            const visibility = values.visibility;
            const stories = values.stories.map((x: any) => JSON.parse(x.value) as Medium);
            const newCreateList = {
                ...createList,
                currentPage: createList.currentPage + 1,
                totalPages: pages.length,
                type: 'list',
                stories,
            };
            dispatch({ type: ActionTypes.SET_CREATE_LIST, value: newCreateList });
        } else if (createList.currentPage === 2) {
            saveAndGoToNext();
        } else if (createList.currentPage < pages.length) {
            const newCreateList = {
                ...createList,
                currentPage: createList.currentPage + 1,
            };
            dispatch({ type: ActionTypes.SET_CREATE_LIST, value: newCreateList });
        } else if (createList.currentPage === pages.length) {
            console.log("createList: ",createList);
            dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
            dispatch(createPostList(createList, () => {
                dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
                queryCache.invalidateQueries('home-screen').then(r => {
                    history.replace('/home');
                });
            }));
        }
    };

    const onFinishFailed = (error: any) => {

    };

    return (
        <div className={styles.CreateList}>
            {/*<div id="my-node" className={styles.thumbnail}>*/}
            {/*    <Paragraph className={styles.text} ellipsis={{ rows: 5, expandable: false, symbol: 'more' }}>*/}
            {/*        7 movies that will change your life*/}
            {/*        /!*hello the one is here for sure hello the one is here for sure hello the one is here for sure hello the one is here for sure hello the one is here for sure*!/*/}
            {/*    </Paragraph>*/}
            {/*</div>*/}
            <Form
                className={styles.container}
                form={form}
                action="create-list"
                name="create-list"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <CreateProgress existingId={createList.quizId} current={createList.currentPage} pages={pages} />
                <div className={styles.listContainer}>
                    {
                        pages[createList.currentPage - 1].content
                    }
                </div>
                <div className={styles.inputContainer}>
                    <Button
                        className={styles.previousButton}
                        onClick={onPreviousClick}
                    >
                        Previous
                    </Button>
                    <Form.Item style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                        <Button
                            className={styles.nextButton}
                            type="primary"
                            htmlType="submit"
                            formAction="create-list"
                            loading={isLoading}
                        >
                            {createList.currentPage === pages.length ? 'Post' : 'Next'}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}

export default CreateList;