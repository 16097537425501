import React, {useState} from 'react';
import styles from "../../screens/storynote-screen/storynote-screen.module.scss";
import {Button, Modal} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers/_RootReducers";
import {ActionTypes} from "../../actions/types";
import {StoryNoteTemplate} from "../../models/Template";
import {updateStoryNote} from "../../services-api/notesService";
import {useQueryCache} from "react-query";
import {currentTemplate} from "../../reducers/templateReducers";

type TemplatePreviewModalProps = {
    id: string
}

function TemplatePreviewModal(props: TemplatePreviewModalProps) {

    const { id } = props;
    const isTemplatePreviewModalOpen = useSelector<StoreState, boolean>((state) => state.isTemplatePreviewModalOpen);
    const currentTemplate = useSelector<StoreState, StoryNoteTemplate | null>((state) => state.currentTemplate);
    const dispatch = useDispatch();
    const queryCache = useQueryCache();

    const onClosePreviewTemplateModal = () => {
        dispatch({ type: ActionTypes.SET_TEMPLATE_PREVIEW_MODAL, value: false });
    };

    const onCloseTemplatesModal = () => {
        dispatch({ type: ActionTypes.SET_TEMPLATES_MODAL, value: false });
    };

    const onUseTemplateClick = () => {
        dispatch(updateStoryNote(id, { templateId: currentTemplate?._id }, () => {
            queryCache.invalidateQueries(`story-templates-note-${id}-screen`);
            onClosePreviewTemplateModal();
            onCloseTemplatesModal();
        }));
    };

    return (
        <Modal
            className={styles.TemplateModal}
            title={null}
            visible={isTemplatePreviewModalOpen}
            footer={null}
            closable={false}
            onCancel={onClosePreviewTemplateModal}
        >
            <div className={styles.content}>
                <div className={styles.previewHeader}>
                    <div className={styles.previewTitle}>
                        {currentTemplate?.title}
                    </div>
                    <Button
                        className={styles.closeButton}
                        type="link"
                        size="large"
                        icon={<CloseCircleOutlined />}
                        onClick={onClosePreviewTemplateModal}
                    />
                </div>
                <div className={styles.previewInfo}>
                    {currentTemplate?.language}
                    {' '}
                    •
                    {' '}
                    {currentTemplate?.year}
                    {' '}
                    •
                    {' '}
                    {currentTemplate?.version}
                </div>
                <div className={styles.previewBody}>
                    {
                        currentTemplate?.sections.map((section, index) => (
                            <div className={styles.chapter}>
                                <div className={styles.info}>
                                    <div className={styles.number}>
                                        {index + 1}
                                        .
                                    </div>
                                    <div>
                                        {section.text}
                                    </div>
                                    <div className={styles.location}>
                                        {
                                            currentTemplate?.isAudio
                                                ? (section.time.minute === -1 || section.time.second === -1) ? '' : `${section.time.minute}min ${section.time.second}s`
                                                : section.page === -1 ? '' : `page ${section.page}`
                                        }
                                    </div>
                                </div>
                                <div className={styles.subChapterSection}>
                                    {
                                        section.subChapters.map((subSection, subIndex) => (
                                            <div key={`subsection-${index}-${subIndex}`} className={styles.subChapter}>
                                                <div className={styles.subInfo}>
                                                    <div className={styles.subNumber}>
                                                        {index + 1}
                                                        .
                                                        {subIndex + 1}
                                                        .
                                                    </div>
                                                    <div className={styles.subChapterInput}>
                                                        {subSection.text}
                                                    </div>
                                                    <div className={styles.location}>
                                                        {
                                                            currentTemplate?.isAudio
                                                                ? (subSection.time.minute === -1 || subSection.time.second === -1) ? '' : `${subSection.time.minute}min ${subSection.time.second}s`
                                                                : subSection.page === -1 ? '' : `page ${subSection.page}`
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.previewFooter}>
                    <Button
                        className={styles.useButton}
                        type="primary"
                        size="large"
                        onClick={onUseTemplateClick}
                    >
                        Use template
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default TemplatePreviewModal;