import { combineReducers } from 'redux';
import {
  isPostImageModalVisible,
  authModal,
  lastLocationState,
  selectedMenuItem,
  isSettingsVisible,
  isColorPaletteModalVisible,
  isTextModalVisible,
  isLoading,
  isWiltTextModalVisible,
  isMobileMenuOpen,
  isFontModalMainVisible,
  fontModalDataMain,
  fontModalDataModel,
  isFontModalSourceVisible,
  fontModalDataSource,
  isInterestModalVisible,
  currentId,
  scrollPosition,
  scrollRef,
  isNewMessageModalOpen,
  isTemplatesModalOpen,
  isTemplatePreviewModalOpen,
  isReactionsOpen,
  isMyNotesOpen,
  isMyPreviewNoteOpen, lastCreatedScreen, isAddNotesOpen, resetTimestamp, isGradientPaletteModalVisible,
} from './displayReducers';
import { User } from '../models/User';
import {
  currentUser, followingUserIds, followRequestUserIds, myMediums, reactionIds, requestsCount, shelfIds, unreadChats,
} from './userReducers';
import { Medium } from '../models/Medium';
import { medium, mediums, podcastEpisode } from './mediumReducers';
import { AddPost } from '../models/AddPost';
import {
  addPost,
  createQuiz,
  createQuote,
  createSummary,
  createTemplate,
  createWilt,
  createQuizAnswersCounter,
  thought,
  createQuizCounter, createList,
} from './createReducers';
import {
  searchBooks, searchMovies, searchPodcasts, searchSeries, searchUsers, searchValue,
} from './searchReducers';
import { notifications } from './notificationScreenReducers';
import { selectedPost, topRankingViewingUser, viewingUser } from './profileScreenReducers';
import { Post } from '../models/Post';
import { CreateWilt } from '../models/CreateWilt';
import { CreateSummary } from '../models/CreateSummary';
import { drafts } from './draftReducers';
import { CreateQuote } from '../models/create-models/CreateQuote';
import { Wishlists } from '../models/Wishlists';
import { wishlists } from './wishlistReducers';
import { currentChatRoom, currentChatUser, selectedUsers } from './chatsReducers';
import { ChatRoom } from '../models/ChatRoom';
import {
  currentTemplate,
  newlyCreatedTemplate,
  sectionCounter, subsectionCounter, subTemplates, templates,
} from './templateReducers';
import { CreateTemplate } from '../models/create-models/CreateTemplate';
import { StoryNote } from '../models/StoryNote';
import {
  currentDisplayNote, currentNote, currentPreviewNote, currentStoryNote,
} from './notesReducers';
import { Note } from '../models/Note';
import { activeStoryTab } from './clubReducers';
import { CreateQuiz } from '../models/create-models/CreateQuiz';
import {createQuizAnswers, currentQuiz, quizAnswers} from './quizReducers';
import {StoryNoteTemplate} from "../models/Template";
import {CreateList} from "../models/create-models/create-list";
import {activeClubTab} from "./club-reducers";
import {isEditingModalOpen, isThumbnailPreviewModalOpen, thumbnailStyles} from "./thumbnail-reducers";
import {ThumbnailStyles} from "../models/ThumbnailStyles";

export interface StoreState {
  isPostImageModalVisible: boolean;
  authModal: any;
  currentUser: User | any;
  medium: Medium | any;
  mediums: Medium[] | any;
  lastLocationState: string;
  selectedMenuItem: string;
  isSettingsVisible: boolean;
  addPost: AddPost;
  isColorPaletteModalVisible: boolean;
  isFontModalMainVisible: boolean;
  isFontModalSourceVisible: boolean;
  isTextModalVisible: boolean;
  thought: string;
  searchBooks: Medium[] | any;
  searchPodcasts: Medium[] | any;
  searchSeries: Medium[] | any;
  searchMovies: Medium[] | any;
  searchUsers: User[] | any;
  drafts: Post[] | any;
  notifications: any[] | any;
  viewingUser: User | null;
  isLoading: boolean;
  selectedPost: Post | null;
  createWilt: CreateWilt;
  createQuote: CreateQuote;
  isWiltTextModalVisible: boolean;
  isMobileMenuOpen: boolean;
  createSummary: CreateSummary;
  fontModalDataMain: fontModalDataModel;
  fontModalDataSource: fontModalDataModel;
  isInterestModalVisible: boolean;
  wishlists: Wishlists;
  currentId: string;
  myMediums: Medium[];
  topRankingViewingUser: User | null;
  scrollPosition: number;
  scrollRef: any;
  reactionIds: any;
  shelfIds: any;
  followingUserIds: any;
  followRequestUserIds: any;
  podcastEpisode: Medium | null;
  requestsCount: number;
  isNewMessageModalOpen: boolean;
  currentChatUser: User | null;
  currentChatRoom: ChatRoom | null;
  selectedUsers: User[],
  unreadChats: number,
  templates: any[],
  subTemplates: any[],
  sectionCounter: number,
  subsectionCounter: { [key: string]: number },
  createTemplate: CreateTemplate,
  isTemplatesModalOpen: boolean,
  isTemplatePreviewModalOpen: boolean,
  currentStoryNote: StoryNote | null;
  currentNote: Note | null;
  isReactionsOpen: boolean;
  isMyNotesOpen: boolean;
  isMyPreviewNoteOpen: boolean;
  currentPreviewNote: Note | null;
  currentDisplayNote: Note | null;
  activeStoryTab: string;
  createQuiz: CreateQuiz;
  createQuizCounter: number;
  createQuizAnswersCounter: { [key: string]: number };
  createQuizAnswers: { [key: string]: string };
  quizAnswers: { [key: string]: string };
  currentQuiz: Post | null;
  newlyCreatedTemplate: StoryNoteTemplate | null;
  lastCreatedScreen: string;
  isAddNotesOpen: boolean;
  createList: CreateList;
  activeClubTab: string;
  currentTemplate: StoryNoteTemplate | null;
  thumbnailStyles: ThumbnailStyles | null;
  isEditingModalOpen: boolean;
  isThumbnailPreviewModalOpen: boolean;
  searchValue: string;
  resetTimestamp: boolean;
  isGradientPaletteModalVisible: boolean;
}

export default combineReducers<StoreState>({
  isPostImageModalVisible,
  authModal,
  currentUser,
  mediums,
  medium,
  lastLocationState,
  selectedMenuItem,
  isSettingsVisible,
  addPost,
  isColorPaletteModalVisible,
  isTextModalVisible,
  thought,
  searchBooks,
  searchPodcasts,
  searchUsers,
  notifications,
  viewingUser,
  isLoading,
  selectedPost,
  createWilt,
  isWiltTextModalVisible,
  searchSeries,
  searchMovies,
  isMobileMenuOpen,
  createSummary,
  drafts,
  createQuote,
  isFontModalMainVisible,
  fontModalDataMain,
  isFontModalSourceVisible,
  fontModalDataSource,
  isInterestModalVisible,
  wishlists,
  currentId,
  myMediums,
  topRankingViewingUser,
  scrollPosition,
  scrollRef,
  reactionIds,
  shelfIds,
  followingUserIds,
  podcastEpisode,
  followRequestUserIds,
  requestsCount,
  isNewMessageModalOpen,
  currentChatUser,
  selectedUsers,
  currentChatRoom,
  unreadChats,
  templates,
  subTemplates,
  sectionCounter,
  subsectionCounter,
  createTemplate,
  isTemplatesModalOpen,
  isTemplatePreviewModalOpen,
  currentStoryNote,
  currentNote,
  isReactionsOpen,
  isMyNotesOpen,
  isMyPreviewNoteOpen,
  currentPreviewNote,
  currentDisplayNote,
  activeStoryTab,
  createQuiz,
  createQuizAnswersCounter,
  createQuizAnswers,
  createQuizCounter,
  quizAnswers,
  currentQuiz,
  newlyCreatedTemplate,
  lastCreatedScreen,
  isAddNotesOpen,
  createList,
  activeClubTab,
  currentTemplate,
  thumbnailStyles,
  isEditingModalOpen,
  isThumbnailPreviewModalOpen,
  searchValue,
  resetTimestamp,
  isGradientPaletteModalVisible,
});
