import React from 'react';
import { Button, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './NavBar.module.scss';
import Logo from '../../../components/Logo/Logo';
import AuthModal from '../AuthModal/AuthModal';
import { ActionTypes } from '../../../actions/types';

function NavBar() {
  const dispatch = useDispatch();

  const login = () => {
    dispatch({ type: ActionTypes.TOGGLE_AUTH_MODAL, authType: 'login' });
  };

  const signUp = () => {
    dispatch({ type: ActionTypes.TOGGLE_AUTH_MODAL, authType: 'signup' });
  };

  return (
    <div className={styles.NavBar}>
      <AuthModal />
      <div className={styles.container}>
        <div className={styles.leftItems}>
          <Logo />
          <Tag className={styles.beta} color="#2db7f5">beta</Tag>
        </div>
        <div className={styles.rightItems}>
          <Button type="link" className={styles.loginButton} onClick={login}>Login</Button>
          <Button type="primary" className={styles.signUpButton} onClick={signUp}>Sign Up</Button>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
