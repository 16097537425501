import { Dispatch } from 'redux';
import axios from 'axios';
import { TopicPost } from '../models/TopicPost';

export const createTopic = (clubId: string, data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post(`/api/clubs/topics/${clubId}`, data)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateTopic = (topicId: string, data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put(`/api/clubs/topics/${topicId}`, data)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const deleteTopic = (topicId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/clubs/topics/${topicId}`)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const joinTopic = (topicId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post(`/api/clubs/topics/join/${topicId}`)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const like = (topicPost: TopicPost, typeText:string, callBack: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post(`/api/clubs/reactions/like/${topicPost._id}`, {
    topicPostUser: topicPost?.user?._id, typeText,
  })
    .then((response) => {
      callBack();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const unlike = (topicPost: TopicPost, typeText:string, callBack: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/clubs/reactions/like/${topicPost._id}`)
    .then((response) => {
      callBack();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateLike = (topicPost: TopicPost, typeText:string, callBack: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put(`/api/clubs/reactions/like/${topicPost._id}`, {
    topicPostUser: topicPost?.user?._id, typeText,
  })
    .then((response) => {
      callBack();
    }).catch((error) => {
      console.log('error: ', error);
    });
};
