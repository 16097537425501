import React, {useState} from 'react';
import styles from './list-info.module.scss';
import {Button, Form, Image, Input, Select, Spin} from "antd";
import {ArrowLeftOutlined, CloseOutlined} from "@ant-design/icons";
import {User} from "../../../models/User";
import {fallBackUrl} from "../../../utils/imageData";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useInfiniteQuery, useQuery} from "react-query";
import axios from "axios";
import {createClub} from "../../../services-api/clubService";
import {ActionTypes} from "../../../actions/types";
import {Medium} from "../../../models/Medium";
import {StoreState} from "../../../reducers/_RootReducers";
import {CreateList} from "../../../models/create-models/create-list";

const { Option } = Select;
function ListInfo() {
    const createList = useSelector<StoreState, CreateList>((state) => state.createList);
    const [searchQuery, setSearchQuery] = useState('');
    const dispatch = useDispatch();

    const handleChange = (values: any) => {
        //setValue(value);
        const updatedList = {
            ...createList,
            values,
        };
        dispatch({ type: ActionTypes.SET_CREATE_LIST, value: updatedList });
    };

    const { isLoading, error, data } = useQuery(`list-info-${searchQuery}`, () => axios.post('/api/users/mymediums/', { searchText: searchQuery })
        .then((response) => {
            return response.data;
        }));

    const onSearch = (data: any) => {
        setSearchQuery(data);
    };

    // const onDeleteClick = (currVal: string) => {
    //     setValue(value.filter((x: any) => x.value !== currVal));
    // };

    const onSelectChange = (value: string) => {
        const updatedList = {
            ...createList,
            visibility: value,
        };
        dispatch({ type: ActionTypes.SET_CREATE_LIST, value: updatedList });
    };

    const onListTitleChange = (data: any) => {
        const updatedList = {
            ...createList,
            text: data.target.value,
        };
        dispatch({ type: ActionTypes.SET_CREATE_LIST, value: updatedList });
    };

    return (
        <div className={styles.ListInfo}>
            <div className={styles.form}>
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input a list title!' }]}
                >
                    <Input
                        size="large"
                        allowClear
                        onChange={onListTitleChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Visibility"
                    name="visibility"
                    initialValue="public"
                    rules={[{ required: true, message: 'Please select visibility!' }]}
                >
                    <Select size="large" defaultValue="public" onSelect={onSelectChange}>
                        <Select.Option value="public">Public (Anyone)</Select.Option>
                        <Select.Option value="private">Private (My followers)</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Select stories"
                    name="stories"
                    rules={[{ required: true, message: 'Please select at least 1 story!' }]}
                >
                    <Select
                        mode="multiple"
                        size="large"
                        labelInValue
                        value={createList.values}
                        placeholder="Select stories"
                        notFoundContent={isLoading ? <Spin size="small" /> : null}
                        filterOption={false}
                        onSearch={onSearch}
                        tagRender={(item) => {
                            const medium: Medium = JSON.parse(item.value as string);
                            return (
                                <div className={styles.friendLabel}>
                                    <Image
                                        className={styles.image}
                                        src={medium.imageUrl}
                                        fallback={fallBackUrl}
                                    />
                                    <div className={styles.name}>{medium.title}</div>
                                    {/*<CloseOutlined className={styles.closeIcon} onClick={() => onDeleteClick(item.value as string)} />*/}
                                </div>
                            );
                        }}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                    >
                        {data?.map((medium: Medium, index: number) => (
                            <Option key={medium.imageUrl} value={JSON.stringify({ ...medium })}>
                                <div className={styles.friendLabelOption}>
                                    <Image
                                        className={styles.image}
                                        src={medium.imageUrl}
                                        alt={medium.imageUrl}
                                        fallback={fallBackUrl}
                                    />
                                    <div className={styles.name}>{medium.title}</div>
                                </div>
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        </div>
    );
}

export default ListInfo;