import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, DatePicker, Form, Image, Input, InputNumber, Select, Spin, Switch,
} from 'antd';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {useInfiniteQuery, useQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import { ActionTypes } from '../../actions/types';
import {createTopic, deleteTopic, updateTopic} from '../../services-api/clubTopicsService';
import styles from './club-stories-create-screen.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { Medium } from '../../models/Medium';
import { fallBackUrl } from '../../utils/imageData';
import { getCorrectTitle } from '../../helpers/helpers';

const { Option } = Select;
function ClubStoriesCreateScreen() {
  const { id, storiesId } = useParams<{ id: string, storiesId: string, sectionId: string }>();
  const [searchQuery, setSearchQuery] = useState('');
  const [form] = Form.useForm();
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const [value, setValue] = useState<any>('');
  const [type, setType] = useState('books');
  const [showRate, setShowRate] = useState<boolean>(false);
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const { isLoading: isTopicLoading, error: topicError, data: currentTopic } = useQuery(`story-${storiesId}`, () => axios.get(`/api/clubs/topics/${storiesId}`)
    .then((response) => response.data));

  const [frequency, setFrequency] = useState(currentTopic?.frequency || 'DAY');
  const [chapter, setChapter] = useState(currentTopic?.rate || 1);
  const [startDate, setStartDate] = useState<string | undefined>(currentTopic?.startDate);
  const [setupComplete, setSetupComplete] = useState<boolean>(true);

  useEffect(() => {
    if (currentTopic && setupComplete) {
      setFrequency(currentTopic?.frequency)
      setChapter(currentTopic?.rate)
      setStartDate(currentTopic?.startDate)
      setSetupComplete(false)
    }
  }, [currentTopic]);

  const {
    data,
    isFetching,
  } = useInfiniteQuery(
    `new-story-note-screen-${searchQuery}-${type}`,
    async (key, nextId = 0) => {
      const { data } = await axios.post('/api/users/mymediums', { searchText: searchQuery, type: getCorrectTitle(type || 'books')?.value });
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onBackClick = () => {
    history.goBack();
  };

  const createStoryTopic = (values: any) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(createTopic(id, { ...values, type: 'STORY' }, () => {
      dispatch({ type: ActionTypes.SET_LAST_CREATED_SCREEN, value: 'club-stories-create-screen' });
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      history.push(`/storyclubs/${id}/stories/all`);
    }));
  };

  const updateStoryTopic = (values: any) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(updateTopic(storiesId, { name: values.name, frequency: values.frequency, rate: values.rate, startDate: values.startDate }, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      history.push(`/storyclubs/${id}/stories/${storiesId}`);
    }));
  };

  const onFinish = (values: any) => {
    if (!values.name) {
      return;
    }

    let updatedValues = {...values}
    if (values.startDate) {
      updatedValues = {...values, startDate: values.startDate._d };
    }

    if (storiesId === 'create') {
      createStoryTopic(updatedValues);
    } else {
      updateStoryTopic(updatedValues);
    }
  };

  const onFinishFailed = (error: any) => {
    console.log('error: ', error);
  };

  const handleChange = (value: any) => {
    setValue(value);
  };

  const onSearch = (data: any) => {
    setSearchQuery(data);
  };

  const onTypeChange = (data: string) => {
    setValue(undefined);
    setType(data);
    queryCache.invalidateQueries(`new-story-note-screen-${searchQuery}`);
  };

  const onFrequencyChange = (data: string) => {
    setFrequency(data);
    queryCache.invalidateQueries(`new-story-note-screen-${searchQuery}`);
  };

  const onChapterChange = (data: any) => {
    setChapter(data);
    queryCache.invalidateQueries(`new-story-note-screen-${searchQuery}`);
  };

  const onDeleteClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(deleteTopic(storiesId, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      onBackClick();
    }));
  };

  const onChange = (date: any, dateString: string) => {
    setStartDate(dateString);
  }

  const onShowRate = (checked: boolean) => {
    setShowRate(checked);
  }

  const getCorrectRate = (type: string) => {
    switch (type) {
      case 'books':
        return 'reading';
      case 'podcasts':
        return 'listening';
      default:
        return 'watching'
    }
  }

  const getCorrectDate = (date: string) => {
    if (Date.parse(date+ " ")) {
      const myDate = new Date(date+" ");
      return myDate.toLocaleString(undefined, {
        day: 'numeric', weekday: 'long', month: 'long', hour: 'numeric', minute: 'numeric',
      });
    } else {
      const myDate = new Date(date);
      return myDate.toLocaleString(undefined, {
        day: 'numeric', weekday: 'long', month: 'long', hour: 'numeric', minute: 'numeric',
      });
    }
  }

  return ((storiesId === 'create' || currentTopic)
    ? (
      <div className={styles.ClubStoriesCreateScreen}>
        <div className={styles.container}>
          <div className={styles.header}>
            <Button
              className={styles.goBack}
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={onBackClick}
            />
            <div className={styles.name}>
              {currentTopic ? <span>Edit</span> : <span>Create</span>}
              {' '}
              Story Notes
            </div>
          </div>
          <div className={styles.form}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Title"
                name="name"
                rules={[{ required: true, message: 'Please enter name!' }]}
                initialValue={currentTopic?.name}
              >
                <Input
                  size="large"
                  placeholder="Enter story notes title"
                  allowClear
                />
              </Form.Item>
              {
                storiesId === 'create'
                && (
                <Form.Item
                  label="Type"
                  rules={[{ required: true, message: 'Please enter type!' }]}
                  name="type"
                  initialValue={type}
                >
                  <Select size="large" defaultValue={type} onChange={onTypeChange}>
                    <Select.Option value="books">Books</Select.Option>
                    <Select.Option value="series">Series</Select.Option>
                    <Select.Option value="movies">Movies</Select.Option>
                    <Select.Option value="podcasts">Podcasts</Select.Option>
                  </Select>
                </Form.Item>
                )
              }
              {
                type && storiesId === 'create'
                && (
                <Form.Item
                  label={`Select from your ${type}`}
                  name="medium"
                  rules={[{ required: true, message: `Please select ${type}` }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionLabelProp="label"
                    style={{ width: '100%', height: '50px !important' }}
                    searchValue={searchQuery}
                    onChange={handleChange}
                    onSearch={onSearch}
                    filterOption={false}
                    size="large"
                    placeholder="Select users"
                    notFoundContent={isFetching ? <Spin size="small" /> : null}
                  >
                    {data?.map((page) => page).flat().map((medium: Medium, index) => (
                      <Option
                        key={medium._id}
                        value={medium._id}
                        label={medium.title}
                      >
                        <div className={styles.friendLabelOption}>
                          <Image
                            className={styles.image}
                            src={medium.imageUrl}
                            alt={medium.imageUrl}
                            fallback={fallBackUrl}
                          />
                          <div className={styles.name}>{medium.title}</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                )
              }
              {
                storiesId === 'create' &&
                    <div>
                      <div>Set {getCorrectRate(type)} rate</div>
                      <Switch className={styles.switchRateSettings} onChange={onShowRate} />
                    </div>
              }
              {
                (currentTopic?.frequency || (storiesId === 'create' && showRate))
                && (
                    <Form.Item
                        label="Frequency"
                        rules={[{ required: showRate, message: 'Please select frequency!' }]}
                        name="frequency"
                        initialValue={currentTopic?.frequency || 'DAY'}
                    >
                      <Select size="large" onChange={onFrequencyChange}>
                        <Select.Option value="DAY">Day</Select.Option>
                        <Select.Option value="WEEK">Week</Select.Option>
                        <Select.Option value="MONTH">Month</Select.Option>
                      </Select>
                    </Form.Item>
                )
              }
              {
                (currentTopic?.rate || (storiesId === 'create' && showRate))
                && (
                    <Form.Item
                        label={`Chapters per ${frequency.toLowerCase()}`}
                        rules={[{ required: showRate, message: 'Please select rate!' }]}
                        name="rate"
                        initialValue={currentTopic?.rate || 1}
                    >
                      <InputNumber placeholder="Enter rate" min={1} max={100} onChange={onChapterChange} />
                    </Form.Item>
                )
              }
              {
                (currentTopic?.startDate || (storiesId === 'create' && showRate))
                && (
                    <div className={styles.startDateContainer}>
                      <Form.Item
                          label="Start Date"
                          rules={[{ required: showRate, message: 'Please select start date!' }]}
                          name="startDate"
                          initialValue={currentTopic?.startDate && moment(currentTopic?.startDate)}
                      >
                        <DatePicker
                            onChange={onChange}
                            size="large"
                            //defaultValue={moment(`${startDate?.toDateString()}`)}
                        />
                      </Form.Item>
                      <div className={styles.chapterPreview}>
                        {chapter} chapter(s) per {frequency.toLowerCase()} starting {startDate ? getCorrectDate(startDate): `...`}
                      </div>
                    </div>

                )
              }
              <div className={styles.actions}>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {currentTopic ? <span>Save</span> : <span>Create</span>}
                  </Button>
                </Form.Item>
                <Form.Item>
                  {currentTopic
                  && (
                  <Button
                    className={styles.delete}
                    size="large"
                    type="primary"
                    loading={isLoading}
                    onClick={onDeleteClick}
                    danger
                  >
                    Delete
                  </Button>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    ) : <div />
  );
}

export default ClubStoriesCreateScreen;
