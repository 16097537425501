import React from 'react';
import styles from './PostSource.module.scss';
import { Medium } from '../../../models/Medium';
import {Button, Dropdown, Menu} from "antd";
import {CheckCircleFilled, CheckOutlined, PlusOutlined } from '@ant-design/icons';

interface PostSourceProps {
  medium: Medium;
  onMediumClick: () => void;
  shelfIds: { [key: string]: string }
}

function PostSource(props: PostSourceProps) {
  const { medium, onMediumClick, shelfIds } = props;

  const menu = (
      <Menu>
          {
              !shelfIds[`${medium.external.id}`] || shelfIds[`${medium.external.id}`] === 'wishlist' &&
              <Menu.Item className={styles.menuItem}>
                  <div>
                      Wishlist
                  </div>
                  {
                      shelfIds[`${medium.external.id}`] && shelfIds[`${medium.external.id}`] === 'wishlist' &&
                      <CheckCircleFilled className={styles.checkedIcon} />
                  }
              </Menu.Item>
          }
        <Menu.Item className={styles.menuItem}>
            <div>
                Shelf
            </div>
            {
                shelfIds[`${medium.external.id}`] && shelfIds[`${medium.external.id}`] !== 'wishlist' &&
                <CheckCircleFilled className={styles.checkedIcon} />
            }
        </Menu.Item>
      </Menu>
  );


    // <div>
    //     <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
    //         <Button
    //             //size="small"
    //             type="link"
    //             icon={shelfIds[`${medium.external.id}`] ? <CheckOutlined /> : <PlusOutlined />}
    //         />
    //     </Dropdown>
    // </div>
  return (
    <div className={styles.PostSource}>
      <div className={styles.sourceInfo} onClick={onMediumClick}>
        <img
          className={styles.sourceImage}
          src={medium.imageUrl.replace('http://', 'https://')}
          alt={medium.imageUrl.replace('http://', 'https://')}
        />
        <div className={styles.sourceTitle}>
          {medium.title}
        </div>
      </div>
    </div>
  );
}

export default PostSource;
