import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './stadium-tab-icon.module.scss';
import stadium from '../../../assets/icons/icons8-colosseum-50.png';
import standsIcon from '../../../assets/icons/icons8-user-groups-50.png';
import chatsIcon from '../../../assets/icons/icons8-chat-50.png';
import userIcon from '../../../assets/icons/icons8-customer-50.png';

type StadiumTabIconProps = {
  icon: string;
};

function StadiumTabIcon(props: StadiumTabIconProps) {
  const { icon } = props;
  const location = useLocation();

  const getIcon = (icon: string) => {
    switch (icon) {
      case 'you':
        return userIcon;
      case 'stands':
        return standsIcon;
      case 'dms':
        return chatsIcon;
      case 'home':
        return stadium;
      default:
    }
  };

  const isCurrentLocation = () => {
    const urlTexts = location.pathname.split('/');
    return urlTexts.find((x) => x === icon);
  };

  return (
    <div className={styles.StadiumTabIcon}>
      <img
        className={`${styles.icon} ${isCurrentLocation() && styles.active}`}
        src={getIcon(icon)}
      />
      <div className={styles.title}>
        {icon}
      </div>
    </div>
  );
}

export default StadiumTabIcon;
