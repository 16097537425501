import React from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import renderHTML from 'react-render-html';
import styles from './WiltTimelinePreview.module.scss';
import { User } from '../../../models/User';
import { StoreState } from '../../../reducers/_RootReducers';
import { CreateQuote } from '../../../models/create-models/CreateQuote';

const { Text } = Typography;
function WiltTimelinePreview() {
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);

  return (
    currentUser
        && (
        <div className={styles.WiltTimelinePreview}>
          <div className={styles.header}>
            <div className={styles.headerContainer}>
              <div className={styles.title}>
                {createQuote.wiltTitle}
              </div>
              <div className={styles.headerInfo}>
                <div className={styles.user}>
                  <img
                    className={styles.userImage}
                    src={currentUser.image}
                    alt={currentUser.image}
                  />
                  <div className={styles.userInfo}>
                    <div className={styles.username}>
                      {currentUser.username}
                    </div>
                    <div className={styles.timestamp}>
                      {moment(new Date().toString()).format('LLL')}
                    </div>
                  </div>
                  <img
                    className={styles.mediumImage}
                    src={createQuote.medium?.imageUrl.replace('http://', 'https://')}
                    alt={createQuote.medium?.imageUrl.replace('http://', 'https://')}
                  />
                </div>
                <div className={styles.medium}>
                  <div className={styles.mediumSubtitle}>
                    <span className={styles.wiltLabel}>wilt</span>
                    {createQuote.wiltItems.length}
                    {' '}
                    things louis has learnt today from
                    {' '}
                    {createQuote.medium?.title}
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.timeline}>
              {
                        createQuote.wiltItems.map((val, index) => (
                          <div
                            key={`preview-timeline-item-${index}`}
                            className={styles.wiltItem}
                          >
                            <div className={styles.wiltContainer}>
                              <div className={styles.index}>
                                NO.
                                {' '}
                                {index + 1}
                              </div>
                              <div className={styles.wiltContent}>
                                <div className={styles.wiltTitle}>
                                  {val.title}
                                </div>
                                <div className={styles.wiltBody}>
                                  {
                                                    renderHTML(val.body)
                                                }
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                    }
            </div>
          </div>
        </div>
        )
  );
}

export default WiltTimelinePreview;
