import { Dispatch } from 'redux';
import axios from 'axios';
import cookies from 'js-cookie';
import socketIOClient from 'socket.io-client';
import { queryCache } from 'react-query';
import { ActionTypes } from '../actions/types';
import ErrorModal from '../components/Modals/Error/Error';
/* eslint-disable @typescript-eslint/no-unused-vars */

export const getCurrentUser = (callback?: () => void) => (dispatch: Dispatch, getState: Function) => {
  axios.get('/api/users/current/user')
    .then((response) => {
      dispatch({ type: ActionTypes.SET_CURRENT_USER, user: response.data });
      if (response.data && response.data.setupInterests && (response.data.setupInterests.complete === true || response.data.setupInterests.skip === true)) {
        dispatch({ type: ActionTypes.SET_INTEREST_MODAL_VISIBILITY, value: false });
      } else {
        dispatch({ type: ActionTypes.SET_INTEREST_MODAL_VISIBILITY, value: true });
      }

      const socketUrl = `${response.config.baseURL}${response.data._id}`;
      // const socketUrl = `${response.config.baseURL}`;
      const socket = socketIOClient(socketUrl);
      socket.on('connection', () => {
        console.log('connection - connection');
      });
      // socket.emit('setup', response.data);
      socket.on('message', (data: any) => {
        queryCache.getQueries().filter((val, index) => {
          if (`${val.queryKey[0]}`.includes('messages')) {
            queryCache.invalidateQueries(val.queryKey[0]);
          }
          return `${val.queryKey[0]}`.includes('messages');
        });
        dispatch({
          type: ActionTypes.SET_CURRENT_USER,
          user: {
            ...getState().currentUser,
            unreadMessagesCount: getState().currentUser?.unreadMessagesCount + 1,
          },
        });
      });
      if (callback) {
        callback();
      }
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        cookies.remove('goodlores-token');
        window.location.reload();
      }
    });
};

export const getUser = (username: string, callback: (user: any) => void) => (dispatch: Dispatch, getState: Function) => {
  axios.post(`/api/users/${username}`, { postTimeStamp: '' })
    .then((response) => {
      callback(response.data);
    }).catch((error) => {
      ErrorModal('An error occurred. Please try again later.');
    });
};

export const updateUser = (data: any, callback: (user: any) => void) => (dispatch: Dispatch, getState: Function) => {
  axios.put('/api/users', data)
    .then((response) => {
      callback(response.data);
    }).catch((error) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      ErrorModal(error.response.data);
    });
};

export const updatePrivateAccount = (privateAccount: boolean, callback: (user: any) => void) => (dispatch: Dispatch, getState: Function) => {
  axios.put('/api/users/private', { privateAccount })
    .then((response) => {
      callback(response.data);
    }).catch((error) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      ErrorModal(error.response.data);
    });
};

export const checkUsername = (username: string, callback: (available: boolean) => void) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/users/check', { username })
    .then((response) => {
      callback(response.data);
    }).catch((error) => {
      ErrorModal('An error occurred. Please try again later.');
    });
};

export const follow = (follower: string, following: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/users/follow', { following, follower })
    .then((response) => {
      callback(response.data.followRequest);
    }).catch((error) => {
      ErrorModal('An error occurred. Please try again later.');
    });
};

export const unfollow = (follower: string, following: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/users/unfollow', { following, follower })
    .then((response) => {
      callback();
    }).catch((error) => {
      ErrorModal('An error occurred. Please try again later.');
    });
};

export const deleteFollowRequest = (requestId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/requests/${requestId}/follow/delete`)
    .then((response) => {
      callback();
    }).catch((error) => {
      ErrorModal('An error occurred. Please try again later.');
    });
};

export const deleteFollowRequestByUserId = (followingId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/requests/follow/delete/${followingId}/`)
    .then((response) => {
      callback();
    }).catch((error) => {
      ErrorModal('An error occurred. Please try again later.');
    });
};

export const confirmFollowRequest = (requestId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.get(`/api/requests/${requestId}/follow/confirm`)
    .then((response) => {
      callback();
    }).catch((error) => {
      ErrorModal('An error occurred. Please try again later.');
    });
};
