import React from 'react';
import styles from './official-card-card.module.scss';
import subIcon from '../../../assets/icons/icons8-replace-50.png';
import baylor from '../../../assets/images/baylor.png';
import elon from '../../../assets/images/elon.jpeg';
import likeIcon from '../../../assets/icons/icons8-facebook-like-50.png';
import statLikeIcon from '../../../assets/icons/like.png';
import statHeartIcon from '../../../assets/icons/heart.png';
import statLolIcon from '../../../assets/icons/lol.png';
import commentIcon from '../../../assets/icons/icons8-topic-50.png';
import shareIcon from '../../../assets/icons/icons8-forward-arrow-50.png';

type OfficialCardCardProps = {
  type: string;
};

function OfficialCardCard(props: OfficialCardCardProps) {
  const { type } = props;
  return (
    <div
      className={styles.OfficialCardCard}
    >
      <div className={styles.officialContent}>
        <div className={styles.imageContainer}>
          <div className={`${styles.card} ${styles[`${type}`]}`} />
        </div>
        <div className={styles.title}>
          {type}
          {' '}
          Card
        </div>
        <div className={styles.time}>
          41&apos;
        </div>
        <div className={styles.players}>
          <div className={styles.player}>
            <div className={styles.info}>
              <div className={styles.in}>
              </div>
              <div className={styles.name}>
                L. James #23
              </div>
              <div className={styles.detailsContainer}>
                <img
                  className={styles.detailsImage}
                  src={baylor}
                />
                {/* <div className={styles.detailsText}> */}
                {/*  info */}
                {/* </div> */}
              </div>
            </div>
            <img
              className={styles.image}
              src={elon}
            />
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.reactContainer}>
          <img
            className={styles.reactionImage}
            src={likeIcon}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.leftStats}>
            <img
              className={styles.reactionIcon1}
              src={statLikeIcon}
              alt=""
            />
            <img
              className={styles.reactionIcon2}
              src={statHeartIcon}
              alt=""
            />
            <img
              className={styles.reactionIcon3}
              src={statLolIcon}
              alt=""
            />
            <div className={styles.reactionNumber}>
              1.5K
            </div>
          </div>
          <div className={styles.rightStats}>
            <img
              className={styles.commentIcon}
              src={commentIcon}
              alt=""
            />
            <div className={styles.commentNumber}>
              1.5K
            </div>
            <img
              className={styles.shareIcon}
              src={shareIcon}
              alt=""
            />
            <div className={styles.shareNumber}>
              1.5K
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficialCardCard;
