import React from 'react';
import styles from './Information.module.scss';
import book1 from '../../../assets/images/book2_red.svg';
import book2 from '../../../assets/images/share1_yellow.svg';
import book3 from '../../../assets/images/create1_green.svg';

function Information() {
  return (
    <div className={styles.Information}>
      <div className={styles.informationContainer}>
        <div className={styles.title}>
          G
          <span className={styles.o1}>o</span>
          <span className={styles.o2}>o</span>
          dL
          <span className={styles.o3}>o</span>
          r
          <span className={styles.e}>e</span>
          s is not
          {' '}
          <br />
          just another movie/book app
        </div>
        <div className={styles.subtitle}>
          We rebuilt GoodLores from the ground up to make it simple and easy to use.
          {' '}
          <br />
          We specifically designed it for those of you who love to read, watch, listen and share your favorite quotes.
        </div>
        <div className={styles.theWs}>
          <div className={styles.item}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={book1} alt="" />
            </div>
            <div className={styles.text_title}>
              FIND
            </div>
            <div className={styles.text_subtitle}>
              Find your favorite stories and see your friends favorites.
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={book2} alt="" />
            </div>
            <div className={styles.text_title}>
              SHARE
            </div>
            <div className={styles.text_subtitle}>
              Share your favorite quotes from your favorite stories
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={book3} alt="" />
            </div>
            <div className={styles.text_title}>
              CREATE
            </div>
            <div className={styles.text_subtitle}>
              Create amazing looking quotes in seconds.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
