import { ActionTypes } from '../actions/types';
import {StoryNoteTemplate} from "../models/Template";

interface setTemplates {
  type: typeof ActionTypes.SET_TEMPLATES;
  value: any[];
}

interface setSubTemplates {
  type: typeof ActionTypes.SET_SUB_TEMPLATES;
  value: any[];
}

interface setSectionCounter {
  type: typeof ActionTypes.SET_SECTION_COUNTER;
  value: number;
}

interface setSubSectionCounter {
  type: typeof ActionTypes.SET_SUBSECTION_COUNTER;
  value: { [key: string]: number };
}

interface setNewlyCreatedTemplate {
  type: typeof ActionTypes.SET_NEWLY_CREATED_TEMPLATE;
  value: StoryNoteTemplate;
}

interface setCurrentPreviewTemplate {
  type: typeof ActionTypes.SET_CURRENT_PREVIEW_TEMPLATE;
  value: StoryNoteTemplate;
}


type templateActions = setTemplates | setSubTemplates | setSectionCounter | setSubSectionCounter
    | setNewlyCreatedTemplate | setCurrentPreviewTemplate;

export const templates = (state: any[] = [], action: templateActions) => {
  if (action.type === ActionTypes.SET_TEMPLATES) {
    return action.value;
  }
  return state;
};

export const subTemplates = (state:any[] = [], action: templateActions) => {
  if (action.type === ActionTypes.SET_SUB_TEMPLATES) {
    return action.value;
  }
  return state;
};

export const sectionCounter = (state: number = 0, action: templateActions) => {
  if (action.type === ActionTypes.SET_SECTION_COUNTER) {
    return action.value;
  }
  return state;
};

export const subsectionCounter = (state: { [key: string]: number } = {}, action: templateActions) => {
  if (action.type === ActionTypes.SET_SUBSECTION_COUNTER) {
    return action.value;
  }
  return state;
};

export const newlyCreatedTemplate = (state: StoryNoteTemplate | null = null, action: templateActions) => {
  if (action.type === ActionTypes.SET_NEWLY_CREATED_TEMPLATE) {
    return action.value;
  }
  return state;
};

export const currentTemplate = (state: StoryNoteTemplate | null = null, action: templateActions) => {
  if (action.type === ActionTypes.SET_CURRENT_PREVIEW_TEMPLATE) {
    return action.value;
  }
  return state;
}
