import React from 'react';
import {Badge, Button, Image, Typography} from 'antd';
import styles from './topic-card.module.scss';
import { Topic } from '../../../models/Topic';
import { fallBackUrl } from '../../../utils/imageData';
import {convertTimeToDaysAgo} from "../../../helpers/helpers";

type TopicCardProps = {
  topic: Topic;
  onTopicClick: (id: string) => void;
  onJoinClick?: (event: any, topicId: string) => void;
  type: string;
  isLoading: boolean;
  currentId: string;
  currentUsersTopicIds?: { [key: string]: string }
  notificationCount?: number;
};

const { Paragraph } = Typography;
function TopicCard(props: TopicCardProps) {
  const {
    topic, onTopicClick, onJoinClick, type, isLoading, currentId,
    currentUsersTopicIds, notificationCount,
  } = props;

  const showCorrectBox = () => {
    if (type === 'all') {
      if (currentUsersTopicIds && currentUsersTopicIds[`${topic._id}`]) {
        return (
          <Button
            type="link"
          >
            Joined
          </Button>
        );
      }

      if (onJoinClick) {
        return (
          <Button
            type="primary"
            onClick={(event: any) => onJoinClick(event, topic._id)}
            loading={isLoading && currentId === topic._id}
          >
            Join
          </Button>
        );
      }
    }

    if (type === 'yours') {
      return (
        <Badge
          className={styles.badge}
          count={notificationCount || 0}
          overflowCount={100}
        />
      );
    }
  };

  return (
    <div
      className={styles.TopicCard}
      onClick={() => onTopicClick(topic?._id)}
    >
      <Image
        className={styles.image}
        src={topic.medium.imageUrl}
        alt={topic.medium.imageUrl}
        preview={false}
        fallback={fallBackUrl}
      />
      <div className={styles.textInfo}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {topic?.name}
          </div>
        </div>
        <Paragraph
            className={styles.message}
            ellipsis={{ rows: 1, suffix: ` ${convertTimeToDaysAgo(topic.updatedAt)}`, expandable: false }}
        >
          updated
        </Paragraph>
      </div>
      <div className={styles.actionBox}>
        {
          showCorrectBox()
        }
      </div>
    </div>
  );
}

export default TopicCard;
