import React from 'react';
import {Button, Image} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UserCard.module.scss';
import { User } from '../../../models/User';
import { ActionTypes } from '../../../actions/types';
import { StoreState } from '../../../reducers/_RootReducers';
import {fallBackUrl} from "../../../utils/imageData";

interface UserCardProps {
  user: User;
  currentUserId: string;
  onFollowAction: (user: User, callback: Function) => void;
  followingUserIds: any;
  followRequestUserIds: any;
}

function UserCard(props: UserCardProps) {
  const {
    user, currentUserId, followingUserIds, followRequestUserIds, onFollowAction,
  } = props;

  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const onCardSelect = () => {
    history.push(`/profile/${props.user.username}`);
  };

  const onFollowClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: user._id });
    onFollowAction(user, (followRequest: boolean) => {
      if (followRequest) {
        dispatch({ type: ActionTypes.SET_FOLLOW_REQUEST_USER_IDS, value: { ...followRequestUserIds, [user._id]: true } });
      } else {
        dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: { ...followingUserIds, [user._id]: true } });
      }
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
    });
  };

  return (
    <div role="button" tabIndex={0} className={styles.UserCard} onClick={onCardSelect}>
      <div className={styles.imageContainer}>
        <Image
            className={styles.image}
            src={user.image}
            alt={user.image}
            preview={false}
            fallback={fallBackUrl}
        />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.header}>
          <div className={styles.name}>
            <div className={styles.displayName}>
              {user.displayName}
            </div>
            <div className={styles.username}>
              @
              {user.username}
            </div>
          </div>
          {
            currentUserId !== user._id
            && (
            <Button
              type="primary"
              className={followingUserIds[`${user._id}`] ? styles.following : styles.follow}
              ghost={!followingUserIds[`${user._id}`]}
              loading={currentId === user._id && isLoading}
              onClick={onFollowClick}
            >
              {followingUserIds[`${user._id}`] ? 'Following' : followRequestUserIds[`${user._id}`] ? 'Requested' : 'Follow'}
            </Button>
            )
          }
        </div>
        <div className={styles.bio}>
          {user.bio}
        </div>
      </div>
    </div>
  );
}

export default UserCard;
