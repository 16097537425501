import React from 'react';
import styles from './PreviewQuote.module.scss';
import { CreateQuote } from '../../../models/create-models/CreateQuote';

interface PreviewQuoteProps {
  createQuote: CreateQuote;
  onPreviousClick: () => void;
}

function PreviewQuote(props: PreviewQuoteProps) {
  const { createQuote } = props;

  return (
    <div className={styles.PreviewQuote}>
      <div className={styles.thoughtText}>
        {createQuote.thought}
      </div>
      <div className={styles.quoteContainer}>
        <div className={styles.layersContent}>
          <img
            className={styles.quote}
            src={createQuote.dataUrl ? URL.createObjectURL(createQuote.dataUrl) : createQuote.image}
            alt={createQuote.dataUrl ? URL.createObjectURL(createQuote.dataUrl) : createQuote.image}
          />
        </div>
      </div>
    </div>
  );
}

export default PreviewQuote;
