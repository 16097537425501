import React, { useEffect, useState } from 'react';
import { Input, List, Tabs } from 'antd';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SearchScreen.module.scss';
import MediumCard from '../../components/Cards/MediumCard/MediumCard';
import UserCard from '../../components/Cards/UserCard/UserCard';
import { Medium } from '../../models/Medium';
import { ActionTypes } from '../../actions/types';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { follow } from '../../services-api/userService';
import { addMediumToShelf, addMediumToWishList } from '../../services-api/mediumService';
import Empty from '../../components/Empty/Empty';
import TabTitle from '../../components/TabTitle/TabTitle';

const { Search } = Input;
const { TabPane } = Tabs;
function SearchScreen() {
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const searchBooks = useSelector<StoreState, Medium[]>((state) => state.searchBooks);
  const searchPodcasts = useSelector<StoreState, Medium[]>((state) => state.searchPodcasts);
  const searchSeries = useSelector<StoreState, Medium[]>((state) => state.searchSeries);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const searchMovies = useSelector<StoreState, Medium[]>((state) => state.searchMovies);
  const searchUsers = useSelector<StoreState, User[]>((state) => state.searchUsers);
  const shelfIds = useSelector<StoreState, any>((state) => state.shelfIds);
  const followingUserIds = useSelector<StoreState, any>((state) => state.followingUserIds);
  const followRequestUserIds = useSelector<StoreState, any>((state) => state.followRequestUserIds);
  const searchValue = useSelector<StoreState, string>((state) => state.searchValue);
  const [currentTab, setCurrentTab] = useState(tab || 'users');
  const [searchText, setSearchText] = useState(searchValue || '');
  const dispatch = useDispatch();

  const onTabChange = (id: string) => {
    setCurrentTab(id);
    history.replace(`/search/${id}`);

    if (!searchText) {
      return;
    }

    if (id === 'users' && searchUsers.length === 0) {
      findUsers(searchText);
    } else if (id === 'ebook' && searchBooks.length === 0) {
      findBooks(searchText);
    } else if (id === 'tvShow' && searchSeries.length === 0) {
      findSeries(searchText);
    } else if (id === 'movie' && searchMovies.length === 0) {
      findMovies(searchText);
    } else if (id === 'podcast' && searchPodcasts.length === 0) {
      findPodcasts(searchText);
    }
  };

  const onSearch = (value: string) => {
    setSearchText(value);
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    if (currentTab === 'users') {
      findUsers(value);
    } else if (currentTab === 'ebook') {
      findBooks(value);
    } else if (currentTab === 'tvShow') {
      findSeries(value);
    } else if (currentTab === 'movie') {
      findMovies(value);
    } else if (currentTab === 'podcast') {
      findPodcasts(value);
    } else {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
    }
  };

  const findBooks = (searchText: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_BOOKS, mediums: [] });
    axios.post('/api/search/mediums', { searchText, media: 'ebook', entity: 'ebook' })
      .then((response) => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data.shelfIds } });
        dispatch({ type: ActionTypes.SET_SEARCH_BOOKS, mediums: response.data.mediums });
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const findSeries = (searchText: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_SERIES, mediums: [] });
    axios.post('/api/search/series', { searchText })
      .then((response) => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data.shelfIds } });
        dispatch({ type: ActionTypes.SET_SEARCH_SERIES, mediums: response.data.mediums });
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const findMovies = (searchText: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_MOVIES, mediums: [] });
    axios.post('/api/search/movies', { searchText, media: 'movie', entity: 'movie' })
      .then((response) => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data.shelfIds } });
        dispatch({ type: ActionTypes.SET_SEARCH_MOVIES, mediums: response.data.mediums });
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const findPodcasts = (searchText: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_PODCASTS, mediums: [] });
    axios.post('/api/search/mediums', { searchText, media: 'podcast', entity: 'podcast' })
      .then((response) => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data.shelfIds } });
        dispatch({ type: ActionTypes.SET_SEARCH_PODCASTS, mediums: response.data.mediums });
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const findUsers = (searchText: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_USERS, users: [] });
    axios.post('/api/search/users', { searchText })
      .then((response) => {
        dispatch({ type: ActionTypes.SET_FOLLOW_REQUEST_USER_IDS, value: response.data.followRequestsUserIds });
        dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: response.data.followingUserIds });
        dispatch({ type: ActionTypes.SET_SEARCH_USERS, users: response.data.users });
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const onMediumSelect = (medium: Medium) => {
    dispatch({ type: ActionTypes.SET_MEDIUM, medium });
    history.push(`/mediums/${medium.external.id}/${medium.typeValue}`);
  };

  const addToShelfAction = (medium: Medium, callback: Function) => {
    dispatch(addMediumToShelf(currentUser.username, medium, callback));
  };

  const addToWishListAction = (medium: Medium, callback: Function) => {
    dispatch(addMediumToWishList(currentUser.username, medium, callback));
  };

  const onFollowAction = (user: User, callback: Function) => {
    dispatch(follow(currentUser._id, user._id, callback));
  };

  useEffect(() => {
  }, [currentUser]);

  return (
    <div className={styles.SearchScreen}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.searchContainer}>
            <Search
              className={styles.searchInput}
              placeholder="Search"
              allowClear
              size="large"
              defaultValue={searchValue}
              onSearch={onSearch}
            />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.resultContainer}>
            <Tabs tabBarGutter={12} defaultActiveKey={tab || 'users'} onChange={onTabChange}>
              <TabPane tab={<TabTitle text="Users" />} key="users">
                <div className={styles.users}>
                  <List
                    className={styles.usersContainer}
                    grid={{ gutter: 0, column: 1 }}
                    dataSource={searchUsers && searchUsers}
                    locale={!searchText
                      ? {
                        emptyText: <Empty
                          title="Find other users"
                          subTitle="Search for users"
                          icon="search"
                          url="/search"
                          screen="empty_users"
                        />,
                      }
                      : {
                        emptyText:
                        <div className={styles.noResultSearch}>
                          No results found for
                          {' '}
                          <span className={styles.searchText} >{searchText}</span>
                        </div>,
                      }}
                    renderItem={(item) => (
                      <List.Item key={`UserCard${item}`}>
                        <UserCard
                          user={item}
                          onFollowAction={onFollowAction}
                          currentUserId={currentUser._id}
                          followingUserIds={followingUserIds}
                          followRequestUserIds={followRequestUserIds}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
              <TabPane tab={<TabTitle text="Books" />} key="ebook">
                <div className={styles.books}>
                  <List
                    className={styles.booksContainer}
                    grid={{ gutter: 0, column: 1 }}
                    loading={isLoading}
                    dataSource={searchBooks && searchBooks}
                    locale={!searchText ? {
                      emptyText: <Empty
                        title="Find millions of books"
                        subTitle="Search for books"
                        icon="search"
                        url="/search"
                        screen="empty_books"
                      />,
                    } : {
                      emptyText:
                      <div className={styles.noResultSearch}>
                        No results found for
                        {' '}
                        <span className={styles.searchText}>{searchText}</span>
                      </div>,
                    }}
                    renderItem={(item) => (
                      <List.Item key={`MediumCard${item}`}>
                        <MediumCard
                          onMediumSelect={onMediumSelect}
                          addToShelfAction={addToShelfAction}
                          addToWishListAction={addToWishListAction}
                          medium={item}
                          shelfIds={shelfIds}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
              <TabPane tab={<TabTitle text="Series" />} key="tvShow">
                <div className={styles.books}>
                  <List
                    className={styles.booksContainer}
                    grid={{ gutter: 0, column: 1 }}
                    loading={isLoading}
                    dataSource={searchSeries && searchSeries}
                    locale={!searchText ? {
                      emptyText: <Empty
                        title="Find millions of series"
                        subTitle="Search for series"
                        icon="search"
                        url="/search"
                        screen="empty_series"
                      />,
                    } : {
                      emptyText:
                      <div className={styles.noResultSearch}>
                        No results found for
                        {' '}
                        <span className={styles.searchText}>{searchText}</span>
                      </div>,
                    }}
                    renderItem={(item) => (
                      <List.Item key={`MediumCardSeries${item}`}>
                        <MediumCard
                          onMediumSelect={onMediumSelect}
                          addToShelfAction={addToShelfAction}
                          addToWishListAction={addToWishListAction}
                          medium={item}
                          shelfIds={shelfIds}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
              <TabPane tab={<TabTitle text="Movies" />} key="movie">
                <div className={styles.books}>
                  <List
                    className={styles.booksContainer}
                    grid={{ gutter: 0, column: 1 }}
                    loading={isLoading}
                    dataSource={searchMovies && searchMovies}
                    locale={!searchText ? {
                      emptyText: <Empty
                        title="Find millions of movies"
                        subTitle="Search for movies"
                        icon="search"
                        url="/search"
                        screen="empty_movies"
                      />,
                    } : {
                      emptyText:
                      <div className={styles.noResultSearch}>
                        No results found for
                        {' '}
                        <span className={styles.searchText}>{searchText}</span>
                      </div>,
                    }}
                    renderItem={(item) => (
                      <List.Item key={`MediumCardMovies${item}`}>
                        <MediumCard
                          onMediumSelect={onMediumSelect}
                          addToShelfAction={addToShelfAction}
                          addToWishListAction={addToWishListAction}
                          medium={item}
                          shelfIds={shelfIds}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
              <TabPane tab={<TabTitle text="Podcasts" />} key="podcast">
                <div className={styles.books}>
                  <List
                    className={styles.booksContainer}
                    grid={{ gutter: 0, column: 1 }}
                    loading={isLoading}
                    dataSource={searchPodcasts && searchPodcasts}
                    locale={!searchText ? {
                      emptyText: <Empty
                        title="Find millions of podcasts"
                        subTitle="Search for podcasts"
                        icon="search"
                        url="/search"
                        screen="empty_podcasts"
                      />,
                    } : {
                      emptyText:
                      <div className={styles.noResultSearch}>
                        No results found for
                        {' '}
                        <span className={styles.searchText}>{searchText}</span>
                      </div>,
                    }}
                    renderItem={(item) => (
                      <List.Item key={`MediumCardPodcast${item}`}>
                        <MediumCard
                          onMediumSelect={onMediumSelect}
                          addToShelfAction={addToShelfAction}
                          addToWishListAction={addToWishListAction}
                          medium={item}
                          shelfIds={shelfIds}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchScreen;
