import React from 'react';
import { Button } from 'antd';
import styles from './stadium-stats.module.scss';
import TopPostCard from './top-post-card/top-post-card';
import LoudnessCard from './loudness-card/loudness-card';
import FoodCard from './food-card/food-card';
import PollCard from './poll-card/poll-card';
import BestPlayerCard from './best-player-card/best-player-card';

function StadiumStats() {
  const getCorrectStatCard = (index: number) => {
    switch (index) {
      case 0:
        return <TopPostCard />;
      case 1:
        return <LoudnessCard />;
      case 2:
        return <FoodCard />;
      case 3:
        return <PollCard />;
      case 4:
        return <BestPlayerCard />;
      default:
    }
  };

  return (
    <div className={styles.StadiumStats}>
      <div className={styles.header}>
        <div className={styles.title}>
          Stadium Stats
        </div>
        <Button
          className={styles.viewAll}
          type="link"
        >
          view all
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.container}>
          {
          [1, 1, 1, 1].map((item, index) => getCorrectStatCard(index))
          }
        </div>
      </div>
    </div>
  );
}

export default StadiumStats;
