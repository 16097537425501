import React from 'react';
import styles from './food-card.module.scss';
import Header from '../header/header';
import pizzaIcon from '../../../../../assets/icons/icons8-salami-pizza-48.png';
import Footer from '../footer/footer';
import elon from '../../../../../assets/images/elon.jpeg';
import OneUser from '../one-user/one-user';

function FoodCard() {
  return (
    <div className={styles.FoodCard}>
      <Header image={pizzaIcon} title="Food Fight" minute="17th min" />
      <OneUser
        image={elon}
        username="elonmusk"
        text="Elon Musk was hit with a Pizza slice"
      />
      <Footer />
    </div>
  );
}

export default FoodCard;
