import React, {useEffect} from 'react';
import { Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {
  LeftOutlined, RightOutlined, UploadOutlined, SaveOutlined,
} from '@ant-design/icons';
import * as htmlToImage from 'html-to-image';
import styles from './create-quote-screen.module.scss';
import CreateProgress from '../../components/CreateProgress/CreateProgress';
import { StoreState } from '../../reducers/_RootReducers';
import { CreateQuote } from '../../models/create-models/CreateQuote';
import { ActionTypes } from '../../actions/types';
import MyMediumList from '../../components/MyMediumList/MyMediumList';
import { Medium } from '../../models/Medium';
import CustomizeQuote from './CustomizeQuote/CustomizeQuote';
import AddThoughts from './AddThoughts/AddThoughts';
import PreviewQuote from './PreviewQuote/PreviewQuote';
import TextModal from './TextModal/TextModal';
import { createDraftQuote, updateDraftQuote, createPostQuote } from '../../services-api/createPostService';
import {useQueryCache} from "react-query";

function CreateQuoteScreen() {
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const location = useLocation();
  const locationState = location.state as { fromStoryNote: boolean | undefined };

  const onMyMediumClick = (medium: Medium) => {
    if (createQuote.currentPage < pages.length) {
      const newCreateQuote = { ...createQuote, medium, currentPage: createQuote.currentPage + 1 };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  const onPreviousClick = () => {

  };

  const pages = [
    {
      page: 1,
      showNavigation: false,
      title: 'select a story',
      content: <MyMediumList onMyMediumClick={onMyMediumClick} />,
    },
    {
      page: 2,
      showNavigation: true,
      title: 'create quote',
      content: <CustomizeQuote createQuote={createQuote} actionType={ActionTypes.SET_CREATE_QUOTE} />,
    },
    {
      page: 3,
      showNavigation: true,
      title: 'add thoughts',
      content: <AddThoughts
        createQuote={createQuote}
      />,
    },
    {
      page: 4,
      showNavigation: true,
      title: 'preview',
      content: <PreviewQuote
        createQuote={createQuote}
        onPreviousClick={onPreviousClick}
      />,
    },
  ];

  const onNextClick = () => {
    if (createQuote.currentPage < pages.length) {
      if (createQuote.currentPage === 2) {
        saveAndGoToNext();
      } else {
        const newCreateQuote = {
          ...createQuote, currentPage: createQuote.currentPage + 1, totalPages: pages.length, type: 'quote',
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
      }
    }
  };

  const onPrevClick = () => {
    if (locationState.fromStoryNote && createQuote.currentPage === 2) {
      history.goBack();
    } else {
      if (createQuote.currentPage > 1) {
        const newCreateQuote = {
          ...createQuote, currentPage: createQuote.currentPage - 1, totalPages: pages.length, type: 'quote',
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
      }
    }
  };

  const onSaveAsDraft = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    if (createQuote.draftId) {
      dispatch(updateDraftQuote(createQuote, () => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        history.replace('/drafts');
        dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
        dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
      }));
    } else {
      dispatch(createDraftQuote(createQuote, () => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        history.replace('/drafts');
        dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
        dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
      }));
    }
  };

  const onPostClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(createPostQuote(createQuote, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      queryCache.invalidateQueries('home-screen');
      history.replace('/home');
    }));
  };

  const saveAndGoToNext = () => {
    const item = document.getElementById('my-node');

    htmlToImage.toPng(item!, { pixelRatio: 2 })
        .then((dataUrl) => {
          console.log("dataUrl: ",dataUrl);
          fetch(dataUrl)
              .then(resD => {
                console.log("dataUrl - fet: ",resD);
                resD.blob().then((blob) => {
                  console.log("dataUrl - blob: ",blob);
                  const newCreateQuote = { ...createQuote, dataUrl: blob, currentPage: createQuote.currentPage + 1 };
                  dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
                });
              })
              .then(console.log)
        })

    // htmlToImage.toSvgDataURL(item!, { pixelRatio: 1 })
    //     .then((dataUrl) => {
    //       console.log("dataUrl: ",dataUrl);
    //       fetch(dataUrl)
    //           .then(resD => {
    //             console.log("dataUrl - fet: ",resD);
    //             resD.blob().then((blob) => {
    //               console.log("dataUrl - blob: ",blob);
    //               const newCreateQuote = { ...createQuote, dataUrl: blob, currentPage: createQuote.currentPage + 1 };
    //               dispatch({ type: ActionTypes.SET_CREATE_QUOTE, createQuote: newCreateQuote });
    //             });
    //           })
    //           .then(console.log)
    //     })

    // htmlToImage.toSvg(item!, { pixelRatio: 4 })
    //     .then((dataUrl) => {
    //       Jimp.read(dataUrl, (err, image) => {
    //         if (err) {
    //           console.log("Error homie: ",err);
    //         } else {
    //           const temp = image.scale(2)    // resize
    //               .quality(100); // set JPEG quality
    //           const mime = temp.getMIME();
    //           temp.getBuffer(Jimp.MIME_PNG, (err2, encodedData) => {
    //             if (err2) {
    //               alert("error: "+err2);
    //             } else {
    //               if (encodedData) {
    //                 const newCreateQuote = { ...createQuote, dataUrl: new Blob([encodedData]), currentPage: createQuote.currentPage + 1 };
    //                 dispatch({ type: ActionTypes.SET_CREATE_QUOTE, createQuote: newCreateQuote });
    //                 } else {
    //                 alert("no error and no res");
    //               }
    //             }
    //
    //           });
    //         }
    //       });
    //     }).catch(e => {
    //   console.log("e: ",e);
    // });

    // htmlToImage.toPng(item!, { pixelRatio: 4 })
    //     .then((dataUrl) => {
    //       Jimp.read(dataUrl, (err, image) => {
    //         if (err) {
    //           console.log("Error homie: ",err);
    //         } else {
    //           const temp = image.scale(4)    // resize
    //               .quality(100); // set JPEG quality
    //           const mime = temp.getMIME();
    //           temp.getBuffer(Jimp.MIME_PNG, (err2, encodedData) => {
    //             if (err2) {
    //               alert("error: "+err2);
    //             } else {
    //               if (encodedData) {
    //                 const newCreateQuote = { ...createQuote, dataUrl: new Blob([encodedData]), currentPage: createQuote.currentPage + 1 };
    //                 dispatch({ type: ActionTypes.SET_CREATE_QUOTE, createQuote: newCreateQuote });
    //                 } else {
    //                 alert("no error and no res");
    //               }
    //             }
    //
    //           });
    //         }
    //       });
    //     }).catch(e => {
    //   console.log("e: ",e);
    // });
    // if (item) {
    //   htmlToImage.toBlob(item, { pixelRatio: 1 })
    //     .then((dataUrl) => {
    //       if (dataUrl) {
    //         const newCreateQuote = { ...createQuote, dataUrl, currentPage: createQuote.currentPage + 1 };
    //         dispatch({ type: ActionTypes.SET_CREATE_QUOTE, createQuote: newCreateQuote });
    //       }
    //     }).catch((e) => {
    //       console.log('e: ', e);
    //     });
    // }
  };

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <div className={styles.CreateQuoteScreen}>
      {
        createQuote.draftId
        && (
        <Button className={styles.navigationBtn} style={{ marginRight: '8px' }} onClick={onBackClick}>
          <LeftOutlined />
          Drafts
        </Button>
        )
      }
      <div className={styles.quoteCard}>
        <TextModal />
        <Spin spinning={isLoading}>
          <div
            className={styles.container}
          >
            <CreateProgress existingId={createQuote?.draftId} pages={pages} current={createQuote?.currentPage} />
            <div>
              {
                  pages[createQuote.currentPage - 1]?.content
              }
            </div>
            {
                pages[createQuote.currentPage - 1]?.showNavigation
                && (
                <div>
                  {
                        createQuote.currentPage === createQuote.totalPages
                          ? (
                            <div className={styles.actions}>
                              <Button className={styles.navigationBtn} size="large" onClick={onPrevClick}>
                                <LeftOutlined type="left" />
                                back
                              </Button>
                              <div className={styles.draftAndPostContainer}>
                                <Button className={styles.saveAsDraft} size="large" type="primary" ghost onClick={onSaveAsDraft}>
                                  save draft
                                  <SaveOutlined type="save" />
                                </Button>
                                <Button className={styles.postButton} size="large" type="primary" onClick={onPostClick}>
                                  POST
                                  <UploadOutlined type="upload" />
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className={styles.actions}>
                              <Button className={styles.navigationBtn} size="large" onClick={onPrevClick}>
                                <LeftOutlined type="left" />
                                back
                              </Button>
                              <Button className={`${styles.nextButton}`} size="large" type="primary" onClick={onNextClick}>
                                next
                                <RightOutlined type="right" />
                              </Button>
                            </div>
                          )
                      }
                </div>
                )
              }
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default CreateQuoteScreen;
