import React from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import renderHTML from 'react-render-html';
import { Post } from '../../models/Post';
import styles from './WiltFullPost.module.scss';

interface WiltFullPostProps {
  post: Post;
  likePost: Function;
  unlikePost: Function;
  reactionIds: any;
}

function WiltFullPost(props: WiltFullPostProps) {
  const { post } = props;
  const history = useHistory();

  const onMediumClick = () => {
    history.push(`/mediums/${post.medium.external.id}/${post.medium.typeValue}`);
  };

  const onUserClick = () => {
    history.push(`/profile/${post.user.username}`);
  };

  return (
    <div className={styles.WiltFullPost}>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>
            {post.title}
          </div>
          <div className={styles.headerInfo}>
            <div className={styles.user}>
              <img
                className={styles.userImage}
                src={post.user.image}
                alt={post.user.image}
                onClick={onUserClick}
              />
              <div role="button" tabIndex={0} className={styles.userInfo} onClick={onUserClick}>
                <div className={styles.username}>
                  {post.user.username}
                </div>
                <div className={styles.timestamp}>
                  {moment(post.createdAt).format('LLL')}
                </div>
              </div>
              <img
                className={styles.mediumImage}
                src={post.medium.imageUrl.replace('http://', 'https://')}
                alt={post.medium.imageUrl.replace('http://', 'https://')}
                onClick={onMediumClick}
              />
            </div>
            <div className={styles.medium}>
              <div className={styles.mediumSubtitle}>
                <span className={styles.wiltLabel}>wilt</span>
                <span className={styles.wiltCount}>{post.wilt.length}</span>
                things
                {' '}
                {post.user.displayName}
                {' '}
                has learnt today from
                {' '}
                <span role="button" tabIndex={0} className={styles.mediumSourceTitle} onClick={onMediumClick}>{post.medium.title}</span>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.timeline}>
          {
                        post.wilt.map((val: any, index: number) => (
                          <div
                            key={`wilt-item${index}`}
                            className={styles.wiltItem}
                          >
                            <div className={styles.wiltContainer}>
                              <div className={styles.index}>
                                NO.
                                {' '}
                                {index + 1}
                              </div>
                              <div className={styles.wiltContent}>
                                <h2 className={styles.wiltTitle}>
                                  {val.title}
                                </h2>
                                <div className={styles.wiltBody}>
                                  {
                                    renderHTML(val.body)
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                    }
        </div>
      </div>
    </div>
  );
}

export default WiltFullPost;
