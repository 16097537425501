import { ActionTypes } from '../actions/types';
import { Medium } from '../models/Medium';
import { Wishlists } from '../models/Wishlists';

interface setWishlistBooks {
  type: typeof ActionTypes.SET_WISHLIST_BOOKS;
  value: Medium[];
}

interface setWishlistSeries {
  type: typeof ActionTypes.SET_WISHLIST_SERIES;
  value: Medium[];
}

interface setWishlistMovies {
  type: typeof ActionTypes.SET_WISHLIST_MOVIES;
  value: Medium[];
}

interface setWishlistPodcasts {
  type: typeof ActionTypes.SET_WISHLIST_PODCASTS;
  value: Medium[];
}

interface removeFromWishlist {
  type: typeof ActionTypes.REMOVE_FROM_WISHLIST;
  mediumId: string;
}

type wishlistActions = setWishlistBooks | setWishlistSeries
| setWishlistMovies | setWishlistPodcasts | removeFromWishlist;

const defaultWishList:Wishlists = {
  books: [],
  series: [],
  movies: [],
  podcasts: [],
};

export const wishlists = (state = defaultWishList, action: wishlistActions) => {
  if (action.type === ActionTypes.SET_WISHLIST_BOOKS) {
    if (state) {
      return { ...state, books: action.value };
    }
    return state;
  } if (action.type === ActionTypes.SET_WISHLIST_SERIES) {
    if (state) {
      return { ...state, series: action.value };
    }
    return state;
  } if (action.type === ActionTypes.SET_WISHLIST_MOVIES) {
    if (state) {
      return { ...state, movies: action.value };
    }
    return state;
  } if (action.type === ActionTypes.SET_WISHLIST_PODCASTS) {
    if (state) {
      return { ...state, podcasts: action.value };
    }
    return state;
  }
  if (action.type === ActionTypes.REMOVE_FROM_WISHLIST) {
    if (state) {
      return {
        ...state,
        books: [...state.books.filter((x:any) => x.medium._id !== action.mediumId)],
        series: [...state.series.filter((x:any) => x.medium._id !== action.mediumId)],
        movies: [...state.movies.filter((x:any) => x.medium._id !== action.mediumId)],
        podcasts: [...state.podcasts.filter((x:any) => x.medium._id !== action.mediumId)],
      };
    }
    return state;
  }
  return state;
};
