import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import styles from './QuizPostCard.module.scss';
import { Post } from '../../../models/Post';
import PostHeader from '../PostHeader/PostHeader';
import PostDivider from '../PostDivider/PostDivider';
import PostFooter from '../PostFooter/PostFooter';
import PostSource from '../PostSource/PostSource';
import QuizModal from '../QuizModal/QuizModal';
import { StoreState } from '../../../reducers/_RootReducers';
import { ActionTypes } from '../../../actions/types';
import {createNewQuiz} from "../../../services-api/quizService";
import {BarChartOutlined, RiseOutlined } from '@ant-design/icons';

interface QuizPostCardProps {
  post: Post;
  likePost: Function;
  unlikePost: Function;
  reactionIds: any;
  shelfIds: { [key: string]: string }
}

function QuizPostCard(props: QuizPostCardProps) {
  const {
    reactionIds, post, likePost, unlikePost, shelfIds,
  } = props;
  const {
    user, medium, createdAt, title, tags, hasSpoilers,
  } = post;
  const currentId = useSelector<StoreState, string>(state => state.currentId);
  const isLoading = useSelector<StoreState, boolean>(state => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const onMediumClick = () => {
    history.push(`/mediums/${medium.external.id}/${medium.typeValue}`);
  };

  const onStartQuizStart = () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: post?._id });
      dispatch(createNewQuiz({ post: post._id }, () => {
          dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
          dispatch({ type: ActionTypes.SET_CURRENT_QUIZ, value: post });
      }));
  };

  const getMediumScore = (scoreCount: { [key: string]: number }): number => {
      return scoreCount ? Number(Object.keys(scoreCount).reduce((a, b) => scoreCount[a] > scoreCount[b] ? a : b)): 0;
  }

  return (
    <div className={styles.QuizPostCard}>
      <PostHeader user={user} post={post} createdAt={createdAt} />
      <PostDivider padding="0px" />
      <div className={styles.quizContent}>
        <span className={styles.quizLabel}>
          Quiz
        </span>
        <div className={styles.quizTitle}>
          {title}
        </div>
        <div className={styles.stats}>
            <div className={styles.statContainer}>
                <RiseOutlined className={styles.icon} />
                <div className={styles.value}>
                    {post.quizzesCount}
                </div>
            </div>
            <div className={styles.statContainer}>
                <BarChartOutlined className={styles.barIcon} />
                <div className={styles.value}>
                    {Math.floor((getMediumScore(post.scoreCount)/post.questions.length)*100)}%
                </div>
            </div>
        </div>
        <Button
          className={styles.startQuizButton}
          size="large"
          type="primary"
          onClick={onStartQuizStart}
          loading={post?._id === currentId && isLoading}
        >
          START QUIZ
        </Button>
      </div>
      <PostSource medium={medium} shelfIds={shelfIds} onMediumClick={onMediumClick} />
      <PostDivider padding="12px" />
      <PostFooter
        createdAt={createdAt}
        post={post}
        reactionIds={reactionIds}
        likePost={likePost}
        unlikePost={unlikePost}
      />
    </div>
  );
}

export default QuizPostCard;