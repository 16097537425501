import React from 'react';
import { Skeleton } from 'antd';
import styles from './UserCardSkeleton.module.scss';

function UserCardSkeleton() {
  return (
    <div className={styles.UserCardSkeleton}>
      <Skeleton avatar active paragraph={{ rows: 1 }} />
    </div>
  );
}

export default UserCardSkeleton;
