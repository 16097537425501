import { ActionTypes } from '../actions/types';
import {ThumbnailStyles} from "../models/ThumbnailStyles";

interface setThumbnailStyles {
    type: typeof ActionTypes.SET_THUMBNAIL_STYLES;
    value: ThumbnailStyles;
}

interface setIsEditingModalOpen {
    type: typeof ActionTypes.SET_EDITING_MODAL_OPEN;
    value: boolean;
}

interface setIsPreviewModalOpen {
    type: typeof ActionTypes.SET_THUMBNAIL_PREVIEW_MODAL_VISIBILITY;
    value: boolean;
}

type thumbnailActions = setThumbnailStyles | setIsEditingModalOpen | setIsPreviewModalOpen;

const defaultThumbnail: ThumbnailStyles | null = {
    isDarkTheme: false
}

export const thumbnailStyles = (state: ThumbnailStyles | null = defaultThumbnail, action: thumbnailActions) => {
    if (action.type === ActionTypes.SET_THUMBNAIL_STYLES) {
        return action.value;
    }
    return state;
};

export const isEditingModalOpen = (state: boolean = false, action: thumbnailActions) => {
    if (action.type === ActionTypes.SET_EDITING_MODAL_OPEN) {
        return action.value;
    }
    return state;
};

export const isThumbnailPreviewModalOpen = (state: boolean = false, action: thumbnailActions) => {
    if (action.type === ActionTypes.SET_THUMBNAIL_PREVIEW_MODAL_VISIBILITY) {
        return action.value;
    }
    return state;
};