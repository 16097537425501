import React from 'react';
import { Button } from 'antd';
import styles from './podcasts-grid-card.module.scss';

type PodcastsGridCardProps = {
  item: any;
  isEditing: boolean;
  onMediumClick: (medium: any) => void;
  tempOnRemoveMediumClick: (medium: any) => void;
};

function PodcastsGridCard(props: PodcastsGridCardProps) {
  const {
    item, isEditing, onMediumClick, tempOnRemoveMediumClick,
  } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      key={item?._id}
      className={styles.PodcastsGridCard}
      style={{ overflow: 'hidden' }}
      onClick={() => onMediumClick(item)}
    >
      <img
        style={{ filter: 'blur(3px)', overflow: 'hidden' }}
        className={styles.postcontainer}
        src={item.mediumImage?.replace('http://', 'https://')}
        alt={item.mediumImage?.replace('http://', 'https://')}
      />
      <img
        className={styles.image}
        src={item.mediumImage}
        alt={item.mediumImage}
      />
      {
              isEditing
              && <div className={styles.mediumBackground} />
          }
      {
              isEditing
              && (
              <div className={styles.removeContainer}>
                <Button
                  className={styles.removeButton}
                  onClick={() => tempOnRemoveMediumClick(item)}
                >
                  Remove
                </Button>
              </div>
              )
          }
    </div>
  );
}

export default PodcastsGridCard;
