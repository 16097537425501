import React from 'react';
import { Button } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './post-grid-card.module.scss';
import { Post } from '../../../../models/Post';
import WiltTitlePreview from '../../../../components/WiltTitlePreview/WiltTitlePreview';
import SummaryPreview from '../../../../components/SummaryPreview/SummaryPreview';
import RatingItem from '../../../../components/RatingItem/RatingItem';
import whitebg from '../../../../assets/images/whitebg.svg';
import darkbg from '../../../../assets/images/darkbg.svg';
import RatingItemPodcast from '../../../../components/RatingItemPodcast/RatingItemPodcast';

type PostGridCardProps = {
  onPostClick: (item: any) => void;
  item: any;
  isEditing: boolean;
  onRemovePostClick: (item: any) => void;
};

function PostGridCard(props: PostGridCardProps) {
  const {
    onPostClick, item, isEditing, onRemovePostClick,
  } = props;

  const displayCorrectPreviewCard = (item: Post) => {
    switch (item.typeValue) {
      case 0:
        return;
      case 1:
        return <WiltTitlePreview item={item} type="wilt" />;
      case 2:
        return <SummaryPreview item={item} />;
      case 3:
        if (item.medium.typeValue === 4) {
          return <RatingItemPodcast item={item} />;
        }
        return <RatingItem item={item} />;
      case 4:
        return <WiltTitlePreview item={item} type="quiz" />;
      default:
    }
  };

  return (
    <div className={styles.PostGridCard} onClick={() => onPostClick(item)}>
      {
        item.typeValue === 0
          ? (
            <LazyLoadImage
              effect="opacity"
              className={styles.post}
              src={item.image?.replace('http://', 'https://')}
              alt={item.image?.replace('http://', 'https://')}
              placeholderSrc={require('../../../../assets/images/ph-rect.webp')}
            />
          ) : (
            <img
              className={styles.post}
              src={localStorage.getItem('goodlores-theme') === "dark" ? darkbg : whitebg}
              alt={localStorage.getItem('goodlores-theme') === "dark" ? darkbg : whitebg}
            />
          )
      }
      {
            displayCorrectPreviewCard(item)
      }
      {
            isEditing
            && <div className={styles.mediumBackground} />
      }
      {
            isEditing
            && (
            <div className={styles.removeContainer}>
              <Button
                className={styles.removeButton}
                onClick={() => onRemovePostClick(item)}
              >
                Remove
              </Button>
            </div>
            )
      }
    </div>
  );
}

export default PostGridCard;
