import React from 'react';
import { PlusCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import styles from './UserGroupCard.module.scss';
import {fallBackUrl} from "../../../utils/imageData";
import {Image} from "antd";

type UserGroupCardProps = {
  onUserClick: (user: any) => void;
  user: any;
  selected: boolean;
};

function UserGroupCard(props: UserGroupCardProps) {
  const { onUserClick, user, selected } = props;

  return (
    <div className={styles.UserGroupCard} onClick={() => onUserClick(user)}>
        <Image
            className={styles.image}
            src={user.image}
            alt={user.image}
            preview={false}
            fallback={fallBackUrl}
        />
      <div className={styles.textContainer}>
        <div className={styles.displayName}>
          {user.displayName}
        </div>
        <div className={styles.username}>
          {user.username}
        </div>
      </div>
      {
            selected
              ? <CheckCircleFilled className={styles.selectedIcon} />
              : <PlusCircleOutlined className={styles.unSelectedIcon} />
      }
    </div>
  );
}

export default UserGroupCard;
