import React from 'react';
import styles from './PostDivider.module.scss';

interface PostDividerProps {
  padding: string;
}

function PostDivider(props: PostDividerProps) {
  const { padding } = props;
  return (
    <div className={styles.PostDivider} style={{ paddingLeft: padding, paddingRight: padding }}>
      <div className={styles.divider} />
    </div>
  );
}

export default PostDivider;
