import React from 'react';
import { DeleteFilled } from '@ant-design/icons';
import styles from './UserGroupNameCard.module.scss';

type UserGroupCardProps = {
  onDeleteClick: (user: any) => void;
  user: any;
};

function UserGroupNameCard(props: UserGroupCardProps) {
  const { onDeleteClick, user } = props;

  return (
    <div className={styles.UserGroupCard}>
      <img className={styles.image} src={user.image} alt={user.image} />
      <div className={styles.textContainer}>
        <div className={styles.displayName}>
          {user.displayName}
        </div>
        <div className={styles.username}>
          {user.username}
        </div>
      </div>
      <DeleteFilled className={styles.removeIcon} onClick={() => onDeleteClick(user)} />
    </div>
  );
}

export default UserGroupNameCard;
