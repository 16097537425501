import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './topic-tab.module.scss';

type StandTabProps = {
  name: string;
};

function TopicTab(props: StandTabProps) {
  const { name } = props;
  const location = useLocation();

  const isCurrentLocation = () => {
    const urlTexts = location.pathname.split('/');
    //return urlTexts.find((x) => x === icon);
  };

  return (
    <div className={styles.TopicTab}>
      <div className={styles.title}>
        {name}
      </div>
    </div>
  );
}

export default TopicTab;
