import { ActionTypes } from '../actions/types';
import { User } from '../models/User';
import { shallowCopy } from '../helpers/helpers';
import { Medium } from '../models/Medium';

interface setUser {
  type: typeof ActionTypes.SET_CURRENT_USER;
  user: User;
}

interface clearNotifications {
  type: typeof ActionTypes.CLEAR_NOTIFICATIONS;
}

interface setMyMediums {
  type: typeof ActionTypes.SET_MY_MEDIUMS;
  mediums: Medium[];
}

interface setReactionIds {
  type: typeof ActionTypes.SET_REACTION_IDS;
  value: any;
}

interface setShelfIds {
  type: typeof ActionTypes.SET_SHELF_IDS;
  value: any;
}

interface setFollowingUserIds {
  type: typeof ActionTypes.SET_FOLLOWING_USER_IDS;
  value: any;
}

interface setFollowRequestUserIds {
  type: typeof ActionTypes.SET_FOLLOW_REQUEST_USER_IDS;
  value: any;
}

interface setRequestsCount {
  type: typeof ActionTypes.SET_REQUESTS_COUNT;
  value: number;
}

interface setUnreadChatsCount {
  type: typeof ActionTypes.SET_UNREAD_CHATS_COUNT;
  value: number;
}

type usersActions = setUser | clearNotifications | setMyMediums | setReactionIds | setShelfIds
| setFollowingUserIds | setFollowRequestUserIds | setRequestsCount | setUnreadChatsCount;

export const currentUser = (state = null, action: usersActions) => {
  if (action.type === ActionTypes.SET_CURRENT_USER) {
    return action.user;
  } if (action.type === ActionTypes.CLEAR_NOTIFICATIONS) {
    const user = shallowCopy(state) as User;
    return { ...user, notificationCount: 0 };
  }
  return state;
};

export const myMediums = (state: Medium[] = [], action: usersActions) => {
  if (action.type === ActionTypes.SET_MY_MEDIUMS) {
    return action.mediums;
  }
  return state;
};

export const reactionIds = (state: any = {}, action: usersActions) => {
  if (action.type === ActionTypes.SET_REACTION_IDS) {
    return action.value;
  }
  return state;
};

export const shelfIds = (state: any = {}, action: usersActions) => {
  if (action.type === ActionTypes.SET_SHELF_IDS) {
    return action.value;
  }
  return state;
};

export const followingUserIds = (state: any = {}, action: usersActions) => {
  if (action.type === ActionTypes.SET_FOLLOWING_USER_IDS) {
    return action.value;
  }
  return state;
};

export const followRequestUserIds = (state: any = {}, action: usersActions) => {
  if (action.type === ActionTypes.SET_FOLLOW_REQUEST_USER_IDS) {
    return action.value;
  }
  return state;
};

export const requestsCount = (state: number = 0, action: usersActions) => {
  if (action.type === ActionTypes.SET_REQUESTS_COUNT) {
    return action.value;
  }
  return state;
};

export const unreadChats = (state: number = 0, action: usersActions) => {
  if (action.type === ActionTypes.SET_UNREAD_CHATS_COUNT) {
    return action.value;
  }
  return state;
};
