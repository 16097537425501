import React from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import renderHTML from 'react-render-html';
import styles from './SummaryFullPost.module.scss';
import { Post } from '../../models/Post';

interface SummaryFullPostProps {
  post: Post;
  likePost: Function;
  unlikePost: Function;
  reactionIds: any;
}
function SummaryFullPost(props: SummaryFullPostProps) {
  const {
    post,
  } = props;

  const history = useHistory();

  const onMediumClick = () => {
    history.push(`/mediums/${post.medium.external.id}/${post.medium.typeValue}`);
  };

  const onUserClick = () => {
    history.push(`/profile/${post.user.username}`);
  };

  return (
    <div className={styles.SummaryFullPost}>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>
            {post.title}
          </div>
          <div>
            <span className={styles.summaryLabel}>summary</span>
          </div>
          <div className={styles.headerInfo}>
            <div className={styles.user}>
              <img
                className={styles.userImage}
                src={post.user.image}
                alt={post.user.image}
                onClick={onUserClick}
              />
              <div role="button" tabIndex={0} className={styles.userInfo} onClick={onUserClick}>
                <div className={styles.username}>
                  {post.user.username}
                </div>
                <div className={styles.timestamp}>
                  {moment(post.createdAt).format('LLL')}
                </div>
              </div>
              <img
                className={styles.mediumImage}
                src={post.medium.imageUrl.replace('http://', 'https://')}
                alt={post.medium.imageUrl.replace('http://', 'https://')}
                onClick={onMediumClick}
              />
            </div>
            <div className={styles.medium} />
          </div>
        </div>
      </div>
      <div className={styles.body}>
        {
                    renderHTML(post.summary)
                }
      </div>
    </div>
  );
}

export default SummaryFullPost;
