import React, { useEffect, useState } from 'react';
import {
  Route, Redirect, useHistory, useLocation,
} from 'react-router-dom';
import { Affix } from 'antd';
import cookies from 'js-cookie';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import { useQueryCache, useQuery } from 'react-query';
import styles from './App.module.scss';
import LandingScreen from '../../screens/LandingScreen/LandingScreen';
import HomeScreen from '../../screens/HomeScreen/HomeScreen';
import MediumScreen from '../../screens/medium-screen/medium-screen';
import NavBar from '../NavBar/NavBar';
import ProfileScreen from '../../screens/profile-screen/profile-screen';
import SearchScreen from '../../screens/SearchScreen/SearchScreen';
import NotificationScreen from '../../screens/NotificationScreen/NotificationScreen';
import EditProfileScreen from '../../screens/EditProfileScreen/EditProfileScreen';
import UsersScreen from '../../screens/UsersScreen/UsersScreen';
import BottomNavBar from '../BottomNavBar/BottomNavBar';
import PostScreen from '../../screens/post-screen/post-screen';
import CreateScreen from '../../screens/create-screen/create-screen';
import WishListScreen from '../../screens/WishListScreen/WishListScreen';
import MobileMenu from '../MobileMenu/MobileMenu';
import DraftsScreen from '../../screens/DraftsScreen/DraftsScreen';
import DraftScreen from '../../screens/DraftScreen/DraftScreen';
import CreateQuoteScreen from '../../screens/create-quote-screen/create-quote-screen';
import CreateWiltScreen from '../../screens/create-wilt-screen/CreateWiltScreen';
import CreateSummaryScreen from '../../screens/create-summary-screen/CreateSummaryScreen';
import TopMediumScreen from '../../screens/TopMediumScreen/TopMediumScreen';
import MediumEpisodesScreen from '../../screens/MediumEpisodesScreen/MediumEpisodesScreen';
import RequestsScreen from '../../screens/RequestsScreen/RequestsScreen';
import MessagesScreen from '../../screens/MessagesScreen/MessagesScreen';
import ChatScreen from '../../screens/ChatScreen/chat-screen';
import NewChatScreen from '../../screens/NewChatScreen/new-chat-screen';
import NewGroupUsersScreen from '../../screens/NewGroupUsersScreen/new-group-users-screen';
import NewGroupNameScreen from '../../screens/NewGroupNameScreen/new-group-name-screen';
import ChatGroupScreen from '../../screens/ChatGroupScreen/chat-group-screen';
import { ActionTypes } from '../../actions/types';
import ClubsScreen from '../../screens/clubs-screen/clubs-screen';
import ClubScreen from '../../screens/club-screen/club-screen';
import ClubsCreateScreen from '../../screens/clubs-create-screen/clubs-create-screen';
import ClubsEditScreen from '../../screens/clubs-edit-screen/clubs-edit-screen';
import ClubAboutScreen from '../../screens/club-about-screen/club-about-screen';
import ClubMembersScreen from '../../screens/club-members-screen/club-members-screen';
import ClubDiscussionsScreen from '../../screens/club-discussions-screen/club-discussions-screen';
import ClubStoriesScreen from '../../screens/club-stories-screen/club-stories-screen';
import CreateTemplateScreen from '../../screens/create-template-screen/create-template-screen';
import StorynotesScreen from '../../screens/storynotes-screen/storynotes-screen';
import StorynotesCreateScreen from '../../screens/storynotes-create-screen/storynotes-create-screen';
import StorynoteScreen from '../../screens/storynote-screen/storynote-screen';
import StorynotesNotesScreen from '../../screens/storynotes-notes-screen/storynotes-notes-screen';
import StorynotesNotesCreateScreen from '../../screens/storynotes-notes-create-screen/storynotes-notes-create-screen';
import StorynotesNoteScreen from '../../screens/storynotes-note-screen/storynotes-note-screen';
import SportEventScreen from '../../screens/sport-event-screen/sport-event-screen';
import StadiumScreen from '../../screens/stadium-screen/stadium-screen';
import StandsScreen from '../../screens/stands-screen/stands-screen';
import StandsPostScreen from '../../screens/stands-post-screen/stands-post-screen';
import ClubDiscussionScreen from '../../screens/club-discussion-screen/club-discussion-screen';
import ClubDiscussionCreateScreen from '../../screens/club-discussion-create-screen/club-discussion-create-screen';
import ClubStoriesCreateScreen from '../../screens/club-stories-create-screen/club-stories-create-screen';
import ClubStoryScreen from '../../screens/club-story-screen/club-story-screen';
import ClubStoryNotes from '../../screens/club-story-notes/club-story-notes';
import RemindersScreen from '../../screens/reminders-screen/reminders-screen';
import CreateQuizScreen from '../../screens/create-quiz-screen/create-quiz-screen';
import QuizScreen from "../../screens/quiz-screen/quiz-screen";
import Menu from "../Menu/Menu";
import SideContent from "../../screens/HomeScreen/side-content/side-content";
import ClubStoryCreateNoteScreen from "../../screens/club-story-create-note-screen/club-story-create-note-screen";
import CreateList from "../../screens/create-list/create-list";
import ThumbNailScreen from "../../screens/thumb-nail-screen/thumb-nail-screen";
import StadiumDraft from "../../screens/stadium-draft/stadium-draft";

const App: React.FC = () => {
  const cookieExist = cookies.get('goodlores-token');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryCache = useQueryCache();

  const { data } = useQuery('current-user', () => cookieExist && axios.get('/api/users/current/user')
    .then((response) => {
      if (cookieExist) {
        dispatch({ type: ActionTypes.SET_CURRENT_USER, user: response.data });
        if (response.data && response.data.setupInterests && (response.data.setupInterests.complete === true || response.data.setupInterests.skip === true)) {
          dispatch({ type: ActionTypes.SET_INTEREST_MODAL_VISIBILITY, value: false });
        } else {
          dispatch({ type: ActionTypes.SET_INTEREST_MODAL_VISIBILITY, value: true });
        }

        const socketUrl = `${response.config.baseURL || 'https://api.goodlores.com/'}${response.data._id}`;
        const socket = socketIOClient(socketUrl);

        socket.on('message', (data: any) => {
          const arrQueryIds = queryCache.getQueries().map((val) => `${val.queryKey[0]}`);
          const queryIds = arrQueryIds.reduce(
            (obj, item) => Object.assign(obj, { [item]: item }), {},
          );

          queryCache.getQueries().filter((val, index) => {
            if (`${val.queryKey[0]}`.includes('messages')) {
              queryCache.invalidateQueries(val.queryKey[0]);
            }

            if (`${val.queryKey[0]}`.includes('chat') && `${val.queryKey[0]}`.includes(`${data.user}`)) {
              queryCache.invalidateQueries(val.queryKey[0]);
            }

            if (`${val.queryKey[0]}`.includes('chat-group-messages') && `${val.queryKey[0]}`.includes(`${data.chatRoom}`)) {
              queryCache.invalidateQueries(val.queryKey[0]);
            }

            return `${val.queryKey[0]}`.includes('messages');
          });
        });
      }

      return response.data;
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        cookies.remove('goodlores-token');
        window.location.reload();
      }
    }));

  // useQuery('getUnreadChatRooms', () => axios.get('/api/chats/unread/rooms')
  //   .then((response) => {
  //     dispatch({
  //       type: ActionTypes.SET_UNREAD_CHATS_COUNT,
  //       value: parseInt(response.data.count, 0),
  //     });
  //     return response.data;
  //   }));

  const [width, setWidth] = useState(window.innerWidth);

  const update = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', update);
  }, []);

  const showMoreContent = () => {
    return ["home","notifications"].includes(`${location.pathname.split('/')[1]}`);
  }

  const dontShowMenu = () => {
    return !["profile","wishlist","posts","search","create"].includes(`${location.pathname.split('/')[1]}`);
  }

  return (
    <div className={styles.App}>
      {
            !cookieExist
              ? (
                <div className={styles.appBody}>
                  {/* <Route path="/" render={() => <Redirect to="/" />} /> */}
                  <Route
                    exact
                    path="/"
                    render={() => (<LandingScreen />)}
                  />
                  <Route
                    path="/token/:token"
                    render={(props) => {
                      // eslint-disable-next-line react/prop-types
                      if (props.match.params.token) {
                        // eslint-disable-next-line react/prop-types
                        cookies.set('goodlores-token', props.match.params.token, { expires: 7 });
                        history.replace('/');
                        window.location.reload();
                      }
                      return (<LandingScreen />);
                    }}
                  />
                  <Route
                    exact
                    path="/user-exists"
                    render={() => (<LandingScreen userExists />)}
                  />
                  <Route
                    exact
                    path="/not-found"
                    render={() => (<LandingScreen notFound />)}
                  />
                  <Route path="/:type" render={(props) => {
                    if (['movies','podcasts','books','series'].includes(props.match.params.type)) {
                      return (<LandingScreen />);
                    }
                    return <Redirect to="/" />
                  }} />
                </div>
              )
              : (
                <div className={styles.appBody}>
                  <Affix offsetTop={0}>
                    <NavBar />
                  </Affix>
                  <MobileMenu />
                  <div className={styles.mainContainer}>
                    {
                      dontShowMenu() &&
                      <div className={styles.menuMainContainer}>
                        <Affix className={styles.leftContainer} offsetTop={80}>
                          <Menu />
                        </Affix>
                      </div>
                    }
                    <div className={styles.contentMainContainer}>
                      <div className={styles.centerMainContainer}>
                        <Route exact path="/" render={() => <Redirect to="/home" />} />
                        <Route
                            path="/home"
                            render={() => (<HomeScreen />)}
                        />
                        <Route
                            exact
                            path="/create"
                            render={() => (<CreateScreen />)}
                        />
                        <Route
                            path="/create/quote"
                            render={() => (<CreateQuoteScreen />)}
                        />
                        <Route
                            path="/create/wilt"
                            render={() => (<CreateWiltScreen />)}
                        />
                        <Route
                            path="/create/quiz"
                            render={() => (<CreateQuizScreen />)}
                        />
                        <Route
                            path="/create/list"
                            render={() => (<CreateList />)}
                        />
                        <Route
                            path="/create/summary"
                            render={() => (<CreateSummaryScreen />)}
                        />
                        <Route
                            path="/create/template"
                            render={() => (<CreateTemplateScreen />)}
                        />
                        <Route
                            path="/mediums/:id/:type"
                            exact
                            render={() => (<MediumScreen />)}
                        />
                        <Route
                            path="/mediums/:id/:type/:tab"
                            render={() => (<MediumScreen />)}
                        />
                        <Route
                            path="/episodes/:episodeId"
                            render={() => (<MediumEpisodesScreen />)}
                        />
                        <Route
                            path="/drafts/:id/:type"
                            render={() => (<DraftScreen />)}
                        />
                        <Route
                            exact
                            path="/drafts"
                            render={() => (<DraftsScreen />)}
                        />
                        <Route
                            path="/createclubs"
                            render={() => (<ClubsCreateScreen />)}
                        />
                        <Route
                            path="/quizzes/:id"
                            render={() => (<QuizScreen />)}
                        />
                        <Route exact path="/storyclubs" render={() => <Redirect to="/storyclubs/clubs" />} />
                        <Route
                            exact
                            path="/storyclubs/:id"
                            render={(props) => {
                              if (props.match.params.id === 'clubs') {
                                return (<ClubsScreen />);
                              }
                              if (props.match.params.id === 'activity') {
                                return (<ClubsScreen />);
                              }
                              return <ClubScreen />
                            }}
                        />
                        <Route
                            path="/storyclubs/:id/edit"
                            render={() => (<ClubsEditScreen />)}
                        />
                        <Route
                            path="/storyclubs/:id/about"
                            render={() => (<ClubAboutScreen />)}
                        />
                        <Route
                            path="/storyclubs/:id/members"
                            render={() => (<ClubMembersScreen />)}
                        />
                        <Route
                            path="/stadium"
                            render={() => (<StadiumDraft />)}
                        />
                        <Route
                            exact
                            path="/storyclubs/:id/discussions"
                            render={() => (<ClubDiscussionsScreen />)}
                        />
                        <Route
                            exact
                            path="/storyclubs/:id/discussions/:discussionId"
                            render={(props) => {
                              if (props.match.params.discussionId === 'create') {
                                return (<ClubDiscussionCreateScreen />);
                              }
                              if (props.match.params.discussionId === 'all') {
                                return (<ClubDiscussionsScreen />);
                              }
                              if (props.match.params.discussionId === 'your') {
                                return (<ClubDiscussionsScreen />);
                              }
                              return (<ClubDiscussionScreen />);
                            }}
                        />
                        <Route
                            exact
                            path="/storyclubs/:id/stories"
                            render={() => (<ClubStoriesScreen />)}
                        />
                        <Route
                            exact
                            path="/storyclubs/:id/stories/:storiesId"
                            render={(props) => {
                              if (props.match.params.storiesId === 'create') {
                                return (<ClubStoriesCreateScreen />);
                              }
                              if (props.match.params.storiesId === 'all') {
                                return (<ClubStoriesScreen />);
                              }
                              if (props.match.params.storiesId === 'your') {
                                return (<ClubStoriesScreen />);
                              }
                              return (<ClubStoryScreen />);
                            }}
                        />
                        <Route
                            exact
                            path="/storyclubs/:id/stories/:storiesId/:sectionId"
                            render={(props) => {
                              if (props.match.params.sectionId === 'edit') {
                                return (<ClubStoriesCreateScreen />);
                              }
                              return <ClubStoryNotes />;
                            }}
                        />
                        <Route
                            exact
                            path="/storyclubs/:id/stories/:storiesId/:sectionId/note"
                            render={(props) => {
                              return <ClubStoryCreateNoteScreen />;
                            }}
                        />
                        <Route
                            path="/stadiums/:id/:tab"
                            render={() => (<StadiumScreen />)}
                        />
                        <Route
                            path="/stands/:id"
                            render={() => (<StandsScreen />)}
                        />
                        <Route
                            path="/posts/:id"
                            render={() => (<PostScreen />)}
                        />
                        <Route
                            exact
                            path="/profile/:username"
                            render={() => (<ProfileScreen />)}
                        />
                        <Route
                            path="/profile/:username/:tab"
                            render={() => (<ProfileScreen />)}
                        />
                        <Route
                            path="/top/:mediumType/:username"
                            render={() => (<TopMediumScreen />)}
                        />
                        <Route exact path="/search/" render={() => <Redirect to="/search/users" />} />
                        <Route
                            path="/search/:tab"
                            render={() => (<SearchScreen />)}
                        />
                        <Route
                            path="/notifications"
                            render={() => (<NotificationScreen />)}
                        />
                        <Route
                            path="/messages"
                            render={() => (<MessagesScreen />)}
                        />
                        <Route
                            path="/chats/:id/:info"
                            render={() => (<ChatScreen />)}
                        />
                        <Route
                            exact
                            path="/chats/:id"
                            render={() => (<ChatScreen />)}
                        />
                        <Route
                            path="/group/chats/:id/:info"
                            render={() => (<ChatGroupScreen />)}
                        />
                        <Route
                            exact
                            path="/group/chats/:id"
                            render={() => (<ChatGroupScreen />)}
                        />
                        <Route
                            path="/newchat"
                            render={() => (<NewChatScreen />)}
                        />
                        <Route
                            path="/newgroupchatname"
                            render={() => (<NewGroupNameScreen />)}
                        />
                        <Route
                            path="/newgroupchatusers/:chatRoom"
                            render={() => (<NewGroupUsersScreen />)}
                        />
                        <Route
                            exact
                            path="/newgroupchatusers"
                            render={() => (<NewGroupUsersScreen />)}
                        />
                        <Route
                            path="/sport/events/:id"
                            render={() => (<SportEventScreen />)}
                        />
                        <Route
                            path="/stands/posts/:id"
                            render={() => (<StandsPostScreen />)}
                        />
                        <Route
                            path="/notes/:id"
                            render={() => (<StorynotesNoteScreen />)}
                        />
                        <Route
                            path="/notes/:notesId/template"
                            render={() => (<MessagesScreen />)}
                        />
                        <Route
                            path="/notes/:notesId/template/section/:sectionId"
                            render={() => (<MessagesScreen />)}
                        />
                        <Route
                            path="/notes/:notesId/template/subsection/:sectionId"
                            render={() => (<MessagesScreen />)}
                        />
                        <Route
                            exact
                            path="/storynotes/:id/:sectionId"
                            render={(props) => {
                              if (props.match.params.sectionId === 'create') {
                                return (<StorynotesNotesCreateScreen />);
                              }
                              return (<StorynotesNotesScreen />);
                            }}
                        />
                        <Route
                            exact
                            path="/storynotes/:id/:sectionId/:noteId"
                            render={(props) => {
                              if (props.match.params.noteId === 'create') {
                                return (<StorynotesNotesCreateScreen />);
                              }
                              return (<StorynotesNotesCreateScreen />);
                            }}
                        />
                        <Route
                            exact
                            path="/storynotes/:id"
                            render={(props) => {
                              if (props.match.params.id === 'create') {
                                return (<StorynotesCreateScreen />);
                              }
                              return (<StorynoteScreen />);
                            }}
                        />
                        <Route
                            exact
                            path="/storynotes"
                            render={() => (<StorynotesScreen />)}
                        />
                        <Route
                            path="/reminders"
                            render={() => (<RemindersScreen />)}
                        />
                        <Route
                            path="/requests"
                            render={() => (<RequestsScreen />)}
                        />
                        <Route
                            path="/editprofile"
                            render={() => (<EditProfileScreen />)}
                        />
                        <Route
                            path="/users/:userId/:type"
                            render={() => (<UsersScreen />)}
                        />
                        <Route exact path="/wishlist/" render={() => <Redirect to="/wishlist/books" />} />
                        <Route
                            path="/wishlist/:tab"
                            render={() => (<WishListScreen />)}
                        />
                        <Route
                            path="/thumbnail/:id"
                            render={() => (<ThumbNailScreen />)}
                        />
                      </div>
                      {
                        showMoreContent() &&
                        <div className={styles.rightMainContainer}>
                          <Affix className={styles.leftContainer} offsetTop={80}>
                            <SideContent/>
                          </Affix>
                        </div>
                      }
                    </div>
                  </div>
                  <Affix style={{ height: width < 1000 && !['chats', 'group', 'newgroupchatusers','create'].includes(location.pathname.split('/')[1]) ? '68px' : '0px' }} offsetBottom={0}>
                    <BottomNavBar />
                  </Affix>
                </div>
              )
        }
    </div>
  );
};

export default App;
