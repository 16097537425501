import React, { useEffect, useState } from 'react';
import styles from './WiltTitlePreview.module.scss';

interface WiltTitlePreviewProps {
  item: any;
  type: string;
}

function WiltTitlePreview(props:WiltTitlePreviewProps) {
  const { item, type } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', update);
  }, []);

  const update = () => {
    setWidth(window.innerWidth);
  };

  const addCorrectStyling = () => {
    if (width < 500) {
      if (item.title.split('').length > 40) {
        return { alignItems: 'flex-start' };
      }
      return { alignItems: 'center' };
    } if (width > 500) {
      if (item.title.split('').length > 500) {
        return { alignItems: 'flex-start' };
      }
      return { alignItems: 'center' };
    }
    return {};
  };

  return (
    <div className={styles.WiltTitlePreview}>
      <div className={styles.wiltHeader}>
        <div className={styles.wiltLabel}>{type}</div>
      </div>
      <div
        className={styles.wiltBody}
        style={addCorrectStyling()}
      >
        {item.title}
      </div>
      <div className={styles.wiltFooter}>
        <img
          className={styles.wiltSourceImage}
          src={item.medium.imageUrl.replace('http://', 'https://')}
          alt={item.medium.imageUrl.replace('http://', 'https://')}
        />
        <div className={styles.wiltSourceTitle}>
          {item.medium.title}
        </div>
      </div>
    </div>
  );
}

export default WiltTitlePreview;
