import React, { useState } from 'react';
import {Button, InputNumber, Modal, Slider} from 'antd';
import { TwitterPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ColorPaletteModal.module.scss';
import { ActionTypes } from '../../../actions/types';
import { StoreState } from '../../../reducers/_RootReducers';
import {lightOrDark} from "../../../helpers/helpers";
import {CreateQuote} from "../../../models/create-models/CreateQuote";

interface ColorPaletteModalProps {
  callback: Function;
  opacityCallback: Function;
  currentUpdate: string;
}

function ColorPaletteModal(props: ColorPaletteModalProps) {
  const { currentUpdate, callback, opacityCallback } = props;
  const [color, setColor] = useState({ id: 'white', color: '#ffffff', rgb: '255,255,255' });
  const createQuote = useSelector<StoreState, CreateQuote>(state => state.createQuote);
  const isColorPaletteModalVisible = useSelector<StoreState, boolean>((state) => state.isColorPaletteModalVisible);
  const dispatch = useDispatch();
  const currentLayer = createQuote?.template?.layers.find((x) => x.type === currentUpdate);

  const handleCancel = () => {
    dispatch({ type: ActionTypes.TOGGLE_COLOR_PALETTE_MODAL });
  };

  const onSaveClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_COLOR_PALETTE_MODAL });
  };

  const handleChangeComplete = (color: any) => {
    if (color && color.hex) {
      const newColor = { id: `${color.hex}`, color: `${color.hex}`, rgb: `${color.rgb.r},${color.rgb.g},${color.rgb.b}` };
      callback(newColor, 1);
      setColor(newColor);
    }
  }

  const styleColor = { color: lightOrDark(color.color) === 'dark' ? '#ffffff': '#000000' };

  const onOpacitySizeChange = (data: any) => {
    opacityCallback(data);
  };

  return (
    <Modal
      className={styles.ColorPaletteModal}
      visible={isColorPaletteModalVisible}
      onCancel={handleCancel}
      bodyStyle={{ padding: '0px' }}
      closable={false}
      centered
      title={null}
      footer={null}
    >
      <div className={styles.ColorPaletteModal}>
        <div className={styles.colorTitle} style={{...styleColor, color: color.color }} >
          Color
        </div>
        <div className={styles.colors}>
          <TwitterPicker
              color={ color.color }
              onChangeComplete={ handleChangeComplete }
          />
        </div>
        {
          currentUpdate === "backgroundText" &&
          <div className={styles.opacityTitle} >
            Opacity
          </div>
        }
        {
          currentUpdate === "backgroundText" &&
          <div className={styles.opacityContainer}>
            <Slider
                className={styles.opacitySlider}
                min={0}
                max={1}
                step={0.1}
                onChange={onOpacitySizeChange}
                defaultValue={currentLayer?.content.opacity}
                value={currentLayer?.content.opacity}
            />
            <InputNumber
                min={0}
                max={1}
                step={0.1}
                style={{ marginLeft: 16 }}
                onChange={onOpacitySizeChange}
                defaultValue={currentLayer?.content.opacity}
                value={currentLayer?.content.opacity}
                size="large"
            />
          </div>
        }
        <div className={styles.actions}>
          <Button type="link" size="large" onClick={onSaveClick}>Done</Button>
        </div>
      </div>
    </Modal>
  );
}

export default ColorPaletteModal;
