import React from 'react';
import styles from './top-post-card.module.scss';
import baylor from '../../../../../assets/images/baylor.png';
import likeIcon from '../../../../../assets/icons/icons8-facebook-like-50.png';
import shareIcon from '../../../../../assets/icons/icons8-forward-arrow-50.png';
import commentIcon from '../../../../../assets/icons/icons8-topic-50.png';
import statLikeIcon from '../../../../../assets/icons/like.png';
import statHeartIcon from '../../../../../assets/icons/heart.png';
import statLolIcon from '../../../../../assets/icons/lol.png';
import elon from '../../../../../assets/images/elon.jpeg';
import statShockedIcon from '../../../../../assets/icons/shocked.png';
import statAngryIcon from '../../../../../assets/icons/angry.png';
import statSadIcon from '../../../../../assets/icons/sad.png';
import Footer from '../footer/footer';
import Header from '../header/header';
import OneUser from '../one-user/one-user';

function TopPostCard() {
  return (
    <div className={styles.TopPostCard}>
      <Header
        image={baylor}
        title="Top Post"
        minute="85th min"
      />
      <OneUser
        image={elon}
        username="elonmusk"
        text="Kobe is better than Lebron. Change my mind..."
      />
      <Footer />
    </div>
  );
}

export default TopPostCard;
