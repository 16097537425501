import { Dispatch } from 'redux';
import axios from 'axios';

export const createStoryNote = (data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/notes/story', data)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const createNote = (data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/notes', data)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateNote = (id: string, data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put(`/api/notes/${id}`, data)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateStoryNote = (id: string, data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put(`/api/notes/story/${id}`, data)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};
