import React from 'react';
import styles from './spin-container-list.module.scss';
import {Spin} from "antd";

function SpinContainerList() {
    return (
        <div className={styles.SpinContainerList}>
            <Spin />
        </div>
    );
}

export default SpinContainerList;