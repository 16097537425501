import { ActionTypes } from '../actions/types';

interface setActiveStoryTab {
  type: typeof ActionTypes.SET_CLUB_STORY_ACTIVE_TAB;
  value: string;
}

type clubActions = setActiveStoryTab;

export const activeStoryTab = (state: string = 'activity', action: clubActions) => {
  if (action.type === ActionTypes.SET_CLUB_STORY_ACTIVE_TAB) {
    return action.value;
  }
  return state;
};
