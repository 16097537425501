import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Spin } from 'antd';
import {
  LeftOutlined, UploadOutlined, RightOutlined,
} from '@ant-design/icons';
import styles from './create-template-screen.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { Medium } from '../../models/Medium';
import { ActionTypes } from '../../actions/types';
import MyMediumList from '../../components/MyMediumList/MyMediumList';
import CreateProgress from '../../components/CreateProgress/CreateProgress';
import TemplateInfo from './template-info/template-info';
import TemplateChapters from './template-chapters/template-chapters';
import { CreateTemplate } from '../../models/create-models/CreateTemplate';
import PreviewTemplate from './preview-template/preview-template';
import { createNewTemplate } from '../../services-api/templateService';
import {User} from "../../models/User";
import {useQueryCache} from "react-query";

function CreateTemplateScreen() {
  const currentUser = useSelector<StoreState, User>(state => state.currentUser);
  const createTemplate = useSelector<StoreState, CreateTemplate>((state) => state.createTemplate);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const onMyMediumClick = (medium: Medium) => {
    if (createTemplate.currentPage < pages.length) {
      const newCreateTemplate = {
        ...createTemplate, medium, isAudio: true,  currentPage: createTemplate.currentPage + 1, totalPages: pages.length, type: 'template',
      };
      dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: newCreateTemplate });
    }
  };

  const pages = [
    {
      page: 1,
      showNavigation: false,
      title: 'select a story',
      content: <MyMediumList onMyMediumClick={onMyMediumClick} />,
    },
    {
      page: 2,
      showNavigation: true,
      title: 'template info',
      content: <TemplateInfo />,
    },
    {
      page: 3,
      showNavigation: true,
      title: 'create template',
      content: <TemplateChapters />,
    },
    {
      page: 4,
      showNavigation: true,
      title: 'preview',
      content: <PreviewTemplate />,
    },
  ];

  const onPrevClick = () => {
    if (createTemplate.currentPage > 1) {
      const newCreateTemplate = {
        ...createTemplate, currentPage: createTemplate.currentPage - 1, totalPages: pages.length, type: 'template',
      };
      dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: newCreateTemplate });
    }
  };

  const onPostClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(createNewTemplate(createTemplate, (snt) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
      dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'create/template' });
      dispatch({ type: ActionTypes.SET_NEWLY_CREATED_TEMPLATE, value: {...snt, user: currentUser } })
      if (createTemplate.redirectUrl) {
        if (createTemplate.invalidateCache) {
          console.log("english: ",createTemplate.invalidateCache);
          console.log("english: ",queryCache.getQueries());
          queryCache.invalidateQueries(createTemplate.invalidateCache).then(() => {
            history.replace(createTemplate.redirectUrl!);
          })
        } else {
          //history.replace(createTemplate.redirectUrl);
        }
      } else {
        history.replace(`/mediums/${createTemplate?.medium?.external?.id}/${createTemplate?.medium?.typeValue}/templates`);
      }
    }));
  };

  const onNextClick = () => {
    if (createTemplate.currentPage < pages.length) {
      const newCreateTemplate = {
        ...createTemplate, currentPage: createTemplate.currentPage + 1, totalPages: pages.length, type: 'template',
      };
      dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: newCreateTemplate });
    }
  };

  const validateTemplate = (template: CreateTemplate) => {
    if (template.currentPage === 2) {
      if (template.title && template.year && template.version && template.length && template.language) {
        return false;
      }
    }

    if (template.currentPage === 3) {
      if (template.sections.length > 0) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className={styles.CreateTemplateScreen}>
      <Spin spinning={isLoading}>
        <div
          className={styles.container}
        >
          <CreateProgress existingId={createTemplate.templateId} current={createTemplate.currentPage} pages={pages} />
          <div>
            {
              pages[createTemplate.currentPage - 1].content
            }
          </div>
          {
            pages[createTemplate.currentPage - 1].showNavigation
            && (
            <div>
              {
                createTemplate.currentPage === createTemplate.totalPages
                  ? (
                    <div className={styles.actions}>
                      <Button className={styles.navigationBtn} size="large" onClick={onPrevClick}>
                        <LeftOutlined type="left" />
                        back
                      </Button>
                      <div>
                        <Button className={styles.postButton} size="large" type="primary" onClick={onPostClick}>
                          POST
                          <UploadOutlined type="upload" />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.actions}>
                      <Button
                        className={styles.navigationBtn}
                        size="large"
                        onClick={onPrevClick}
                      >
                        <LeftOutlined type="left" />
                        back
                      </Button>
                      <Button
                        className={styles.nextButton}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={validateTemplate(createTemplate)}
                        onClick={onNextClick}
                      >
                        next
                        <RightOutlined type="right" />
                      </Button>
                    </div>
                  )
                  }
            </div>
            )
          }
        </div>
      </Spin>
    </div>
  );
}

export default CreateTemplateScreen;
