import React from 'react';
import styles from './side-content.module.scss';
import {useQuery} from "react-query";
import axios from "axios";
import {Post} from "../../../models/Post";
import Snippet from "../snippet/snippet";
import {useHistory} from "react-router-dom";

function SideContent() {

    const history = useHistory();

    const { isFetching: quizIsFetching, error: quizError, data: quizData } = useQuery(`home-screen-quizzes`, () => axios.get(`/api/quiz/popular`)
        .then((response) => {
            console.log('response.data: ', response.data);
            return response.data as Post[];
        }));

    const onActionClick = (post: Post) => {
        history.push(`posts/${post._id}`);
    };

    return (
        <div className={styles.SideContent}>
            {
                quizData ?
                    <div>
                        {
                            quizData.map((val, index) => {
                                return <Snippet
                                    key={`${index}-quiz`}
                                    snippetTitle={"popular quiz"}
                                    mediumTitle={val.title}
                                    mediumImageUrl={val.medium.imageUrl}
                                    actionTitle="View Quiz"
                                    onAction={() => onActionClick(val)}
                                />
                            })
                        }
                    </div>
                    :
                    <Snippet
                        snippetTitle={"popular note"}
                        mediumTitle={"Why we don't want to sleep"}
                        mediumImageUrl="../../../assets/images/got.jpg"
                        actionTitle="View note"
                        onAction={onActionClick}
                    />
            }
        </div>
    );
}

export default SideContent;