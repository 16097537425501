// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import { Medium } from '../models/Medium';
import {StoryNote} from "../models/StoryNote";
import {Topic} from "../models/Topic";

export const shallowCopy = (state: any) => ({
  ...state as {},
});

export const convertTimeToDaysAgo = (time: string) => {
  const now = moment();
  const days = now.diff(moment(time), 'days');
  let displayTime = days;
  let displayTimeExt = 'days ago';
  const hours = now.diff(moment(time), 'hours');
  const minutes = now.diff(moment(time), 'minutes');

  if (days === 0) {
    displayTime = hours;
    displayTimeExt = 'hrs ago';
    if (hours === 0) {
      displayTime = minutes;
      displayTimeExt = 'min ago';
    } else if (hours === 1) {
      displayTimeExt = 'hr ago';
    }
  } else if (days === 1) {
    displayTimeExt = 'day ago';
  }
  return `${displayTime} ${displayTimeExt}`;
};

export const convertTimeToDaysAgoOrDate = (dateAsString: string) => {
  const date = new Date(dateAsString);
  const now = new Date();

  let baseOptions: any = {
    hour: 'numeric', minute: 'numeric', day: '', month: '',
  };

  if (date.getDay() !== now.getDay()) {
    baseOptions = { ...baseOptions, weekday: 'short' };
  }

  if (date.getMonth() !== now.getMonth()) {
    baseOptions = { ...baseOptions, month: 'short', day: 'numeric' };
  }

  if (date.getFullYear() !== now.getFullYear()) {
    baseOptions = { ...baseOptions, year: 'numeric' };
  }

  return date.toLocaleDateString(undefined, baseOptions);
};

export const convertRatingToColor = (rating: number | undefined): string => {
  switch (rating) {
    case 10: // diamond
      return '#01c88a';
    case 9: // platinum
      return '#01c88a';
    case 8: // gold
      return '#01c88a';
    case 7: // silver
      return '#1c7ac6';
    case 6: // bronze
      return '#1c7ac6';
    case 5: // wood
      return '#f9d70c';
    case 4: // water
      return '#f9d70c';
    case 3: // tin
      return '#de752f';
    case 2: //
      return '#ff0000';
    case 1: // coronavirus
      return '#ff0000';
    default:
      return '#1c7ac6';
  }
};

export const convertMonthNumberToMonthText = (monthAsNumber: number): string => {
  const monthObj: any = {
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC',
  };
  return monthObj[monthAsNumber];
};

export const getRatingsAverage = (medium: Medium) => {
  const numOfRatings = getNumberOfRatings(medium);
  const sumOfRatings = getSumOfRatings(medium);
  return numOfRatings > 0 ? sumOfRatings / numOfRatings : 0;
};

export const getNumberOfRatings = (medium: Medium) => {
  const {
    rating1, rating2, rating3, rating4, rating5, rating6, rating7, rating8, rating9, rating10,
  } = medium;
  return rating1 + rating2 + rating3 + rating4 + rating5 + rating6 + rating7 + rating8 + rating9 + rating10;
};

export const getSumOfRatings = (medium: Medium) => {
  const {
    rating1, rating2, rating3, rating4, rating5, rating6, rating7, rating8, rating9, rating10,
  } = medium;
  return (rating1) + (rating2 * 2) + (rating3 * 3) + (rating4 * 4) + (rating5 * 5) + (rating6 * 6) + (rating7 * 7) + (rating8 * 8) + (rating9 * 9) + (rating10 * 10);
};

export const convertReactionTextToValue = (value: string) => {
  switch (value) {
    case 'like':
      return 0;
    case 'love':
      return 1;
    case 'lol':
      return 2;
    default:
      return 0;
  }
};

export const getCorrectTitle = (type?: string) => {
  switch (type) {
    case 'books':
      return {
        plural: 'Books', singular: 'book', screen: 'empty_books', value: 0,
      };
    case 'series':
      return {
        plural: 'Series', singular: 'Series', screen: 'empty_series', value: 3,
      };
    case 'movies':
      return {
        plural: 'Movies', singular: 'Movie', screen: 'empty_movies', value: 2,
      };
    case 'podcasts':
      return {
        plural: 'Podcasts', singular: 'Podcast', screen: 'empty_podcasts', value: 1,
      };
    default:
  }
};

export const getCorrectWishListShelf = (typeValue: number) => {
  if (typeValue === 0) {
    return 'wishBooksCount';
  } if (typeValue === 1) {
    return 'wishPodcastsCount';
  } if (typeValue === 2) {
    return 'wishMoviesCount';
  } if (typeValue === 3) {
    return 'wishSeriesCount';
  }
  return '';
};

export const getCorrectShelf = (typeValue: number) => {
  if (typeValue === 0) {
    return 'booksCount';
  } if (typeValue === 1) {
    return 'podcastsCount';
  } if (typeValue === 2) {
    return 'moviesCount';
  } if (typeValue === 3) {
    return 'seriesCount';
  }
  return '';
};

export const removeDuplicates = (existingMediums: Array<any>, newMediums: Array<any>) => {
  const existingIds = existingMediums.map((x: any) => x.medium._id);
  return newMediums.filter((x: any) => !existingIds?.includes(x.medium._id));
};

export const getProgressStoryNotePercentage = (storyNote: StoryNote) => {
  const sectionsLen = storyNote?.template.sections.length;
  const subSectionsLen = storyNote?.template.sections.map((x) => x.subChapters).flat().length;
  const completedLen = Object.keys(storyNote?.completed || {}).length;
  return Math.floor(((completedLen / ((sectionsLen || 0) + (subSectionsLen || 0))) * 100));
};

export const getProgressTopicPercentage = (topic: Topic) => {
  const sectionsLen = topic?.template?.sections.length;
  const subSectionsLen = topic?.template?.sections.map((x) => x.subChapters).flat().length;
  const completedLen = Object.keys(topic?.completed || {}).length;
  return Math.floor(((completedLen / ((sectionsLen || 0) + (subSectionsLen || 0))) * 100));
};

export const lightOrDark = (colorArg: string) => {

  // Variables for red, green, blue values
  let r, g, b, hsp, color;

  // Check the format of the color, HEX or RGB?
  if (colorArg.match(/^rgb/)) {

    // If RGB --> store the red, green, blue values in separate variables
    color = colorArg.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color![1];
    g = color![2];
    b = color![3];
  } else {

    // If hex --> Convert it to RGB: http://gist.github.com/983661
    // @ts-ignore
    color = +("0x" + colorArg.slice(1).replace(colorArg.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  // @ts-ignore
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export const getFormattedDate = (dateAsStr: string): string => {
  const date = new Date(dateAsStr);
  const currDate = new Date();
  date.toLocaleString(undefined, {
    day: 'numeric', weekday: 'short', month: 'short',
  });

  //var hours = Math.abs(date - currDate) / 36e5;
  let diff = Math.abs(date.getTime() - currDate.getTime()) / 3600000;

  if (diff < 24) {
    return date.toLocaleString(undefined, {
      day: "numeric", month: "short", hour: "numeric", minute: "numeric"
    }).toLowerCase();
    // less than 7 days
  } else {
    return date.toLocaleString(undefined, {
      day: "numeric", month: "short"
    }).toLowerCase();
  }

  // if (diff < 24) {
  //   return date.toLocaleString(undefined, {
  //     hour: "numeric", minute: "numeric", weekday: "short"
  //   }).toLowerCase();
  //   // less than 7 days
  // } else if (diff > 24 && diff < (24*7)) {
  //   return date.toLocaleString(undefined, {
  //     hour: "numeric", minute: "numeric", weekday: "short"
  //   }).toLowerCase();
  //   // greater than 7 days
  // } else {
  //   return date.toLocaleString(undefined, {
  //     weekday: "short", day: "numeric", month: "short", hour: "numeric", minute: "numeric"
  //   }).toLowerCase();
  // }
};