import React from 'react';
import { Badge, Image, Typography } from 'antd';
import styles from './OverViewCard.module.scss';
import { convertTimeToDaysAgo } from '../../../helpers/helpers';
import { fallBackUrl } from '../../../utils/imageData';
import { StoryNote } from '../../../models/StoryNote';

type OverViewCardProps = {
  storyNote: StoryNote;
  currentUserId: String;
  onOverviewCard: (storynote: any) => void
};

const { Paragraph } = Typography;
function OverViewCard(props: OverViewCardProps) {
  const { storyNote, currentUserId, onOverviewCard } = props;
  const {
    notesCount,
  } = storyNote;

  return (
    <div
      className={styles.OverViewCard}
      onClick={() => onOverviewCard(storyNote)}
    >
      <Image
        className={styles.image}
        src={storyNote.medium.imageUrl}
        alt={storyNote.medium.imageUrl}
        preview={false}
        fallback={fallBackUrl}
      />
      <div className={styles.textContainer}>
        <div className={styles.chatRoomInfo}>
          <div className={styles.username}>
            {storyNote.title}
          </div>
          <div className={styles.messagesCount}>
            {
              notesCount > 0
              && (
              <Badge
                count={notesCount}
                overflowCount={100}
              />
              )
            }
          </div>
        </div>
        <Paragraph
          className={styles.message}
          ellipsis={{ rows: 1, suffix: ` ${convertTimeToDaysAgo(storyNote.updatedAt)}`, expandable: false }}
        >
          updated
        </Paragraph>
      </div>
    </div>
  );
}

export default OverViewCard;
