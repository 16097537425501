import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Collapse, Radio, List,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './CustomizeQuote.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';
import { CreateQuote } from '../../../models/create-models/CreateQuote';
import Templates from '../Templates/Templates';
import { ActionTypes } from '../../../actions/types';
import ColorPaletteModal from '../ColorPaletteModal/ColorPaletteModal';
import FontModalMain from '../FontModalMain/FontModalMain';
import FontModalSource from '../FontModalSource/FontModalSource';
import { flexConverter } from '../../../helpers/flexConverter';
import {CreateList} from "../../../models/create-models/create-list";
import {highlightHashTags} from "../../../helpers/highlight";
import GradientPaletteModal from "../GradientPaletteModal/GradientPaletteModal";

interface CustomizeQuoteProps {
  createQuote?: CreateQuote;
  createList?: CreateList;
  actionType: typeof ActionTypes.SET_CREATE_QUOTE | typeof ActionTypes.SET_CREATE_LIST;
}

const { Panel } = Collapse;
function CustomizeQuote(props: CustomizeQuoteProps) {
  const { createQuote: cq, createList, actionType } = props;
  const createQuote = cq || createList;
  const [currentUpdate, setCurrentUpdate] = useState('');
  const currentFont = { fontSize: 20, isBold: false, isItalic: false };
  const dispatch = useDispatch();

  useEffect(() => {
  }, [currentFont.fontSize]);

  const background = createQuote?.template?.layers.find((x) => x.type === 'background');
  const backgroundText = createQuote?.template?.layers.find((x) => x.type === 'backgroundText');
  const quote = createQuote?.template?.layers.find((x) => x.type === 'quote');

  const onTextClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_TEXT_MODAL });
  };

  // display: flex;
  // flex-wrap: wrap;
  const layers = [
    <div key="layer1" style={{...background?.content }} />,
    <div key="layer2" style={{...backgroundText?.content }} >
      {createQuote?.text}
    </div>,
    <div className="createQuoteClickableArea" role="button" tabIndex={0} key="layer3" style={quote?.content.layout} onClick={onTextClick}>
      <div style={{ ...quote?.content.main.layout, justifyContent: flexConverter(quote?.content.layout.textAlign) }}>
        <span style={quote?.content.main.text}>
          {
            highlightHashTags(createQuote?.text || "", quote?.content.main.highlights)
          }
        </span>
      </div>
      <div style={quote?.content.source.layout}>
        <div style={quote?.content.source.title}>
          {createQuote?.medium?.title}
        </div>
        <div style={quote?.content.source.subtitle}>
          {createQuote?.medium?.providers[0]?.displayName}
        </div>
      </div>
    </div>,
  ];

  const selectFontCallback = (size: number, isBold: boolean, isItalic: boolean) => {
    if (currentUpdate === 'quoteFont') {
      updateQuoteTextFont(size, isBold, isItalic);
    } else if (currentUpdate === 'sourceFont') {
      updateSourceFont(size, isBold, isItalic);
    } else if (currentUpdate === 'highlightsFont') {
      updateHighlightextFont(size, isBold, isItalic);
    } else if (currentUpdate === 'backgroundTextFont') {
      updateBackgroundTextFont(size, isBold, isItalic);
    }
  };

  const selectSourceCallback = (size: number, isBold: boolean, isItalic: boolean) => {
    if (currentUpdate === 'quoteFont') {
      updateQuoteTextFont(size, isBold, isItalic);
    } else if (currentUpdate === 'sourceFont') {
      updateSourceFont(size, isBold, isItalic);
    }
  };

  const selectColorCallback = (color: any, opacity: number) => {
    if (currentUpdate === 'background') {
      updateBackground(color, opacity);
    } else if (currentUpdate === 'quote') {
      updateQuoteTextColor(color, opacity);
    } else if (currentUpdate === 'source') {
      updateSourceColor(color, opacity);
    } else if (currentUpdate === 'highlights') {
      updateHighlight(color, opacity);
    } else if (currentUpdate === 'gradientBackground') {
      updateGradientBackground(color, opacity);
    } else if (currentUpdate === 'backgroundText') {
      updateBackgroundText(color);
    }
  };

  const opacityCallback = (opacity: number) => {
    if (currentUpdate === 'backgroundText') {
      updateBackgroundTextOpacity(opacity);
    }
  }

  const updateBackground = (color: any, opacity: number) => {
    const tempBackground = createQuote?.template?.layers.find((x) => x.type === 'background');
    const updatedBackground = {
      ...tempBackground,
      content: { ...tempBackground?.content, background: color.color, opacity },
    };
    const removedBackground = createQuote?.template?.layers.filter((x) => x.type !== 'background');
    if (removedBackground) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [updatedBackground, ...removedBackground] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateGradientBackground = (color: any, opacity: number) => {
    const tempBackground = createQuote?.template?.layers.find((x) => x.type === 'background');
    const updatedBackground = {
      ...tempBackground,
      content: { ...tempBackground?.content, backgroundColor: color.backgroundColor, backgroundImage: color.backgroundImage, opacity },
    };
    const removedBackground = createQuote?.template?.layers.filter((x) => x.type !== 'background');
    if (removedBackground) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [updatedBackground, ...removedBackground] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateBackgroundText = (color: any) => {
    const tempBackground = createQuote?.template?.layers.find((x) => x.type === 'backgroundText');
    const updatedBackground = {
      ...tempBackground,
      content: { ...tempBackground?.content, color: color.color },
    };
    const removedBackground = createQuote?.template?.layers.filter((x) => x.type !== 'backgroundText');
    if (removedBackground) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [updatedBackground, ...removedBackground] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateBackgroundTextOpacity = (opacity: number) => {
    const tempBackground = createQuote?.template?.layers.find((x) => x.type === 'backgroundText');
    const updatedBackground = {
      ...tempBackground,
      content: { ...tempBackground?.content, opacity },
    };
    const removedBackground = createQuote?.template?.layers.filter((x) => x.type !== 'backgroundText');
    if (removedBackground) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [updatedBackground, ...removedBackground] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateHighlight = (color: any, opacity: number) => {
    const updatedQuote = {
      ...quote,
      content: {
        ...quote?.content,
        main: {
          ...quote?.content.main,
          highlights: { ...quote?.content.main.highlights, color: color.color, opacity },
        },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');
    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateQuoteTextFont = (size: number, isBold: boolean, isItalic: boolean) => {
    const updatedQuote = {
      ...quote,
      content: {
        ...quote?.content,
        main: {
          ...quote?.content.main,
          text: {
            ...quote?.content.main.text,
            fontSize: `${size}px`,
            fontWeight: isBold ? '800' : '400',
            fontStyle: isItalic ? 'italic' : 'normal',
          },
        },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');
    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateHighlightextFont = (size: number, isBold: boolean, isItalic: boolean) => {
    const updatedQuote = {
      ...quote,
      content: {
        ...quote?.content,
        main: {
          ...quote?.content.main,
          highlights: {
            ...quote?.content.main.highlights,
            fontSize: `${size}px`,
            fontWeight: isBold ? '800' : '400',
            fontStyle: isItalic ? 'italic' : 'normal',
          },
        },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');
    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateBackgroundTextFont = (size: number, isBold: boolean, isItalic: boolean) => {
    const updatedQuote = {
      ...backgroundText,
      content: {
        ...backgroundText?.content,
        fontSize: `${size}px`,
        fontWeight: isBold ? '800' : '400',
        fontStyle: isItalic ? 'italic' : 'normal',
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'backgroundText');
    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateSourceFont = (size: number, isBold: boolean, isItalic: boolean) => {
    const updatedQuote = {
      ...quote,
      content: {
        ...quote?.content,
        source: {
          ...quote?.content.source,
          title: {
            ...quote?.content.source.title,
            fontSize: `${size}px`,
            fontWeight: isBold ? '800' : '400',
            fontStyle: isItalic ? 'italic' : 'normal',
          },
          subtitle: {
            ...quote?.content.source.subtitle,
            fontSize: `${size}px`,
            fontWeight: isBold ? '800' : '400',
            fontStyle: isItalic ? 'italic' : 'normal',
          },
        },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');

    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateQuoteTextColor = (color: any, opacity: number) => {
    const updatedQuote = {
      ...quote,
      content: {
        ...quote?.content,
        main: {
          ...quote?.content.main,
          text: { ...quote?.content.main.text, color: color.color, opacity },
        },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');
    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateQuoteTextColorOpacity = (opacity: number) => {
    const updatedQuote = {
      ...quote,
      content: {
        ...quote?.content,
        main: {
          ...quote?.content.main,
          text: { ...quote?.content.main.text, opacity },
        },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');
    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateSourceColor = (color: any, opacity: number) => {
    const updatedQuote = {
      ...quote,
      content: {
        ...quote?.content,
        source: {
          ...quote?.content.source,
          title: { ...quote?.content.source.title, color: color.color, opacity },
          subtitle: { ...quote?.content.source.subtitle, color: color.color, opacity },
        },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');

    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const updateSourceColorOpacity = (opacity: number) => {
    const updatedQuote = {
      ...quote,
      content: {
        ...quote?.content,
        source: {
          ...quote?.content.source,
          title: { ...quote?.content.source.title, opacity },
          subtitle: { ...quote?.content.source.subtitle, opacity },
        },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');

    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedQuote] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  const handleBackgroundClick = () => {
    setCurrentUpdate('background');
    dispatch({ type: ActionTypes.TOGGLE_COLOR_PALETTE_MODAL });
  };

  const handleGradientBackgroundClick = () => {
    setCurrentUpdate('gradientBackground');
    dispatch({ type: ActionTypes.TOGGLE_GRADIENT_PALETTE_MODAL });
  };

  const handleHighlightsClick = () => {
    setCurrentUpdate('highlights');
    dispatch({ type: ActionTypes.TOGGLE_COLOR_PALETTE_MODAL });
  };

  const handleQuoteClick = () => {
    setCurrentUpdate('quote');
    dispatch({ type: ActionTypes.TOGGLE_COLOR_PALETTE_MODAL });
  };

  const handleBackgroundTextClick = () => {
    setCurrentUpdate('backgroundText');
    dispatch({ type: ActionTypes.TOGGLE_COLOR_PALETTE_MODAL });
  };

  const handleQuoteFontClick = () => {
    const tempFontSize = quote?.content.main.text.fontSize.replace('px', '') as number;
    const tempBold = quote?.content.main.text.fontWeight === '900';
    const tempIsItalic = quote?.content.main.text.fontStyle === 'italic';
    setCurrentUpdate('quoteFont');
    dispatch({ type: ActionTypes.TOGGLE_FONT_MODAL_MAIN, data: { fontSize: tempFontSize, isBold: tempBold, isItalic: tempIsItalic } });
  };

  const handleSourceClick = () => {
    setCurrentUpdate('source');
    dispatch({ type: ActionTypes.TOGGLE_COLOR_PALETTE_MODAL });
  };

  const handleSourceFontClick = () => {
    const tempFontSize = quote?.content.source.title.fontSize.replace('px', '') as number;
    const tempBold = quote?.content.source.title.fontWeight === '900';
    const tempIsItalic = quote?.content.source.title.fontStyle === 'italic';
    setCurrentUpdate('sourceFont');
    dispatch({ type: ActionTypes.TOGGLE_FONT_MODAL_SOURCE, data: { fontSize: tempFontSize, isBold: tempBold, isItalic: tempIsItalic } });
  };

  const handleHighlightFontClick = () => {
    const tempFontSize = quote?.content.main.highlights.fontSize.replace('px', '') as number;
    const tempBold = quote?.content.main.highlights.fontWeight === '900';
    const tempIsItalic = quote?.content.main.highlights.fontStyle === 'italic';
    setCurrentUpdate('highlightsFont');
    dispatch({ type: ActionTypes.TOGGLE_FONT_MODAL_MAIN, data: { fontSize: tempFontSize, isBold: tempBold, isItalic: tempIsItalic } });
  };

  const handleBackgroundTextFontClick = () => {
    const tempFontSize = backgroundText?.content.fontSize.replace('px', '') as number;
    const tempBold = backgroundText?.content.fontWeight === '900';
    const tempIsItalic = backgroundText?.content.fontStyle === 'italic';
    setCurrentUpdate('backgroundTextFont');
    dispatch({ type: ActionTypes.TOGGLE_FONT_MODAL_MAIN, data: { fontSize: tempFontSize, isBold: tempBold, isItalic: tempIsItalic } });
  };

  const onTextLayoutChange = (data: any) => {
    const updatedLayout = {
      ...quote,
      content: {
        ...quote?.content,
        layout: { ...quote?.content.layout, textAlign: data.target.value },
      },
    };
    const removedQuote = createQuote?.template?.layers.filter((x) => x.type !== 'quote');

    if (removedQuote) {
      const newCreateQuote = {
        ...createQuote,
        template: { ...createQuote?.template, layers: [...removedQuote, updatedLayout] },
      };
      dispatch({ type: actionType, value: newCreateQuote });
    }
  };

  // const colors = [
  //   <Button className={styles.backgroundColor} onClick={handleQuoteClick}>
  //     <span style={{ background: quote?.content.main.text.color }} className={styles.palette} />
  //     <span className={styles.colorsButtonTitle}>Quote</span>
  //     <DownOutlined type="down" />
  //   </Button>,
  //   <Button className={styles.backgroundColor} onClick={handleSourceClick}>
  //     <span style={{ background: quote?.content.source.title.color }} className={styles.palette} />
  //     <span className={styles.colorsButtonTitle}>Source</span>
  //     <DownOutlined type="down" />
  //   </Button>,
  //   <Button className={styles.backgroundColor} onClick={handleBackgroundClick}>
  //     <span style={{ background: background?.content.background }} className={styles.palette} />
  //     <span className={styles.colorsButtonTitle}>Background</span>
  //     <DownOutlined type="down" />
  //   </Button>,
  // ];

  // const colorsList = [
  //   <Button className={styles.backgroundColor} onClick={handleQuoteClick}>
  //     <span style={{ background: quote?.content.main.text.color }} className={styles.palette} />
  //     <span className={styles.colorsButtonTitle}>Quote</span>
  //     <DownOutlined type="down" />
  //   </Button>,
  //   <Button className={styles.backgroundColor} onClick={handleBackgroundClick}>
  //     <span style={{ background: background?.content.background }} className={styles.palette} />
  //     <span className={styles.colorsButtonTitle}>Background</span>
  //     <DownOutlined type="down" />
  //   </Button>,
  // ];

  const getCorrectFont = () => {
    console.log("createQuote: ",createQuote);
    let allFonts = [];
    if (createQuote?.template?.fonts.includes("quote")) {
      allFonts.push(<Button className={styles.backgroundColor} onClick={handleQuoteFontClick}>
      <span style={{ fontSize: '15px' }} className={styles.font}>
        {quote?.content.main.text.fontSize}
      </span>
        <span className={styles.colorsButtonTitle}>Quote</span>
        <DownOutlined type="down" />
      </Button>)
    }

    if (cq && createQuote?.template?.fonts.includes("source")) {
      allFonts.push(<Button className={styles.backgroundColor} onClick={handleSourceFontClick}>
      <span style={{ fontSize: '15px' }} className={styles.font}>
        {quote?.content.source.title.fontSize}
      </span>
        <span className={styles.colorsButtonTitle}>Source</span>
        <DownOutlined />
      </Button>)
    }

    if (createQuote?.template?.fonts.includes("highlights")) {
      allFonts.push(<Button className={styles.backgroundColor} onClick={handleHighlightFontClick}>
      <span style={{ fontSize: '15px' }} className={styles.font}>
        {quote?.content.main.highlights.fontSize}
      </span>
        <span className={styles.colorsButtonTitle}>Highlights</span>
        <DownOutlined />
      </Button>)
    }

    if (createQuote?.template?.fonts.includes("backgroundText")) {
      allFonts.push(<Button className={styles.backgroundColor} onClick={handleBackgroundTextFontClick}>
      <span style={{ fontSize: '15px' }} className={styles.font}>
        {backgroundText?.content.fontSize}
      </span>
        <span className={styles.colorsButtonTitle}>Background Text</span>
        <DownOutlined />
      </Button>)
    }

    return allFonts;
  };

  const getCorrectColors = () => {
    let allColors = [];
    if (createQuote?.template?.colors.includes("quote")) {
      allColors.push(<Button className={styles.backgroundColor} onClick={handleQuoteClick}>
        <span style={{ background: quote?.content.main.text.color }} className={styles.palette} />
        <span className={styles.colorsButtonTitle}>Quote</span>
        <DownOutlined type="down" />
      </Button>)
    }

    if (createQuote?.template?.colors.includes("source")) {
      allColors.push(<Button className={styles.backgroundColor} onClick={handleSourceClick}>
        <span style={{ background: quote?.content.source.title.color }} className={styles.palette} />
        <span className={styles.colorsButtonTitle}>Source</span>
        <DownOutlined type="down" />
      </Button>)
    }

    if (createQuote?.template?.colors.includes("background")) {
      allColors.push(<Button className={styles.backgroundColor} onClick={handleBackgroundClick}>
        <span style={{ background: background?.content.background }} className={styles.palette} />
        <span className={styles.colorsButtonTitle}>Background</span>
        <DownOutlined type="down" />
      </Button>)
    }

    if (createQuote?.template?.colors.includes("highlights")) {
      allColors.push(<Button className={styles.backgroundColor} onClick={handleHighlightsClick}>
        <span style={{ background: quote?.content.main.highlights.color }} className={styles.palette} />
        <span className={styles.colorsButtonTitle}>Highlights</span>
        <DownOutlined type="down" />
      </Button>)
    }

    if (createQuote?.template?.colors.includes("gradientBackground")) {
      allColors.push(<Button className={styles.backgroundColor} onClick={handleGradientBackgroundClick}>
        <span style={{ backgroundColor: background?.content.backgroundColor, backgroundImage: background?.content.backgroundImage }} className={styles.palette} />
        <span className={styles.colorsButtonTitle}>Gradient Background</span>
        <DownOutlined type="down" />
      </Button>)
    }

    if (createQuote?.template?.colors.includes("backgroundText")) {
      allColors.push(<Button className={styles.backgroundColor} onClick={handleBackgroundTextClick}>
        <span style={{ background: backgroundText?.content.color }} className={styles.palette} />
        <span className={styles.colorsButtonTitle}>Background Text</span>
        <DownOutlined type="down" />
      </Button>)
    }

    return allColors;
  };

  // const saveAndGoToNext = () => {
  //   const item = document.getElementById('my-node');
  //   if (item) {
  //     htmlToImage.toBlob(item, { quality: 1.0 })
  //       .then((dataUrl) => {
  //         console.log('dataUrl: ', dataUrl);
  //         // download(dataUrl, 'my-node.png');
  //         if (dataUrl) {
  //           const fd = new FormData();
  //           fd.append('quote', dataUrl);
  //           const newCreateQuote = { ...createQuote, dataUrl, currentPage: createQuote.currentPage + 1 };
  //           dispatch({ type: ActionTypes.SET_CREATE_QUOTE, createQuote: newCreateQuote });
  //         }
  //       }).catch((e) => {
  //         console.log('e: ', e);
  //       });
  //   }
  // };

  return (
    <div className={styles.CustomizeQuote}>
      <ColorPaletteModal currentUpdate={currentUpdate} callback={selectColorCallback} opacityCallback={opacityCallback} />
      <GradientPaletteModal callback={selectColorCallback} />
      <FontModalMain
        callback={selectFontCallback}
      />
      <FontModalSource
        callback={selectSourceCallback}
      />
      <div className={styles.quoteContainer}>
        <div id="my-node-container" className={styles.layersContent}>
          <div id="my-node" className={styles.quote}>
            {
                layers.map((val) => val)
            }
          </div>
        </div>
      </div>
      <div className={styles.controls}>
        <Collapse bordered={false} accordion defaultActiveKey={['1']}>
          <Panel header="Templates" key="1">
            <Templates actionType={actionType} createQuote={createQuote} />
          </Panel>
          <Panel header="Colors" key="2">
            <div className={styles.colors}>
              <List
                style={{ width: '100%' }}
                grid={{ gutter: 8, xs: 2, sm: 3 }}
                dataSource={ getCorrectColors() }
                renderItem={(item) => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </div>
          </Panel>
          <Panel header="Font" key="3">
            <div className={styles.colors}>
              <List
                style={{ width: '100%' }}
                grid={{ gutter: 8, xs: 2 }}
                dataSource={getCorrectFont()}
                renderItem={(item) => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </div>
          </Panel>
          <Panel header="Layout" key="4">
            <div className={styles.centerTextContainer}>
              <Radio.Group onChange={onTextLayoutChange} defaultValue="left">
                <Radio.Button value="left">left</Radio.Button>
                <Radio.Button value="center">center</Radio.Button>
                <Radio.Button value="right">right</Radio.Button>
              </Radio.Group>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default CustomizeQuote;
