import React from 'react';
import styles from './best-player-card.module.scss';

function BestPlayerCard() {
  return (
    <div className={styles.BestPlayerCard}>
      <div className={styles.header}>
        header
      </div>
      <div className={styles.body}>
        BestPlayerCard
      </div>
      <div className={styles.footer}>
        footer
      </div>
    </div>
  );
}

export default BestPlayerCard;
