import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styles from './GradientPaletteModal.module.scss';
import { ActionTypes } from '../../../actions/types';
import { StoreState } from '../../../reducers/_RootReducers';
import {lightOrDark} from "../../../helpers/helpers";
import {CloseOutlined} from "@ant-design/icons";

interface ColorPaletteModalProps {
  callback: Function;
}

function GradientPaletteModal(props:ColorPaletteModalProps) {
  const [color, setColor] = useState({ id: 'white', backgroundColor: '#ffffff', backgroundImage: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)' });
  const [opacity] = useState(1);
  const isGradientPaletteModalVisible = useSelector<StoreState, boolean>((state) => state.isGradientPaletteModalVisible);
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch({ type: ActionTypes.TOGGLE_GRADIENT_PALETTE_MODAL });
  };

  const onSaveClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_GRADIENT_PALETTE_MODAL });
  };

  const handleChangeComplete = (color: any) => {
    if (color) {
      const newColor = { id: `${color.backgroundColor}`, backgroundColor: `${color.backgroundColor}`, backgroundImage: `${color.backgroundImage}` };
      props.callback(newColor, opacity);
      setColor(newColor);
    }
  }

  const styleColor = { color: lightOrDark(color.backgroundColor) === 'dark' ? '#ffffff': '#000000' };

  return (
    <Modal
      className={styles.ColorPaletteModal}
      visible={isGradientPaletteModalVisible}
      onCancel={handleCancel}
      bodyStyle={{ padding: '0px', backgroundColor: `${color.backgroundColor}`, backgroundImage: `${color.backgroundImage}` }}
      closable={false}
      centered
      title={null}
      footer={null}
    >
      <div className={styles.ColorPaletteModal}>
        <div className={styles.colorTitle} style={styleColor}>
          Color
        </div>
        <div className={styles.colors}>
          {
            [{ 'backgroundColor': '#4158D0', 'backgroundImage': 'linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)'},
              { 'backgroundColor': '#0093E9', 'backgroundImage': 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)'},
              { 'backgroundColor': '#FBAB7E', 'backgroundImage': 'linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)'},
              { 'backgroundColor': '#21D4FD', 'backgroundImage': 'linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)'},
              { 'backgroundColor': '#FA8BFF', 'backgroundImage': 'linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)'},
              { 'backgroundColor': '#F4D03F', 'backgroundImage': 'linear-gradient(132deg, #F4D03F 0%, #16A085 100%)'},
              { 'backgroundColor': '#74EBD5', 'backgroundImage': 'linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%)'}
            ].map((item, index) => {
              return <div className={styles.colorItem} style={{...item}} key={`${index}`} onClick={() => handleChangeComplete(item)} />
            })
          }
        </div>
        <div className={styles.actions}>
          <Button style={styleColor} type="link" onClick={onSaveClick}><CloseOutlined/></Button>
        </div>
      </div>
    </Modal>
  );
}

export default GradientPaletteModal;
