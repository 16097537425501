import React from 'react';
//import styles from './thumb-test-cinema.module.scss';
import styles from './thumb-test-court.module.scss';

function StadiumDraft() {
    return (
        <div className={styles.ThumbTestFin}>
            {/*{*/}
            {/*    Array.from(Array( 4).keys()).map((x, index) => {*/}
            {/*        return <div*/}
            {/*            key={`${index}-dot`}*/}
            {/*            className={styles.dot}/>*/}
            {/*    })*/}
            {/*}*/}
            <div className={styles.section1}>
                <div className={styles.subsection1}>section1A</div>
                <div className={styles.subsection1}>section1B</div>
                <div className={styles.subsection1}>section1C</div>
            </div>
            <div className={styles.section2}>section2</div>
            <div className={styles.section3}>
                <div className={styles.subsection3}>section3A</div>
            </div>
            <div className={styles.section4}>section4</div>
            <div className={styles.section5}>
                <div className={styles.subsection5}>section5A</div>
                <div className={styles.subsection5}>section5B</div>
                {/*<div className={styles.subsection5}>section5C</div>*/}
            </div>
            <div className={styles.section6}>section6</div>
            <div className={styles.section7}>
                <div className={styles.subsection7}>section7A</div>
            </div>
            <div className={styles.section8}>section8</div>
            <div className={styles.court}>court</div>
        </div>
    );
}

export default StadiumDraft;