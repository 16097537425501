import React from 'react';
import styles from './substitution-card.module.scss';
import Header from '../../stadium-stats/header/header';
import baylor from '../../../../../assets/images/baylor.png';
import OneUser from '../../stadium-stats/one-user/one-user';
import elon from '../../../../../assets/images/elon.jpeg';
import Footer from '../../stadium-stats/footer/footer';
import subIcon from '../../../../../assets/icons/icons8-replace-50.png';

function SubstitutionCard() {
  return (
    <div className={styles.SubstitutionCard}>
      <Header
        image={subIcon}
        title="Substitution"
        minute="85'"
      />
      <div className={styles.body}>
        <div className={styles.player}>
          <div className={styles.info}>
            <div className={styles.in}>
              IN
            </div>
            <div className={styles.name}>
              E. Musk #24
            </div>
            <div className={styles.detailsContainer}>
              <img
                className={styles.detailsImage}
                src={baylor}
              />
              {/* <div className={styles.detailsText}> */}
              {/*  info */}
              {/* </div> */}
            </div>
          </div>
          <img
            className={styles.image}
            src={elon}
          />
        </div>
        <div className={styles.player}>
          <div className={styles.info}>
            <div className={styles.out}>
              OUT
            </div>
            <div className={styles.name}>
              L. James #23
            </div>
            <div className={styles.detailsContainer}>
              <img
                className={styles.detailsImage}
                src={baylor}
              />
              {/* <div className={styles.detailsText}> */}
              {/*  info */}
              {/* </div> */}
            </div>
          </div>
          <img
            className={styles.image}
            src={elon}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SubstitutionCard;
