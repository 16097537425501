import React, { useState } from 'react';
import { Button, Progress } from 'antd';
import { CheckCircleOutlined, BorderOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import styles from './preview-quiz.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';
import { CreateQuiz } from '../../../models/create-models/CreateQuiz';
import { Answer } from '../../../models/create-models/Question';
import { ActionTypes } from '../../../actions/types';

function PreviewQuiz() {
  const quizAnswers = useSelector<StoreState, { [key: string]: string }>((state) => state.createQuizAnswers);
  const createQuiz = useSelector<StoreState, CreateQuiz>((state) => state.createQuiz);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState<Answer | undefined>(undefined);
  const [clickCounter, setClickCounter] = useState(0);
  const totalQuestionsCount = createQuiz.questions.length;
  const dispatch = useDispatch();

  const onSelectClick = (answer: Answer) => {
    setSelectedAnswer(answer);
  };

  const answerExistAndIsCorrect = (answer: Answer) => quizAnswers[`${currentQuestion}`]
      && selectedAnswer
        && createQuiz.questions
          .find((q) => q.id === `${currentQuestion}`)?.correctAnswerId === answer.id;

  const answerExistAndIsIncorrect = (answer: Answer) => {
    if (quizAnswers[`${currentQuestion}`] && selectedAnswer) {
      const dev = createQuiz.questions
        .find((q) => q.id === `${currentQuestion}`);
      return dev?.correctAnswerId !== answer.id && selectedAnswer.id === answer.id;
    }

    return false;
  };

  const answerExistAndIsNotThisOption = (answer: Answer) => {
    if (quizAnswers[`${currentQuestion}`] && selectedAnswer) {
      const dev = createQuiz.questions
        .find((q) => q.id === `${currentQuestion}`);
      if (dev) {
        return dev.correctAnswerId !== answer.id && selectedAnswer.id !== answer.id;
      }
    }
    return false;
  };

  const answerDoesNotExistAndIsSelect = (answer: Answer) => selectedAnswer && selectedAnswer.id === answer.id && !quizAnswers[`${currentQuestion}`];

  const onSubmitAnswer = () => {

    if (clickCounter === 0) {
      const foundAnswer = createQuiz.questions
        .find((q) => q.id === `${currentQuestion}`)?.answers
          .find((a) => a.id === selectedAnswer?.id);
      // console.log('answer - foundAnswer: ', foundAnswer);
      if (foundAnswer) {
        dispatch({ type: ActionTypes.SET_CREATE_QUIZ_ANSWERS, value: { ...quizAnswers, [`${currentQuestion}`]: foundAnswer.id } });
      }
      setClickCounter(clickCounter + 1);
    } else if (clickCounter === 1) {
      if ((currentQuestion - 1) === totalQuestionsCount) {
        return;
      }
      setCurrentQuestion(currentQuestion + 1);
      setClickCounter(0);
      setSelectedAnswer(undefined);
    }
  };

  const calculateScore = (createQuiz: CreateQuiz) => {
    let totalPoints = 0;
    createQuiz.questions.forEach((question) => {
      if (question.correctAnswerId === quizAnswers[question.id]) {
        totalPoints = totalPoints + 1;
      }
    })
    return totalPoints;
  }

  return (
    <div className={styles.PreviewQuiz}>
      <div className={styles.quizContainer}>
        <div className={styles.header}>
          <div>
            Question
            {' '}
            {currentQuestion > totalQuestionsCount ? totalQuestionsCount: currentQuestion}
          </div>
          <div>
            <Progress
              status="active"
              strokeColor={{
                '0%': '#2ECC71',
                '100%': '#2ECC71',
              }}
              percent={((currentQuestion / totalQuestionsCount) * 100)}
              format={(percent) => `${currentQuestion > totalQuestionsCount ? totalQuestionsCount: currentQuestion}/${totalQuestionsCount}`}
            />
          </div>
        </div>
        {
          (currentQuestion - 1) === totalQuestionsCount ?
              <div className={styles.body}>
                <div className={styles.congrats}>
                  🎉{' '}congrats{' '}🎉
                </div>
                <div className={styles.yourScore}>
                  you scored
                </div>
                <div className={styles.scoreContainer}>
                  <span className={styles.score}>{calculateScore(createQuiz)}</span>
                  <span className={styles.totalQuestions}>/{totalQuestionsCount}</span>
                </div>
              </div>
              :
              <div className={styles.body} >
                <div className={styles.question}>
                  {createQuiz.questions.find((q) => q.id === `${currentQuestion}`)?.question}
                </div>
                <div className={styles.options}>
                  {
                    createQuiz.questions
                        .find((q) => q.id === `${currentQuestion}`)?.answers
                        .map((a, qIndex) => (
                            <div key={`options${qIndex}`}>
                              <Button
                                  type={quizAnswers[`${currentQuestion}`] ? 'primary' : 'default'}
                                  size="large"
                                  icon={answerExistAndIsCorrect(a) ? <CheckCircleOutlined /> : answerExistAndIsIncorrect(a) ? <CloseCircleOutlined /> : <BorderOutlined />}
                                  className={`${styles.option} ${answerDoesNotExistAndIsSelect(a) && styles.selectedOption} ${answerExistAndIsCorrect(a) && styles.correctOption} ${answerExistAndIsIncorrect(a) && styles.incorrectOption}`}
                                  onClick={() => onSelectClick(a)}
                                  disabled={answerExistAndIsNotThisOption(a)}
                              >
                                {a.text}
                              </Button>
                            </div>
                        ))
                  }
                </div>
              </div>
        }
        <div className={styles.footer}>
          <Button
            className={styles.nextButton}
            type="link"
            size="large"
          />
          <Button
            className={styles.nextButton}
            type="primary"
            size="large"
            onClick={onSubmitAnswer}
            disabled={(currentQuestion - 1) === totalQuestionsCount}
          >
            {clickCounter === 0 ? 'Submit' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PreviewQuiz;
