import React from 'react';
import styles from './notes-source-modal.module.scss';
import {Button, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {ActionTypes} from "../../../actions/types";
import {useHistory, useParams} from "react-router-dom";

function NotesSourceModal() {
    const { id, storiesId, sectionId } = useParams<{ id: string, storiesId: string, sectionId: string }>();
    const isAddNotesOpen = useSelector<StoreState, boolean>((state) => state.isAddNotesOpen);

    const dispatch = useDispatch();
    const history = useHistory();

    const onCloseNotesModal = () => {
        dispatch({ type: ActionTypes.SET_ADD_NOTES_MODAL_VISIBILITY, value: false });
    };

    const onExistingClick = () => {
        onCloseNotesModal();
        dispatch({ type: ActionTypes.SET_MY_NOTES_VISIBILITY, value: true });
    }

    const onNewClick = () => {
        onCloseNotesModal();
        history.push(`/storyclubs/${id}/stories/${storiesId}/${sectionId}/notes/create`)
    }

    return (
        <Modal
            className={styles.NotesSourceModal}
            title={null}
            visible={isAddNotesOpen}
            footer={null}
            closable={false}
            onCancel={onCloseNotesModal}
        >
            <div className={styles.content}>
                <div className={styles.title}>
                    Add Notes
                </div>
                <div>
                    <Button
                        className={styles.optionButton}
                        size="large"
                        onClick={onExistingClick}
                    >
                        Choose from your existing notes
                    </Button>
                </div>
                <div>
                    <Button
                        className={styles.optionButton}
                        size="large"
                        onClick={onNewClick}
                    >
                        Create new note
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default NotesSourceModal;