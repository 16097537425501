import React from 'react';
import styles from './stands-post-screen.module.scss';

function StandsPostScreen() {
  return (
    <div className={styles.StandsPostScreen}>
      StandsPostScreen
    </div>
  );
}

export default StandsPostScreen;
