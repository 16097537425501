import React from 'react';
import { useHistory } from 'react-router-dom';
import { List } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './WiltCard2.module.scss';
import { Post } from '../../../models/Post';
import PostHeader from '../PostHeader/PostHeader';
import PostSource from '../PostSource/PostSource';
import PostDivider from '../PostDivider/PostDivider';
import PostFooter from '../PostFooter/PostFooter';
import { ActionTypes } from '../../../actions/types';

interface WiltCardProps {
  post: Post;
  likePost: Function;
  unlikePost: Function;
  reactionIds: any;
  shelfIds: { [key: string]: string };
  onMediumAction?: (url: string) => void;
}

function WiltCard2(props:WiltCardProps) {
  const {
    reactionIds, post, likePost, unlikePost, onMediumAction, shelfIds,
  } = props;
  const {
    user, medium, createdAt, title,
  } = post;
  const history = useHistory();
  const dispatch = useDispatch();

  const onViewFullWiltClick = () => {
    dispatch({ type: ActionTypes.SET_SELECTED_POST, post: props.post });
    history.push(`/posts/${props.post._id}`);
  };

  const onMediumClick = () => {
    if (onMediumAction) {
      onMediumAction(`/mediums/${props.post.medium.external.id}/${props.post.medium.typeValue}`);
    } else {
      history.push(`/mediums/${props.post.medium.external.id}/${props.post.medium.typeValue}`);
    }
  };

  return (
    <List.Item className={styles.WiltCard2}>
      <PostHeader user={user} post={post} createdAt={createdAt} />
      <div className={styles.title} />
      <div className={styles.body}>
        <div className={styles.wiltTitleContainer}>
          <div className={styles.wiltLabel}>wilt</div>
        </div>
        <div className={styles.wiltContainer}>
          {title}
        </div>
        <div role="button" tabIndex={0} className={styles.viewMore} onClick={onViewFullWiltClick}>
          view more
        </div>
      </div>
      <PostSource medium={medium} shelfIds={shelfIds} onMediumClick={onMediumClick} />
      <PostDivider padding="12px" />
      <PostFooter
        createdAt={createdAt}
        post={post}
        reactionIds={reactionIds}
        likePost={likePost}
        unlikePost={unlikePost}
      />
    </List.Item>
  );
}

export default WiltCard2;
