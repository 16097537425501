import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Button, Dropdown, Input, Menu, Spin,
} from 'antd';
import { SendOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useInfiniteQuery, useQuery, useQueryCache } from 'react-query';
import axios from 'axios';
import _ from 'lodash';
import styles from './activity-tab.module.scss';
import TopicPostCard from '../topic-post-card/topic-post-card';
import NotesCard from '../../storynotes-notes-screen/notes-card/notes-card';
import { StoreState } from '../../../reducers/_RootReducers';
import { User } from '../../../models/User';
import { Note } from '../../../models/Note';
import { ActionTypes } from '../../../actions/types';
import { TopicPost } from '../../../models/TopicPost';
import { like, unlike, updateLike } from '../../../services-api/clubTopicsService';
import Empty from "../../../components/Empty/Empty";
import RecentCardSkeleton from "../../clubs-screen/club-recent-activity/recent-card-skeleton/recent-card-skeleton";
import PostSkeletonCard from "./post-skeleton-card/post-skeleton-card";

type ActivityTabProps = {
  id: string,
  storiesId: string
};

const { TextArea } = Input;
const { Search } = Input;
function ActivityTab(props: ActivityTabProps) {
  const { id, storiesId } = props;
  let globalFileInput: any;
  const [file, setFile] = useState<Blob | null>(null);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const isMyNotesOpen = useSelector<StoreState, boolean>((state) => state.isMyNotesOpen);
  const reactionIds = useSelector<StoreState, any>((state) => state.reactionIds);
  const currentUser = useSelector<StoreState, User | null>((state) => state.currentUser);
  const currentPreviewNote = useSelector<StoreState, Note | null>((state) => state.currentPreviewNote);
  const currentDisplayNote = useSelector<StoreState, Note | null>((state) => state.currentDisplayNote);
  const isReactionsOpen = useSelector<StoreState, boolean>((state) => state.isReactionsOpen);
  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [isAtNotesEnd, setIsAtNotesEnd] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const [message, setMessage] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);
  const [cropData, setCropData] = useState({
    image: 'your-image-url or as base64',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
  });
  const [cropD, setCropD] = useState<any>({
    src: null,
    crop: {
      aspect: 16 / 9,
    },
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const { isLoading: isTopicLoading, error: topicError, data: currentTopic } = useQuery(`story-${storiesId}`, () => axios.get(`/api/clubs/topics/${storiesId}`)
    .then((response) => response.data));

  useEffect(() => () => {
    dispatch({ type: ActionTypes.SET_CURRENT_CHAT_USER, value: null });
    dispatch({ type: ActionTypes.SET_CURRENT_CHAT_ROOM, value: null });
  }, []);

  const onInputChange = (data: any) => {
    setMessage(data.target.value);
  };

  const onSendClick = () => {
    if (currentTopic) {
      const data = new FormData();

      data.append('topic', currentTopic._id as string);

      if (!message && !currentPreviewNote) {
        return;
      }

      if (message) {
        data.append('message', message);
      }

      if (file != null) {
        data.append('avatar', file, 'temp');
      }

      if (currentPreviewNote) {
        data.append('note', currentPreviewNote._id);
      }

      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      axios.post(`/api/clubs/topics/posts/${id}/${storiesId}`, data)
        .then((response) => {
          const newTopicPost = response.data;
          newTopicPost.user = currentUser;
          newTopicPost.note = currentPreviewNote;

          queryCache.setQueryData(`club-stories-posts-${storiesId}`, (oldData: any) => {
            if (oldData) {
              return oldData.map((x: any) => ({ ...x, posts: [newTopicPost, ...x.posts] }));
            }
            return [{ posts: [newTopicPost], nextId: 1 }];
          });
          dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
          dispatch({ type: ActionTypes.SET_CURRENT_PREVIEW_NOTE, value: null });
          if (message) {
            setMessage('');
          }
          setFile(null);
        }).catch((error) => {
          console.log('error: ', error);
        });
    }
  };

  const onBackClick = () => {
    history.push(`/storyclubs/${id}/stories`);
  };

  const updateRead = (chatRoomId: string, messages: string[]) => {
    axios.put(`/api/chats/reads/${chatRoomId}`, { messages })
      .then((response) => {
      });
  };

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `club-stories-posts-${storiesId}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/clubs/topics/posts/${storiesId}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      console.log('data: ', data);
      dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, ...data?.reactionIds } });
      if (data?.posts?.length > 0) {
        // updateRead(data?.chatRoomId, data?.messages?.map((m: any) => m._id));
      }

      if (data?.posts?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onMoreClick = () => {

  };

  const onPostClick = (postId: string) => {

  };

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsImageModalOpen(true);
      setFile(event.target.files[0]);
    }
  };

  const onNotesClick = () => {
    dispatch({ type: ActionTypes.SET_MY_NOTES_VISIBILITY, value: true });
  };

  const menu = (
    <Menu>
      <Menu.Item className={styles.uploadOptionItem} onClick={onNotesClick}>
        <EditOutlined className={styles.uploadIcon} />
        <div className={styles.uploadText}>
          Notes
        </div>
      </Menu.Item>
      {/* <Menu.Item className={styles.uploadOptionItem} onClick={() => globalFileInput.click()}> */}
      {/*  <FileImageOutlined className={styles.uploadIcon} /> */}
      {/*  <div className={styles.uploadText}> */}
      {/*    <input */}
      {/*      style={{ display: 'none' }} */}
      {/*      type="file" */}
      {/*      name="avatar" */}
      {/*      onChange={fileSelectedHandler} */}
      {/*      ref={(fileInput) => globalFileInput = fileInput} */}
      {/*      accept=".jpeg,.png" */}
      {/*    /> */}
      {/*    Photo */}
      {/*  </div> */}
      {/* </Menu.Item> */}
    </Menu>
  );

  const handleOk = () => {

  };

  const handleCancel = () => {

  };

  const onCropChange = (crop: any) => {
    setCropD({ ...cropD, crop });
  };

  const onCancel = () => {
    setFile(null);
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    console.log(croppedArea, croppedAreaPixels);
  };

  const onZoomChange = (zoom: any) => {
    setCropData({ ...cropData, zoom });
  };

  const onImageLoaded = (image: any) => {
    // this.imageRef = image;
  };

  const updatePost = (post: TopicPost, type: string) => {
    if (type === 'like') {
      return { ...post, likesCount: (post?.likesCount || 0) + 1 };
    }

    if (type === 'love') {
      return { ...post, lovesCount: (post?.lovesCount || 0) + 1 };
    }

    if (type === 'lol') {
      return { ...post, laughsCount: (post?.laughsCount || 0) + 1 };
    }

    dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${post?._id}`]: { typeText: type } } });
    return post;
  };

  const updateExistingPost = (post: TopicPost, type: string, existingReaction: any) => {
    let updatedPost = { ...post };

    if (type === existingReaction.typeText) {
      if (type === 'like') {
        updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) - 1 };
      }

      if (type === 'love') {
        updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) - 1 };
      }

      if (type === 'lol') {
        updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) - 1 };
      }
      const updatedReactionIds = _.omit(reactionIds, `${post._id}`);
      dispatch({ type: ActionTypes.SET_REACTION_IDS, value: updatedReactionIds });
      return updatedPost;
    }

    if (type === 'like') {
      updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) + 1 };
    }

    if (type === 'love') {
      updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) + 1 };
    }

    if (type === 'lol') {
      updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) + 1 };
    }

    if (existingReaction.typeText === 'like') {
      updatedPost = { ...updatedPost, likesCount: updatedPost!.likesCount! - 1 };
    }

    if (existingReaction.typeText === 'love') {
      updatedPost = { ...updatedPost, lovesCount: updatedPost!.lovesCount! - 1 };
    }

    if (existingReaction.typeText === 'lol') {
      updatedPost = { ...updatedPost, laughsCount: updatedPost!.laughsCount! - 1 };
    }
    console.log('louis updatedPost: ', updatedPost);
    dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${post?._id}`]: { typeText: type } } });

    return updatedPost;
  };

  const onReactionAction = (tp: TopicPost, type: string) => {
    queryCache.setQueryData(`club-stories-posts-${storiesId}`, (oldData: any) => {
      if (oldData) {
        dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${tp?._id}`]: { typeText: type } } });
        return oldData.map((x: any) => ({
          ...x,
          posts: x.posts.map((post: TopicPost) => {
            if (post._id === tp._id) {
              if (reactionIds[`${post._id}`]) {
                return updateExistingPost(post, type, reactionIds[`${post._id}`]);
              }
              return updatePost(post, type);
            }
            return post;
          }),
        }));
      }
      return oldData;
    });

    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
    dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: false });

    if (reactionIds[`${tp._id}`]) {
      if (type === reactionIds[`${tp._id}`].typeText) {
        console.log('louis unlike');
        dispatch(unlike(tp, type, () => {
        }));
      } else {
        console.log('louis update like');
        dispatch(updateLike(tp, type, () => {
        }));
      }
    } else {
      console.log('louis like');
      dispatch(like(tp, type, () => {
      }));
    }
  };

  const onReactionClick = (tp: TopicPost) => {
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: tp._id });
    dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: true });
  };
  const [searchNotesQuery, setSearchNotesQuery] = useState('');
  const {
    isLoading: isNotesLoading, data: notesData, isFetching: isNotesFetching, fetchMore: fetchMoreNotes,
  } = useInfiniteQuery(`my-notes-${searchNotesQuery}`,
    async (key, nextId = 0) => {
      const { data: noteData } = await axios.get(`/api/notes/my/notes?search=${searchNotesQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      if (noteData?.notes?.length < limit) {
        setIsAtNotesEnd(true);
      }
      return noteData;
    },
    {
      enabled: true,
      getFetchMore: (lastGroup) => lastGroup?.nextId,
    });

  const onCloseNotesModal = () => {
    dispatch({ type: ActionTypes.SET_MY_NOTES_VISIBILITY, value: false });
  };

  const onNotesSearch = (search: string) => {
    setSearchNotesQuery(search);
  };

  const onNotesCardClick = (note: Note) => {
    dispatch({ type: ActionTypes.SET_CURRENT_PREVIEW_NOTE, value: note });
    dispatch({ type: ActionTypes.SET_MY_NOTES_VISIBILITY, value: false });
  };

  const onCloseAction = () => {
    dispatch({ type: ActionTypes.SET_CURRENT_PREVIEW_NOTE, value: null });
  };

  const onPreviewNoteAction = (note: Note) => {
    dispatch({ type: ActionTypes.SET_DISPLAY_NOTE, value: note });
  };

  const onDisplayNoteCancel = () => {
    dispatch({ type: ActionTypes.SET_DISPLAY_NOTE, value: null });
  };

  const renderCorrectNote = (type: string) => {
    switch (type) {
      case 'TEXT':
        return <div className={styles.text}>{currentDisplayNote?.text}</div>;
      case 'QUOTE_THOUGHT':
        return (
          <div className={styles.quoteThoughtContainer}>
            <div className={styles.quoteContainer}>
              <div className={styles.title}>quote</div>
              <div className={styles.body}>{currentDisplayNote?.quoteText}</div>
            </div>
            <div className={styles.quoteContainer}>
              <div className={styles.title}>thought</div>
              <div className={styles.body}>{currentDisplayNote?.thoughtText}</div>
            </div>
          </div>
        );
      case 'QUESTION_ANSWER':
        return (
          <div className={styles.questionAnswerContainer}>
            <div className={styles.questionContainer}>
              <div className={styles.title}>question</div>
              <div className={styles.body}>{currentDisplayNote?.questionText}</div>
            </div>
            {
                            isAnswerVisible
                              ? (
                                <div className={styles.answerContainer}>
                                  <div className={styles.title}>answer</div>
                                  <div className={styles.body}>{currentDisplayNote?.answerText}</div>
                                </div>
                              ) : (
                                <div className={styles.answerContainer}>
                                  <Button
                                    className={styles.showAnswer}
                                    type="primary"
                                    size="large"
                                    onClick={() => setIsAnswerVisible(true)}
                                  >
                                    show answer
                                  </Button>
                                </div>
                              )
                        }
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className={styles.ActivityTab}>
      <div
        id="scrollableDiv"
        className={styles.messagesContainer}
      >
        {
          isFetching === true && !data &&
          [1, 1, 1, 1].map((item, index) => <PostSkeletonCard key={`${index}`} />)
        }
        {
          isFetching === false && data?.map((page) => page.posts).flat().length === 0 &&
          <Empty
              title="No messages"
              subTitle="Messages will appear here"
              icon="plus"
              url="/createclubs"
              screen="posts"
          />
        }
        {
          data?.map((page) => page.posts).flat().length !== 0 &&
              <InfiniteScroll
                  style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  dataLength={data?.map((page) => page.posts).flat().length || 0}
                  next={() => fetchMore()}
                  hasMore={(data?.map((page) => page.posts).flat().length || 0) > 0 && !isAtTheEnd}
                  loader={(
                      <div className={styles.spinningContainer}>
                        <Spin />
                      </div>
                  )}
                  endMessage={(<p style={{ textAlign: 'center' }} />)}
                  inverse
                  scrollableTarget="scrollableDiv"
                  scrollThreshold={0.3}
              >
                {
                  data?.map((page) => page.posts).flat().map((topicPost, index) => (
                      <TopicPostCard
                          key={`post-card-${index}`}
                          topicPost={topicPost}
                          onPostClick={onPostClick}
                          onReactionAction={onReactionAction}
                          isReactionsOpen={isReactionsOpen}
                          onReactionClick={onReactionClick}
                          onPreviewNoteAction={onPreviewNoteAction}
                          currentId={currentId}
                          reactionIds={reactionIds}
                          currentTopic={currentTopic}
                          timeStamp={timeStamp}
                          hideTimeStamp
                      />
                  ))
                }
              </InfiniteScroll>
        }
      </div>
      <div className={styles.inputContainer}>
        {/* <Dropdown overlay={menu} placement="topLeft" trigger={['click']}> */}
        {/*  <PlusOutlined className={styles.addButton} /> */}
        {/* </Dropdown> */}
        <div className={styles.sendingDataContainer}>
          <TextArea
            bordered={false}
            className={styles.textArea}
            autoSize={{ minRows: 1, maxRows: 5 }}
            placeholder="Type here..."
            onChange={onInputChange}
            value={message}
            disabled={isLoading}
          />
          {
                      currentPreviewNote
                      && (
                      <div className={styles.attachmentContainer}>
                        <NotesCard
                          note={currentPreviewNote}
                          showClose
                          onNotesCardClick={() => () => {}}
                          onCloseAction={onCloseAction}
                        />
                      </div>
                      )
                  }
        </div>
        <Button
          icon={<SendOutlined className={styles.sendButton} />}
          type="link"
          onClick={onSendClick}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default ActivityTab;
