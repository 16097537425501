import React from 'react';
import { List, Skeleton } from 'antd';
import styles from './PostCardSkeleton.module.scss';

function PostCardSkeleton() {
  return (
    <List.Item className={styles.PostCardSkeleton}>
      <div className={styles.postHeader}>
        <Skeleton.Avatar className={styles.profileImage} active size="default" shape="circle" />
        <Skeleton.Input className={styles.userInfo} active size="default" />
      </div>
      <Skeleton className={styles.body} active paragraph={{ rows: 7 }} />
    </List.Item>
  );
}

export default PostCardSkeleton;
