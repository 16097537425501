import React from 'react';
import styles from './Welcome.module.scss';
import Logo from "../../../components/Logo/Logo";
import {Button} from "antd";

interface WelcomeProps {
    onYesAction: () => void;
    onSkipAction: () => void;
}

function Welcome(props: WelcomeProps) {
    const { onYesAction, onSkipAction } = props;
    return (
        <div className={styles.Welcome}>
            <Logo/>
            <div className={styles.welcomeText}>
                Welcome 👋
            </div>
            <div className={styles.subtitle} >
                Take a few seconds to setup your profile?
            </div>
            <Button className={styles.yesButton} size="large" type="primary" block onClick={onYesAction} >Yes</Button>
            <Button className={styles.skipButton} size="small" type="link" onClick={onSkipAction} >Skip</Button>
        </div>
    );
}

export default Welcome;
