import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Button, Image, Input, Menu, Modal, Skeleton, Spin,
} from 'antd';
import {
  SendOutlined, ArrowLeftOutlined, InfoCircleOutlined,
  MessageOutlined, PlusOutlined, SaveOutlined, FileImageOutlined, CameraOutlined,
} from '@ant-design/icons';
import {useInfiniteQuery, useQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './chat-group-screen.module.scss';
import ChatCard from '../MessagesScreen/ChatCard/ChatCard';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';
import MemberCard from '../../components/Cards/member-card/member-card';
import {
  deleteGroup, leaveGroup, updateChatRoom, makeAdmin, removeFromGroup,
} from '../../services-api/chatsService';
import ChatTimestampCard from '../../components/Cards/chat-timestamp-card/chat-timestamp-card';
import {fallBackUrl} from "../../utils/imageData";

type ChatScreenProps = {

};

const { confirm, error } = Modal;
const { TextArea } = Input;
function ChatGroupScreen(props: ChatScreenProps) {
  const { id, info } = useParams<{ id: string, info: string }>();
  const { pathname } = useLocation();
  let globalFileInput: any;
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentUser = useSelector<StoreState, User | null>((state) => state.currentUser);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [isAtTheEndMembers, setIsAtTheEndMembers] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const [infoView, setInfoView] = useState(!!info);
  const [file, setFile] = useState<Blob | null>(null);
  const [chatRoom, setChatRoom] = useState<String>('');
  const [message, setMessage] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const { isLoading: isChatRoomLoading, error: chatRoomError, data: currentChatRoom } = useQuery(`chat-group-${id}`, () => axios.get(`/api/chats/rooms/${id}`)
    .then((response) => {
      dispatch({ type: ActionTypes.SET_CURRENT_CHAT_ROOM, value: response.data });
      return response.data;
    }));

  const [groupName, setGroupName] = useState(currentChatRoom?.name);

  useEffect(() => () => {
    dispatch({ type: ActionTypes.SET_CURRENT_CHAT_USER, value: null });
    dispatch({ type: ActionTypes.SET_CURRENT_CHAT_ROOM, value: null });
  }, []);

  const onInputChange = (data: any) => {
    setMessage(data.target.value);
  };

  const onSendClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    axios.post('/api/chats/messages', { message, type: 'TEXT', chatRoom })
      .then((response) => {
        const newMessage = response.data;
        newMessage.user = currentUser;
        queryCache.setQueryData(`chat-group-messages-${id}-screen`, (oldData) =>
          // @ts-ignore
          oldData.map((x) => ({ ...x, messages: [newMessage, ...x.messages] })));
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        if (message) {
          setMessage('');
        }
      }).catch((error) => {
        console.log('error: ', error.response);
      });
  };

  const onBackClick = () => {
    history.replace('/messages');
  };

  const updateRead = (chatRoomId: string, messages: string[]) => {
    axios.put(`/api/chats/reads/${chatRoomId}`, { messages })
      .then((response) => {
        console.log('response: ', response);
      });
  };

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `chat-group-messages-${id}-screen`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/chats/messages/${id}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      if (data?.messages?.length > 0 && !infoView) {
        updateRead(data?.chatRoomId, data?.messages?.map((m: any) => m._id));
      }

      if (!chatRoom && data.chatRoomId) {
        setChatRoom(data.chatRoomId);
      }

      if (data?.messages?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const {
    data: membersData,
    isFetching: membersIsFetching,
    fetchMore: membersFetchMore,
  } = useInfiniteQuery(
    `chat-${id}-screen-members`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/chats/members/${id}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);

      if (data?.members?.length < limit) {
        setIsAtTheEndMembers(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onInfoClick = () => {
    if (infoView) {
      setInfoView(!infoView);
      history.push(`${pathname}`.replace('/info', ''));
    } else {
      setInfoView(!infoView);
      if (!pathname.includes('info')) {
        history.push(`${pathname}/info`);
      }
    }
  };

  const onSaveClick = () => {
    const data = new FormData();
    let dataChanged = false;

    if (groupName && groupName !== currentChatRoom?.name) {
      data.append('name', groupName);
      dataChanged = true;
    }

    if (file != null) {
      data.append('avatar', file, 'temp');
      dataChanged = true;
    }

    if (dataChanged) {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      dispatch(updateChatRoom(id, data, (success: boolean) => {
        if (success) {
          dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        }
      }));
    }
  };

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const onGroupNameChange = (data: any) => {
    setGroupName(data.target.value);
  };

  const onDeleteGroup = () => {
    confirm({
      title: 'Delete Group?',
      content: 'Are you sure you want to delete this group?',
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(deleteGroup(currentChatRoom!._id, (success:boolean) => {
            if (success) {
              history.replace('/messages');
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onLeaveGroup = () => {
    confirm({
      title: 'Leave Group?',
      content: 'Are you sure you want to leave this group?',
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(leaveGroup(currentChatRoom!._id, (success:boolean, message: string) => {
            if (success) {
              history.replace('/messages');
              resolve('');
            } else {
              error({
                title: 'Error',
                content: message || 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onAddMemberClick = () => {
    history.push(`/newgroupchatusers/${currentChatRoom?._id}`);
  };

  const onMakeAdminClick = (user: User) => {
    confirm({
      title: 'Make Admin?',
      content: `Are you sure you want to make ${user.displayName} an admin?`,
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(makeAdmin(currentChatRoom!._id, user._id, (success:boolean) => {
            if (success) {
              queryCache.invalidateQueries(`chat-${id}-screen-members`);
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onRemoveClick = (user: User) => {
    confirm({
      title: 'Remove from group?',
      content: `Are you sure you want to remove ${user.displayName} from this group?`,
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(removeFromGroup(currentChatRoom!._id, user._id, (success:boolean) => {
            if (success) {
              queryCache.invalidateQueries(`chat-${id}-screen-members`);
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const renderChatMessages = () => {
    const chatMessages = data?.map((page) => page.messages).flat();
    return chatMessages?.map((message, index) => (
      <div key={`chat-card-${index}`} className={styles.item}>
        <ChatTimestampCard
          previousCreatedAt={index !== 0 && chatMessages[index - 1].createdAt}
          currentCreatedAt={message.createdAt}
          nextCreatedAt={index !== chatMessages?.length - 1 && chatMessages[index + 1].createdAt}
        />
        <ChatCard
          currentUserId={currentUser!._id}
          previousMessage={index !== 0 && chatMessages[index - 1]}
          message={message}
          nextMessage={index !== chatMessages?.length && chatMessages[index + 1]}
          groupChat
        />
      </div>
    ));
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <CameraOutlined />
        <div>
          Camera
        </div>
      </Menu.Item>
      <Menu.Item>
        <FileImageOutlined />
        <div>
          Photo
        </div>
      </Menu.Item>
    </Menu>
  );

  return (currentUser
    && (
    <div className={styles.ChatScreen}>
      <div className={styles.chatHeader}>
        <div className={styles.back}>
          <Button
            size="large"
            icon={<ArrowLeftOutlined />}
            shape="circle"
            onClick={onBackClick}
          />
        </div>
        {
          currentChatRoom
            ? (
              <div className={styles.content}>
                <Image
                    className={styles.image}
                    src={currentChatRoom?.image}
                    alt={currentChatRoom?.image}
                    preview={false}
                    fallback={fallBackUrl}
                />
                <div className={styles.username}>
                  {currentChatRoom?.name}
                </div>
              </div>
            )
            : (
              <div className={styles.content}>
                <Skeleton.Avatar active size="large" shape="circle" />
                <Skeleton.Input style={{ marginLeft: '16px', width: 200 }} active size="large" />
              </div>
            )
        }
        <div className={styles.info} onClick={onInfoClick}>
          {
            infoView
              ? <MessageOutlined />
              : <InfoCircleOutlined />
          }
        </div>
      </div>
      {
        infoView
          ? (
            <div className={styles.container}>
              <div id="scrollableDivMembers" className={styles.membersContainer}>
                {
                  currentChatRoom?.role === 'ADMIN'
                  && (
                  <div className={styles.groupInfo}>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      name="avatar"
                      onChange={fileSelectedHandler}
                      ref={(fileInput) => globalFileInput = fileInput}
                      accept=".jpeg,.png"
                    />
                    <img
                      className={styles.groupImage}
                      src={file ? URL.createObjectURL(file) : currentChatRoom?.image}
                      alt={currentChatRoom?.image}
                      onClick={() => globalFileInput.click()}
                    />
                    <Input
                      className={styles.nameInput}
                      defaultValue={groupName || currentChatRoom?.name}
                      value={groupName || currentChatRoom?.name}
                      size="large"
                      allowClear
                      onChange={onGroupNameChange}
                    />
                    <Button
                      className={styles.saveButton}
                      type="primary"
                      icon={<SaveOutlined />}
                      size="large"
                      shape="round"
                      onClick={onSaveClick}
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  </div>
                  )
                }
                {
                  currentChatRoom?.role === 'ADMIN'
                  && <div className={styles.divider} />
                }
                <div className={styles.membersTitle}>
                  <div className={styles.title}>
                    Members
                    {' '}
                    <span style={{ fontWeight: 400 }}>
                      (
                      {currentChatRoom?.usersCount}
                      )
                    </span>
                  </div>
                  {
                    currentChatRoom?.role === 'ADMIN'
                    && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      size="large"
                      shape="circle"
                      onClick={onAddMemberClick}
                    />
                    )
                  }
                </div>
                <div className={styles.membersList}>
                  <InfiniteScroll
                    dataLength={membersData?.length || 0}
                    next={() => membersFetchMore()}
                    hasMore={(membersData?.length || 0) > 0 && !isAtTheEndMembers}
                    loader={(
                      <div className={styles.spinningContainer}>
                        <Spin />
                      </div>
                      )}
                    endMessage={(<p style={{ textAlign: 'center' }} />)}
                    scrollableTarget="scrollableDivMembers"
                  >
                    {
                      membersData?.map((page) => page.members).flat().map((user, index) => (
                        <MemberCard
                          key={`${index}-key`}
                          user={user}
                          role={currentChatRoom?.role || ''}
                          currentUserId={currentUser?._id || ''}
                          onMakeAdminClick={onMakeAdminClick}
                          onRemoveClick={onRemoveClick}
                        />
                      ))
                    }
                  </InfiniteScroll>
                </div>
              </div>
              <div className={styles.divider} />
              <Button
                className={styles.dangerButton}
                type="link"
                size="large"
                onClick={onLeaveGroup}
                danger
              >
                Leave Group
              </Button>
              {
                currentChatRoom?.role === 'ADMIN'
                && (
                <Button
                  className={styles.dangerButton}
                  type="link"
                  size="large"
                  onClick={onDeleteGroup}
                  danger
                >
                  Delete Group
                </Button>
                )
              }
            </div>
          )
          : (
            <div className={styles.container}>
              <div
                id="scrollableDiv"
                className={styles.messagesContainer}
              >
                <InfiniteScroll
                  style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  dataLength={data?.map((page) => page.messages).flat().length || 0}
                  next={() => fetchMore()}
                  hasMore={(data?.map((page) => page.messages).flat().length || 0) > 0 && !isAtTheEnd}
                  loader={(
                    <div className={styles.spinningContainer}>
                      <Spin />
                    </div>
                  )}
                  endMessage={(<p style={{ textAlign: 'center' }} />)}
                  inverse
                  scrollableTarget="scrollableDiv"
                  scrollThreshold={0.3}
                >
                  {
                    renderChatMessages()
                  }
                </InfiniteScroll>
              </div>
              <div className={styles.inputContainer}>
                {/*<Dropdown*/}
                {/*  overlay={menu}*/}
                {/*  placement="topLeft"*/}
                {/*  trigger={['click']}*/}
                {/*  disabled={isLoading}*/}
                {/*>*/}
                {/*  <PlusOutlined className={styles.addButton} />*/}
                {/*</Dropdown>*/}
                <TextArea
                  className={styles.textArea}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  placeholder="Type here..."
                  onChange={onInputChange}
                  value={message}
                  disabled={isLoading}
                />
                <Button
                  icon={<SendOutlined className={styles.sendButton} />}
                  type="link"
                  onClick={onSendClick}
                  loading={isLoading}
                />
              </div>
            </div>
          )
      }
    </div>
    )
  );
}

export default ChatGroupScreen;
