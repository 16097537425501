import React from 'react';
import styles from './PostPodcastSource.module.scss';
import { Medium } from '../../../models/Medium';

type PostPodcastSourceProps = {
  medium: Medium;
  onMediumClick: () => void;
  shelfIds: { [key: string]: string }
};

function PostPodcastSource(props: PostPodcastSourceProps) {
  const { medium, onMediumClick, shelfIds } = props;

  return (
    <div className={styles.PostPodcastSource}>
      <div className={styles.sourceInfo} onClick={onMediumClick}>
        <img
          className={styles.sourceImage}
          src={medium.medium.imageUrl.replace('http://', 'https://')}
          alt={medium.medium.imageUrl.replace('http://', 'https://')}
        />
        <div className={styles.sourceTitle}>
          {medium.title}
        </div>
      </div>
    </div>
  );
}

export default PostPodcastSource;
