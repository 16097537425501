import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import styles from './TopMediumScreen.module.scss';
import GoBack from '../../components/GoBack/GoBack';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import UserPostCard from './UserPostCard/UserPostCard';
import { ActionTypes } from '../../actions/types';
import DraggableList from './DraggableList/DraggableList';
import { getCorrectTitle } from '../../helpers/helpers';
import { Medium } from '../../models/Medium';
import EditTopRank from '../../components/EditTopRank/EditTopRank';
import {useQuery} from "react-query";

function TopMediumScreen() {
  const { mediumType, username } = useParams<{ mediumType: string, username: string }>();
  //const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const followingUserIds = useSelector<StoreState, any>((state) => state.followingUserIds);
  const [isEditing, setIsEditing] = useState(false);
  //const [mediums, setMediums] = useState<Medium[]>([]);
  const topRankingViewingUser = useSelector<StoreState, User | null>((state) => state.topRankingViewingUser);
  const dispatch = useDispatch();

  const { data, isLoading } = useQuery(`/api/ranking/${username}/${getCorrectTitle(mediumType)?.value}`, () =>
    axios.get(`/api/ranking/${username}/${getCorrectTitle(mediumType)?.value}`)
        .then((response) => {
          const { rankedList, user } = response.data;
          if (user) {
            dispatch({ type: ActionTypes.SET_TOP_RANKING_VIEWING_USER, topRankingViewingUser: user });
          }

          // if (rankedList) {
          //   const obj = data.rankedList.ranking;
          //   //setMediums(Object.values(obj));
          // }
          //dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
          return response.data;
        })
  , {})

  useEffect(() => {
    if (username) {
      //dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      // axios.get(`/api/ranking/${username}/${getCorrectTitle(mediumType)?.value}`)
      //   .then((response) => {
      //     const { rankedList, user } = response.data;
      //     if (user) {
      //       dispatch({ type: ActionTypes.SET_TOP_RANKING_VIEWING_USER, topRankingViewingUser: user });
      //     }
      //
      //     if (rankedList) {
      //       const obj = rankedList.ranking;
      //       setMediums(Object.values(obj));
      //     }
      //     dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      //   }).catch((error) => {
      //     console.log('error: ', error);
      //   });
    }
  }, []);

  const onFollowAction = () => {

  };

  const onEditingClick = () => {
    setIsEditing(!isEditing);
  };

  const onSaveAction = (items: Medium[]) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    axios.post(`/api/ranking/${getCorrectTitle(mediumType)?.value}`, { mediums: items })
      .then(() => {
        setIsEditing(false);
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  return (
    <div className={styles.TopMediumScreen}>
      <div className={styles.container}>
        {
          !data && isLoading === true &&
              <div>
                loading
              </div>
        }
        {/*{*/}
        {/*  topRankingViewingUser &&*/}
        {/*  <>*/}
        {/*    <GoBack />*/}
        {/*    <div className={styles.title}>*/}
        {/*      <span>{topRankingViewingUser?.displayName}</span>*/}
        {/*      <span>&apos;s Top Ranked </span>*/}
        {/*      <span>{mediumType}</span>*/}
        {/*    </div>*/}
        {/*    {*/}
        {/*      currentUser && topRankingViewingUser*/}
        {/*      && (*/}
        {/*          <UserPostCard*/}
        {/*              user={topRankingViewingUser}*/}
        {/*              onFollowAction={onFollowAction}*/}
        {/*              currentUserId={currentUser._id}*/}
        {/*              followingUserIds={followingUserIds}*/}
        {/*          />*/}
        {/*      )*/}
        {/*    }*/}
        {/*    {*/}
        {/*      currentUser && topRankingViewingUser && topRankingViewingUser.username === currentUser.username*/}
        {/*      && <EditTopRank isEditing={isEditing} onEditingClick={onEditingClick} />*/}
        {/*    }*/}
        {/*    <div className={`${styles.mediumsContainer}`}>*/}
        {/*      <DraggableList*/}
        {/*          isEditing={isEditing}*/}
        {/*          mediums={Object.values(data?.rankedList?.ranking)}*/}
        {/*          onSaveAction={onSaveAction}*/}
        {/*          mediumType={mediumType}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*}*/}
      </div>
    </div>
  );
}

export default TopMediumScreen;
