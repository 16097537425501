import React, {useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styles from './post-screen.module.scss';
import PostCard from '../HomeScreen/PostCard/PostCard';
import { Post } from '../../models/Post';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';
import { like, unlike } from '../../services-api/reactionService';
import WiltFullPost from '../../components/WiltFullPost/WiltFullPost';
import SummaryFullPost from '../../components/SummaryFullPost/SummaryFullPost';
import RatingCard from '../HomeScreen/RatingCard/RatingCard';
import GoBack from '../../components/GoBack/GoBack';
import PostCardSkeleton from '../HomeScreen/PostCardSkeleton/PostCardSkeleton';
import SummaryCardSkeleton from '../HomeScreen/SummaryCardSkeleton/SummaryCardSkeleton';
import QuizPostCard from "../HomeScreen/QuizPostCard/QuizPostCard";
import QuizModal from "../HomeScreen/QuizModal/QuizModal";
import {Spin, Tabs} from "antd";
import {useInfiniteQuery, useQuery} from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import QuizCard from "./quiz-card/quiz-card";

const { TabPane } = Tabs;
function PostScreen() {
  const { id } = useParams<{ id: string }>();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const selectedPost = useSelector<StoreState, Post | null>((state) => state.selectedPost);
  const reactionIds = useSelector<StoreState, any>((state) => state.reactionIds);
  const shelfIds = useSelector<StoreState, any>((state) => state.shelfIds);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const ref = useRef<any>(null);
  const dispatch = useDispatch();

  const {
    data: quizData,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
      `post-${id}-screen`,
      async (key, nextId = 0) => {
        const { data } = await axios.get(`/api/quiz/post/${id}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
        if (data?.quizzes?.length < limit) {
          setIsAtTheEnd(true);
        }
        return data;
      },
      {
        getFetchMore: (lastGroup) => lastGroup.nextId,
      },
  );

  const { isFetching: postIsFetching, error: postError, data } = useQuery(`post-screen-${id}`, () => axios.get(`/api/posts/${id}`)
      .then((response) => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data?.shelfIds } });
        //dispatch({ type: ActionTypes.SET_REACTION_IDS, value: response.data?.reactionId });
        dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, ...response.data?.reactionIds } });
        dispatch({ type: ActionTypes.SET_SELECTED_POST, post: response.data.post });
        return response.data as Post;
      }));

  // const {
  //   data: quizData,
  //   isFetching,
  //   fetchMore,
  // } = useInfiniteQuery(
  //     `post-${id}-screen`,
  //     async (key, nextId = 0) => {
  //       const { data } = await axios.get(`/api/quiz/post/${id}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
  //       if (data?.quizzes?.length < limit) {
  //         setIsAtTheEnd(true);
  //       }
  //       return data;
  //     },
  //     {
  //       getFetchMore: (lastGroup) => lastGroup.nextId,
  //     },
  // );

  const likePost = (post: Post, type: string, callBack: Function) => {
    if (currentUser) {
      dispatch(like(currentUser.username, post, type, callBack));
    }
  };

  const unlikePost = (post: Post, callBack: Function) => {
    if (currentUser) {
      dispatch(unlike(currentUser.username, post, callBack));
    }
  };

  const getPost = () => {
    axios.get(`/api/posts/${id}`)
      .then((response) => {
        dispatch({ type: ActionTypes.SET_REACTION_IDS, value: response.data.reactionId });
        dispatch({ type: ActionTypes.SET_SELECTED_POST, post: response.data.post });
      }).catch((error) => {
        console.log('PostScreen error: ', error);
      });
  };

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current?.offsetTop);
  const myRef = useRef(null);

  useEffect(() => {
    if (!isAtTheEnd) {
      scrollToRef(ref);
    }
  }, [currentUser]);

  const displayCorrectPost = (type: string | undefined) => {
    switch (type) {
      case '0':
        if (selectedPost) {
          return (
            <PostCard
              key="PostScreenPostCard"
              post={selectedPost}
              reactionIds={reactionIds}
              shelfIds={shelfIds}
              likePost={likePost}
              unlikePost={unlikePost}
            />
          );
        }
        break;
      case '1':
        if (selectedPost) {
          return (
            <WiltFullPost
              key="PostScreenWiltFullPost"
              post={selectedPost}
              likePost={likePost}
              unlikePost={unlikePost}
              reactionIds={reactionIds}
            />
          );
        }
        break;
      case '2':
        if (selectedPost) {
          return (
            <SummaryFullPost
              key="PostScreenSummaryFullPost"
              post={selectedPost}
              likePost={likePost}
              unlikePost={unlikePost}
              reactionIds={reactionIds}
            />
          );
        }
        break;
      case '3':
        if (selectedPost) {
          return (
            <RatingCard
              key="PostScreenRatingCard"
              post={selectedPost}
              reactionIds={reactionIds}
              shelfIds={shelfIds}
              likePost={likePost}
              unlikePost={unlikePost}
            />
          );
        }
        break;
      case '4':
        if (selectedPost) {
          return (
              <QuizPostCard
                  key="PostScreenQuizPostCard"
                  post={selectedPost}
                  reactionIds={reactionIds}
                  shelfIds={shelfIds}
                  likePost={likePost}
                  unlikePost={unlikePost}
              />
          );
        }
        break;
      default:
    }
  };

  const displayCorrectSkeleton = (type: string | undefined) => {
    switch (type) {
      case '0':
        return <PostCardSkeleton key="PostCardSkeleton" />;
      case '1':
        return <SummaryCardSkeleton key="SummaryCardSkeleton" />;
      case '2':
        return <SummaryCardSkeleton key="SummaryCardSkeleton" />;
      default:
    }
  };

  const callback = (key: string) => {
    console.log(key);
  }

  return (
    <div
        ref={myRef}
        className={styles.PostScreen}
    >
      <QuizModal />
      <GoBack maxWidth={ selectedPost && ['0','3','4'].includes(`${selectedPost.typeValue}`) ? '500px' : '800px'} />
      {
         selectedPost
           ? displayCorrectPost(`${selectedPost.typeValue}`)
           : displayCorrectSkeleton(`${data?.typeValue}`)
      }
      {
        selectedPost && ['4'].includes(`${selectedPost.typeValue}`) &&
            <div className={styles.content}>
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Quizzes" key="quizzes">
                  <InfiniteScroll
                      dataLength={quizData?.length || 0}
                      next={() => fetchMore()}
                      hasMore={(quizData?.length || 0) > 0 && !isAtTheEnd}
                      loader={(
                          <div className={styles.spinningContainer}>
                            <Spin />
                          </div>
                      )}
                      endMessage={(<p style={{ textAlign: 'center' }} />)}
                  >
                    {
                      quizData?.map((page) => page.quizzes).flat().map((quiz, index) => {
                        return <QuizCard key={`${index}-quiz`} quiz={quiz} post={selectedPost} />
                      })
                    }
                  </InfiniteScroll>
                </TabPane>
              </Tabs>
            </div>
      }
    </div>
  );
}

export default PostScreen;
