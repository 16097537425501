import React from 'react';
import styles from './DraftQuote.module.scss';
import { List } from "antd";
import {convertTimeToDaysAgo} from "../../../helpers/helpers";
import {Post} from "../../../models/Post";

interface DraftQuoteProps {
    post: Post;
}

function DraftQuote(props: DraftQuoteProps) {

    const { _id, user, medium, image, createdAt, title } = props.post;

    return (
        <List.Item className={styles.DraftQuote} >
            <div className={styles.header} >
                <img
                    className={styles.profileImage}
                    src={user.image}
                    alt={user.image}
                />
                <div className={styles.username} >
                    <b>
                        {user.displayName}
                    </b>
                    <span style={{ paddingLeft: '4px'}} >
                        @{user.username}
                    </span>
                </div>
            </div>
            <div className={styles.title}>
                {title}
            </div>
            <img className={styles.body}
                 src={image?.replace("http://", "https://")}
                 alt={image?.replace("http://", "https://")}
            />
            <div className={styles.source}>
                <div className={styles.sourceInfo}>
                    <img
                        className={styles.sourceImage}
                        src={medium.imageUrl.replace("http://", "https://")}
                        alt={medium.imageUrl.replace("http://", "https://")}
                    />
                    <div className={styles.sourceTitle} >
                        {medium.title}
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.timeStamp}>
                    {convertTimeToDaysAgo(createdAt)}
                </div>
            </div>
        </List.Item>
    );
}

export default DraftQuote;
