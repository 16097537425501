import React from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import renderHTML from 'react-render-html';
import styles from './PreviewSummary.module.scss';
import { User } from '../../../models/User';
import { StoreState } from '../../../reducers/_RootReducers';
import { CreateQuote } from '../../../models/create-models/CreateQuote';

interface PreviewSummaryProps {

}

function PreviewSummary() {
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);

  return (
    <div className={styles.PreviewSummary}>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          {/* <WiltHeader/> */}
          <div className={styles.title}>
            {createQuote.summaryTitle}
          </div>
          <div>
            <span className={styles.summaryLabel}>summary</span>
          </div>
          <div className={styles.headerInfo}>
            <div className={styles.user}>
              <img
                className={styles.userImage}
                src={currentUser.image}
                alt={currentUser.image}
              />
              <div className={styles.userInfo}>
                <div className={styles.username}>
                  {currentUser.username}
                </div>
                <div className={styles.timestamp}>
                  {moment(new Date().toString()).format('LLL')}
                </div>
              </div>
              <img
                className={styles.mediumImage}
                src={createQuote.medium?.imageUrl.replace('http://', 'https://')}
                alt={createQuote.medium?.imageUrl.replace('http://', 'https://')}
              />
            </div>
            <div className={styles.medium} />
          </div>
        </div>
      </div>
      <div className={styles.body}>
        {
                    renderHTML(createQuote.summaryText)
        }
        {/* <div className={styles.timeline}> */}
        {/*    { */}
        {/*        createQuote.summaryItems?.map((val,index) => { */}
        {/*            return (<Paragraph className={styles.textItem} key={`${index}`} >{val.text}</Paragraph>) */}
        {/*        }) */}
        {/*    } */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default PreviewSummary;
