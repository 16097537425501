import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { List, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import { RightOutlined } from '@ant-design/icons';
import styles from './NotificationScreen.module.scss';
import NotificationCard from '../../components/Cards/NotificationCard/NotificationCard';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';
import Empty from '../../components/Empty/Empty';
import NotificationCardSkeleton from './NotificationCardSkeleton/NotificationCardSkeleton';

function NotificationScreen() {
  const history = useHistory();
  const [oneCall, setOneCall] = useState(true);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const followingUserIds = useSelector<StoreState, any>((state) => state.followingUserIds);
  const followRequestUserIds = useSelector<StoreState, any>((state) => state.followRequestUserIds);
  const requestsCount = useSelector<StoreState, any>((state) => state.requestsCount);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const timeStamp = new Date();
  const limit = 24;
  const dispatch = useDispatch();

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    'notification-screen-key',
    async (key, nextId = 0) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      const { data } = await axios.get(`/api/notifications?page=${nextId}&limit=${24}&timeStamp=${timeStamp}`);

      dispatch({ type: ActionTypes.SET_FOLLOW_REQUEST_USER_IDS, value: { ...followRequestUserIds, ...data?.followRequestUserIds } });
      dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: { ...followingUserIds, ...data?.followingUserIds } });
      dispatch({ type: ActionTypes.SET_REQUESTS_COUNT, value: data?.requestsCount });
      if (data?.notifications?.length < limit) {
        setIsAtTheEnd(true);
      }

      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  useEffect(() => {
    if (currentUser) {
      if (oneCall) {
        setOneCall(false);
        dispatch({ type: ActionTypes.CLEAR_NOTIFICATIONS });
      }
    }
  }, [data]);

  const clearNotifications = () => {
      axios.get('/api/notifications/clear/current-user')
  }

  const onRequestsClick = () => {
    history.push('/requests');
  };

  return (currentUser
    ? (
      <div className={styles.NotificationScreen}>
        {
          requestsCount > 0
            && (
            <div className={styles.container}>
              <div className={styles.requests} onClick={onRequestsClick}>
                <div className={styles.noRequests}>
                  <div className={styles.requestCount}>{requestsCount}</div>
                </div>
                <div className={styles.textContainer}>
                  <div className={styles.title}>
                    Requests
                  </div>
                  <div className={styles.subtitle}>
                    Approve or ignore requests
                  </div>
                </div>
                <div>
                  <RightOutlined />
                </div>
              </div>
            </div>
            )
       }
        {
            !data?.map((page) => page.notifications).flat().length
              ? (
                isFetching
                  ? (
                    <List
                      className={styles.container}
                      grid={{ gutter: 0, column: 1 }}
                      dataSource={[1, 1, 1, 1]}
                      renderItem={(item, index) => <List.Item key={`${index}`}><NotificationCardSkeleton /></List.Item>}
                    />
                  ) : (
                    currentUser?.requestCount === 0
                      && (
                        <Empty
                          title="No notifications yet"
                          subTitle={"When users follow or like your posts you'll see it here"}
                          icon="search"
                          buttonTitle="Search"
                          url="/search"
                          screen="notifications"
                        />
                      )
                  )
              ) : (
                <InfiniteScroll
                  dataLength={data?.map((page) => page.notifications).flat().length || 0}
                  next={() => fetchMore()}
                  hasMore={(data?.map((page) => page.notifications).flat().length || 0) > 0 && isAtTheEnd === false}
                  loader={(
                    <div className={styles.spinningContainer}>
                      <Spin />
                    </div>
                      )}
                  endMessage={(
                    <p style={{ textAlign: 'center' }} />
                      )}
                >
                  <div className={styles.container}>
                    {
                        data?.map((page) => page.notifications).flat().map((item, index) => (
                            <NotificationCard
                                key={`NotificationCard${index}`}
                                notification={item}
                                followingUserIds={followingUserIds}
                                followRequestUserIds={followRequestUserIds}
                                currentUserId={currentUser?._id}
                            />
                        ))
                    }
                  </div>
                </InfiniteScroll>
              )
       }
      </div>
    ) : (
      <List
        className={styles.container}
        grid={{ gutter: 0, column: 1 }}
        dataSource={[1, 1, 1]}
        renderItem={(item, index) => <List.Item key={`${index}`}><NotificationCardSkeleton /></List.Item>}
      />
    )
  );
}

export default NotificationScreen;
