import React from 'react';
import { Skeleton } from 'antd';
import styles from './club-card-skeleton.module.scss';

function ClubCardSkeleton() {
  return (
    <div className={styles.ClubCardSkeleton}>
      {/* <Skeleton active paragraph={{ rows: 1 }} /> */}
      <Skeleton.Input className={styles.item} style={{ width: '100% !important' }} active size="large" />
    </div>
  );
}

export default ClubCardSkeleton;
