import React from 'react';
import styles from './snippet.module.scss';
import {Button} from "antd";
import { ArrowRightOutlined } from '@ant-design/icons';

type SnippetProps = {
    snippetTitle: string;
    mediumTitle: string;
    mediumImageUrl: string;
    actionTitle: string;
    onAction: Function;
}

function Snippet(props: SnippetProps) {
    const { snippetTitle, mediumTitle, mediumImageUrl, actionTitle, onAction } = props;
    return (
        <div className={styles.Snippet}>
            <div className={styles.container}>
                <div className={styles.header}>
                    {snippetTitle}
                </div>
                <div className={styles.body}>
                    <div className={styles.title}>
                        {mediumTitle}
                    </div>
                    <img className={styles.image} src={mediumImageUrl} alt="" />
                </div>
                <div className={styles.footer}>
                    <Button
                        type="link"
                        onClick={() => onAction()}
                    >
                        {actionTitle}
                        <ArrowRightOutlined />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Snippet;
