import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './podcast-episode-card.module.scss';
import { Medium } from '../../../../models/Medium';
import {convertMonthNumberToMonthText, convertTimeToDaysAgo} from '../../../../helpers/helpers';
import {Typography} from "antd";

type PodcastEpisodeCardProps = {
  medium: Medium;
  _id: string;
};

const { Paragraph } = Typography;
function PodcastEpisodeCard(props: PodcastEpisodeCardProps) {
  const { medium } = props;
  const [releaseDate] = useState(new Date(medium.releaseDate));
  const history = useHistory();

  const onEpisodeClick = () => {
    history.push(`/episodes/${medium._id}`);
  };

  return (
    <div className={styles.PodcastEpisodeCard} onClick={onEpisodeClick}>
      <div className={styles.dateContainer}>
        <div className={styles.day}>
          {releaseDate.getDate()}
        </div>
        <div className={styles.month}>
          {convertMonthNumberToMonthText(releaseDate.getMonth() + 1)}
        </div>
        <div className={styles.year}>
          {releaseDate.getFullYear()}
        </div>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.title}>
          {medium.title}
        </div>
        <Paragraph
            className={styles.description}
            ellipsis={{ rows: 1, expandable: false }}
        >
          {medium.description}
        </Paragraph>
      </div>
    </div>
  );
}

export default PodcastEpisodeCard;
