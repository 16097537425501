import React from 'react';
import { Skeleton } from 'antd';
import styles from './FriendUserCardSkeleton.module.scss';

function FriendUserCardSkeleton() {
  return (
    <div className={styles.FriendUserCardSkeleton}>
      <Skeleton avatar active paragraph={{ rows: 1 }} />
    </div>
  );
}

export default FriendUserCardSkeleton;
