import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {useInfiniteQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import { List, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './RequestsScreen.module.scss';
import { ActionTypes } from '../../actions/types';
import NotificationCardSkeleton from '../NotificationScreen/NotificationCardSkeleton/NotificationCardSkeleton';
import Empty from '../../components/Empty/Empty';
import RequestCard from './RequestCard/RequestCard';
import GoBack from '../../components/GoBack/GoBack';
import { confirmFollowRequest, deleteFollowRequest } from '../../services-api/userService';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';

function RequestsScreen() {
  const history = useHistory();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(24);
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    'requests-screen',
    async (key, nextId = 0) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      const { data } = await axios.get(`/api/requests?page=${nextId}&limit=${limit}&timeStamp=${timeStamp}`);

      if (data?.requests?.length < limit) {
        setIsAtTheEnd(true);
      }

      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const removeRequestFromList = (requestId: string) => {
    queryCache.setQueryData('requests-screen', (oldData) =>
    // @ts-ignore
      oldData.map((x) => ({ ...x, requests: [...x.requests.filter((x: any) => x._id !== requestId)] })));
    dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, requestCount: currentUser.requestCount - 1 } });
  };

  const onConfirmClick = (requestId: string) => {
    dispatch(confirmFollowRequest(requestId, () => {
      removeRequestFromList(requestId);
    }));
  };

  const onDeleteClick = (requestId: string) => {
    dispatch(deleteFollowRequest(requestId, () => {
      removeRequestFromList(requestId);
    }));
  };

  return (
    <div className={styles.RequestsScreen}>
      <div className={styles.goBack}>
        <GoBack />
      </div>
      <div className={styles.container}>
        {
              isFetching && !data
                ? (
                  <List
                    className={styles.container}
                    grid={{ gutter: 0, column: 1 }}
                    dataSource={[1, 1, 1]}
                    renderItem={(item, index) => <List.Item key={`${index}`}><NotificationCardSkeleton /></List.Item>}
                  />
                ) : (
                  <InfiniteScroll
                    dataLength={data?.length || 0}
                    next={() => fetchMore()}
                    hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
                    loader={(
                      <div className={styles.spinningContainer}>
                        <Spin />
                      </div>
                          )}
                    endMessage={(
                      <p style={{ textAlign: 'center' }} />
                          )}
                  >
                    <List
                      className={styles.container}
                      dataSource={data && data?.map((page) => page.requests).flat()}
                      split={false}
                      locale={{
                        emptyText: <Empty
                          title="No requests yet"
                          subTitle={"When users send you requests you'll see it here"}
                          icon="search"
                          buttonTitle="Search"
                          url="/search"
                          screen="notifications"
                        />,
                      }}
                      renderItem={(item, index) => (
                        <RequestCard
                          key={`RequestCard${index}`}
                          request={item}
                          history={history}
                          dispatch={dispatch}
                          onConfirmClick={onConfirmClick}
                          onDeleteClick={onDeleteClick}
                        />
                      )}
                    />
                  </InfiniteScroll>
                )
          }
      </div>
    </div>
  );
}

export default RequestsScreen;
