import { Dispatch } from 'redux';
import axios from 'axios';

export const updateChatRoom = (chatRoomId: string, data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put(`/api/chats/rooms/${chatRoomId}`, data)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      callback(false);
    });
};

export const deleteGroup = (chatRoomId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/chats/rooms/${chatRoomId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error);
      callback(false);
    });
};

export const leaveGroup = (chatRoomId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/chats/leave/rooms/${chatRoomId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error.response.data);
      callback(false, error.response.data);
    });
};

export const removeFromGroup = (chatRoomId: string, userId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/chats/remove/${userId}/rooms/${chatRoomId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error);
      callback(false);
    });
};

export const makeAdmin = (chatRoomId: string, userId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put(`/api/chats/rooms/${chatRoomId}/users/${userId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error);
      callback(false);
    });
};
