import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton, Spin } from 'antd';
import {useInfiniteQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './profile-mediums-grid.module.scss';
import EditGrid from '../../../components/EditGrid/EditGrid';
import { Medium } from '../../../models/Medium';
import MediumsGridCard from './mediums-grid-card/mediums-grid-card';
import PodcastsGridCard from './podcasts-grid-card/podcasts-grid-card';
import Empty from '../../../components/Empty/Empty';
import DraggableList from '../../TopMediumScreen/DraggableList/DraggableList';
import RankingGrid from '../ranking-grid/ranking-grid';

type ProfileMediumsGridProps = {
  isEditing: boolean;
  isCurrentUser: boolean;
  username: string;
  onEditingClick: () => void;
  type: string;
  mediumCount?: number;
  onRemoveMediumClick: (mediumShelf: any, callback: (id: string) => void) => void;
  onMediumClick: (medium: Medium) => void;
  onSaveAction: (items: Medium[], callback: (data: any) => void) => void;
  title: string;
  subTitle: string;
  icon: string;
  url: string;
  buttonTitle: string;
  screenItem: string;
};

function ProfileMediumsGrid(props: ProfileMediumsGridProps) {
  const {
    isEditing, isCurrentUser, username, onEditingClick, type, onRemoveMediumClick, onMediumClick,
    title, subTitle, icon, url, buttonTitle, screenItem, onSaveAction, mediumCount,
  } = props;
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [sortBy, setSortBy] = useState(search?.replace('?', '') || 'createdAt');
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(15);
  const [width, setWidth] = useState(window.innerWidth);
  const queryCache = useQueryCache();

  const {
    data,
    isFetching,
    isLoading,
    fetchMore,
  } = useInfiniteQuery(
    `${username}-${type}-tab-${sortBy}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/users/${username}/mediums?currentTab=${type}&sortBy=${sortBy}&limit=${limit}&page=${nextId}&timeStamp=${timeStamp}`);
      if (data?.mediums?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
      enabled: !!username
    },
  );

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);
  }, [data]);

  const tempOnRemoveMediumClick = (mediumShelf: any) => {
    onRemoveMediumClick(mediumShelf, (mediumId) => {
      queryCache.setQueryData(`${username}-${type}-tab-${sortBy}`, (oldData) =>
        // @ts-ignore
        oldData.map((x) => ({ ...x, mediums: [...x.mediums.filter((x: any) => x._id !== mediumId)] })));
    });
  };

  const convertArrayToObject = (array: any[], key: any) => {
    const initialValue = {};
    return array.reduce((obj, item) => ({
      ...obj,
      [item[key]]: item,
    }), initialValue);
  };

  const onTempSaveAction = (items: Medium[]) => {
    onSaveAction(items, (newRanking: any[]) => {
      const newData = convertArrayToObject(newRanking.map((x: any, index: number) => ({ ...x, order: index })), 'order');
      queryCache.setQueryData(`${username}-${type}-tab-${sortBy}`, (oldData) =>
        // @ts-ignore
        oldData.map((x) => ({ ...x, mediums: { ...x.mediums, ranking: newData } })));
    });
  };

  const triggerSortMediums = (updatedSortBy: string) => {
    setSortBy(updatedSortBy);
    setIsAtTheEnd(false);
    history.replace(`${pathname}?${updatedSortBy}`);
  };

  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };

  const dataIsEmptyAndIsCurrentUser = () => isCurrentUser && (data?.filter((x) => x.mediums).length === 0 || (data?.filter((x) => x.mediums).map((page) => page.mediums).flat().length && Object.values(data?.map((page) => page.mediums).flat()[0].ranking).length === 0));

  return (
    <div className={styles.ProfileMediumsGrid}>
      <EditGrid
        topRanked
        isEditing={isEditing}
        isCurrentUser={isCurrentUser}
        triggerSort={triggerSortMediums}
        onEditingClick={onEditingClick}
        mediumType={type}
        mediumCount={mediumCount}
      />
      {
        sortBy === 'ranking' ? (
          <div className={styles.topRanking}>
            {
               isEditing || dataIsEmptyAndIsCurrentUser()
                 ? (
                   <DraggableList
                     isEditing
                     mediums={data && data?.filter((x) => x.mediums).map((page) => page.mediums).flat().length > 0
                       ? Object.values(data?.map((page) => page.mediums).flat()[0].ranking) : []}
                     onSaveAction={onTempSaveAction}
                     mediumType={type}
                   />
                 )
                 : <RankingGrid
                       type={type}
                       username={username}
                       onMediumClick={onMediumClick}
                       mediums={data?.map((page) => page.mediums).flat() || []}
                       isLoading={isLoading}
                   />
             }
          </div>
        )
          : (
            <InfiniteScroll
              className={styles.gridList}
              next={() => fetchMore()}
              hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
              loader={(
                <div className={styles.spinningContainer}>
                  <Spin />
                </div>
                )}
              dataLength={data?.length || 0}
            >
              {
                isFetching && !data && [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item: any, index: number) => (
                  <div key={`skel-${index}`} className={styles.skeletonItem}>
                    <Skeleton
                      className={styles.imageContainer}
                      active
                      title={{ style: { height: width > 800 ? '390px' : width * 0.5, width: 'auto !important' } }}
                      paragraph={{ rows: 0 }}
                    />
                  </div>
                ))
              }
              {
                data?.map((page) => page.mediums).flat().length === 0
                && (
                <div className={styles.emptyView}>
                  {
                        isCurrentUser
                          ? (
                            <Empty
                              title={title}
                              subTitle={subTitle}
                              icon={icon}
                              url={url}
                              buttonTitle={buttonTitle}
                              screen={screenItem}
                            />
                          )
                          : (
                            <div style={{ textAlign: 'center' }}>
                              <h3>
                                @
                                {username}
                                {' '}
                                hasn’t added any
                                {' '}
                                {type}
                              </h3>
                              <div>
                                When they do, those
                                {' '}
                                {type}
                                {' '}
                                will show up here.
                              </div>
                            </div>
                          )
                      }
                </div>
                )
              }
              {
                data?.map((page) => page.mediums).flat().length !== 0 && data?.map((page) => page.mediums).flat().map((item: any) => {
                  if (type === 'podcasts') {
                    return (
                      <PodcastsGridCard
                        key={item?._id}
                        item={item}
                        isEditing={isEditing}
                        onMediumClick={onMediumClick}
                        tempOnRemoveMediumClick={tempOnRemoveMediumClick}
                      />
                    );
                  }
                  return (
                    <MediumsGridCard
                      key={item?._id}
                      item={item}
                      isEditing={isEditing}
                      onMediumClick={onMediumClick}
                      tempOnRemoveMediumClick={tempOnRemoveMediumClick}
                    />
                  );
                })
              }
            </InfiniteScroll>
          )
      }
    </div>
  );
}

export default ProfileMediumsGrid;
