import React, { useEffect, useState } from 'react';
import { Button, Popover, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './topic-post-card.module.scss';
import likeIcon from '../../../assets/icons/icons8-facebook-like-50.png';
import commentIcon from '../../../assets/icons/icons8-topic-50.png';
import shareIcon from '../../../assets/icons/icons8-forward-arrow-50.png';
import newLike from '../../../assets/icons/new-like.png';
import newHeart from '../../../assets/icons/new-heart.png';
import newLikeCircle from '../../../assets/icons/new-like-circle.png';
import newHeartCircle from '../../../assets/icons/new-heart-circle.png';
import newLolCircle from '../../../assets/icons/new-lol-circle.png';
import { TopicPost } from '../../../models/TopicPost';
import { convertTimeToDaysAgo } from '../../../helpers/helpers';
import { ActionTypes } from '../../../actions/types';
import NotesCard from '../../storynotes-notes-screen/notes-card/notes-card';
import { Note } from '../../../models/Note';
import {Section} from "../../../models/Template";
import {
  addDays, addToSection,
  availableDate,
  getCurrentSpot,
  getSectionStartDate
} from "../../../services-util/club-story-service-util";

type TopicPostCardProps = {
  topicPost: TopicPost;
  onPostClick: (id: string) => void;
  onReactionAction: (topicPost: TopicPost, type: string) => void;
  isReactionsOpen: boolean;
  onReactionClick: (topicPost: TopicPost) => void;
  onPreviewNoteAction: (note: Note) => void;
  currentId: string;
  reactionIds: { [key: string]: any },
  roundBorder?: boolean;
  hideTimeStamp?: boolean;
  currentTopic?: any;
  timeStamp: Date;
};

const { Paragraph } = Typography;
function TopicPostCard(props: TopicPostCardProps) {
  const {
    topicPost, onPostClick, onReactionAction, isReactionsOpen,
    onReactionClick, currentId, reactionIds, onPreviewNoteAction,
    roundBorder, hideTimeStamp, currentTopic, timeStamp,
  } = props;
  const dispatch = useDispatch();

  const getSumOfPrevious = (index: number): number => {
    const previousSections = currentTopic?.template?.sections?.slice(0, index);
    let sum = 0;
    previousSections?.forEach((previousSection: Section) => {
      sum = sum + previousSection.subChapters.length;
    })
    return sum;
  }

  const nextSectionId = addToSection((topicPost?.subSectionId || topicPost?.sectionId), currentTopic?.rate, currentTopic?.template?.sections || [])
  const availableDateVal = availableDate(nextSectionId, currentTopic?.template?.sections || [], new Date(currentTopic?.startDate), timeStamp, currentTopic?.rate, currentTopic?.frequency)
  const isNotActiveYet = availableDateVal > timeStamp;

  useEffect(() => {
    console.log("louis - topicPost: ",topicPost.note);
  }, [])

  const onLikeClick = () => {
    onReactionAction(topicPost, 'like');
  };

  const onLoveClick = () => {
    onReactionAction(topicPost, 'love');
  };

  const onLolClick = () => {
    onReactionAction(topicPost, 'lol');
  };

  const content = (
    <div className={styles.reactionsIcons}>
      <img
        className={styles.reactionIcon}
        src={newLike}
        alt=""
        onClick={onLikeClick}
      />
      <img
        className={styles.reactionIcon}
        src={newHeart}
        alt=""
        onClick={onLoveClick}
      />
      <img
        className={styles.reactionIcon}
        src={newLolCircle}
        alt=""
        onClick={onLolClick}
      />
    </div>
  );

  const getCurrentReaction = () => {
    if (reactionIds[`${topicPost._id}`] && reactionIds[`${topicPost._id}`].typeText === 'like') {
      return (
        <img
          className={styles.reactionImage}
          src={newLike}
          onClick={() => onReactionClick(topicPost)}
        />
      );
    }

    if (reactionIds[`${topicPost._id}`] && reactionIds[`${topicPost._id}`].typeText === 'love') {
      return (
        <img
          className={styles.reactionImage}
          src={newHeart}
          onClick={() => onReactionClick(topicPost)}
        />
      );
    }

    if (reactionIds[`${topicPost._id}`] && reactionIds[`${topicPost._id}`].typeText === 'lol') {
      return (
        <img
          className={styles.reactionImage}
          src={newLolCircle}
          onClick={() => onReactionClick(topicPost)}
        />
      );
    }

    return (
      <img
        className={styles.defaultReactionImage}
        src={likeIcon}
        onClick={() => onReactionClick(topicPost)}
      />
    );
  };

  const onVisibleChange = (visible: boolean) => {
    dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: visible });
  };

  const displayCount = () => {
    let count = 0;

    if (topicPost?.lovesCount) {
      count += topicPost.lovesCount;
    }

    if (topicPost?.likesCount) {
      count += topicPost.likesCount;
    }

    if (topicPost?.laughsCount) {
      count += topicPost.laughsCount;
    }

    return count > 0 ? count : undefined;
  };

  const getContent = () => {

    if (!topicPost.text && isNotActiveYet) {
      return <div className={styles.notAvailable}>
        Note will be visible on {availableDateVal.toLocaleString(undefined, {
        day: 'numeric', weekday: 'short', month: 'short',
      })}
      </div>
    }

    if (topicPost.image) {
      return (
        <img
          className={styles.postImage}
          src={topicPost.image}
          alt=""
        />
      );
    }

    if (topicPost.note) {
      return (
        <NotesCard
          note={topicPost.note}
          hideTimeStamp={hideTimeStamp}
          onNotesCardClick={() => onPreviewNoteAction(topicPost.note!)}
        />
      );
    }
  };

  return (
    <div
      className={`${styles.TopicPostCard} ${roundBorder ? styles.borderAround : styles.borderTop}`}
      onClick={() => onPostClick(topicPost._id)}
    >
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={topicPost?.user?.image}
        />
        <Popover
          placement="topLeft"
          visible={isReactionsOpen && currentId === topicPost._id}
          onVisibleChange={onVisibleChange}
          content={content}
          trigger="click"
          arrowContent={null}
        >
          {
            getCurrentReaction()
          }
        </Popover>
      </div>
      <div className={styles.textInfo}>
        <div className={styles.titleContainer}>
          <Paragraph
            ellipsis={{ rows: 1, expandable: false, suffix: `  @${topicPost?.user?.username} · ${convertTimeToDaysAgo(topicPost.createdAt)}` }}
          >
            <span className={styles.title}>{topicPost?.user?.displayName}</span>
          </Paragraph>
        </div>
        <div className={styles.postContainer}>
          <div className={styles.postText}>
            {topicPost.text}
          </div>
          {
              getContent()
          }
          <div className={styles.footer}>
            <div className={styles.leftStats}>
              {
                topicPost?.likesCount !== undefined && topicPost?.likesCount > 0
                && (
                <img
                  className={styles.reactionIcon1}
                  src={newLikeCircle}
                  alt=""
                />
                )
              }
              {
                topicPost?.lovesCount !== undefined && topicPost?.lovesCount > 0
                && (
                <img
                  className={styles.reactionIcon2}
                  src={newHeartCircle}
                  alt=""
                />
                )
              }
              {
                topicPost?.laughsCount !== undefined && topicPost?.laughsCount > 0
                && (
                <img
                  className={styles.reactionIcon3}
                  src={newLolCircle}
                  alt=""
                />
                )
              }
              <div className={styles.reactionNumber}>
                {displayCount()}
              </div>
            </div>
            <div className={styles.rightStats}>
              <img
                className={styles.commentIcon}
                src={commentIcon}
                alt=""
              />
              <div className={styles.commentNumber}>
                1.5K
              </div>
              <img
                className={styles.shareIcon}
                src={shareIcon}
                alt=""
              />
              <div className={styles.shareNumber}>
                1.5K
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopicPostCard;
