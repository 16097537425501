import React from 'react';
import { Skeleton } from 'antd';
import styles from './NotificationCardSkeleton.module.scss';

function NotificationCardSkeleton() {
  return (
    <div className={styles.NotificationCardSkeleton}>
      <Skeleton avatar active paragraph={{ rows: 1 }} />
    </div>
  );
}

export default NotificationCardSkeleton;
