import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Tabs } from 'antd';
import styles from './sport-event-screen.module.scss';
import gonzaga from '../../assets/images/gonzaga.jpg';
import baylor from '../../assets/images/baylor.png';
import michigan from '../../../../assets/images/michigan.png';
import TabTitle from '../../components/TabTitle/TabTitle';
import Sources from '../medium-screen/Sources/Sources';

const { TabPane } = Tabs;
function SportEventScreen() {
  const { id } = useParams();
  const history = useHistory();

  const onTabChange = () => {

  };

  const onEnterClick = () => {
    history.push('/stadiums/123/home');
  };

  return (
    <div className={styles.SportEventScreen}>
      <div className={styles.container}>
        <div className={styles.event}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              Champions League Final
            </div>
            <div className={styles.date}>
              22nd March, &apos;21
            </div>
          </div>
          <div className={styles.teams}>
            <div className={styles.teamContainer}>
              <div className={styles.team}>
                <img
                  className={styles.image}
                  src={baylor}
                  alt=""
                />
                <div
                  className={styles.name}
                >
                  Baylor University
                </div>
              </div>
              <div className={styles.rightScore}>
                100
              </div>
            </div>
            <div className={styles.vs}>
              VS
            </div>
            <div
              className={styles.teamContainer}
            >
              <div className={styles.leftScore}>
                100
              </div>
              <div className={styles.team}>
                <img
                  className={styles.image}
                  src={gonzaga}
                  alt=""
                />
                <div
                  className={styles.name}
                >
                  Gonzaga University
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <Button>Add to wishlist</Button>
          </div>
        </div>
        <div className={styles.content}>
          <Tabs tabBarGutter={0} defaultActiveKey="sources" onChange={onTabChange}>
            <TabPane tab={<TabTitle text="Stadium" />} key="stadium">
              <div className={styles.stadium}>
                <div className={styles.leftStadium}>
                  <div className={styles.innerLeft}>
                    <div className={styles.support}>
                      <img
                        className={styles.supportImage}
                        src={baylor}
                        alt=""
                      />
                      <div className={styles.supportTitle}>
                        Enter as Baylor University support
                      </div>
                      <Button
                        className={styles.enter}
                        size="large"
                        type="primary"
                        onClick={onEnterClick}
                      >
                        Enter
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={styles.rightStadium}>
                  <div className={styles.innerRight}>
                    <div className={styles.support}>
                      <img
                        className={styles.supportImage}
                        src={gonzaga}
                        alt=""
                      />
                      <div className={styles.supportTitle}>
                        Enter as Gonzaga University support
                      </div>
                      <Button
                        className={styles.enter}
                        size="large"
                        type="primary"
                        onClick={onEnterClick}
                      >
                        Enter
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab={<TabTitle text="Predictions" />} key="predictions">
              Predictions
            </TabPane>
            <TabPane tab={<TabTitle text="Line Up" />} key="line-up">
              Line Up
            </TabPane>
            <TabPane tab={<TabTitle text="Live Ticker" />} key="live-ticker">
              Line Up
            </TabPane>
            <TabPane tab={<TabTitle text="Watch Party" />} key="watch-party">
              Watch Party
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default SportEventScreen;
