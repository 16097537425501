import React, { useState } from 'react';
import {
  Input,
  Modal, Select, Skeleton, Spin,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import styles from './my-notes-modal.module.scss';
import languages from '../../../utils/languages.json';
import NotesCard from '../../storynotes-notes-screen/notes-card/notes-card';
import { StoreState } from '../../../reducers/_RootReducers';
import { ActionTypes } from '../../../actions/types';
import { Note } from '../../../models/Note';

const { Search } = Input;

type MyNotesModalProps = {
  onNotesCardClick: (event: any, note: Note) => void;
};

function MyNotesModal(props: MyNotesModalProps) {
  const { onNotesCardClick } = props;
  const [isAtNotesEnd, setIsAtNotesEnd] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchNotesQuery, setSearchNotesQuery] = useState('');
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const isMyNotesOpen = useSelector<StoreState, boolean>((state) => state.isMyNotesOpen);
  const dispatch = useDispatch();

  const {
    isLoading: isNotesLoading, data: notesData, isFetching: isNotesFetching, fetchMore: fetchMoreNotes,
  } = useInfiniteQuery(`my-notes-${searchNotesQuery}`,
    async (key, nextId = 0) => {
      const { data: noteData } = await axios.get(`/api/notes/my/notes?search=${searchNotesQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      if (noteData?.notes?.length < limit) {
        setIsAtNotesEnd(true);
      }
      return noteData;
    },
    {
      enabled: true,
      getFetchMore: (lastGroup) => lastGroup?.nextId,
    });

  const onCloseNotesModal = () => {
    dispatch({ type: ActionTypes.SET_MY_NOTES_VISIBILITY, value: false });
  };

  const onNotesSearch = (search: string) => {
    setSearchNotesQuery(search);
  };

  return (
    <Modal
      className={styles.MyNotesModal}
      title={null}
      visible={isMyNotesOpen}
      footer={null}
      closable={false}
      onCancel={onCloseNotesModal}
    >
      <div className={styles.content}>
        <div className={styles.title}>
          Notes
        </div>
        <div className={styles.filterSearchContainer}>
          <Search
            placeholder="Search for your notes..."
            size="large"
            allowClear
            onSearch={onNotesSearch}
          />
          {/* <Tooltip title="filter"> */}
          {/*  <Button */}
          {/*    className={styles.filtersButton} */}
          {/*    icon={<FunnelPlotOutlined />} */}
          {/*    size="large" */}
          {/*    onClick={() => setShowFilters(!showFilters)} */}
          {/*  /> */}
          {/* </Tooltip> */}
        </div>
        {
                    showFilters
                    && (
                    <div>
                      <div className={styles.filtersTitle}>
                        Filters
                      </div>
                      <div className={styles.filtersContainer}>
                        <div className={styles.filterContainer}>
                          <div className={styles.filterTitle}>
                            Language
                          </div>
                          <Select
                            showSearch
                            style={{ width: '120px' }}
                            size="large"
                                        // onChange={onLangChange}
                            allowClear
                          >
                            {
                                            languages.map((language) => <Select.Option value={language.name}>{language.name}</Select.Option>)
                                        }
                          </Select>
                        </div>
                      </div>
                    </div>
                    )
                }
        <div id="scrollableDivTemplates" className={styles.templatesBody}>
          {
                        (isNotesLoading) && (
                        <div className={styles.loadingContainer}>
                          {
                                    [1, 1, 1, 1].map((item, index) => <div key={`${index}-loading-card`} className={styles.loadingCard}><Skeleton avatar active paragraph={{ rows: 1 }} /></div>)
                                }
                        </div>
                        )
                        || notesData?.map((page) => page.notes).flat().length === 0
                        && <div className={styles.nothingContainer}>No templates found</div>
                        || (
                        <InfiniteScroll
                          dataLength={notesData?.map((page) => page.notes).flat().length || 0}
                          next={() => fetchMoreNotes()}
                          hasMore={(notesData?.map((page) => page.notes).flat().length || 0) > 0 && !isAtNotesEnd}
                          loader={(
                            <div className={styles.spinningContainer}>
                              <Spin />
                            </div>
                                )}
                          endMessage={(
                            <p style={{ textAlign: 'center' }} />
                                )}
                          scrollableTarget="scrollableDivTemplates"
                        >
                          <div className={styles.container}>
                            {
                                        notesData?.map((page) => page.notes).flat().map((item, index) => (
                                          <NotesCard
                                            key={item._id}
                                            note={item}
                                            onNotesCardClick={onNotesCardClick}
                                          />
                                        ))
                                    }
                          </div>
                        </InfiniteScroll>
                        )
                    }
        </div>
        {/* <Button */}
        {/*  className={styles.createButton} */}
        {/*  type="primary" */}
        {/*  size="large" */}
        {/*    // onClick={onCreateTemplateClick} */}
        {/* > */}
        {/*  Create Template */}
        {/* </Button> */}
      </div>
    </Modal>
  );
}

export default MyNotesModal;
