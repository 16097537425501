import React from 'react';
import styles from './PostModal.module.scss';
import {Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {ActionTypes} from "../../../actions/types";
import { useHistory } from 'react-router-dom';

function PostModal(props: any) {

    const history = useHistory();
    const isPostImageModalVisible = useSelector<StoreState, boolean>(state => state.isPostImageModalVisible);
    const dispatch = useDispatch();

    const handleOk = () => {

    };

    const handleCancel = () => {
        dispatch({ type: ActionTypes.TOGGLE_POST_IMAGE });
    };

    const viewMedium = () => {
        history.push('/mediums/123');
    };

    return (
        <Modal
            className={styles.PostModal}
            visible={isPostImageModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            bodyStyle={{padding: '0px'}}
            centered={true}
            title={<div className={styles.modalTitle}>
                <img
                    className={styles.image}
                    src={"https://pbs.twimg.com/profile_images/1824002576/pg-railsconf_400x400.jpg"}
                    alt={"https://pbs.twimg.com/profile_images/1824002576/pg-railsconf_400x400.jpg"}
                />
                <div className={styles.user}>
                    <div className={styles.displayName}>
                        Louis
                    </div>
                    <div className={styles.username}>
                        @louis
                    </div>
                </div>
            </div>}
            footer={<div className={styles.footer}>
                <div className={styles.medium} onClick={viewMedium} >
                    <img
                        src={"https://images-na.ssl-images-amazon.com/images/I/41rDChnVnDL._SX292_BO1,204,203,200_.jpg"}
                        alt={"https://images-na.ssl-images-amazon.com/images/I/41rDChnVnDL._SX292_BO1,204,203,200_.jpg"}
                        className={styles.image}
                    />
                    <div className={styles.title}>
                        Zero to One: Notes on Startups, or How to Build the Future
                    </div>
                </div>
            </div>}
        >
            <div className={styles.content}>
                <img
                    className={styles.image}
                    src={"https://scontent-ort2-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/p640x640/83534301_173316490616540_4933018129038849193_n.jpg?_nc_ht=scontent-ort2-2.cdninstagram.com&_nc_cat=108&_nc_ohc=gbvqxuC5f18AX-tcK2I&oh=68f3ca9242b791e78af3d0ae13767fbc&oe=5ED211AF"}
                    alt={"https://scontent-ort2-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/p640x640/83534301_173316490616540_4933018129038849193_n.jpg?_nc_ht=scontent-ort2-2.cdninstagram.com&_nc_cat=108&_nc_ohc=gbvqxuC5f18AX-tcK2I&oh=68f3ca9242b791e78af3d0ae13767fbc&oe=5ED211AF"}
                />
            </div>
        </Modal>
    );
}

export default PostModal;