import React from 'react';
import styles from './club-tab-pane-tab.module.scss';

type ClubTabPaneTabProps = {
    name: string;
};

function ClubTabPaneTab(props: ClubTabPaneTabProps) {
    const { name } = props;

    return (
        <div className={styles.ClubTabPaneTab}>
            <div className={styles.title}>
                {name}
            </div>
        </div>
    );
}

export default ClubTabPaneTab;