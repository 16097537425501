import React, { useState } from 'react';
import {
  Button, Input, Spin,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowRightOutlined, ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import styles from './new-group-users-screen.module.scss';
import UserGroupCard from './UserGroupCard/UserGroupCard';
import FriendUserCardSkeleton from '../NewChatScreen/FriendUserCardSkeleton/FriendUserCardSkeleton';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';

const { Search } = Input;
function NewGroupUsersScreen() {
  const { chatRoom } = useParams<{ chatRoom: string }>();
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const selectedUsers = useSelector<StoreState, User[]>((state) => state.selectedUsers);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(24);
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `new-chat-screen-${searchQuery}-${chatRoom}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/users/friends?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}${chatRoom ? `&chatRoom=${chatRoom}` : ''}`);
      if (data?.users?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onUserClick = (user: any) => {
    if (selectedUsers.map((x) => x._id).includes(user._id)) {
      dispatch({ type: ActionTypes.SET_SELECTED_USERS, value: [...selectedUsers.filter((x) => x._id !== user._id)] });
    } else {
      dispatch({ type: ActionTypes.SET_SELECTED_USERS, value: [user, ...selectedUsers] });
    }
  };

  const onSearch = (data: string) => {
    setSearchQuery(data);
  };

  const onNextClick = () => {
    history.push('/newgroupchatname');
  };

  const onSaveClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    axios.put(`/api/chats/rooms/${chatRoom}/members`, { users: selectedUsers.map((x) => x._id) })
      .then((response) => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        history.goBack();
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <div className={styles.NewGroupUsersScreen}>
      <div className={styles.headerContainer}>
        <div className={styles.headerWhiteboard}>
          {
            selectedUsers && selectedUsers.length > 0
            && (
            <div className={styles.selectedUsers}>
              <div className={styles.selectedUsersContainer}>
                {
                  selectedUsers.map((value) => (
                    <div className={styles.selectedUser}>
                      <img
                        className={styles.image}
                        src={value.image}
                        alt={value.image}
                      />
                      <div className={styles.username}>
                        {value.username}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            )
          }
          <div className={styles.search}>
            <div className={styles.topHeader}>
              <Button
                className={styles.goBack}
                size="large"
                icon={<ArrowLeftOutlined />}
                shape="circle"
                onClick={onBackClick}
              />
              <div className={styles.titleHeader}>
                Select friends
              </div>
              {
                selectedUsers.length > 0
                && !chatRoom && (
                <Button
                  className={styles.nextBack}
                  type="primary"
                  size="large"
                  shape="round"
                  onClick={onNextClick}
                >
                  Next
                  <ArrowRightOutlined />
                </Button>
                )
              }
              {
                selectedUsers.length > 0
                && chatRoom && (
                <Button
                  className={styles.nextButton}
                  type="primary"
                  size="large"
                  shape="round"
                  loading={isLoading}
                  onClick={onSaveClick}
                >
                  <SaveOutlined />
                  Save
                </Button>
                )
              }
            </div>
            <div className={styles.searchContainer}>
              <Search
                className={styles.searchInput}
                placeholder="Search for users"
                allowClear
                size="large"
                onSearch={onSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {
          isFetching && !data
            ? (
              [1, 1, 1].map((item, index) => <FriendUserCardSkeleton key={`${index}`} />)
            ) : (
              <InfiniteScroll
                dataLength={data?.map((page) => page.users).flat().length || 0}
                next={() => fetchMore()}
                hasMore={(data?.map((page) => page.users).flat().length || 0) > 0 && !isAtTheEnd}
                loader={(
                  <div className={styles.spinningContainer}>
                    <Spin />
                  </div>
                      )}
                endMessage={(
                  <p style={{ textAlign: 'center' }} />
                      )}
              >
                <div className={styles.container}>
                  {
                        data?.map((page) => page.users).flat().map((item, index) => (
                          <UserGroupCard
                            user={item}
                            selected={selectedUsers.map((x) => x._id).includes(item._id)}
                            onUserClick={onUserClick}
                            key={`FriendCard${index}`}
                          />
                        ))
                  }
                </div>
              </InfiniteScroll>
            )
        }
      </div>
    </div>
  );
}

export default NewGroupUsersScreen;
