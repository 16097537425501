import React from 'react';
import { Progress } from 'antd';
import styles from './poll-card.module.scss';
import Footer from '../footer/footer';
import pollIcon from '../../../../../assets/icons/icons8-poll-48.png';
import baylor from '../../../../../assets/images/baylor.png';
import gonzaga from '../../../../../assets/images/gonzaga.jpg';
import Header from '../header/header';

function PollCard() {
  return (
    <div className={styles.PollCard}>
      <Header image={pollIcon} title="Poll" minute="45th min" />
      <div className={styles.body}>
        <div className={styles.question}>
          Which team is playing better?
        </div>
        <div className={styles.resultContainer}>
          <div className={styles.option}>
            <img
              className={styles.image}
              src={baylor}
            />
            <Progress className={styles.percentage} percent={30} status="active" />
          </div>
          <div className={styles.option}>
            <img
              className={styles.image}
              src={gonzaga}
            />
            <Progress className={styles.percentage} percent={70} status="active" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PollCard;
