import React from 'react';
import { Rate } from 'antd';
import styles from './you-screen.module.scss';
import elon from '../../../assets/images/elon.jpeg';

function YouScreen() {
  return (
    <div className={styles.YouScreen}>
      <div className={styles.container}>
        <div className={styles.headerTitle}>
          Stadium Stats
        </div>
        <div className={styles.statsContainer}>
          <div className={styles.leftContainer}>
            <img
              className={styles.image}
              src={elon}
            />
            <div className={styles.username}>
              Supporter rating
            </div>
            <div>
              <Rate defaultValue={4} disabled />
            </div>
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.statItem}>
              <div>Engagement</div>
              <Rate defaultValue={4} disabled />
            </div>
            <div className={styles.statItem}>
              <div>Cheer</div>
              <Rate defaultValue={4} disabled />
            </div>
            <div className={styles.statItem}>
              <div>Banter</div>
              <Rate defaultValue={4} disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YouScreen;
