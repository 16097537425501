import React, { useEffect, useState } from 'react';
import { Button, Image, Skeleton } from 'antd';
// import './mediums-grid-card.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './mediums-grid-card.module.scss';

type MediumsGridCardProps = {
  item: any;
  isEditing: boolean;
  onMediumClick: (medium: any) => void;
  tempOnRemoveMediumClick: (medium: any) => void;
};

function MediumsGridCard(props: MediumsGridCardProps) {
  const {
    item, isEditing, onMediumClick, tempOnRemoveMediumClick,
  } = props;
  const [width, setWidth] = useState(window.innerWidth);

  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      key={item?._id}
      className={styles.MediumsGridCard}
      onClick={() => onMediumClick(item)}
    >
      <LazyLoadImage
        className={styles.image}
        src={item.mediumImage}
        alt={item.mediumImage}
        effect="opacity"
        placeholderSrc={require('../../../../assets/images/placeholder.webp')}
      />
      {
              isEditing
              && <div className={styles.mediumBackground} />
      }
      {
              isEditing
              && (
              <div className={styles.removeContainer}>
                <Button
                  className={styles.removeButton}
                  onClick={() => tempOnRemoveMediumClick(item)}
                >
                  Remove
                </Button>
              </div>
              )
          }
    </div>
  );
}

export default MediumsGridCard;
