import React from 'react';
import styles from './card-header.module.scss';

type HeaderProps = {
  type: any;
  title: string;
  minute: string;
};

function CardHeader(props: HeaderProps) {
  const { type, title, minute } = props;

  return (
    <div className={styles.CardHeader}>
      <div className={`${styles.card} ${styles[`${type}`]}`} />
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.gameTime}>
        {minute}
      </div>
    </div>
  );
}

export default CardHeader;
