import React from 'react';
import styles from './Sources.module.scss';
import SourceCard from '../../../components/Cards/SourceCard/SourceCard';
import { Medium } from '../../../models/Medium';

interface SourcesProps {
  medium: Medium;
}

function Sources(props:SourcesProps) {
  const { medium } = props;
  return (
    <div className={styles.Sources}>
      <SourceCard
        key={medium.officialSite}
        medium={medium}
      />
    </div>
  );
}

export default Sources;
