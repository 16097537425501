import { Dispatch } from 'redux';
import axios from 'axios';
import { Medium } from '../models/Medium';
/* eslint-disable @typescript-eslint/no-unused-vars */
export const addMediumIdToShelf = (mediumType: string, externalId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.get(`/api/mediums/interests/${externalId}/${mediumType}`)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const addMediumToShelf = (username: string, medium: Medium, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  let endpoint = medium.typeTitle;

  if (medium.typeTitle === 'book') {
    endpoint = 'books';
  } else if (medium.typeTitle === 'podcast') {
    endpoint = 'podcasts';
  } else if (medium.typeTitle === 'movie') {
    endpoint = 'movies';
  }

  axios.post(`/api/mediums/shelf/${endpoint}`, { username, medium })
    .then((response) => {
      callback(endpoint);
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const addMediumToWishList = (username: string, medium: Medium, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/mediums/shelf/wishlist', { username, medium })
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const removeMediumFromShelf = (shelfMediumId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/shelves/${shelfMediumId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      callback(false);
    });
};

export const rateMedium = (rating: number, title: string, medium: string, tags: string[], hasSpoilers: boolean, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  const data = {
    rating,
    title,
    medium,
    tags,
    hasSpoilers,
  };
  axios.post('/api/posts/rating', data)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      callback(false);
    });
};
