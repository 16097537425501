import {ActionTypes} from "../actions/types";


interface setActiveClubTab {
    type: typeof ActionTypes.SET_ACTIVE_TAB;
    value: string;
}

type clubActions = setActiveClubTab;

export const activeClubTab = (state: string = "home", action: clubActions) => {
    if (action.type === ActionTypes.SET_ACTIVE_TAB) {
        return action.value;
    }
    return state;
};