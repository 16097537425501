import React from 'react';
import { List } from 'antd';
import { FullscreenExitOutlined, FontSizeOutlined, OrderedListOutlined, ReadOutlined, FileDoneOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './create-screen.module.scss';
import Empty from '../../components/Empty/Empty';
import { CreateQuote } from '../../models/create-models/CreateQuote';
import { ActionTypes } from '../../actions/types';
import { defaultCreateQuote } from '../../reducers/createReducers';

function CreateScreen() {
  const history = useHistory();
  const dispatch = useDispatch();

  const data = [
    {
      id: styles.quote,
      url: '/create/quote',
      image: <FontSizeOutlined className={`${styles.createImage} ${styles.quote}`} key={styles.summary} />,
      title: 'create quote',
      subtitle: 'Create amazing looking quotes in just a few clicks',
    },
    {
      id: styles.template,
      url: '/create/template',
      image: <ReadOutlined className={`${styles.createImage} ${styles.template}`} key={styles.template} />,
      title: 'create template',
      subtitle: 'Create story templates in just a few clicks',
    },
    {
      id: styles.quiz,
      url: '/create/quiz',
      image: <FileDoneOutlined className={`${styles.createImage} ${styles.wilt}`} key={styles.template} />,
      title: 'create quiz',
      subtitle: 'Create a fun quiz in just a few clicks',
    },
    // {
    //   id: styles.list,
    //   url: '/create/list',
    //   image: <OrderedListOutlined className={`${styles.createImage} ${styles.list}`} key={styles.list} />,
    //   title: 'create list',
    //   subtitle: 'Create a list in just a few clicks',
    // },
    // {
    //   id: styles.wilt,
    //   url: '/create/wilt',
    //   image: <OrderedListOutlined className={`${styles.createImage} ${styles.wilt}`} key={styles.summary} />,
    //   title: 'create W.I.L.T (What I Learnt Today)',
    //   subtitle: "(What I Learnt Today) Write down what you learnt today, whether it's one sentence or bullet points",
    // },
    // {
    //   id: styles.summary,
    //   url: '/create/summary',
    //   image: <FullscreenExitOutlined className={`${styles.createImage} ${styles.summary}`} key={styles.summary} />,
    //   title: 'create summary',
    //   subtitle: 'Create a summary of your favorite book or a chapter',
    // },
  ];

  const onCreateClick = (item: any) => {
    if (item.url === '/create/summary') {
      const newCreateQuote: CreateQuote = {
        ...defaultCreateQuote,
        currentPage: 1,
        totalPages: 3,
        type: 'summary',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    } else if (item.url === '/create/wilt') {
      const newCreateQuote: CreateQuote = {
        ...defaultCreateQuote,
        currentPage: 1,
        totalPages: 3,
        type: 'wilt',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    } else if (item.url === '/create/quote') {
      const newCreateQuote: CreateQuote = {
        ...defaultCreateQuote,
        currentPage: 1,
        totalPages: 4,
        type: 'quote',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    } else if (item.url === '/create/list') {
      const newCreateQuote: CreateQuote = {
        ...defaultCreateQuote,
        currentPage: 1,
        totalPages: 4,
        type: 'list',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }

    history.push(item.url);
  };

  return (
    <div className={styles.Create}>
      <div className={styles.container}>
        <List
          className={styles.createList}
          grid={{ gutter: 0, column: 1 }}
          dataSource={data}
          locale={{
            emptyText: <Empty
              title="Create your first post"
              subTitle="Create awesome looking posts"
              icon="plus"
              url="/create"
              buttonTitle="Create post"
              screen="posts"
            />,
          }}
          renderItem={(item) => (
            <List.Item className={`${styles.createCard}`} onClick={() => onCreateClick(item)}>
              {
                                item.image
                            }
              <div className={styles.createText}>
                <div className={styles.title}>
                  {item.title}
                </div>
                <div className={styles.subtitle}>
                  {item.subtitle}
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

export default CreateScreen;
