import React from 'react';
import styles from "./story-card.module.scss";
import {TeamOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {TopicPost} from "../../../../models/TopicPost";
import {Topic} from "../../../../models/Topic";
import {getFormattedDate} from "../../../../helpers/helpers";

type StoryCardProps = {
    topic: Topic;
    onTopicClick: (id: string) => void;
    onJoinClick: (e: any, id: string) => void;
    currentUsersTopicIds?: { [key: string]: string }
}

function StoryCard(props: StoryCardProps) {
    const { topic, onTopicClick, onJoinClick, currentUsersTopicIds } = props;

    return (
        <div className={styles.StoryCard} onClick={() => onTopicClick(topic?._id)}>
            <div className={styles.content}>
                <div className={styles.startingContainer}>
                    <div className={styles.startingDate}>
                        {getFormattedDate(topic.createdAt)}
                    </div>
                </div>
                <div>
                    {topic.name}
                </div>
                <div className={styles.medium}>
                    <img className={styles.image}
                         src={topic.medium.imageUrl}
                    />
                    <div className={styles.mediumTitle}>
                        {topic.medium.title}
                    </div>
                </div>
                <div className={styles.membersContainers}>
                    <TeamOutlined />
                    <div className={styles.additionalMembers}>
                        {topic.usersCount || 0}
                    </div>
                </div>
            </div>
            {
                currentUsersTopicIds && currentUsersTopicIds[`${topic._id}`] ?
                    <Button
                        type="link"
                    >
                        Joined
                    </Button>
                    : <Button
                        size="large"
                        type="primary"
                        className={styles.joinButton}
                        onClick={(e) => onJoinClick(e, topic?._id)}
                    >
                        JOIN
                    </Button>
            }
        </div>
    );
}

export default StoryCard;