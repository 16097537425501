import React from 'react';
import { Button, Form, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './club-discussion-create-screen.module.scss';
import { createTopic } from '../../services-api/clubTopicsService';
import { StoreState } from '../../reducers/_RootReducers';
import { ActionTypes } from '../../actions/types';

type ClubDiscussionCreateScreenProps = {

};

function ClubDiscussionCreateScreen(props: ClubDiscussionCreateScreenProps) {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const onBackClick = () => {
    history.push(`/storyclubs/${id}/discussions`);
  };

  const onFinish = (values: any) => {
    if (!values.name) {
      return;
    }
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(createTopic(id, { ...values, type: 'DISCUSSION' }, () => {
      dispatch({ type: ActionTypes.SET_LAST_CREATED_SCREEN, value: 'club-discussion-create-screen' });
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      history.push(`/storyclubs/${id}/discussions/all`);
    }));
  };

  const onFinishFailed = (error: any) => {
    console.log('error: ', error);
  };

  return (
    <div className={styles.ClubDiscussionCreateScreen}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Button
            className={styles.goBack}
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={onBackClick}
          />
          <div className={styles.name}>
            Create New Topic
          </div>
        </div>
        <div className={styles.form}>
          <Form
            form={form}
            layout="vertical"
            className="create-channel-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            // requiredMark="optional"
          >
            <Form.Item
              label="Topic Name"
              name="name"
              required
              // initialValue="hello"
              rules={[{
                required: true,
                validator: (ro, value) => {
                  if (new RegExp('^[\\w-]{0,20}$').test(value) && value !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject('Topic name can only have letters, numbers, - and max length of 20');
                },
              }]}
            >
              <Input
                size="large"
                placeholder="Enter topic name"
              />
            </Form.Item>
            <Form.Item
              label="Topic Description"
              name="description"
            >
              <Input
                size="large"
                placeholder="Enter short topic description"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="create-channel-form-button"
                loading={isLoading}
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ClubDiscussionCreateScreen;
