import React from 'react';
import styles from './recent-activity-card.module.scss';
import {RecentActivity} from "../../../../models/RecentActivity";
import {Popover, Typography} from "antd";
import {useDispatch} from "react-redux";
import likeIcon from '../../../../assets/icons/icons8-facebook-like-50.png';
import commentIcon from '../../../../assets/icons/icons8-topic-50.png';
import shareIcon from '../../../../assets/icons/icons8-forward-arrow-50.png';
import newLike from '../../../../assets/icons/new-like.png';
import newHeart from '../../../../assets/icons/new-heart.png';
import newLikeCircle from '../../../../assets/icons/new-like-circle.png';
import newHeartCircle from '../../../../assets/icons/new-heart-circle.png';
import newLolCircle from '../../../../assets/icons/new-lol-circle.png';
import NotesCard from "../../../storynotes-notes-screen/notes-card/notes-card";
import {addToSection, availableDate} from "../../../../services-util/club-story-service-util";
import {ActionTypes} from "../../../../actions/types";
import {convertTimeToDaysAgo} from "../../../../helpers/helpers";
import {Note} from "../../../../models/Note";

type RecentActivityCardProps = {
    recentActivity: RecentActivity,
    timeStamp: Date,
    onReactionAction: (event: any, recentActivity: RecentActivity, type: string) => void;
    currentId: string;
    isReactionsOpen: boolean;
    onRecentActivityClick: (recentActivity: RecentActivity) => void;
    onReactionClick: (event: any, recentActivity: RecentActivity) => void;
    reactionIds: { [key: string]: any },
    hideTimeStamp?: boolean;
    onPreviewNoteAction: (note: Note) => void;
}

const { Paragraph } = Typography;
function RecentActivityCard(props: RecentActivityCardProps) {
    const { recentActivity, timeStamp, onReactionAction, currentId,
        isReactionsOpen, onRecentActivityClick, onReactionClick, reactionIds, hideTimeStamp,
        onPreviewNoteAction} = props;

    const dispatch = useDispatch();

    const nextSectionId = addToSection((recentActivity?.subSectionId || recentActivity?.sectionId), recentActivity.topic?.rate || 0, recentActivity.topic?.template?.sections || [])
    const availableDateVal = availableDate(nextSectionId, recentActivity.topic?.template?.sections || [], new Date(recentActivity.topic?.startDate || ''), timeStamp, recentActivity.topic?.rate || 0, recentActivity.topic?.frequency || '')
    const isNotActiveYet = availableDateVal > timeStamp;

    const onLikeClick = (e: any) => {
        onReactionAction(e, recentActivity, 'like');
    };

    const onLoveClick = (e: any) => {
        onReactionAction(e, recentActivity, 'love');
    };

    const onLolClick = (e: any) => {
        onReactionAction(e, recentActivity, 'lol');
    };

    const content = (
        <div className={styles.reactionsIcons}>
            <img
                className={styles.reactionIcon}
                src={newLike}
                alt=""
                onClick={(e) => onLikeClick(e)}
            />
            <img
                className={styles.reactionIcon}
                src={newHeart}
                alt=""
                onClick={(e) => onLoveClick(e)}
            />
            <img
                className={styles.reactionIcon}
                src={newLolCircle}
                alt=""
                onClick={(e) => onLolClick(e)}
            />
        </div>
    );

    const getCurrentReaction = () => {
        if (reactionIds[`${recentActivity._id}`] && reactionIds[`${recentActivity._id}`].typeText === 'like') {
            return (
                <img
                    className={styles.reactionImage}
                    src={newLike}
                    onClick={(e) => onReactionClick(e, recentActivity)}
                />
            );
        }

        if (reactionIds[`${recentActivity._id}`] && reactionIds[`${recentActivity._id}`].typeText === 'love') {
            return (
                <img
                    className={styles.reactionImage}
                    src={newHeart}
                    onClick={(e) => onReactionClick(e, recentActivity)}
                />
            );
        }

        if (reactionIds[`${recentActivity._id}`] && reactionIds[`${recentActivity._id}`].typeText === 'lol') {
            return (
                <img
                    className={styles.reactionImage}
                    src={newLolCircle}
                    onClick={(e) => onReactionClick(e, recentActivity)}
                />
            );
        }

        return (
            <img
                className={styles.defaultReactionImage}
                src={likeIcon}
                onClick={(e) => onReactionClick(e, recentActivity)}
            />
        );
    };

    const onVisibleChange = (visible: boolean) => {
        dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: visible });
    };

    const displayCount = () => {
        let count = 0;

        if (recentActivity?.lovesCount) {
            count += recentActivity.lovesCount;
        }

        if (recentActivity?.likesCount) {
            count += recentActivity.likesCount;
        }

        if (recentActivity?.laughsCount) {
            count += recentActivity.laughsCount;
        }

        return count > 0 ? count : undefined;
    };

    const getContent = () => {

        if (isNotActiveYet) {

            return <div className={styles.notAvailable}>
                Note will be visible on {availableDateVal.toLocaleString(undefined, {
                day: 'numeric', weekday: 'short', month: 'short',
            })}
            </div>
        }

        if (recentActivity.image) {
            return (
                <img
                    className={styles.postImage}
                    src={recentActivity.image}
                    alt=""
                />
            );
        }

        if (recentActivity.note && recentActivity.note?._id) {
            return (
                <NotesCard
                    note={recentActivity.note}
                    hideTimeStamp={hideTimeStamp}
                    onNotesCardClick={(e) => onRecentCardClick(e)}
                    hideBorder
                />
            );
        }
    };

    const onRecentCardClick = (event: any) => {
        event.stopPropagation();
        onPreviewNoteAction(recentActivity.note!);
    }

    return (
        <div
            className={`${styles.RecentActivityCard} ${styles.borderAround}`}
            onClick={() => onRecentActivityClick(recentActivity)}
        >
            <div className={styles.clubContainer}>
                <span className={styles.name}>{recentActivity.topic.type === 'DISCUSSION' ? '#':''}{recentActivity.topic.name}</span> in <span className={styles.name} >{recentActivity.club.name}</span>
            </div>
            <div className={styles.recentPostContainer}>
                <div className={styles.imageContainer}>
                    <img
                        className={styles.image}
                        src={recentActivity?.user?.image}
                    />
                    <Popover
                        placement="topLeft"
                        visible={isReactionsOpen && currentId === recentActivity._id}
                        onVisibleChange={onVisibleChange}
                        content={content}
                        trigger="click"
                        arrowContent={null}
                    >
                        {
                            getCurrentReaction()
                        }
                    </Popover>
                </div>
                <div className={styles.textInfo}>
                    <div className={styles.titleContainer}>
                        <Paragraph
                            ellipsis={{ rows: 1, expandable: false, suffix: `  @${recentActivity?.user?.username} · ${convertTimeToDaysAgo(recentActivity.createdAt)}` }}
                        >
                            <span className={styles.title}>{recentActivity?.user?.displayName}</span>
                        </Paragraph>
                    </div>
                    <div className={styles.postContainer}>
                        <div className={styles.postText}>
                            {recentActivity.text}
                        </div>
                        {
                            getContent()
                        }
                        <div className={styles.footer}>
                            <div className={styles.leftStats}>
                                {
                                    recentActivity?.likesCount !== undefined && recentActivity?.likesCount > 0
                                    && (
                                        <img
                                            className={styles.reactionIcon1}
                                            src={newLikeCircle}
                                            alt=""
                                        />
                                    )
                                }
                                {
                                    recentActivity?.lovesCount !== undefined && recentActivity?.lovesCount > 0
                                    && (
                                        <img
                                            className={styles.reactionIcon2}
                                            src={newHeartCircle}
                                            alt=""
                                        />
                                    )
                                }
                                {
                                    recentActivity?.laughsCount !== undefined && recentActivity?.laughsCount > 0
                                    && (
                                        <img
                                            className={styles.reactionIcon3}
                                            src={newLolCircle}
                                            alt=""
                                        />
                                    )
                                }
                                <div className={styles.reactionNumber}>
                                    {displayCount()}
                                </div>
                            </div>
                            <div className={styles.rightStats}>
                                <img
                                    className={styles.commentIcon}
                                    src={commentIcon}
                                    alt=""
                                />
                                <div className={styles.commentNumber}>
                                    1.5K
                                </div>
                                <img
                                    className={styles.shareIcon}
                                    src={shareIcon}
                                    alt=""
                                />
                                <div className={styles.shareNumber}>
                                    1.5K
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecentActivityCard;