import React, {useState} from 'react';
import {Badge, Drawer, Switch} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  HomeOutlined,
  BookOutlined,
  EditOutlined,
  MailOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  SnippetsOutlined,
  BellOutlined,
  BulbOutlined, CloseOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import cookies from 'js-cookie';
import { StoreState } from '../../reducers/_RootReducers';
import { ActionTypes } from '../../actions/types';
import styles from './MobileMenu.module.scss';
import {User} from "../../models/User";
import {useThemeSwitcher} from "react-css-theme-switcher";

function MobileMenu() {
  const history = useHistory();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const isMobileMenuOpen = useSelector<StoreState, boolean>((state) => state.isMobileMenuOpen);
  const unreadChats = useSelector<StoreState, number>((state) => state.unreadChats);
  const dispatch = useDispatch();
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(localStorage.getItem('goodlores-theme') === 'dark');
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();

  const closeMenu = () => {
    dispatch({ type: ActionTypes.TOGGLE_MOBILE_MENU });
  };

  const onLogoutAction = () => {
    cookies.remove('goodlores-token');
    window.location.reload();
  };

  const onWishList = () => {
    history.replace('/wishlist');
    closeMenu();
  };

  const onNotifications = () => {
    history.replace('/notifications');
    closeMenu();
  };

  const onHomeClick = () => {
    history.replace('/home');
    dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
    dispatch({ type: ActionTypes.SET_MENU_ITEM, value: 'home' });
    closeMenu();
  };

  const onDraftsClick = () => {
    history.replace('/drafts');
    closeMenu();
  };

  const onMessagesClick = () => {
    history.replace('/messages');
    closeMenu();
  };

  const onClubsClick = () => {
    history.replace('/storyclubs');
    closeMenu();
  };

  const onNotesClick = () => {
    history.replace('/storynotes');
    closeMenu();
  };

  const onChangeTheme = (event: any) => {
    if (localStorage.getItem('goodlores-theme') === "dark") {
      document.documentElement.style.setProperty("--main-background-color", "var(--light-background-color)");
      document.documentElement.style.setProperty("--main-secondary-background-color", "var(--light-secondary-background-color)");
      document.documentElement.style.setProperty("--main-border-color", "var(--light-border-color)");
      document.documentElement.style.setProperty("--main-card-color", "var(--light-card-color)");
      document.documentElement.style.setProperty("--primary-text-color", "var(--light-primary-text-color)");
      document.documentElement.style.setProperty("--secondary-text-color", "var(--light-secondary-text-color)");
      document.documentElement.style.setProperty("--main-skeleton-color", "var(--light-skeleton-color)");
      document.documentElement.style.setProperty("--main-filter", "var(--light-filter)");
      document.documentElement.style.setProperty("--main-gradient", "var(--light-gradient)");
      document.documentElement.style.setProperty("--main-home-divider-color", "var(--light-home-divider-color)");
      localStorage.setItem('goodlores-theme', "light");
      setIsDarkTheme(false);
      switcher({ theme: themes.light });
    } else {
      document.documentElement.style.setProperty("--main-background-color", "var(--dark-background-color)");
      document.documentElement.style.setProperty("--main-secondary-background-color", "var(--dark-secondary-background-color)");
      document.documentElement.style.setProperty("--main-border-color", "var(--dark-border-color)");
      document.documentElement.style.setProperty("--main-card-color", "var(--dark-card-color)");
      document.documentElement.style.setProperty("--primary-text-color", "var(--dark-primary-text-color)");
      document.documentElement.style.setProperty("--secondary-text-color", "var(--dark-secondary-text-color)");
      document.documentElement.style.setProperty("--main-skeleton-color", "var(--dark-skeleton-color)");
      document.documentElement.style.setProperty("--main-filter", "var(--dark-filter)");
      document.documentElement.style.setProperty("--main-gradient", "var(--dark-gradient)");
      document.documentElement.style.setProperty("--main-home-divider-color", "var(--dark-home-divider-color)");
      localStorage.setItem('goodlores-theme', "dark");
      setIsDarkTheme(true);
      switcher({ theme: themes.dark });
    }
  }

  return (
    <Drawer
      className={styles.MobileMenu}
      title="Menu"
      placement="left"
      closable
      onClose={closeMenu}
      visible={isMobileMenuOpen}
      bodyStyle={{ padding: '0px' }}
    >
      <div className={styles.body}>
        <div role="button" tabIndex={0} className={styles.menuItem} onClick={onHomeClick}>
          <HomeOutlined className={styles.icon} type="home" />
          <div className={styles.title}>
            Home
          </div>
        </div>
        <div role="button" tabIndex={0} className={styles.menuItem} onClick={onWishList}>
          <BookOutlined className={styles.icon} />
          <div className={styles.title}>
            Wish List
          </div>
          {/*<Badge count={currentUser?.notificationCount} />*/}
        </div>
        <div role="button" tabIndex={0} className={styles.menuItem} onClick={onNotesClick}>
          <EditOutlined className={styles.icon} />
          <div className={styles.title}>
            Story Notes
          </div>
        </div>
        <div role="button" tabIndex={0} className={styles.menuItem} onClick={onClubsClick}>
          <TeamOutlined className={styles.icon} />
          <div className={styles.title}>
            Story Clubs
          </div>
        </div>
        {/*<div role="button" tabIndex={0} className={styles.menuItem} onClick={onMessagesClick}>*/}
        {/*  <MailOutlined className={styles.icon} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Messages*/}
        {/*  </div>*/}
        {/*  <Badge count={unreadChats} />*/}
        {/*</div>*/}
        {/*<div role="button" tabIndex={0} className={styles.menuItem} onClick={onDraftsClick}>*/}
        {/*  <SnippetsOutlined className={styles.icon} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Drafts*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.themeItem} >
          <div>Theme</div>
          <Switch
              className={styles.themeSwitch}
              onChange={onChangeTheme}
              checked={isDarkTheme}
              checkedChildren={<BulbOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked
          />
        </div>
        <div role="button" tabIndex={0} className={styles.logout} onClick={onLogoutAction}>
          Logout
        </div>
      </div>
    </Drawer>
  );
}

export default MobileMenu;
