import React from 'react';
import styles from './goal-user.module.scss';
import baylor from '../../../../../assets/images/baylor.png';
import elon from '../../../../../assets/images/elon.jpeg';

type GoalUserProps = {
  image: any;
  teamImage: any;
  name: string;
};

function GoalUser(props: GoalUserProps) {
  const { image, teamImage, name } = props;
  return (
    <div className={styles.GoalUser}>
      <div className={styles.info}>
        <div className={styles.name}>
          {name}
        </div>
        <div className={styles.detailsContainer}>
          <img
            className={styles.detailsImage}
            src={teamImage}
          />
          {/* <div className={styles.detailsText}> */}
          {/*  info */}
          {/* </div> */}
        </div>
      </div>
      <img
        className={styles.image}
        src={image}
      />
    </div>
  );
}

export default GoalUser;
