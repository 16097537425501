import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Badge,
  Button, Input, Spin,
} from 'antd';
import {PlusOutlined, BellOutlined, MenuOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import styles from './storynotes-screen.module.scss';
import OverViewCard from './OverViewCard/OverViewCard';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import FriendUserCardSkeleton from '../NewChatScreen/FriendUserCardSkeleton/FriendUserCardSkeleton';
import { StoryNote } from '../../models/StoryNote';
import Empty from "../../components/Empty/Empty";
import {ActionTypes} from "../../actions/types";

const { Search } = Input;
function StorynotesScreen() {
  const unreadChats = useSelector<StoreState, number>((state) => state.unreadChats);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `story-notes-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/notes/story?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      if (data?.storyNotes?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onOverviewCard = (item: StoryNote) => {
    history.push(`/storynotes/${item._id}`);
  };

  const onCreateClick = () => {
    history.push('/storynotes/create');
  };

  const onRemindersClick = () => {
    history.push('/reminders');
  };

  const onSearch = (data: string) => {
    setSearchQuery(data);
  };

  const toggleMobileMenu = () => {
    dispatch({ type: ActionTypes.TOGGLE_MOBILE_MENU });
  };

  return (currentUser
    && (
    <div className={styles.StorynotesScreen}>
      <div className={styles.headerContainer}>
        <div className={styles.headerWhiteboard}>
          <div className={styles.messageHeader}>
            <div className={styles.topHeader}>
              <Badge style={{ fontSize: '10px' }} dot={unreadChats > 0}>
                <MenuOutlined className={styles.navMenuIcon} onClick={toggleMobileMenu} />
              </Badge>
              <div className={styles.titleHeader}>
                Story Notes
              </div>
              <div className={styles.create}>
                <Button
                  className={styles.remindersButton}
                  size="large"
                  onClick={onRemindersClick}
                  icon={<BellOutlined />}
                />
                <Button
                  size="large"
                  type="primary"
                  onClick={onCreateClick}
                  icon={<PlusOutlined />}
                />
              </div>
            </div>
            <div className={styles.searchContainer}>
              <Search
                className={styles.searchInput}
                placeholder="Search for your notes"
                allowClear
                size="large"
                onSearch={onSearch}
              />
            </div>
          </div>
          <div className={styles.messageRequest}>
            message requests
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {
          isFetching === false &&  data?.map((page) => page.storyNotes).flat().length === 0 &&
          <Empty
              title="Create your first note"
              subTitle="Create notes in a few clicks"
              icon="plus"
              buttonTitle="Create Note"
              url="/storynotes/create"
              screen="posts"
          />
        }
        {
          isFetching && !data
            ? (
              [1, 1, 1, 1, 1, 1].map((item, index) => <FriendUserCardSkeleton key={`${index}`} />)
            ) : (
              <InfiniteScroll
                dataLength={data?.length || 0}
                next={() => fetchMore()}
                hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
                loader={(
                  <div className={styles.spinningContainer}>
                    <Spin />
                  </div>
                      )}
                endMessage={(<p style={{ textAlign: 'center' }} />)}
              >
                {
                      data?.map((page) => page.storyNotes).flat()
                          .map((item: StoryNote) => (
                            <OverViewCard
                              key={item._id}
                              storyNote={item}
                              currentUserId={currentUser._id}
                              onOverviewCard={onOverviewCard}
                            />
                          ))
                }
              </InfiniteScroll>
            )
        }
      </div>
    </div>
    )
  );
}

export default StorynotesScreen;
