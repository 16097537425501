import React from 'react';
import styles from './Highlights.module.scss';
import {highlightHashTags} from "../../../../helpers/highlight";
import {Typography} from "antd";


const { Paragraph } = Typography;
interface HashTagProps {

}

function Highlights(props:HashTagProps) {
    return (
        <div className={styles.Highlights}>
            <div className={styles.container}>
                <div
                    className={styles.title}
                    //style={{ flex: 'nowrap' }}
                >
                    {
                        highlightHashTags("Use hashtags to #highlight words", { color: '#2ECC71', fontSize: "14px", alpha: 1, fontWeight: 600 })
                    }
                </div>
                <div className={styles.source}>
                    <div>
                        <i>author</i>
                    </div>
                    <div>
                        book name
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Highlights;
