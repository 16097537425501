import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { List, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import styles from './UsersScreen.module.scss';
import GoBack from '../../components/GoBack/GoBack';
import UserCard from '../../components/Cards/UserCard/UserCard';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { follow } from '../../services-api/userService';
import UserCardSkeleton from './UserCardSkeleton/UserCardSkeleton';
import { ActionTypes } from '../../actions/types';

function UsersScreen() {
  const { userId, type } = useParams<{ userId: string, type: string }>();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const followingUserIds = useSelector<StoreState, any>((state) => state.followingUserIds);
  const followRequestUserIds = useSelector<StoreState, any>((state) => state.followRequestUserIds);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(24);
  const dispatch = useDispatch();

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `users-${userId}-${type}-screen`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/users/${userId}/${type}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);

      dispatch({ type: ActionTypes.SET_FOLLOW_REQUEST_USER_IDS, value: { ...followRequestUserIds, ...data?.followRequestUserIds } });
      dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: { ...followingUserIds, ...data?.followingUserIds } });
      if (data?.users?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onFollowAction = (user: User, callback: Function) => {
    dispatch(follow(currentUser._id, user._id, callback));
  };

  return (
    <div className={styles.UsersScreen}>
      <GoBack />
      {
          isFetching && !data?.length
            ? (
              <List
                className={styles.container}
                grid={{ gutter: 0, column: 1 }}
                dataSource={[1, 1, 1]}
                renderItem={(item) => (
                  <List.Item key={`users${item}`}>
                    <UserCardSkeleton />
                  </List.Item>
                )}
              />
            ) : (
              <InfiniteScroll
                dataLength={data?.map((page) => page.users).flat().length || 0}
                next={() => fetchMore()}
                hasMore={(data?.map((page) => page.users).flat().length || 0) > 0 && !isAtTheEnd}
                loader={(
                  <div className={styles.spinningContainer}>
                    <Spin />
                  </div>
                      )}
                endMessage={(
                  <p style={{ textAlign: 'center' }} />
                      )}
              >
                <List
                  className={styles.container}
                  grid={{ gutter: 0, column: 1 }}
                  dataSource={type === 'followers'
                    ? data?.map((page) => page.users).flat().map((f:any) => f.follower)
                    : data?.map((page) => page.users).flat().map((f:any) => f.following)}
                  renderItem={(item) => (
                    <List.Item key={`users${item}`}>
                      <UserCard
                        user={item}
                        onFollowAction={onFollowAction}
                        currentUserId={currentUser._id}
                        followingUserIds={followingUserIds}
                        followRequestUserIds={followRequestUserIds}
                      />
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            )
      }
    </div>
  );
}

export default UsersScreen;
