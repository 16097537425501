import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './new-group-name-screen.module.scss';
import UserGroupNameCard from './UserGroupNameCard/UserGroupNameCard';
import GoBack from '../../components/GoBack/GoBack';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';

function NewGroupNameScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const selectedUsers = useSelector<StoreState, User[]>((state) => state.selectedUsers);

  const onCreateGroupClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    axios.post('/api/chats/create/room', { name, users: selectedUsers.map((x) => x._id) })
      .then((response) => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        history.replace('/messages');
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const onDeleteClick = (user: any) => {
    if (selectedUsers.map((x) => x._id).includes(user._id)) {
      dispatch({ type: ActionTypes.SET_SELECTED_USERS, value: [...selectedUsers.filter((x) => x._id !== user._id)] });
    }
  };

  const onGroupNameChange = (data: any) => {
    setName(data.target.value);
  };

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <div className={styles.NewGroupNameScreen}>
      <div className={styles.headerContainer}>
        <div className={styles.headerWhiteboard}>
          <div className={styles.messageHeader}>
            <div className={styles.topHeader}>
              <Button
                className={styles.goBack}
                size="large"
                icon={<ArrowLeftOutlined />}
                shape="circle"
                onClick={onBackClick}
              />
              <div className={styles.titleHeader}>
                New Group
              </div>
              <div className={styles.create}>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  disabled={name.length === 0}
                  onClick={onCreateGroupClick}
                  loading={isLoading}
                >
                  Create Group
                </Button>
              </div>
            </div>
            <div className={styles.searchContainer}>
              <Input
                placeholder="Enter group name"
                size="large"
                onChange={onGroupNameChange}
              />
            </div>
          </div>
          <div className={styles.title}>
            Members
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {
          selectedUsers.map((val) => (
            <UserGroupNameCard
              key={`${val._id}`}
              user={val}
              onDeleteClick={onDeleteClick}
            />
          ))
        }
      </div>
    </div>
  );
}

export default NewGroupNameScreen;
