import React from 'react';
import { Button, Input, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './stands-screen.module.scss';
import baylor from '../../assets/images/baylor.png';
import gonzaga from '../../assets/images/gonzaga.jpg';
import StadiumTabIcon from '../stadium-screen/stadium-tab-icon/stadium-tab-icon';
import HomeContent from '../stadium-screen/home-content/home-content';
import StandContent from '../stadium-screen/stand-content/stand-content';
import StandsContent from '../stadium-screen/stands-content/stands-content';
import DmsContent from '../stadium-screen/dms-content/dms-content';
import YouScreen from '../stadium-screen/you-screen/you-screen';
import StandTab from './stand-tab/stand-tab';
import TopStand from './top-stand/top-stand';

const { TabPane } = Tabs;
const { Search } = Input;
function StandsScreen() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const onSearch = () => {

  };

  const onBackAction = () => {
    history.push('/stadiums/123/stands');
  };

  const callback = (data: any) => {
    console.log('data: ', data);
    // history.push(`/stadiums/${id}/${data}`);
  };

  return (
    <div className={styles.StandsScreen}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.scoreHeader}>
            <Button
              className={styles.backButton}
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={onBackAction}
            />
            <div className={styles.standTitle}>
              stand name
            </div>
            <div className={styles.scoreContainer}>
              <img
                className={styles.teamIcon1}
                src={baylor}
              />
              <div className={styles.teamScore1}>
                100
              </div>
              <div className={styles.split}>
                -
              </div>
              <div className={styles.teamScore2}>
                101
              </div>
              <img
                className={styles.teamIcon2}
                src={gonzaga}
              />
            </div>
          </div>
          <div className={styles.searchContainer}>
            <Search
              placeholder="search posts"
              onSearch={onSearch}
              allowClear
              size="large"
            />
            <Button
              className={styles.createButton}
              icon={<PlusOutlined />}
              type="primary"
              size="large"
            >
              Post
            </Button>
          </div>
        </div>
        <div className={styles.content}>
          <Tabs
            tabBarGutter={0}
            className={styles.tabs}
            // defaultActiveKey={tab}
            onChange={callback}
          >
            <TabPane
              tab={(
                <StandTab
                  name="top"
                />
                        )}
              key="top"
            >
              <TopStand />
            </TabPane>
            <TabPane
              tab={(
                <StandTab
                  name="newest"
                />
                        )}
              key="newest"
            >
              <TopStand />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default StandsScreen;
