import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {
  CheckCircleOutlined, CheckCircleFilled, PlusCircleOutlined, DeleteOutlined, MenuOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './TopCard.module.scss';
import { User } from '../../../models/User';
import { Medium } from '../../../models/Medium';
import { ActionTypes } from '../../../actions/types';

interface TopCardProps {
  index: number;
  medium?: Medium;
  currentUser?: User;
  viewingUser?: User;
  onAddToShelf: (medium?: Medium, callback?: Function) => void;
  onAddToWishListAction: (medium?: Medium, callback?: Function) => void;
  onRemoveClick: (medium?: Medium) => void;
  isLoading: boolean;
  isEditing: boolean;
  shelfIds: any;
}

function TopCard(props: TopCardProps) {
  const {
    index, medium, isLoading, currentUser, viewingUser, onAddToShelf, onRemoveClick, onAddToWishListAction, isEditing,
    shelfIds,
  } = props;
  const [isInWishList, setIsInWishList] = useState(shelfIds[`${medium?.external.id}`] !== undefined && shelfIds[`${medium?.external.id}`]?.typeText === 'wishlist');
  const [isOnShelf, setIsOnShelf] = useState(shelfIds[`${medium?.external.id}`] !== undefined && shelfIds[`${medium?.external.id}`]?.typeText !== 'wishlist');
  const history = useHistory();
  const dispatch = useDispatch();

  const onAddToShelfClick = () => {
    if (!isOnShelf) {
      onAddToShelf(medium, (typeText: string) => {
        if (currentUser) {
          setIsOnShelf(true);
          dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, [`${medium?.external.id}`]: { typeText } } });
          dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        }
      });
    }
  };

  const onAddToWishListClick = () => {
    if (!isInWishList) {
      onAddToWishListAction(medium, () => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, [`${medium?.external.id}`]: { typeText: 'wishlist' } } });
        setIsInWishList(true);
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      });
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={onAddToShelfClick}>
        {isOnShelf ? 'On Shelf ' : 'Add to shelf '}
        {isOnShelf && <CheckCircleFilled style={{ color: 'green' }} />}
      </Menu.Item>
      <Menu.Item onClick={onAddToWishListClick}>
        {isInWishList ? 'In Wish List ' : 'Add to Wish List '}
        {isInWishList && <CheckCircleFilled style={{ color: 'green' }} />}
      </Menu.Item>
    </Menu>
  );

  const onMediumClick = () => {
    if (!isEditing) {
      history.push(`/mediums/${medium?.external.id}/${medium?.typeValue}`);
    }
  };

  return (
    <div className={styles.TopCard}>
      <div className={styles.iconContainer}>
        <MenuOutlined />
      </div>
      <div className={styles.leftContainer}>
        <div className={styles.rankNum}>
          {index + 1}
        </div>
      </div>
      <div className={styles.centerContainer}>
        <img
          className={styles.image}
          src={medium?.imageUrl}
          alt={medium?.imageUrl}
          onClick={onMediumClick}
        />
        <div className={styles.mediumInfo}>
          <div role="button" tabIndex={0} className={styles.mediumTitle} onClick={onMediumClick}>
            {medium?.title}
          </div>
          {
            viewingUser?._id !== currentUser?._id
              ? (
                <div className={styles.mediumActions}>
                  <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
                    <Button
                      className={isOnShelf || isInWishList ? styles.activeShelfButton : styles.shelfButton}
                      icon={isOnShelf || isInWishList ? <CheckCircleOutlined /> : <PlusCircleOutlined />}
                      type="primary"
                      loading={isLoading}
                    >
                      {isOnShelf ? 'On Shelf' : isInWishList ? 'In Wish List' : 'Add'}
                    </Button>
                  </Dropdown>
                </div>
              ) : (
                <div className={styles.mediumActions}>
                  {
                    isEditing
                        && (
                          <Button
                            className={styles.removeButton}
                            type="primary"
                            icon={<DeleteOutlined />}
                            loading={isLoading}
                            onClick={() => onRemoveClick(medium)}
                          >
                            remove
                          </Button>
                        )
                  }
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
}

export default TopCard;
