import React from 'react';
import {Badge, Image, Typography} from 'antd';
import styles from './OverViewCard.module.scss';
import { convertTimeToDaysAgo } from '../../../helpers/helpers';
import {fallBackUrl} from "../../../utils/imageData";

type OverViewCardProps = {
  chatRoom: any;
  currentUserId: String;
  onOverviewCard: (chatRoom: any) => void
};

const { Paragraph } = Typography;
function OverViewCard(props: OverViewCardProps) {
  const { chatRoom, currentUserId, onOverviewCard } = props;
  const {
    user1, user2, message, messagesCount,
  } = chatRoom;
  const otherUser = currentUserId === user1._id ? user2 : user1;
  const newMessagesCountDiff = parseInt(chatRoom?.chatRoom?.messagesCount, 0) - parseInt(messagesCount, 0);

  return (
    <div className={styles.OverViewCard} onClick={() => onOverviewCard(chatRoom)}>
      <Image
          className={styles.image}
          src={chatRoom.chatRoom.type === 'CHAT' ? otherUser?.image : chatRoom.chatRoom.image}
          alt={chatRoom.chatRoom.type === 'CHAT' ? otherUser?.image : chatRoom.chatRoom.image}
          preview={false}
          fallback={fallBackUrl}
      />
      <div className={styles.textContainer}>
        <div className={styles.chatRoomInfo}>
          <div className={styles.username}>
            {chatRoom.chatRoom.type === 'CHAT' ? otherUser?.username : chatRoom.chatRoom.name}
          </div>
          <div className={styles.messagesCount}>
            {
              newMessagesCountDiff > 0
              && (
              <Badge
                count={newMessagesCountDiff}
                overflowCount={100}
              />
              )
            }
          </div>
        </div>
        <Paragraph
          className={styles.message}
          ellipsis={{ rows: 1, suffix: `·${convertTimeToDaysAgo(chatRoom.chatRoom.updatedAt)}`, expandable: false }}
        >
          {message?.message}
        </Paragraph>
      </div>
    </div>
  );
}

export default OverViewCard;
