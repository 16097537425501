import { ActionTypes } from '../actions/types';
import { Medium } from '../models/Medium';

interface setMedium {
  type: typeof ActionTypes.SET_MEDIUM;
  medium: Medium;
}

interface setPodcastEpisode {
  type: typeof ActionTypes.SET_PODCAST_EPISODE;
  value: Medium;
}

interface setMediums {
  type: typeof ActionTypes.SET_MEDIUMS;
  mediums: Medium[];
}

type mediumActions = setMedium | setMediums | setPodcastEpisode;

export const medium = (state = null, action: mediumActions) => {
  if (action.type === ActionTypes.SET_MEDIUM) {
    return action.medium;
  }
  return state;
};

export const podcastEpisode = (state: Medium | null = null, action: mediumActions) => {
  if (action.type === ActionTypes.SET_PODCAST_EPISODE) {
    return action.value;
  }
  return state;
};

export const mediums = (state = [], action: mediumActions) => {
  if (action.type === ActionTypes.SET_MEDIUMS) {
    return action.mediums;
  }
  return state;
};

