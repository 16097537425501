import React from 'react';
import { List } from 'antd';
import styles from './MediumCard.module.scss';
import MediumItem from '../../MediumItem/MediumItem';
import { Medium } from '../../../models/Medium';

interface MediumCardProps {
  onMediumSelect: (medium: Medium) => void;
  medium: Medium;
  addToShelfAction: (medium: Medium, callback: Function) => void;
  addToWishListAction: (medium: Medium, callback: Function) => void;
  shelfIds: any;
}

function MediumCard(props:MediumCardProps) {
  const {
    medium, addToShelfAction, addToWishListAction, shelfIds,
  } = props;
  const onMediumAction = () => {
    props.onMediumSelect(props.medium);
  };

  return (
    <List.Item className={styles.MediumCard} onClick={onMediumAction}>
      <MediumItem
        medium={medium}
        addToShelfAction={addToShelfAction}
        addToWishListAction={addToWishListAction}
        shelfIds={shelfIds}
      />
    </List.Item>
  );
}

export default MediumCard;
