import React, {useState} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {Button, Skeleton, Tabs, Tooltip} from 'antd';
import {ArrowLeftOutlined, ArrowRightOutlined, EditOutlined, FullscreenOutlined, TeamOutlined} from '@ant-design/icons';
import { useQuery } from 'react-query';
import axios from 'axios';
import styles from './club-screen.module.scss';
import TabTitle from "../../components/TabTitle/TabTitle";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers/_RootReducers";
import {ActionTypes} from "../../actions/types";
import ClubHomeTab from "./club-home-tab/club-home-tab";
import ClubStoriesTab from "./club-stories-tab/club-stories-tab";
import ClubDiscussionsTab from "./club-discussions-tab/club-discussions-tab";
import ClubActivityTab from "./club-activity-tab/club-activity-tab";
import ClubAboutTab from "./club-about-tab/club-about-tab";

const { TabPane } = Tabs;
function ClubScreen() {
  const { id } = useParams<{ id: string }>();
  const activeClubTab = useSelector<StoreState, string>((state) => state.activeClubTab);
  const history = useHistory();
  const dispatch = useDispatch();

  const colors: { [key: string]: any; } = {
    about: styles.green,
    discussions: styles.blue,
    members: styles.yellow,
    events: styles.yellow,
    questions: styles.purple,
    stories: styles.red,
  };

  const {
    isLoading, error, data, isFetching,
  } = useQuery(`club-screen-${id}`, () => axios.get(`/api/clubs/${id}`)
    .then((response) => response.data));

  const onEditClick = () => {
    history.push(`/storyclubs/${id}/edit`);
  };

  const onMembersClick = () => {
    history.push(`/storyclubs/${id}/members`);
  };

  const onSectionClick = (section: string) => {
    history.push(`/storyclubs/${id}/${section}`);
  };

  const onBackClick = () => {
    history.replace('/storyclubs')
    //history.goBack();
  }

  const onTabChange = (key: string) => {
    console.log("key: ",key);
    dispatch({ type: ActionTypes.SET_ACTIVE_TAB, value: key })
  }

  const renderActiveTabContent = (activeTab: string) => {
    switch (activeTab) {
      case 'home':
        return <ClubHomeTab/>;
      case 'activity':
        return <ClubActivityTab/>;
      case 'stories':
        return <ClubStoriesTab/>;
      case 'discussions':
        return <ClubDiscussionsTab/>;
      case 'about':
        return <ClubAboutTab/>
      default:
        return <div />
    }
  }

  return (
    <div className={styles.ClubScreen}>
      <div className={styles.container}>
        {
          isLoading
            ? <Skeleton.Input className={styles.backgroundImageSkeleton} active size="large" />
            : (
                <div className={styles.backgroundImageContainer}>
                  <img
                      className={styles.backgroundImage}
                      src={data?.club?.backgroundImage}
                      alt={data?.club?.backgroundImage}
                  />
                  <Button
                      className={styles.backButton}
                      icon={<ArrowLeftOutlined />}
                      shape="circle"
                      onClick={onBackClick}
                  />
                </div>

            )
        }
        <div className={styles.clubHeader}>
          {
            isLoading
              ? (
                <Skeleton.Avatar
                  className={styles.clubImage}
                  active
                  size="large"
                  shape="circle"
                />
              ) : (
                <img
                  className={styles.clubImage}
                  src={data?.club?.image}
                  alt={data?.club?.image}
                />
              )
          }
          <div className={styles.clubInfo}>
            <div className={styles.name}>{data?.club?.name}</div>
            <div className={styles.description}>{data?.club?.subtitle}</div>
            {/*<div className={styles.aboutContainer}>*/}
            {/*  <Button*/}
            {/*      className={styles.aboutButton}*/}
            {/*      type="link"*/}
            {/*      onClick={onEditClick}*/}
            {/*  >*/}
            {/*      <span className={styles.aboutText}>*/}
            {/*        more*/}
            {/*      </span>*/}
            {/*    <FullscreenOutlined />*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
          <div className={styles.more}>
            <Tooltip title="edit">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={onEditClick}
              />
            </Tooltip>
            <div className={styles.membersContainer}>
              <Button
                  className={styles.moreButton}
                  type="link"
                  icon={<TeamOutlined />}
                  onClick={onMembersClick}
              >
                  <span className={styles.membersText}>
                    {data?.club?.usersCount} Members
                  </span>
              </Button>
            </div>
          </div>
        </div>
        <div>
          <Tabs defaultActiveKey={activeClubTab} tabBarGutter={0} onChange={onTabChange}>
            <TabPane tab={<TabTitle text="Home" />} key="home">
            </TabPane>
            <TabPane tab={<TabTitle text="Activity" />} key="activity">
            </TabPane>
            <TabPane tab={<TabTitle text="Stories" />} key="stories">
            </TabPane>
            <TabPane tab={<TabTitle text="Discussions" />} key="discussions">
            </TabPane>
            <TabPane tab={<TabTitle text="About" />} key="about">
            </TabPane>
            {/*<TabPane tab={<TabTitle text="Members" />} key="members">*/}
            {/*</TabPane>*/}
          </Tabs>
        </div>
        {/*<div className={styles.sections}>*/}
        {/*  {[{ title: 'About', image: require('../../assets/icons/icons8-info-50.png') },*/}
        {/*    { title: 'Discussions', image: require('../../assets/icons/icons8-chat-50.png') },*/}
        {/*    { title: 'Members', image: require('../../assets/icons/icons8-user-groups-50.png') },*/}
        {/*    { title: 'Stories', image: require('../../assets/icons/icons8-storytelling-50.png') }].map((item, index) => (*/}
        {/*      <div key={`${index}-section`} className={styles.item} onClick={() => onSectionClick(item.title.toLowerCase())}>*/}
        {/*        <img className={`${styles.icon} ${colors[`${item.title.toLowerCase()}`]}`} src={item.image} alt={item.image} />*/}
        {/*        <div className={styles.title}>*/}
        {/*          {item.title}*/}
        {/*        </div>*/}
        {/*        <div className={styles.description}>*/}
        {/*          description*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
      {
        renderActiveTabContent(activeClubTab)
      }
    </div>
  );
}

export default ClubScreen;
