import React from 'react';
import { Button } from 'antd';
import { BorderOutlined, CheckOutlined } from '@ant-design/icons';
import styles from './MultipleSelect.module.scss';

interface MultipleSelectProps {
  options: any[]
  onSelect: (uniqueName: string) => void;
  question?: string;
}

function MultipleSelect(props: MultipleSelectProps) {
  const { options, onSelect, question } = props;

  const onSelectClick = (item: any) => {
    onSelect(item.uniqueName);
  };

  return (
    <div className={styles.MultipleSelect}>
      <div className={styles.title}>
        {question}
      </div>
      {
                options.map((x, index) => (
                  <Button
                    block
                    style={{
                      marginTop: '16px', height: 'auto', display: 'flex', alignItems: 'center',
                    }}
                    className={`select_option${index} ${x.selected ? styles.selected : ''}`}
                    key={`item${index}`}
                    size="large"
                    onClick={() => onSelectClick(x)}
                  >
                    {
                            x.selected
                              ? <CheckOutlined />
                              : <BorderOutlined />
                        }
                    {x.displayName}
                  </Button>
                ))
            }
    </div>
  );
}

export default MultipleSelect;
