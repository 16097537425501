import React, {useState} from 'react';
import {Badge, Button, Switch} from 'antd';
import {
  HomeOutlined, BookOutlined, EditOutlined, MailOutlined, CheckCircleOutlined, TeamOutlined, SnippetsOutlined, BellOutlined, UserOutlined, BulbOutlined, CloseOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './Menu.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import {User} from "../../models/User";
import {useThemeSwitcher} from "react-css-theme-switcher";

function Menu() {
  const router = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const unreadChats = useSelector<StoreState, number>((state) => state.unreadChats);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(localStorage.getItem('goodlores-theme') === 'dark');
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();

  const onChangeTheme = (event: any) => {
    if (localStorage.getItem('goodlores-theme') === "dark") {
      document.documentElement.style.setProperty("--main-background-color", "var(--light-background-color)");
      document.documentElement.style.setProperty("--main-secondary-background-color", "var(--light-secondary-background-color)");
      document.documentElement.style.setProperty("--main-border-color", "var(--light-border-color)");
      document.documentElement.style.setProperty("--main-card-color", "var(--light-card-color)");
      document.documentElement.style.setProperty("--primary-text-color", "var(--light-primary-text-color)");
      document.documentElement.style.setProperty("--secondary-text-color", "var(--light-secondary-text-color)");
      document.documentElement.style.setProperty("--main-skeleton-color", "var(--light-skeleton-color)");
      document.documentElement.style.setProperty("--main-filter", "var(--light-filter)");
      document.documentElement.style.setProperty("--main-gradient", "var(--light-gradient)");
      document.documentElement.style.setProperty("--main-home-divider-color", "var(--light-home-divider-color)");
      localStorage.setItem('goodlores-theme', "light");
      setIsDarkTheme(false);
      switcher({ theme: themes.light });
    } else {
      document.documentElement.style.setProperty("--main-background-color", "var(--dark-background-color)");
      document.documentElement.style.setProperty("--main-secondary-background-color", "var(--dark-secondary-background-color)");
      document.documentElement.style.setProperty("--main-border-color", "var(--dark-border-color)");
      document.documentElement.style.setProperty("--main-card-color", "var(--dark-card-color)");
      document.documentElement.style.setProperty("--primary-text-color", "var(--dark-primary-text-color)");
      document.documentElement.style.setProperty("--secondary-text-color", "var(--dark-secondary-text-color)");
      document.documentElement.style.setProperty("--main-skeleton-color", "var(--dark-skeleton-color)");
      document.documentElement.style.setProperty("--main-filter", "var(--dark-filter)");
      document.documentElement.style.setProperty("--main-gradient", "var(--dark-gradient)");
      document.documentElement.style.setProperty("--main-home-divider-color", "var(--dark-home-divider-color)");
      localStorage.setItem('goodlores-theme', "dark");
      setIsDarkTheme(true);
      switcher({ theme: themes.dark });
    }
  }

  return (
    <div className={styles.Menu}>
      <div className={styles.container}>
        <div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('home') && styles.active}`} onClick={() => { router.replace('/home'); }}>
          <HomeOutlined className={`${styles.icon} ${pathname.includes('home') && styles.active}`} type="home" />
          <div className={styles.title}>
            Home
          </div>
        </div>
        <div
            role="button"
            tabIndex={0}
            className={`${styles.menuItem} ${pathname.includes('notifications') && styles.active}`}
            onClick={() => { router.replace('/notifications'); }}
        >
          <BellOutlined className={`${styles.icon} ${pathname.includes('notifications') && styles.active}`} />
          <div className={styles.title}>
            Notifications
          </div>
          <Badge count={currentUser?.notificationCount} overflowCount={10} />
        </div>
        <div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('storynotes') && styles.active}`} onClick={() => { router.replace('/storynotes'); }}>
          <EditOutlined className={`${styles.icon} ${pathname.includes('storynotes') && styles.active}`} />
          <div className={styles.title}>
            Story Notes
          </div>
          {/*<Badge count={unreadChats} />*/}
        </div>
        <div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('storyclubs') && styles.active}`} onClick={() => { router.replace('/storyclubs'); }}>
          <TeamOutlined className={`${styles.icon} ${pathname.includes('storyclubs') && styles.active}`} />
          <div className={styles.title}>
            Story Clubs
          </div>
          {/*<Badge count={unreadChats} />*/}
        </div>
        <div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('wishlist') && styles.active}`} onClick={() => { router.replace('/wishlist/books'); }}>
          <BookOutlined className={`${styles.icon} ${pathname.includes('wishlist') && styles.active}`} />
          <div className={styles.title}>
            Wish List
          </div>
        </div>
        <div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('profile') && styles.active}`} onClick={() => { router.replace(`/profile/${currentUser?.username}`); }}>
          <UserOutlined className={`${styles.icon} ${pathname.includes('profile') && styles.active}`} />
          <div className={styles.title}>
            Profile
          </div>
          {/*<Badge count={unreadChats} />*/}
        </div>
        {/*<div role="button" tabIndex={0} className={styles.menuItem} onClick={() => { router.replace('/messages'); }}>*/}
        {/*  <MailOutlined className={styles.icon} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Messages*/}
        {/*  </div>*/}
        {/*  <Badge count={unreadChats} />*/}
        {/*</div>*/}
        {/*<div role="button" tabIndex={0} className={styles.menuItem} onClick={() => { router.replace('/drafts'); }}>*/}
        {/*  <SnippetsOutlined className={styles.icon} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Drafts*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Button
            type="primary"
            size="large"
            className={styles.create}
            onClick={() => { router.replace('/create'); }}
        >
          Create
        </Button>
      </div>
      <div className={styles.themeContainer}>
        <Switch
            className={styles.themeSwitch}
            onChange={onChangeTheme}
            checked={isDarkTheme}
            checkedChildren={<BulbOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked
        />
      </div>
    </div>
  );
}

export default Menu;
