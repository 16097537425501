import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, Input, Modal, Spin,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import styles from './MessagesScreen.module.scss';
import OverViewCard from './OverViewCard/OverViewCard';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import FriendUserCardSkeleton from '../NewChatScreen/FriendUserCardSkeleton/FriendUserCardSkeleton';

const { Search } = Input;
function MessagesScreen() {
  const isNewMessageModalOpen = useSelector<StoreState, boolean>((state) => state.isNewMessageModalOpen);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const history = useHistory();
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `messages-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/chats/rooms?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      if (data?.rooms?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onOverviewCard = (item: any) => {
    if (item?.chatRoom?.type === 'CHAT') {
      const otherUser = currentUser._id === item?.user1._id ? item?.user2 : item?.user1;
      history.push(`/chats/${otherUser._id}`);
    } else {
      history.push(`/group/chats/${item?.chatRoom?._id}`);
    }
  };

  const handleOk = () => {

  };

  const handleCancel = () => {

  };

  const onCreateClick = () => {
    history.push('/newchat');
  };

  const onSearch = (data: string) => {
    setSearchQuery(data);
  };

  return (currentUser
    && (
    <div className={styles.MessagesScreen}>
      <div className={styles.headerContainer}>
        <div className={styles.headerWhiteboard}>
          <div className={styles.messageHeader}>
            <div className={styles.topHeader}>
              <div className={styles.titleHeader}>
                Messages
              </div>
              <div className={styles.create}>
                <Button
                  size="large"
                  shape="circle"
                  type="primary"
                  onClick={onCreateClick}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </div>
            <div className={styles.searchContainer}>
              <Search
                className={styles.searchInput}
                placeholder="Search for people and groups"
                allowClear
                size="large"
                onSearch={onSearch}
              />
            </div>
          </div>
          <div className={styles.messageRequest}>
            message requests
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {
          isFetching && !data
            ? (
              [1, 1, 1, 1, 1, 1].map((item, index) => <FriendUserCardSkeleton key={`${index}`} />)
            ) : (
              <InfiniteScroll
                dataLength={data?.length || 0}
                next={() => fetchMore()}
                hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
                loader={(
                  <div className={styles.spinningContainer}>
                    <Spin />
                  </div>
                      )}
                endMessage={(<p style={{ textAlign: 'center' }} />)}
              >
                {
                      data?.map((page) => page.rooms).flat()
                          .map((item) => <OverViewCard key={item._id} chatRoom={item} currentUserId={currentUser._id} onOverviewCard={onOverviewCard} />)
                }
              </InfiniteScroll>
            )
        }
      </div>
    </div>
    )
  );
}

export default MessagesScreen;
