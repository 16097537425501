import React from 'react';
import { Button, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './main-item.module.scss';
import { ActionTypes } from '../../../actions/types';
import book from '../../../assets/images/books-demo.png';
import movie from '../../../assets/images/movie-demo.png';
import series from '../../../assets/images/series-demo.png';
import podcast from '../../../assets/images/podcast-demo.png';

const { TabPane } = Tabs;
function MainItem() {
  const { type } = useParams<{ type: string }>();
  const dispatch = useDispatch();

  const onNavItemAction = () => {
    dispatch({ type: ActionTypes.TOGGLE_AUTH_MODAL, authType: 'signup' });
  };

  const callback = (key: string) => {
    console.log(key);
  };

  const getCorrectMedium = (mediumType: string) => {
    switch (mediumType) {
      case 'books':
        return 'book';
      case 'series':
        return 'series';
      case 'movies':
        return 'movie';
      case 'podcasts':
        return 'podcast';
      default:
        return 'movie';
    }
  };

  const getCorrectImage = (mediumType: string) => {
    switch (mediumType) {
      case 'books':
        return book;
      case 'series':
        return series;
      case 'movies':
        return movie;
      case 'podcasts':
        return podcast;
      default:
        return movie;
    }
  };

  return (
    <div className={styles.MainItem}>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          FIND YOUR NEXT FAVORITE
          {' '}
          <span className={styles[`${type}`]}>{getCorrectMedium(type)}</span>
        </div>
        <div className={styles.subtitle}>
          The easiest way to find and share the greatest stories.
        </div>
        <div className={styles.actions}>
          <Button className={styles.actionButton} type="primary" onClick={onNavItemAction}>
            Join the community
          </Button>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          // src="https://goodlores.nyc3.cdn.digitaloceanspaces.com/goodlores%20%281%29.png"
          src={getCorrectImage(type)}
          alt=""
        />
        {/* <div className={styles.image}> */}
        {/*  image */}
        {/* </div> */}
        {/* <div className={styles.options}> */}
        {/*  { */}
        {/*    ['books', 'series', 'movies', 'podcasts'].map((val, index) => <div className={styles.option} key={`${index}-op`}>{val}</div>) */}
        {/*  } */}
        {/* </div> */}
        {/* <Tabs defaultActiveKey="1" tabPosition="right" onChange={callback}> */}
        {/*  <TabPane tab="Books" key="1"> */}
        {/*    Content of Tab Pane 1 */}
        {/*  </TabPane> */}
        {/*  <TabPane tab="Series" key="2"> */}
        {/*    Content of Tab Pane 2 */}
        {/*  </TabPane> */}
        {/*  <TabPane tab="Movies" key="3"> */}
        {/*    Content of Tab Pane 3 */}
        {/*  </TabPane> */}
        {/*  <TabPane tab="Podcasts" key="4"> */}
        {/*    Content of Tab Pane 4 */}
        {/*  </TabPane> */}
        {/* </Tabs> */}
      </div>
    </div>
  );
}

export default MainItem;
