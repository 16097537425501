import React from 'react';
import styles from './chat-timestamp-card.module.scss';

type ChatTimestampCardProps = {
  previousCreatedAt: string | undefined;
  currentCreatedAt: string;
  nextCreatedAt: string | undefined;
};

function ChatTimestampCard(props: ChatTimestampCardProps) {
  const { previousCreatedAt, currentCreatedAt, nextCreatedAt } = props;

  const previousDate = previousCreatedAt && new Date(previousCreatedAt).getDate();
  const currentDate = new Date(currentCreatedAt).getDate();
  const nextDate = nextCreatedAt && new Date(nextCreatedAt).getDate();

  return (
    <div>
      {
          nextDate !== currentDate
            && (
            <div className={styles.ChatTimestampCard}>
              {
              new Date(currentCreatedAt).toLocaleString(undefined, {
                day: 'numeric', weekday: 'long', month: 'short', hour: 'numeric', minute: 'numeric',
              })
              }
            </div>
            )
      }
    </div>
  );
}

export default ChatTimestampCard;
