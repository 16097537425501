import React from 'react';
import styles from './HowItWorks.module.scss';
import HowItWorksItem from './HowItWorksItem/HowItWorksItem';

function HowItWorks() {
  const products: any[] = [
    {
      title: 'VISUALLY BEAUTIFUL QUOTES',
      subtitle: 'Create awesome looking quotes and choose from many awesome templates.',
      image: require('../../../assets/images/book9_yellow.svg'),
      link: 'https://techbiits.com',
      reason: 'QUOTE',
    },
    {
      title: 'TRACK YOUR FAVORITE STORIES',
      subtitle: 'Track all your books, series, movies and podcasts and share them with your friends.',
      image: require('../../../assets/images/book5_green.svg'),
      link: 'https://techbiits.com',
      reason: 'TRACK',
    },
    {
      title: 'REVIEW YOUR FAVORITE STORIES',
      subtitle: 'Review your favorite stories in just a few clicks',
      image: require('../../../assets/images/quiz1_red.svg'),
      link: 'https://techbiits.com',
      reason: 'REVIEW',
    },
  ];

  return (
    <div className={styles.HowItWorks}>
      <div className={styles.title}>
        Simple yet Powerful
      </div>
      <div className={styles.subtitle}>
        Packed full of features
      </div>
      <div className={styles.container}>
        {
                    products.map((val, index) => (<HowItWorksItem key={`productItem${index}`} product={val} />))
                }
      </div>
    </div>
  );
}

export default HowItWorks;
