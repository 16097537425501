import React from 'react';
import styles from './goal-card.module.scss';
import Header from '../../stadium-stats/header/header';
import baylor from '../../../../../assets/images/baylor.png';
import basketBall from '../../../../../assets/icons/icons8-basketball-48.png';
import OneUser from '../../stadium-stats/one-user/one-user';
import elon from '../../../../../assets/images/elon.jpeg';
import Footer from '../../stadium-stats/footer/footer';
import GoalHeader from '../goal-header/goal-header';
import GoalUser from '../goal-user/goal-user';

function GoalCard() {
  return (
    <div className={styles.GoalCard}>
      <GoalHeader
        image={basketBall}
        title="Swiiish!!! 3 points"
        minute="87'"
      />
      <GoalUser
        image={elon}
        teamImage={baylor}
        name="E.Musk #24"
      />
      <Footer />
    </div>
  );
}

export default GoalCard;
