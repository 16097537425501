import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button, Input, List, Modal, Spin, Select, DatePicker, InputNumber, Tooltip, Skeleton, Badge, Progress,
} from 'antd';
import {
  PlusOutlined, ArrowLeftOutlined, CloseOutlined, CloseCircleOutlined, FunnelPlotOutlined, ArrowRightOutlined, CheckCircleFilled, CheckCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { queryCache, useInfiniteQuery, useQuery } from 'react-query';
import axios from 'axios';
import styles from './storynote-screen.module.scss';
import OverViewCard from './OverViewCard/OverViewCard';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import SectionCardSkeleton from './section-card-skeleton/section-card-skeleton';
import { StoryNote } from '../../models/StoryNote';
import { ActionTypes } from '../../actions/types';
import {
  getProgressStoryNotePercentage,
} from '../../helpers/helpers';
import Empty from '../../components/Empty/Empty';
import languages from '../../utils/languages.json';
import StoryTemplateCard from '../../components/Cards/story-template-card/story-template-card';
import { CreateTemplate } from '../../models/create-models/CreateTemplate';
import { Template } from '../../models/create-models/Template';
import { Section, StoryNoteTemplate, SubSection } from '../../models/Template';
import NotificationCard from '../../components/Cards/NotificationCard/NotificationCard';
import { updateStoryNote } from '../../services-api/notesService';
import TemplatesModal from "../../components/templates-modal/templates-modal";
import TemplatePreviewModal from "../../components/template-preview-modal/template-preview-modal";

const { Search } = Input;
function StorynoteScreen() {
  const { id } = useParams<{ id: string }>();
  const currentStoryNote = useSelector<StoreState, StoryNote | null>((state) => state.currentStoryNote);
  const isLoadingGlobal = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const [currentTemplate, setCurrentTemplate] = useState<StoryNoteTemplate | undefined>(undefined);
  const history = useHistory();
  const dispatch = useDispatch();
  const [timeStamp] = useState(new Date());
  const [limit] = useState(3);
  const [searchQuery, setSearchQuery] = useState('');
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    const existingStoryNote = queryCache.getQueryData(`story-templates-note-${id}-screen`);
    if (existingStoryNote) {
      dispatch({ type: ActionTypes.SET_CURRENT_STORY_NOTE, value: existingStoryNote });
      const tempStoryNote = existingStoryNote as StoryNote;
      setSections(tempStoryNote.template?.sections);
    }
  }, []);

  const {
    isLoading, error, data, isFetching,
  } = useQuery(`story-templates-note-${id}-screen`, () => axios.get(`/api/notes/story/${id}?search=${searchQuery}&limit=${limit}&timestamp=${timeStamp}`)
    .then((response) => {
      dispatch({ type: ActionTypes.SET_CURRENT_STORY_NOTE, value: response.data });
      const tempStoryNote = response.data as StoryNote;
      setSections(tempStoryNote.template?.sections);
      return tempStoryNote;
    }));

  useEffect(() => {
  }, [isLoading, isFetching]);

  const onSearch = (data: string) => {
    setSearchQuery(data);
    setSections([...sections
      .filter((sec) => {
        let keep = false;
        sec.subChapters.map((sub) => {
          if (sub.text.toLowerCase().includes(data)) {
            keep = true;
          }
          return sub;
        });
        return keep || sec.text.toLowerCase().includes(data);
      })
      .map((sec) => ({
        ...sec,
        subChapters: [...sec.subChapters.filter((sub) => sub.text.toLowerCase().includes(data))],
      }))]);
  };

  const onBackClick = () => {
    dispatch({ type: ActionTypes.SET_CURRENT_STORY_NOTE, value: null });
    history.goBack();
  };

  const onAddStoryTemplateClick = () => {
    dispatch({ type: ActionTypes.SET_TEMPLATES_MODAL, value: true });
  };

  const onAllClick = () => {
    history.push(`/storynotes/${id}/all`);
  };

  const onSectionClick = (section: Section) => {
    history.push(`/storynotes/${id}/${section.id}`);
  };

  const onSectionCompleteClick = (event: any, section: Section) => {
    event.stopPropagation();
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: section.id });
    dispatch(updateStoryNote(id, { completed: { ...currentStoryNote?.completed, [`${section.id}`]: true } }, () => {
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_STORY_NOTE, value: { ...currentStoryNote, completed: { ...currentStoryNote?.completed, [`${section.id}`]: true } } });
    }));
  };

  const onSubSectionCompleteClick = (event: any, subSection: SubSection) => {
    event.stopPropagation();
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: subSection.id });
    dispatch(updateStoryNote(id, { completed: { ...currentStoryNote?.completed, [`${subSection.id}`]: true } }, () => {
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_STORY_NOTE, value: { ...currentStoryNote, completed: { ...currentStoryNote?.completed, [`${subSection.id}`]: true } } });
    }));
  };

  const onSubSectionClick = (subSection: SubSection) => {
    history.push(`/storynotes/${id}/${subSection.id}`);
  };

  const onSearchChange = (searchElem: any) => {
    if (searchElem.target.value === '') {
      setSections(data!.template.sections);
    }
  };

  return (currentUser
        && (
        <div className={styles.StorynoteScreen}>
          <TemplatesModal id={id} />
          <div className={styles.headerContainer}>
            <div className={styles.headerWhiteboard}>
              <div className={styles.messageHeader}>
                <div className={styles.topHeader}>
                  <div className={styles.back}>
                    <Button
                      type="link"
                      icon={<ArrowLeftOutlined />}
                      shape="circle"
                      onClick={onBackClick}
                    />
                  </div>
                  <div className={styles.titleHeader}>
                    {currentStoryNote?.title}
                  </div>
                  <div className={styles.progressContainer}>
                    {/* <Button */}
                    {/*  size="large" */}
                    {/*  shape="circle" */}
                    {/*  type="primary" */}
                    {/*  onClick={onCreateClick} */}
                    {/* > */}
                    {/*  <PlusOutlined /> */}
                    {/* </Button> */}
                  </div>
                </div>
                <div className={styles.searchContainer}>
                  <Search
                    className={styles.searchInput}
                    placeholder="Search for your notes"
                    allowClear
                    disabled={!currentStoryNote?.template}
                    size="large"
                    onSearch={onSearch}
                    onChange={onSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.container}>
            {
              isLoading && !currentStoryNote
                && [1, 1, 1, 1, 1, 1].map((item, index) => <SectionCardSkeleton key={`${index}`} />)
            }
            {
              (!isLoading || !isFetching) && !currentStoryNote?.template
              && (
                  <div className={styles.addTemplateContainer}>
                    <Button
                        className={styles.addTemplateButton}
                        type="dashed"
                        size="large"
                        onClick={onAddStoryTemplateClick}
                    >
                      Add a story template
                    </Button>
                  </div>

              )
            }
            {
              !isLoading && currentStoryNote?.template
              && (
              <div className={styles.chapters}>
                <div className={styles.progressContainer}>
                  <Progress
                    percent={getProgressStoryNotePercentage(currentStoryNote)}
                    strokeColor={{
                      '0%': '#2ECC71',
                      '100%': '#2ECC71',
                    }}
                    status={getProgressStoryNotePercentage(currentStoryNote) !== 100 ? 'active' : 'success'}
                  />
                </div>
                <div className={styles.chapter}>
                  <div className={styles.info} onClick={onAllClick}>
                    <div className={styles.title}>
                      All notes
                    </div>
                    <div className={styles.count}>
                      <Badge count={currentStoryNote?.count && currentStoryNote?.count['all']} />
                    </div>
                    <div className={styles.arrow}>
                      <ArrowRightOutlined />
                    </div>
                  </div>
                </div>
                {
                  sections?.map((section, index) => (
                    <div className={styles.chapter}>
                      <div className={styles.info} onClick={() => onSectionClick(section)}>
                        <Button
                          className={currentStoryNote?.completed && currentStoryNote?.completed[`${section.id}`] ? styles.greenCheckButton : styles.checkButton}
                          icon={currentStoryNote?.completed && currentStoryNote?.completed[`${section.id}`] ? <CheckCircleFilled /> : <CheckCircleOutlined />}
                          shape="circle"
                          type="link"
                          onClick={(e) => onSectionCompleteClick(e, section)}
                          loading={isLoadingGlobal && currentId === section.id}
                        />
                        <div className={styles.number}>
                          {section.id.replace('section', '')}
                          .
                        </div>
                        <div className={styles.title}>
                          {section.text}
                        </div>
                        <div className={styles.location}>
                          {
                            currentStoryNote?.template?.isAudio
                              ? (section?.time?.minute === -1 || section?.time?.second === -1) ? '' : `${section?.time?.minute}min ${section?.time?.second}s`
                              : (section?.page === -1 || section?.page === undefined) ? '' : `page ${section?.page}`
                          }
                        </div>
                        <div className={styles.count}>
                          <Badge count={currentStoryNote?.count && currentStoryNote?.count[`${section.id}`]} />
                        </div>
                        <div className={styles.arrow}>
                          <ArrowRightOutlined />
                        </div>
                      </div>
                      <div className={styles.subChapterSection}>
                        {
                            section.subChapters.map((subSection, subIndex) => (
                              <div key={`subsection-${index}-${subIndex}`} className={styles.subChapter}>
                                <div className={styles.subInfo} onClick={() => onSubSectionClick(subSection)}>
                                  <Button
                                    className={currentStoryNote?.completed && currentStoryNote?.completed[`${subSection.id}`] ? styles.greenCheckButton : styles.checkButton}
                                    icon={currentStoryNote?.completed && currentStoryNote?.completed[`${subSection.id}`] ? <CheckCircleFilled /> : <CheckCircleOutlined />}
                                    shape="circle"
                                    type="link"
                                    onClick={(e) => onSubSectionCompleteClick(e, subSection)}
                                    loading={isLoadingGlobal && currentId === subSection.id}
                                  />
                                  <div className={styles.subNumber}>
                                    {subSection.id
                                      .replace('section', '')
                                      .replace('-sub-section', '.')}
                                  </div>
                                  <div className={styles.title}>
                                    {subSection.text}
                                  </div>
                                  <div className={styles.location}>
                                    {
                                      currentStoryNote?.template?.isAudio
                                        ? (subSection?.time?.minute === -1 || subSection?.time?.second === -1) ? '' : `${subSection?.time?.minute}min ${subSection?.time?.second}s`
                                        : (subSection?.page === -1 || subSection?.page === undefined) ? '' : `page ${subSection?.page}`
                                    }
                                  </div>
                                  <div className={styles.count}>
                                    <Badge count={currentStoryNote?.count && currentStoryNote?.count[`${subSection.id}`]} />
                                  </div>
                                  <div className={styles.arrow}>
                                    <ArrowRightOutlined />
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                      </div>
                    </div>
                  ))
                }
              </div>
              )
            }
          </div>
        </div>
        )
  );
}

export default StorynoteScreen;
