import React from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './AddThoughts.module.scss';
import { CreateQuote } from '../../../models/create-models/CreateQuote';
import { ActionTypes } from '../../../actions/types';

interface AddThoughtsProps {
  createQuote: CreateQuote;
}
const { TextArea } = Input;
function AddThoughts(props: AddThoughtsProps) {
  const { createQuote } = props;
  const dispatch = useDispatch();

  const onTextChange = (data: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newCreateQuote = { ...createQuote, thought: data.target.value };
    dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
  };

  return (
    <div className={styles.AddThoughts}>
      <TextArea
        className={styles.thoughtInput}
        rows={4}
        defaultValue={createQuote.thought}
        value={createQuote.thought}
        placeholder="Type a thought you would like to share"
        onChange={onTextChange}
      />
    </div>
  );
}

export default AddThoughts;
