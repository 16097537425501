import React, { useEffect, useState } from 'react';
import {
  Badge, Button, DatePicker, Input, InputNumber, Modal, Progress, Select, Skeleton, Spin, Tag, Tooltip, Typography,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {useInfiniteQuery, useQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import {
  FunnelPlotOutlined, CloseCircleOutlined, ArrowLeftOutlined, ArrowRightOutlined, CheckCircleFilled, CheckCircleOutlined, LockOutlined,
} from '@ant-design/icons';
import styles from './sections-tab.module.scss';
import languages from '../../../utils/languages.json';
import StoryTemplateCard from '../../../components/Cards/story-template-card/story-template-card';
import SectionCardSkeleton from '../../storynote-screen/section-card-skeleton/section-card-skeleton';
import { StoreState } from '../../../reducers/_RootReducers';
import { CreateTemplate } from '../../../models/create-models/CreateTemplate';
import { Section, StoryNoteTemplate, SubSection } from '../../../models/Template';
import { ActionTypes } from '../../../actions/types';
import { updateTopic } from '../../../services-api/clubTopicsService';
import { Topic } from '../../../models/Topic';
import { getProgressTopicPercentage } from '../../../helpers/helpers';
import { availableDate } from "../../../services-util/club-story-service-util";

const { Search } = Input;
const { Text } = Typography;
function SectionsTab() {
  const { id, storiesId } = useParams<{ id: string, storiesId: string }>();
  const createTemplate = useSelector<StoreState, CreateTemplate>((state) => state.createTemplate);
  const isTemplatesModalOpen = useSelector<StoreState, boolean>((state) => state.isTemplatesModalOpen);
  const isTemplatePreviewModalOpen = useSelector<StoreState, boolean>((state) => state.isTemplatePreviewModalOpen);
  const isLoadingGlobal = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<StoryNoteTemplate | undefined>(undefined);
  const history = useHistory();
  const dispatch = useDispatch();
  const [timeStamp] = useState(new Date());
  const [limit] = useState(3);
  const [searchQuery, setSearchQuery] = useState('');
  const [sections, setSections] = useState<Section[]>([]);
  const [language, setLanguage] = useState<string | undefined>('');
  const [year, setYear] = useState<number>(0);
  const [version, setVersion] = useState<number | undefined>(undefined);
  const [searchTemplatesQuery, setSearchTemplatesQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const queryCache = useQueryCache();

  const {
    isLoading: isTopicLoading, isFetching: isTopicFetching, error: topicError, data: currentTopic,
  } = useQuery(`story-${storiesId}`, () => axios.get(`/api/clubs/topics/${storiesId}`)
    .then((response) => {
      if (response.data && response.data.template) {
        setSections(response.data.template?.sections);
      }
      return response.data;
    }));

  useEffect(() => {
    const existingStory = queryCache.getQueryData(`story-${storiesId}`);
    if (existingStory) {
      const tempStory = existingStory as Topic;
      setSections(tempStory.template?.sections || []);
    }
  }, []);

  const {
    isLoading: isTemplatesLoading, data: templatesData, fetchMore,
  } = useInfiniteQuery(`story-templates-${id}-${language}-${year}-${version}-templates-screen-${searchTemplatesQuery}`,
    async (key, nextId = 0) => {
      const { data: templateData } = await axios.get(`/api/templates/medium/${currentTopic?.medium}?search=${searchTemplatesQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}&language=${language}&year=${year}&version=${version}`);
      if (templateData?.templates?.length < limit) {
        setIsAtTheEnd(true);
      }
      return templateData;
    },
    {
      enabled: !!currentTopic?.medium,
      getFetchMore: (lastGroup) => lastGroup?.nextId,
    });

  const handleOk = () => {

  };

  const onSearch = (data: string) => {
    setSearchQuery(data);
    setSections([...sections
      .filter((sec) => {
        let keep = false;
        sec.subChapters.map((sub) => {
          if (sub.text.toLowerCase().includes(data)) {
            keep = true;
          }
          return sub;
        });
        return keep || sec.text.toLowerCase().includes(data);
      })
      .map((sec) => ({
        ...sec,
        subChapters: [...sec.subChapters.filter((sub) => sub.text.toLowerCase().includes(data))],
      }))]);
  };

  const onTemplateSearch = (data: string) => {
    setSearchTemplatesQuery(data);
  };

  const onBackClick = () => {
    dispatch({ type: ActionTypes.SET_CURRENT_STORY_NOTE, value: null });
    history.replace('/storynotes');
  };

  const onAddStoryTemplateClick = () => {
    dispatch({ type: ActionTypes.SET_TEMPLATES_MODAL, value: true });
  };

  const onLangChange = (data: any) => {
    if (data) {
      setLanguage(data);
    } else {
      setLanguage('');
    }
  };

  const onYearChange = (data: any) => {
    if (data) {
      setYear(new Date(data._d).getFullYear());
    } else {
      setYear(0);
    }
  };

  const onVersionChange = (data: any) => {
    setVersion(data);
  };

  const onPreviewClick = (template: any) => {
    setCurrentTemplate(template);
    dispatch({ type: ActionTypes.SET_TEMPLATE_PREVIEW_MODAL, value: true });
  };

  const onCloseTemplatesModal = () => {
    dispatch({ type: ActionTypes.SET_TEMPLATES_MODAL, value: false });
  };

  const onClosePreviewTemplateModal = () => {
    dispatch({ type: ActionTypes.SET_TEMPLATE_PREVIEW_MODAL, value: false });
  };

  const onCreateTemplateClick = () => {
    const newCreateTemplate = {
      title: '',
      length: 1,
      version: 1,
      privacy: 'public',
      language: 'English',
      sections: [],
      medium: currentTopic?.medium,
      currentPage: 2,
      totalPages: 4,
      type: 'template',
      redirectUrl: `/storynotes/${id}`,
    };
    dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: newCreateTemplate });
    history.push('/create/template');
  };

  const onUseTemplateClick = () => {
    // TODO: update topic to include a template
    dispatch(updateTopic(storiesId, { templateId: currentTemplate?._id }, () => {
      queryCache.invalidateQueries(`story-${storiesId}`);
      onClosePreviewTemplateModal();
      onCloseTemplatesModal();
    }));
  };

  const onAllClick = () => {
    history.push(`/storynotes/${id}/all`);
  };

  const onSectionClick = (section: Section) => {
    history.push(`/storyclubs/${id}/stories/${storiesId}/${section.id}`);
  };

  const onSubSectionClick = (subSection: SubSection) => {
    history.push(`/storyclubs/${id}/stories/${storiesId}/${subSection.id}`);
  };

  const onSearchChange = (searchElem: any) => {
    if (searchElem.target.value === '') {
      // setSections(data!.template.sections);
    }
  };

  const onSectionCompleteClick = (event: any, section: Section) => {
    event.stopPropagation();
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: section.id });
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(updateTopic(storiesId, { completed: { ...currentTopic?.completed, [`${section.id}`]: true } }, () => {
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      queryCache.setQueryData(`story-${storiesId}`, (oldData: any) => {
        if (oldData?.completed) {
          return { ...oldData, completed: { ...oldData?.completed, [`${section.id}`]: true } };
        }
        return oldData;
      });
    }));
  };

  const onSubSectionCompleteClick = (event: any, subSection: SubSection) => {
    event.stopPropagation();
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: subSection.id });
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(updateTopic(storiesId, { completed: { ...currentTopic?.completed, [`${subSection.id}`]: true } }, () => {
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      queryCache.setQueryData(`story-${storiesId}`, (oldData: any) => {
        if (oldData?.completed) {
          return { ...oldData, completed: { ...oldData?.completed, [`${subSection.id}`]: true } };
        }
        return oldData;
      });
    }));
  };

  return (
    <div className={styles.SectionsTab}>
      <Modal
        className={styles.MediumModal}
        title={null}
        visible={isTemplatesModalOpen}
        onOk={handleOk}
        footer={null}
        closable={false}
        onCancel={onCloseTemplatesModal}
      >
        <div className={styles.content}>
          <div className={styles.title}>
            Templates
          </div>
          <div className={styles.filterSearchContainer}>
            <Search
              placeholder="Search for templates..."
              size="large"
              allowClear
              onSearch={onTemplateSearch}
            />
            <Tooltip title="filter">
              <Button
                className={styles.filtersButton}
                icon={<FunnelPlotOutlined />}
                size="large"
                onClick={() => setShowFilters(!showFilters)}
              />
            </Tooltip>
          </div>
          {
                      showFilters
                      && (
                      <div>
                        <div className={styles.filtersTitle}>
                          Filters
                        </div>
                        <div className={styles.filtersContainer}>
                          <div className={styles.filterContainer}>
                            <div className={styles.filterTitle}>
                              Language
                            </div>
                            <Select
                              showSearch
                              style={{ width: '120px' }}
                              size="large"
                              onChange={onLangChange}
                              allowClear
                            >
                              {
                                  languages.map((language) => <Select.Option value={language.name}>{language.name}</Select.Option>)
                              }
                            </Select>
                          </div>
                          <div className={styles.filterContainer}>
                            <div className={styles.filterTitle}>
                              Year
                            </div>
                            <DatePicker
                              onChange={onYearChange}
                              picker="year"
                              size="large"
                            />
                          </div>
                          <div className={styles.filterContainer}>
                            <div className={styles.filterTitle}>
                              Version
                            </div>
                            <InputNumber
                              min={0}
                              onChange={onVersionChange}
                              size="large"
                            />
                          </div>
                        </div>
                      </div>
                      )
                  }
          <div id="scrollableDivTemplates" className={styles.templatesBody}>
            {
                          (isTemplatesLoading) && (
                          <div className={styles.loadingContainer}>
                            {
                                      [1, 1, 1, 1].map((item, index) => <div key={`${index}-loading-card`} className={styles.loadingCard}><Skeleton avatar active paragraph={{ rows: 1 }} /></div>)
                                  }
                          </div>
                          )
                          || templatesData?.map((page) => page.templates).flat().length === 0
                          && <div className={styles.nothingContainer}>No templates found</div>
                          || (
                          <InfiniteScroll
                            dataLength={templatesData?.map((page) => page.templates).flat().length || 0}
                            next={() => fetchMore()}
                            hasMore={(templatesData?.map((page) => page.templates).flat().length || 0) > 0 && !isAtTheEnd}
                            loader={(
                              <div className={styles.spinningContainer}>
                                <Spin />
                              </div>
                                  )}
                            endMessage={(
                              <p style={{ textAlign: 'center' }} />
                                  )}
                            scrollableTarget="scrollableDivTemplates"
                          >
                            <div className={styles.container}>
                              {
                                          templatesData?.map((page) => page.templates).flat().map((item, index) => (
                                            <div key={`${index}-thing`}>
                                              <StoryTemplateCard
                                                template={item}
                                                onPreview={onPreviewClick}
                                              />
                                            </div>
                                          ))
                                      }
                            </div>
                          </InfiniteScroll>
                          )
                      }
          </div>
          <Button
            className={styles.createButton}
            type="primary"
            size="large"
            onClick={onCreateTemplateClick}
          >
            Create Template
          </Button>
        </div>
      </Modal>
      <Modal
        className={styles.TemplateModal}
        title={null}
        visible={isTemplatePreviewModalOpen}
        onOk={handleOk}
        footer={null}
        closable={false}
        onCancel={onClosePreviewTemplateModal}
      >
        <div className={styles.content}>
          <div className={styles.previewHeader}>
            <div className={styles.previewTitle}>
              {currentTemplate?.title}
            </div>
            <Button
              className={styles.closeButton}
              type="link"
              size="large"
              icon={<CloseCircleOutlined />}
              onClick={onClosePreviewTemplateModal}
            />
          </div>
          <div className={styles.previewInfo}>
            {currentTemplate?.language}
            {' '}
            •
            {' '}
            {currentTemplate?.year}
            {' '}
            •
            {' '}
            {currentTemplate?.version}
          </div>
          <div className={styles.previewBody}>
            {
                          currentTemplate?.sections.map((section, index) => (
                            <div className={styles.chapter}>
                              <div className={styles.info}>
                                <div className={styles.number}>
                                  {index + 1}
                                  .
                                </div>
                                <div>
                                  {section.text}
                                </div>
                                <div className={styles.location}>
                                  {
                                              currentTemplate?.isAudio
                                                ? (section.time.minute === -1 || section.time.second === -1) ? '' : `${section.time.minute}min ${section.time.second}s`
                                                : section.page === -1 ? '' : `page ${section.page}`
                                          }
                                </div>
                              </div>
                              <div className={styles.subChapterSection}>
                                {
                                          section.subChapters.map((subSection, subIndex) => (
                                            <div key={`subsection-${index}-${subIndex}`} className={styles.subChapter}>
                                              <div className={styles.subInfo}>
                                                <div className={styles.subNumber}>
                                                  {index + 1}
                                                  .
                                                  {subIndex + 1}
                                                  .
                                                </div>
                                                <div className={styles.subChapterInput}>
                                                  {subSection.text}
                                                </div>
                                                <div className={styles.location}>
                                                  {
                                                              currentTemplate?.isAudio
                                                                ? (subSection.time.minute === -1 || subSection.time.second === -1) ? '' : `${subSection.time.minute}min ${subSection.time.second}s`
                                                                : subSection.page === -1 ? '' : `page ${subSection.page}`
                                                          }
                                                </div>
                                              </div>
                                            </div>
                                          ))
                                      }
                              </div>
                            </div>
                          ))
                      }
          </div>
          <div className={styles.previewFooter}>
            <Button
              className={styles.useButton}
              type="primary"
              size="large"
              onClick={onUseTemplateClick}
            >
              Use template
            </Button>
          </div>
        </div>
      </Modal>
      <div className={styles.container}>
        {
          isTopicLoading && !currentTopic
                  && [1, 1, 1, 1, 1, 1].map((item, index) => <SectionCardSkeleton key={`${index}`} />)
        }
        {
                  (!isTopicLoading || !isTopicFetching) && !currentTopic?.template
                  && (
                  <Button
                    className={styles.addTemplateButton}
                    type="dashed"
                    size="large"
                    onClick={onAddStoryTemplateClick}
                  >
                    Add a story template
                  </Button>
                  )
              }
        {
                  !isTopicLoading && currentTopic?.template
                  && (
                  <div className={styles.chapters}>
                    <div className={styles.progressContainer}>
                      <Progress
                        percent={getProgressTopicPercentage(currentTopic)}
                        strokeColor={{
                          '0%': '#2ECC71',
                          '100%': '#2ECC71',
                        }}
                        status={getProgressTopicPercentage(currentTopic) !== 100 ? 'active' : 'success'}
                      />
                    </div>
                    <div className={styles.chapter}>
                      <div className={styles.info} onClick={onAllClick}>
                        <div className={styles.title}>
                          All notes
                        </div>
                        <div className={styles.count}>
                          <Badge count={currentTopic?.count && currentTopic?.count['all']} />
                        </div>
                        <div className={styles.arrow}>
                          <ArrowRightOutlined />
                        </div>
                      </div>
                    </div>
                    {
                              sections?.map((section, index) => {
                                const availableDateVal = availableDate(section.id, sections, new Date(currentTopic?.startDate), timeStamp, currentTopic?.rate, currentTopic?.frequency);
                                const sectionIsDisabled = availableDateVal > timeStamp;
                                return (
                                    <Text className={styles.chapter}
                                          disabled={sectionIsDisabled}
                                    >
                                      <div
                                          className={`${sectionIsDisabled ? styles.disableInfo: styles.info }`}
                                          onClick={() => !sectionIsDisabled && onSectionClick(section)}
                                      >
                                        {
                                          sectionIsDisabled ?
                                              <Button
                                                  className={styles.checkButton}
                                                  icon={<LockOutlined className={styles.checkButton} />}
                                                  shape="circle"
                                                  type="link"
                                                  disabled
                                              />
                                              :
                                              <Button
                                                  className={currentTopic?.completed && currentTopic?.completed[`${section.id}`] ? styles.greenCheckButton : styles.checkButton}
                                                  icon={currentTopic?.completed && currentTopic?.completed[`${section.id}`] ? <CheckCircleFilled /> : <CheckCircleOutlined />}
                                                  shape="circle"
                                                  type="link"
                                                  disabled={sectionIsDisabled}
                                                  onClick={(e) => onSectionCompleteClick(e, section)}
                                                  loading={isLoadingGlobal && currentId === section.id}
                                              />
                                        }
                                        <div className={styles.number}>
                                          {section.id.replace('section', '')}
                                          .
                                        </div>
                                        <div className={styles.title}>
                                          {section.text}
                                        </div>
                                        <div className={styles.location}>
                                          {
                                            currentTopic?.template?.isAudio
                                                ? (section?.time?.minute === -1 || section?.time?.second === -1) ? '' : `${section?.time?.minute}min ${section?.time?.second}s`
                                                : (section?.page === -1 || section?.page === undefined) ? '' : `page ${section?.page}`
                                          }
                                        </div>
                                        <div className={styles.count}>
                                          <Badge count={currentTopic?.count && currentTopic?.count[`${section.id}`]} />
                                        </div>
                                        <div className={styles.arrow}>
                                          {
                                            sectionIsDisabled ?
                                                <Tag aria-disabled="true">{availableDateVal.toLocaleString(undefined, {
                                                  day: 'numeric', weekday: 'short', month: 'short',
                                                })}</Tag>
                                                : <ArrowRightOutlined />
                                          }
                                        </div>
                                      </div>
                                      <div className={styles.subChapterSection}>
                                        {
                                          section.subChapters.map((subSection, subIndex) => {
                                            const subAvailableDateVal = availableDate(subSection.id, sections, new Date(currentTopic?.startDate), timeStamp, currentTopic?.rate, currentTopic?.frequency);
                                            const subSectionIsDisabled = subAvailableDateVal > timeStamp;
                                            return (
                                                <div
                                                    key={`subsection-${index}-${subIndex}`}
                                                    className={subSectionIsDisabled ? styles.disableSubChapter :styles.subChapter}
                                                >
                                                  <div
                                                      className={subSectionIsDisabled ? styles.disableSubInfo: styles.subInfo}
                                                      onClick={() => !subSectionIsDisabled && onSubSectionClick(subSection)}
                                                  >
                                                    {
                                                      subSectionIsDisabled ?
                                                          <Button
                                                              className={styles.checkButton}
                                                              icon={<LockOutlined className={styles.checkButton} />}
                                                              shape="circle"
                                                              type="link"
                                                              disabled
                                                          />:
                                                          <Button
                                                              className={currentTopic?.completed && currentTopic?.completed[`${subSection.id}`] ? styles.greenCheckButton : styles.checkButton}
                                                              icon={currentTopic?.completed && currentTopic?.completed[`${subSection.id}`] ? <CheckCircleFilled /> : <CheckCircleOutlined />}
                                                              shape="circle"
                                                              type="link"
                                                              disabled={subSectionIsDisabled}
                                                              onClick={(e) => !subSectionIsDisabled && onSubSectionCompleteClick(e, subSection)}
                                                              loading={isLoadingGlobal && currentId === subSection.id}
                                                          />
                                                    }
                                                    <div className={styles.subNumber}>
                                                      {subSection.id
                                                          .replace('section', '')
                                                          .replace('-sub-section', '.')}
                                                    </div>
                                                    <div className={styles.title}>
                                                      {subSection.text}
                                                    </div>
                                                    {
                                                      subSectionIsDisabled ?
                                                          <div className={styles.location}>
                                                          </div>
                                                          :
                                                          <div className={styles.location}>
                                                            {
                                                              currentTopic?.template?.isAudio
                                                                  ? (subSection?.time?.minute === -1 || subSection?.time?.second === -1) ? '' : `${subSection?.time?.minute}min ${subSection?.time?.second}s`
                                                                  : (subSection?.page === -1 || subSection?.page === undefined) ? '' : `page ${subSection?.page}`
                                                            }
                                                          </div>
                                                    }
                                                    <div className={styles.count}>
                                                      <Badge count={currentTopic?.count && currentTopic?.count[`${subSection.id}`]} />
                                                    </div>
                                                    <div className={styles.arrow}>
                                                      {
                                                        subSectionIsDisabled ?
                                                            <Tag aria-disabled="true">{subAvailableDateVal.toLocaleString(undefined, {
                                                              day: 'numeric', weekday: 'short', month: 'short',
                                                            })}</Tag>
                                                            : <ArrowRightOutlined />
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                            )
                                          })
                                        }
                                      </div>
                                    </Text>
                                )
                              })
                          }
                  </div>
                  )
              }
      </div>
    </div>
  );
}

export default SectionsTab;
