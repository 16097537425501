import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './PostHeader.module.scss';
import { User } from '../../../models/User';
import { convertTimeToDaysAgo } from '../../../helpers/helpers';
import {fallBackUrl} from "../../../utils/imageData";
import {Button, Dropdown, Image, Menu} from "antd";
import {EllipsisOutlined, MoreOutlined} from "@ant-design/icons";

interface PostHeaderProps {
  user: User;
  createdAt: string;
  post: any;
}

function PostHeader(props:PostHeaderProps) {
  const { user, createdAt, post } = props;
  const history = useHistory();

  const onUserClick = () => {
    history.push(`/profile/${user.username}`);
  };

  const onThumbnailClick = () => {
    history.push(`/thumbnail/${post._id}`);
  };

  const menu = (
      <Menu>
        <Menu.Item onClick={onThumbnailClick}>
          Thumbnail
        </Menu.Item>
      </Menu>
  );

  return (
    <div className={styles.PostHeader}>
      <Image
          className={styles.profileImage}
          src={user.image}
          alt={user.image}
          preview={false}
          fallback={fallBackUrl}
      />
      <div className={styles.userInfo}>
        <div className={styles.username} onClick={onUserClick}>
          <span className={styles.displayName}>
            <b>
              {user.displayName}
            </b>
          </span>
          <span style={{ paddingLeft: '4px' }}>
            @
            {user.username}
          </span>
        </div>
        <div className={styles.timestamp}>
          {convertTimeToDaysAgo(createdAt)}
        </div>
      </div>
      {
        post.typeValue === 3 &&
        <div className={styles.more}>
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <Button type="link" size="small" icon={<EllipsisOutlined />}/>
          </Dropdown>
        </div>
      }
    </div>
  );
}

export default PostHeader;
