import React from 'react';
import { Button, Tabs } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  useParams, useHistory,
} from 'react-router-dom';
import baylor from '../../assets/images/baylor.png';
import gonzaga from '../../assets/images/gonzaga.jpg';
import styles from './stadium-screen.module.scss';
import colosseum from '../../assets/icons/icons8-colosseum-50.png';
import StadiumTabIcon from './stadium-tab-icon/stadium-tab-icon';
import HomeContent from './home-content/home-content';
import StandsContent from './stands-content/stands-content';
import DmsContent from './dms-content/dms-content';
import YouScreen from './you-screen/you-screen';
import StandContent from './stand-content/stand-content';

const { TabPane } = Tabs;
function StadiumScreen() {
  const { id, tab, tabId } = useParams();
  const history = useHistory();
  const onBackAction = () => {
    history.push('/sport/events/123');
  };

  const callback = (data: any) => {
    console.log('data: ', data);
    history.push(`/stadiums/${id}/${data}`);
  };

  return (
    <div className={styles.StadiumScreen}>
      <div className={styles.container}>
        <div className={styles.scoreHeader}>
          <Button
            className={styles.backButton}
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={onBackAction}
          />
          <div className={styles.scoreContainer}>
            <img
              className={styles.teamIcon1}
              src={baylor}
            />
            <div className={styles.teamScore1}>
              100
            </div>
            <div className={styles.split}>
              -
            </div>
            <div className={styles.teamScore2}>
              101
            </div>
            <img
              className={styles.teamIcon2}
              src={gonzaga}
            />
          </div>
        </div>
        <div className={styles.content}>
          <Tabs
            tabBarGutter={0}
            className={styles.tabs}
            defaultActiveKey={tab}
            onChange={callback}
          >
            <TabPane
              tab={(
                <StadiumTabIcon
                  icon="home"
                />
                )}
              key="home"
            >
              <HomeContent />
            </TabPane>
            <TabPane
              tab={(
                <StadiumTabIcon
                  icon="stands"
                />
                )}
              key="stands"
            >
              {
                tabId
                  ? <StandContent />
                  : <StandsContent />
              }
            </TabPane>
            <TabPane
              tab={(
                <StadiumTabIcon
                  icon="dms"
                />
                )}
              key="dms"
            >
              <DmsContent />
            </TabPane>
            <TabPane
              tab={(
                <StadiumTabIcon
                  icon="you"
                />
                )}
              key="you"
            >
              <YouScreen />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default StadiumScreen;
