import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './FriendUserCard.module.scss';
import { User } from '../../../models/User';
import {fallBackUrl} from "../../../utils/imageData";
import {Image} from "antd";

type FriendUserCardProps = {
  user: User;
};

function FriendUserCard(props: FriendUserCardProps) {
  const { user } = props;
  const history = useHistory();

  const onFriendClick = () => {
    history.push(`/chats/${user._id}`);
  };

  return (
    <div className={styles.FriendUserCard} onClick={onFriendClick}>
      <Image
          className={styles.image}
          src={user.image}
          alt={user.image}
          preview={false}
          fallback={fallBackUrl}
      />
      <div className={styles.textContainer}>
        <div className={styles.displayName}>
          {user.displayName}
        </div>
        <div className={styles.username}>
          {user.username}
        </div>
      </div>
    </div>
  );
}

export default FriendUserCard;
