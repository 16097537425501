import { ActionTypes } from '../actions/types';
import { Post } from '../models/Post';

interface setCreateQuizAnswers {
  type: typeof ActionTypes.SET_CREATE_QUIZ_ANSWERS;
  value: { [key: string]: string };
}

interface setQuizAnswers {
  type: typeof ActionTypes.SET_QUIZ_ANSWERS;
  value: { [key: string]: string };
}

interface setCurrentQuiz {
  type: typeof ActionTypes.SET_CURRENT_QUIZ;
  value: Post | null;
}

type quizActions = setQuizAnswers | setCreateQuizAnswers | setCurrentQuiz;

export const createQuizAnswers = (state: { [key: string]: string } = {}, action: quizActions) => {
  if (action.type === ActionTypes.SET_CREATE_QUIZ_ANSWERS) {
    return action.value;
  }
  return state;
};

export const currentQuiz = (state: Post | null = null, action: quizActions) => {
  if (action.type === ActionTypes.SET_CURRENT_QUIZ) {
    return action.value;
  }
  return state;
};

export const quizAnswers = (state: { [key: string]: string } = {}, action: quizActions) => {
  if (action.type === ActionTypes.SET_QUIZ_ANSWERS) {
    return action.value;
  }
  return state;
};
