import React, {useState} from 'react';
import styles from './Templates.module.scss';
import TemplatesItem from "./TemplatesItem/TemplatesItem";
import {TemplatePreview} from "../../../models/TemplatePreview";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {CreateQuote} from "../../../models/create-models/CreateQuote";
import {ActionTypes} from "../../../actions/types";
import {classicTemplatePreview} from "../../../helpers/templates/ClassicTemplate";
import { highlightsTemplatePreview} from "../../../helpers/templates/HighlightTemplate";
import {CreateList} from "../../../models/create-models/create-list";
import {backgroundTextTemplatePreview} from "../../../helpers/templates/BackgroundTextTemplate";

type TemplatesProps = {
    createQuote: CreateQuote | CreateList | undefined;
    actionType: typeof ActionTypes.SET_CREATE_QUOTE | typeof ActionTypes.SET_CREATE_LIST;
}

function Templates(props: TemplatesProps) {
    const { createQuote, actionType } = props;
    const [template, setTemplate] = useState<TemplatePreview | null>(classicTemplatePreview);
    const dispatch = useDispatch();

    const onTemplateSelect = (templatePreview: TemplatePreview) => {
        dispatch({ type: actionType, value: {...createQuote, template: templatePreview.template }})
        setTemplate(templatePreview);
    };

    const isActive = (temp: TemplatePreview) => {
        if (template) {
            return template.id === temp.id ? true: false
        }
        return false;
    };

    //const data = [classic,highlights,simple,simple1];
    const data: TemplatePreview[] = [classicTemplatePreview, highlightsTemplatePreview, backgroundTextTemplatePreview];

    return (
        <div className={styles.Templates}>
            <div className={styles.templateContainer}>
                {
                    data.map((val, index) => {
                        return <TemplatesItem
                            key={`TemplatesItem${index}`}
                            template={val}
                            active={isActive(val)}
                            onTemplateSelect={onTemplateSelect}
                        />
                    })
                }
            </div>
        </div>
    );
}

export default Templates;
