export const flexConverter = (textAlign: string) => {
    switch (textAlign) {
        case 'left':
            return 'flex-start';
        case 'right':
            return 'flex-end';
        default:
            return textAlign;
    }
};
