import React from 'react';
import {
  Button, Tag, Dropdown, Menu, Image,
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import styles from './member-card.module.scss';
import { User } from '../../../models/User';
import { fallBackUrl } from '../../../utils/imageData';

type MemberCardProps = {
  user: User;
  role: string;
  currentUserId: string;
  onMakeAdminClick: (user: User) => void;
  onRemoveClick: (user: User) => void;
};

function MemberCard(props: MemberCardProps) {
  const {
    user, role, currentUserId, onMakeAdminClick, onRemoveClick,
  } = props;

  const menu = (
    <Menu>
      {
          user.role !== 'ADMIN'
          && (
          <Menu.Item onClick={() => onMakeAdminClick(user)}>
            Make admin
          </Menu.Item>
          )
      }
      <Menu.Item danger onClick={() => onRemoveClick(user)}>
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.MemberCard}>
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          src={user.image}
          alt={user.image}
          preview={false}
          fallback={fallBackUrl}
        />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.header}>
          <div className={styles.name}>
            <div className={styles.displayName}>
              {user.displayName}
            </div>
            <div className={styles.username}>
              @
              {user.username}
            </div>
          </div>
          {
            role === 'ADMIN' && user._id !== currentUserId
            && (
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <Button
                type="primary"
                ghost
                shape="circle"
                loading={false}
                      // onClick={onFollowClick}
                icon={<EllipsisOutlined />}
              />
            </Dropdown>
            )
          }
        </div>
        <div className={styles.bio}>
          {
            user.role === 'ADMIN'
            && <Tag color="red">Admin</Tag>
          }
        </div>
      </div>
    </div>
  );
}

export default MemberCard;
