import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Affix, List, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useInfiniteQuery, useQuery} from 'react-query';
import styles from './HomeScreen.module.scss';
import PostModal from './PostModal/PostModal';
import PostCard from './PostCard/PostCard';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { Post } from '../../models/Post';
import Empty from '../../components/Empty/Empty';
import { ActionTypes } from '../../actions/types';
import { like, unlike } from '../../services-api/reactionService';
import Menu from '../../components/Menu/Menu';
import WiltCard2 from './WiltCard2/WiltCard2';
import SummaryCard from './SummaryCard/SummaryCard';
import RatingCard from './RatingCard/RatingCard';
import PostCardSkeleton from './PostCardSkeleton/PostCardSkeleton';
import InterestModal from '../../components/InterestModal/InterestModal';
import QuizPostCard from './QuizPostCard/QuizPostCard';
import QuizModal from './QuizModal/QuizModal';
import Snippet from "./snippet/snippet";
import {Note} from "../../models/Note";
import {useHistory} from "react-router-dom";

function HomeScreen() {
  const currentUser = useSelector<StoreState, User >((state) => state.currentUser);
  const isInterestModalVisible = useSelector<StoreState, boolean>((state) => state.isInterestModalVisible);
  const reactionIds = useSelector<StoreState, any>((state) => state.reactionIds);
  const shelfIds = useSelector<StoreState, any>((state) => state.shelfIds);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const refContainer = useRef<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    'home-screen',
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/home?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...data?.shelfIds } });
      dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, ...data?.reactionIds } });
      if (data?.posts?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const likePost = (post: Post, type: string, callBack: Function) => {
    dispatch(like(currentUser.username, post, type, callBack));
  };

  const unlikePost = (post: Post, callBack: Function) => {
    dispatch(unlike(currentUser.username, post, callBack));
  };

  useEffect(() => {
  }, [data]);

  const displayCorrectCard = (item: any) => {
    switch (item.typeValue) {
      case 0:
        return (
          <PostCard
            key={`${item}-p`}
            post={item}
            reactionIds={reactionIds}
            shelfIds={shelfIds}
            likePost={likePost}
            unlikePost={unlikePost}
          />
        );
      case 1:
        return (
          <WiltCard2
            key={`${item}-w`}
            post={item}
            reactionIds={reactionIds}
            shelfIds={shelfIds}
            likePost={likePost}
            unlikePost={unlikePost}
          />
        );
      case 2:
        return (
          <SummaryCard
            key={`${item}-s`}
            post={item}
            reactionIds={reactionIds}
            shelfIds={shelfIds}
            likePost={likePost}
            unlikePost={unlikePost}
          />
        );
      case 3:
        return (
          <RatingCard
            key={`${item._id}-r`}
            post={item}
            reactionIds={reactionIds}
            shelfIds={shelfIds}
            likePost={likePost}
            unlikePost={unlikePost}
          />
        );
      case 4:
        return (
          <QuizPostCard
            key={`${item}-q`}
            post={item}
            shelfIds={shelfIds}
            reactionIds={reactionIds}
            likePost={likePost}
            unlikePost={unlikePost}
          />
        );
      default:
        return <div key={`random-${item}`} />;
    }
  };

  return (
    <div className={styles.HomeScreen}>
      <QuizModal />
      <PostModal />
      {
        currentUser && ((!currentUser?.setupInterests?.skip && !currentUser?.setupInterests?.complete) || isInterestModalVisible)
        && <InterestModal />
      }
      <div ref={refContainer} className={styles.centerContainer}>
        {
          isFetching && !data
              ? (
                  <List
                      className={styles.postsList}
                      grid={{ gutter: 0, column: 1 }}
                      dataSource={[1, 1, 1]}
                      renderItem={(item, index) => <PostCardSkeleton key={`${index}-ttt`} />}
                  />
              ) : (
                  <InfiniteScroll
                      dataLength={data?.length || 0}
                      next={() => fetchMore()}
                      hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
                      loader={(
                          <div className={styles.spinningContainer}>
                            <Spin />
                          </div>
                      )}
                      endMessage={(<p style={{ textAlign: 'center' }} />)}
                  >
                    <List
                        className={styles.postsList}
                        grid={{ gutter: 0, column: 1 }}
                        dataSource={data && data?.map((page) => page.posts).flat()}
                        locale={{
                          emptyText: <Empty
                              title="Find your friends"
                              subTitle="Follow users to see their posts here"
                              icon="search"
                              buttonTitle="Search"
                              url="/search"
                              screen="home"
                          />,
                        }}
                        renderItem={(item) => (displayCorrectCard(item))}
                    />
                  </InfiniteScroll>
              )
        }
      </div>
    </div>
  );
}

export default HomeScreen;
