import React from 'react';
import styles from './recent-card-skeleton.module.scss';
import {Skeleton} from "antd";

function RecentCardSkeleton() {
    return (
        <div className={styles.RecentCardSkeleton}>
            <Skeleton avatar active paragraph={{ rows: 1 }} />
        </div>
    );
}

export default RecentCardSkeleton;