import React, {useEffect, useState} from 'react';
import {
  Button, DatePicker, Form, Input, InputNumber, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { Section, SubSection } from '../../../models/Template';
import { StoreState } from '../../../reducers/_RootReducers';
import { createClub } from '../../../services-api/clubService';
import { ActionTypes } from '../../../actions/types';
import styles from '../template-info/template-info.module.scss';
import { CreateTemplate } from '../../../models/create-models/CreateTemplate';

function TemplateChapters() {
  const [form] = Form.useForm();
  const createTemplate = useSelector<StoreState, CreateTemplate>((state) => state.createTemplate);
  const sectionCount = useSelector<StoreState, number>((state) => state.sectionCounter);
  const subsectionCounter = useSelector<StoreState, { [key: string]: number }>((state) => state.subsectionCounter);
  const history = useHistory();
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    dispatch(createClub(values, () => {
      history.goBack();
    }));
  };

  const onFinishFailed = (error: any) => {
  };

  const onAddSection = () => {
    const newItem: Section = {
      id: `section${sectionCount + 1}`,
      text: '',
      time: {
        minute: -1,
        second: -1,
      },
      page: -1,
      subChapters: [],
    };

    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections, newItem],
      },
    });

    dispatch({ type: ActionTypes.SET_SUBSECTION_COUNTER, value: { ...subsectionCounter, [`section${sectionCount + 1}`]: 0 } });
    dispatch({ type: ActionTypes.SET_SECTION_COUNTER, value: sectionCount + 1 });
  };

  const onAddSubSection = (section: Section) => {
    const newItem: SubSection = {
      id: `${section.id}-sub-section${subsectionCounter[section.id] + 1}`,
      text: '',
      time: {
        minute: -1,
        second: -1,
      },
      page: section.subChapters.length > 0 ? (section.subChapters[section.subChapters.length - 1].page + 1 || -1) : -1,
      parentId: section.id,
    };

    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === section.id) {
            return {
              ...item,
              time: {
                minute: -1,
                second: -1,
              },
              page: -1,
              subChapters: [...item.subChapters, newItem],
            };
          }
          return item;
        })],
      },
    });
    dispatch({ type: ActionTypes.SET_SUBSECTION_COUNTER, value: { ...subsectionCounter, [`${section.id}`]: subsectionCounter[section.id] + 1 } });
  };

  const onChapterInputChange = (data: any, section: Section) => {
    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === section.id) {
            return {
              ...item,
              text: data.target.value,
            };
          }
          return item;
        })],
      },
    });
  };

  const onSubChapterInputChange = (data: any, subSection: SubSection) => {
    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === subSection.parentId) {
            return {
              ...item,
              subChapters: [...item.subChapters.map((subItem) => {
                if (subItem.id === subSection.id) {
                  return { ...subItem, text: data.target.value };
                }
                return subItem;
              })],
            };
          }
          return item;
        })],
      },
    });
  };

  const onSubStartMinuteInputChange = (data: any, subSection: SubSection) => {
    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === subSection.parentId) {
            return {
              ...item,
              subChapters: [...item.subChapters.map((subItem) => {
                if (subItem.id === subSection.id) {
                  return { ...subItem, time: { ...subItem.time, minute: data } };
                }
                return subItem;
              })],
            };
          }
          return item;
        })],
      },
    });
  };

  const onSubStartSecondsInputChange = (data: any, subSection: SubSection) => {
    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === subSection.parentId) {
            return {
              ...item,
              subChapters: [...item.subChapters.map((subItem) => {
                if (subItem.id === subSection.id) {
                  console.log('data: ', data);
                  return {
                    ...subItem,
                    time: { ...subItem.time, second: data },
                  };
                }
                return subItem;
              })],
            };
          }
          return item;
        })],
      },
    });
  };

  const onStartMinutesInputChange = (data: any, section: Section) => {
    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === section.id) {
            return {
              ...item,
              time: { ...item.time, minute: data },
            };
          }
          return item;
        })],
      },
    });
  };

  const onStartSecondsInputChange = (data: any, section: Section) => {
    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === section.id) {
            return {
              ...item,
              time: { ...item.time, second: data },
            };
          }
          return item;
        })],
      },
    });
  };

  const onPageInputChange = (data: any, section: Section) => {
    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === section.id) {
            return {
              ...item,
              page: data,
            };
          }
          return item;
        })],
      },
    });
  };

  const onSubPageInputChange = (data: any, subSection: SubSection) => {
    dispatch({
      type: ActionTypes.SET_CREATE_TEMPLATE,
      value: {
        ...createTemplate,
        sections: [...createTemplate.sections.map((item) => {
          if (item.id === subSection.parentId) {
            return {
              ...item,
              subChapters: [...item.subChapters.map((subItem) => {
                if (subItem.id === subSection.id) {
                  return { ...subItem, page: data };
                }
                return subItem;
              })],
            };
          }
          return item;
        })],
      },
    });
  };

  const getSubSectionMax = (section: Section): number => {
    const subSectionLength = section.subChapters.length;
    if (subSectionLength > 0) {
      return section.subChapters[subSectionLength - 1].page;
    }
    return section.page;
  };

  const getSectionMin = (index: number) => (index - 1 >= 0 ? getSubSectionMax(createTemplate.sections[index - 1]) : 0);

  const getSubSectionMin = (subIndex: number, index: number, section: Section) => {
    if (subIndex === 0) {
      console.log('wtf - subIndex: ', subIndex);
      console.log('wtf - section: ', section);

      if (index > 0) {
        const previousSection = createTemplate.sections[index - 1];
        const prevSubSectionLength = previousSection.subChapters.length;
        if (prevSubSectionLength > 0) {
          return previousSection.subChapters[prevSubSectionLength - 1].page;
        }
      }

      console.log('wtf - getSubSectionMax: ', getSubSectionMax(section));
      const subSectionMax = getSubSectionMax(section);
      // if (subSectionMax !== -1) {
      //   return subSectionMax;
      // }
      return 0;
    }

    return section.subChapters[subIndex - 1].page;
  };

  return (
    <div className={styles.NewTemplateScreen}>
      <div className={styles.container}>
        <div className={styles.form}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {
              createTemplate.sections.map((section, index) => (
                <div key={`section-${index}`} className={styles.chapter}>
                  <div className={styles.info}>
                    <div className={styles.number}>{index + 1}</div>
                    {
                      createTemplate?.isAudio
                        ? (
                          <Input.Group className={styles.chapterInputGroup} compact>
                            <Input
                              size="large"
                              placeholder="Enter section title"
                              defaultValue={section.text}
                              allowClear
                              onChange={(event) => onChapterInputChange(event, section)}
                            />
                            <InputNumber
                              className={styles.startMinute}
                              min={0}
                              disabled={section.subChapters.length > 0}
                              defaultValue={section.time.minute === -1 ? undefined : section.time.minute}
                              placeholder="minute"
                              onChange={(event) => onStartMinutesInputChange(event, section)}
                            />
                            <InputNumber
                              className={styles.startSeconds}
                              min={0}
                              max={59}
                              disabled={section.subChapters.length > 0}
                              defaultValue={section.time.second === -1 ? undefined : section.time.second}
                              placeholder="seconds"
                              onChange={(event) => onStartSecondsInputChange(event, section)}
                            />
                          </Input.Group>
                          ) : (
                            <Input.Group className={styles.chapterInputGroup} compact>
                              <Input
                                size="large"
                                placeholder="Enter section title"
                                defaultValue={section.text}
                                allowClear
                                onChange={(event) => onChapterInputChange(event, section)}
                              />
                              <InputNumber
                                className={styles.startMinute}
                                min={section.page === -1 ? 0 : getSectionMin(index) + 1}
                                disabled={section.subChapters.length > 0}
                                defaultValue={section.page === -1 ? undefined : section.page}
                                placeholder={createTemplate?.medium?.typeValue === 0 ? "page": "min"}
                                onChange={(event) => onPageInputChange(event, section)}
                              />
                            </Input.Group>
                        )
                    }
                  </div>
                  <div className={styles.subChapterSection}>
                    {
                        section.subChapters.map((subChapter, subIndex) => (
                          <div key={`subsection-${subIndex}`} className={styles.subChapter}>
                            <div className={styles.subInfo}>
                              <div className={styles.subNumber}>
                                {index + 1}
                                .
                                {subIndex + 1}
                              </div>
                              {
                                createTemplate?.isAudio === false
                                  ? (
                                    <Input.Group className={styles.subChapterInputGroup} compact>
                                      <Input
                                        size="large"
                                        placeholder="Enter subsection title"
                                        defaultValue={subChapter.text}
                                        allowClear
                                        onChange={(event) => onSubChapterInputChange(event, subChapter)}
                                      />
                                      <InputNumber
                                        className={styles.startMinute}
                                        min={getSubSectionMin(subIndex, index, section) + 1}
                                        defaultValue={subChapter.page === -1 ? undefined : subChapter.page}
                                        placeholder={createTemplate?.medium?.typeValue === 0 ? "page": "min"}
                                        onChange={(event) => onSubPageInputChange(event, subChapter)}
                                      />
                                    </Input.Group>
                                    )
                                  : (
                                    <Input.Group className={styles.subChapterInputGroup} compact>
                                      <Input
                                        size="large"
                                        placeholder="Enter subsection title"
                                        defaultValue={subChapter.text}
                                        allowClear
                                        onChange={(event) => onSubChapterInputChange(event, subChapter)}
                                      />
                                      <InputNumber
                                        className={styles.startMinute}
                                        defaultValue={subChapter.time.minute === -1 ? undefined : subChapter.time.minute}
                                        onChange={(event) => onSubStartMinuteInputChange(event, subChapter)}
                                        placeholder="minutes"
                                      />
                                      <InputNumber
                                        className={styles.startMinute}
                                        defaultValue={subChapter.time.second === -1 ? undefined : subChapter.time.second}
                                        onChange={(event) => onSubStartSecondsInputChange(event, subChapter)}
                                        placeholder="seconds"
                                      />
                                    </Input.Group>
                                    )
                              }
                            </div>
                          </div>
                        ))
                      }
                  </div>
                  <div className={styles.actions}>
                    <Button
                      className={styles.addSubchapter}
                      size="large"
                      type="dashed"
                      onClick={() => onAddSubSection(section)}
                    >
                      Add Sub-Chapter
                    </Button>
                  </div>
                </div>
              ))
            }
            <Button
              className={styles.addChapter}
              size="large"
              type="dashed"
              onClick={onAddSection}
            >
              Add Chapter
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default TemplateChapters;
