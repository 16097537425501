import React, { useEffect } from 'react';
import {
  Button, Image, List, Progress,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './NotificationCard.module.scss';
import { Notification } from '../../../models/Notification';
import { convertRatingToColor, convertTimeToDaysAgo } from '../../../helpers/helpers';
import { ActionTypes } from '../../../actions/types';
import { follow as followUser } from '../../../services-api/userService';
import { User } from '../../../models/User';
import { Post } from '../../../models/Post';
import { StoreState } from '../../../reducers/_RootReducers';
import {fallBackUrl} from "../../../utils/imageData";
import {TopicReaction} from "../../../models/TopicReaction";

interface NotificationCardProps {
  notification: Notification;
  followingUserIds: any;
  followRequestUserIds: any;
  currentUserId: string;
}

function NotificationCard(props:NotificationCardProps) {
  const {
    notification, followingUserIds, followRequestUserIds,
  } = props;

  const {
    follow, typeValue, contentTimeStamp, reaction, _id, topicPostReaction,
  } = notification;
  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const onUserAction = (user: User) => {
    history.push(`/profile/${user.username}`);
  };

  const onFollowAction = (user: User) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: _id });
    dispatch(followUser(props.currentUserId, user._id, (followRequest: boolean) => {
      if (followRequest) {
        dispatch({ type: ActionTypes.SET_FOLLOW_REQUEST_USER_IDS, value: { ...followRequestUserIds, [user._id]: true } });
      } else {
        dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: { ...followingUserIds, [user._id]: true } });
      }
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
    }));
  };

  const onPostClick = (post: Post | undefined) => {
    if (post) {
      history.push(`/posts/${post._id}`);
    }
  };

  const onTopicPostLinkClick = (topicReaction: TopicReaction) => {
    history.push(`storyclubs/${topicReaction.topicPost.topic.club._id}/${topicReaction.topicPost.topic.type === 'STORY' ? 'stories' : 'discussions'}/${topicReaction.topicPost.topic._id}`);
  }

  const followCard = (user: User) => (
    <List.Item className={styles.NotificationCard}>
      <div role="button" tabIndex={0} onClick={() => onUserAction(user)}>
        <Image
            className={styles.image}
            src={user.image}
            alt={user.image}
            preview={false}
            fallback={fallBackUrl}
        />
      </div>
      <div className={styles.text}>
        <span role="button" tabIndex={0} className={styles.username} onClick={() => onUserAction(user)}>{user.username}</span>
        {' '}
        started following you.
        <div>{convertTimeToDaysAgo(contentTimeStamp)}</div>
      </div>
      {
        followingUserIds[`${user._id}`]
          ? <Button className={styles.follow} type="default">Following</Button>
          : followRequestUserIds[`${user._id}`] ? <Button className={styles.follow} type="default">Requested</Button>
            : (
              <Button
                className={styles.follow}
                type="primary"
                loading={currentId === _id && isLoading}
                onClick={() => onFollowAction(user)}
              >
                Follow
              </Button>
            )
      }
    </List.Item>
  );

  const reactionCard = (user: User) => (
    <List.Item className={styles.NotificationCard}>
      <div role="button" tabIndex={0} onClick={() => onUserAction(user)}>
        <Image
            className={styles.image}
            src={user.image}
            alt={user.image}
            preview={false}
            fallback={fallBackUrl}
        />
      </div>
      <div className={styles.text}>
        <span role="button" tabIndex={0} className={styles.username} onClick={() => onUserAction(user)}>{user.username}</span>
        {' '}
        {typeValue === 0 ? 'started following you.' : 'reacted to your post.'}
        <div>{convertTimeToDaysAgo(contentTimeStamp)}</div>
      </div>
      {
        reaction?.post?.typeValue === 0
          ? (
            <Image
                className={styles.postImage}
                src={reaction?.post?.image?.replace('http://', 'https://')}
                alt={reaction?.post?.image?.replace('http://', 'https://')}
                preview={false}
                fallback={fallBackUrl}
                onClick={() => onPostClick(reaction?.post)}
            />
            )
          : reaction?.post?.typeValue === 3
            ? (
              <div role="button" tabIndex={0} onClick={() => onPostClick(reaction.post)}>
                <Progress
                  className={styles.ratingProgressTablet}
                  width={40}
                  type="circle"
                  strokeColor={{
                    '0%': convertRatingToColor(reaction?.post?.rating),
                    '100%': convertRatingToColor(reaction?.post?.rating),
                  }}
                  percent={((reaction?.post?.rating / 10) * 100)}
                  format={() => (
                    <span>
                      <span className={styles.ratingValue}>{reaction?.post?.rating}</span>
                      <span className={styles.outOf}>/10</span>
                    </span>
                  )}
                />
              </div>
            ) : (
              <div />
            )
      }
    </List.Item>
  );

  const getReaction = (topicReaction: TopicReaction) => {
    if (topicReaction?.topicPost.topic.type === "STORY") {
      return topicReaction?.topicPost.topic.name
    }

    return `#${topicReaction?.topicPost.topic.name}`
  }

  const reactionClubPostCard = (topicReaction: TopicReaction) => {
    return <div className={styles.reactionClubPostCard}>
      <div role="button" tabIndex={0} onClick={() => onUserAction(topicReaction?.user)}>
        <Image
            className={styles.image}
            src={topicReaction?.user?.image}
            alt={topicReaction?.user?.image}
            preview={false}
            fallback={fallBackUrl}
        />
      </div>
      <div className={styles.text}>
        <span
            role="button"
            tabIndex={0}
            className={styles.username}
            onClick={() => onUserAction(topicReaction?.user)}
        >
          {topicReaction?.user?.username}
        </span>
        {' '}
        <span>
          reacted to your post in
        </span>
        {' '}
        <Button style={ { paddingLeft: '0px'} } type="link" size="small" onClick={() => onTopicPostLinkClick(topicReaction)}>
          {getReaction(topicReaction)} from {topicReaction?.topicPost.topic.club.name}
        </Button>
        <div>{convertTimeToDaysAgo(contentTimeStamp)}</div>
      </div>
    </div>
  }

  useEffect(() => {
    console.log("topicPostReaction: ",topicPostReaction);
  }, [follow?.follower, reaction?.user]);

  const getCorrectCard = () => {
    if (typeValue === 0 && follow?.follower) {
      return followCard(follow.follower);
    }

    if (typeValue === 2 && topicPostReaction) {
      return reactionClubPostCard(topicPostReaction);
    }

    if (reaction?.user) {
      return reactionCard(reaction.user);
    }

    return <div />;
  }

  return (
      getCorrectCard()
  );
}

export default NotificationCard;
