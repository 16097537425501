import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Badge } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './stands-content.module.scss';

const { Search } = Input;
function StandsContent() {
  const history = useHistory();
  const onSearch = () => {

  };

  const onStandClick = () => {
    history.push('/stands/123');
  };

  return (
    <div className={styles.StandsContent}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>
                Stands
              </div>
              <Button
                className={styles.createButton}
                icon={<PlusOutlined />}
                shape="circle"
                type="primary"
                size="large"
              />
            </div>
            <div className={styles.searchContainer}>
              <Search
                placeholder="search the stands"
                onSearch={onSearch}
                allowClear
                size="large"
              />
            </div>
          </div>
          <div />
        </div>
        <div className={styles.overviewCards}>
          {
              [1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                <div
                  className={styles.standOverviewCard}
                  onClick={onStandClick}
                >
                  <img
                    className={styles.image}
                    src={`https://picsum.photos/100/100?random=${index}`}
                  />
                  <div className={styles.textInfo}>
                    <div className={styles.titleContainer}>
                      <div className={styles.title}>
                        {`#stand-box-${index}`}
                      </div>
                      <Badge
                        className={styles.badge}
                        count={25}
                        overflowCount={100}
                      />
                    </div>
                    <div className={styles.subtitle}>
                      subtitle
                    </div>
                  </div>
                </div>
              ))
          }
        </div>
      </div>
    </div>
  );
}

export default StandsContent;
