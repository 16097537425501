import React, { useState } from 'react';
import {
  Badge, Button, Input, Switch,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './dms-content.module.scss';
import mkbhd from '../../../assets/images/mkbhd.jpg';

const { Search } = Input;
function DmsContent() {
  const [checked, setChecked] = useState(true);
  const onSearch = () => {

  };

  const onChange = (data: boolean) => {
    setChecked(data);
  };

  return (
    <div className={styles.DmsContent}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>
                DMs
              </div>
              <div className={styles.switchContainer}>
                <div className={styles.switchTitle}>
                  Turn
                  {' '}
                  {checked ? 'off' : 'on'}
                  {' '}
                  DMs
                </div>
                <Switch
                  defaultChecked={checked}
                  checkedChildren="Open"
                  unCheckedChildren="Closed"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className={styles.searchContainer}>
              <Search
                placeholder="search your dms"
                onSearch={onSearch}
                allowClear
                size="large"
              />
            </div>
          </div>
          <div />
        </div>
        <div className={styles.overviewCards}>
          {
                    [1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                      <div className={styles.standOverviewCard}>
                        <img
                          className={styles.image}
                          src={`https://picsum.photos/100/100?random=${index}`}
                        />
                        <div className={styles.textInfo}>
                          <div className={styles.titleContainer}>
                            <div className={styles.title}>
                              title
                            </div>
                            <Badge
                              className={styles.badge}
                              count={25}
                              overflowCount={100}
                            />
                          </div>
                          <div className={styles.subtitle}>
                            subtitle
                          </div>
                        </div>
                      </div>
                    ))
                }
        </div>
      </div>
    </div>
  );
}

export default DmsContent;
