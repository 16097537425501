import React, {useState} from 'react';
import styles from "../../screens/storynote-screen/storynote-screen.module.scss";
import {Button, DatePicker, Input, InputNumber, Modal, Select, Skeleton, Spin, Tooltip} from "antd";
import {FunnelPlotOutlined} from "@ant-design/icons";
import languages from "../../utils/languages.json";
import InfiniteScroll from "react-infinite-scroll-component";
import StoryTemplateCard from "../Cards/story-template-card/story-template-card";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers/_RootReducers";
import {ActionTypes} from "../../actions/types";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {StoryNoteTemplate} from "../../models/Template";
import {useHistory} from "react-router-dom";
import {StoryNote} from "../../models/StoryNote";
import TemplatePreviewModal from "../template-preview-modal/template-preview-modal";

type TemplateModalProps = {
    id: string
}

const { Search } = Input;
function TemplatesModal(props: TemplateModalProps) {

    const { id } = props;

    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [searchTemplatesQuery, setSearchTemplatesQuery] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [language, setLanguage] = useState<string | undefined>('');
    const [year, setYear] = useState<number>(0);
    const [limit] = useState(3);
    const [version, setVersion] = useState<number | undefined>(undefined);
    const currentStoryNote = useSelector<StoreState, StoryNote | null>((state) => state.currentStoryNote);
    const isTemplatesModalOpen = useSelector<StoreState, boolean>((state) => state.isTemplatesModalOpen);
    const [timeStamp] = useState(new Date());
    const dispatch = useDispatch();
    const history = useHistory();

    const queryKey = `story-templates-${id}-${language}-${year}-${version}-templates-screen-${searchTemplatesQuery}`;

    const {
        isLoading: isTemplatesLoading, data: templatesData, isFetching: isTemplatesFetching, fetchMore,
    } = useInfiniteQuery(queryKey,
        async (key, nextId = 0) => {
            const { data: templateData } = await axios.get(`/api/templates/medium/${currentStoryNote?.medium._id}?search=${searchTemplatesQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}&language=${language}&year=${year}&version=${version}`);
            if (templateData?.templates?.length < limit) {
                setIsAtTheEnd(true);
            }
            return templateData;
        },
        {
            enabled: !!currentStoryNote?.medium?._id,
            getFetchMore: (lastGroup) => lastGroup?.nextId,
        });

    const onCloseTemplatesModal = () => {
        dispatch({ type: ActionTypes.SET_TEMPLATES_MODAL, value: false });
    };

    const onTemplateSearch = (data: string) => {
        setSearchTemplatesQuery(data);
    };

    const onLangChange = (data: any) => {
        if (data) {
            setLanguage(data);
        } else {
            setLanguage('');
        }
    };

    const onYearChange = (data: any) => {
        if (data) {
            setYear(new Date(data._d).getFullYear());
        } else {
            setYear(0);
        }
    };

    const onVersionChange = (data: any) => {
        setVersion(data);
    };

    const onPreviewClick = (template: any) => {
        dispatch({ type: ActionTypes.SET_CURRENT_PREVIEW_TEMPLATE, value: template });
        dispatch({ type: ActionTypes.SET_TEMPLATE_PREVIEW_MODAL, value: true });
    };

    const onCreateTemplateClick = () => {
        const newCreateTemplate = {
            totalPages: 4,
            currentPage: 2,
            title: '',
            length: 1,
            type: 'template',
            version: 1,
            year: 2020,
            isAudio: false,
            privacy: 'public',
            language: 'English',
            sections: [],
            medium: currentStoryNote?.medium,
            redirectUrl: `/storynotes/${id}`,
            invalidateCache: queryKey
        };
        dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: newCreateTemplate });
        history.push('/create/template');
    };

    return (
        <Modal
            className={styles.TemplatesModal}
            title={null}
            visible={isTemplatesModalOpen}
            footer={null}
            closable={false}
            onCancel={onCloseTemplatesModal}
        >
            <TemplatePreviewModal id={id} />
            <div className={styles.content}>
                <div className={styles.title}>
                    Templates
                </div>
                <div className={styles.filterSearchContainer}>
                    <Search
                        placeholder="Search for templates..."
                        size="large"
                        allowClear
                        onSearch={onTemplateSearch}
                    />
                    <Tooltip title="filter">
                        <Button
                            className={styles.filtersButton}
                            icon={<FunnelPlotOutlined />}
                            size="large"
                            onClick={() => setShowFilters(!showFilters)}
                        />
                    </Tooltip>
                </div>
                {
                    showFilters
                    && (
                        <div>
                            <div className={styles.filtersTitle}>
                                Filters
                            </div>
                            <div className={styles.filtersContainer}>
                                <div className={styles.filterContainer}>
                                    <div className={styles.filterTitle}>
                                        Language
                                    </div>
                                    <Select
                                        showSearch
                                        style={{ width: '120px' }}
                                        size="large"
                                        onChange={onLangChange}
                                        allowClear
                                    >
                                        {
                                            languages.map((language) => <Select.Option value={language.name}>{language.name}</Select.Option>)
                                        }
                                    </Select>
                                </div>
                                <div className={styles.filterContainer}>
                                    <div className={styles.filterTitle}>
                                        Year
                                    </div>
                                    <DatePicker
                                        onChange={onYearChange}
                                        picker="year"
                                        size="large"
                                    />
                                </div>
                                <div className={styles.filterContainer}>
                                    <div className={styles.filterTitle}>
                                        Version
                                    </div>
                                    <InputNumber
                                        min={0}
                                        onChange={onVersionChange}
                                        size="large"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
                <div id="scrollableDivTemplates" className={styles.templatesBody}>
                    {
                        (isTemplatesLoading) && (
                            <div className={styles.loadingContainer}>
                                {
                                    [1, 1, 1, 1].map((item, index) => <div key={`${index}-loading-card`} className={styles.loadingCard}><Skeleton avatar active paragraph={{ rows: 1 }} /></div>)
                                }
                            </div>
                        )
                        || templatesData?.map((page) => page.templates).flat().length === 0
                        && <div className={styles.nothingContainer}>No templates found</div>
                        || (
                            <InfiniteScroll
                                dataLength={templatesData?.map((page) => page.templates).flat().length || 0}
                                next={() => fetchMore()}
                                hasMore={(templatesData?.map((page) => page.templates).flat().length || 0) > 0 && !isAtTheEnd}
                                loader={(
                                    <div className={styles.spinningContainer}>
                                        <Spin />
                                    </div>
                                )}
                                endMessage={(
                                    <p style={{ textAlign: 'center' }} />
                                )}
                                scrollableTarget="scrollableDivTemplates"
                            >
                                <div className={styles.container}>
                                    {
                                        templatesData?.map((page) => page.templates).flat().map((item, index) => (
                                            <div key={`${index}-thing`}>
                                                <StoryTemplateCard
                                                    template={item}
                                                    onPreview={onPreviewClick}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </InfiniteScroll>
                        )
                    }
                </div>
                <Button
                    className={styles.createButton}
                    type="primary"
                    size="large"
                    onClick={onCreateTemplateClick}
                >
                    Create Template
                </Button>
            </div>
        </Modal>
    );
}

export default TemplatesModal;