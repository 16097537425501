import React, {useEffect} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button, Typography } from 'antd';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import mkbhd from '../../../assets/images/mkbhd.jpg';
import styles from './club-card.module.scss';
import whitebg from '../../../assets/images/whitebg.svg';
import { Club } from '../../../models/Club';
import { ClubUser } from '../../../models/ClubUser';

type ClubCardProps = {
  onClubClick: (item: any) => void;
  onFavoriteAction: (clubUser1: ClubUser) => void;
  clubUser: ClubUser;
  isEditing?: boolean;
};

const { Paragraph } = Typography;
function ClubCard(props: ClubCardProps) {
  const {
    onClubClick, onFavoriteAction, clubUser, isEditing,
  } = props;
  const { club } = clubUser ? clubUser: { club: undefined };

  const onFavoriteClick = (event: any) => {
    event.stopPropagation();
    onFavoriteAction(clubUser);
  };

  return (
    <div className={styles.ClubCard} onClick={() => onClubClick(club)}>
      <div className={styles.backgroundContainer}>
        <div className={styles.clubInfoContainer}>
          <div className={styles.body}>
            <div className={styles.imageAndInfoHeader}>
              <img
                className={styles.image}
                src={club?.image}
              />
              <div className={styles.info}>
                {
                  clubUser?.favorite
                    ? (
                      <Button
                        type="link"
                        shape="circle"
                        icon={<StarFilled className={styles.filledStar} />}
                        onClick={onFavoriteClick}
                      />
                    )
                    : (
                      <Button
                        type="link"
                        shape="circle"
                        icon={<StarOutlined className={styles.unfilledStar} />}
                        onClick={onFavoriteClick}
                      />
                    )
                }
              </div>
            </div>
            <Paragraph className={styles.title} ellipsis={{ rows: 2, expandable: false, symbol: '...' }}>
              {club?.name}
            </Paragraph>
            <div className={styles.description}>
              <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: '...' }}>
                {club?.subtitle || '-'}
              </Paragraph>
            </div>
          </div>
          <div className={styles.footer}>
            <div>
              {club?.usersCount}
              {' '}
              members
            </div>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClubCard;
