import React, { useState } from 'react';
import {
  Button, Form, Image, Input, Modal, Select, Spin,
} from 'antd';
import {useInfiniteQuery, useQuery, useQueryCache} from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PlusOutlined, ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from '../../actions/types';
import styles from './club-members-screen.module.scss';
import MemberCard from '../../components/Cards/member-card/member-card';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { fallBackUrl } from '../../utils/imageData';
import {
  deleteClub, inviteUsers, leaveClub, removeFromClub, makeAdmin,
} from '../../services-api/clubService';

const { confirm, error } = Modal;
const { Option } = Select;
function ClubMembersScreen() {
  const { id } = useParams<{ id: string }>();
  const currentUser = useSelector<StoreState, User | null>((state) => state.currentUser);
  const isReqLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const [timeStamp] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState('');
  const [limit] = useState(12);
  const [value, setValue] = useState([]);
  const [isAtTheEndMembers, setIsAtTheEndMembers] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const {
    isLoading, error: clubError, data: currentClubUser, isFetching,
  } = useQuery(`club-screen-${id}`, () => axios.get(`/api/clubs/${id}`)
    .then((response) => response.data));

  const {
    data,
  } = useInfiniteQuery(
    `new-club-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/users/friends?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const {
    data: membersData,
    isFetching: membersIsFetching,
    fetchMore: membersFetchMore,
  } = useInfiniteQuery(
    `club-${id}-screen-members`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/clubs/members/${id}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);

      console.log('data: ', data);
      if (data?.members?.length < limit) {
        setIsAtTheEndMembers(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onAddMemberClick = () => {
    const idToUsername: { [key: string]: string;} = {};
    const users = value.map((item: any) => {
      const user: User = JSON.parse(item.value as string);
      idToUsername[user._id] = user.username;
      return user._id;
    });

    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(inviteUsers(currentClubUser!.club._id, users, (success:boolean, dupIds: string[]) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      if (success) {
        queryCache.invalidateQueries(`club-${id}-screen-members`);
        queryCache.invalidateQueries(`club-screen-${id}`);
        setValue([]);
      } else if (dupIds && dupIds.length > 0) {
        error({
          title: 'Error',
          content: `${dupIds.map((dupId) => idToUsername[dupId]).join(', ')} already in the club`,
          onOk() {

          },
        });
      } else {
        error({
          title: 'Error',
          content: 'An error has occurred. Please try again later',
          onOk() {

          },
        });
      }
    }));
  };

  const onMakeAdminClick = (user: User) => {
    confirm({
      title: 'Make Admin?',
      content: `Are you sure you want to make ${user.displayName} an admin?`,
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(makeAdmin(currentClubUser!.club._id, user._id, (success:boolean) => {
            if (success) {
              queryCache.invalidateQueries(`club-${id}-screen-members`);
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onRemoveClick = (user: User) => {
    confirm({
      title: 'Remove from club?',
      content: `Are you sure you want to remove ${user.displayName} from this club?`,
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(removeFromClub(currentClubUser!.club._id, user._id, (success:boolean) => {
            if (success) {
              queryCache.invalidateQueries(`club-${id}-screen-members`);
              queryCache.invalidateQueries(`club-screen-${id}`);
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onBackAction = () => {
    history.goBack();
  };

  const onSearch = (data: any) => {
    setSearchQuery(data);
  };

  const onDeleteClick = (currVal: string) => {
    setValue(value.filter((x: any) => x.value !== currVal));
  };

  const handleChange = (value: any) => {
    setValue(value);
  };

  const onDeleteClub = () => {
    confirm({
      title: 'Delete Club?',
      content: 'Are you sure you want to delete this club?',
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(deleteClub(currentClubUser!.club._id, (success:boolean) => {
            if (success) {
              history.replace('/storyclubs');
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onLeaveClub = () => {
    confirm({
      title: 'Leave Club?',
      content: 'Are you sure you want to leave this club?',
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(leaveClub(currentClubUser!.club._id, (success:boolean, message: string) => {
            if (success) {
              history.replace('/storyclubs');
              resolve('');
            } else {
              error({
                title: 'Error',
                content: message || 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <div className={styles.ClubMembersScreen}>
      <div className={styles.container}>
        <div id="scrollableDivMembers" className={styles.membersContainer}>
          <div className={styles.membersTitle}>
            <Button
              className={styles.backButton}
              type="link"
              size="large"
              icon={<ArrowLeftOutlined />}
              onClick={onBackAction}
            />
            <div className={styles.title}>
              Members
              {' '}
              <span style={{ fontWeight: 400 }}>
                (
                {currentClubUser?.club?.usersCount}
                )
              </span>
            </div>
          </div>
          <div className={styles.addContainer}>
            <Select
              mode="multiple"
              size="large"
              labelInValue
              value={value}
              placeholder="Select users"
              notFoundContent={isFetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={onSearch}
              tagRender={(item) => {
                const user: User = JSON.parse(item.value as string);
                return (
                  <div className={styles.friendLabel}>
                    <Image
                      className={styles.image}
                      src={user.image}
                      fallback={fallBackUrl}
                    />
                    <div className={styles.name}>{user.username}</div>
                    <CloseOutlined className={styles.closeIcon} onClick={() => onDeleteClick(item.value as string)} />
                  </div>
                );
              }}
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              {data?.map((page) => page.users).flat().map((user: User) => (
                <Option key={user.image} value={JSON.stringify({ image: user?.image, username: user?.username, _id: user?._id })}>
                  <div className={styles.friendLabelOption}>
                    <Image
                      className={styles.image}
                      src={user.image}
                      alt={user.image}
                      fallback={fallBackUrl}
                    />
                    <div className={styles.name}>{user.username}</div>
                  </div>
                </Option>
              ))}
            </Select>
            <Button
              className={styles.inviteButton}
              type="primary"
              size="large"
              disabled={value.length === 0}
              onClick={onAddMemberClick}
              loading={isReqLoading}
            >
              Add
            </Button>
          </div>
          <div className={styles.membersList}>
            <InfiniteScroll
              dataLength={membersData?.length || 0}
              next={() => membersFetchMore()}
              hasMore={(membersData?.length || 0) > 0 && !isAtTheEndMembers}
              loader={(
                <div className={styles.spinningContainer}>
                  <Spin />
                </div>
                )}
              endMessage={(<p style={{ textAlign: 'center' }} />)}
              scrollableTarget="scrollableDivMembers"
            >
              {
                membersData?.map((page) => page.members).flat().map((user, index) => (
                  <MemberCard
                    key={`${index}-key`}
                    user={user}
                    role={currentClubUser?.role || ''}
                    currentUserId={currentUser?._id || ''}
                    onMakeAdminClick={onMakeAdminClick}
                    onRemoveClick={onRemoveClick}
                  />
                ))
              }
            </InfiniteScroll>
          </div>
        </div>
        <div className={styles.divider} />
        <Button
          className={styles.dangerButton}
          type="link"
          size="large"
          onClick={onLeaveClub}
          danger
        >
          Leave Club
        </Button>
        {
          currentClubUser?.role === 'ADMIN'
          && (
          <Button
            className={styles.dangerButton}
            type="link"
            size="large"
            onClick={onDeleteClub}
            danger
          >
            Delete Club
          </Button>
          )
        }
      </div>
    </div>
  );
}

export default ClubMembersScreen;
