import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Input, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useInfiniteQuery, useQuery } from 'react-query';
import axios from 'axios';
import styles from './storynotes-notes-screen.module.scss';
import FriendUserCardSkeleton from '../NewChatScreen/FriendUserCardSkeleton/FriendUserCardSkeleton';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { StoryNote } from '../../models/StoryNote';
import { ActionTypes } from '../../actions/types';
import NotesCard from './notes-card/notes-card';
import { Note } from '../../models/Note';
import {addSeconds} from "../../services-util/club-story-service-util";

const { Search } = Input;
function StorynotesNotesScreen() {
  const { id, sectionId } = useParams<{ id: string, sectionId: string }>();
  const currentStoryNote = useSelector<StoreState, StoryNote | null>((state) => state.currentStoryNote);
  const lastLocationState = useSelector<StoreState, string>((state) => state.lastLocationState);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const resetTimestamp = useSelector<StoreState, boolean>(state => state.resetTimestamp);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [limit] = useState(12);
  const [timeStamp, setTimestamp] = useState(new Date());
  const history = useHistory();
  const dispatch = useDispatch();

  useQuery(`story-templates-${id}-screen-${searchQuery}`, () => axios.get(`/api/notes/story/${id}?search=${searchQuery}&limit=${limit}&timestamp=${timeStamp}`)
    .then((response) => {
      dispatch({ type: ActionTypes.SET_CURRENT_STORY_NOTE, value: response.data });
      return response.data as StoryNote;
    }), { enabled: currentStoryNote === null });

  useEffect(() => {
    return () => {
      dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'notes-screen' });
    }
  }, [currentStoryNote]);

  const getNewTimestamp = () => {
    dispatch({ type: ActionTypes.SET_RESET_TIME, value: false });
    const newDate = addSeconds(new Date(), 30);
    setTimestamp((timeStamp) => {
      return newDate;
    })
    return newDate;
  }

  const {
    data, isFetching, fetchMore,
  } = useInfiniteQuery(`story-notes-${id}-${sectionId}-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data: notesData } = await axios.get(`/api/notes/story/${id}/${sectionId}/?search=${searchQuery}&limit=${limit}&page=${nextId}${resetTimestamp ? `&timestamp=${getNewTimestamp()}` : `&timestamp=${timeStamp}`}`);
      if (notesData?.notes?.length < limit) {
        setIsAtTheEnd(true);
      }
      return notesData;
    },
    {
      getFetchMore: (lastGroup) => lastGroup?.nextId,
    });

  const onSearch = (data: string) => {
    setSearchQuery(data);
  };

  const onNotesCardClick = (e: any, note: Note) => {
    history.push(`/notes/${note._id}`);
  };

  const onAddStoryTemplateClick = () => {
    history.push(`/storynotes/${id}/${sectionId}/create`);
  };

  const onBackClick = () => {
    history.goBack();
  };

  return ((
    <div className={styles.StorynotesNotesScreen}>
      <div className={styles.headerContainer}>
        <div className={styles.headerWhiteboard}>
          <div className={styles.messageHeader}>
            <div className={styles.topHeader}>
              <Button
                className={styles.goBack}
                type="link"
                size="large"
                icon={<ArrowLeftOutlined />}
                shape="circle"
                onClick={onBackClick}
              />
              <div className={styles.titleHeader}>
                {
                  sectionId === 'all' && (
                  <span className={styles.sectionId}>
                    all
                  </span>
                  )
                }
                <span className={styles.sectionId}>
                  {sectionId.includes('sub')
                    ? currentStoryNote?.template?.sections.map((section) => section.subChapters).flat().find((val) => val?.id === sectionId)?.text
                    : currentStoryNote?.template?.sections.find((val) => val.id === sectionId)?.text}
                </span>
              </div>
              <div className={styles.create}>
                <Button
                  size="large"
                  type="primary"
                  onClick={onAddStoryTemplateClick}
                  icon={<PlusOutlined />}
                />
              </div>
            </div>
            <div className={styles.searchContainer}>
              <Search
                className={styles.searchInput}
                placeholder="Search all your notes"
                allowClear
                size="large"
                onSearch={onSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {
          (sectionId !== 'all' && (data && data?.map((page) => page.notes).flat().length === 0)) && (
          <Button
            className={styles.addNewNote}
            type="dashed"
            size="large"
            onClick={onAddStoryTemplateClick}
          >
            Add new note
          </Button>
          )
        }
        {
          isFetching && !data
            ? (
              [1, 1, 1, 1, 1, 1].map((item, index) => <FriendUserCardSkeleton key={`${index}`} />)
            ) : (
              <InfiniteScroll
                dataLength={data?.map((page) => page.notes).flat().length || 0}
                next={() => fetchMore()}
                hasMore={(data?.map((page) => page.notes).flat().length || 0) > 0 && !isAtTheEnd}
                loader={(
                  <div className={styles.spinningContainer}>
                    <Spin />
                  </div>
                      )}
                endMessage={(<p style={{ textAlign: 'center' }} />)}
              >
                {
                      data?.map((page) => page.notes).flat()
                          .map((item) => (
                            <NotesCard
                              key={item._id}
                              note={item}
                              onNotesCardClick={onNotesCardClick}
                            />
                          ))
                }
              </InfiniteScroll>
            )
        }
      </div>
    </div>
  )
  );
}

export default StorynotesNotesScreen;
