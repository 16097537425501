import React from 'react';
import styles from './card-card.module.scss';
import baylor from '../../../../../assets/images/baylor.png';
import Footer from '../../stadium-stats/footer/footer';
import CardHeader from '../card-header/card-header';
import elon from '../../../../../assets/images/elon.jpeg';
import GoalUser from '../goal-user/goal-user';

type CardCardProps = {
  type: string;
};

function CardCard(props: CardCardProps) {
  const { type } = props;
  return (
    <div className={styles.CardCard}>
      <CardHeader type={type} title={`${type} Card`} minute="11'" />
      <div className={styles.body}>
        <GoalUser
          image={elon}
          teamImage={baylor}
          name="E.Musk #24"
        />
      </div>
      <Footer />
    </div>
  );
}

export default CardCard;
