import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton, Spin } from 'antd';
import {useInfiniteQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import styles from './podcasts-grid.module.scss';
import EditGrid from '../../../components/EditGrid/EditGrid';
import { Medium } from '../../../models/Medium';
import Empty from '../../../components/Empty/Empty';
import PodcastsGridCard2 from '../../profile-screen/profile-podcasts-grid/podcasts-grid-card2/podcasts-grid-card2';

type PodcastsGridProps = {
  isEditing: boolean;
  onEditingClick: () => void;
  type: string;
  mediumCount: number;
  onRemoveMediumClick: (mediumShelf: any, callback: (id: string) => void) => void;
  onMediumClick: (medium: Medium) => void;
  title: string;
  subTitle: string;
  icon: string;
  url: string;
  buttonTitle: string;
  screenItem: string;
};

function PodcastsGrid(props: PodcastsGridProps) {
  const {
    isEditing, onEditingClick, type, onRemoveMediumClick, onMediumClick,
    title, subTitle, icon, url, buttonTitle, screenItem, mediumCount,
  } = props;
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(15);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [sortBy, setSortBy] = useState(search?.replace('?', '') || 'createdAt');
  const [width, setWidth] = useState(window.innerWidth);
  const queryCache = useQueryCache();

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `wishlist-${type}-tab-${sortBy}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/wishlist?currentTab=${type}&sortBy=${sortBy}&limit=${limit}&page=${nextId}&timeStamp=${timeStamp}`);
      if (data?.mediums?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);
  }, [data]);

  const tempOnRemoveMediumClick = (mediumShelf: any) => {
    onRemoveMediumClick(mediumShelf, (mediumId) => {
      queryCache.setQueryData(`wishlist-${type}-tab-${sortBy}`, (oldData) =>
      // @ts-ignore
        oldData.map((x) => ({ ...x, mediums: [...x.mediums.filter((x: any) => x._id !== mediumId)] })));
    });
  };

  const triggerSortMediums = (updatedSortBy: string) => {
    setSortBy(updatedSortBy);
    setIsAtTheEnd(false);
    history.replace(`${pathname}?${updatedSortBy}`);
  };

  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className={styles.PodcastsGrid}>
      <EditGrid
          isEditing={isEditing}
          mediumType={type}
          mediumCount={mediumCount}
          isCurrentUser
          triggerSort={triggerSortMediums} onEditingClick={onEditingClick} />
      <InfiniteScroll
        className={styles.gridList}
        next={() => fetchMore()}
        hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
        loader={(
          <div className={styles.spinningContainer}>
            <Spin />
          </div>
                )}
        dataLength={data?.length || 0}
      >
        {
                    isFetching && !data && [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item: any, index: number) => (
                      <div key={`skel-${index}`} className={styles.skeletonItem}>
                        <Skeleton
                          className={styles.imageContainer}
                          active
                          title={{ style: { height: width > 800 ? '264px' : width * 0.33, width: 'auto !important' } }}
                          paragraph={{ rows: 0 }}
                        />
                      </div>
                    ))
                }
        {
                    data?.map((page) => page.mediums).flat().length === 0
                    && (
                    <div className={styles.emptyView}>
                      <Empty
                        title={title}
                        subTitle={subTitle}
                        icon={icon}
                        url={url}
                        buttonTitle={buttonTitle}
                        screen={screenItem}
                      />
                    </div>
                    )
                }
        {
                    data?.map((page) => page.mediums).flat().length !== 0 && data?.map((page) => page.mediums).flat().map((item: any) => (
                      <PodcastsGridCard2
                        key={item?._id}
                        item={item}
                        isEditing={isEditing}
                        onMediumClick={onMediumClick}
                        tempOnRemoveMediumClick={tempOnRemoveMediumClick}
                      />
                    ))
                }
      </InfiniteScroll>
    </div>
  );
}

export default PodcastsGrid;
