import React from 'react';

interface TabTitleProps {
  text: string;
  count?: number;
}

function TabTitle(props: TabTitleProps) {
  const { text, count } = props;

  return (
    <div style={{ paddingLeft: '8px', paddingRight: '8px' }}>
      {text}
      {
      count && count !== 0 && count !== undefined
        ? (
          <span>
            {' '}
            (
            {count && count !== 0 && count !== undefined && count}
            )
          </span>
        ) : <span />
    }
    </div>
  );
}

export default TabTitle;
