import React, { useEffect, useState } from 'react';
import { Button, Popover, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './topic-post-card.module.scss';
import likeIcon from '../../../assets/icons/icons8-facebook-like-50.png';
import commentIcon from '../../../assets/icons/icons8-topic-50.png';
import shareIcon from '../../../assets/icons/icons8-forward-arrow-50.png';
import newLike from '../../../assets/icons/new-like.png';
import newHeart from '../../../assets/icons/new-heart.png';
import newLikeCircle from '../../../assets/icons/new-like-circle.png';
import newHeartCircle from '../../../assets/icons/new-heart-circle.png';
import newLolCircle from '../../../assets/icons/new-lol-circle.png';
import { TopicPost } from '../../../models/TopicPost';
import { convertTimeToDaysAgo } from '../../../helpers/helpers';
import { ActionTypes } from '../../../actions/types';

type TopicPostCardProps = {
  topicPost: TopicPost;
  onPostClick: (id: string) => void;
  onReactionAction: (topicPost: TopicPost, type: string) => void;
  isReactionsOpen: boolean;
  onReactionClick: (topicPost: TopicPost) => void;
  currentId: string;
  reactionIds: { [key: string]: any }
};

const { Paragraph } = Typography;
function TopicPostCard(props: TopicPostCardProps) {
  const {
    topicPost, onPostClick, onReactionAction, isReactionsOpen,
    onReactionClick, currentId, reactionIds,
  } = props;
  const dispatch = useDispatch();
  // const [areReactionsVisible, setAreReactionsVisible] = useState(false);

  // useEffect(() => {
  //   console.log('reactionIds: ', reactionIds);
  // }, [reactionIds]);
  const onLikeClick = () => {
    console.log('onLikeAction');
    onReactionAction(topicPost, 'like');
  };

  const onLoveClick = () => {
    console.log('onHeartAction');
    onReactionAction(topicPost, 'love');
  };

  const onLolClick = () => {
    console.log('onLolAction');
    onReactionAction(topicPost, 'lol');
  };

  const content = (
    <div className={styles.reactionsIcons}>
      <img
        className={styles.reactionIcon}
        src={newLike}
        alt=""
        onClick={onLikeClick}
      />
      <img
        className={styles.reactionIcon}
        src={newHeart}
        alt=""
        onClick={onLoveClick}
      />
      <img
        className={styles.reactionIcon}
        src={newLolCircle}
        alt=""
        onClick={onLolClick}
      />
    </div>
  );

  const getCurrentReaction = () => {
    if (reactionIds[`${topicPost._id}`] && reactionIds[`${topicPost._id}`].typeText === 'like') {
      return (
        <img
          className={styles.reactionImage}
          src={newLike}
          onClick={() => onReactionClick(topicPost)}
        />
      );
    }

    if (reactionIds[`${topicPost._id}`] && reactionIds[`${topicPost._id}`].typeText === 'love') {
      return (
        <img
          className={styles.reactionImage}
          src={newHeart}
          onClick={() => onReactionClick(topicPost)}
        />
      );
    }

    if (reactionIds[`${topicPost._id}`] && reactionIds[`${topicPost._id}`].typeText === 'lol') {
      return (
        <img
          className={styles.reactionImage}
          src={newLolCircle}
          onClick={() => onReactionClick(topicPost)}
        />
      );
    }

    return (
      <img
        className={styles.defaultReactionImage}
        src={likeIcon}
        onClick={() => onReactionClick(topicPost)}
      />
    );
  };

  const onVisibleChange = (visible: boolean) => {
    // setAreReactionsVisible(visible);
    dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: visible });
  };

  const displayCount = () => {
    let count = 0;

    if (topicPost?.lovesCount) {
      count += topicPost.lovesCount;
    }

    if (topicPost?.likesCount) {
      count += topicPost.likesCount;
    }

    if (topicPost?.laughsCount) {
      count += topicPost.laughsCount;
    }

    return count > 0 ? count : undefined;
  };

  return (
    <div
      className={styles.TopicPostCard}
      onClick={() => onPostClick(topicPost._id)}
    >
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={topicPost?.user?.image}
        />
        <Popover
          placement="topLeft"
          visible={isReactionsOpen && currentId === topicPost._id}
          onVisibleChange={onVisibleChange}
          content={content}
          trigger="click"
          arrowContent={null}
        >
          {
            getCurrentReaction()
          }
        </Popover>
      </div>
      <div className={styles.textInfo}>
        <div className={styles.titleContainer}>
          <Paragraph
            ellipsis={{ rows: 1, expandable: false, suffix: `  @${topicPost?.user?.username} · ${convertTimeToDaysAgo(topicPost.createdAt)}` }}
          >
            <span className={styles.title}>{topicPost?.user?.displayName}</span>
          </Paragraph>
        </div>
        <div className={styles.postContainer}>
          <div className={styles.postText}>
            {topicPost.text}
          </div>
          {
              topicPost.image
                        && (
                        <img
                          className={styles.postImage}
                          src={topicPost.image}
                          alt=""
                        />
                        )
                    }
          <div className={styles.footer}>
            <div className={styles.leftStats}>
              {
                topicPost?.likesCount !== undefined && topicPost?.likesCount > 0
                && (
                <img
                  className={styles.reactionIcon1}
                  src={newLikeCircle}
                  alt=""
                />
                )
              }
              {
                topicPost?.lovesCount !== undefined && topicPost?.lovesCount > 0
                && (
                <img
                  className={styles.reactionIcon2}
                  src={newHeartCircle}
                  alt=""
                />
                )
              }
              {
                topicPost?.laughsCount !== undefined && topicPost?.laughsCount > 0
                && (
                <img
                  className={styles.reactionIcon3}
                  src={newLolCircle}
                  alt=""
                />
                )
              }
              <div className={styles.reactionNumber}>
                {displayCount()}
              </div>
            </div>
            <div className={styles.rightStats}>
              <img
                className={styles.commentIcon}
                src={commentIcon}
                alt=""
              />
              <div className={styles.commentNumber}>
                1.5K
              </div>
              <img
                className={styles.shareIcon}
                src={shareIcon}
                alt=""
              />
              <div className={styles.shareNumber}>
                1.5K
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopicPostCard;
