import React from 'react';
import { Progress } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './RatingItemHome.module.scss';
import { convertRatingToColor } from '../../helpers/helpers';

interface RatingItemProps {
  item: any;
}

function RatingItemHome(props: RatingItemProps) {
  const { item } = props;

  return (
    <div className={styles.RatingItemHome}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <Progress
            className={styles.ratingProgress}
            width={100}
            type="circle"
            strokeColor={{
              '0%': convertRatingToColor(item.rating),
              '100%': convertRatingToColor(item.rating),
            }}
            percent={((item.rating / 10) * 100)}
            format={(percent) => (
              <span>
                <span className={styles.ratingValue}>{item.rating}</span>
                <span className={styles.outOf}>/10</span>
              </span>
            )}
          />
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.imageContainer}>
            <LazyLoadImage
              effect="opacity"
              className={styles.image}
              src={item.medium.imageUrl.replace('http://', 'https://')}
              alt={item.medium.imageUrl.replace('http://', 'https://')}
              placeholderSrc={require('../../assets/images/placeholder.webp')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingItemHome;
