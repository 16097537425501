import React, { useEffect } from 'react';
import { Affix, Modal } from 'antd';
import styles from './LandingScreen.module.scss';
import NavBar from './NavBar/NavBar';
import Information from './Information/Information';
import Footer from './Footer/Footer';
import HowItWorks from './HowItWorks/HowItWorks';
import NewMain from './new-main/new-main';

interface LandingScreenProps {
  userExists?: boolean;
  notFound?: boolean;
  type?: string;
}

function LandingScreen(props: LandingScreenProps) {
  const { type } = props;
  const error = (title: string, content: string) => {
    Modal.error({
      title,
      content,
    });
  };

  useEffect(() => {
    if (props.userExists) {
      error('User already exists', 'Please login in');
    }

    if (props.notFound) {
      error('User not found', 'Please sign up');
    }
  }, []);

  return (
    <div className={styles.LandingScreen}>
      <Affix offsetTop={0}>
        <NavBar />
      </Affix>
      <div className={styles.body}>
        <NewMain />
        <Information />
        <HowItWorks />
        <Footer />
      </div>
    </div>
  );
}

export default LandingScreen;
