import React from 'react';
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './InterestCard.module.scss';
import {LazyLoadImage} from "react-lazy-load-image-component";

interface InterestCard2Props {
  item: any;
  onMovieGenreSelect: (uniqueName: string) => void;
}

export default function InterestCard(props: InterestCard2Props) {
  const { item } = props;
  const {
    displayName, imageUrl, uniqueName, selected,
  } = item;

  const onSelectClick = () => {
    props.onMovieGenreSelect(uniqueName);
  };

  return (
    <div className={styles.InterestCard}>
      <div className={styles.imageContainer}>
        <LazyLoadImage
            effect="blur"
            className={styles.backgroundImage}
            src={imageUrl}
            alt={imageUrl}
        />
        <div className={styles.darkBackground} />
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {displayName}
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          type="primary"
          icon={selected === true && <CheckCircleOutlined />}
          className={`${styles.selectButton} ${selected === true ? styles.selected : ''}`}
          onClick={onSelectClick}
        >
          {selected === true ? 'Selected' : 'Select'}
        </Button>
      </div>
    </div>
  );
}
