import React, { useState } from 'react';
import { Button, Drawer, Radio } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './EditGrid.module.scss';

interface EditGridProps {
  isEditing: boolean;
  onEditingClick: () => void;
  triggerSort?: (updatedSortBy: string) => void;
  isCurrentUser?: boolean;
  username?: string;
  mediumType?: string;
  mediumCount?: number;
  topRanked?: boolean;
}

function EditGrid(props: EditGridProps) {
  const {
    isEditing, onEditingClick, username, mediumType, mediumCount, isCurrentUser, triggerSort, topRanked,
  } = props;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { search } = useLocation();
  const [sortByValue, setSortByValue] = useState(search?.replace('?', '') || 'createdAt');
  const [editValue, setEditValue] = useState('');

  const onViewToClick = () => {
    if (username && mediumType) {
      history.push(`/top/${mediumType}/${username}`);
    }
  };

  const onEditingAction = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setEditValue('');
  };

  const onSortByChange = (event: any) => {
    setSortByValue(event.target.value);
    if (triggerSort) {
      triggerSort(event.target.value);
    }
    onClose();
  };

  const onEditChange = (event: any) => {
    onEditingClick();
    setEditValue(event.target.value);
    onClose();
  };

  return (
    <div className={styles.EditGrid}>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onClose}
        visible={visible}
        key="bottom"
        height="300px"
      >
        <div className={styles.filterDrawer}>
          {
                isCurrentUser
                && (
                <div className={styles.filterSection}>
                  <div className={styles.filterTitle}>
                    Edit
                  </div>
                  <div className={styles.filterValues}>
                    {/* <Button type="primary">Edit List</Button> */}
                    <Radio.Group onChange={onEditChange} value={editValue}>
                      <Radio className={styles.radioItem} value="edit">
                        Edit List
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                )
            }
          {/* <div className={styles.filterSection}> */}
          {/*  <div className={styles.filterTitle}> */}
          {/*    Filter By */}
          {/*  </div> */}
          {/*  <div className={styles.filterValues}> */}
          {/*    Filter value */}
          {/*  </div> */}
          {/* </div> */}
          <div className={styles.filterSection}>
            <div className={styles.filterTitle}>
              Sort By
            </div>
            <div className={styles.filterValues}>
              <Radio.Group onChange={onSortByChange} defaultValue={sortByValue} value={sortByValue}>
                <Radio className={styles.radioItem} value="createdAt">
                  Recently Added
                </Radio>
                {
                  topRanked
                  && (
                  <Radio className={styles.radioItem} value="ranking">
                    Top Ranked
                  </Radio>
                  )
                }
                {
                  mediumType !== 'posts' && (
                  <Radio className={styles.radioItem} value="releaseDate">
                    Release Date
                  </Radio>
                  )
                }
              </Radio.Group>
            </div>
          </div>
        </div>
      </Drawer>
      <div className={styles.leftContent}>
        <div>
          <span className={styles.mediumType}>{mediumType}</span>{" "} <span>{mediumCount && `(${mediumCount})`}</span>
        </div>
        {/*{*/}
        {/*  false*/}
        {/*  && <Button type="default" className={styles.viewTop} onClick={onViewToClick}>View Top Ranked</Button>*/}
        {/*}*/}
      </div>
      <div className={styles.rightContent}>
        {
          isEditing ? <Button size="large" className={styles.editButton} type="primary" icon={<CloseOutlined type="close" />} onClick={onEditingClick} />
            : <Button className={styles.editButton} type="default" onClick={onEditingAction}>Filters</Button>
        }
      </div>
    </div>
  );
}

export default EditGrid;
