import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import TopCard from '../TopCard/TopCard';
import { StoreState } from '../../../reducers/_RootReducers';
import styles from './DraggableList.module.scss';
import MediumModal from '../MediumModal/MediumModal';
import { Medium } from '../../../models/Medium';
import { User } from '../../../models/User';
import { getCorrectTitle } from '../../../helpers/helpers';
import { addMediumToShelf, addMediumToWishList } from '../../../services-api/mediumService';

interface DraggableListProps {
  mediums: Medium[];
  mediumType?: string;
  isEditing: boolean;
  onSaveAction: (mediums: Medium[]) => void;
}

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result: Medium[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'transparent' : 'transparent',
  // padding: grid,
  // width: 250
});

function DraggableList(props: DraggableListProps) {
  const {
    mediumType, mediums, isEditing, onSaveAction,
  } = props;
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const topRankingViewingUser = useSelector<StoreState, User | null>((state) => state.viewingUser);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const shelfIds = useSelector<StoreState, any>((state) => state.shelfIds);
  const [isMediumModalOpen, setIsMediumModalOpen] = useState(false);
  const [items, setItems] = useState<Medium[]>(mediums);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mediums) {
      setItems(mediums);
    }
  }, [mediums.length]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const nItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(nItems);
  };

  const onSaveClick = () => {
    onSaveAction(items);
  };

  const onAddToShelf = (medium?: Medium, callback?: Function) => {
    if (medium && callback) {
      dispatch(addMediumToShelf(currentUser.username, medium, callback));
    }
  };

  const onAddToWishListAction = (medium?: Medium, callback?: Function) => {
    if (medium && callback) {
      dispatch(addMediumToWishList(currentUser.username, medium, callback));
    }
  };

  const onAddNewMedium = () => {
    setIsMediumModalOpen(!isMediumModalOpen);
  };

  const onRemoveClick = (item?: Medium) => {
    setItems([...items.filter((x) => x._id !== item?._id)]);
  };

  const handleOk = () => {

  };

  const handleCancel = () => {
    setIsMediumModalOpen(!isMediumModalOpen);
  };

  const onSelect = (item: Medium) => {
    if (!items.map((x) => x._id).includes(item._id)) {
      setItems([...items, item]);
      setIsMediumModalOpen(!isMediumModalOpen);
    }
  };

  return (
    topRankingViewingUser && currentUser
        && (
        <div className={styles.DraggableList}>
          <MediumModal
            isMediumModalOpen={isMediumModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            onSelect={onSelect}
            mediumType={mediumType}
          />
          {
                !isLoading
                && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {(props.mediums.length > 0 || items) && items.map((item: Medium, index: number) => (
                          <Draggable
                            key={item._id}
                            isDragDisabled={!isEditing}
                            draggableId={item._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <TopCard
                                  isEditing={isEditing}
                                  shelfIds={shelfIds}
                                  index={index}
                                  medium={item}
                                  currentUser={currentUser}
                                  viewingUser={topRankingViewingUser}
                                  onRemoveClick={onRemoveClick}
                                  onAddToShelf={onAddToShelf}
                                  onAddToWishListAction={onAddToWishListAction}
                                  isLoading={isLoading}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        {
                                    (isEditing || mediums.length === 0)
                                    && topRankingViewingUser && currentUser
                                    && topRankingViewingUser.username === currentUser.username
                                    && (
                                    <div className={styles.addNewMediumContainer}>
                                      <Button
                                        className={styles.addNewMediumButton}
                                        size="large"
                                        type="primary"
                                        loading={isLoading}
                                        onClick={onAddNewMedium}
                                      >
                                        Add New
                                        {' '}
                                        {getCorrectTitle(mediumType)?.singular}
                                      </Button>
                                      <Button
                                        className={styles.saveButton}
                                        size="large"
                                        type="primary"
                                        loading={isLoading}
                                        onClick={onSaveClick}
                                      >
                                        Save
                                      </Button>
                                    </div>
                                    )
                                }
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                )
            }
        </div>
        )
  );
}

export default DraggableList;
