import React, { useEffect } from 'react';
import { Input, List, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styles from './MyMediumList.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { Medium } from '../../models/Medium';
import Empty from '../Empty/Empty';
import { ActionTypes } from '../../actions/types';

interface MyMediumListProps {
  onMyMediumClick: Function;
}

const { Search } = Input;
function MyMediumList(props: MyMediumListProps) {
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const myMediums = useSelector<StoreState, Medium[]>((state) => state.myMediums);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const dispatch = useDispatch();

  const onSearch = (value: string) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    axios.post('/api/users/mymediums/', { searchText: value })
      .then((response) => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        dispatch({ type: ActionTypes.SET_MY_MEDIUMS, mediums: response.data });
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const onMyMediumClick = (medium: Medium) => {
    props.onMyMediumClick(medium);
  };

  useEffect(() => {
    if (currentUser && myMediums && myMediums.length === 0) {
      onSearch('');
    }
  }, [currentUser]);

  return (
    <div className={styles.MyMediumList}>
      <Search
        className={styles.searchInput}
        placeholder="Search from your shelves"
        size="large"
        onSearch={onSearch}
      />
      <List
        className={styles.mediumList}
        itemLayout="vertical"
        dataSource={myMediums}
        loading={isLoading}
        locale={currentUser && myMediums && myMediums.length === 0 && !isLoading ? {
          emptyText: <Empty
            title="Your shelves are empty"
            subTitle="Add books and films to your shelf"
            buttonTitle="Search"
            icon="search"
            url="/search/movie"
            screen="empty_movies"
          />,
        } : { emptyText: <Spin /> }}
        renderItem={(item) => (
          <List.Item key={`medium${item._id}`} className={styles.mediumItem} onClick={() => onMyMediumClick(item)}>
            <div className={styles.title}>
              {item.title}
            </div>
            <div className={styles.subtitle}>
              {
                  item.providers[0] && item.providers[0].displayName
              }
            </div>
          </List.Item>
        )}
      />
    </div>
  );
}

export default MyMediumList;
