import React from 'react';
import styles from './quiz-card.module.scss';
import {Quiz} from "../../../models/Quiz";
import {Button, Image} from "antd";
import {fallBackUrl} from "../../../utils/imageData";
import {Post} from "../../../models/Post";

type QuizCardProps = {
    quiz: Quiz,
    post: Post
}

function QuizCard(props: QuizCardProps) {
    const { quiz, post } = props;
    const { user } = quiz;

    const calculateScore = (post: Post, quizAnswers: { [key: string]: string }) => {
        let totalPoints = 0;
        post.questions.forEach((question) => {
            if (question.correctAnswerId === quizAnswers[question.id]) {
                totalPoints = totalPoints + 1;
            }
        })
        return totalPoints;
    }

    return (
        <div className={styles.QuizCard}>
            <div className={styles.imageContainer}>
                <Image
                    className={styles.image}
                    src={user.image}
                    alt={user.image}
                    preview={false}
                    fallback={fallBackUrl}
                />
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.header}>
                    <div className={styles.name}>
                        <div className={styles.displayName}>
                            {user.displayName}
                        </div>
                        <div className={styles.username}>
                            @
                            {user.username}
                        </div>
                    </div>
                    <div className={styles.scoreContainer}>
                        <span className={styles.score}>{Math.floor((calculateScore(post, quiz.answers)/post.questions.length) * 100)}%</span>
                        {/*<span>/{post.questions.length}</span>*/}
                    </div>
                    {
                        // currentUserId !== user._id
                        // && (
                        //     <Button
                        //         type="primary"
                        //         className={followingUserIds[`${user._id}`] ? styles.following : styles.follow}
                        //         ghost={!followingUserIds[`${user._id}`]}
                        //         loading={currentId === user._id && isLoading}
                        //         onClick={onFollowClick}
                        //     >
                        //         {followingUserIds[`${user._id}`] ? 'Following' : followRequestUserIds[`${user._id}`] ? 'Requested' : 'Follow'}
                        //     </Button>
                        // )
                    }
                </div>
                <div className={styles.bio}>
                    {user.bio}
                </div>
            </div>
        </div>
    );
}

export default QuizCard;