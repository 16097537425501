import React, { useState } from 'react';
import {
  Button, Form, Input, Select,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import {
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import styles from './clubs-edit-screen.module.scss';
import 'cropperjs/dist/cropper.css';
import BgImageModal from './bg-image-modal/bg-image-modal';
import ImageModal from './image-modal/image-modal';
import { ActionTypes } from '../../actions/types';
import { updateClub } from '../../services-api/clubService';
import { StoreState } from '../../reducers/_RootReducers';

const { TextArea } = Input;
function ClubsEditScreen() {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  let globalFileInput: any;
  let globalFileInputClub: any;
  const [file, setFile] = useState<Blob | null>(null);
  const [fileClub, setFileClub] = useState<Blob | null>(null);
  const [name, setName] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [about, setAbout] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isClubOpen, setIsClubOpen] = useState(false);
  const [cropData, setCropData] = useState(null);
  const [clubCropImageData, setClubCropImageData] = useState(null);
  const [backgroundCropper, setBackgroundCropper] = useState<any>();
  const [clubCropper, setClubCropper] = useState<any>();
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const onBackClick = () => {
    history.goBack();
  };

  const {
    isLoading: isDataLoading, error, data, isFetching,
  } = useQuery(`club-screen-${id}-edit`, () => axios.get(`/api/clubs/${id}`)
    .then((response) => response.data));

  const onImageClick = () => {
    setIsClubOpen(true);
  };

  const onBackgroundClick = () => {
    setIsOpen(true);
  };

  const onCancel = () => {
    setFile(null);
    setFileClub(null);
    setIsOpen(false);
    setIsClubOpen(false);
  };

  const onFileSaveClick = async () => {
    if (typeof backgroundCropper !== 'undefined') {
      backgroundCropper.getCroppedCanvas().toBlob((dataBlob: any) => {
        setCropData(dataBlob);
        setIsOpen(false);
        setFile(null);
        setFileClub(null);
      });
    }
  };

  const onFileClubSaveClick = async () => {
    if (typeof clubCropper !== 'undefined') {
      clubCropper.getCroppedCanvas().toBlob((dataBlob: any) => {
        setClubCropImageData(dataBlob);
        setIsClubOpen(false);
        setFile(null);
        setFileClub(null);
      });
    }
  };

  const fileSelectedHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  const fileSelectedHandlerClub = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileClub(file);
    }
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onSubtitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubtitle(event.target.value);
  };

  const onAboutChange = (event: any) => {
    setAbout(event.target.value);
  };

  const onFinish = (values: any) => {
    const formData = new FormData();
    let dataChanged = false;

    if (values.name?.trim() !== data?.club?.name?.trim()) {
      formData.append('name', values.name?.trim());
      dataChanged = true;
    }

    if (values.subtitle?.trim() !== data?.club?.subtitle?.trim()) {
      formData.append('subtitle', values.subtitle?.trim());
      dataChanged = true;
    }

    if (values.about?.trim() !== data?.club?.about?.trim()) {
      formData.append('about', values.about?.trim());
      dataChanged = true;
    }

    if (values.privacy?.trim() !== data?.club?.privacy?.trim()) {
      formData.append('privacy', values.privacy?.trim());
      dataChanged = true;
    }

    if (cropData != null) {
      formData.append('avatar', cropData as any, 'temp');
      dataChanged = true;
    }

    if (clubCropImageData != null) {
      formData.append('avatar-club', clubCropImageData as any, 'temp');
      dataChanged = true;
    }

    if (dataChanged) {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      dispatch(updateClub(id, formData, () => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        onBackClick();
      }));
    }
  };

  const onFinishFailed = (error: any) => {
    console.log('onFinish - fail - values: ', error);
  };

  return (
    <div className={styles.ClubsEditScreen}>
      <BgImageModal
        file={file}
        isOpen={isOpen}
        onCancel={onCancel}
        saveAction={onFileSaveClick}
        setBackgroundCropper={setBackgroundCropper}
      />
      <ImageModal
        file={fileClub}
        isOpen={isClubOpen}
        onCancel={onCancel}
        saveAction={onFileClubSaveClick}
        setClubCropper={setClubCropper}
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <Button
            className={styles.goBack}
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={onBackClick}
          />
          <div className={styles.name}>
            Edit Story Club
          </div>
        </div>
        <div className={styles.form}>
          <label htmlFor="file-input">
            <img
              className={styles.backgroundImage}
              src={cropData ? URL.createObjectURL(cropData) : data?.club?.backgroundImage}
              alt={cropData ? URL.createObjectURL(cropData) : data?.club?.backgroundImage}
              onClick={onBackgroundClick}
            />
          </label>
          <input
            style={{ display: 'none' }}
            id="file-input"
            type="file"
            name="avatar"
            onChange={fileSelectedHandler}
            ref={(fileInput) => globalFileInput = fileInput}
            accept=".jpeg,.png"
          />
          <div style={{ textAlign: 'center' }} className={styles.clickToEdit}>
            click image to edit
          </div>
          <label htmlFor="file-input-club">
            <img
              className={styles.clubImage}
              src={clubCropImageData ? URL.createObjectURL(clubCropImageData) : data?.club?.image}
              alt="cropped"
              onClick={onImageClick}
            />
          </label>
          <input
            style={{ display: 'none' }}
            id="file-input-club"
            type="file"
            name="avatar-club"
            onChange={fileSelectedHandlerClub}
            ref={(fileInput) => globalFileInputClub = fileInput}
            accept=".jpeg,.png"
          />
          <div className={styles.clickToEdit}>
            click image to edit
          </div>
          {
            data
            && (
            <Form
              form={form}
              className="edit-club-form"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Club Name"
                name="name"
                initialValue={data?.club?.name}
                required
                rules={[{ required: true, message: 'Club Name is required' }]}
              >
                <Input
                  size="large"
                  defaultValue={data?.club?.name}
                  value={name}
                  onChange={onNameChange}
                  placeholder="Enter story club name"
                />
              </Form.Item>
              <Form.Item
                label="Club Subtitle"
                name="subtitle"
                initialValue={data?.club?.subtitle}
              >
                <Input
                  size="large"
                  defaultValue={data?.club?.subtitle}
                  value={subtitle}
                  onChange={onSubtitleChange}
                  placeholder="Enter story club subtitle"
                />
              </Form.Item>
              <Form.Item
                label="About"
                name="about"
                initialValue={data?.club?.about}
                rules={[{ required: false }]}
              >
                <TextArea
                  placeholder="Enter text about club..."
                  defaultValue={data?.club?.about}
                  value={about}
                  onChange={onAboutChange}
                  autoSize={{ minRows: 2, maxRows: 3 }}
                />
              </Form.Item>
              <Form.Item
                label="Privacy"
                initialValue={data?.club?.privacy?.toLowerCase()}
                required
                name="privacy"
                rules={[{ required: true, message: 'Privacy is required' }]}
              >
                <Select size="large" defaultValue={data?.club?.privacy?.toLowerCase()}>
                  <Select.Option value="public">Public</Select.Option>
                  <Select.Option value="private">Private</Select.Option>
                </Select>
              </Form.Item>
              {/* <Form.Item */}
              {/*  label="Invite friends" */}
              {/*  name="friends" */}
              {/* > */}
              {/*  <Select */}
              {/*    mode="multiple" */}
              {/*    size="large" */}
              {/*    labelInValue */}
              {/*    value={value} */}
              {/*    placeholder="Select users" */}
              {/*    notFoundContent={isFetching ? <Spin size="small" /> : null} */}
              {/*    filterOption={false} */}
              {/*    onSearch={onSearch} */}
              {/*    tagRender={(item) => { */}
              {/*      const user: User = JSON.parse(item.value as string); */}
              {/*      return ( */}
              {/*        <div className={styles.friendLabel}> */}
              {/*          <Image */}
              {/*            className={styles.image} */}
              {/*            src={user.image} */}
              {/*            fallback={fallBackUrl} */}
              {/*          /> */}
              {/*          <div className={styles.name}>{user.username}</div> */}
              {/*          <CloseOutlined className={styles.closeIcon} onClick={() => onDeleteClick(item.value as string)} /> */}
              {/*        </div> */}
              {/*      ); */}
              {/*    }} */}
              {/*    onChange={handleChange} */}
              {/*    style={{ width: '100%' }} */}
              {/*  > */}
              {/*    {data?.map((page) => page.users).flat().map((user: User, index) => ( */}
              {/*      <Option key={user.image} value={JSON.stringify({ image: user.image, username: user.username, _id: user._id })}> */}
              {/*        <div className={styles.friendLabelOption}> */}
              {/*          <Image */}
              {/*            className={styles.image} */}
              {/*            src={user.image} */}
              {/*            alt={user.image} */}
              {/*            fallback={fallBackUrl} */}
              {/*          /> */}
              {/*          <div className={styles.name}>{user.username}</div> */}
              {/*        </div> */}
              {/*      </Option> */}
              {/*    ))} */}
              {/*  </Select> */}
              {/* </Form.Item> */}
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default ClubsEditScreen;
