import React, { useEffect, useState } from 'react';
import {useInfiniteQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton, Spin } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './profile-posts-grid.module.scss';
import EditGrid from '../../../components/EditGrid/EditGrid';
import { Post } from '../../../models/Post';
import PostGridCard from './post-grid-card/post-grid-card';
import Empty from '../../../components/Empty/Empty';

type ProfilePostsGrid = {
  isEditing: boolean;
  isCurrentUser: boolean;
  username: string;
  onEditingClick: () => void;
  onRemovePostClick: (post: Post, callback: (id: string) => void) => void;
  onPostClick: (post: Post) => void;
  mediumCount?: number;
};

function ProfilePostsGrid(props: ProfilePostsGrid) {
  const {
    isEditing, isCurrentUser, username, mediumCount, onEditingClick, onRemovePostClick, onPostClick,
  } = props;
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [sortBy, setSortBy] = useState(search?.replace('?', '') || 'createdAt');
  const [timeStamp] = useState(new Date());
  const [limit] = useState(15);
  const [width, setWidth] = useState(window.innerWidth);
  const queryCache = useQueryCache();

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `${username}-posts-tab-${sortBy}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/users/${username}/posts?sortBy=${sortBy}&limit=${limit}&page=${nextId}&timeStamp=${timeStamp}`);
      if (data?.posts?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
      enabled: !!username
    },
  );

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);
  }, [data]);

  const tempOnRemovePostClick = (post: Post) => {
    onRemovePostClick(post, (postId) => {
      queryCache.setQueryData(`${username}-posts-tab-${sortBy}`, (oldData) =>
        // @ts-ignore
      // eslint-disable-next-line implicit-arrow-linebreak
        oldData.map((x: any) => ({ ...x, posts: [...x.posts.filter((x: any) => x._id !== postId)] })));
    });
  };

  const triggerSortMediums = (updatedSortBy: string) => {
    setSortBy(updatedSortBy);
    setIsAtTheEnd(false);
    history.replace(`${pathname}?${updatedSortBy}`);
  };

  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className={styles.ProfilePostsGrid}>
      <EditGrid
        isEditing={isEditing}
        mediumType="posts"
        mediumCount={mediumCount}
        isCurrentUser={isCurrentUser}
        triggerSort={triggerSortMediums}
        onEditingClick={onEditingClick}
      />
      {
        data?.map((page) => page.posts).flat().length === 0
          ? (
            <div className={styles.emptyView}>
              {
                isCurrentUser
                  ? (
                    <Empty
                      title="Create your first post"
                      subTitle="Create awesome looking posts"
                      icon="plus"
                      url="/create"
                      buttonTitle="Create post"
                      screen="posts"
                    />
                  )
                  : (
                    <div style={{ textAlign: 'center' }}>
                      <h3>
                        @
                        {username}
                        {' '}
                        hasn’t added any posts
                      </h3>
                      <div>
                        When they do, those posts will show up here.
                      </div>
                    </div>
                  )
              }
            </div>
        )
          : (
            <InfiniteScroll
              className={styles.gridList}
              next={() => fetchMore()}
              hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
              loader={(
                <div className={styles.spinningContainer}>
                  <Spin />
                </div>
                )}
              dataLength={data?.length || 0}
            >
              {
                isFetching && !data && [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item: any, index: number) => (
                  <div key={`skel-${index}`} className={styles.skeletonItem}>
                    <Skeleton
                      className={styles.imageContainer}
                      active
                      title={{ style: { height: width > 800 ? '264px' : width * 0.5, width: 'auto !important' } }}
                      paragraph={{ rows: 0 }}
                    />
                  </div>
                ))
              }
              {
                data?.map((page) => page.posts).flat().length !== 0 && data?.map((page) => page.posts).flat().map((item: any) => (
                  <PostGridCard
                    key={item._id}
                    isEditing={isEditing}
                    onRemovePostClick={tempOnRemovePostClick}
                    item={item}
                    onPostClick={onPostClick}
                  />
                ))
              }
            </InfiniteScroll>
            )
      }
    </div>
  );
}

export default ProfilePostsGrid;
