import {Modal} from "antd";
import './Error.scss';

function ErrorModal(message: string) {
    Modal.error({
        title: 'Oops! Something went wrong...',
        content: message,
    });
}

export default ErrorModal;
