import React, {useState} from 'react';
import styles from './club-activity-tab.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {useHistory, useParams} from "react-router-dom";
import {useInfiniteQuery, useQueryCache} from "react-query";
import axios from "axios";
import {ActionTypes} from "../../../actions/types";
import {RecentActivity as RecentActivityModel, RecentActivity} from "../../../models/RecentActivity";
import {TopicPost} from "../../../models/TopicPost";
import _ from "lodash";
import {like, unlike, updateLike} from "../../../services-api/clubTopicsService";
import {Note} from "../../../models/Note";
import PreviewNoteModal from "../../../components/preview-note-modal/preview-note-modal";
import Empty from "../../../components/Empty/Empty";
import RecentCardSkeleton from "../../clubs-screen/club-recent-activity/recent-card-skeleton/recent-card-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {Spin} from "antd";
import RecentActivityCard from "../../clubs-screen/club-recent-activity/recent-activity-card/recent-activity-card";
import SpinContainerList from "../../../components/spin-container-list/spin-container-list";

function ClubActivityTab() {
    const { id } = useParams<{ id: string }>();
    const reactionIds = useSelector<StoreState, any>((state) => state.reactionIds);
    const isReactionsOpen = useSelector<StoreState, boolean>((state) => state.isReactionsOpen);
    const currentId = useSelector<StoreState, string>((state) => state.currentId);
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const history = useHistory();
    const [timeStamp] = useState(new Date());
    const [limit] = useState(12);
    const dispatch = useDispatch();
    const queryCache = useQueryCache();

    const {
        data,
        isFetching,
        fetchMore,
    } = useInfiniteQuery(
        `club-screen-activity-tab-${id}`,
        async (key, nextId = 0) => {
            const { data } = await axios.get(`/api/clubs/activities/club/${id}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
            dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, ...data?.reactionIds } });

            if (data?.topicPosts?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getFetchMore: (lastGroup) => lastGroup.nextId,
        },
    );

    const onReactionClick = (event: any, tp: RecentActivity) => {
        event.stopPropagation();
        dispatch({ type: ActionTypes.SET_CURRENT_ID, value: tp._id });
        dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: true });
    };

    const onRecentActivityClick = (recentActivity: RecentActivity) => {
        history.push(`/storyclubs/${recentActivity.club._id}/${recentActivity.topic.type === 'STORY' ? 'stories' : 'discussions'}/${recentActivity.topic._id}`)
    };

    const updateExistingPost = (post: TopicPost, type: string, existingReaction: any) => {
        let updatedPost = { ...post };

        if (type === existingReaction.typeText) {
            if (type === 'like') {
                updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) - 1 };
            }

            if (type === 'love') {
                updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) - 1 };
            }

            if (type === 'lol') {
                updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) - 1 };
            }
            const updatedReactionIds = _.omit(reactionIds, `${post._id}`);
            dispatch({ type: ActionTypes.SET_REACTION_IDS, value: updatedReactionIds });
            return updatedPost;
        }

        if (type === 'like') {
            updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) + 1 };
        }

        if (type === 'love') {
            updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) + 1 };
        }

        if (type === 'lol') {
            updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) + 1 };
        }

        if (existingReaction.typeText === 'like') {
            updatedPost = { ...updatedPost, likesCount: updatedPost!.likesCount! - 1 };
        }

        if (existingReaction.typeText === 'love') {
            updatedPost = { ...updatedPost, lovesCount: updatedPost!.lovesCount! - 1 };
        }

        if (existingReaction.typeText === 'lol') {
            updatedPost = { ...updatedPost, laughsCount: updatedPost!.laughsCount! - 1 };
        }

        dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${post?._id}`]: { typeText: type } } });

        return updatedPost;
    };

    const updatePost = (post: TopicPost, type: string) => {
        if (type === 'like') {
            return { ...post, likesCount: (post?.likesCount || 0) + 1 };
        }

        if (type === 'love') {
            return { ...post, lovesCount: (post?.lovesCount || 0) + 1 };
        }

        if (type === 'lol') {
            return { ...post, laughsCount: (post?.laughsCount || 0) + 1 };
        }

        dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${post?._id}`]: { typeText: type } } });
        return post;
    };

    const onReactionAction = (event: any, tp: any, type: string) => {
        event.stopPropagation();
        queryCache.setQueryData('clubs-screen-activity-tab', (oldData: any) => {
            if (oldData) {
                console.log("what - oldData: ",oldData);
                dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${tp?._id}`]: { typeText: type } } });
                return oldData.map((x: any) => ({
                    ...x,
                    topicPosts: x.topicPosts.map((post: TopicPost) => {
                        if (post._id === tp._id) {
                            if (reactionIds[`${post._id}`]) {
                                return updateExistingPost(post, type, reactionIds[`${post._id}`]);
                            }
                            return updatePost(post, type);
                        }
                        return post;
                    }),
                }));
            }
            return oldData;
        });

        dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
        dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: false });

        if (reactionIds[`${tp._id}`]) {
            if (type === reactionIds[`${tp._id}`].typeText) {
                dispatch(unlike(tp, type, () => {
                }));
            } else {
                dispatch(updateLike(tp, type, () => {
                }));
            }
        } else {
            dispatch(like(tp, type, () => {
            }));
        }
    };

    const onPreviewNoteAction = (note: Note) => {
        dispatch({ type: ActionTypes.SET_DISPLAY_NOTE, value: note });
    };

    return (
        <div className={styles.ClubActivityTab}>
            <PreviewNoteModal />
            {
                isFetching === false &&  data?.map((page) => page.topicPosts).flat().length === 0 &&
                <Empty
                    title="You have no recent activities"
                    subTitle="Join fun clubs"
                    icon="plus"
                    buttonTitle="Create Story Club"
                    url="/createclubs"
                    screen="posts"
                />
            }
            {
                isFetching && !data
                    ? (
                        <div className={styles.container}>
                            {
                                [1, 1, 1, 1, 1, 1].map((item, index) => <RecentCardSkeleton key={`${index}`} />)
                            }
                        </div>
                    ) : (
                        <InfiniteScroll
                            dataLength={data?.map((page) => page.topicPosts).flat().length || 0}
                            next={() => fetchMore()}
                            hasMore={(data?.map((page) => page.topicPosts).flat().length || 0) > 0 && !isAtTheEnd}
                            loader={(
                                <SpinContainerList />
                            )}
                        >
                            {
                                data?.map((page) => page.topicPosts).flat()
                                    .map((recentActivity: RecentActivityModel) => (
                                        <RecentActivityCard
                                            key={recentActivity?._id}
                                            recentActivity={recentActivity}
                                            timeStamp={timeStamp}
                                            currentId={currentId}
                                            isReactionsOpen={isReactionsOpen}
                                            onReactionClick={onReactionClick}
                                            reactionIds={reactionIds}
                                            onRecentActivityClick={onRecentActivityClick}
                                            onReactionAction={onReactionAction}
                                            onPreviewNoteAction={onPreviewNoteAction}
                                            hideTimeStamp
                                        />
                                    ))
                            }
                        </InfiniteScroll>
                    )
            }
        </div>
    );
}

export default ClubActivityTab;