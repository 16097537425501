import { ActionTypes } from '../actions/types';
import { User } from '../models/User';
import { Post } from '../models/Post';
import { Medium } from '../models/Medium';

interface setViewingUser {
  type: typeof ActionTypes.SET_VIEWING_USER;
  viewingUser: User;
}

interface setSelectedPost {
  type: typeof ActionTypes.SET_SELECTED_POST;
  post: Post;
}

interface updateViewingUserPosts {
  type: typeof ActionTypes.UPDATE_VIEWING_USER_POSTS;
  posts: Post[];
}

interface updateViewingUserBooks {
  type: typeof ActionTypes.UPDATE_VIEWING_USER_BOOKS;
  books: Medium[];
}

interface updateViewingUserSeries {
  type: typeof ActionTypes.UPDATE_VIEWING_USER_SERIES;
  series: Medium[];
}

interface updateViewingUserMovies {
  type: typeof ActionTypes.UPDATE_VIEWING_USER_MOVIES;
  movies: Medium[];
}

interface updateViewingUserPodcasts {
  type: typeof ActionTypes.UPDATE_VIEWING_USER_PODCASTS;
  podcasts: Medium[];
}

interface setTopRankingViewingUser {
  type: typeof ActionTypes.SET_TOP_RANKING_VIEWING_USER;
  topRankingViewingUser: User;
}

type profileActions = setViewingUser | setSelectedPost | updateViewingUserPosts
| updateViewingUserBooks | updateViewingUserSeries | updateViewingUserMovies
| updateViewingUserPodcasts | setTopRankingViewingUser;

export const viewingUser = (state: User | null = null, action: profileActions) => {
  if (action.type === ActionTypes.SET_VIEWING_USER) {
    return action.viewingUser;
  } if (action.type === ActionTypes.UPDATE_VIEWING_USER_POSTS) {
    if (state) {
      return { ...state, posts: [...state.posts, ...action.posts] };
    }
    return state;
  } if (action.type === ActionTypes.UPDATE_VIEWING_USER_BOOKS) {
    if (state) {
      return { ...state, books: [...state.books, ...action.books] };
    }
    return state;
  } if (action.type === ActionTypes.UPDATE_VIEWING_USER_MOVIES) {
    if (state) {
      return { ...state, movies: [...state.movies, ...action.movies] };
    }
    return state;
  } if (action.type === ActionTypes.UPDATE_VIEWING_USER_SERIES) {
    if (state) {
      return { ...state, series: [...state.series, ...action.series] };
    }
    return state;
  } if (action.type === ActionTypes.UPDATE_VIEWING_USER_PODCASTS) {
    if (state) {
      return { ...state, podcasts: [...state.podcasts, ...action.podcasts] };
    }
    return state;
  }
  return state;
};

export const topRankingViewingUser = (state: User | null = null, action: profileActions) => {
  if (action.type === ActionTypes.SET_TOP_RANKING_VIEWING_USER) {
    return action.topRankingViewingUser;
  }
  return state;
};

export const selectedPost = (state: Post | null = null, action: profileActions) : Post | null => {
  if (action.type === ActionTypes.SET_SELECTED_POST) {
    return action.post;
  }
  return state;
};
