import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { Badge, Button } from 'antd';
import {
  LeftOutlined, RightOutlined, UploadOutlined, SaveOutlined, HomeOutlined, SearchOutlined,
  PlusCircleOutlined, BellOutlined, UserOutlined,
} from '@ant-design/icons';
import * as htmlToImage from 'html-to-image';
import styles from './BottomNavBar.module.scss';
import { User } from '../../models/User';
import { StoreState } from '../../reducers/_RootReducers';
import { ActionTypes } from '../../actions/types';
import { CreateQuote } from '../../models/create-models/CreateQuote';
import {
  createDraftQuote,
  createDraftSummary, createDraftWilt, createPostQuote,
  createSummary, createWilt, updateDraftQuote,
  updateDraftSummary, updateDraftWilt,
} from '../../services-api/createPostService';

function BottomNavBar() {
  const lastLocationState = useSelector<StoreState, string>((state) => state.lastLocationState);
  const selectedMenuItem = useSelector<StoreState, string>((state) => state.selectedMenuItem);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);
  const location = useLocation();
  const history = useHistory();
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();

  const tabs = (currentUser: User) => [{ id: 'home', url: 'home', icon: <HomeOutlined /> }, { id: 'search', url: 'search', icon: <SearchOutlined /> }, { id: 'create', url: 'create', icon: <PlusCircleOutlined /> },
    { id: 'notifications', url: 'notifications', icon: <BellOutlined /> }, { id: 'profile', url: `profile/${currentUser.username}`, icon: <UserOutlined /> }];

  const setMenu = (id: string) => {
    dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: id });
    dispatch({ type: ActionTypes.SET_MENU_ITEM, value: id });
  };

  const getLastState = () => {
    const currentLocation = location.pathname.split('/')[1];
    if (!tabs(currentUser).map((x) => x.id).includes(currentLocation)) {
      if (lastLocationState) {
        if (tabs(currentUser).map((x) => x.id).includes(lastLocationState)) {
          return lastLocationState;
        }
        return 'home';
      }
      if (lastLocation) {
        const lastLocationName = lastLocation.pathname.split('/')[1];
        if (tabs(currentUser).map((x) => x.id).includes(lastLocationName)) {
          return lastLocationName;
        }
        return 'home';
      }
      return 'home';
    }
    return '';
  };

  useEffect(() => {
    if (location) {
      dispatch({ type: ActionTypes.SET_MENU_ITEM, location: location.pathname.split('/')[1] });
      dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: location.pathname.split('/')[1] });
    }
  }, [selectedMenuItem, lastLocationState]);

  const onPrevClick = () => {
    if (createQuote.currentPage > 1) {
      const newCreateQuote = { ...createQuote, currentPage: createQuote.currentPage - 1 };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    } else {
      history.replace('/create');
    }
  };

  const saveAndGoToNext = () => {
    const item = document.getElementById('my-node');
    if (item) {
      htmlToImage.toBlob(item, { quality: 2.0 })
        .then((dataUrl) => {
          if (dataUrl) {
            const newCreateQuote = { ...createQuote, dataUrl, currentPage: createQuote.currentPage + 1 };
            dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
          }
        }).catch((e) => {
          console.log('e: ', e);
        });
    }
  };

  const onNextClick = () => {
    if (createQuote.currentPage < createQuote.totalPages) {
      if (createQuote.currentPage === 2 && location.pathname === '/create/quote') {
        saveAndGoToNext();
      } else {
        const newCreateQuote = { ...createQuote, currentPage: createQuote.currentPage + 1 };
        dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
      }
    }
  };

  const onSaveAsDraft = () => {
    if (createQuote.type === 'quote') {
      if (createQuote.draftId) {
        dispatch(updateDraftQuote(createQuote, () => {
          history.replace('/drafts');
          dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
          dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
        }));
      } else {
        dispatch(createDraftQuote(createQuote, () => {
          history.replace('/drafts');
          dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
          dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
        }));
      }
    } else if (createQuote.type === 'wilt') {
      if (createQuote.wiltId) {
        dispatch(updateDraftWilt(createQuote, () => {
          history.replace('/drafts');
          dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
          dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
        }));
      } else {
        dispatch(createDraftWilt(createQuote, () => {
          history.replace('/drafts');
          dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
          dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
        }));
      }
    } else if (createQuote.type === 'summary') {
      if (createQuote.summaryId) {
        dispatch(updateDraftSummary(createQuote, () => {
          history.replace('/drafts');
          dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
          dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
        }));
      } else {
        dispatch(createDraftSummary(createQuote, () => {
          history.replace('/drafts');
          dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
          dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
        }));
      }
    }
  };

  const onPostClick = () => {
    if (createQuote.type === 'quote') {
      dispatch(createPostQuote(createQuote, () => {
        history.replace('/');
        dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
        dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
      }));
    } else if (createQuote.type === 'wilt') {
      dispatch(createWilt(createQuote, () => {
        history.replace('/');
        dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
        dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
      }));
    } else if (createQuote.type === 'summary') {
      dispatch(createSummary(createQuote, () => {
        history.replace('/');
        dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
        dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
      }));
    }
  };

  const showCorrectIcon = (val: any) => {
    switch (val.id) {
      case 'home':
        return (
          <HomeOutlined
            className={`${styles.icon} ${selectedMenuItem === val.id ? styles.selected : getLastState() === val.id ? styles.selected : ''}`}
            type={val.icon}
          />
        );
      case 'search':
        return (
          <SearchOutlined
            className={`${styles.icon} ${selectedMenuItem === val.id ? styles.selected : getLastState() === val.id ? styles.selected : ''}`}
            type={val.icon}
          />
        );
      case 'create':
        return (
          <PlusCircleOutlined
            className={`${styles.icon} ${selectedMenuItem === val.id ? styles.selected : getLastState() === val.id ? styles.selected : ''}`}
            type={val.icon}
          />
        );
      case 'notifications':
        return (
          <BellOutlined
            className={`${styles.icon} ${selectedMenuItem === val.id ? styles.selected : getLastState() === val.id ? styles.selected : ''}`}
            type={val.icon}
          />
        );
      case 'profile':
        return (
          <UserOutlined
            className={`${styles.icon} ${selectedMenuItem === val.id ? styles.selected : getLastState() === val.id ? styles.selected : ''}`}
            type={val.icon}
          />
        );
      default:
        return <div />;
    }
  };

  const notInBadList = () => {
    const locArr = location.pathname.split('/');
    const loc = locArr[1];
    if (locArr.length > 3 && loc === 'clubs' && ['members', 'about', 'edit'].includes(locArr[3])) {
      return false;
    }

    if (locArr.length > 3 && loc === 'story' && ['notes'].includes(locArr[2])) {
      return false;
    }

    if (locArr.length > 1 && loc === 'storyclubs') {
      return false;
    }

    if (locArr.length > 1 && loc === 'storynotes') {
      return false;
    }

    if (locArr.length > 4 && loc === 'storyclubs' && ['stories'].includes(locArr[3])) {
      return false;
    }

    if (locArr[1] === "create" && ['/create/quote', '/create/quiz', '/create/template'].includes(location.pathname)) {
      return !['create','template'].includes(locArr[1]);
    }

    return !['chats', 'group', 'newgroupchatusers', 'notes'].includes(locArr[1]);
  };

  return (
    currentUser && !['/create/quote', '/create/wilt', '/create/summary'].includes(location.pathname)
      ? notInBadList() ? (
        <div className={`${styles.BottomNavBar}`}>
          {
            tabs(currentUser).map((val) => (
              <Badge key={val.id} count={val.id === 'notifications' ? currentUser.notificationCount : 0} className={styles.item}>
                <Link to={`/${val.url}`} onClick={() => setMenu(val.id)}>
                  {
                    showCorrectIcon(val)
                  }
                </Link>
              </Badge>
            ))
          }
        </div>
      ) : <div /> : currentUser
            && (
            <div className={styles.BottomNavBar}>
              <div className={styles.actions}>
                <Button className={styles.navigationBtn} onClick={onPrevClick}><LeftOutlined /></Button>
                {
                        createQuote.currentPage === createQuote.totalPages
                          ? (
                            <div>
                              <Button className={styles.saveAsDraft} type="primary" ghost onClick={onSaveAsDraft}>
                                save draft
                                <SaveOutlined type="save" />
                              </Button>
                              <Button className={styles.navigationBtn} type="primary" onClick={onPostClick}>
                                POST
                                <UploadOutlined type="upload" />
                              </Button>
                            </div>
                          ) : createQuote.currentPage !== 1
                            && (
                            <Button className={styles.navigationBtn} type="primary" onClick={onNextClick}>
                              next
                              <RightOutlined />
                            </Button>
                            )
                    }
              </div>
            </div>
            )
  );
}

export default BottomNavBar;
