import React, { useEffect, useRef, useState } from 'react';
import { List, Skeleton, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './medium-screen.module.scss';
import MediumItem from '../../components/MediumItem/MediumItem';
import Sources from './Sources/Sources';
import GoBack from '../../components/GoBack/GoBack';
import { StoreState } from '../../reducers/_RootReducers';
import { Medium } from '../../models/Medium';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';
import { addMediumToShelf, addMediumToWishList } from '../../services-api/mediumService';
import { Post } from '../../models/Post';
import whitebg from '../../assets/images/whitebg.svg';
import darkbg from '../../assets/images/darkbg.svg';
import WiltTitlePreview from '../../components/WiltTitlePreview/WiltTitlePreview';
import SummaryPreview from '../../components/SummaryPreview/SummaryPreview';
import Rating10 from '../../components/Rating10/Rating10';
import TabTitle from '../../components/TabTitle/TabTitle';
import PodcastEpisodes from './podcast-episodes/podcast-episodes';
import {useInfiniteQuery, useQuery} from "react-query";
import TemplatesTab from "./templates-tab/templates-tab";

const { TabPane } = Tabs;

function MediumScreen() {
  const { id, type, tab } = useParams<{ id: string, type: string, tab: string | undefined }>();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const medium = useSelector<StoreState, Medium>((state) => state.medium);
  const [podcastEpisodes, setPodcastEpisodes] = useState<any[]>([]);
  const shelfIds = useSelector<StoreState, { [key: string]: { typeText: string } }>((state) => state.shelfIds);
  const ref = useRef<any>(null);
  const dispatch = useDispatch();

  const { isFetching, error, data } = useQuery(`medium-${id}-screen`, () => axios.get(`/api/mediums/${id}/${currentUser._id}/${type}`)
      .then((response) => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data.shelfIds } });
        dispatch({ type: ActionTypes.SET_MEDIUM, medium: response.data.medium });
        if (response.data.medium._id) {
            getEpisodes(response.data.medium._id);
        }

        return response.data;
      }), { enabled: currentUser && medium?.external?.id !== id });

  const getEpisodes = (mediumId: string) => {
    if (mediumId) {
      axios.get(`/api/mediums/podcast-episodes/${mediumId}?page=${0}&limit=${3}`)
        .then((response) => {
          setPodcastEpisodes(response.data.episodes);
        }).catch((error) => {
          console.log('error: ', error);
        });
    }
  };

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current?.offsetTop);
  const myRef = useRef(null);

  useEffect(() => {
    scrollToRef(ref);
    if (medium?.typeValue === 1) {
      getEpisodes(medium._id);
    }

    return () => {
      dispatch({ type: ActionTypes.SET_MEDIUM, medium: null });
    };
  }, []);

  const onTabChange = () => {

  };

  const addToShelfAction = (medium: Medium, callback: Function) => {
    dispatch(addMediumToShelf(currentUser.username, medium, callback));
  };

  const addToWishListAction = (medium: Medium, callback: Function) => {
    dispatch(addMediumToWishList(currentUser.username, medium, callback));
  };

  const onPostClick = (item: any) => {
    console.log(item);
  };

  const displayCorrectPreviewCard = (item: Post) => {
    switch (item.typeValue) {
      case 0:
        return;
      case 1:
        return <WiltTitlePreview item={item} type="wilt" />;
      case 2:
        return <SummaryPreview item={item} />;
      case 4:
        return <WiltTitlePreview item={item} type="quiz" />;
      default:
    }
  };

  return (
    currentUser && `${medium?.external?.id}` === `${id}`
      ? (
        <div ref={myRef} className={styles.MediumScreen}>
          <GoBack />
          <div className={styles.header}>
            <MediumItem
              medium={medium}
              addToShelfAction={addToShelfAction}
              addToWishListAction={addToWishListAction}
              shelfIds={shelfIds}
            />
          </div>
          <div className={styles.ratingContainer}>
            <Rating10 isOnShelf={shelfIds[`${medium.external.id}`] !== undefined && shelfIds[`${medium.external.id}`]?.typeText !== 'wishlist'} medium={medium} />
          </div>
          <div className={styles.container}>
            <div className={styles.content}>
              <Tabs tabBarGutter={0} defaultActiveKey={tab || "sources"} onChange={onTabChange}>
                  {
                      medium.officialSite &&
                      <TabPane tab={<TabTitle text="Sources" />} key="sources">
                          {
                              medium.officialSite
                              && <Sources medium={medium} />
                          }
                      </TabPane>
                  }
                {
                  medium && medium.currentUserPosts && medium.currentUserPosts.filter((x) => x.typeValue !== 3).length > 0
                  && (
                  <TabPane
                    tab={`Your posts ${medium.currentUserPosts ? `(${medium.currentUserPosts.filter((x) => x.typeValue !== 3).length})` : ''}`}
                    key="Your posts"
                  >
                    <List
                      className={styles.postsList}
                      grid={{ gutter: 0, column: 3 }}
                      dataSource={medium && medium.currentUserPosts && medium.currentUserPosts.filter((x) => x.typeValue !== 3)}
                      renderItem={(item) => (
                        <List.Item className={styles.item} onClick={() => onPostClick(item)}>
                          <img
                            className={styles.post}
                            src={item.typeValue === 0 ? item.image?.replace('http://', 'https://') : localStorage.getItem('goodlores-theme') === "dark" ? darkbg : whitebg}
                            alt={item.typeValue === 0 ? item.image?.replace('http://', 'https://') : localStorage.getItem('goodlores-theme') === "dark" ? darkbg : whitebg}
                          />
                          {
                              displayCorrectPreviewCard(item)
                          }
                          {/* <div className={styles.postBackground} /> */}
                          {/* <div className={styles.postReactions}> */}
                          {/*    <Icon type="heart" theme="filled" /> */}
                          {/*    <span style={{ paddingLeft: '4px' }} >{item.likesCount}</span> */}
                          {/* </div> */}
                        </List.Item>
                      )}
                    />
                  </TabPane>
                  )
                }
                {
                  medium.typeValue === 1 && podcastEpisodes.length > 0 && medium._id
                  && (
                  <TabPane
                    tab={<TabTitle text="Episodes" />}
                    key="episodes"
                  >
                    <PodcastEpisodes mediumId={medium._id} />
                  </TabPane>
                  )
                }
                {
                    medium._id &&
                    <TabPane
                        tab={<TabTitle text="Templates" />}
                        key="templates"
                    >
                        <TemplatesTab mediumId={medium._id} />
                    </TabPane>
                }
              </Tabs>
            </div>
          </div>
        </div>
      )
      : (
        <div className={styles.MediumScreen}>
            <div className={styles.skeletonContainer}>
                <div className={styles.mediumSkeletonContainer}>
                    <Skeleton.Input className={styles.mediumSkeleton} active={true} />
                    <Skeleton className={styles.mediumTitleSkeleton} paragraph={{ rows: 6 }} active={true} />
                </div>
                <Skeleton.Input className={styles.ratingSkeleton} active={true} />
                <Skeleton.Input className={styles.tabsSkeleton} active={true} />
            </div>
        </div>
      )
  );
}

export default MediumScreen;
