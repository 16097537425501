import React from 'react';
import styles from './footer.module.scss';
import statLikeIcon from '../../../../../assets/icons/like.png';
import statHeartIcon from '../../../../../assets/icons/heart.png';
import statLolIcon from '../../../../../assets/icons/lol.png';
import commentIcon from '../../../../../assets/icons/icons8-topic-50.png';
import shareIcon from '../../../../../assets/icons/icons8-forward-arrow-50.png';
import likeIcon from '../../../../../assets/icons/icons8-facebook-like-50.png';

function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.reactionStats}>
        <div className={styles.leftStats}>
          <img
            className={styles.reactionIcon1}
            src={statLikeIcon}
            alt=""
          />
          <img
            className={styles.reactionIcon2}
            src={statHeartIcon}
            alt=""
          />
          <img
            className={styles.reactionIcon3}
            src={statLolIcon}
            alt=""
          />
          <div className={styles.reactionNumber}>
            1.5K
          </div>
        </div>
        <div className={styles.rightStats}>
          <img
            className={styles.commentIcon}
            src={commentIcon}
            alt=""
          />
          <div className={styles.commentNumber}>
            1.5K
          </div>
          <img
            className={styles.shareIcon}
            src={shareIcon}
            alt=""
          />
          <div className={styles.shareNumber}>
            1.5K
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.reactionButton}>
          <img
            src={likeIcon}
            alt=""
          />
        </div>
        {/* <div className={styles.commentButton}> */}
        {/*  <img */}
        {/*    src={commentIcon} */}
        {/*    alt="" */}
        {/*  /> */}
        {/* </div> */}
        <div className={styles.shareButton}>
          <img
            src={shareIcon}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
