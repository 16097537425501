import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Tag } from 'antd';
import styles from './RatingCard.module.scss';
import { Post } from '../../../models/Post';
import RatingItemHome from '../../../components/RatingItemHome/RatingItemHome';
import PostHeader from '../PostHeader/PostHeader';
import PostFooter from '../PostFooter/PostFooter';
import PostDivider from '../PostDivider/PostDivider';
import PostSource from '../PostSource/PostSource';
import RatingItemPodcastHome from '../../../components/RatingItemPodcastHome/RatingItemPodcastHome';
import PostPodcastSource from '../PostPodcastSource/PostPodcastSource';

interface RatingCardProps {
  post: Post;
  likePost: Function;
  unlikePost: Function;
  reactionIds: any;
  shelfIds: { [key: string]: string }
}

function RatingCard(props: RatingCardProps) {
  const {
    reactionIds, post, likePost, unlikePost, shelfIds,
  } = props;
  const {
    user, medium, createdAt, title, tags, hasSpoilers,
  } = post;
  const history = useHistory();
  const [isShowingMore, setIsShowingMore] = useState(!hasSpoilers);

  const onMediumClick = () => {
    history.push(`/mediums/${medium.external.id}/${medium.typeValue}`);
  };

  const onPodcastClick = () => {
    history.push(`/episodes/${medium._id}`);
  };

  return (
    <div key={post?._id} className={styles.RatingCard}>
      <PostHeader user={user} post={post} createdAt={createdAt} />
      {
            isShowingMore
              ? (
                <div className={styles.title}>
                  {title}
                  <div>
                    {
                      tags.map((tag) => <Tag color="blue" className={styles.tag}>{tag}</Tag>)
                    }
                  </div>
                </div>
              ) : (
                <div className={styles.title}>
                  <div className={styles.outer}>
                    <div className={styles.top}>
                      {title}
                      <div>
                        {
                           tags.map((tag) => <Tag className={styles.tag}>{tag}</Tag>)
                        }
                      </div>
                    </div>
                    <div className={styles.below}>
                      <div className={styles.belowTitle}>
                        Spoiler Alert!
                      </div>
                      <Button type="link" className={styles.belowButton} onClick={() => setIsShowingMore(!isShowingMore)}>
                        show more
                      </Button>
                    </div>
                  </div>
                </div>
              )
      }
      {
          medium.typeValue === 4
            ? <RatingItemPodcastHome item={post} />
            : <RatingItemHome item={post} />
      }
      {
          medium.typeValue === 4
            ? <PostPodcastSource medium={medium} shelfIds={shelfIds} onMediumClick={onPodcastClick} />
            : <PostSource medium={medium} shelfIds={shelfIds} onMediumClick={onMediumClick} />
      }
      <PostDivider padding="12px" />
      <PostFooter
        createdAt={createdAt}
        post={post}
        reactionIds={reactionIds}
        likePost={likePost}
        unlikePost={unlikePost}
      />
    </div>
  );
}

export default RatingCard;
