import { Dispatch } from 'redux';
import axios from 'axios';
import { CreateQuote } from '../models/create-models/CreateQuote';
import {CreateList} from "../models/create-models/create-list";
import {getCircularReplacer} from "../helpers/helpers";
import {parse, stringify} from 'flatted';

/* eslint-disable @typescript-eslint/no-unused-vars */
export const createPostQuote = (createQuote: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  const postData = JSON.stringify(createQuote);
  const formData = new FormData();
  formData.append('data', postData);
  formData.append('quote', createQuote.dataUrl);
  axios.post('/api/posts/quote', formData)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const createPostList = (createList: CreateList, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  // const postData = stringify(createList);
  // const formData = new FormData();
  // formData.append('data', postData);
  // formData.append('thumbnail', createList.dataUrl);
  // axios.post('/api/posts/list', formData)
  //     .then((response) => {
  //       callback();
  //     }).catch((error) => {
  //   console.log('error: ', error);
  // });
};

export const createDraftQuote = (createQuote: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  const postData = JSON.stringify(createQuote);
  const formData = new FormData();
  formData.append('data', postData);
  formData.append('quote', createQuote.dataUrl);
  axios.post('/api/drafts/quote', formData)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateDraftQuote = (createQuote: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  const postData = JSON.stringify(createQuote);
  const formData = new FormData();
  formData.append('data', postData);
  formData.append('quote', createQuote.dataUrl);
  axios.put('/api/drafts/quote', formData)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const createWilt = (wilt: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/posts/wilt', { wilt })
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const createDraftWilt = (wilt: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/drafts/wilt', { wilt })
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateDraftWilt = (wilt: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put('/api/drafts/wilt', { wilt })
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const createSummary = (summary: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/posts/summary', { summary })
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const createDraftSummary = (summary: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/drafts/summary', { summary })
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateDraftSummary = (summary: CreateQuote, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put('/api/drafts/summary', { summary })
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const deletePost = (postId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/posts/${postId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error);
      callback(false);
    });
};

export const deleteDraft = (postId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/drafts/${postId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error);
      callback(false);
    });
};
