import React, { useState } from 'react';
import { Popover } from 'antd';
import {
  LikeFilled, LikeOutlined, HeartFilled, HeartOutlined,
} from '@ant-design/icons';
import styles from './PostFooter.module.scss';
import PostDivider from '../PostDivider/PostDivider';
import { Reaction } from '../../../models/Reaction';
import newLike from '../../../assets/icons/new-like.png';
import likeIcon from '../../../assets/icons/icons8-facebook-like-50.png';
import { convertReactionTextToValue } from '../../../helpers/helpers';
import { Post } from '../../../models/Post';
import newLolCircle from '../../../assets/icons/new-lol-circle.png';
import newHeartCircle from '../../../assets/icons/new-heart-circle.png';
import newLikeCircle from '../../../assets/icons/new-like-circle.png';
import newHeart from '../../../assets/icons/new-heart.png';

interface PostFooterProps {
  createdAt: string;
  likePost: Function;
  unlikePost: Function;
  post: Post;
  reactionIds: any;
}
function PostFooter(props: PostFooterProps) {
  const {
    reactionIds,
    unlikePost, likePost, post,
  } = props;
  const { likesCount, loveCount, lolCount } = post;

  const [liked, setLiked] = useState(reactionIds[`${post._id}`] !== undefined);
  const [reaction, setReaction] = useState(reactionIds[`${post._id}`]);
  const [tempLikesCount, setTempLikesCount] = useState(likesCount);
  const [tempLoveCount, setTempLoveCount] = useState(loveCount);
  const [tempLolCount, setTempLolCount] = useState(lolCount);
  const [totalCount, setTotalCount] = useState(likesCount + loveCount + lolCount);
  const [visible, setVisible] = useState(false);

  const onReactionClick = (type: string) => {
    setVisible(false);
    likeOrUnlikeAction(type);
  };

  const likeOrUnlikeAction = (typeText: string) => {
    if (liked) {
      unlikePost(post, () => {
        const typeValue = convertReactionTextToValue(typeText);
        if (typeValue === 0) {
          setTempLikesCount(tempLikesCount - 1);
        } else if (typeValue === 1) {
          setTempLoveCount(tempLoveCount - 1);
        } else if (typeValue === 2) {
          setTempLolCount(tempLolCount - 1);
        }
        setLiked(false);
        setTotalCount(totalCount - 1);
      });
    } else {
      likePost(post, typeText, () => {
        const typeValue = convertReactionTextToValue(typeText);
        const newReaction: Reaction = { typeText, typeValue, postId: post._id };
        setReaction(newReaction);

        if (typeValue === 0) {
          setTempLikesCount(tempLikesCount + 1);
        } else if (typeValue === 1) {
          setTempLoveCount(tempLoveCount + 1);
        } else if (typeValue === 2) {
          setTempLolCount(tempLolCount + 1);
        }

        setLiked(true);
        setTotalCount(totalCount + 1);
      });
    }
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const content = (
    <div className={styles.reactionsIcons}>
      <img
        className={`${styles.likedIcon} ${styles.popoverLikeIcon}`}
        src={newLike}
        onClick={() => onReactionClick('like')}
      />
      <HeartFilled
        className={`${styles.heartIcon} ${styles.popoverHeartIcon}`}
        type="heart"
        onClick={() => onReactionClick('love')}
      />
      <span
        role="img"
        aria-label="lol"
        className={styles.popoverLolIcon}
        onClick={() => onReactionClick('lol')}
      >
        😂
      </span>
    </div>
  );

  const renderReaction = (type: string) => {
    switch (type) {
      case 'like':
        if (liked) {
          return (
            <img
              className={styles.reactionImage}
              src={newLike}
              onClick={() => likeOrUnlikeAction('like')}
            />
          );
        }
        break;

      case 'love':

        if (liked) {
          return (
            <img
              className={styles.reactionImage}
              src={newHeart}
              onClick={() => likeOrUnlikeAction('love')}
            />
          );
        }
        break;

      case 'lol':
        return (
          <img
            className={styles.reactionImage}
            src={newLolCircle}
            onClick={() => likeOrUnlikeAction('lol')}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div className={styles.PostFooter}>
      <div className={styles.actions}>
        <Popover
          className={styles.popoverContainer}
          placement="topLeft"
          visible={visible}
          content={content}
          onVisibleChange={handleVisibleChange}
          trigger={liked && reaction ? 'contextMenu' : 'click'}
        >
          {
              liked && reaction
                ? renderReaction(reaction.typeText) : (
                  <img
                    className={styles.defaultLikeIcon}
                    src={likeIcon}
                  />
                )
          }
        </Popover>
        {
          totalCount !== 0
          && (
          <div className={styles.reactions}>
            {
              tempLikesCount > 0
              && (
              <img
                className={styles.miniReactionImage}
                src={newLikeCircle}
                alt=""
              />
              )
            }
            {
                tempLoveCount > 0
                && (
                <img
                  className={styles.miniReactionImage}
                  src={newHeartCircle}
                  alt=""
                />
                )
            }
            {
                  tempLolCount > 0
                  && (
                  <img
                    className={styles.miniReactionImage}
                    src={newLolCircle}
                    alt=""
                  />
                  )
                }
            <div className={styles.reactionCount}>
              {totalCount}
            </div>
          </div>
          )
        }
      </div>
    </div>
  );
}

export default PostFooter;
