import React, { useEffect, useState } from 'react';
import { queryCache, useInfiniteQuery } from 'react-query';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton, Spin } from 'antd';
import styles from './drafts-grid.module.scss';
import EditGrid from '../../../components/EditGrid/EditGrid';
import { Post } from '../../../models/Post';
import PostGridCard from '../../profile-screen/profile-posts-grid/post-grid-card/post-grid-card';
import Empty from '../../../components/Empty/Empty';

type DraftsGrid = {
  isEditing: boolean;
  isCurrentUser: boolean;
  onEditingClick: () => void;
  onRemovePostClick: (post: Post, callback: (id: string) => void) => void;
  onPostClick: (post: Post) => void;
};

function DraftsGrid(props: DraftsGrid) {
  const {
    isEditing, isCurrentUser, onEditingClick, onRemovePostClick, onPostClick,
  } = props;
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(15);
  const [width, setWidth] = useState(window.innerWidth);

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    'drafts-screen',
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/drafts?limit=${limit}&page=${nextId}&timeStamp=${timeStamp}`);
      if (data?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);
  }, [data]);

  const tempOnRemovePostClick = (post: Post) => {
    onRemovePostClick(post, (postId) => {
      queryCache.setQueryData('drafts-screen', (oldData) =>
        // @ts-ignore
        // eslint-disable-next-line implicit-arrow-linebreak
        oldData.map((x: any) => ([...x.filter((x: any) => x._id !== postId)])));
    });
  };

  const triggerSortMediums = (updatedSortBy: string) => {
    setIsAtTheEnd(false);
  };

  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className={styles.DraftsGrid}>
      <EditGrid isEditing={isEditing} mediumType="posts" isCurrentUser={isCurrentUser} triggerSort={triggerSortMediums} onEditingClick={onEditingClick} />
      {
        data?.flat().length === 0
          ? (
            <div className={styles.emptyView}>
              <Empty
                title="Create your first post"
                subTitle="Create awesome looking posts"
                icon="plus"
                url="/create"
                buttonTitle="Create post"
                screen="posts"
              />
            </div>
        )
          : (
            <InfiniteScroll
              className={styles.gridList}
              next={() => fetchMore()}
              hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
              loader={(
                <div className={styles.spinningContainer}>
                  <Spin />
                </div>
                )}
              dataLength={data?.length || 0}
            >
              {
                isFetching && !data && [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item: any, index: number) => (
                  <div key={`skel-${index}`} className={styles.skeletonItem}>
                    <Skeleton
                      className={styles.imageContainer}
                      active
                      title={{ style: { height: width > 800 ? '264px' : width * 0.5, width: 'auto !important' } }}
                      paragraph={{ rows: 0 }}
                    />
                  </div>
                ))
              }
              {
                data?.flat().length !== 0 && data?.flat().map((item: any) => (
                  <PostGridCard
                    key={item._id}
                    isEditing={isEditing}
                    onRemovePostClick={tempOnRemovePostClick}
                    item={item}
                    onPostClick={onPostClick}
                  />
                ))
              }
            </InfiniteScroll>
)
      }
    </div>
  );
}

export default DraftsGrid;
