import React from 'react';
import styles from './loudness-card.module.scss';
import Footer from '../footer/footer';
import soundIcon from '../../../../../assets/icons/icons8-sound-50.png';
import Header from '../header/header';

function LoudnessCard() {
  return (
    <div className={styles.LoudnessCard}>
      <Header image={soundIcon} title="Loudness" minute="15th min" />
      <div className={styles.body}>
        <strong>Baylor University</strong>
        {' '}
        fans are the
        {' '}
        <strong>loudest</strong>
        {' '}
        in the stadium
      </div>
      <Footer />
    </div>
  );
}

export default LoudnessCard;
