import React from 'react';
import {
  Collapse, Form, Input, Button, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styles from './quiz-info.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';
import { CreateQuiz } from '../../../models/create-models/CreateQuiz';
import { ActionTypes } from '../../../actions/types';
import { Answer, Question } from '../../../models/create-models/Question';

const { Panel } = Collapse;
const { Option } = Select;
function QuizInfo() {
  const createQuiz = useSelector<StoreState, CreateQuiz>((state) => state.createQuiz);
  const quizCounter = useSelector<StoreState, number>((state) => state.createQuizCounter);
  const quizAnswersCounter = useSelector<StoreState, { [key: string]: number }>((state) => state.createQuizAnswersCounter);
  const dispatch = useDispatch();

  const onAddQuestionClick = () => {
    const newQuestion: Question = {
      id: `${quizCounter}`,
      question: `Question ${quizCounter}`,
      answers: [],
    };

    const updatedQuiz = { ...createQuiz, questions: [...createQuiz?.questions, newQuestion] };
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ_COUNTER, value: quizCounter + 1 });
  };

  const onAddOptionClick = (question: Question) => {
    const updatedQuiz = {
      ...createQuiz,
      questions: [...createQuiz?.questions.map((quest, index) => {
        if (quest.id === question.id) {
          const newAnswer: Answer = {
            id: `${quizAnswersCounter[question.id] ? quizAnswersCounter[question.id] + 1 : 1}`,
            text: `Option ${quizAnswersCounter[question.id] ? quizAnswersCounter[question.id] + 1 : 1}`,
          };
          return { ...quest, answers: [...quest.answers, newAnswer] };
        }
        return quest;
      })],
    };
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ_ANSWERS_COUNTER, value: { ...quizAnswersCounter, [`${question.id}`]: quizAnswersCounter[question.id] ? quizAnswersCounter[question.id] + 1 : 1 } });
  };

  const onOptionChange = (e: any, question: Question, answer: Answer) => {
    const updatedQuiz = {
      ...createQuiz,
      questions: [...createQuiz?.questions.map((quest, questIndex) => {
        if (quest.id === question.id) {
          return {
            ...quest,
            answers: [...quest.answers.map((ans, ansIndex) => {
              if (ans.id === answer.id) {
                return { ...ans, text: e.target.value };
              }
              return ans;
            })],
          };
        }
        return quest;
      })],
    };
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
  };

  const onQuestionChange = (e: any, question: Question) => {
    const updatedQuiz = {
      ...createQuiz,
      questions: createQuiz.questions.map((val) => {
        if (val.id === question.id) {
          return { ...val, question: e.target.value };
        }
        return val;
      }),
    };
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
  };

  const onAnswerChange = (data: any, question: Question) => {
    const updatedQuiz = {
      ...createQuiz,
      questions: createQuiz.questions.map((val) => {
        if (val.id === question.id) {
          return { ...val, correctAnswerId: `${data}`, correctAnswer: question.answers.find((x) => x.id === `${data}`) };
        }
        return val;
      }),
    };
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
  };

  const onQuestionTitleChange = (data: any) => {
    const updatedQuiz = {
      ...createQuiz,
      title: data.target.value,
    };
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
  };

  const onSelectChange = (value: string) => {
    const updatedQuiz = {
      ...createQuiz,
      visibility: value,
    };
    dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: updatedQuiz });
  };

  return (
    <div className={styles.QuizInfo}>
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: 'Please input a quiz title!' }]}
      >
        <Input
          size="large"
          allowClear
          onChange={onQuestionTitleChange}
        />
      </Form.Item>
      <Form.Item
          label="Visibility"
          name="visibility"
          initialValue="public"
          rules={[{ required: true, message: 'Please select visibility!' }]}
      >
        <Select size="large" defaultValue="public" onSelect={onSelectChange}>
          <Select.Option value="public">Public (Anyone)</Select.Option>
          <Select.Option value="private">Private (My followers)</Select.Option>
        </Select>
      </Form.Item>
    </div>
  );
}

export default QuizInfo;
