import React from 'react';
import styles from './list-stories.module.scss';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {CreateList} from "../../../models/create-models/create-list";
import {Typography} from "antd";

const { Paragraph } = Typography;
function ListStories() {
    const createList = useSelector<StoreState, CreateList>((state) => state.createList);
    return (
        <div className={styles.ListStories}>
            {
                createList.stories.map((item, index) => {
                    return (<div className={styles.previewItem} key={`${item.title}`}>
                        <img
                            className={styles.image}
                            src={item.imageUrl}
                        />
                        <div className={styles.title}>
                            {item.title}
                        </div>
                        <Paragraph className={styles.description} ellipsis={{ rows: 5, expandable: false, symbol: '...' }}>
                            {item.description}
                        </Paragraph>
                    </div>)
                })
            }
            {/*<img*/}
            {/*    className={styles.preview}*/}
            {/*    src={createList.blob ? URL.createObjectURL(createList.blob) : 'createQuote.image'}*/}
            {/*    alt={createList.blob ? URL.createObjectURL(createList.blob) : 'createQuote.image'}*/}
            {/*/>*/}
        </div>
    );
}

export default ListStories;