import {Menu} from "antd";
import {Link} from "react-router-dom";
import cookies from 'js-cookie';
import React from "react";

const onLogoutAction = () => {
    cookies.remove('goodlores-token');
    window.location.reload();
};

const ProfileMenu = (username: string) => (
    <Menu>
        <Menu.Item>
            {" "}
            <Link to={"/profile/"+username}>
                Profile
            </Link>
            {" "}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={onLogoutAction}>
            {" "}
            <Link to="/" >
                Logout
            </Link>
            {" "}
        </Menu.Item>
    </Menu>
);

export default ProfileMenu;
