import React from 'react';
import styles from './TemplatesItem.module.scss';
import Classic from "../Classic/Classic";
import Highlights from "../Highlights/Highlights";
import {TemplatePreview} from "../../../../models/TemplatePreview";
import BackgroundText from "../BackgroundText/BackgroundText";


interface TemplatesItemProps {
    template: TemplatePreview;
    onTemplateSelect: Function;
    active: boolean;
}

function TemplatesItem(props: TemplatesItemProps) {
    const { onTemplateSelect: propsOnTemplateSelect, template } = props;

    const onTemplateSelect = () => {
        propsOnTemplateSelect(template);
    };

    const renderCorrectTemplate = (templateId: string) => {
        if (templateId === 'classic') {
            return <Classic/>
        } else if (templateId === 'highlights') {
            return <Highlights/>
        } else if (templateId === 'backgroundText') {
            return <BackgroundText />
        } else {
            return <div/>
        }
    };

    return (
        <div className={styles.TemplatesItem} onClick={onTemplateSelect} >
            <div className={`${styles.container} ${props.active && styles.active}`}>
                <div className={styles.title} >
                    {template.title}
                </div>
                <div className={styles.preview}>
                    <div className={styles.previewContainer} >
                        {
                            renderCorrectTemplate(template.id)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemplatesItem;
