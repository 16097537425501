import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Button, Input, Modal, Spin, Skeleton, Menu, Dropdown, Form, Image,
} from 'antd';
import {
  SendOutlined, ArrowLeftOutlined, InfoCircleOutlined,
  MessageOutlined,
  PlusOutlined,
  FileImageOutlined,
  CameraOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { queryCache, useInfiniteQuery, useQuery } from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Cropper from 'react-easy-crop';
import ReactCrop from 'react-image-crop';
import styles from './chat-screen.module.scss';
import ChatCard from '../MessagesScreen/ChatCard/ChatCard';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';
import ChatTimestampCard from '../../components/Cards/chat-timestamp-card/chat-timestamp-card';
import { fallBackUrl } from '../../utils/imageData';

const { TextArea } = Input;
function ChatScreen() {
  const { id, info } = useParams<{ id: string, info: string }>();
  const { pathname } = useLocation();
  let globalFileInput: any;
  const [file, setFile] = useState<Blob | null>(null);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentUser = useSelector<StoreState, User | null>((state) => state.currentUser);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const [chatRoom, setChatRoom] = useState<String>('');
  const [message, setMessage] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [cropData, setCropData] = useState({
    image: 'your-image-url or as base64',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
  });
  const [cropD, setCropD] = useState<any>({
    src: null,
    crop: {
      aspect: 16 / 9,
    },
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading: isChatUserLoading, error: chatUserError, data: currentChatUser } = useQuery(`chat-user-${id}`, () => axios.get(`/api/users/${id}`)
    .then((response) => {
      dispatch({ type: ActionTypes.SET_CURRENT_CHAT_USER, value: response.data });
      return response.data;
    }));

  useEffect(() => () => {
    dispatch({ type: ActionTypes.SET_CURRENT_CHAT_USER, value: null });
    dispatch({ type: ActionTypes.SET_CURRENT_CHAT_ROOM, value: null });
  }, []);

  const onInputChange = (data: any) => {
    setMessage(data.target.value);
  };

  const onSendClick = () => {
    if (chatRoom) {
      const data = new FormData();

      data.append('chatRoom', chatRoom as string);

      if (message) {
        data.append('message', message);
      }

      if (file != null) {
        data.append('avatar', file, 'temp');
      }

      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      axios.post('/api/chats/messages', data)
        .then((response) => {
          const newMessage = response.data;
          newMessage.user = currentUser;
          queryCache.setQueryData(`chat-${id}-screen`, (oldData) =>
          // @ts-ignore
            oldData.map((x) => ({ ...x, messages: [newMessage, ...x.messages] })));
          dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
          if (message) {
            setMessage('');
          }
          setFile(null);
        }).catch((error) => {
          console.log('error: ', error.response);
        });
    }
  };

  const onBackClick = () => {
    history.replace('/messages');
  };

  const updateRead = (chatRoomId: string, messages: string[]) => {
    axios.put(`/api/chats/reads/${chatRoomId}`, { messages })
      .then((response) => {
      });
  };

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `chat-${id}-screen`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/chats/private/${id}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      if (data?.messages?.length > 0) {
        updateRead(data?.chatRoomId, data?.messages?.map((m: any) => m._id));
      }

      if (!chatRoom && data.chatRoomId) {
        setChatRoom(data.chatRoomId);
      }

      if (data?.messages?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onMoreClick = () => {

  };

  const renderChatMessages = () => {
    const chatMessages = data?.map((page) => page.messages).flat();
    return chatMessages?.map((message, index) => (
      <div key={`chat-card-${index}`} className={styles.item}>
        <ChatTimestampCard
          previousCreatedAt={index !== 0 && chatMessages[index - 1].createdAt}
          currentCreatedAt={message.createdAt}
          nextCreatedAt={index !== chatMessages?.length - 1 && chatMessages[index + 1].createdAt}
        />
        <ChatCard
          currentUserId={currentUser!._id}
          previousMessage={index !== 0 && chatMessages[index - 1]}
          message={message}
          nextMessage={index !== chatMessages?.length - 1 && chatMessages[index + 1]}
          otherUserImage={currentChatUser?.image}
          onMoreClick={onMoreClick}
        />
      </div>
    ));
  };

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsImageModalOpen(true);
      setFile(event.target.files[0]);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item className={styles.uploadOptionItem}>
        <CameraOutlined className={styles.uploadIcon} />
        <div className={styles.uploadText}>
          Camera
        </div>
      </Menu.Item>
      <Menu.Item className={styles.uploadOptionItem} onClick={() => globalFileInput.click()}>
        <FileImageOutlined className={styles.uploadIcon} />
        <div className={styles.uploadText}>
          <input
            style={{ display: 'none' }}
            type="file"
            name="avatar"
            onChange={fileSelectedHandler}
            ref={(fileInput) => globalFileInput = fileInput}
            accept=".jpeg,.png"
          />
          Photo
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleOk = () => {

  };

  const handleCancel = () => {

  };

  const onCropChange = (crop: any) => {
    setCropD({ ...cropD, crop });
  };

  const onCancel = () => {
    setFile(null);
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    console.log(croppedArea, croppedAreaPixels);
  };

  const onZoomChange = (zoom: any) => {
    setCropData({ ...cropData, zoom });
  };

  const onImageLoaded = (image: any) => {
    // this.imageRef = image;
  };

  return (currentUser
    && (
    <div className={styles.ChatScreen}>
      <Modal
        title={<div style={{ padding: '16px' }}>Upload Image</div>}
        style={{ overflow: 'hidden' }}
        bodyStyle={{ padding: '0px' }}
        visible={!!file}
        closable
        maskClosable
        onCancel={onCancel}
        centered
        footer={(
          <div className={styles.modalFooter}>
            <TextArea
              className={styles.textArea}
              autoSize={{ minRows: 1, maxRows: 5 }}
              placeholder="Type here..."
              onChange={onInputChange}
              value={message}
              disabled={isLoading}
            />
            <Button
              icon={<SendOutlined className={styles.sendButton} />}
              type="link"
              onClick={onSendClick}
              loading={isLoading}
            />
          </div>
        )}
      >
        <div className={styles.imageUploadPreview}>
          {
                file
                  ? (
                // <img
                //   className={styles.image}
                //   src={file ? URL.createObjectURL(file) : ''}
                //   //alt={currentUser.image}
                // />
                    <ReactCrop
                      className={styles.image}
                      src={URL.createObjectURL(file)}
                      imageStyle={{ width: '100%' }}
                      crop={cropD.crop}
                      ruleOfThirds
                      onImageLoaded={onImageLoaded}
                      onComplete={onCropComplete}
                      onChange={onCropChange}
                    />
                  )
                  : <Spin size="large" />
            }
        </div>
      </Modal>
      <div className={styles.chatHeader}>
        <div className={styles.back}>
          <Button
            size="large"
            icon={<ArrowLeftOutlined />}
            shape="circle"
            onClick={onBackClick}
          />
        </div>
        {
          currentChatUser
            ? (
              <div className={styles.content}>
                <Image
                  className={styles.image}
                  src={currentChatUser?.image}
                  alt={currentChatUser?.image}
                  preview={false}
                  fallback={fallBackUrl}
                />
                <div className={styles.username}>
                  {currentChatUser?.username}
                </div>
              </div>
            )
            : (
              <div className={styles.content}>
                <Skeleton.Avatar active size="large" shape="circle" />
                <Skeleton.Input style={{ marginLeft: '16px', width: 200 }} active size="large" />
              </div>
            )
        }
      </div>
      <div className={styles.container}>
        <div
          id="scrollableDiv"
          className={styles.messagesContainer}
        >
          <InfiniteScroll
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            dataLength={data?.map((page) => page.messages).flat().length || 0}
            next={() => fetchMore()}
            hasMore={(data?.map((page) => page.messages).flat().length || 0) > 0 && !isAtTheEnd}
            loader={(
              <div className={styles.spinningContainer}>
                <Spin />
              </div>
              )}
            endMessage={(<p style={{ textAlign: 'center' }} />)}
            inverse
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.3}
          >
            {
              renderChatMessages()
            }
          </InfiniteScroll>
        </div>
        <div className={styles.inputContainer}>
          <Dropdown overlay={menu} placement="topLeft" trigger={['click']}>
            <PlusOutlined className={styles.addButton} />
          </Dropdown>
          <TextArea
            className={styles.textArea}
            autoSize={{ minRows: 1, maxRows: 5 }}
            placeholder="Type here..."
            onChange={onInputChange}
            value={message}
            disabled={isLoading}
          />
          <Button
            icon={<SendOutlined className={styles.sendButton} />}
            type="link"
            onClick={onSendClick}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
    )
  );
}

export default ChatScreen;
