import React, {useState} from 'react';
import {Button, InputNumber, Modal, Slider, Checkbox} from "antd";
import styles from "./FontModalSource.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {ActionTypes} from "../../../actions/types";

interface FontModalProps {
    callback: Function;
}

function FontModalSource(props: FontModalProps) {

    const fontModalDataSource = useSelector<StoreState, any>(state => state.fontModalDataSource);
    const defaultList = fontModalDataSource.isBold ? fontModalDataSource.isItalic ? ['Bold','Italic'] : ['Bold'] : fontModalDataSource.isItalic ? ['Italic']: [];
    const [selectedOption, setSelectedOptions] = useState<string[]>([]);
    const isFontModalSourceVisible = useSelector<StoreState, boolean>(state => state.isFontModalSourceVisible);
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch({ type: ActionTypes.TOGGLE_FONT_MODAL_SOURCE });
    };

    const onBoldnessChange = (data: any[]) => {
        setSelectedOptions(data);
    };

    const onSaveClick = () => {
        props.callback(fontModalDataSource.fontSize, selectedOption.includes('Bold'), selectedOption.includes('Italic'));
        handleCancel();
    };

    const onFontSizeChange = (data: any) => {
        dispatch({ type: ActionTypes.SET_FONT_MODAL_DATA_SOURCE, data: {...fontModalDataSource, fontSize: data as number} } );
    };

    const plainOptions = ['Bold', 'Italic'];

    return (
        <Modal
            visible={isFontModalSourceVisible}
            onCancel={handleCancel}
            bodyStyle={{padding: '0px !important'}}
            closable={false}
            centered={true}
            title={null}
            footer={null}
        >
            <div className={styles.FontModalSource}>
                <div className={styles.fontTitle}>
                    Font
                </div>
                <div className={styles.fontContainer}>
                    <Slider
                        className={styles.fontSlider}
                        min={1}
                        max={30}
                        onChange={onFontSizeChange}
                        defaultValue={parseInt(fontModalDataSource.fontSize)}
                        value={parseInt(fontModalDataSource.fontSize)}
                    />
                    <InputNumber
                        min={1}
                        max={30}
                        style={{ marginLeft: 16 }}
                        onChange={onFontSizeChange}
                        defaultValue={parseInt(fontModalDataSource.fontSize)}
                        value={parseInt(fontModalDataSource.fontSize)}
                        size="large"
                    />
                </div>
                <Checkbox.Group
                    className={styles.otherOptions}
                    options={plainOptions}
                    defaultValue={defaultList}
                    value={selectedOption}
                    onChange={onBoldnessChange}
                />
                <div className={styles.actions}>
                    <Button className={styles.saveButton} type="primary" onClick={onSaveClick} >Save</Button>
                </div>
            </div>
        </Modal>
    );
}

export default FontModalSource;
