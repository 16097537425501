import React from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import styles from './top-stand.module.scss';
import statLikeIcon from '../../../assets/icons/like.png';
import statHeartIcon from '../../../assets/icons/heart.png';
import statLolIcon from '../../../assets/icons/lol.png';
import commentIcon from '../../../assets/icons/icons8-topic-50.png';
import shareIcon from '../../../assets/icons/icons8-forward-arrow-50.png';
import likeIcon from '../../../assets/icons/icons8-facebook-like-50.png';
import subIcon from '../../../assets/icons/icons8-replace-50.png';
import dummyData from './dummyData.json';
import baylor from '../../../assets/images/baylor.png';
import elon from '../../../assets/images/elon.jpeg';
import OfficialSubCard from '../official-sub-card/official-sub-card';
import OfficialCardCard from '../official-card-card/official-card-card';
import OfficialGoalCard from '../official-goal-card/official-goal-card';

const { Paragraph } = Typography;
function TopStand() {
  const onBackAction = () => {

  };

  const history = useHistory();
  const onSearch = () => {

  };

  const onStandClick = () => {
    history.push('/stands/123');
  };

  return (
    <div className={styles.TopStand}>
      <div className={styles.container}>
        <div className={styles.overviewCards}>
          {
            dummyData.map((item, index) => {
              if (item.timeline === 'goal') {
                return (
                  <OfficialGoalCard />
                );
              }
              if (item.timeline === 'substitution') {
                return (
                  <OfficialSubCard />
                );
              }
              if (item.timeline === 'card') {
                return (
                  <OfficialCardCard type={item.card!} />
                );
              }
              return (
                <div
                  key={`${index}-wow`}
                  className={styles.standOverviewCard}
                  onClick={onStandClick}
                >
                  <div className={styles.imageContainer}>
                    <img
                      className={styles.image}
                      src={`https://picsum.photos/100/100?random=${index}`}
                    />
                    <img
                      className={styles.reactionImage}
                      src={likeIcon}
                    />
                  </div>
                  <div className={styles.textInfo}>
                    <div className={styles.titleContainer}>
                      <Paragraph
                        ellipsis={{ rows: 1, expandable: false, suffix: '  @username · 8h' }}
                      >
                        <span className={styles.title}>{`DisplayName ${index + 10}`}</span>
                      </Paragraph>
                    </div>
                    <div className={styles.postContainer}>
                      <div className={styles.postText}>
                        {item.text}
                      </div>
                      {
                        item.image
                        && (
                        <img
                          className={styles.postImage}
                          src={item.image}
                          alt=""
                        />
                        )
                      }
                      <div className={styles.footer}>
                        <div className={styles.leftStats}>
                          <img
                            className={styles.reactionIcon1}
                            src={statLikeIcon}
                            alt=""
                          />
                          <img
                            className={styles.reactionIcon2}
                            src={statHeartIcon}
                            alt=""
                          />
                          <img
                            className={styles.reactionIcon3}
                            src={statLolIcon}
                            alt=""
                          />
                          <div className={styles.reactionNumber}>
                            1.5K
                          </div>
                        </div>
                        <div className={styles.rightStats}>
                          <img
                            className={styles.commentIcon}
                            src={commentIcon}
                            alt=""
                          />
                          <div className={styles.commentNumber}>
                            1.5K
                          </div>
                          <img
                            className={styles.shareIcon}
                            src={shareIcon}
                            alt=""
                          />
                          <div className={styles.shareNumber}>
                            1.5K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
                    }
        </div>
      </div>
    </div>
  );
}

export default TopStand;
