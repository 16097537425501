import React from 'react';
import styles from "./DoneCard.module.scss";
import {Button} from "antd";
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {User} from "../../../models/User";
import {ActionTypes} from "../../../actions/types";

interface DoneCardProps {

}

function DoneCard(props: DoneCardProps) {
    const currentUser = useSelector<StoreState, User >(state => state.currentUser);
    const history = useHistory();
    const dispatch = useDispatch();

    const onFindFriendsClick = () => {
        dispatch({ type: ActionTypes.SET_INTEREST_MODAL_VISIBILITY, value: false});
        history.replace("/search")
    };

    const onViewShelvesClick = () => {
        dispatch({ type: ActionTypes.SET_INTEREST_MODAL_VISIBILITY, value: false});
        history.replace("/profile/"+currentUser.username);
    };

    return (
        <div className={styles.DoneCard}>
            <div className={styles.container}>
                <div className={styles.title}>
                    🎉 Congrats! 🎉
                </div>
                <div className={styles.subtitle}>
                    You have successfully completed your profile setup
                </div>
                <div className={styles.actions}>
                    <div>
                        <Button
                            size="large"
                            type={"primary"}
                            className={styles.doneButton}
                            onClick={onFindFriendsClick}
                        >
                            Find your friends
                        </Button>
                    </div>
                    <Button
                        size="large"
                        type={"primary"}
                        className={styles.doneButton}
                        onClick={onViewShelvesClick}
                    >
                        View your shelves
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default DoneCard;
