import React, { useEffect } from 'react';
import styles from './ranking-grid.module.scss';
import lightRect from '../../../assets/images/120.svg';
import darkRect from '../../../assets/images/120-dark.svg';
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Skeleton} from "antd";

type RankingGridProps = {
  mediums: any[];
  onMediumClick: (medium: any) => void;
  type: string;
  username: string;
  isLoading: boolean;
};

function RankingGrid(props: RankingGridProps) {
  const {
    mediums, onMediumClick, type, username, isLoading,
  } = props;

  useEffect(() => {
  }, [mediums]);

  return (
    <div className={styles.RankingGrid}>
      {
        isLoading ?
            <div className={styles.list}>
              {
                [1, 1, 1, 1, 1, 1].map((item: any, index: number) => (
                    <div key={`skel-${index}`} className={styles.item}>
                      <Skeleton.Input className={styles.tabsSkeleton} active={true} />
                    </div>
                ))
              }
            </div>
            :
            <div className={styles.list}>
              {
                mediums?.length > 0 && mediums[0]?.ranking && Object.values(mediums[0]?.ranking).length > 0 ? Object.values(mediums[0]?.ranking || {}).map((item: any, index: number) => (
                        <div role="button" tabIndex={0} key={`i-${index}`} className={styles.item} onClick={() => onMediumClick(item)}>
                          <img
                              className={styles.image}
                              src={localStorage.getItem('goodlores-theme') === "dark" ? darkRect : lightRect}
                              alt={localStorage.getItem('goodlores-theme') === "dark" ? darkRect : lightRect}
                          />
                          <div className={styles.content}>
                            <div className={styles.number}>
                              {index + 1}
                            </div>
                            <div className={styles.mediumContainer} />
                          </div>
                          <div className={styles.imageContent}>
                            <div className={styles.number} />
                            <div className={styles.mediumContainer}>
                              <img
                                  //effect="blur"
                                  className={styles.mediumImage}
                                  src={item.imageUrl}
                                  alt={item.imageUrl}
                              />
                            </div>
                          </div>
                        </div>
                    ))
                    : (
                        <div className={styles.emptyView}>
                          <div style={{ textAlign: 'center' }}>
                            <h3>
                              @
                              {username}
                              {' '}
                              hasn’t ranked any
                              {' '}
                              {type}
                            </h3>
                            <div>
                              When they do, those
                              {' '}
                              {type}
                              {' '}
                              will show up here.
                            </div>
                          </div>
                        </div>
                    )
              }
            </div>
      }
    </div>
  );
}

export default RankingGrid;
