import React from 'react';
import styles from './SourceCard.module.scss';
import { Medium } from '../../../models/Medium';

interface SourceCardProps {
  medium: Medium;
}

function SourceCard(props:SourceCardProps) {
  const { medium } = props;
  const onSourceClick = () => {
    const win = window.open(medium.officialSite, '_blank');
    if (win) {
      win.focus();
    }
  };

  return (
    <div role="button" tabIndex={0} className={styles.SourceCard} onClick={onSourceClick}>
      {
          (medium.typeValue === 0 || medium.typeValue === 1)
          && (
          <img
            className={styles.sourceImage}
            src="https://pbs.twimg.com/profile_images/1138486130968412161/N7S-X7UO_400x400.jpg"
            alt="https://pbs.twimg.com/profile_images/1138486130968412161/N7S-X7UO_400x400.jpg"
          />
          )
            }
      <div className={styles.sourceLink}>{medium.title}</div>
    </div>
  );
}

export default SourceCard;
