import React, { useState } from 'react';
import {
  AutoComplete, Badge, Button, Dropdown, Image, Input, Select, Skeleton,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, MenuOutlined } from '@ant-design/icons';
import axios from 'axios';
import styles from './NavBar.module.scss';
import Logo from '../Logo/Logo';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';
import { CreateQuote } from '../../models/create-models/CreateQuote';
import { fallBackUrl } from '../../utils/imageData';
import { Medium } from '../../models/Medium';

const { Option, OptGroup } = Select;
function NavBar() {
  const location = useLocation();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const unreadChats = useSelector<StoreState, number>((state) => state.unreadChats);
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);
  const searchValue = useSelector<StoreState, string>((state) => state.searchValue);
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [finishedRequests, setFinishedRequests] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleMobileMenu = () => {
    dispatch({ type: ActionTypes.TOGGLE_MOBILE_MENU });
  };

  const onNavItemAction = (path: string) => {
    history.replace(path);
  };

  const goBackAction = () => {
    history.goBack();
  };

  const renderCorrectNavLeftButton = () => {
    if (location.pathname && location.pathname.split('/').length > 0) {
      const locArr = location.pathname.split('/');
      const loc = locArr[1];

      if (['mediums', 'posts', 'top', 'episodes', 'createclubs', 'thumbnail'].includes(loc)
          || (loc === 'create' && createQuote.draftId)
          || (['goals'].includes(loc) && locArr.length > 2)) {
        return <Button type="link" icon={<ArrowLeftOutlined />} onClick={goBackAction} />;
      }

      if (locArr.length === 3 && loc === 'clubs') {
        return <Button type="default" shape="circle" icon={<ArrowLeftOutlined />} onClick={goBackAction} />;
      }

      if (locArr.length > 3 && loc === 'clubs' && ['members', 'about', 'edit'].includes(locArr[3])) {
        return <Button type="default" shape="circle" icon={<ArrowLeftOutlined />} onClick={goBackAction} />;
      }

      return (
        <Badge style={{ fontSize: '10px' }} dot={unreadChats > 0}>
          <MenuOutlined className={styles.navMenuIcon} onClick={toggleMobileMenu} />
        </Badge>
      );
    }
  };

  const hideNav = () => {
    const urlItems = location.pathname.split('/');
    const urlItem = urlItems[1];

    // if (urlItems.length > 4 && urlItem === 'storyclubs' && ['stories'].includes(urlItems[3])) {
    //   return true;
    // }
    //
    // if (urlItems.length > 4 && urlItem === 'storyclubs' && ['stories'].includes(urlItems[3])) {
    //   return true;
    // }

    return ['storyclubs', 'storynotes', 'chats', 'group', 'notes'].includes(urlItem);
  };

  const hideSearchInNav = () => {
    const urlItems = location.pathname.split('/');
    const urlItem1 = urlItems[1];
    return !['search'].includes(urlItem1);
  };

  const getUsers = async (searchText: string) => axios.get(`/api/nav/search/users?searchText=${searchText}`)
    .then((response) => [...options.filter((x) => x.key !== 'users'), {
      key: 'users',
      label: renderTitle('Users').render,
      options: [...response.data.map((user: User) => renderUserItem(user.username, user.image))],
    }]).catch(() => []);

  const getSeries = async (searchText: string) => axios.get(`/api/nav/search/series?searchText=${searchText}`)
    .then((response) => {
      console.log("seriesOptions: ",response);
      return [...options.filter((x) => x.key !== 'series'), {
        key: 'series',
        label: renderTitle('Series').render,
        options: [...response.data.map((medium: Medium) => renderMediumItem(medium))],
      }]
    }).catch(() => []);

  const getMovies = async (searchText: string) => axios.get(`/api/nav/search/movies?searchText=${searchText}`)
    .then((response) => [...options.filter((x) => x.key !== 'movies'), {
      key: 'movies',
      label: renderTitle('Movie').render,
      options: [...response.data.map((medium: Medium) => renderMediumItem(medium))],
    }]).catch(() => []);

  const getBooks = async (searchText: string) => axios.get(`/api/nav/search/books?searchText=${searchText}&media=ebook&entity=ebook`)
    .then((response) => [...options.filter((x) => x.key !== 'books'), {
      key: 'books',
      label: renderTitle('Books').render,
      options: [...response.data.map((medium: Medium) => renderMediumItem(medium))],
    }]).catch(() => []);

  const getPodcasts = async (searchText: string) => axios.get(`/api/nav/search/books?searchText=${searchText}&media=podcast&entity=podcast`)
    .then((response) => [...options.filter((x) => x.key !== 'podcasts'), {
      key: 'podcasts',
      label: renderTitle('Podcasts').render,
      options: [...response.data.map((medium: Medium) => renderMediumItem(medium))],
    }]).catch(() => []);

  const renderTitle = (title: string) => ({
    id: title,
    render:
  <div className={styles.searchMore}>
    {title}
  </div>,
  });

  const goToSearch = () => {
    setOptions([]);
    history.push('/search');
  };

  const renderMoreTitle = (title: string) => ({
    id: title,
    render:
  <div className={styles.searchMore}>
    <Button
      type="link"
      onClick={goToSearch}
    >
      show for more results
    </Button>
  </div>,
  });

  const renderUserItem = (username: string, imageUrl: string) => ({
    value: `/profile/${username}`,
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '20px',
          }}
          src={imageUrl}
          alt=""
        />
        <div style={{
          marginLeft: '8px',
        }}
        >
          {username}
        </div>
      </div>
    ),
  });

  const renderMediumItem = (medium: Medium) => ({
    value: `/mediums/${medium.external.id}/${medium.typeValue}`,
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            // width: '40px',
            height: '40px',
            objectFit: 'contain',
          }}
          src={medium.image}
          alt=""
        />
        <div style={{
          marginLeft: '8px',
        }}
        >
          {medium.title}
        </div>
      </div>
    ),
  });

  const onSearchClick = (data: string) => {
    setIsLoadingOptions(true);
    setOptions([]);
    setSearchQuery(data);
    getOptions(data).then(r => {

    });
  };

  const getOptions = async (data: string) => {
    setFinishedRequests(false);
    setOptions([]);
    const userOptions = await getUsers(data);
    const movieOptions = await getMovies(data);
    const seriesOptions = await getSeries(data);
    const bookOptions = await getBooks(data);
    const podcastOptions = await getPodcasts(data);

    let options: any[] = [];

    if (userOptions.find((x) => x.key === 'users')?.options?.length > 0) {
      options = [...options, ...userOptions];
    }

    if (movieOptions.find((x) => x.key === 'movies')?.options?.length > 0) {
      options = [...options, ...movieOptions];
    }

    if (seriesOptions.find((x) => x.key === 'series')?.options?.length > 0) {
      options = [...options, ...seriesOptions];
    }

    if (bookOptions.find((x) => x.key === 'books')?.options?.length > 0) {
      options = [...options, ...bookOptions];
    }

    if (podcastOptions.find((x) => x.key === 'podcasts')?.options?.length > 0) {
      options = [...options, ...podcastOptions];
    }

    setIsLoadingOptions(false);
    setFinishedRequests(true);
    setOptions(options);
  };

  const onClearAction = (data: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_VALUE, value: data });
    if (data === '') {
      setOptions([]);
    }
  };

  const onSelect = (data: any) => {
    dispatch({ type: ActionTypes.SET_SEARCH_VALUE, value: '' });
    setOptions([]);
    history.replace(data);
  };

  const getSearchOptions = () => {

    if (options.length > 0) {
      return [...options, {
        label: renderMoreTitle('More').render,
        options: [],
      }]
    }

    if (options.length === 0 && searchValue && isLoadingOptions && finishedRequests) {
      return [{
        label: renderMoreTitle('More').render,
        options: [],
      }]
    }

    return [];
  }
  return (
    <div className={`${hideNav() ? styles.hideNav : styles.newOne} `}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.navMenu}>
            {
              renderCorrectNavLeftButton()
            }
          </div>
          <Logo />
        </div>
        {
          hideSearchInNav()
          && (
          <div className={styles.center}>
            <AutoComplete
              className={styles.searchInput}
              dropdownMatchSelectWidth={300}
              style={{ width: 300 }}
              onChange={onClearAction}
              options={getSearchOptions()}
              value={searchValue}
              onSelect={onSelect}
            >
              <Input.Search
                size="large"
                placeholder="Search"
                allowClear
                loading={isLoadingOptions}
                onSearch={onSearchClick}
              />
            </AutoComplete>
            {
              searchQuery !== ''
              && (
              <OptGroup label="Search">
                <Option value="searchQuery">{searchQuery}</Option>
              </OptGroup>
              )
            }
          </div>
          )
        }
        {
          currentUser
            ? (
              <div className={styles.right}>
                <Button
                  className={styles.createButton}
                  type="primary"
                  onClick={() => onNavItemAction('/create')}
                  size="large"
                >
                  Create
                </Button>
                <Dropdown
                  className={styles.settingsContainer}
                  overlay={() => ProfileMenu(true ? currentUser.username : '')}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Image
                    className={styles.profileImage}
                    src={currentUser.image}
                    alt={currentUser.image}
                    preview={false}
                    fallback={fallBackUrl}
                  />
                </Dropdown>
              </div>
            ) : (
              <div className={styles.right}>
                <Skeleton.Input style={{ width: '200px', marginRight: '24px' }} />
                <Skeleton.Avatar active size="default" shape="circle" />
              </div>
            )
        }
      </div>
    </div>
  );
}

export default NavBar;
