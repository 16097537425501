import React, {useRef, useState} from 'react';
import {useQuery} from "react-query";
import axios from "axios";
import {ActionTypes} from "../../actions/types";
import {Post} from "../../models/Post";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers/_RootReducers";
import PostCard from "../HomeScreen/PostCard/PostCard";
import WiltFullPost from "../../components/WiltFullPost/WiltFullPost";
import SummaryFullPost from "../../components/SummaryFullPost/SummaryFullPost";
import QuizPostCard from "../HomeScreen/QuizPostCard/QuizPostCard";
import PostCardSkeleton from "../HomeScreen/PostCardSkeleton/PostCardSkeleton";
import SummaryCardSkeleton from "../HomeScreen/SummaryCardSkeleton/SummaryCardSkeleton";
import RatingThumbnail from "./rating-thumbnail/rating-thumbnail";
import styles from './thumb-nail-screen.module.scss';
import {Button, Collapse, Drawer, Switch} from "antd";
import GoBack from "../../components/GoBack/GoBack";
import {BulbOutlined, CloseOutlined} from "@ant-design/icons";
import {ThumbnailStyles} from "../../models/ThumbnailStyles";
import PreviewThumbnailModal from "./preview-thumbnail-modal/preview-thumbnail-modal";
import * as htmlToImage from "html-to-image";
import {updateThumbnail} from "../../services-api/thumbnailService";

const { Panel } = Collapse;
function ThumbNailScreen() {
    const { id } = useParams<{ id: string }>();
    const isEditingModalOpen = useSelector<StoreState, boolean>(state => state.isEditingModalOpen);
    const isLoading = useSelector<StoreState, boolean>(state => state.isLoading);

    const showDrawer = () => {
        dispatch({ type: ActionTypes.SET_EDITING_MODAL_OPEN, value: true });
    };
    const onClose = () => {
        dispatch({ type: ActionTypes.SET_EDITING_MODAL_OPEN, value: false });
    };
    const selectedPost = useSelector<StoreState, Post | null>((state) => state.selectedPost);
    const thumbnailStyles = useSelector<StoreState, ThumbnailStyles | null>(state => state.thumbnailStyles);
    const reactionIds = useSelector<StoreState, any>((state) => state.reactionIds);
    const shelfIds = useSelector<StoreState, any>((state) => state.shelfIds);
    const [saved, setSaved] = useState(false);
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [timeStamp] = useState(new Date());
    const [limit] = useState(12);
    const ref = useRef<any>(null);
    const dispatch = useDispatch();

    const { isFetching: postIsFetching, error: postError, data } = useQuery(`post-screen-${id}`, () => axios.get(`/api/posts/${id}`)
        .then((response) => {
            dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data?.shelfIds } });
            dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, ...response.data?.reactionIds } });
            dispatch({ type: ActionTypes.SET_SELECTED_POST, post: response.data.post });
            dispatch({ type: ActionTypes.SET_THUMBNAIL_STYLES, value: response.data.thumbnailStyles });
            return response.data as Post;
        }));

    const displayCorrectPost = (type: string | undefined) => {
        switch (type) {
            case '0':
                if (selectedPost) {
                    return (
                        <PostCard
                            key="PostScreenPostCard"
                            post={selectedPost}
                            reactionIds={reactionIds}
                            shelfIds={shelfIds}
                            likePost={() => {}}
                            unlikePost={() => {}}
                        />
                    );
                }
                break;
            case '1':
                if (selectedPost) {
                    return (
                        <WiltFullPost
                            key="PostScreenWiltFullPost"
                            post={selectedPost}
                            likePost={() => {}}
                            unlikePost={() => {}}
                            reactionIds={reactionIds}
                        />
                    );
                }
                break;
            case '2':
                if (selectedPost) {
                    return (
                        <SummaryFullPost
                            key="PostScreenSummaryFullPost"
                            post={selectedPost}
                            likePost={() => {}}
                            unlikePost={() => {}}
                            reactionIds={reactionIds}
                        />
                    );
                }
                break;
            case '3':
                if (selectedPost) {
                    return (
                    <RatingThumbnail key="PostScreenRatingCard" item={selectedPost} />
                    );
                }
                break;
            case '4':
                if (selectedPost) {
                    return (
                        <QuizPostCard
                            key="PostScreenQuizPostCard"
                            post={selectedPost}
                            reactionIds={reactionIds}
                            shelfIds={shelfIds}
                            likePost={() => {}}
                            unlikePost={() => {}}
                        />
                    );
                }
                break;
            default:
        }
    };

    const callback = (key: string | string[]) => {
        console.log(key);
    }

    const displayCorrectSkeleton = (type: string | undefined) => {
        switch (type) {
            case '0':
                return <PostCardSkeleton key="PostCardSkeleton" />;
            case '1':
                return <SummaryCardSkeleton key="SummaryCardSkeleton" />;
            case '2':
                return <SummaryCardSkeleton key="SummaryCardSkeleton" />;
            default:
        }
    };

    const onEditClick = () => {
        dispatch({ type: ActionTypes.SET_EDITING_MODAL_OPEN, value: true });
    }

    const onPreviewClick = () => {
        const item = document.getElementById('thumbnail-node');

        htmlToImage.toPng(item!, { pixelRatio: 2 })
            .then((dataUrl) => {
                console.log("dataUrl: ",dataUrl);
                fetch(dataUrl)
                    .then(resD => {
                        console.log("dataUrl - fet: ",resD);
                        resD.blob().then((blob) => {
                            console.log("dataUrl - blob: ",blob);
                            //const newCreateQuote = { ...createQuote, dataUrl: blob, currentPage: createQuote.currentPage + 1 };
                            dispatch({ type: ActionTypes.SET_THUMBNAIL_STYLES, value: {...thumbnailStyles, imageData: blob } });
                            dispatch({ type: ActionTypes.SET_THUMBNAIL_PREVIEW_MODAL_VISIBILITY, value: true });
                        });
                    })
                    .then(console.log)
            })
    }

    const onChangeTheme = (themeValue: boolean) => {
        console.log("thumbnailStyles - event: ",themeValue);
        dispatch({ type: ActionTypes.SET_THUMBNAIL_STYLES, value: {...thumbnailStyles, isDarkTheme: themeValue }})
    }

    const onSaveClick = () => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
        const item = document.getElementById('thumbnail-node');

        htmlToImage.toPng(item!, { pixelRatio: 2 })
            .then((dataUrl) => {
                console.log("dataUrl: ",dataUrl);
                fetch(dataUrl)
                    .then(resD => {
                        console.log("dataUrl - fet: ",resD);
                        resD.blob().then((blob) => {
                            console.log("dataUrl - blob: ",blob);
                            //const newCreateQuote = { ...createQuote, dataUrl: blob, currentPage: createQuote.currentPage + 1 };
                            //dispatch({ type: ActionTypes.SET_THUMBNAIL_STYLES, value: {...thumbnailStyles, imageData: blob } });
                            //dispatch({ type: ActionTypes.SET_THUMBNAIL_PREVIEW_MODAL_VISIBILITY, value: true });
                            dispatch(updateThumbnail(id, thumbnailStyles, blob, () => {
                                setSaved(true);
                                dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
                                // dispatch({ type: ActionTypes.SET_THUMBNAIL_STYLES, value: {...thumbnailStyles, imageData: blob } });
                                // dispatch({ type: ActionTypes.SET_THUMBNAIL_PREVIEW_MODAL_VISIBILITY, value: true });
                            }))
                        });
                    })
                    .then(console.log)
            })
    }

    return (
        <div className={styles.ThumbNailScreen}>
            <PreviewThumbnailModal/>
            <Drawer
                title="Thumbnail editor"
                placement="right"
                bodyStyle={{padding: '0px'}}
                closable={false}
                onClose={onClose}
                visible={isEditingModalOpen}
            >
                <Collapse defaultActiveKey={['theme']} onChange={callback}>
                    <Panel header="Theme" key="theme">
                        <Switch
                            className={styles.themeSwitch}
                            onChange={onChangeTheme}
                            checked={thumbnailStyles?.isDarkTheme}
                            checkedChildren={<span>dark</span>}
                            unCheckedChildren={<span>light</span>}
                        />
                    </Panel>
                    {/*<Panel header="Template" key="template">*/}
                    {/*    images*/}
                    {/*</Panel>*/}
                </Collapse>
            </Drawer>
            <GoBack/>
            <div className={styles.contentContainer}>
                {
                    selectedPost
                        ? displayCorrectPost(`${selectedPost.typeValue}`)
                        : displayCorrectSkeleton(`${data?.typeValue}`)
                }
                <div className={styles.controls}>
                    <Button
                        size="large"
                        className={styles.controlButton}
                        onClick={onEditClick}
                    >
                        Edit
                    </Button>
                    <Button
                        size="large"
                        className={styles.controlButton}
                        onClick={onPreviewClick}
                    >
                        Preview
                    </Button>
                    {
                        saved ?
                            <div
                                className={styles.controlButton}
                            >
                                Saved!
                            </div>
                            :
                            <Button
                                size="large"
                                className={styles.controlButton}
                                onClick={onSaveClick}
                                loading={isLoading}
                            >
                                Save
                            </Button>
                    }

                </div>
            </div>
        </div>
    );
}

export default ThumbNailScreen;