import React from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill, { Range, UnprivilegedEditor } from 'react-quill';
import { DeltaStatic, Sources } from 'quill';
import styles from './Summary.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';
import { CreateQuote } from '../../../models/create-models/CreateQuote';
import { ActionTypes } from '../../../actions/types';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

function Summary() {
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);
  const dispatch = useDispatch();

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCreateQuote = { ...createQuote, summaryTitle: event.target.value };
    dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (value: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor) => {
    const newCreateQuote = { ...createQuote, summaryText: value };
    dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeSelection = (selection: Range, source: Sources, editor: UnprivilegedEditor) => {

  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFocus = (selection: Range, source: Sources, editor: UnprivilegedEditor) => {

  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onBlur = (previousSelection: Range, source: Sources, editor: UnprivilegedEditor) => {

  };

  return (
    <div className={styles.Summary}>
      <div className={styles.title}>
        <Input className={styles.summaryInputTitle} placeholder="Basic usage" size="large" allowClear value={createQuote.summaryTitle} onChange={onTitleChange} />
      </div>
      <ReactQuill
        theme="bubble"
                // formats={["bold"]}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline'], // toggled buttons
            // ['blockquote', 'code-block'],
            [{ background: [] }],
            ['blockquote'], // dropdown with defaults from theme
            // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            // [{ 'direction': 'rtl' }],                         // text direction

            // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            // [{ 'align': [] }],

            // ['clean']                                         // remove formatting button
          ],
        }}
                // placeholder={createQuote.summaryText}
                // scrollingContainer={"<div>Hello</div>"}
        value={createQuote.summaryText}
        onChange={handleChange}
        onChangeSelection={onChangeSelection}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}

export default Summary;
