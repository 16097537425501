import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Button, List, Skeleton, Tabs,
} from 'antd';
import {
  CheckCircleOutlined, BookOutlined, RightOutlined,
} from '@ant-design/icons';
import styles from './MediumEpisodesScreen.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { Medium } from '../../models/Medium';
import { ActionTypes } from '../../actions/types';
import GoBack from '../../components/GoBack/GoBack';
import { addMediumToWishList } from '../../services-api/mediumService';
import Rating10 from '../../components/Rating10/Rating10';
import TabTitle from '../../components/TabTitle/TabTitle';
import Sources from '../medium-screen/Sources/Sources';
import whitebg from '../../assets/images/whitebg.svg';
import { Post } from '../../models/Post';
import WiltTitlePreview from '../../components/WiltTitlePreview/WiltTitlePreview';
import SummaryPreview from '../../components/SummaryPreview/SummaryPreview';
import {useQuery} from "react-query";

type MediumEpisodesScreenProps = {

};

const { TabPane } = Tabs;
function MediumEpisodesScreen(props: MediumEpisodesScreenProps) {
  const { episodeId } = useParams<{ episodeId: string }>();
  const [isOpen, setIsOpen] = useState(true);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const podcastEpisode = useSelector<StoreState, Medium | null>((state) => state.podcastEpisode);
  const shelfIds = useSelector<StoreState, any>((state) => state.shelfIds);
  const ref = useRef<any>(null);
  const [isInWishList, setIsInWishList] = useState(shelfIds[`${podcastEpisode?.external.id}`] !== undefined && shelfIds[`${podcastEpisode?.external.id}`]?.typeText === 'wishlist');
  const dispatch = useDispatch();
  const history = useHistory();

  // const getEpisode = () => {
  //   axios.get(`/api/mediums/podcast-episodes/episode/${episodeId}`)
  //     .then((response) => {
  //       dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data.shelfIds } });
  //       dispatch({ type: ActionTypes.SET_PODCAST_EPISODE, value: response.data.episode });
  //       setIsInWishList(response.data.shelfIds[`${response.data.episode?.external.id}`] !== undefined && response.data.shelfIds[`${response.data.episode?.external.id}`]?.typeText === 'wishlist');
  //     }).catch((error) => {
  //       console.log('error: ', error);
  //     });
  // };

  const { isFetching, error, data } = useQuery(`episode-${episodeId}-screen`, () => axios.get(`/api/mediums/podcast-episodes/episode/${episodeId}`)
      .then((response) => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, ...response.data.shelfIds } });
        dispatch({ type: ActionTypes.SET_PODCAST_EPISODE, value: response.data.episode });
        setIsInWishList(response.data.shelfIds[`${response.data.episode?.external.id}`] !== undefined && response.data.shelfIds[`${response.data.episode?.external.id}`]?.typeText === 'wishlist');
        return response.data;
      }), { enabled: currentUser });


  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current?.offsetTop);
  const myRef = useRef(null);

  useEffect(() => {
    scrollToRef(ref);

    return () => {
      dispatch({ type: ActionTypes.SET_PODCAST_EPISODE, value: null });
    };
  }, []);

  const onAddToWishListAction = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!isInWishList && podcastEpisode) {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      dispatch(addMediumToWishList(currentUser.username, podcastEpisode, () => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, [`${podcastEpisode.external.id}`]: { typeText: 'wishlist' } } });
        setIsInWishList(true);
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      }));
    }
  };

  const onMoreClick = () => {
    setIsOpen(!isOpen);
  };

  const onTabChange = () => {

  };

  const displayCorrectPreviewCard = (item: Post) => {
    switch (item.typeValue) {
      case 0:
        return;
      case 1:
        return <WiltTitlePreview item={item} type="wilt" />;
      case 2:
        return <SummaryPreview item={item} />;
      default:
    }
  };

  const onPostClick = (item: any) => {

  };

  const onSourceClick = () => {
    history.push(`/mediums/${podcastEpisode?.medium.external.id}/${podcastEpisode?.medium.typeValue}`);
  };

  return (
    currentUser && podcastEpisode
      ? (
        <div ref={myRef} className={styles.MediumEpisodesScreen}>
          <GoBack />
          <div className={styles.header}>
            <div className={styles.mediumContainer}>
              <img
                src={podcastEpisode?.medium?.imageUrl?.replace('http://', 'https://') || podcastEpisode?.medium?.imageUrl}
                alt={podcastEpisode?.medium?.imageUrl?.replace('http://', 'https://') || podcastEpisode?.medium?.imageUrl}
                className={styles.image}
              />
              <div className={styles.mediumInfo}>
                <div className={styles.title}>
                  {podcastEpisode?.title}
                </div>
                <div className={styles.author}>
                  {podcastEpisode?.provider}
                </div>
                <div className={styles.description}>
                  <div className={isOpen ? styles.clampText : ''}>
                    {podcastEpisode?.description}
                  </div>
                  <div className={styles.moreContainer}>
                    <Button size="small" type="link" ghost onClick={onMoreClick}>{isOpen ? 'Show more' : 'Show less'}</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              <Button
                className={isInWishList ? styles.wishListButton : styles.actionButton}
                icon={isInWishList ? <CheckCircleOutlined /> : <BookOutlined />}
                type="primary"
                loading={isLoading}
                ghost={!isInWishList}
                onClick={onAddToWishListAction}
              >
                {isInWishList ? 'in WishList' : 'Add to WishList'}
              </Button>
            </div>
          </div>
          <div className={styles.ratingContainer}>
            <Rating10 isOnShelf={shelfIds[`${podcastEpisode?.medium?.external.id}`] !== undefined && shelfIds[`${podcastEpisode?.medium?.external.id}`]?.typeText !== 'wishlist'} medium={podcastEpisode} />
          </div>
          <div className={styles.sourceContainer} onClick={onSourceClick}>
            <img
              src={podcastEpisode?.medium?.imageUrl?.replace('http://', 'https://') || podcastEpisode?.medium?.imageUrl}
              alt={podcastEpisode?.medium?.imageUrl?.replace('http://', 'https://') || podcastEpisode?.medium?.imageUrl}
              className={styles.sourceImage}
            />
            <div className={styles.sourceTitle}>
              {podcastEpisode?.medium?.title}
            </div>
            <div>
              <RightOutlined />
            </div>
          </div>
          <div className={styles.content}>
            <Tabs tabBarGutter={0} defaultActiveKey="sources" onChange={onTabChange}>
              <TabPane tab={<TabTitle text="Sources" />} key="sources">
                {
                  podcastEpisode?.officialSite
                  && <Sources medium={podcastEpisode} />
                }
              </TabPane>
              {
                podcastEpisode?.currentUserPosts && podcastEpisode?.currentUserPosts?.filter((x) => x.typeValue !== 3).length > 0
                && (
                <TabPane
                  tab={`Your posts ${podcastEpisode.currentUserPosts ? `(${podcastEpisode.currentUserPosts.filter((x) => x.typeValue !== 3).length})` : ''}`}
                  key="Your posts"
                >
                  <List
                    className={styles.postsList}
                    grid={{ gutter: 0, column: 3 }}
                    dataSource={podcastEpisode && podcastEpisode.currentUserPosts && podcastEpisode.currentUserPosts.filter((x) => x.typeValue !== 3)}
                    renderItem={(item) => (
                      <List.Item className={styles.item} onClick={() => onPostClick(item)}>
                        <img
                          className={styles.post}
                          src={item.typeValue === 0 ? item.image?.replace('http://', 'https://') : whitebg}
                          alt={item.typeValue === 0 ? item.image?.replace('http://', 'https://') : whitebg}
                        />
                        {
                                  displayCorrectPreviewCard(item)
                                }
                        {/* <div className={styles.postBackground} /> */}
                        {/* <div className={styles.postReactions}> */}
                        {/*    <Icon type="heart" theme="filled" /> */}
                        {/*    <span style={{ paddingLeft: '4px' }} >{item.likesCount}</span> */}
                        {/* </div> */}
                      </List.Item>
                    )}
                  />
                </TabPane>
                )
              }
            </Tabs>
          </div>
        </div>
      ) : (
        <div className={styles.MediumEpisodesScreen}>
          <Skeleton paragraph={{ rows: 16 }} />
        </div>
      )
  );
}

export default MediumEpisodesScreen;
