import React, { useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutlined, PlusCircleOutlined, BookOutlined } from '@ant-design/icons';
import renderHTML from 'react-render-html';
import ReactHtmlParser from 'react-html-parser';
import styles from './MediumItem.module.scss';
import { Medium } from '../../models/Medium';
import { ActionTypes } from '../../actions/types';
import { StoreState } from '../../reducers/_RootReducers';

interface MediumItemProps {
  medium: Medium;
  addToWishListAction: (medium: Medium, callback: Function) => void;
  addToShelfAction: (medium: Medium, callback: Function) => void;
  shelfIds: { [key: string]: { typeText: string } };
}
function MediumItem(props:MediumItemProps) {
  const {
    addToWishListAction, addToShelfAction, medium, shelfIds,
  } = props;
  const {
    title, image, provider, imageUrl, description,
  } = medium;
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const [isOpen, setIsOpen] = useState(true);
  const [isInWishList, setIsInWishList] = useState(shelfIds[`${medium.external.id}`] !== undefined && shelfIds[`${medium.external.id}`]?.typeText === 'wishlist');
  const [isOnShelf, setIsOnShelf] = useState(shelfIds[`${medium.external.id}`] !== undefined && shelfIds[`${medium.external.id}`]?.typeText !== 'wishlist');
  const dispatch = useDispatch();

  const onAddToWishListAction = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!isInWishList) {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      addToWishListAction(medium, () => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, [`${medium.external.id}`]: { typeText: 'wishlist' } } });
        setIsInWishList(true);
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      });
    }
  };

  const onAddToShelf = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!isOnShelf) {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      addToShelfAction(medium, (typeText: string) => {
        dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, [`${medium.external.id}`]: { typeText } } });
        setIsOnShelf(true);
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      });
    }
  };

  const onMoreClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.Medium}>
      <div className={styles.mediumContainer}>
        <img
          src={image?.replace('http://', 'https://') || imageUrl}
          alt={image?.replace('http://', 'https://') || imageUrl}
          className={medium.typeValue === 1 ? styles.podcastImage : styles.image}
        />
        <div className={styles.mediumInfo}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.author}>
            {provider}
          </div>
          {
            description
            && (
            <div className={styles.description}>
              <div className={isOpen ? styles.clampText : ''}>
                {/*{`${renderHTML(description)}`.replaceAll('<p>', '').replaceAll('</p>', '')}*/}
                {/*{renderHTML(description)}*/}
                {ReactHtmlParser(description).toString().replaceAll('<p>', '').replaceAll('</p>', '')}
              </div>
              <div className={styles.moreContainer}>
                <Button size="small" type="link" ghost onClick={onMoreClick}>{isOpen ? 'Show more' : 'Show less'}</Button>
              </div>
            </div>
            )
          }
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          className={isOnShelf ? styles.wishListButton : styles.actionButton}
          icon={isOnShelf ? <CheckCircleOutlined /> : <PlusCircleOutlined />}
          type="primary"
          loading={isLoading}
          ghost={!isOnShelf}
          onClick={onAddToShelf}
        >

          {isOnShelf ? 'on Shelf' : 'Add to Shelf'}
        </Button>
        {
                    !isOnShelf
                    && (
                    <Button
                      className={isInWishList ? styles.wishListButton : styles.actionButton}
                      icon={isInWishList ? <CheckCircleOutlined /> : <BookOutlined />}
                      type="primary"
                      loading={isLoading}
                      ghost={!isInWishList}
                      onClick={onAddToWishListAction}
                    >
                      {isInWishList ? 'in WishList' : 'Add to WishList'}
                    </Button>
                    )
                }
      </div>
    </div>
  );
}

export default MediumItem;
