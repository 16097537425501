import { ActionTypes } from '../actions/types';
import { Post } from '../models/Post';

interface setDraft {
  type: typeof ActionTypes.SET_DRAFTS;
  drafts: Post[];
}

type draftsActions = setDraft;

export const drafts = (state = [], action: draftsActions) => {
  if (action.type === ActionTypes.SET_DRAFTS) {
    return action.drafts;
  }
  return state;
};
