import { ActionTypes } from '../actions/types';

interface viewPostImageModal {
  type: typeof ActionTypes.TOGGLE_POST_IMAGE
}

interface toggleAuthModal {
  type: typeof ActionTypes.TOGGLE_AUTH_MODAL;
  authType: string;
}

interface setLastLocation {
  type: typeof ActionTypes.SET_LAST_LOCATION;
  location: string;
}

interface setMenuItem {
  type: typeof ActionTypes.SET_MENU_ITEM;
  location: string;
}

interface toggleSettingsModal {
  type: typeof ActionTypes.TOGGLE_SETTINGS_MODAL;
}

interface toggleColorPaletteModal {
  type: typeof ActionTypes.TOGGLE_COLOR_PALETTE_MODAL;
}

interface toggleFontModalMain {
  type: typeof ActionTypes.TOGGLE_FONT_MODAL_MAIN;
  data?: any;
}

interface toggleFontModalSource {
  type: typeof ActionTypes.TOGGLE_FONT_MODAL_SOURCE;
  data?: any;
}

interface setFontDataMain {
  type: typeof ActionTypes.SET_FONT_MODAL_DATA_MAIN;
  data?: any;
}

interface setFontDataSource {
  type: typeof ActionTypes.SET_FONT_MODAL_DATA_SOURCE;
  data?: any;
}

interface toggleTextModal {
  type: typeof ActionTypes.TOGGLE_TEXT_MODAL;
}

interface toggleWiltTextModal {
  type: typeof ActionTypes.TOGGLE_WILT_TEXT_MODAL;
}

interface isLoading {
  type: typeof ActionTypes.TOGGLE_LOADING;
  value: boolean;
}

interface toggleMobileMenu {
  type: typeof ActionTypes.TOGGLE_MOBILE_MENU;
}

interface toggleInterestModalVisibility {
  type: typeof ActionTypes.TOGGLE_INTEREST_MODAL_VISIBILITY;
}

interface setInterestModalVisibility {
  type: typeof ActionTypes.SET_INTEREST_MODAL_VISIBILITY;
  value: boolean;
}

interface setCurrentId {
  type: typeof ActionTypes.SET_CURRENT_ID;
  value: string;
}

interface scrollPosition {
  type: typeof ActionTypes.SET_SCROLL_POSITION;
  value: number;
}

interface setScrollRef {
  type: typeof ActionTypes.SET_SCROLL_REF;
  value: any;
}

interface isNewMessageModalOpen {
  type: typeof ActionTypes.SET_NEM_MESSAGE_MODAL;
  value: boolean;
}

interface isTemplatesModalOpen {
  type: typeof ActionTypes.SET_TEMPLATES_MODAL;
  value: boolean;
}

interface isTemplatePreviewModalOpen {
  type: typeof ActionTypes.SET_TEMPLATE_PREVIEW_MODAL;
  value: boolean;
}

interface setReactionsOpen {
  type: typeof ActionTypes.SET_REACTIONS_VISIBILITY;
  value: boolean;
}

interface setIsMyNotesOpen {
  type: typeof ActionTypes.SET_MY_NOTES_VISIBILITY;
  value: boolean;
}

interface setIsMyPreviewNoteOpen {
  type: typeof ActionTypes.SET_PREVIEW_NOTE_VISIBILITY;
  value: boolean;
}

interface setLastCreatedScreen {
  type: typeof ActionTypes.SET_LAST_CREATED_SCREEN;
  value: string;
}

interface setAddNotesOpen {
  type: typeof ActionTypes.SET_ADD_NOTES_MODAL_VISIBILITY;
  value: boolean;
}

interface setResetTime {
  type: typeof ActionTypes.SET_RESET_TIME;
  value: boolean;
}

interface toggleGradientPaletteModal {
  type: typeof ActionTypes.TOGGLE_GRADIENT_PALETTE_MODAL;
}

type displayActions = setCurrentId | viewPostImageModal | toggleAuthModal
| setLastLocation | setMenuItem | toggleSettingsModal | toggleColorPaletteModal
| toggleTextModal | isLoading | toggleWiltTextModal | toggleMobileMenu | toggleFontModalMain
| setFontDataMain | toggleFontModalSource | setFontDataSource | toggleInterestModalVisibility
| setInterestModalVisibility | scrollPosition | setScrollRef | isNewMessageModalOpen
| isTemplatesModalOpen | isTemplatePreviewModalOpen | setReactionsOpen | setIsMyNotesOpen
| setIsMyPreviewNoteOpen | setLastCreatedScreen | setAddNotesOpen | setResetTime | toggleGradientPaletteModal;

export const isPostImageModalVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_POST_IMAGE) {
    return !state;
  }
  return state;
};

export const authModal = (state = { visible: false, authType: '' }, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_AUTH_MODAL) {
    return { visible: !state.visible, authType: action.authType };
  }
  return state;
};

export const lastLocationState = (state = '', action: displayActions) => {
  if (action.type === ActionTypes.SET_LAST_LOCATION && action.location) {
    return action.location;
  }
  return state;
};

export const selectedMenuItem = (state = 'home', action: displayActions) => {
  if (action.type === ActionTypes.SET_MENU_ITEM && action.location) {
    return action.location;
  }
  return state;
};

export const isSettingsVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_SETTINGS_MODAL) {
    return !state;
  }
  return state;
};

export const isColorPaletteModalVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_COLOR_PALETTE_MODAL) {
    return !state;
  }
  return state;
};

export const isFontModalMainVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_FONT_MODAL_MAIN) {
    return !state;
  }
  return state;
};

export const isFontModalSourceVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_FONT_MODAL_SOURCE) {
    return !state;
  }
  return state;
};

export interface fontModalDataModel {
  fontSize: number;
  isBold: boolean;
  isItalic: boolean;
}

const temp: fontModalDataModel = {
  fontSize: 10,
  isBold: false,
  isItalic: false,
};

export const fontModalDataMain = (state = temp, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_FONT_MODAL_MAIN && action.data) {
    return { ...action.data };
  } if (action.type === ActionTypes.SET_FONT_MODAL_DATA_MAIN && action.data) {
    return { ...action.data };
  }
  return state;
};

export const fontModalDataSource = (state = temp, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_FONT_MODAL_SOURCE && action.data) {
    return { ...action.data };
  } if (action.type === ActionTypes.SET_FONT_MODAL_DATA_SOURCE && action.data) {
    return { ...action.data };
  }
  return state;
};

export const isTextModalVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_TEXT_MODAL) {
    return !state;
  }
  return state;
};

export const isWiltTextModalVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_WILT_TEXT_MODAL) {
    return !state;
  }
  return state;
};

export const isMobileMenuOpen = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_MOBILE_MENU) {
    return !state;
  }
  return state;
};

export const isLoading = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_LOADING) {
    return action.value;
  }
  return state;
};

export const currentId = (state = '', action: displayActions) => {
  if (action.type === ActionTypes.SET_CURRENT_ID) {
    return action.value;
  }
  return state;
};

export const isInterestModalVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_INTEREST_MODAL_VISIBILITY) {
    return !state;
  } if (action.type === ActionTypes.SET_INTEREST_MODAL_VISIBILITY) {
    return action.value;
  }
  return state;
};

export const scrollPosition = (state = 0, action: displayActions) => {
  if (action.type === ActionTypes.SET_SCROLL_POSITION) {
    return action.value;
  }
  return state;
};

export const scrollRef = (state = null, action: displayActions) => {
  if (action.type === ActionTypes.SET_SCROLL_REF) {
    return action.value;
  }
  return state;
};

export const isNewMessageModalOpen = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.SET_NEM_MESSAGE_MODAL) {
    return action.value;
  }
  return state;
};

export const isTemplatesModalOpen = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.SET_TEMPLATES_MODAL) {
    return action.value;
  }
  return state;
};

export const isTemplatePreviewModalOpen = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.SET_TEMPLATE_PREVIEW_MODAL) {
    return action.value;
  }
  return state;
};

export const isReactionsOpen = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.SET_REACTIONS_VISIBILITY) {
    return action.value;
  }
  return state;
};

export const isMyNotesOpen = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.SET_MY_NOTES_VISIBILITY) {
    return action.value;
  }
  return state;
};

export const isMyPreviewNoteOpen = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.SET_PREVIEW_NOTE_VISIBILITY) {
    return action.value;
  }
  return state;
};

export const lastCreatedScreen = (state: string = '', action: displayActions) => {
  if (action.type === ActionTypes.SET_LAST_CREATED_SCREEN) {
    return action.value;
  }
  return state;
};

export const isAddNotesOpen = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.SET_ADD_NOTES_MODAL_VISIBILITY) {
    return action.value;
  }
  return state;
};

export const resetTimestamp = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.SET_RESET_TIME) {
    return action.value;
  }
  return state;
};

export const isGradientPaletteModalVisible = (state = false, action: displayActions) => {
  if (action.type === ActionTypes.TOGGLE_GRADIENT_PALETTE_MODAL) {
    return !state;
  }
  return state;
};