import React, {useEffect} from 'react';
import darkStyles from './rating-thumbnail-dark.module.scss';
import lightStyles from './rating-thumbnail-light.module.scss';
import {Progress} from "antd";
import {convertRatingToColor} from "../../../helpers/helpers";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {ThumbnailStyles} from "../../../models/ThumbnailStyles";

type RatingThumbnailProps = {
    item: any;
}

function RatingThumbnail(props: RatingThumbnailProps) {
    const { item } = props;
    const thumbnailStyles = useSelector<StoreState, ThumbnailStyles | null>(state => state.thumbnailStyles);
    const styles = thumbnailStyles?.isDarkTheme ? darkStyles: lightStyles;

    useEffect(() => {
        console.log("thumbnailStyles - molly: ",thumbnailStyles);
    }, [thumbnailStyles]);

    const getImageUrl = () => {

        if (item?.medium?.typeValue === 4) {
            console.log("item: ",item);
            return item?.medium?.medium?.imageUrl?.replace('http://', 'https://');
        }

        return item?.medium?.imageUrl?.replace('http://', 'https://');
    };

    return (
        <div className={styles.RatingThumbnail} id="thumbnail-node" >
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <Progress
                        className={styles.ratingProgress}
                        width={128}
                        type="circle"
                        strokeColor={{
                            '0%': convertRatingToColor(item.rating),
                            '100%': convertRatingToColor(item.rating),
                        }}
                        percent={((item.rating / 10) * 100)}
                        format={(percent) => (
                            <span>
                <span className={styles.ratingValue}>{item.rating}</span>
                <span className={styles.outOf}>/10</span>
              </span>
                        )}
                    />
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.imageContainer}>
                        <img
                            className={styles.image}
                            src={getImageUrl()}
                            alt={getImageUrl()}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.layer2}>
                <span className={styles.swipe}>goodlores.com</span>
                {/*<DoubleRightOutlined />*/}
            </div>
            <div className={styles.layer3}>
                {item.medium.title}
            </div>
        </div>
    );
}

export default RatingThumbnail;