import React from 'react';
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './InterestMediumCard2.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';

interface InterestMediumCardProps {
  item: any;
  onBookSelect: (uniqueName: string) => void;
  isLoading: boolean;
  currentId: string;
  setFinal?: (item: any) => void;
}

function InterestMediumCard2(props: InterestMediumCardProps) {
  const { currentId, item, onBookSelect } = props;
  const {
    title, selected, imageUrl,
  } = item;

  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);

  const onSelectClick = () => {
    if (title) {
      onBookSelect(title);
    }
  };

  return (
    <div className={styles.InterestMediumCard2}>
      <div className={styles.mediumContainer}>
        <img
          className={styles.image}
          src={imageUrl.replace('http://', 'https://')}
          alt={imageUrl.replace('http://', 'https://')}
        />
        <div className={styles.mediumTitle}>
          {title}
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          type="primary"
          icon={selected === true && <CheckCircleOutlined />}
          className={`${styles.selectButton} ${selected === true ? styles.selected : ''}`}
          loading={currentId === title && isLoading}
          onClick={onSelectClick}
        >
          {selected === true ? 'Selected' : 'Select'}
        </Button>
      </div>
    </div>
  );
}

export default InterestMediumCard2;
