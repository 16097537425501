import { QuoteStyling } from '../../models/create-models/QuoteStyling';
import { Layer } from '../../models/create-models/Layer';
import { QuoteContent } from '../../models/create-models/QuoteContent';
import { Template } from '../../models/create-models/Template';
import {TemplatePreview} from "../../models/TemplatePreview";

const mainStyling: QuoteStyling = {
    fontSize: '20px',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: '400',
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
};

const mainLayoutStyling: QuoteStyling = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const sourceLayoutStyling: QuoteStyling = {
    width: '100%',
};

const sourceStyling: QuoteStyling = {
    fontSize: '14px',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: '400',
    textDecoration: 'none',
};

const backgroundStyling: QuoteStyling = {
    background: '#BCD9D4',
    opacity: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

const backgroundLayer: Layer = {
    order: 0,
    type: 'background',
    content: backgroundStyling,
};

const layout: QuoteStyling = {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
};

const quoteContent: QuoteContent = {
    layout,
    main: {
        layout: mainLayoutStyling,
        text: mainStyling,
        highlights: {...mainStyling, color: "#6bd031"},
    },
    source: {
        layout: sourceLayoutStyling,
        title: sourceStyling,
        subtitle: sourceStyling,
    },
};

const quoteLayer: Layer = {
    order: 1,
    type: 'quote',
    content: quoteContent,
};

const layers: Layer[] = [backgroundLayer, quoteLayer];

const backgroundTextStyling: QuoteStyling = {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    color: '#fff',
    opacity: 0.1,
    fontSize: '32px',
    background: '',
    overflow: 'hidden',
};

const backgroundTextLayer: Layer = {
    order: 2,
    type: 'backgroundText',
    content: backgroundTextStyling,
};

export const BackgroundTextTemplate: Template = {
    id: '2',
    title: 'Background Text',
    uniqueName: 'backgroundText',
    layers: [...layers, backgroundTextLayer],
    colors: ["quote", "source", "background", "gradientBackground", "backgroundText"],
    fonts: ["quote", "source", "gradientBackground", "backgroundText"]
};

export const backgroundTextTemplatePreview: TemplatePreview = { id: 'backgroundText', idNum: 2, title: 'Background Text', uniqueName: 'backgroundText', template: BackgroundTextTemplate };