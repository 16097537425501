import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import { useQueryCache, useInfiniteQuery } from 'react-query';
import axios from 'axios';
import {Badge, Button, Input, Spin, Tabs} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {MenuOutlined, PlusOutlined} from '@ant-design/icons';
import { User } from '../../models/User';
import { StoreState } from '../../reducers/_RootReducers';
import styles from './clubs-screen.module.scss';
import FriendUserCardSkeleton from '../NewChatScreen/FriendUserCardSkeleton/FriendUserCardSkeleton';
import OverViewCard from '../MessagesScreen/OverViewCard/OverViewCard';
import ClubCard from './club-card/club-card';
import { Post } from '../../models/Post';
import { ActionTypes } from '../../actions/types';
import { Club } from '../../models/Club';
import { ClubUser } from '../../models/ClubUser';
import { updateClub } from '../../services-api/clubService';
import ClubCardSkeleton from './club-card-skeleton/club-card-skeleton';
import Empty from "../../components/Empty/Empty";
import ClubTabPaneTab from "./club-tab-pane-tab/club-tab-pane-tab";
import ClubRecentActivity from "./club-recent-activity/club-recent-activity";

const { TabPane } = Tabs;
const { Search } = Input;
function ClubsScreen() {
  const { id } = useParams<{ id: string }>();
  const unreadChats = useSelector<StoreState, number>((state) => state.unreadChats);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const history = useHistory();
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `clubs-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/clubs/current/user?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      if (data?.clubs?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onCreateClick = () => {
    history.push('/createclubs');
  };

  const onSearch = (data: string) => {
    setSearchQuery(data);
    history.replace('/storyclubs/clubs');
  };

  const onClubClick = (club: Club) => {
    history.push(`/storyclubs/${club._id}`);
  };

  const onFavoriteAction = (clubUser: ClubUser) => {
    dispatch(updateClub(clubUser._id, { favorite: true }, (updatedClub: Club) => {
      queryCache.setQueryData(`messages-screen-${searchQuery}`, (oldData) =>
      // @ts-ignore
        oldData.map((x) => ({
          ...x,
          clubs: [...x.clubs.map((x: ClubUser) => {
            if (x.club._id === clubUser.club._id) {
              return { ...x, favorite: true };
            }
            return x;
          })],
        })));
    }));
  };

  const callback = (key: string) => {
    console.log(key);
    history.replace(`/storyclubs/${key}`);
  }

  const toggleMobileMenu = () => {
    dispatch({ type: ActionTypes.TOGGLE_MOBILE_MENU });
  };

  return (currentUser
        && (
        <div className={styles.ClubsScreen}>
          <div className={styles.headerContainer}>
            <div className={styles.headerWhiteboard}>
              <div className={styles.messageHeader}>
                <div className={styles.topHeader}>
                  <Badge style={{ fontSize: '10px' }} dot={unreadChats > 0}>
                    <MenuOutlined className={styles.navMenuIcon} onClick={toggleMobileMenu} />
                  </Badge>
                  <div className={styles.titleHeader}>
                    Story Clubs
                  </div>
                  <div className={styles.create}>
                    <Button
                      size="large"
                      type="primary"
                      onClick={onCreateClick}
                      icon={<PlusOutlined />}
                    />
                  </div>
                </div>
                <div className={styles.searchContainer}>
                  <Search
                    className={styles.searchInput}
                    placeholder="Search for your story clubs"
                    allowClear
                    size="large"
                    onSearch={onSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <Tabs defaultActiveKey={id || "clubs"} activeKey={id || "clubs"} tabBarGutter={0} onChange={callback}>
              <TabPane tab={<ClubTabPaneTab name={"Clubs"}/>} key="clubs">
                {
                  isFetching === false &&  data?.map((page) => page.clubs).flat().length === 0 &&
                  <Empty
                      title="Create your first story club"
                      subTitle="Join fun clubs"
                      icon="plus"
                      buttonTitle="Create Story Club"
                      url="/createclubs"
                      screen="posts"
                  />
                }
                {
                  isFetching && !data
                      ? (
                          <div className={styles.gridList}>
                            {
                              [1, 1, 1, 1, 1, 1].map((item, index) => <ClubCardSkeleton key={`${index}`} />)
                            }
                          </div>
                      ) : (
                          <InfiniteScroll
                              className={styles.gridList}
                              dataLength={data?.length || 0}
                              next={() => fetchMore()}
                              hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
                              loader={(
                                  <div className={styles.spinningContainer}>
                                    <Spin />
                                  </div>
                              )}
                          >
                            {
                              data?.map((page) => page.clubs).flat()
                                  .map((clubUser: ClubUser) => (
                                      <ClubCard
                                          key={clubUser?._id}
                                          clubUser={clubUser}
                                          onClubClick={onClubClick}
                                          onFavoriteAction={onFavoriteAction}
                                      />
                                  ))
                            }
                          </InfiniteScroll>
                      )
                }
              </TabPane>
              <TabPane tab={<ClubTabPaneTab name={"Activity"}/>} key="activity">
                <ClubRecentActivity />
              </TabPane>
            </Tabs>
          </div>
        </div>
        )
  );
}

export default ClubsScreen;
