import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './Logo.module.scss';
import { ActionTypes } from '../../actions/types';

function Logo(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();

  const navigateToHome = () => {
    history.replace('/');
    dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
    dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
  };

  return (
    <div className={styles.Logo} onClick={navigateToHome}>
      G
      <span className={styles.o1}>o</span>
      <span className={styles.o2}>o</span>
      dL
      <span className={styles.o3}>o</span>
      r
      <span className={styles.e}>e</span>
      s
    </div>
  );
}

export default Logo;
