import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Form, Image, Input, InputNumber, Select, Spin, Switch,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useQueryCache, useInfiniteQuery, useQuery, queryCache,
} from 'react-query';
import axios from 'axios';
import {
  ArrowLeftOutlined, PlusOutlined, PictureOutlined, CheckOutlined, CloseOutlined, AudioOutlined, ReadOutlined, ArrowRightOutlined,
} from '@ant-design/icons';
import styles from './storynotes-notes-create-screen.module.scss';
import { getCorrectTitle } from '../../helpers/helpers';
import { createNote, updateNote } from '../../services-api/notesService';
import { NoteCategory } from '../../models/NoteCategory';
import { StoreState } from '../../reducers/_RootReducers';
import { StoryNote } from '../../models/StoryNote';
import { ActionTypes } from '../../actions/types';
import { Note } from '../../models/Note';

const { TextArea } = Input;
function StorynotesNotesCreateScreen() {
  const { id, sectionId, noteId } = useParams<{ id: string, sectionId: string, noteId: string }>();
  const [form] = Form.useForm();
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentStoryNote = useSelector<StoreState, StoryNote | null>((state) => state.currentStoryNote);
  const [searchQuery, setSearchQuery] = useState('');
  const [titleText] = useState(noteId === 'create' ? 'create' : 'update');
  const [note, setNote] = useState<any | undefined>(undefined);
  const [name, setName] = useState<string>('');
  const [categoryOptions, setCategoryOptions] = useState<NoteCategory[]>([]);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(24);
  const [value, setValue] = useState<any>('');
  const [type, setType] = useState('TEXT');
  const [location, setLocation] = useState(0);
  const [locationType, setLocationType] = useState(false);
  const [startMinutes, setStartMinutes] = useState(0);
  const [endMinutes, setEndMinutes] = useState(0);
  const [startSeconds, setStartSeconds] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const onBackClick = () => {
    history.goBack();
  };
  const { data: categories } = useQuery('note-categories', () => axios.get('/api/notes/category')
    .then((response) => {
      setCategoryOptions(response.data);
      return response.data as NoteCategory[];
    }));

  useQuery(`story-templates-${id}-screen-`, () => axios.get(`/api/notes/story/${id}?search=''&limit=${limit}&timestamp=${timeStamp}`)
    .then((response) => {
      dispatch({ type: ActionTypes.SET_CURRENT_STORY_NOTE, value: response.data });
      return response.data as StoryNote;
    }), { enabled: currentStoryNote === null });

  const { data: existingNote } = useQuery(`note-${noteId}-screen`, () => axios.get(`/api/notes/${noteId}`)
    .then((response) => {
      const tempNote = response.data as Note;
      setType(tempNote.type);
      setLocationType(tempNote.locationType === 'TIME');
      return tempNote;
    }), { enabled: noteId !== 'create' && categories });

  //const [text, setText] = useState(existingNote?.text || '');

  useEffect(() => {
  }, [currentStoryNote]);

  const {
    data,
    isFetching,
  } = useInfiniteQuery(
    `new-club-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.post('/api/users/mymediums', { searchText: searchQuery, type: getCorrectTitle(type || 'books')?.value });
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onFinish = (values: any) => {
    const data = {
      type: values.type,
      title: values.title,
      location: values.location,
      noteCategories: values.noteCategories,
      text: values.text,
      minutes: values.minutes,
      seconds: values.seconds,
      startMinutes: values.startMinutes,
      startSeconds: values.startSeconds,
      endMinutes: values.endMinutes,
      endSeconds: values.endSeconds,
      locationType: currentStoryNote?.medium?.typeValue === 0 ? locationType ? 'TIME' : 'PAGE' : 'TIME',
      storyNote: id,
      sectionId: sectionId.includes('sub')
        ? currentStoryNote?.template.sections.map((section) => section.subChapters).flat().find((val) => val?.id === sectionId)?.parentId : sectionId,
      subSectionId: sectionId.includes('sub') ? sectionId : undefined,
      questionText: values.questionText,
      answerText: values.answerText,
      quoteText: values.quoteText,
      thoughtText: values.thoughtText,
    };
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_RESET_TIME, value: true });
    if (noteId === 'create') {
      dispatch(createNote(data, async () => {
        await queryCache.invalidateQueries(`story-notes-${id}-${sectionId}-screen`);
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        history.replace(`/storynotes/${id}/${sectionId}`);
      }));
    } else {
      dispatch(updateNote(noteId, data, async () => {
        await queryCache.invalidateQueries(`story-notes-${id}-${sectionId}-screen`)
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        history.replace(`/storynotes/${id}/${sectionId}`);
      }));
    }
  };

  const onFinishFailed = (error: any) => {

  };

  const onTypeChange = (data: string) => {
    setValue(undefined);
    setType(data);
  };

  const getCorrectNoteType = (currType: any) => {
    switch (currType) {
      case 'TEXT':
        return (
          <Form.Item
            label="Text"
            name="text"
            initialValue={existingNote?.text}
          >
            <TextArea
              placeholder="Enter text here..."
              //onChange={onTextChange}
              autoSize={{ minRows: 4, maxRows: 8 }}
              allowClear
            />
          </Form.Item>
        );
      case 'QUESTION_ANSWER':
        return (
          <div className={styles.quoteThoughtContainer}>
            <div className={styles.textAreaContainer}>
              <Form.Item
                label="Question"
                name="questionText"
                initialValue={existingNote?.questionText}
              >
                <TextArea
                  className={styles.textArea}
                  placeholder="Enter question here..."
                  autoSize={{ minRows: 4, maxRows: 8 }}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className={styles.textAreaContainer}>
              <Form.Item
                label="Answer"
                name="answerText"
                initialValue={existingNote?.answerText}
              >
                <TextArea
                  className={styles.textArea}
                  placeholder="Enter answer here..."
                  autoSize={{ minRows: 4, maxRows: 8 }}
                  allowClear
                />
              </Form.Item>
            </div>
          </div>
        );
      case 'QUOTE_THOUGHT':
        return (
          <div className={styles.quoteThoughtContainer}>
            <div className={styles.textAreaContainer}>
              <Form.Item
                label="Quote"
                name="quoteText"
                initialValue={existingNote?.quoteText}
              >
                <TextArea
                  className={styles.textArea}
                  placeholder="Enter quote here..."
                  autoSize={{ minRows: 4, maxRows: 8 }}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className={styles.textAreaContainer}>
              <Form.Item
                label="Thought"
                name="thoughtText"
                initialValue={existingNote?.thoughtText}
              >
                <TextArea
                  className={styles.textArea}
                  placeholder="Enter thought here..."
                  autoSize={{ minRows: 4, maxRows: 8 }}
                  allowClear
                />
              </Form.Item>
            </div>
          </div>
        );
      default:
    }
  };

  // const onTextChange = (data: any) => {
  //   //setText(data.target.value);
  // };

  const onCategorySelect = (data: string) => {
    const item = categoryOptions.find((x) => x._id === data);
    if (!item) {
      return;
    }
    if (note?.goalCategories && item) {
      setNote({ ...note, goalCategories: [...note?.goalCategories, item] });
    } else {
      setNote({ ...note, goalCategories: [item] });
    }
  };

  const onNameChange = (event: any) => {
    setName(event.target.value);
  };

  const addItem = () => {
    if (name) {
      axios.post('/api/notes/category', { name })
        .then((response) => {
          setCategoryOptions([...categoryOptions, response.data]);
          setName('');
        }).catch((error) => {
          console.log('error: ', error);
        });
    }
  };

  const onLocationChange = (data: any) => {
    setLocation(data);
  };

  const onStartMinutesChange = (data: any) => {
    setStartMinutes(data);
  };

  const onEndMinutesChange = (data: any) => {
    setEndMinutes(data);
  };

  const onStartSecondsChange = (data: any) => {
    setStartSeconds(data);
  };

  const onLocationTypeChange = (data: any) => {
    setLocationType(data);
  };

  return ((noteId === 'create' || existingNote)
    ? (
      <div className={styles.StorynotesNotesCreateScreen}>
        <div className={styles.container}>
          <div className={styles.header}>
            <Button
              className={styles.goBack}
              type="link"
              size="large"
              icon={<ArrowLeftOutlined />}
              shape="circle"
              onClick={onBackClick}
            />
            <div className={styles.name}>
              {titleText}
              {' '}
              Note
            </div>
          </div>
          <div className={styles.form}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                className={styles.selectItem}
                label="Title"
                name="title"
                initialValue={existingNote?.title}
                rules={[{ required: true, message: 'Please input a note title!' }]}
              >
                <Input
                  size="large"
                  allowClear
                />
              </Form.Item>
              <div>
                <Form.Item
                  className={styles.selectItem}
                  label="Category"
                  name="noteCategories"
                  initialValue={existingNote?.noteCategories?.map((val) => val._id)}
                >
                  <Select
                    className="item-name-id"
                    mode="multiple"
                    size="large"
                    placeholder="Select categories"
                    onSelect={onCategorySelect}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input
                            size="large"
                            style={{ flex: 'auto' }}
                            value={name}
                            onChange={onNameChange}
                          />
                          <a
                            style={{
                              flex: 'none', padding: '8px', display: 'block', cursor: 'pointer',
                            }}
                            onClick={addItem}
                          >
                            <PlusOutlined />
                            {' '}
                            Add item
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {categoryOptions?.map((item) => (
                      <Select.Option key={item._id} value={item._id}>{item.displayName}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              {
                currentStoryNote?.medium?.typeValue === 0
                && (
                <Form.Item
                  className={styles.switchItem}
                  label={locationType ? 'Audio' : 'Book'}
                  name="locationType"
                >
                  <Switch
                    checkedChildren={<AudioOutlined />}
                    unCheckedChildren={<ReadOutlined />}
                    checked={locationType}
                    onChange={onLocationTypeChange}
                  />
                </Form.Item>
                )
              }
              {
                (currentStoryNote?.medium?.typeValue !== 0 || locationType)
                  ? (
                    <div className={styles.rangeContainer}>
                      <div className={styles.startItem}>
                        <Form.Item
                          className={styles.minutesItem}
                          label="Start Minutes"
                          name="startMinutes"
                          initialValue={existingNote?.start?.minutes || 0}
                        >
                          <InputNumber
                            className={styles.locationInput}
                            size="large"
                            min={0}
                            onChange={onStartMinutesChange}
                          />
                        </Form.Item>
                        <Form.Item
                          className={styles.secondsItem}
                          label="Start Seconds"
                          name="startSeconds"
                          initialValue={existingNote?.start?.seconds || 0}
                        >
                          <InputNumber
                            className={styles.locationInput}
                            size="large"
                            min={0}
                            max={59}
                            onChange={onStartSecondsChange}
                          />
                        </Form.Item>
                      </div>
                      <ArrowRightOutlined />
                      <div className={styles.endItem}>
                        <Form.Item
                          className={styles.minutesItem}
                          label="End Minutes"
                          name="endMinutes"
                          initialValue={existingNote?.end?.minutes || 0}
                        >
                          <InputNumber
                            className={styles.locationInput}
                            size="large"
                            min={startMinutes}
                            onChange={onEndMinutesChange}
                          />
                        </Form.Item>
                        <Form.Item
                          className={styles.secondsItem}
                          label="End Seconds"
                          name="endSeconds"
                          initialValue={existingNote?.end?.seconds || 0}
                        >
                          <InputNumber
                            className={styles.locationInput}
                            size="large"
                            min={startMinutes === endMinutes ? startSeconds : 0}
                            max={59}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Form.Item
                        className={styles.secondsItem}
                        label="Page Number"
                        name="location"
                        initialValue={existingNote?.location || 0}
                      >
                        <InputNumber
                          className={styles.locationInput}
                          size="large"
                          min={0}
                          onChange={onLocationChange}
                        />
                      </Form.Item>
                    </div>
                  )
              }
              <div className={styles.selectItems}>
                <Form.Item
                  className={styles.selectItem}
                  label="Type"
                  name="type"
                  initialValue={existingNote?.type || 'TEXT'}
                >
                  <Select size="large" onChange={onTypeChange}>
                    <Select.Option value="TEXT">Text</Select.Option>
                    <Select.Option value="QUOTE_THOUGHT">Quote & Thought</Select.Option>
                    <Select.Option value="QUESTION_ANSWER">Question & Answer</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              {
                getCorrectNoteType(type)
              }
              <Form.Item>
                <Button
                  className={styles.createButton}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {titleText}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    ) : <div />
  );
}

export default StorynotesNotesCreateScreen;
