import React from 'react';
import styles from './Classic.module.scss';

function Classic(props:any) {
    return (
        <div className={styles.Classic} >
            <div className={styles.container}>
                <div className={styles.title}>
                    Example of quote here
                </div>
                <div className={styles.source}>
                    <div>
                        <i>author</i>
                    </div>
                    <div>
                        book name
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Classic;
