import React from 'react';
import styles from './HowItWorksItem.module.scss';
//import book from '../../../../assets/images/book4_red.svg';
//import book from '../../../../assets/images/book5_red.svg';
//import book from '../../../../assets/images/book7_red.svg';
import book from '../../../../assets/images/quiz_1.svg';
import {Button} from "antd";

function HowItWorksItem(props:any) {

    const viewSite = () => {

    };

    return (
        <div className={styles.HowItWorksItem} >
            <div className={styles.textContainer}>
                <div className={styles.step} >
                    {props.product.reason}
                </div>
                <div className={styles.title} >
                    {props.product.title}
                </div>
                <div className={styles.subtitle}>
                    {props.product.subtitle}
                </div>
            </div>
            <div className={styles.imageContainer}>
                <img className={styles.image}
                     src={props.product.image?.replace("http://", "https://")}
                     alt={props.product.image?.replace("http://", "https://")}
                />
            </div>
        </div>
    );
}

export default HowItWorksItem;
