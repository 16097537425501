import {Dispatch} from "redux";
import axios from "axios";
import {ThumbnailStyles} from "../models/ThumbnailStyles";

export const updateThumbnail = (id: string, thumbnail: ThumbnailStyles | null, imageData: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
    const postData = JSON.stringify(thumbnail);
    const formData = new FormData();
    formData.append('data', postData);
    formData.append('thumbnail', imageData);
    axios.put(`/api/posts/${id}/thumbnail`, formData)
        .then((response) => {
            callback();
        }).catch((error) => {
            console.log('error: ', error);
    });
};