import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Badge, Button, Input, Spin, Tabs,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {useInfiniteQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import styles from './club-discussions-screen.module.scss';
import FriendUserCardSkeleton from '../NewChatScreen/FriendUserCardSkeleton/FriendUserCardSkeleton';
import { Topic } from '../../models/Topic';
import TopicDiscussionCard from './topic-discussion-card/topic-discussion-card';
import StandTab from '../stands-screen/stand-tab/stand-tab';
import TopicTab from './topic-tab/topic-tab';
import { joinTopic } from '../../services-api/clubTopicsService';
import { ActionTypes } from '../../actions/types';
import { StoreState } from '../../reducers/_RootReducers';
import { TopicUser } from '../../models/TopicUser';
import Empty from "../../components/Empty/Empty";

const { Search } = Input;
const { TabPane } = Tabs;
function ClubDiscussionsScreen() {
  const { id, discussionId } = useParams<{ id: string, discussionId: string }>();
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [isAtTheEndYours, setIsAtTheEndYours] = useState(false);
  const lastCreatedScreen = useSelector<StoreState, string>((state) => state.lastCreatedScreen);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy] = useState('updatedAt');
  const [yourSortBy] = useState('topic.updatedAt')
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const onCreateClick = () => {
    history.push(`/storyclubs/${id}/discussions/create`);
  };

  const {
    data: yourData,
    isFetching: yourIsFetching,
    fetchMore: yourFetchMore,
  } = useInfiniteQuery(
    `club-topics-screen-your-${id}-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/clubs/topics/club/yours/${id}?search=${searchQuery}&sortBy=${yourSortBy}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}&type=DISCUSSION`);
      if (data?.topicUsers?.length < limit) {
        setIsAtTheEndYours(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `club-topics-screen-all-${id}-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/clubs/topics/club/${id}?search=${searchQuery}&limit=${limit}&sortBy=${sortBy}&page=${nextId}&type=DISCUSSION${lastCreatedScreen !== 'club-discussion-create-screen' ? `&timestamp=${timeStamp}`: ''}`);
      if (data?.topics?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onSearch = (data: string) => {
    setSearchQuery(data);
  };

  const onTopicClick = (topicId: string) => {
    history.push(`/storyclubs/${id}/discussions/${topicId}`);
  };

  const onJoinClick = (event: any, topicId: string) => {
    event.stopPropagation();
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: topicId });
    dispatch(joinTopic(topicId, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'x' });
      queryCache.invalidateQueries(`club-topics-screen-all-${id}-${searchQuery}`);
      queryCache.invalidateQueries(`club-topics-screen-your-${id}-${searchQuery}`);
    }));
  };

  const onBackClick = () => {
    history.goBack();
  };

  const callback = (key: string) => {
    history.replace(`/storyclubs/${id}/discussions/${key}`)
  };

  return (
    <div className={styles.ClubDiscussionsScreen}>
      <div className={styles.headerContainer}>
        <div className={styles.headerWhiteboard}>
          <div className={styles.messageHeader}>
            <div className={styles.topHeader}>
              <Button
                className={styles.goBack}
                icon={<ArrowLeftOutlined />}
                type="link"
                onClick={onBackClick}
              />
              <div className={styles.titleHeader}>
                Discussions
              </div>
              <div className={styles.create}>
                <Button
                  size="large"
                  shape="circle"
                  type="primary"
                  onClick={onCreateClick}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </div>
            <div className={styles.searchContainer}>
              <Search
                className={styles.searchInput}
                placeholder="Search for topics"
                allowClear
                size="large"
                onSearch={onSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <Tabs
          tabBarGutter={0}
          className={styles.tabs}
          defaultActiveKey={discussionId || "your"}
          onChange={callback}
        >
          <TabPane
            tab={<TopicTab key="your" name="Your Topics" />}
            key="your"
          >
            {
              yourIsFetching === false &&  yourData?.map((page) => page.topicUsers).flat().length === 0 &&
              <Empty
                  title="Join your first discussion"
                  subTitle="You haven't joined any discussions"
                  icon="search"
                  buttonTitle="View all topics"
                  url={`/storyclubs/${id}/discussions/`}
                  hideButton={true}
                  screen="discussion"
              />
            }
            {
              yourIsFetching && !yourData
                ? (
                  [1, 1, 1, 1, 1, 1].map((item, index) => <FriendUserCardSkeleton key={`${index}`} />)
                ) : (
                  <InfiniteScroll
                    dataLength={yourData?.length || 0}
                    next={() => yourFetchMore()}
                    hasMore={(yourData?.length || 0) > 0 && !isAtTheEndYours}
                    loader={(
                      <div className={styles.spinningContainer}>
                        <Spin />
                      </div>
                          )}
                    endMessage={(<p style={{ textAlign: 'center' }} />)}
                  >
                    {
                      yourData?.map((page) => page.topicUsers).flat()
                              .map((item: TopicUser, index: number) => (
                                <TopicDiscussionCard
                                  key={`topic-card-${index}`}
                                  topic={item.topic}
                                  notificationCount={item.notificationCount}
                                  onTopicClick={(id: string) => onTopicClick(id)}
                                  type="yours"
                                  isLoading={isLoading}
                                  currentId={currentId}
                                />
                              ))
                    }
                  </InfiniteScroll>
                )
            }
          </TabPane>
          <TabPane
            tab={<TopicTab key="all" name="All Topics" />}
            key="all"
          >
            {
              isFetching === false &&  data?.map((page) => page.topics).flat().length === 0 &&
              <Empty
                  title="Start the first discussion"
                  subTitle="Start discussions with friends"
                  icon="plus"
                  buttonTitle="Start discussion"
                  url={`/storyclubs/${id}/discussions/create`}
                  screen="posts"
              />
            }
            {
              isFetching && !data
                ? (
                  [1, 1, 1, 1, 1, 1].map((item, index) => <FriendUserCardSkeleton key={`${index}`} />)
                ) : (
                  <InfiniteScroll
                    dataLength={data?.length || 0}
                    next={() => fetchMore()}
                    hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
                    loader={(
                      <div className={styles.spinningContainer}>
                        <Spin />
                      </div>
                          )}
                    endMessage={(<p style={{ textAlign: 'center' }} />)}
                  >
                    {
                          data?.map((page) => page.topics).flat()
                              .map((item: Topic, index: number) => (
                                <TopicDiscussionCard
                                  key={`topic-card-${index}`}
                                  topic={item}
                                  onTopicClick={(id: string) => onTopicClick(id)}
                                  onJoinClick={onJoinClick}
                                  isLoading={isLoading}
                                  currentId={currentId}
                                  currentUsersTopicIds={data?.map((page) => page.currentUsersTopicIds).reduce((result, current) => Object.assign(result, current), {})}
                                  type="all"
                                />
                              ))
                        }
                  </InfiniteScroll>
                )
            }
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default ClubDiscussionsScreen;
