import React, { useEffect, useState } from 'react';
import {
  Button, Dropdown, Input, Menu, Modal, Skeleton, Spin,
} from 'antd';
import ReactCrop from 'react-image-crop';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import {useInfiniteQuery, useQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import {
  SendOutlined, PlusOutlined, ArrowLeftOutlined, FileImageOutlined, CameraOutlined,
} from '@ant-design/icons';
import { User } from '../../models/User';
import { StoreState } from '../../reducers/_RootReducers';
import styles from './club-discussion-screen.module.scss';
import { ActionTypes } from '../../actions/types';
import TopicPostCard from './topic-post-card/topic-post-card';
import { TopicPost } from '../../models/TopicPost';
import { like, unlike, updateLike } from '../../services-api/clubTopicsService';

const { TextArea } = Input;
function ClubDiscussionScreen() {
  const { id, discussionId } = useParams<{ id: string, discussionId: string }>();
  let globalFileInput: any;
  const [file, setFile] = useState<Blob | null>(null);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const reactionIds = useSelector<StoreState, any>((state) => state.reactionIds);
  const currentUser = useSelector<StoreState, User | null>((state) => state.currentUser);
  const isReactionsOpen = useSelector<StoreState, boolean>((state) => state.isReactionsOpen);
  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(12);
  const [message, setMessage] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [cropData, setCropData] = useState({
    image: 'your-image-url or as base64',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
  });
  const [cropD, setCropD] = useState<any>({
    src: null,
    crop: {
      aspect: 16 / 9,
    },
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const { data: currentTopic } = useQuery(`topic-${discussionId}`, () => axios.get(`/api/clubs/topics/${discussionId}`)
    .then((response) => response.data));

  useEffect(() => () => {
    dispatch({ type: ActionTypes.SET_CURRENT_CHAT_USER, value: null });
    dispatch({ type: ActionTypes.SET_CURRENT_CHAT_ROOM, value: null });
  }, []);

  const onInputChange = (data: any) => {
    setMessage(data.target.value);
  };

  const onSendClick = () => {
    if (currentTopic) {
      const data = new FormData();

      data.append('topic', currentTopic._id as string);

      if (message) {
        data.append('message', message);
      }

      if (file != null) {
        data.append('avatar', file, 'temp');
      }

      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      axios.post(`/api/clubs/topics/posts/${id}/${discussionId}`, data)
        .then((response) => {
          const newTopicPost = response.data;
          newTopicPost.user = currentUser;

          queryCache.setQueryData(`club-topic-posts-${discussionId}`, (oldData: any) => {
            if (oldData) {
              return oldData.map((x: any) => ({ ...x, posts: [newTopicPost, ...x.posts] }));
            }
            return [{ posts: [newTopicPost], nextId: 1 }];
          });
          dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
          if (message) {
            setMessage('');
          }
          setFile(null);
        }).catch((error) => {
          console.log('error: ', error);
        });
    }
  };

  const onBackClick = () => {
    history.goBack();
  };

  const {
    data,
    fetchMore,
  } = useInfiniteQuery(
    `club-topic-posts-${discussionId}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/clubs/topics/posts/${discussionId}?limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, ...data?.reactionIds } });

      if (data?.posts?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onPostClick = (postId: string) => {

  };

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsImageModalOpen(true);
      setFile(event.target.files[0]);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item className={styles.uploadOptionItem}>
        <CameraOutlined className={styles.uploadIcon} />
        <div className={styles.uploadText}>
          Camera
        </div>
      </Menu.Item>
      <Menu.Item className={styles.uploadOptionItem} onClick={() => globalFileInput.click()}>
        <FileImageOutlined className={styles.uploadIcon} />
        <div className={styles.uploadText}>
          <input
            style={{ display: 'none' }}
            type="file"
            name="avatar"
            onChange={fileSelectedHandler}
            ref={(fileInput) => globalFileInput = fileInput}
            accept=".jpeg,.png"
          />
          Photo
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleOk = () => {

  };

  const handleCancel = () => {

  };

  const onCropChange = (crop: any) => {
    setCropD({ ...cropD, crop });
  };

  const onCancel = () => {
    setFile(null);
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    console.log(croppedArea, croppedAreaPixels);
  };

  const onZoomChange = (zoom: any) => {
    setCropData({ ...cropData, zoom });
  };

  const onImageLoaded = (image: any) => {
    // this.imageRef = image;
  };

  const updatePost = (post: TopicPost, type: string) => {
    if (type === 'like') {
      return { ...post, likesCount: (post?.likesCount || 0) + 1 };
    }

    if (type === 'love') {
      return { ...post, lovesCount: (post?.lovesCount || 0) + 1 };
    }

    if (type === 'lol') {
      return { ...post, laughsCount: (post?.laughsCount || 0) + 1 };
    }

    dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${post?._id}`]: { typeText: type } } });
    return post;
  };

  const removePost = (post: TopicPost, type: string, existingReaction: any) => {
    let updatedPost = { ...post };
    if (type === existingReaction.typeText) {
      if (type === 'like') {
        updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) - 1 };
      }

      if (type === 'love') {
        updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) - 1 };
      }

      if (type === 'lol') {
        updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) - 1 };
      }

      return updatedPost;
    }

    return post;
  };

  const updateExistingPost = (post: TopicPost, type: string, existingReaction: any) => {
    let updatedPost = { ...post };

    if (type === existingReaction.typeText) {
      if (type === 'like') {
        updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) - 1 };
      }

      if (type === 'love') {
        updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) - 1 };
      }

      if (type === 'lol') {
        updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) - 1 };
      }
      const updatedReactionIds = _.omit(reactionIds, `${post._id}`);
      dispatch({ type: ActionTypes.SET_REACTION_IDS, value: updatedReactionIds });
      return updatedPost;
    }

    if (type === 'like') {
      updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) + 1 };
    }

    if (type === 'love') {
      updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) + 1 };
    }

    if (type === 'lol') {
      updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) + 1 };
    }

    if (existingReaction.typeText === 'like') {
      updatedPost = { ...updatedPost, likesCount: updatedPost!.likesCount! - 1 };
    }

    if (existingReaction.typeText === 'love') {
      updatedPost = { ...updatedPost, lovesCount: updatedPost!.lovesCount! - 1 };
    }

    if (existingReaction.typeText === 'lol') {
      updatedPost = { ...updatedPost, laughsCount: updatedPost!.laughsCount! - 1 };
    }

    dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${post?._id}`]: { typeText: type } } });

    return updatedPost;
  };

  const onReactionAction = (tp: TopicPost, type: string) => {
    queryCache.setQueryData(`club-topic-posts-${discussionId}`, (oldData: any) => {
      if (oldData) {
        dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${tp?._id}`]: { typeText: type } } });
        return oldData.map((x: any) => ({
          ...x,
          posts: x.posts.map((post: TopicPost) => {
            if (post._id === tp._id) {
              if (reactionIds[`${post._id}`]) {
                return updateExistingPost(post, type, reactionIds[`${post._id}`]);
              }
              return updatePost(post, type);
            }
            return post;
          }),
        }));
      }
      return oldData;
    });

    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
    dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: false });

    if (reactionIds[`${tp._id}`]) {
      if (type === reactionIds[`${tp._id}`].typeText) {
        console.log('louis unlike');
        dispatch(unlike(tp, type, () => {
        }));
      } else {
        console.log('louis update like');
        dispatch(updateLike(tp, type, () => {
        }));
      }
    } else {
      console.log('louis like');
      dispatch(like(tp, type, () => {
      }));
    }
  };

  const onReactionClick = (tp: TopicPost) => {
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: tp._id });
    dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: true });
  };

  return (currentUser
      && (
      <div className={styles.ClubDiscussionScreen}>
        <Modal
          title={<div style={{ padding: '16px' }}>Upload Image</div>}
          style={{ overflow: 'hidden' }}
          bodyStyle={{ padding: '0px' }}
          visible={!!file}
          closable
          maskClosable
          onCancel={onCancel}
          centered
          footer={(
            <div className={styles.modalFooter}>
              <TextArea
                className={styles.textArea}
                autoSize={{ minRows: 1, maxRows: 5 }}
                placeholder="Type here..."
                onChange={onInputChange}
                value={message}
                disabled={isLoading}
              />
              <Button
                icon={<SendOutlined className={styles.sendButton} />}
                type="link"
                onClick={onSendClick}
                loading={isLoading}
              />
            </div>
                  )}
        >
          <div className={styles.imageUploadPreview}>
            {
                          file
                            ? (
                          // <img
                          //   className={styles.image}
                          //   src={file ? URL.createObjectURL(file) : ''}
                          //   //alt={currentUser.image}
                          // />
                              <ReactCrop
                                className={styles.image}
                                src={URL.createObjectURL(file)}
                                imageStyle={{ width: '100%' }}
                                crop={cropD.crop}
                                ruleOfThirds
                                onImageLoaded={onImageLoaded}
                                onComplete={onCropComplete}
                                onChange={onCropChange}
                              />
                            )
                            : <Spin size="large" />
                      }
          </div>
        </Modal>
        <div className={styles.chatHeader}>
          <div className={styles.back}>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={onBackClick}
            />
          </div>
          {
                      currentTopic
                        ? (
                          <div className={styles.content}>
                            <div className={styles.name}>
                              #
                              {currentTopic?.name}
                            </div>
                          </div>
                        )
                        : (
                          <div className={styles.content}>
                            <Skeleton.Input style={{ width: 200 }} active size="large" />
                          </div>
                        )
                  }
        </div>
        <div className={styles.container}>
          <div
            id="scrollableDiv"
            className={styles.messagesContainer}
          >
            <InfiniteScroll
              style={{ display: 'flex', flexDirection: 'column-reverse' }}
              dataLength={data?.map((page) => page.messages).flat().length || 0}
              next={() => fetchMore()}
              hasMore={(data?.map((page) => page.messages).flat().length || 0) > 0 && !isAtTheEnd}
              loader={(
                <div className={styles.spinningContainer}>
                  <Spin />
                </div>
                          )}
              endMessage={(<p style={{ textAlign: 'center' }} />)}
              inverse
              scrollableTarget="scrollableDiv"
              scrollThreshold={0.3}
            >
              {
                data?.map((page) => page.posts).flat().map((topicPost, index) => (
                  <TopicPostCard
                    key={`post-card-${index}`}
                    topicPost={topicPost}
                    onPostClick={onPostClick}
                    onReactionAction={onReactionAction}
                    isReactionsOpen={isReactionsOpen}
                    onReactionClick={onReactionClick}
                    currentId={currentId}
                    reactionIds={reactionIds}
                  />
                ))
              }
            </InfiniteScroll>
          </div>
          <div className={styles.inputContainer}>
            <Dropdown overlay={menu} placement="topLeft" trigger={['click']}>
              <PlusOutlined className={styles.addButton} />
            </Dropdown>
            <TextArea
              className={styles.textArea}
              autoSize={{ minRows: 1, maxRows: 5 }}
              placeholder="Type here..."
              onChange={onInputChange}
              value={message}
              disabled={isLoading}
            />
            <Button
              icon={<SendOutlined className={styles.sendButton} />}
              type="link"
              onClick={onSendClick}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
      )
  );
}

export default ClubDiscussionScreen;
