import { Dispatch } from 'redux';
import axios from 'axios';
import cookies from 'js-cookie';
import ErrorModal from '../components/Modals/Error/Error';
import { ActionTypes } from '../actions/types';
/* eslint-disable @typescript-eslint/no-unused-vars */
export const access = (username: string, access: string, callback: () => void) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/auth/access', { username, access })
    .then((response) => {
      if (response.data.token) {
        const in1Minutes = (2 / 48) / 10;
        cookies.set('goodlores-access', response.data.token, { expires: in1Minutes });
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        callback();
      }
    }).catch((error) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      ErrorModal('Unable to sign user up. Please try again.');
    });
};

export const signup = (isSocial: boolean, username: string, access: string, callback: () => void) => (dispatch: Dispatch, getState: Function) => {
  axios.post(`/api/auth/login/${isSocial && username}`, { username, access })
    .then((response) => {
      if (response.data.token) {
        cookies.set('goodlores-token', response.data.token, { expires: 7 });
        callback();
      }
    }).catch((error) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      ErrorModal("Unable to authenticate user. Username or password doesn't match. Please try again.");
    });
};

export const signupWithEmail = (name: string, email: string, callback: () => void) => (dispatch: Dispatch, getState: Function) => {
  if (cookies.get('goodlores-access')) {
    axios.defaults.headers.common = {
      'goodlores-access': cookies.get('goodlores-access'),
    };
  }
  axios.post('/api/auth/signup/email', { name, email })
    .then((response) => {
      callback();
    }).catch((error) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      ErrorModal('Unable to sign user up. Please try again.');
    });
};

export const login = (isSocial: boolean, username: string, access: string, callback: () => void) => (dispatch: Dispatch, getState: Function) => {
  axios.post(`/api/auth/login/${isSocial && username}`, { username, access })
    .then((response) => {
      if (response.data.token) {
        cookies.set('goodlores-token', response.data.token, { expires: 7 });
        callback();
      }
    }).catch((error) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      ErrorModal("Unable to authenticate user. Username or password doesn't match. Please try again.");
    });
};

export const loginWithEmail = (email: string, callback: () => void) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/auth/login/email', { email })
    .then((response) => {
      callback();
    }).catch((error) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      ErrorModal('Unable to authenticate user. Please try again.');
    });
};
