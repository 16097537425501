import React from 'react';
import { Progress } from 'antd';
import styles from './RatingItemPodcastHome.module.scss';
import { Post } from '../../models/Post';
import { convertRatingToColor } from '../../helpers/helpers';

type RatingItemPodcastHomeProps = {
  item: Post;
};

function RatingItemPodcastHome(props: RatingItemPodcastHomeProps) {
  const { item } = props;
  const { medium } = item;

  return (
    <div className={styles.RatingItemPodcastHome}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <Progress
            className={styles.ratingProgress}
            width={100}
            type="circle"
            strokeColor={{
              '0%': convertRatingToColor(item.rating),
              '100%': convertRatingToColor(item.rating),
            }}
            percent={((item.rating / 10) * 100)}
            format={(percent) => (
              <span>
                <span className={styles.ratingValue}>{item.rating}</span>
                <span className={styles.outOf}>/10</span>
              </span>
            )}
          />
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={item.medium.medium.imageUrl.replace('http://', 'https://')}
              alt={item.medium.medium.imageUrl.replace('http://', 'https://')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingItemPodcastHome;
