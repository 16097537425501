import React from 'react';
import styles from './one-user.module.scss';
import elon from '../../../../../assets/images/elon.jpeg';

type OneUserProps = {
  image: any;
  username: string;
  text: string;
};

function OneUser(props: OneUserProps) {
  const { image, username, text } = props;
  return (
    <div className={styles.OneUser}>
      <div className={styles.profileHeader}>
        <img
          className={styles.profileImage}
          src={image}
          alt=""
        />
        <div className={styles.username}>
            {username}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.postInfo}>
            {text}
        </div>
      </div>
    </div>
  );
}

export default OneUser;
