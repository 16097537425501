import React, { useState} from 'react';
import {Button, InputNumber, Modal, Slider, Checkbox} from "antd";
import styles from "./FontModalMain.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {ActionTypes} from "../../../actions/types";

interface FontModalProps {
    callback: Function;
}

function FontModalMain(props: FontModalProps) {

    const fontModalDataMain = useSelector<StoreState, any>(state => state.fontModalDataMain);
    const defaultList = fontModalDataMain.isBold ? fontModalDataMain.isItalic ? ['Bold','Italic'] : ['Bold'] : fontModalDataMain.isItalic ? ['Italic']: [];
    const [selectedOption, setSelectedOptions] = useState<string[]>([]);
    const isFontModalMainVisible = useSelector<StoreState, boolean>(state => state.isFontModalMainVisible);
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch({ type: ActionTypes.TOGGLE_FONT_MODAL_MAIN });
    };

    const onBoldnessChange = (data: any[]) => {
        setSelectedOptions(data)
    };

    const onSaveClick = () => {
        props.callback(fontModalDataMain.fontSize, selectedOption.includes('Bold'), selectedOption.includes('Italic'));
        handleCancel();
    };

    const onFontSizeChange = (data: any) => {
        dispatch({ type: ActionTypes.SET_FONT_MODAL_DATA_MAIN, data: {...fontModalDataMain, fontSize: data as number} } );
    };

    const plainOptions = ['Bold', 'Italic'];

    return (
        <Modal
            visible={isFontModalMainVisible}
            onCancel={handleCancel}
            bodyStyle={{padding: '0px !important'}}
            closable={false}
            centered={true}
            title={null}
            footer={null}
        >
            <div className={styles.FontModalMain}>
                <div className={styles.fontTitle}>
                    Font
                </div>
                <div className={styles.fontContainer}>
                    <Slider
                        className={styles.fontSlider}
                        min={1}
                        max={50}
                        onChange={onFontSizeChange}
                        defaultValue={parseInt(fontModalDataMain.fontSize)}
                        value={parseInt(fontModalDataMain.fontSize)}
                    />
                    <InputNumber
                        min={1}
                        max={50}
                        style={{ marginLeft: 16 }}
                        onChange={onFontSizeChange}
                        defaultValue={parseInt(fontModalDataMain.fontSize)}
                        value={parseInt(fontModalDataMain.fontSize)}
                        size="large"
                    />
                </div>
                <Checkbox.Group
                    className={styles.otherOptions}
                    options={plainOptions}
                    defaultValue={defaultList}
                    value={selectedOption}
                    onChange={onBoldnessChange}
                />
                <div className={styles.actions}>
                    <Button className={styles.saveButton} type="primary" onClick={onSaveClick} >Save</Button>
                </div>
            </div>
        </Modal>
    );
}

export default FontModalMain;
