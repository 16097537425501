import { Dispatch } from 'redux';
import axios from 'axios';

export const createReminder = (data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/reminder', data)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateReminder = (data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put('/api/reminder', data)
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const getReminder = (callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.get('/api/reminder')
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};
