import React from 'react';
import styles from './header.module.scss';
type HeaderProps = {
  image: any;
  title: string;
  minute: string;
};

function Header(props: HeaderProps) {
  const { image, title, minute } = props;
  return (
    <div className={styles.Header}>
      <img
        className={styles.image}
        src={image}
        alt=""
      />
      <div className={styles.supporter}>
        {title}
      </div>
      <div className={styles.gameTime}>
        {minute}
      </div>
    </div>
  );
}

export default Header;
