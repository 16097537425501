import React from 'react';
import { Badge, Button } from 'antd';
import styles from './topic-discussion-card.module.scss';
import { Topic } from '../../../models/Topic';

type TopicCardProps = {
  topic: Topic;
  onTopicClick: (id: string) => void;
  onJoinClick?: (event: any, topicId: string) => void;
  type: string;
  isLoading: boolean;
  currentId: string;
  currentUsersTopicIds?: { [key: string]: string }
  notificationCount?: number;
};

function TopicDiscussionCard(props: TopicCardProps) {
  const {
    topic, onTopicClick, onJoinClick, type, isLoading, currentId,
    currentUsersTopicIds, notificationCount,
  } = props;

  const showCorrectBox = () => {
    if (type === 'all') {
      if (currentUsersTopicIds && currentUsersTopicIds[`${topic._id}`]) {
        return (
          <Button
            type="link"
          >
            Joined
          </Button>
        );
      }

      if (onJoinClick) {
        return (
          <Button
            type="primary"
            onClick={(event: any) => onJoinClick(event, topic._id)}
            loading={isLoading && currentId === topic._id}
          >
            Join
          </Button>
        );
      }
    }

    if (type === 'yours') {
      return (
        <Badge
          className={styles.badge}
          count={notificationCount || 0}
          overflowCount={100}
        />
      );
    }
  };

  return (
    <div
      className={styles.TopicDiscussionCard}
      onClick={() => onTopicClick(topic?._id)}
    >
      <div className={styles.textInfo}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            #
            {topic?.name}
          </div>
        </div>
        <div className={styles.subtitle}>
          {topic?.description}
        </div>
      </div>
      <div className={styles.actionBox}>
        {
          showCorrectBox()
        }
      </div>
    </div>
  );
}

export default TopicDiscussionCard;
