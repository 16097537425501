import React from 'react';
import styles from './commentary-card.module.scss';
import Header from '../../stadium-stats/header/header';
import microphone from '../../../../../assets/icons/icons8-microphone-64.png';
import OneUser from '../../stadium-stats/one-user/one-user';
import elon from '../../../../../assets/images/elon.jpeg';
import Footer from '../../stadium-stats/footer/footer';

function CommentaryCard() {
  return (
    <div className={styles.CommentaryCard}>
      <Header
        image={microphone}
        title="Commentary"
        minute="85'"
      />
      <div className={styles.commentary}>
        Lebron James is asking the refs how that wasn&apos;t a foul. Things are getting heated in Miami.
      </div>
      <Footer />
    </div>
  );
}

export default CommentaryCard;
