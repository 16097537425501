import React from 'react';
import { Skeleton } from 'antd';
import styles from './SummaryCardSkeleton.module.scss';

function SummaryCardSkeleton() {
  return (
    <div className={styles.SummaryCardSkeleton}>
      <Skeleton active paragraph={{ rows: 12 }} />
    </div>
  );
}

export default SummaryCardSkeleton;
