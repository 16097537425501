import React from 'react';
import styles from './goal-header.module.scss';
import baylor from '../../../../../assets/images/baylor.png';
import gonzaga from '../../../../../assets/images/gonzaga.jpg';

type HeaderProps = {
  image: any;
  title: string;
  minute: string;
};

function GoalHeader(props: HeaderProps) {
  const { image, title, minute } = props;
  return (
    <div className={styles.GoalHeader}>
      <img
        className={styles.image}
        src={image}
        alt=""
      />
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.gameTime}>
        {minute}
      </div>
      <div className={styles.scoreContainer}>
        <img
          className={styles.teamImage}
          src={baylor}
          alt=""
        />
        <div className={styles.score}>
          <strong>100</strong>
        </div>
        <div className={styles.divider}>
          -
        </div>
        <div className={styles.score}>
          102
        </div>
        <img
          className={styles.teamImage}
          src={gonzaga}
          alt=""
        />
      </div>
    </div>
  );
}

export default GoalHeader;
