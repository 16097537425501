import React from 'react';
import { Progress, Typography } from 'antd';
import styles from './RatingItemPodcast.module.scss';
import { convertRatingToColor } from '../../helpers/helpers';

interface RatingItemProps {
  item: any;
}

const { Paragraph } = Typography;
function RatingItemPodcast(props: RatingItemProps) {
  const { item } = props;
  return (
    <div className={styles.RatingItemPodcast}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <Progress
            className={styles.ratingProgressMobile}
            width={50}
            type="circle"
            strokeColor={{
              '0%': convertRatingToColor(item.rating),
              '100%': convertRatingToColor(item.rating),
            }}
            percent={((item.rating / 10) * 100)}
            format={() => (
              <span>
                <span className={styles.ratingValue}>{item.rating}</span>
                <span className={styles.outOf}>/10</span>
              </span>
            )}
          />
          <Progress
            className={styles.ratingProgressTablet}
            width={60}
            type="circle"
            strokeColor={{
              '0%': convertRatingToColor(item.rating),
              '100%': convertRatingToColor(item.rating),
            }}
            percent={((item.rating / 10) * 100)}
            format={() => (
              <span>
                <span className={styles.ratingValue}>{item.rating}</span>
                <span className={styles.outOf}>/10</span>
              </span>
            )}
          />
        </div>
        <div className={styles.rightContainer}>
          <img
            className={styles.image}
            src={item.medium.medium.imageUrl.replace('http://', 'https://')}
            alt={item.medium.medium.imageUrl.replace('http://', 'https://')}
          />
        </div>
      </div>
      <Paragraph className={styles.title} ellipsis>
        {item.medium.title}
      </Paragraph>
    </div>
  );
}

export default RatingItemPodcast;
