import { ActionTypes } from '../actions/types';
import { StoryNote } from '../models/StoryNote';
import { Note } from '../models/Note';

interface setCurrentStoryNote {
  type: typeof ActionTypes.SET_CURRENT_STORY_NOTE;
  value: StoryNote;
}

interface setCurrentNote {
  type: typeof ActionTypes.SET_CURRENT_NOTE;
  value: Note;
}

interface setCurrentPreviewNote {
  type: typeof ActionTypes.SET_CURRENT_PREVIEW_NOTE;
  value: Note;
}

interface setCurrentDisplayNote {
  type: typeof ActionTypes.SET_DISPLAY_NOTE;
  value: Note;
}

type storyNotesActions = setCurrentStoryNote | setCurrentNote | setCurrentPreviewNote
| setCurrentDisplayNote;

export const currentStoryNote = (state: StoryNote | null = null, action: storyNotesActions) => {
  if (action.type === ActionTypes.SET_CURRENT_STORY_NOTE) {
    return action.value;
  }
  return state;
};

export const currentNote = (state: Note | null = null, action: storyNotesActions) => {
  if (action.type === ActionTypes.SET_CURRENT_NOTE) {
    return action.value;
  }
  return state;
};

export const currentPreviewNote = (state: Note | null = null, action: storyNotesActions) => {
  if (action.type === ActionTypes.SET_CURRENT_PREVIEW_NOTE) {
    return action.value;
  }
  return state;
};

export const currentDisplayNote = (state: Note | null = null, action: storyNotesActions) => {
  if (action.type === ActionTypes.SET_DISPLAY_NOTE) {
    return action.value;
  }
  return state;
};
