import React from 'react';
import { Button, Modal, Spin } from 'antd';
import Cropper from 'react-cropper';
import { CloseOutlined } from '@ant-design/icons';
import styles from './image-modal.module.scss';

type ImageModalProps = {
  file: any;
  isOpen: boolean;
  onCancel: () => void;
  saveAction: () => void;
  setClubCropper: any;
};

function ImageModal(props: ImageModalProps) {
  const {
    file, isOpen, onCancel, saveAction, setClubCropper,
  } = props;

  return (
    <div className={styles.ImageModal}>
      <Modal
        className={styles.imageModal}
        title={(
          <div className={styles.modalTitleContainer}>
            <Button shape="circle" icon={<CloseOutlined />} onClick={onCancel} />
            <div className={styles.title}>Upload Image</div>
            <Button type="primary" onClick={saveAction}>Save</Button>
          </div>
            )}
        style={{ overflow: 'hidden' }}
        bodyStyle={{ padding: '0px' }}
        closable={false}
        visible={isOpen && !!file}
        maskClosable
        onCancel={onCancel}
        centered
        footer={null}
      >
        <div className={styles.imageUploadPreview}>
          {
                    true
                      ? (
                        <div className={styles.cropContainer}>
                          <Cropper
                            style={{ height: 'auto', width: '100%' }}
                            initialAspectRatio={(2 / 2)}
                            aspectRatio={(2 / 2)}
                            preview=".img-preview"
                            src={file ? URL.createObjectURL(file) : ''}
                            viewMode={1}
                            guides
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              setClubCropper(instance);
                            }}
                          />
                        </div>
                      )
                      : <Spin size="large" />
                }
        </div>
      </Modal>
    </div>
  );
}

export default ImageModal;
