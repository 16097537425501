import React, { useState } from 'react';
import {
  Button, Form, Input, Select, Spin, Image,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQueryCache, useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import styles from './storynotes-create-screen.module.scss';
import { fallBackUrl } from '../../utils/imageData';
import { getCorrectTitle } from '../../helpers/helpers';
import { Medium } from '../../models/Medium';
import { createStoryNote } from '../../services-api/notesService';
import { ActionTypes } from '../../actions/types';
import { StoreState } from '../../reducers/_RootReducers';

const { Option } = Select;
function StorynotesCreateScreen() {
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState('');
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(24);
  const [value, setValue] = useState<any>('');
  const [type, setType] = useState('books');
  const queryCache = useQueryCache();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (value: any) => {
    setValue(value);
  };

  const onBackClick = () => {
    history.goBack();
  };

  const {
    data,
    isFetching,
  } = useInfiniteQuery(
    `new-story-note-screen-${searchQuery}-${type}`,
    async (key, nextId = 0) => {
      const { data } = await axios.post('/api/users/mymediums', { searchText: searchQuery, type: getCorrectTitle(type || 'books')?.value });
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onSearch = (data: any) => {
    setSearchQuery(data);
  };

  const onFinish = (values: any) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(createStoryNote(values, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      history.replace('/storynotes');
    }));
  };

  const onFinishFailed = (error: any) => {
  };

  const onTypeChange = (data: string) => {
    setValue(undefined);
    setType(data);
    queryCache.invalidateQueries(`new-story-note-screen-${searchQuery}`);
  };

  return (
    <div className={styles.StorynotesCreateScreen}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Button
            className={styles.goBack}
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={onBackClick}
          />
          <div className={styles.name}>
            Create New Story
          </div>
        </div>
        <div className={styles.form}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Title"
              name="title"
              required
            >
              <Input
                size="large"
                placeholder="Enter story notes title"
              />
            </Form.Item>
            <Form.Item
              label="Type"
              required
              name="type"
              initialValue={type}
            >
              <Select size="large" defaultValue={type} onChange={onTypeChange}>
                <Select.Option value="books">Books</Select.Option>
                <Select.Option value="series">Series</Select.Option>
                <Select.Option value="movies">Movies</Select.Option>
                <Select.Option value="podcasts">Podcasts</Select.Option>
              </Select>
            </Form.Item>
            {
              type
              && (
              <Form.Item
                label={`Select from your ${type}`}
                name="medium"
                required
              >
                <Select
                  showSearch
                  allowClear
                  optionLabelProp="label"
                  style={{ width: '100%', height: '50px !important' }}
                  searchValue={searchQuery}
                  onChange={handleChange}
                  onSearch={onSearch}
                  filterOption={false}
                  size="large"
                  placeholder="Select users"
                  notFoundContent={isFetching ? <Spin size="small" /> : null}
                >
                  {data?.map((page) => page).flat().map((medium: Medium, index) => (
                    <Option
                      key={medium._id}
                      value={medium._id}
                      label={medium.title}
                    >
                      <div className={styles.friendLabelOption}>
                        <Image
                          className={styles.image}
                          src={medium.imageUrl}
                          alt={medium.imageUrl}
                          fallback={fallBackUrl}
                        />
                        <div className={styles.name}>{medium.title}</div>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              )
            }
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default StorynotesCreateScreen;
