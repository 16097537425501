import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import styles from './new-main.module.scss';
import TabLandingIcon from '../../../components/tab-landing-icon/tab-landing-icon';
import Main from '../Main/Main';
import MainItem from '../main-item/main-item';

const { TabPane } = Tabs;

function NewMain() {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();

  const callback = (key: string) => {
    history.replace(`/${key}`);
  };

  return (
    <div className={styles.NewMain}>
      <div className={styles.container}>
        <Tabs defaultActiveKey={type || 'movies'} tabBarGutter={0} onChange={callback}>
          <TabPane tab={<TabLandingIcon icon="movies" count={0} />} key="movies">
            <MainItem />
          </TabPane>
          <TabPane tab={<TabLandingIcon icon="series" count={0} />} key="series">
            <MainItem />
          </TabPane>
          <TabPane tab={<TabLandingIcon icon="podcasts" count={0} />} key="podcasts">
            <MainItem />
          </TabPane>
          <TabPane tab={<TabLandingIcon icon="books" count={0} />} key="books">
            <MainItem />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default NewMain;
