import React, { useState } from 'react';
import {
  Button, Checkbox, Dropdown, Form, Input, Menu, Spin, Tabs,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GoBack from '../../components/GoBack/GoBack';
import styles from './EditProfileScreen.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import {checkUsername, updatePrivateAccount, updateUser} from '../../services-api/userService';
import { ActionTypes } from '../../actions/types';
import TabTitle from '../../components/TabTitle/TabTitle';

const { TabPane } = Tabs;
const { TextArea } = Input;
function EditProfileScreen() {
  let globalFileInput: any;
  const [form] = Form.useForm();
  const [type, setType] = useState('');
  const [file, setFile] = useState<Blob | null>(null);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const [privateAccount, setPrivateAccount] = useState(currentUser?.privateAccount || false);
  const [username, setUsername] = useState(currentUser?.username);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const onTabChange = () => {

  };

  const onCheckAction = () => {
    if (form.getFieldValue('username')) {
      dispatch(checkUsername(form.getFieldValue('username'), (available) => {
        if (available) {
          setType('available');
        } else {
          setType('taken');
        }
      }));
    }
  };

  const getCorrectCheckButton = (type: string) => {
    switch (type) {
      case 'available':
        return <Button size="large" style={{ background: 'green', color: 'white' }} className={styles.available} onClick={onCheckAction}>Available</Button>;
      case 'taken':
        return <Button size="large" style={{ background: 'red', color: 'white' }} className={styles.taken} onClick={onCheckAction}>Taken</Button>;
      default:
        return <Button size="large" className={styles.check} onClick={onCheckAction}>Check</Button>;
    }
  };

  const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType('');
    setUsername(event.target.value?.toLowerCase());
  };

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const menu = () => (
    <Menu>
      <Menu.Item key="1">
        <input
          style={{ display: 'none' }}
          type="file"
          name="avatar"
          onChange={fileSelectedHandler}
          ref={(fileInput) => globalFileInput = fileInput}
          accept=".jpeg,.png"
        />
        <div role="button" tabIndex={0} onClick={() => globalFileInput.click()}>Upload New Photo</div>
      </Menu.Item>
    </Menu>
  );

  const onFinish = (values: any) => {
    const data = new FormData();
    let dataChanged = false;

    if (values.displayName !== currentUser.displayName) {
      data.append('displayName', values.displayName);
      dataChanged = true;
    }

    if (values.username !== currentUser.username) {
      data.append('username', values.username);
      dataChanged = true;
    }

    if (values.bio && values.bio !== currentUser.bio) {
      data.append('bio', values.bio);
      dataChanged = true;
    }

    if (file != null) {
      data.append('avatar', file, 'temp');
      dataChanged = true;
    }

    if (dataChanged) {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      dispatch(updateUser(data, (user) => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, ...user } });
        history.replace(`/profile/${user.username}`);
      }));
    }
  };

  const onFinishFailed = (error: any) => {
    // console.log('error: ', error);
  };

  const onPrivacyChange = (data: any) => {
    setPrivateAccount(data.target.checked);
  };

  const onSavePrivateClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(updatePrivateAccount(privateAccount, (user) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, privateAccount } });
      history.replace(`/profile/${user.username}`);
    }));
  };

  return (
    currentUser
        && (
        <div className={styles.EditProfileScreen}>
          <GoBack url={`/profile/${currentUser.username}`} />
          <Spin spinning={isLoading}>
            <div className={styles.container}>
              <div className={styles.tabContainer}>
                <Tabs tabBarGutter={0} defaultActiveKey="Edit Profile" onChange={onTabChange}>
                  <TabPane tab={<TabTitle text="Edit Profile" />} key="Edit Profile">
                    <div className={styles.editContainer}>
                      <div className={styles.header}>
                        <div className={styles.imageContainer}>
                          <img
                            className={styles.image}
                            src={file ? URL.createObjectURL(file) : currentUser.image}
                            alt={currentUser.image}
                          />
                          <Dropdown overlay={menu} trigger={['click']}>
                            <Button type="link">Change profile image</Button>
                          </Dropdown>
                        </div>
                      </div>
                      <div className={styles.body}>
                        <Form
                          layout="vertical"
                          form={form}
                          className="login-form"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                        >
                          <Form.Item
                            label="Display Name"
                            name="displayName"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                            initialValue={currentUser.displayName}
                          >
                            <Input
                              prefix={<UserOutlined className={styles.iconColor} />}
                              placeholder="Display Name"
                              size="large"
                            />
                          </Form.Item>
                          <Form.Item
                            className={styles.username}
                            label="Username"
                            name="username"
                            rules={[{
                              required: true, message: 'Please input your username!', min: 3, max: 15, transform: (value) => value.toLowerCase(),
                            }]}
                            initialValue={currentUser.username}
                          >
                            <div className={styles.usernameContainer}>
                              <Input
                                prefix={
                                  <UserOutlined
                                      className={styles.iconColor}
                                      //style={{ color: 'rgba(0,0,0,.25)' }}
                                  />}
                                placeholder="Username"
                                defaultValue={currentUser.username}
                                value={username}
                                onChange={onUsernameChange}
                                size="large"
                              />
                              {
                                getCorrectCheckButton(type)
                              }
                            </div>

                          </Form.Item>
                          <Form.Item
                            label="Bio"
                            name="bio"
                            rules={[{ required: false }]}
                            initialValue={currentUser.bio}
                          >
                            <TextArea
                              placeholder="Tell others a little about yourself ..."
                              autoSize={{ minRows: 2, maxRows: 3 }}
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                              Save
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab={<TabTitle text="Privacy" />} key="Privacy">
                    <div className={styles.privateContainer}>
                      <div className={styles.privateTitle}>
                        Account Privacy
                      </div>
                      <Checkbox checked={privateAccount} className={styles.privacyCheckbox} onChange={onPrivacyChange}><span className={styles.privacyCheckboxTitle}>Private Account</span></Checkbox>
                      <div className={styles.privacySubtitle}>
                        When your account is private, only people you approve can see your posts on GoodLores. Your existing followers won&apos;t be affected.
                      </div>
                      <div className={styles.savePrivateData}>
                        <Button className={styles.savePrivateButton} size="large" type="primary" onClick={onSavePrivateClick}>
                          Save
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </Spin>
        </div>
        )
  );
}

export default EditProfileScreen;
