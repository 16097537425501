import {Section} from "../models/Template";

export const getCurrentSpot = (startDate: Date, currentDate: Date, rate: number, frequency: string) => {
    const frequencyValue = getFrequency(frequency);
    const diffDays = dateDiffInDays(startDate, currentDate);
    if ((diffDays/frequencyValue) < 0) {
        return rate * Math.floor(diffDays/frequencyValue);
    } else {
        return rate * Math.ceil(diffDays/frequencyValue);
    }

}

export const getSectionStartDate = (startDate: Date, rate: number, frequency: string, index: number): Date => {
    const frequencyValue = getFrequency(frequency);
    return addDays(startDate, (Math.floor(index / rate) * frequencyValue));
}

export const addDays = (date: Date, numDays: number): Date => {
    date.setDate(date.getDate() + numDays);
    return date;
}

export const addSeconds = (date: Date, seconds: number): Date => {
    date.setSeconds(date.getSeconds() + seconds);
    return date;
}

const getFrequency = (frequency: string) => {
    switch (frequency) {
        case 'DAY':
            return 1;
        case 'WEEK':
            return 7;
        default:
            return 0;
    }
}

const dateDiffInDays = (a: Date, b: Date) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utcA = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utcB = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utcB - utcA) / _MS_PER_DAY);
}

export const isNotAvailable = (index: number, startDate: Date, currentDate: Date, rate: number, frequency: string) => {
    const currentSpot = getCurrentSpot(startDate, currentDate, rate, frequency);
    return index > currentSpot;
}

const getSumOfPrevious = (index: number, sections: Section[]): number => {
    const previousSections = sections.slice(0, index);
    let sum = 0;
    previousSections?.forEach((previousSection: Section) => {
        sum = sum + previousSection.subChapters.length;
    })
    return sum;
}

const getSubsectionUmber = (subsectionId: string): number => {
    const arrStr = subsectionId.split('-sub-section');
    const lastChar = arrStr[arrStr.length - 1];
    return parseInt(lastChar);
}

const getSectionNumberFromSubsectionId = (subsectionId: string): number => {
    const arrStr = subsectionId.split('-sub-section');
    return parseInt(arrStr[0].replace('section',''));
}

const availableDateForSubsectionId = (id: string, sections: any[], startDate: Date, currentDate: Date, rate: number, frequency: string): Date => {
    const subsectionNumber = getSubsectionUmber(id) - 1;
    const sectionNumber = getSectionNumberFromSubsectionId(id) - 1;
    const finalSectionNumber = sections.find((x: Section) => x.id === `section${sectionNumber}`)?.subChapters.length > 0 ? getSumOfPrevious(sectionNumber, sections): sectionNumber
    const finalIndex = finalSectionNumber + subsectionNumber;
    return getSectionStartDate(startDate, rate, frequency, finalIndex);
}

const getSectionUmber = (sectionId: string): number => {
    return parseInt(sectionId.replace('section', ''))
}

const availableDateForSectionId = (id: string, sections: any[], startDate: Date, currentDate: Date, rate: number, frequency: string): Date => {
    const sectionNumber = getSectionUmber(id) - 1;
    const finalIndex = sections.find((x: Section) => x.id === `section${sectionNumber}`)?.subChapters.length > 0 ? getSumOfPrevious(sectionNumber, sections): sectionNumber
    return getSectionStartDate(startDate, rate, frequency, finalIndex);
}

export const availableDate = (id: string, sections: any[], startDate: Date, currentDate: Date, rate: number, frequency: string): Date => {
    if (id.includes('sub')) {
        return availableDateForSubsectionId(id, sections, startDate, currentDate, rate, frequency);
    } else {
        return availableDateForSectionId(id, sections, startDate, currentDate, rate, frequency);
    }
};

const getIndexForSectionId = (sectionId: string, sections: Section[]): number => {
    let count = 0;
    let finalIndex = 0;
    sections.forEach((section, index) => {
        if (section.id === sectionId) {
            finalIndex = count;
        }

        count = count + 1;
        section.subChapters.forEach((subSection, subsectionIndex) => {
            if (subSection.id === sectionId) {
                finalIndex = count;
            }

            count = count + 1;
        })
    })
    return finalIndex;
}

const getSectionIdForIndex = (sectionIndex: number, sections: Section[]): string => {
    let count = 0;
    let sectionId = '';
    sections.forEach((section, index) => {
        if (count === sectionIndex) {
            sectionId = section.id;
        }

        count = count + 1;
        section.subChapters.forEach((subSection, subsectionIndex) => {
            if (count === sectionIndex) {
                sectionId = subSection.id;
            }

            count = count + 1;
        })
    })
    return sectionId;
}

export const addToSection = (sectionId: string, addition: number, sections: Section[]): string => {
    const currentIndex = getIndexForSectionId(sectionId, sections);
    const newIndex = currentIndex + addition;
    return getSectionIdForIndex(newIndex, sections);
}