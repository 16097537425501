import { Dispatch } from 'redux';
import axios from 'axios';

export const createNewQuizPost = (data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
    axios.post('/api/quiz/post', data)
        .then((response) => {
            callback();
        }).catch((error) => {
        console.log('error: ', error);
    });
};

export const createNewQuiz = (data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
    axios.post('/api/quiz', data)
        .then((response) => {
            callback();
        }).catch((error) => {
        console.log('error: ', error);
    });
};

export const updateQuiz = (data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
    axios.put('/api/quiz', data)
        .then((response) => {
            callback();
        }).catch((error) => {
        console.log('error: ', error);
    });
};
