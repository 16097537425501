import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button, Dropdown, Image, Input, Menu, Modal, Skeleton, Spin, Tag,
} from 'antd';
import ReactCrop from 'react-image-crop';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {ArrowLeftOutlined, EllipsisOutlined, PlusOutlined, SendOutlined} from '@ant-design/icons';
import { useQuery } from 'react-query';
import axios from 'axios';
import { fallBackUrl } from '../../utils/imageData';
import styles from './storynotes-note-screen.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { ActionTypes } from '../../actions/types';
import { Note } from '../../models/Note';
import {CreateQuote} from "../../models/create-models/CreateQuote";
import {defaultCreateQuote} from "../../reducers/createReducers";

type NoteScreenProps = {

};

const { TextArea } = Input;
function StorynotesNoteScreen(props: NoteScreenProps) {
  const { id } = useParams<{ id: string }>();
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const currentNote = useSelector<StoreState, Note | null>((state) => state.currentNote);
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, error, data } = useQuery(`note-${id}-screen`, () => axios.get(`/api/notes/${id}`)
    .then((response) => {
      return response.data as Note;
    }));

  const { data: medium } = useQuery(`note-medium-${data?.medium}-screen`, () => axios.get(`/api/mediums/${data?.medium}`)
      .then((response) => {
        return response.data;
      }), { enabled: !!data });

  const onBackClick = () => {
    history.goBack();
  };

  const onSendClick = () => {

  };

  const onEditClick = () => {
    history.push(`/storynotes/${data?.storyNote}/${data?.sectionId}/${id}`);
  };

  const getText = (type: string, note: Note) => {
    if (type === "TEXT") {
      return note.text;
    }

    if (type === "QUOTE") {
      return note.quoteText;
    }

    if (type === "THOUGHT") {
      return note.thoughtText;
    }

    if (type === "QUESTION") {
      return note.questionText;
    }

    if (type === "ANSWER") {
      return note.answerText;
    }

    return 'Nothing found';
  }

  const onGenerateQuoteClick = (type: string) => {
    if (data) {
      const newCreateQuote: CreateQuote = {
        ...defaultCreateQuote,
        currentPage: 2,
        totalPages: 4,
        type: 'quote',
        medium: medium,
        text: getText(type, data)
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
      history.push({ pathname: '/create/quote', state: { fromStoryNote: true } });
    }
  };

  const renderCorrectNote = (type: string) => {
    switch (type) {
      case 'TEXT':
        return <div className={styles.text}>{data?.text}</div>;
      case 'QUOTE_THOUGHT':
        return (
          <div className={styles.quoteThoughtContainer}>
            <div className={styles.quoteContainer}>
              <div className={styles.title}>quote</div>
              <div className={styles.body}>{data?.quoteText}</div>
            </div>
            <div className={styles.quoteContainer}>
              <div className={styles.thoughtTitle}>thought</div>
              <div className={styles.body}>{data?.thoughtText}</div>
            </div>
          </div>
        );
      case 'QUESTION_ANSWER':
        return (
          <div className={styles.questionAnswerContainer}>
            <div className={styles.questionContainer}>
              <div className={styles.title}>question</div>
              <div className={styles.body}>{data?.questionText}</div>
            </div>
            {
              isAnswerVisible
                ? (
                  <div className={styles.answerContainer}>
                    <div className={styles.title}>answer</div>
                    <div className={styles.body}>{data?.answerText}</div>
                  </div>
                ) : (
                  <div className={styles.answerContainer}>
                    <Button
                      className={styles.showAnswer}
                      type="primary"
                      size="large"
                      onClick={() => setIsAnswerVisible(true)}
                    >
                      show answer
                    </Button>
                  </div>
                )
            }
          </div>
        );
      default:
        return <div />;
    }
  };

  const menu = (
      <Menu>
        <Menu.Item onClick={onEditClick}>
          <Button
              type="link"
              size="small"
          >
            Edit
          </Button>
        </Menu.Item>
        {
          ["QUOTE_THOUGHT", "TEXT"].includes(data?.type || '') &&
          <Menu.Item onClick={() => onGenerateQuoteClick(data?.type === "TEXT" ? "TEXT": "QUOTE")}>
            <Button
                type="link"
                size="small"
            >
              Generate Quote
            </Button>
          </Menu.Item>
        }
        {
          data?.type === "QUOTE_THOUGHT" &&
          <Menu.Item onClick={() => onGenerateQuoteClick("THOUGHT")}>
            <Button
                type="link"
                size="small"
            >
              Generate Quote (Thought)
            </Button>
          </Menu.Item>
        }
        {
          data?.type === "QUESTION_ANSWER" &&
          <Menu.Item onClick={() => onGenerateQuoteClick("QUESTION")}>
            <Button
                type="link"
                size="small"
            >
              Generate Quote (Question)
            </Button>
          </Menu.Item>
        }
        {
          data?.type === "QUESTION_ANSWER" &&
          <Menu.Item onClick={() => onGenerateQuoteClick("ANSWER")}>
            <Button
                type="link"
                size="small"
            >
              Generate Quote (Answer)
            </Button>
          </Menu.Item>
        }
      </Menu>
  );

  return (currentUser
        && (
        <div className={styles.StorynotesNoteScreen}>
          <div className={styles.noteHeader}>
            <Button
              className={styles.backButton}
              size="large"
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={onBackClick}
            />
            <div>
              Note
            </div>
          </div>
          <div className={styles.container}>
            {
              data?.type
                ? (
                  <div className={styles.header}>
                    <div className={styles.titleContainer}>
                      <div className={styles.title}>
                        {data?.title}
                      </div>
                      <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                        <Button size="large" type="link" icon={<EllipsisOutlined />} />
                      </Dropdown>
                    </div>
                    <div className={styles.subtitle}>
                      {
                        data?.locationType === 'PAGE' && (
                        <div className={styles.location}>
                          Page No.
                          {' '}
                          {data?.location}
                        </div>
                        )
                      }
                      {
                        data?.locationType === 'TIME' && (
                        <div className={styles.location}>
                          {data?.start.minutes}
                          min
                          {' '}
                          {data?.start.seconds}
                          s
                          {' - '}
                          {data?.end.minutes}
                          min
                          {' '}
                          {data?.end.seconds}
                          s
                        </div>
                        )
                      }
                      <div className={styles.categories}>
                        {
                          data?.noteCategories.map((val) => <Tag color="#108ee9" className={styles.category}>{val.uniqueName}</Tag>)
                        }
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div className={styles.loadingHeader}>
                    <Skeleton.Input className={styles.loadingItem} style={{ width: '100%' }} active size="large" />
                  </div>
                )
            }

            <div className={styles.messagesContainer}>
              {
                data?.type ? renderCorrectNote(data?.type)
                  : (
                    <Skeleton
                      className={styles.loadingContent}
                      active
                      paragraph={{ rows: 8 }}
                    />
                  )
              }
            </div>
          </div>
        </div>
        )
  );
}

export default StorynotesNoteScreen;
