import React, {useState} from 'react';
import styles from './preview-list.module.scss';
import bh from '../../../assets/images/baylor.png';
import Carousel, {Dots} from '@brainhubeu/react-carousel';
import {LeftCircleFilled, RightCircleFilled, RightCircleOutlined} from "@ant-design/icons";
import {Button, Typography} from "antd";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {CreateList} from "../../../models/create-models/create-list";

const { Paragraph } = Typography;
function PreviewList() {

    const createList = useSelector<StoreState, CreateList>(state => state.createList);
    const [value, setValue] = useState<number>(0);
    const [pagesSize] = useState<number>(1 + createList.stories.length);

    const onChange = (a: any) => {
        console.log(a);
    }

    const onCarouselChange = (index: number) => {
        setValue(index);
    };

    const onNextAction = () => {
        if (value < (pagesSize - 1)){
            setValue(value + 1);
        }
    }

    const onPreviousAction = () => {
        if (value > 0){
            setValue(value - 1);
        }
    }

    const slides = [
        <div className={styles.thumbnailContainer}>
            <img
                className={styles.thumbnailImage}
                src={createList.dataUrl ? URL.createObjectURL(createList.dataUrl) : "createList.image"}
                alt={createList.dataUrl ? URL.createObjectURL(createList.dataUrl) : "createList.image"}
            />
        </div>,
        ...createList.stories.map((item, index) => {
            return (
                <div className={styles.previewItem} key={`${item.title}`}>
                    <img
                        className={styles.image}
                        src={item.imageUrl}
                    />
                    <div className={styles.title}>
                        {item.title}
                    </div>
                    <Paragraph className={styles.description} ellipsis={{ rows: 4, expandable: false, symbol: '...' }}>
                        {item.description}
                    </Paragraph>
                </div>
            )
        })
    ]

    return (
        <div className={styles.PreviewList}>
            <Carousel
                className={styles.carousel}
                value={value}
                onChange={onCarouselChange}
                slides={slides}
            />
            <div className={styles.leftContainer}>
                {
                    value !== 0 &&
                    <Button
                        type="link"
                        shape="circle"
                        icon={<LeftCircleFilled className={styles.icon} />}
                        onClick={onPreviousAction}
                    />
                }
            </div>
            <div className={styles.rightContainer}>
                {
                    value !== (pagesSize - 1) &&
                    <Button
                        type="link"
                        shape="circle"
                        icon={<RightCircleFilled className={styles.icon} />}
                        onClick={onNextAction}
                    />
                }
            </div>
            <div className={styles.bottomContainer}>
                <Dots value={value} onChange={onCarouselChange} number={pagesSize} />
            </div>
            {/*<div className={styles.navigationControls}>*/}
            {/*    */}
            {/*    */}
            {/*</div>*/}
        </div>
    );
}

export default PreviewList;