import React, { useEffect, useState } from 'react';
import styles from './SummaryPreview.module.scss';

interface SummaryPreviewProps {
  item: any;
}

function SummaryPreview(props: SummaryPreviewProps) {
  const { item } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', update);
  }, []);

  const update = () => {
    setWidth(window.innerWidth);
  };

  const addCorrectStyling = () => {
    if (width < 500) {
      if (item.title.split('').length > 40) {
        return { alignItems: 'flex-start' };
      }
      return { alignItems: 'center' };
    } if (width > 500) {
      if (item.title.split('').length > 500) {
        return { alignItems: 'flex-start' };
      }
      return { alignItems: 'center' };
    }
    return {};
  };

  return (
    <div className={styles.SummaryPreview}>
      <div className={styles.summaryHeader}>
        <div className={styles.summaryLabel}>summary</div>
      </div>
      <div className={styles.summaryBody} style={addCorrectStyling()}>
        {item.title}
      </div>
      <div className={styles.summaryFooter}>
        <img
          className={styles.summarySourceImage}
          src={item.medium.imageUrl.replace('http://', 'https://')}
          alt={item.medium.imageUrl.replace('http://', 'https://')}
        />
        <div className={styles.summarySourceTitle}>
          {item.medium.title}
        </div>
      </div>
    </div>
  );
}

export default SummaryPreview;
