import React from 'react';
import {
  Checkbox, DatePicker, Form, Input, InputNumber, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import languages from '../../../utils/languages.json';
import styles from './template-info.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';
import { ActionTypes } from '../../../actions/types';
import { CreateTemplate } from '../../../models/create-models/CreateTemplate';

function TemplateInfo() {
  const [form] = Form.useForm();
  const createTemplate = useSelector<StoreState, CreateTemplate>((state) => state.createTemplate);
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    console.log('values: ', values);
  };

  const onFinishFailed = (error: any) => {
  };

  const onYearChange = (data: any) => {
    console.log('woooah: ', data);
    if (data) {
      dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: { ...createTemplate, year: new Date(data._d).getFullYear() } });
    }
    // console.log('data: ', new Date(data._d).getUTCFullYear());
  };

  const onVersionChange = (data: any) => {
    console.log('data: ', data);
    dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: { ...createTemplate, version: data } });
  };

  const onLengthChange = (data: any) => {
    dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: { ...createTemplate, length: data } });
  };

  const onLangChange = (data: any) => {
    dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: { ...createTemplate, language: data } });
  };

  const onPrivacyChange = (data: any) => {
    dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: { ...createTemplate, privacy: data } });
  };

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: { ...createTemplate, title: event.target.value } });
  };

  const onAudioChange = (data: any) => {
    dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: { ...createTemplate, isAudio: data.target.checked } });
  };

  return (
    <div className={styles.NewTemplateScreen}>
      <div className={styles.container}>
        <div className={styles.form}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Title"
              name="title"
              required
              initialValue={createTemplate?.title}
            >
              <Input
                size="large"
                placeholder="Enter a template name"
                allowClear
                onChange={onTitleChange}
              />
            </Form.Item>
            <Form.Item
              label="Language"
              required
              name="language"
            >
              <Select
                showSearch
                size="large"
                defaultValue="English"
                onChange={onLangChange}
              >
                {
                  languages.map((language) => <Select.Option value={language.name}>{language.name}</Select.Option>)
                }
              </Select>
            </Form.Item>
            {
              createTemplate?.medium?.typeValue === 0 &&
              <Form.Item
                  label="Year"
                  name="year"
                  required
              >
                <DatePicker
                    onChange={onYearChange}
                    defaultValue={moment(`${createTemplate.year}`)}
                    picker="year"
                />
              </Form.Item>
            }
            {
              createTemplate?.medium?.typeValue === 0 &&
              <Form.Item
                  label={createTemplate.medium?.typeValue === 0 ? 'Edition' : 'Version'}
                  name="version"
                  required
              >
                <InputNumber
                    min={1}
                    defaultValue={1}
                    onChange={onVersionChange}
                />
              </Form.Item>
            }
            {
              createTemplate?.medium?.typeValue === 0
                ? (
                  <div className={styles.locationContainer}>
                    <Form.Item
                      label={createTemplate?.isAudio ? 'Length (minutes)' : 'Pages (number)'}
                      name="length"
                      required
                      initialValue={createTemplate?.length}
                    >
                      <InputNumber
                        className={styles.versionInput}
                        min={1}
                        defaultValue={1}
                        onChange={onLengthChange}
                      />
                    </Form.Item>
                    <Form.Item
                      className={styles.audioContainer}
                      name="audio"
                      initialValue={createTemplate?.isAudio}
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={createTemplate?.isAudio}
                        checked={createTemplate?.isAudio}
                        onChange={onAudioChange}
                      >
                        Audio
                      </Checkbox>
                    </Form.Item>
                  </div>
                  )
                : createTemplate?.medium?.typeValue !== 3 && (
                  <Form.Item
                    label="Length (minutes)"
                    name="length"
                    required
                  >
                    <InputNumber
                      min={1}
                      defaultValue={1}
                      onChange={onLengthChange}
                    />
                  </Form.Item>
                  )
            }
            <Form.Item
              label="Privacy"
              required
              name="privacy"
            >
              <Select
                size="large"
                defaultValue="public"
                onChange={onPrivacyChange}
              >
                <Select.Option value="public">Public</Select.Option>
                <Select.Option value="private">Private</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default TemplateInfo;
