import React from 'react';
import { Button } from 'antd';
import styles from './story-template-card.module.scss';
import { StoryNoteTemplate } from '../../../models/Template';

type StoryTemplateCardProps = {
  template: StoryNoteTemplate;
  onPreview: (template: any) => void;
};

function StoryTemplateCard(props: StoryTemplateCardProps) {
  const { template, onPreview } = props;
  return (
    <div className={styles.StoryTemplateCard}>
      {
            template.usesCount > 0
            && (
            <div className={styles.usesContainer}>
              <div className={styles.usesCount}>{template.usesCount}</div>
              <div className={styles.usesTitle}>uses</div>
            </div>
            )
      }
      <div className={styles.info}>
        <div className={styles.title}>
          {template.title}
        </div>
        <div className={styles.subtitle}>
          {template.language}
          {' '}
          •
          {' '}
          {template.year}
          {' '}
          •
          {' '}
          {template.version}
        </div>
        <div className={styles.userContainer}>
          <img
            className={styles.userImage}
            src={template.user.image}
            alt={template.user.image}
          />
          <div
            className={styles.username}
          >
            {template.user.username}
          </div>
        </div>
      </div>
      <Button type="link" onClick={() => onPreview(template)}>
        preview
      </Button>
    </div>
  );
}

export default StoryTemplateCard;
