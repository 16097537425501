import React from 'react';
import styles from './club-about-tab.module.scss';
import ClubMembersScreen from "../../club-members-screen/club-members-screen";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import axios from "axios";
import {Button} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";

function ClubAboutTab() {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const {
        isLoading, error, data, isFetching,
    } = useQuery(`club-screen-${id}`, () => axios.get(`/api/clubs/${id}`)
        .then((response) => response.data));

    const onBackAction = () => {
        history.goBack();
    };

    return (
        <div className={styles.ClubAboutTab}>
            <div className={styles.container}>
                <div className={styles.body}>
                    {data?.club?.about || (<span className={styles.emptyText}>N/A</span>)}
                </div>
            </div>
        </div>
    );
}

export default ClubAboutTab;