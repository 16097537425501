import React, { useEffect } from 'react';
import {
  Button, Form, Select,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';
import timeZones from '../../utils/time-zone.json';
import times from '../../utils/times.json';
import styles from './reminders-screen.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { createReminder, updateReminder } from '../../services-api/remindersService';
import { ActionTypes } from '../../actions/types';

function RemindersScreen() {
  const [form] = Form.useForm();
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, isLoading: isDataLoading } = useQuery('reminder', () => axios.get('/api/reminder')
    .then((response) => {
      console.log('data: ', response.data);
      return response.data;
    }));

  useEffect(() => {
  }, [data]);

  const onFinish = (values: any) => {
    if (data) {
      updateExistingReminder(values);
    } else {
      createNewReminder(values);
    }
  };

  const updateExistingReminder = (values: any) => {
    const offset = timeZones.find((x) => x.value === values.timeZone)?.offset;
    const data = {
      times: values.times,
      utcTimes: convertTime(values.times, offset || 0),
      frequency: values.frequency,
      timeZone: timeZones.find((x) => x.value === values.timeZone),
    };
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(updateReminder(data, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      history.replace('/storynotes');
    }));
  };

  const createNewReminder = (values: any) => {
    const offset = timeZones.find((x) => x.value === values.timeZone)?.offset;
    const data = {
      times: values.times,
      utcTimes: convertTime(values.times, offset || 0),
      frequency: values.frequency,
      timeZone: timeZones.find((x) => x.value === values.timeZone),
    };
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(createReminder(data, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      history.replace('/storynotes');
    }));
  };

  const convertTime = (times: Array<number>, offset: number) => times.map((val) => {
    const sum = val + offset;
    if (sum < 0) {
      return 24 + sum;
    }
    return sum;
  });

  const onFinishFailed = (error: any) => {

  };

  const onFrequencyChange = (data: string) => {

  };

  const onBackAction = () => {
    history.goBack();
  };

  const getDefaultZone = () => {
    const now = new Date();
    const zone = now.toTimeString().split('(')[1].replace(')', '');
    return timeZones.find((x) => x.value === zone)?.value;
  };

  return ((data || !isDataLoading)
    ? (
      <div className={styles.RemindersScreen}>
        <div className={styles.container}>
          <div className={styles.header}>
            <Button
              className={styles.backButton}
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={onBackAction}
            />
            <div className={styles.title}>
              Notes reminder
            </div>
          </div>
          <div className={styles.subtitle}>
            Take a lot notes but never read them?
            {' '}
            <br />
            {' '}
            Don&apos;t worry, we can help! We will re-send your notes to you.
          </div>
          <Form
            className={styles.form}
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              className={styles.selectItem}
              label="Frequency"
              name="frequency"
              initialValue={data?.frequency || 'EVERY_DAY'}
              rules={[{ required: true, message: 'Please select a frequency' }]}
            >
              <Select size="large" onChange={onFrequencyChange}>
                <Select.Option value="EVERY_DAY">Every day</Select.Option>
                <Select.Option value="WEEK_DAYS">Week days</Select.Option>
                <Select.Option value="WEEKENDS">Weekends</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.selectItem}
              label="Times"
              name="times"
              initialValue={data?.times || [8, 12, 18, 21]}
              rules={[{ required: true, message: 'Please select at least 1 time' }]}
            >
              <Select
                mode="multiple"
                allowClear
                size="large"
                onChange={onFrequencyChange}
              >
                {
                  (times as any).map((val: any, index: number) => <Select.Option key={`key-${val.key}`} value={val.id}>{val.value}</Select.Option>)
              }
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.selectItem}
              label="Time Zone"
              name="timeZone"
              initialValue={data?.timeZone?.value || getDefaultZone()}
              rules={[{ required: true, message: 'Please select a time zone!' }]}
            >
              <Select
                showSearch
                size="large"
                onChange={onFrequencyChange}
              >
                {
                  timeZones.map((key, index) => <Select.Option key={`key-${index}`} value={`${key.value}`}>{key.text}</Select.Option>)
              }
              </Select>
              {/* time zone -> select time zone */}
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.saveButton}
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    ) : <div />
  );
}

export default RemindersScreen;
