import React from 'react';
import { Button } from 'antd';
import { CheckCircleOutlined, BorderOutlined, CheckOutlined } from '@ant-design/icons';

import { InterestMediumType } from '../../../models/InterestMediumType';
import styles from './YesNoCard.module.scss';

interface YesNoCardProps {
  item: InterestMediumType;
  onNoClick: (uniqueName: string) => void;
  onYesClick: (uniqueName: string) => void;
}

function YesNoCard(props: YesNoCardProps) {
  const { item } = props;
  const {
    displayName, answer, uniqueName,
  } = item;
  const onNoClick = () => {
    props.onNoClick(uniqueName);
  };

  const onYesClick = () => {
    props.onYesClick(uniqueName);
  };

  return (
    <div className={styles.YesNoCard}>
      <div className={styles.title}>
        {displayName}
      </div>
      <div className={styles.actions}>
        <Button
          block
          size="large"
          icon={answer === false && <CheckCircleOutlined />}
          className={`${styles.noButton} ${answer === false && styles.answerNo}`}
          onClick={onNoClick}
        >
          {
                        answer === false
                          ? <CheckOutlined />
                          : <BorderOutlined />
                    }
          No
        </Button>
        <div />
        <Button
          block
          size="large"
          icon={answer === true && <CheckCircleOutlined />}
          className={`${styles.yesButton} ${answer === true && styles.answerYes}`}
          onClick={onYesClick}
        >
          {
                        answer
                          ? <CheckOutlined />
                          : <BorderOutlined />
                    }
          Yes
        </Button>
      </div>
    </div>
  );
}

export default YesNoCard;
