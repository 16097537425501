import React from 'react';
import styles from './preview-thumbnail-modal.module.scss';
import {Button, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {ActionTypes} from "../../../actions/types";
import tem from '../../../assets/images/baylor.png';
import {ThumbnailStyles} from "../../../models/ThumbnailStyles";

function PreviewThumbnailModal() {

    const dispatch = useDispatch();
    const thumbnailStyles = useSelector<StoreState, ThumbnailStyles | null>(state => state.thumbnailStyles);
    const isThumbnailPreviewModalOpen = useSelector<StoreState, boolean>(state => state.isThumbnailPreviewModalOpen)

    const handleOk = () => {

    }

    const handleCancel = () => {
        dispatch({ type: ActionTypes.SET_THUMBNAIL_PREVIEW_MODAL_VISIBILITY, value: false });
    };

    return (
        <Modal
            bodyStyle={{ padding: '8px', width: '100vw !important', maxWidth: '500px', margin: '0px' }}
            visible={isThumbnailPreviewModalOpen}
            onCancel={handleCancel}
            title={null}
            footer={null}
            closable={false}
        >
            <div className={styles.previewThumbnailContainer}>
                <img
                    className={styles.previewImage}
                    src={thumbnailStyles?.imageData ? URL.createObjectURL(thumbnailStyles?.imageData) : "createQuote.image"}
                    alt={thumbnailStyles?.imageData ? URL.createObjectURL(thumbnailStyles?.imageData) : "createQuote.image"}
                />
                {/*<div>*/}
                {/*    Press and hold image to */}
                {/*</div>*/}
                {/*<Button size="large" className={styles.downloadButton}>Download</Button>*/}
            </div>
        </Modal>
    );
}

export default PreviewThumbnailModal;