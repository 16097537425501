import React from 'react';
import { Button } from 'antd';
import styles from './timeline.module.scss';
import TopPostCard from '../stadium-stats/top-post-card/top-post-card';
import LoudnessCard from '../stadium-stats/loudness-card/loudness-card';
import FoodCard from '../stadium-stats/food-card/food-card';
import PollCard from '../stadium-stats/poll-card/poll-card';
import BestPlayerCard from '../stadium-stats/best-player-card/best-player-card';
import TwoPlayersCard from './two-palyers-card/two-players-card';
import OnePlayerCard from './one-player-card/one-player-card';
import GoalCard from './goal-card/goal-card';
import CommentaryCard from './commentary-card/commentary-card';
import SubstitutionCard from './substitution-card/substitution-card';
import CardCard from './card-card/card-card';

function Timeline() {
  const getCorrectTimelineCard = (index: number) => {
    switch (index) {
      case 0:
        return <SubstitutionCard />;
      case 1:
        return <CardCard type="yellow" />;
      case 2:
        return <GoalCard />;
      case 3:
        return <CommentaryCard />;
      case 4:
        return <CardCard type="red" />;
      default:
    }
  };
  return (
    <div className={styles.Timeline}>
      <div className={styles.header}>
        <div className={styles.title}>
          Timeline
        </div>
        <Button
          className={styles.viewAll}
          type="link"
        >
          view all
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.container}>
          {
                [1, 1, 1, 1, 1].map((item, index) => getCorrectTimelineCard(index))
          }
        </div>
      </div>
    </div>
  );
}

export default Timeline;
