import { QuoteStyling } from '../../models/create-models/QuoteStyling';
import { Layer } from '../../models/create-models/Layer';
import { QuoteContent } from '../../models/create-models/QuoteContent';
import { Template } from '../../models/create-models/Template';
import {TemplatePreview} from "../../models/TemplatePreview";

const mainStyling: QuoteStyling = {
  fontSize: '20px',
  color: '#000000',
  fontStyle: 'normal',
  fontWeight: '400',
  textDecoration: 'none',
  whiteSpace: 'pre-wrap',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alpha: 0.7
};

const mainLayoutStyling: QuoteStyling = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const sourceLayoutStyling: QuoteStyling = {
  width: '100%',
};

const sourceStyling: QuoteStyling = {
  fontSize: '14px',
  color: '#000000',
  fontStyle: 'normal',
  fontWeight: '400',
  textDecoration: 'none',
};

const backgroundStyling: QuoteStyling = {
  background: '#BCD9D4',
  opacity: 1,
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const backgroundLayer: Layer = {
  order: 0,
  type: 'background',
  content: backgroundStyling,
};

const layout: QuoteStyling = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
};

const quoteContent: QuoteContent = {
  layout,
  main: {
    layout: mainLayoutStyling,
    text: mainStyling,
    highlights: mainStyling,
  },
  source: {
    layout: sourceLayoutStyling,
    title: sourceStyling,
    subtitle: sourceStyling,
  },
};

const quoteLayer: Layer = {
  order: 1,
  type: 'quote',
  content: quoteContent,
};

const layers: Layer[] = [backgroundLayer, quoteLayer];

export const ClassicTemplate: Template = {
  id: '0',
  title: 'Classic',
  uniqueName: 'classic',
  layers,
  fonts: ["quote", "source"],
  colors: ["quote", "source", "background", "gradientBackground"]
};

export const classicTemplatePreview: TemplatePreview = {id: 'classic', idNum: 0, title: 'Classic', uniqueName: 'classic', template: ClassicTemplate};

