import React, {useEffect, useState} from 'react';
import styles from './templates-tab.module.scss';
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {Section, StoryNoteTemplate} from "../../../models/Template";
import {useParams} from "react-router-dom";
import {Button, Modal, Skeleton, Spin} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import StoryTemplateCard from "../../../components/Cards/story-template-card/story-template-card";
import {ActionTypes} from "../../../actions/types";
import {useDispatch, useSelector} from "react-redux";
import {CloseCircleOutlined} from "@ant-design/icons";
import {StoreState} from "../../../reducers/_RootReducers";
import {CreateTemplate} from "../../../models/create-models/CreateTemplate";
import Empty from "../../../components/Empty/Empty";

type TemplateTabProps = {
    mediumId: string;
}
function TemplatesTab(props: TemplateTabProps) {

    const { mediumId } = props;

    const { id, storiesId } = useParams<{ id: string, storiesId: string }>();
    const [timeStamp, setTimeStamp] = useState(new Date());
    const [limit] = useState(3);
    const newlyCreatedTemplate = useSelector<StoreState, StoryNoteTemplate | null>((state) => state.newlyCreatedTemplate);
    const isTemplatePreviewModalOpen = useSelector<StoreState, boolean>((state) => state.isTemplatePreviewModalOpen);
    const createTemplate = useSelector<StoreState, CreateTemplate>((state) => state.createTemplate);
    const [searchQuery, setSearchQuery] = useState('');
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [sections, setSections] = useState<Section[]>([]);
    const [language, setLanguage] = useState<string | undefined>('');
    const [year, setYear] = useState<number>(0);
    const [version, setVersion] = useState<number | undefined>(undefined);
    const [searchTemplatesQuery, setSearchTemplatesQuery] = useState('');
    const [currentTemplate, setCurrentTemplate] = useState<StoryNoteTemplate | undefined>(undefined);
    const dispatch = useDispatch();

    const {
        isLoading, data, fetchMore,
    } = useInfiniteQuery(`medium-templates-screen-${id}-${language}-${year}-${version}-${searchTemplatesQuery}`,
        async (key, nextId = 0) => {
            const { data: templateData } = await axios.get(`/api/templates/medium/${mediumId}?search=${searchTemplatesQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}&language=${language}&year=${year}&version=${version}`);
            if (templateData?.templates?.length < limit) {
                setIsAtTheEnd(true);
            }
            return templateData;
        },
        {
            getFetchMore: (lastGroup) => lastGroup?.nextId,
        });

    const onPreviewClick = (template: any) => {
        setCurrentTemplate(template);
        dispatch({ type: ActionTypes.SET_TEMPLATE_PREVIEW_MODAL, value: true });
    };

    const onClosePreviewTemplateModal = () => {
        dispatch({ type: ActionTypes.SET_TEMPLATE_PREVIEW_MODAL, value: false });
    };

    useEffect(() => {
        console.log("louis loop template-data: ",data);
        // if (createTemplate) {
        //     dispatch({ type: ActionTypes.SET_CREATE_TEMPLATE, value: null });
        //     //setTimeStamp(new Date());
        // }
    }, []);

    return (
        <div className={styles.TemplatesTab}>
            <Modal
                className={styles.TemplateModal}
                title={null}
                visible={isTemplatePreviewModalOpen}
                footer={null}
                closable={false}
                onCancel={onClosePreviewTemplateModal}
            >
                <div className={styles.content}>
                    <div className={styles.previewHeader}>
                        <div className={styles.previewTitle}>
                            {currentTemplate?.title}
                        </div>
                        <Button
                            className={styles.closeButton}
                            type="link"
                            size="large"
                            icon={<CloseCircleOutlined />}
                            onClick={onClosePreviewTemplateModal}
                        />
                    </div>
                    <div className={styles.previewInfo}>
                        {currentTemplate?.language}
                        {' '}
                        •
                        {' '}
                        {currentTemplate?.year}
                        {' '}
                        •
                        {' '}
                        {currentTemplate?.version}
                    </div>
                    <div className={styles.previewBody}>
                        {
                            currentTemplate?.sections.map((section, index) => (
                                <div className={styles.chapter}>
                                    <div className={styles.info}>
                                        <div className={styles.number}>
                                            {index + 1}
                                            .
                                        </div>
                                        <div>
                                            {section.text}
                                        </div>
                                        <div className={styles.location}>
                                            {
                                                currentTemplate?.isAudio
                                                    ? (section.time.minute === -1 || section.time.second === -1) ? '' : `${section.time.minute}min ${section.time.second}s`
                                                    : section.page === -1 ? '' : `page ${section.page}`
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.subChapterSection}>
                                        {
                                            section.subChapters.map((subSection, subIndex) => (
                                                <div key={`subsection-${index}-${subIndex}`} className={styles.subChapter}>
                                                    <div className={styles.subInfo}>
                                                        <div className={styles.subNumber}>
                                                            {index + 1}
                                                            .
                                                            {subIndex + 1}
                                                            .
                                                        </div>
                                                        <div className={styles.subChapterInput}>
                                                            {subSection.text}
                                                        </div>
                                                        <div className={styles.location}>
                                                            {
                                                                currentTemplate?.isAudio
                                                                    ? (subSection.time.minute === -1 || subSection.time.second === -1) ? '' : `${subSection.time.minute}min ${subSection.time.second}s`
                                                                    : subSection.page === -1 ? '' : `page ${subSection.page}`
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={styles.previewFooter}>
                        {/*<Button*/}
                        {/*    className={styles.useButton}*/}
                        {/*    type="primary"*/}
                        {/*    size="large"*/}
                        {/*    //onClick={onUseTemplateClick}*/}
                        {/*>*/}
                        {/*    Use template*/}
                        {/*</Button>*/}
                    </div>
                </div>
            </Modal>
            {
                (isLoading) && (
                    <div className={styles.loadingContainer}>
                        {
                            [1, 1, 1, 1].map((item, index) => <div key={`${index}-loading-card`} className={styles.loadingCard}><Skeleton avatar active paragraph={{ rows: 1 }} /></div>)
                        }
                    </div>
                )
                || data?.map((page) => page.templates).flat().length === 0
                && <div className={styles.nothingContainer}>
                    {/*No templates found*/}
                    <Empty
                        title="No templates"
                        subTitle={"Create new template"}
                        icon="plus"
                        buttonTitle="Create"
                        url="/create"
                        screen="posts"
                    />
                </div>
                || (
                    <InfiniteScroll
                        dataLength={data?.map((page) => page.templates).flat().length || 0}
                        next={() => fetchMore()}
                        hasMore={(data?.map((page) => page.templates).flat().length || 0) > 0 && !isAtTheEnd}
                        loader={(
                            <div className={styles.spinningContainer}>
                                <Spin />
                            </div>
                        )}
                        endMessage={(
                            <p style={{ textAlign: 'center' }} />
                        )}
                        scrollableTarget="scrollableDivTemplates"
                    >
                        <div className={styles.container}>
                            {
                                newlyCreatedTemplate &&
                                <StoryTemplateCard
                                    template={newlyCreatedTemplate}
                                    onPreview={onPreviewClick}
                                />
                            }
                            {
                                data?.map((page) => page.templates).flat().filter(x => x._id !== newlyCreatedTemplate?._id).map((item, index) => (
                                    <div key={`${index}-thing`}>
                                        <StoryTemplateCard
                                            template={item}
                                            onPreview={onPreviewClick}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </InfiniteScroll>
                )
            }
        </div>
    );
}

export default TemplatesTab;