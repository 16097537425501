import React, { useEffect, useState } from 'react';
import { Input, List, Modal } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Empty from '../../../components/Empty/Empty';
import styles from './MediumModal.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';
import { Medium } from '../../../models/Medium';
import { getCorrectTitle } from '../../../helpers/helpers';
import { ActionTypes } from '../../../actions/types';

interface MediumModalProps {
  isMediumModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  onSelect: (item: Medium) => void;
  mediumType?: string;
}

const { Search } = Input;
function MediumModal(props: MediumModalProps) {
  const {
    isMediumModalOpen, handleOk, handleCancel, onSelect, mediumType,
  } = props;

  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const [items, setItems] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    onSearch('');
  }, []);

  const onSearch = (searchText: string) => {
    setSearchText(searchText);
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    axios.post('/api/users/mymediums/', { searchText, type: getCorrectTitle(mediumType)?.value })
      .then((response) => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        setItems(response.data);
      }).catch((error) => {
        console.log('MediumScreen error: ', error);
      });
  };

  return (
    <Modal
      className={styles.MediumModal}
      title={null}
      visible={isMediumModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <div className={styles.content}>
        <Search
          placeholder={`Enter ${getCorrectTitle(mediumType)?.singular} title here...`}
          enterButton="Search"
          size="large"
          allowClear
          onSearch={onSearch}
        />
        <List
          className={styles.resultContainer}
          grid={{ gutter: 0, column: 1 }}
          loading={isLoading}
          dataSource={items && items}
          locale={!searchText ? {
            emptyText: <Empty
              title={`Find ${getCorrectTitle(mediumType)?.plural} on your shelf`}
              subTitle=""
              icon="search"
              url="/search"
              screen={getCorrectTitle(mediumType)?.screen}
            />,
          } : {
            emptyText:
            <div style={{ fontSize: '16px' }}>
              No results found for
              {' '}
              <span style={{ color: 'black' }}>{searchText}</span>
            </div>,
          }}
          renderItem={(item:any, index: number) => (
            <List.Item key={`mymedium${index}`}>
              <div role="button" tabIndex={0} key={`${index}`} className={styles.resultItem} onClick={() => onSelect(item)}>
                {item.title}
              </div>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
}

export default MediumModal;
