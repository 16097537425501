import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './stand-tab.module.scss';

type StandTabProps = {
  name: string;
};

function StandTab(props: StandTabProps) {
  const { name } = props;
  const location = useLocation();

  const isCurrentLocation = () => {
    const urlTexts = location.pathname.split('/');
    //return urlTexts.find((x) => x === icon);
  };

  return (
    <div className={styles.StandTab}>
      {/*<img*/}
      {/*  className={`${styles.icon} ${isCurrentLocation() && styles.active}`}*/}
      {/*  src={getIcon(icon)}*/}
      {/*/>*/}
      <div className={styles.title}>
        {name}
      </div>
    </div>
  );
}

export default StandTab;
