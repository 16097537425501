import { Dispatch } from 'redux';
import axios from 'axios';

export const createClub = (data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/clubs', { name: data.name, privacy: data.privacy, users: data.friends ? data.friends.map((x: any) => JSON.parse(x.value)) : [] })
    .then((response) => {
      callback();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateUsersClub = (id: string | undefined, data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  console.log('data: ', data);
  axios.put(`/api/clubs/users/${id}`, data)
    .then((response) => {
      callback(response.data);
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const updateClub = (id: string | undefined, data: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  console.log('data: ', data);
  axios.put(`/api/clubs/${id}`, data)
    .then((response) => {
      callback(response.data);
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const makeAdmin = (clubId: string, userId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put(`/api/clubs/${clubId}/admin/${userId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error);
      callback(false);
    });
};

export const deleteClub = (clubId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/clubs/${clubId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error);
      callback(false);
    });
};

export const leaveClub = (clubId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/clubs/${clubId}/leave`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error.response.data);
      callback(false, error.response.data);
    });
};

export const removeFromClub = (clubId: string, userId: string, callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.delete(`/api/clubs/${clubId}/remove/${userId}`)
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error);
      callback(false);
    });
};

export const inviteUsers = (clubId: string, users: string[], callback: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.put(`/api/clubs/${clubId}/add/users`, { users })
    .then((response) => {
      callback(true);
    }).catch((error) => {
      console.log('error: ', error.response.data);
      callback(false, error.response.data);
    });
};
