import React from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styles from './welcome.module.scss';
import stadium from '../../../../assets/images/stadium.png';

function Welcome() {
  return (
    <div className={styles.Welcome}>
      <div className={styles.textContainer}>
        <div className={styles.greeting}>
          Hey
          <span className={styles.name}>louis</span>
          ,
        </div>
        <div className={styles.welcome}>
          Welcome to the virtual stadium for
        </div>
        <div className={styles.event}>
          <span className={styles.team1}>Baylor University</span>
          {' '}
          vs
          <span className={styles.team2}>Gonzaga University</span>
        </div>
        <Button
          className={styles.takeTourButton}
          size="large"
          type="primary"
        >
          Take a tour
          <ArrowRightOutlined />
        </Button>
      </div>
      <div className={styles.stadiumImageContainer}>
          <img
              className={styles.stadiumImage}
              src={stadium}
              alt=""
          />
      </div>
    </div>
  );
}

export default Welcome;
