import React from 'react';
import { Button } from 'antd';
import styles from './RequestCard.module.scss';
import { Request } from '../../../models/Request';

type RequestCardProps = {
  request: Request;
  history: any;
  dispatch: any;
  onDeleteClick: (id: string) => void;
  onConfirmClick: (id: string) => void;
};

function RequestCard(props: RequestCardProps) {
  const { request, onConfirmClick, onDeleteClick } = props;
  const { follower } = request;

  const onUserClick = () => {
    props.history.push(`/profile/${follower?.username}`);
  };

  return (
    <div className={styles.RequestCard}>
      <div className={styles.userContainer}>
        <img className={styles.image} src={follower?.image} alt={follower?.image} onClick={onUserClick} />
        <div className={styles.usernameContainer}>
          <span className={styles.username} onClick={onUserClick}>
            {follower?.username}
          </span>
        </div>
      </div>
      <div className={styles.actions}>
        <Button type="primary" className={styles.confirmButton} onClick={() => onConfirmClick(request._id)}>
          Confirm
        </Button>
        <Button className={styles.deleteButton} onClick={() => onDeleteClick(request._id)}>
          Delete
        </Button>
      </div>
    </div>
  );
}

export default RequestCard;
