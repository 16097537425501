import React from 'react';
import { Badge, Button, Input } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styles from './stand-content.module.scss';
import mkbhd from '../../../assets/images/mkbhd.jpg';

const { Search } = Input;
function StandContent() {
  const onBackAction = () => {

  };

  const history = useHistory();
  const onSearch = () => {

  };

  const onStandClick = () => {
    history.push('/stands/123');
  };

  return (
    <div className={styles.StandContent}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <Button
                className={styles.backButton}
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={onBackAction}
              />
              <div className={styles.title}>
                Stands
              </div>
              <Button
                className={styles.createButton}
                icon={<PlusOutlined />}
                type="primary"
                size="large"
              >
                Post
              </Button>
            </div>
          </div>
          <div />
        </div>
        <div className={styles.overviewCards}>
          {
                    [1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                      <div
                        className={styles.standOverviewCard}
                        onClick={onStandClick}
                      >
                        <img
                          className={styles.image}
                          src={mkbhd}
                        />
                        <div className={styles.textInfo}>
                          <div className={styles.titleContainer}>
                            <div className={styles.title}>
                              Name
                            </div>
                          </div>
                          <div className={styles.subtitle}>
                            tweet like post
                          </div>
                        </div>
                      </div>
                    ))
                }
        </div>
      </div>
    </div>
  );
}

export default StandContent;
