import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './tab-icon.module.scss';
import bookIcon from '../../assets/icons/icons8-open-book-50.png';
import postsIcon from '../../assets/icons/icons8-grid-50.png';
import seriesIcon from '../../assets/icons/icons8-retro-tv-50.png';
import moviesIcon from '../../assets/icons/icons8-clapperboard-50.png';
import podcastsIcon from '../../assets/icons/icons8-microphone-50.png';
import episodesIcon from '../../assets/icons/icons8-browse-podcasts-50.png';
import bookIconFilled from '../../assets/icons/icons8-open-book-50-filled.png';
import seriesIconFilled from '../../assets/icons/icons8-retro-tv-50-filled.png';
import moviesIconFilled from '../../assets/icons/icons8-clapperboard-50-filled.png';
import podcastsIconFilled from '../../assets/icons/icons8-microphone-50-filled.png';
import episodesIconFilled from '../../assets/icons/icons8-browse-podcasts-50-filled.png';
import calendarIcon from '../../assets/icons/icons8-calendar-50.png';

type TabIconProps = {
  count?: number;
  icon: string;
};

function TabIcon(props: TabIconProps) {
  const { count, icon } = props;
  const location = useLocation();

  const getIcon = (icon: string) => {
    switch (icon) {
      case 'posts':
        return postsIcon;
      case 'books':
        return bookIcon;
      case 'series':
        return seriesIcon;
      case 'movies':
        return moviesIcon;
      case 'podcasts':
        return podcastsIcon;
      case 'episodes':
        return episodesIcon;
      case 'events':
        return calendarIcon;
      default:
    }
  };

  const getFilledIcon = (icon: string) => {
    switch (icon) {
      case 'books':
        return bookIconFilled;
      case 'series':
        return seriesIconFilled;
      case 'movies':
        return moviesIconFilled;
      case 'podcasts':
        return podcastsIconFilled;
      case 'episodes':
        return episodesIconFilled;
      default:
    }
  };

  const isCurrentLocation = () => {
    const urlTexts = location.pathname.split('/');
    if (icon === 'posts' && !urlTexts.includes('series') && !urlTexts.includes('books') && !urlTexts.includes('movies') && !urlTexts.includes('podcasts')) {
      return urlTexts.find((x) => x === 'profile');
    }
    return urlTexts.find((x) => x === icon);
  };

  return (
    <div className={styles.TabIcon}>
      <img
        className={`${styles.icon} ${isCurrentLocation() && styles.active}`}
        src={getIcon(icon)}
      />
      <div
        className={styles.count}
      >
        {icon}
      </div>
      {/* <div */}
      {/*    className={styles.count} */}
      {/* > */}
      {/*    ({count}) */}
      {/* </div> */}
    </div>
  );
}

export default TabIcon;
