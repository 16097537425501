import React, { useEffect, useState } from 'react';
import {
  Button, Dropdown, Image, Menu, Modal, Skeleton, Tabs,
} from 'antd';
import { DownOutlined, SettingOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styles from './profile-screen.module.scss';
import GoBack from '../../components/GoBack/GoBack';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { follow, unfollow, deleteFollowRequestByUserId } from '../../services-api/userService';
import SettingsModal from './SettingsModal/SettingsModal';
import { ActionTypes } from '../../actions/types';
import { Post } from '../../models/Post';
import { removeMediumFromShelf } from '../../services-api/mediumService';
import { deletePost } from '../../services-api/createPostService';
import { getCorrectShelf, getCorrectTitle } from '../../helpers/helpers';
import ProfileMediumsGrid from './profile-mediums-grid/profile-mediums-grid';
import ProfilePostsGrid from './profile-posts-grid/profile-posts-grid';
import { Medium } from '../../models/Medium';
import ProfilePodcastsGrid from './profile-podcasts-grid/profile-podcasts-grid';
import { fallBackUrl } from '../../utils/imageData';
import TabIcon from '../../components/tab-icon/tab-icon';

const { TabPane } = Tabs;
const { confirm, error } = Modal;

function ProfileScreen() {
  // @ts-ignore
  const { username, tab } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const viewingUser = useSelector<StoreState, User | null>((state) => state.viewingUser);
  //const shelfIds = useSelector<StoreState, any>((state) => state.shelfIds);
  const followingUserIds = useSelector<StoreState, any>((state) => state.followingUserIds);
  const followRequestUserIds = useSelector<StoreState, any>((state) => state.followRequestUserIds);
  const [currentTab, setCurrentTab] = useState(tab || 'posts');
  const history = useHistory();
  const dispatch = useDispatch();

  const onEditingClick = () => {
    setIsEditing(!isEditing);
  };

  const onEditProfileAction = () => {
    history.push('/editprofile');
  };
  const onFollowersAction = () => {
    if (!cannotView()) {
      history.push(`/users/${viewingUser?._id}/followers`);
    }
  };

  const onFollowingAction = () => {
    if (!cannotView()) {
      history.push(`/users/${viewingUser?._id}/following`);
    }
  };

  const isUserEqualToCurrentUser = () => currentUser && username === currentUser.username;

  const onSettingsAction = () => {
    dispatch({ type: ActionTypes.TOGGLE_SETTINGS_MODAL });
  };

  useEffect(() => {
    if (username) {
      getProfile();
    }
  }, [username]);

  const onFollowAction = () => {
    if (viewingUser) {
      dispatch(follow(currentUser._id, viewingUser._id, (followRequest: boolean) => {
        if (followRequest) {
          dispatch({ type: ActionTypes.SET_FOLLOW_REQUEST_USER_IDS, value: { ...followRequestUserIds, [viewingUser._id]: true } });
        } else {
          dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: { ...followingUserIds, [viewingUser._id]: true } });
          dispatch({ type: ActionTypes.SET_VIEWING_USER, viewingUser: { ...viewingUser, followersCount: viewingUser!.followersCount + 1 } });
        }
      }));
    }
  };

  const onUnFollowAction = () => {
    if (viewingUser) {
      dispatch(unfollow(currentUser._id, viewingUser._id, () => {
        const updatedFollowingUserIds = followingUserIds;
        delete updatedFollowingUserIds[viewingUser._id];
        dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: { ...updatedFollowingUserIds } });
        dispatch({ type: ActionTypes.SET_VIEWING_USER, viewingUser: { ...viewingUser, followersCount: viewingUser!.followersCount - 1 } });
      }));
    }
  };

  const onFollowRequestAction = () => {
    if (viewingUser) {
      dispatch(deleteFollowRequestByUserId(viewingUser._id, () => {
        const updatedFollowRequestsUserIds = followRequestUserIds;
        delete updatedFollowRequestsUserIds[viewingUser._id];
        dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: { ...updatedFollowRequestsUserIds } });
      }));
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={onUnFollowAction}>
        Unfollow
      </Menu.Item>
    </Menu>
  );

  const onPostClick = (post: Post) => {
    if (!isEditing) {
      dispatch({ type: ActionTypes.SET_SELECTED_POST, post });
      history.push(`/posts/${post._id}`);
    }
  };

  const onMediumClick = (shelfMedium: any) => {
    const { medium } = shelfMedium;

    if (medium) {
      if (medium?.currentUserPosts) {
        medium.currentUserPosts = viewingUser?.posts;
      }

      if (!isEditing) {
        //dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, [`${medium.external.id}`]: { typeText: medium.typeTitle } } });
        //dispatch({ type: ActionTypes.SET_MEDIUM, medium });
        history.push(`/mediums/${medium.external.id}/${medium.typeValue}`);
      }
    } else {
      if (shelfMedium?.currentUserPosts) {
        // shelfMedium.currentUserPosts = viewingUser?.posts;
      }

      if (!isEditing) {
        //dispatch({ type: ActionTypes.SET_SHELF_IDS, value: { ...shelfIds, [`${shelfMedium.external.id}`]: { typeText: shelfMedium.typeTitle } } });
        //dispatch({ type: ActionTypes.SET_MEDIUM, medium: shelfMedium });
        history.push(`/mediums/${shelfMedium.external.id}/${shelfMedium.typeValue}`);
      }
    }
  };

  const onSportEventClick = (shelfMedium: any) => {
    history.push('/sport/events/123');
  };

  const onSaveAction = (items: Medium[], callback: Function) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    axios.post(`/api/ranking/${getCorrectTitle(currentTab)?.value}`, { mediums: items })
      .then((response) => {
        setIsEditing(false);
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        callback(items);
      }).catch((error) => {
        console.log('error: ', error);
      });
  };

  const onRemovePostClick = (post: Post, callback: Function) => {
    confirm({
      title: 'Remove item?',
      content: 'Are you sure you want to remove this item?',
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(deletePost(post._id, (success:boolean) => {
            if (success) {
              callback(post._id);
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onRemoveMediumClick = (mediumShelf: any, callback: Function) => {
    confirm({
      title: 'Remove item?',
      content: 'Are you sure you want to remove this item?',
      onOk() {
        return new Promise((resolve, reject) => {
          const { medium } = mediumShelf;
          dispatch(removeMediumFromShelf(mediumShelf._id, (success:boolean) => {
            if (success) {
              callback(mediumShelf._id);
              const shelfTitle = getCorrectShelf(medium.typeValue);
              if (shelfTitle === 'booksCount') {
                dispatch({ type: ActionTypes.SET_VIEWING_USER, viewingUser: { ...viewingUser, booksCount: viewingUser!.booksCount - 1 } });
              } else if (shelfTitle === 'podcastsCount') {
                dispatch({ type: ActionTypes.SET_VIEWING_USER, viewingUser: { ...viewingUser, podcastsCount: viewingUser!.podcastsCount - 1 } });
              } else if (shelfTitle === 'moviesCount') {
                dispatch({ type: ActionTypes.SET_VIEWING_USER, viewingUser: { ...viewingUser, moviesCount: viewingUser!.moviesCount - 1 } });
              } else if (shelfTitle === 'seriesCount') {
                dispatch({ type: ActionTypes.SET_VIEWING_USER, viewingUser: { ...viewingUser, seriesCount: viewingUser!.seriesCount - 1 } });
              }
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch((e) => console.log('Oops errors! :', e));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const initialLoad = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    axios.post(`/api/users/${username}`, { postTimeStamp: '', currentTab })
      .then((response) => {
        dispatch({ type: ActionTypes.SET_FOLLOW_REQUEST_USER_IDS, value: { ...followRequestUserIds, ...response.data.followRequestUserIds } });
        dispatch({ type: ActionTypes.SET_FOLLOWING_USER_IDS, value: { ...followingUserIds, ...response.data.followingUserIds } });
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        dispatch({ type: ActionTypes.SET_VIEWING_USER, viewingUser: response.data });
      }).catch((error) => {
        console.log('error: ', error.response);
      });
  };

  const getProfile = () => {
    if (viewingUser?.username !== username) {
      initialLoad();
    }
  };

  const cannotView = () => currentUser?.username !== viewingUser?.username && viewingUser?.privateAccount && !viewingUser?.viewable;

  const onTabChange = (activeTab: string) => {
    setCurrentTab(activeTab);
    if (activeTab === 'posts') {
      history.replace(`/profile/${username}`);
    } else {
      history.replace(`/profile/${username}/${activeTab}`);
    }
  };

  return (
    <div className={styles.ProfileScreen}>
      <SettingsModal />
      {
        !isLoading && viewingUser && !isUserEqualToCurrentUser()
        && (
        <div className={styles.goBack}>
          <GoBack />
        </div>
        )
      }
      <div className={styles.container}>
        {
                    isLoading || !viewingUser || !currentUser
                      ? (
                        <div className={styles.header}>
                          <div>
                            <Skeleton.Avatar className={styles.profileImageSkeleton} active shape="circle" />
                          </div>
                          <div style={{ paddingLeft: '16px' }} className={styles.profileInfo}>
                            <div className={styles.profileInfoHeader}>
                              <Skeleton className={styles.username} active paragraph={{ rows: 2 }} />
                            </div>
                          </div>
                        </div>
                      )
                      : (
                        <div className={styles.header}>
                          <Image
                            className={styles.profileImage}
                            src={viewingUser.image}
                            alt={viewingUser.image}
                            fallback={fallBackUrl}
                          />
                          <div className={styles.profileInfo}>
                            <div className={styles.profileInfoHeader}>
                              <div className={styles.username}>
                                {viewingUser.username}
                                {' '}
                                <SettingOutlined onClick={onSettingsAction} className={styles.setting} />
                              </div>
                              <div className={styles.follow}>
                                {
                                            isUserEqualToCurrentUser()
                                              ? <Button onClick={onEditProfileAction}>Edit Profile</Button>
                                              : followingUserIds[`${viewingUser._id}`]
                                                ? (
                                                  <Dropdown overlay={menu} trigger={['click']}>
                                                    <Button>
                                                      Following
                                                      {' '}
                                                      <DownOutlined />
                                                    </Button>
                                                  </Dropdown>
                                                )
                                                : followRequestUserIds[`${viewingUser._id}`] ? (
                                                  <Button onClick={onFollowRequestAction}>
                                                    Requested
                                                  </Button>
                                                ) : <Button type="primary" onClick={onFollowAction}>Follow</Button>
                                        }
                              </div>
                            </div>
                            <div className={styles.profileInfoBody}>
                              <div role="button" tabIndex={0} className={styles.followersCount} onClick={onFollowersAction}>
                                <b>{viewingUser.followersCount}</b>
                                {' '}
                                followers
                              </div>
                              <div role="button" tabIndex={0} className={styles.followingCount} onClick={onFollowingAction}>
                                <b>{viewingUser.followingCount}</b>
                                {' '}
                                following
                              </div>
                            </div>
                            <div className={styles.profileInfoFooter}>
                              <b className={styles.displayName}>
                                {viewingUser.displayName}
                              </b>
                              <div className={styles.bio}>
                                {viewingUser.bio}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                }
        {
                    isLoading || !viewingUser || !currentUser
                      ? (
                        <div className={styles.mobileInfo}>
                          <Skeleton.Input active />
                        </div>
                      ) : (
                        <div className={styles.mobileInfo}>
                          <div className={styles.profileInfoFooter}>
                            <b className={styles.displayName}>
                              {viewingUser.displayName}
                            </b>
                            <div className={styles.bio}>
                              {viewingUser.bio}
                            </div>
                            <div className={styles.followInfoContainer}>
                              <div className={styles.followingCount}>
                                <b>{viewingUser.followingCount}</b>
                                {' '}
                                <span>following</span>
                              </div>
                              <div className={styles.followersCount}>
                                <b>{viewingUser.followersCount}</b>
                                {' '}
                                <span>followers</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
        }
        {/* { */}
        {/*  isLoading || !viewingUser || !currentUser */}
        {/*    ? ( */}
        {/*      <div className={styles.profileMobileInfoStats}> */}
        {/*        <Skeleton.Input active /> */}
        {/*      </div> */}
        {/*    ) : ( */}
        {/*      <div className={styles.profileMobileInfoStats}> */}
        {/*        <div role="button" tabIndex={0} className={styles.followersCount} onClick={onFollowersAction}> */}
        {/*          <b>{viewingUser.followersCount}</b> */}
        {/*          <div>followers</div> */}
        {/*        </div> */}
        {/*        <div role="button" tabIndex={0} className={styles.followingCount} onClick={onFollowingAction}> */}
        {/*          <b>{viewingUser.followingCount}</b> */}
        {/*          <div>following</div> */}
        {/*        </div> */}
        {/*      </div> */}
        {/*    ) */}
        {/* } */}
        {
          (cannotView())
            ? (
              <div className={styles.privateBanner}>
                <div className={styles.privateTitle}>
                  This Account is Private
                </div>
                <div className={styles.privateSubtitle}>
                  Follow to see their posts
                </div>
              </div>
            )
            : (
              <Tabs
                tabBarGutter={0}
                className={styles.tabs}
                defaultActiveKey={currentTab}
                onChange={onTabChange}
              >
                <TabPane
                  tab={<TabIcon icon="posts" count={viewingUser?.postCount} />}
                  key="posts"
                >
                  <ProfilePostsGrid
                    isCurrentUser={viewingUser?.username === currentUser?.username}
                    username={username}
                    isEditing={isEditing}
                    mediumCount={viewingUser?.postCount}
                    onEditingClick={onEditingClick}
                    onRemovePostClick={onRemovePostClick}
                    onPostClick={onPostClick}
                  />
                </TabPane>
                <TabPane
                  tab={<TabIcon icon="books" count={viewingUser?.booksCount} />}
                  key="books"
                >
                  <ProfileMediumsGrid
                    isCurrentUser={viewingUser?.username === currentUser?.username}
                    username={viewingUser?.username || ''}
                    isEditing={isEditing}
                    onEditingClick={onEditingClick}
                    type="books"
                    mediumCount={viewingUser?.booksCount}
                    onRemoveMediumClick={onRemoveMediumClick}
                    onMediumClick={onMediumClick}
                    onSaveAction={onSaveAction}
                    title="Find millions of books"
                    subTitle="Search for books"
                    icon="search"
                    url="/search/ebook"
                    buttonTitle="Search Books"
                    screenItem="empty_books"
                  />
                </TabPane>
                <TabPane
                  tab={<TabIcon icon="series" count={viewingUser?.seriesCount} />}
                  key="series"
                >
                  <ProfileMediumsGrid
                    isCurrentUser={viewingUser?.username === currentUser?.username}
                    username={viewingUser?.username || ''}
                    isEditing={isEditing}
                    onEditingClick={onEditingClick}
                    type="series"
                    mediumCount={viewingUser?.seriesCount}
                    onRemoveMediumClick={onRemoveMediumClick}
                    onMediumClick={onMediumClick}
                    onSaveAction={onSaveAction}
                    title="Find millions of series"
                    subTitle="Search for series"
                    icon="search"
                    url="/search/tvShow"
                    buttonTitle="Search Series"
                    screenItem="empty_series"
                  />
                </TabPane>
                <TabPane
                  tab={<TabIcon icon="movies" count={viewingUser?.moviesCount} />}
                  key="movies"
                >
                  <ProfileMediumsGrid
                    isCurrentUser={viewingUser?.username === currentUser?.username}
                    username={viewingUser?.username || ''}
                    isEditing={isEditing}
                    onEditingClick={onEditingClick}
                    type="movies"
                    mediumCount={viewingUser?.moviesCount}
                    onRemoveMediumClick={onRemoveMediumClick}
                    onMediumClick={onMediumClick}
                    onSaveAction={onSaveAction}
                    title="Find millions of movies"
                    subTitle="Search for movies"
                    icon="search"
                    url="/search/movie"
                    buttonTitle="Search Movies"
                    screenItem="empty_movies"
                  />
                </TabPane>
                <TabPane
                  tab={<TabIcon icon="podcasts" count={viewingUser?.podcastsCount} />}
                  key="podcasts"
                >
                  <ProfilePodcastsGrid
                    isCurrentUser={viewingUser?.username === currentUser?.username}
                    username={viewingUser?.username || ''}
                    isEditing={isEditing}
                    onEditingClick={onEditingClick}
                    type="podcasts"
                    mediumCount={viewingUser?.podcastsCount}
                    onRemoveMediumClick={onRemoveMediumClick}
                    onMediumClick={onMediumClick}
                    onSaveAction={onSaveAction}
                    title="Find millions of podcasts"
                    subTitle="Search for podcasts"
                    icon="search"
                    url="/search/podcast"
                    buttonTitle="Search Podcasts"
                    screenItem="empty_podcasts"
                  />
                </TabPane>
              </Tabs>
            )
        }
      </div>
    </div>
  );
}

export default ProfileScreen;
