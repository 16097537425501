import React, { useState } from 'react';
import { Button, Input, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import {useInfiniteQuery, useQuery, useQueryCache} from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import FriendUserCardSkeleton from '../NewChatScreen/FriendUserCardSkeleton/FriendUserCardSkeleton';
import styles from './club-story-notes.module.scss';
import { Section, SubSection } from '../../models/Template';
import { Note } from '../../models/Note';
import MyNotesModal from '../club-story-screen/my-notes-modal/my-notes-modal';
import { ActionTypes } from '../../actions/types';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import TopicPostCard from '../club-story-screen/topic-post-card/topic-post-card';
import { TopicPost } from '../../models/TopicPost';
import { like, unlike, updateLike } from '../../services-api/clubTopicsService';
import {availableDate} from "../../services-util/club-story-service-util";
import createPostImage from '../../assets/images/add_post.svg';
import image404 from '../../assets/images/404.svg';
import NotesSourceModal from "./notes-source-modal/notes-source-modal";
import PreviewNoteModal from "../../components/preview-note-modal/preview-note-modal";

const { Search } = Input;
function ClubStoryNotes() {
  const { id, storiesId, sectionId } = useParams<{ id: string, storiesId: string, sectionId: string }>();
  const reactionIds = useSelector<StoreState, any>((state) => state.reactionIds);
  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const isReactionsOpen = useSelector<StoreState, boolean>((state) => state.isReactionsOpen);
  const currentUser = useSelector<StoreState, User | null>((state) => state.currentUser);
  const [searchQuery, setSearchQuery] = useState('');
  const [limit] = useState(12);
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const dispatch = useDispatch();
  const history = useHistory();
  const queryCache = useQueryCache();

  const {
    isLoading: isTopicLoading, isFetching: isTopicFetching, error: topicError, data: currentTopic,
  } = useQuery(`story-${storiesId}`, () => axios.get(`/api/clubs/topics/${storiesId}`)
    .then((response) => {
      console.log('currentTopic: ', response.data);
      if (response.data && response.data.template) {
        // setSections(response.data.template?.sections);
      }
      return response.data;
    }));

  const availableDateVal = availableDate(sectionId, currentTopic?.template?.sections || [], new Date(currentTopic?.startDate), timeStamp, currentTopic?.rate, currentTopic?.frequency)
  const isNotActiveYet = availableDateVal > timeStamp;

  const {
    data, isFetching, fetchMore,
  } = useInfiniteQuery(`story-notes-${storiesId}-${sectionId}-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data: notesData } = await axios.get(`/api/clubs/topics/posts/${storiesId}/?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}&sectionId=${sectionId}`);

      if (notesData?.posts?.length < limit) {
        setIsAtTheEnd(true);
      }
      return notesData;
    },
    {
      getFetchMore: (lastGroup) => lastGroup?.nextId,
    });

  const onBackClick = () => {
    history.goBack();
  };

  const onAddStoryTemplateClick = () => {
    dispatch({ type: ActionTypes.SET_ADD_NOTES_MODAL_VISIBILITY, value: true });
  };

  const onSearch = (data: string) => {
    setSearchQuery(data);
  };

  const onNotesCardClick = (event: any, note: Note) => {
    if (currentTopic) {
      const data = new FormData();

      data.append('topic', currentTopic._id as string);
      data.append('note', note._id);

      if (sectionId.includes('sub')) {
        const finalSectionId = sectionId.split('-')[0];
        data.append('sectionId', finalSectionId);
        data.append('subSectionId', sectionId);
      } else {
        data.append('sectionId', sectionId);
      }

      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      axios.post(`/api/clubs/topics/posts/${id}/${storiesId}`, data)
        .then( async (response) => {

          const newTopicPost = response.data;
          newTopicPost.user = currentUser;
          newTopicPost.note = note;

          queryCache.setQueryData(`story-notes-${storiesId}-${sectionId}-screen-${searchQuery}`, (oldData: any) => {
            if (oldData && oldData.length > 0) {
              return oldData.map((page: any, index: number) => {
                if (index === 0) {
                  return {...page, posts: [newTopicPost, ...page.posts]}
                }
                return page;
              })
            } else {
              return [{ posts: [newTopicPost], nextId: 1 }];
            }
          });
          dispatch({ type: ActionTypes.SET_MY_NOTES_VISIBILITY, value: false });
          dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
          dispatch({ type: ActionTypes.SET_CURRENT_PREVIEW_NOTE, value: null });
        }).catch((error) => {
          console.log('error: ', error);
        });
    }
  };

  const onPostClick = (postId: string) => {

  };

  // const onNotesCardClick = (note: Note) => {
  //   dispatch({ type: ActionTypes.SET_CURRENT_PREVIEW_NOTE, value: note });
  //   dispatch({ type: ActionTypes.SET_MY_NOTES_VISIBILITY, value: false });
  // };

  const updatePost = (post: TopicPost, type: string) => {
    if (type === 'like') {
      return { ...post, likesCount: (post?.likesCount || 0) + 1 };
    }

    if (type === 'love') {
      return { ...post, lovesCount: (post?.lovesCount || 0) + 1 };
    }

    if (type === 'lol') {
      return { ...post, laughsCount: (post?.laughsCount || 0) + 1 };
    }

    dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${post?._id}`]: { typeText: type } } });
    return post;
  };

  const updateExistingPost = (post: TopicPost, type: string, existingReaction: any) => {
    let updatedPost = { ...post };

    if (type === existingReaction.typeText) {
      if (type === 'like') {
        updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) - 1 };
      }

      if (type === 'love') {
        updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) - 1 };
      }

      if (type === 'lol') {
        updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) - 1 };
      }
      const updatedReactionIds = _.omit(reactionIds, `${post._id}`);
      dispatch({ type: ActionTypes.SET_REACTION_IDS, value: updatedReactionIds });
      return updatedPost;
    }

    if (type === 'like') {
      updatedPost = { ...updatedPost, likesCount: (updatedPost?.likesCount || 0) + 1 };
    }

    if (type === 'love') {
      updatedPost = { ...updatedPost, lovesCount: (updatedPost?.lovesCount || 0) + 1 };
    }

    if (type === 'lol') {
      updatedPost = { ...updatedPost, laughsCount: (updatedPost?.laughsCount || 0) + 1 };
    }

    if (existingReaction.typeText === 'like') {
      updatedPost = { ...updatedPost, likesCount: updatedPost!.likesCount! - 1 };
    }

    if (existingReaction.typeText === 'love') {
      updatedPost = { ...updatedPost, lovesCount: updatedPost!.lovesCount! - 1 };
    }

    if (existingReaction.typeText === 'lol') {
      updatedPost = { ...updatedPost, laughsCount: updatedPost!.laughsCount! - 1 };
    }
    console.log('louis updatedPost: ', updatedPost);
    dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${post?._id}`]: { typeText: type } } });

    return updatedPost;
  };

  const onReactionAction = (tp: TopicPost, type: string) => {
    queryCache.setQueryData(`club-stories-posts-${storiesId}`, (oldData: any) => {
      if (oldData) {
        dispatch({ type: ActionTypes.SET_REACTION_IDS, value: { ...reactionIds, [`${tp?._id}`]: { typeText: type } } });
        return oldData.map((x: any) => ({
          ...x,
          posts: x.posts.map((post: TopicPost) => {
            if (post._id === tp._id) {
              if (reactionIds[`${post._id}`]) {
                return updateExistingPost(post, type, reactionIds[`${post._id}`]);
              }
              return updatePost(post, type);
            }
            return post;
          }),
        }));
      }
      return oldData;
    });

    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
    dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: false });

    if (reactionIds[`${tp._id}`]) {
      if (type === reactionIds[`${tp._id}`].typeText) {
        console.log('louis unlike');
        dispatch(unlike(tp, type, () => {
        }));
      } else {
        console.log('louis update like');
        dispatch(updateLike(tp, type, () => {
        }));
      }
    } else {
      console.log('louis like');
      dispatch(like(tp, type, () => {
      }));
    }
  };

  const onReactionClick = (tp: TopicPost) => {
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: tp._id });
    dispatch({ type: ActionTypes.SET_REACTIONS_VISIBILITY, value: true });
  };

  const onPreviewNoteAction = (note: Note) => {
    dispatch({ type: ActionTypes.SET_DISPLAY_NOTE, value: note });
  };

  const getEmptyView = () => {
    if (isNotActiveYet) {
      return <div className={styles.emptyContainer} ><img className={styles.emptyImage} src={image404} /></div>
    } else {
      return <div className={styles.emptyContainer}>
        <img className={styles.emptyImage} src={createPostImage} />
        <Button
            className={styles.emptyButton}
            type="primary"
            size="large"
            onClick={onAddStoryTemplateClick}
        >
          Add Note
          <PlusOutlined />
        </Button>
      </div>
    }
  }

  const sectionCanHaveNotes = (): boolean => {
    return sectionId !== 'all' && checkIfSectionHasSubsections()
  }

  const checkIfSectionHasSubsections = (): boolean => {
    return currentTopic?.template?.sections?.find((section: Section) => section.id === sectionId)?.subChapters.length > 0;
  }

  return (
    <div className={styles.ClubStoryNotes}>
      <NotesSourceModal/>
      <MyNotesModal
        onNotesCardClick={onNotesCardClick}
      />
      <PreviewNoteModal/>
      <div className={styles.headerContainer}>
        <div className={styles.headerWhiteboard}>
          <div className={styles.messageHeader}>
            <div className={styles.topHeader}>
              <Button
                className={styles.goBack}
                type="link"
                size="large"
                icon={<ArrowLeftOutlined />}
                shape="circle"
                onClick={onBackClick}
              />
              <div className={styles.titleHeader}>
                {
                                sectionId === 'all' && (
                                <span className={styles.sectionId}>
                                  all
                                </span>
                                )
                            }
                <span className={styles.sectionId}>
                  {sectionId.includes('sub')
                    ? currentTopic?.template.sections.map((section: Section) => section.subChapters).flat().find((val: SubSection) => val?.id === sectionId)?.text
                    : currentTopic?.template.sections.find((val: Section) => val.id === sectionId)?.text}
                </span>
              </div>
              <div className={styles.create}>
                <Button
                  size="large"
                  shape="circle"
                  type="primary"
                  onClick={onAddStoryTemplateClick}
                  disabled={isNotActiveYet || sectionCanHaveNotes()}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </div>
            <div className={styles.searchContainer}>
              <Search
                className={styles.searchInput}
                placeholder="Search all your notes"
                allowClear
                size="large"
                onSearch={onSearch}
                disabled={isNotActiveYet}
              />
            </div>
          </div>
          <div className={styles.messageRequest}>
            message requests
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {
                (sectionCanHaveNotes() && (data && data?.map((page) => page.notes).flat().length === 0)) && (
                <Button
                  className={styles.addNewNote}
                  type="dashed"
                  size="large"
                  onClick={onAddStoryTemplateClick}
                >
                  Add new note
                </Button>
                )
            }
        {
                isFetching && !data
                  ? (
                    [1, 1, 1, 1, 1, 1].map((item, index) => <FriendUserCardSkeleton key={`${index}`} />)
                  ) : (
                    <InfiniteScroll
                      dataLength={data?.length || 0}
                      next={() => fetchMore()}
                      hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
                      loader={(
                        <div className={styles.spinningContainer}>
                          <Spin />
                        </div>
                            )}
                      endMessage={(<p style={{ textAlign: 'center' }} />)}
                    >
                      {
                                data?.map((page) => page.posts).flat()
                                    .map((topicPost, index) => (
                                      <TopicPostCard
                                        roundBorder
                                        key={`post-card-${index}`}
                                        topicPost={topicPost}
                                        onPostClick={onPostClick}
                                        onReactionAction={onReactionAction}
                                        isReactionsOpen={isReactionsOpen}
                                        onReactionClick={onReactionClick}
                                        onPreviewNoteAction={onPreviewNoteAction}
                                        currentId={currentId}
                                        reactionIds={reactionIds}
                                        currentTopic={currentTopic}
                                        timeStamp={timeStamp}
                                        hideTimeStamp
                                      />
                                    ))
                            }
                    </InfiniteScroll>
                  )
            }
        {
          !isFetching && data?.map((page) => page.posts).flat().length === 0 &&
          getEmptyView()
        }
      </div>
    </div>
  );
}

export default ClubStoryNotes;
