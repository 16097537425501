import React, { useState } from 'react';
import { Skeleton, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import PodcastEpisodeCard from './podcast-episode-card/podcast-episode-card';
import styles from './podcast-episodes.module.scss';

type PodcastEpisodesProps = {
  mediumId: string;
};

function PodcastEpisodes(props: PodcastEpisodesProps) {
  const { mediumId } = props;
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [limit] = useState(15);

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    'podcast-episodes',
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/mediums/podcast-episodes/${mediumId}?page=${nextId}&limit=${limit}`);
      if (data?.episodes?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  return (
    <div className={styles.PodcastEpisodes}>
      <InfiniteScroll
        className={styles.gridList}
        next={() => fetchMore()}
        hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
        loader={(
          <div className={styles.spinningContainer}>
            <Spin />
          </div>
            )}
        dataLength={data?.length || 0}
      >
        {
              isFetching && !data
              && (
              <div className={styles.emptySkeleton}>
                {
                      [1, 1, 1].map((item: any, index: number) => (
                        <Skeleton
                          key={`skel-${index}`}
                          className={styles.skeletonItem}
                          active
                          title={{ style: { height: 60, width: '100%' } }}
                          paragraph={{ rows: 0 }}
                        />
                      ))
                  }
              </div>
              )
        }
        {
            data?.map((page) => page.episodes).flat().length !== 0 && data?.map((page) => page.episodes).flat().map((item: any) => (
              <PodcastEpisodeCard key={`podcast-episode-${item._id}`} medium={item} _id={item._id} />
            ))
        }
      </InfiniteScroll>
    </div>
  );
}

export default PodcastEpisodes;
