import { ActionTypes } from '../actions/types';
import { Medium } from '../models/Medium';
import { User } from '../models/User';

interface setSearchBooks {
  type: typeof ActionTypes.SET_SEARCH_BOOKS;
  mediums: Medium[];
}

interface setSearchPodcasts {
  type: typeof ActionTypes.SET_SEARCH_PODCASTS;
  mediums: Medium[];
}

interface setSearchUsers {
  type: typeof ActionTypes.SET_SEARCH_USERS;
  users: User[];
}

interface setSearchSeries {
  type: typeof ActionTypes.SET_SEARCH_SERIES;
  mediums: Medium[];
}

interface setSearchMovies {
  type: typeof ActionTypes.SET_SEARCH_MOVIES;
  mediums: Medium[];
}

interface setSearchValue {
  type: typeof ActionTypes.SET_SEARCH_VALUE;
  value: string;
}

type mediumActions = setSearchUsers | setSearchBooks | setSearchPodcasts | setSearchSeries
    | setSearchMovies | setSearchValue;

export const searchBooks = (state = [], action: mediumActions) => {
  if (action.type === ActionTypes.SET_SEARCH_BOOKS) {
    return action.mediums;
  }
  return state;
};

export const searchPodcasts = (state = [], action: mediumActions) => {
  if (action.type === ActionTypes.SET_SEARCH_PODCASTS) {
    return action.mediums;
  }
  return state;
};

export const searchSeries = (state = [], action: mediumActions) => {
  if (action.type === ActionTypes.SET_SEARCH_SERIES) {
    return action.mediums;
  }
  return state;
};

export const searchMovies = (state = [], action: mediumActions) => {
  if (action.type === ActionTypes.SET_SEARCH_MOVIES) {
    return action.mediums;
  }
  return state;
};

export const searchUsers = (state = [], action: mediumActions) => {
  if (action.type === ActionTypes.SET_SEARCH_USERS) {
    return action.users;
  }
  return state;
};

export const searchValue = (state = '', action: mediumActions) => {
  if (action.type === ActionTypes.SET_SEARCH_VALUE) {
    return action.value;
  }
  return state;
};
