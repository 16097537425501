import React, { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './WishListScreen.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { removeMediumFromShelf } from '../../services-api/mediumService';
import TabTitle from '../../components/TabTitle/TabTitle';
import MediumsGrid from './mediums-grid/mediums-grid';
import { getCorrectShelf } from '../../helpers/helpers';
import { ActionTypes } from '../../actions/types';
import PodcastsGrid from './podcasts-grid/podcasts-grid';
import PodcastsEpisodesGrid from './podcsts-episodes-grid/podcasts-episodes-grid';
import bookIcon from '../../assets/icons/icons8-open-book-50.png';
import TabIcon from "../../components/tab-icon/tab-icon";

const { TabPane } = Tabs;

const { confirm, error } = Modal;
function WishListScreen() {
  const { tab } = useParams<{ tab: string }>();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTab, setCurrentTab] = useState(tab || 'books');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
  }, [currentUser]);

  const onMediumClick = (mediumShelf: any) => {
    if (!isEditing) {
      if (mediumShelf.typeValue === 4) {
        history.push(`/episodes/${mediumShelf.medium._id}`);
      } else {
        history.push(`/mediums/${mediumShelf.external.id}/${mediumShelf.typeValue}`);
      }
    }
  };

  const onRemoveMediumClick = (mediumShelf: any, callback: Function) => {
    confirm({
      title: 'Remove item?',
      content: 'Are you sure you want to remove this item?',
      onOk() {
        return new Promise((resolve, reject) => {
          const { medium } = mediumShelf;
          dispatch(removeMediumFromShelf(mediumShelf._id, (success:boolean) => {
            if (success) {
              callback(mediumShelf._id);
              const shelfTitle = getCorrectShelf(medium.typeValue);
              if (shelfTitle === 'booksCount') {
                dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, wishBooksCount: currentUser!.wishBooksCount - 1 } });
              } else if (shelfTitle === 'podcastsCount') {
                dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, wishPodcastsCount: currentUser!.wishPodcastsCount - 1 } });
              } else if (shelfTitle === 'moviesCount') {
                dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, wishMoviesCount: currentUser!.wishMoviesCount - 1 } });
              } else if (shelfTitle === 'seriesCount') {
                dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, wishSeriesCount: currentUser!.wishSeriesCount - 1 } });
              }
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).then(() => {}).catch(() => {});
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onEditingClick = () => {
    setIsEditing(!isEditing);
  };

  const onTabChange = (activeTab: string) => {
    setCurrentTab(activeTab);
    history.replace(`/wishlist/${activeTab}`);
  };

  return (
    currentUser
        && (
        <div className={styles.WishListScreen}>
          <div className={styles.container}>
            <Tabs
              tabBarGutter={0}
              className={styles.tabs}
              defaultActiveKey={currentTab}
              onChange={onTabChange}
            >
              <TabPane
                // tab={<TabTitle text="Books" count={currentUser.wishBooksCount} />}
                tab={<TabIcon icon="books" count={currentUser.wishBooksCount} />}
                key="books"
              >
                <MediumsGrid
                  isEditing={isEditing}
                  onEditingClick={onEditingClick}
                  type="books"
                  mediumCount={currentUser.wishBooksCount}
                  onRemoveMediumClick={onRemoveMediumClick}
                  onMediumClick={onMediumClick}
                  title="Find millions of books"
                  subTitle="Search for books"
                  icon="search"
                  url="/search/ebook"
                  buttonTitle="Search Books"
                  screenItem="empty_books"
                />
              </TabPane>
              <TabPane
                // tab={<TabTitle text="Series" count={currentUser.wishSeriesCount} />}
                tab={<TabIcon icon="series" count={currentUser.wishSeriesCount} />}
                key="series"
              >
                <MediumsGrid
                  isEditing={isEditing}
                  onEditingClick={onEditingClick}
                  type="series"
                  mediumCount={currentUser.wishSeriesCount}
                  onRemoveMediumClick={onRemoveMediumClick}
                  onMediumClick={onMediumClick}
                  title="Find millions of series"
                  subTitle="Search for series"
                  icon="search"
                  url="/search/tvShow"
                  buttonTitle="Search Series"
                  screenItem="empty_series"
                />
              </TabPane>
              <TabPane
                //tab={<TabTitle text="Movies" count={currentUser.wishMoviesCount} />}
                tab={<TabIcon icon="movies" count={currentUser.wishMoviesCount} />}
                key="movies"
              >
                <MediumsGrid
                  isEditing={isEditing}
                  onEditingClick={onEditingClick}
                  type="movies"
                  mediumCount={currentUser.wishMoviesCount}
                  onRemoveMediumClick={onRemoveMediumClick}
                  onMediumClick={onMediumClick}
                  title="Find millions of movies"
                  subTitle="Search for movies"
                  icon="search"
                  url="/search/movie"
                  buttonTitle="Search Movies"
                  screenItem="empty_movies"
                />
              </TabPane>
              <TabPane
                //tab={<TabTitle text="Podcasts" count={currentUser.wishPodcastsCount} />}
                tab={<TabIcon icon="podcasts" count={currentUser.wishPodcastsCount} />}
                key="podcasts"
              >
                <PodcastsGrid
                  isEditing={isEditing}
                  onEditingClick={onEditingClick}
                  type="podcasts"
                  mediumCount={currentUser.wishPodcastsCount}
                  onRemoveMediumClick={onRemoveMediumClick}
                  onMediumClick={onMediumClick}
                  title="Find millions of podcasts"
                  subTitle="Search for podcasts"
                  icon="search"
                  url="/search/podcast"
                  buttonTitle="Search Podcasts"
                  screenItem="empty_podcasts"
                />
              </TabPane>
              <TabPane
                //tab={<TabTitle text="Episodes" count={currentUser.wishPodcastEpisodeCount} />}
                tab={<TabIcon icon="episodes" count={currentUser.wishPodcastEpisodeCount} />}
                key="episodes"
              >
                <PodcastsEpisodesGrid
                  isEditing={isEditing}
                  onEditingClick={onEditingClick}
                  type="podcast-episode"
                  mediumType="episodes"
                  mediumCount={currentUser.wishPodcastEpisodeCount}
                  onRemoveMediumClick={onRemoveMediumClick}
                  onMediumClick={onMediumClick}
                  title="Find millions of podcasts"
                  subTitle="Search for podcasts"
                  icon="search"
                  url="/search/podcast"
                  buttonTitle="Search Podcasts"
                  screenItem="empty_podcasts"
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
        )
  );
}

export default WishListScreen;
