import React from 'react';
import styles from './BackgroundText.module.scss';
import {highlightHashTags} from "../../../../helpers/highlight";
import {Typography} from "antd";


const { Paragraph } = Typography;
interface HashTagProps {

}

function BackgroundText(props: HashTagProps) {

    return (
        <div className={styles.BackgroundText}>
            <div className={styles.textContainer}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci assumenda eligendi iste porro qui voluptate. Accusantium consequatur, dolor eaque enim illo, illum ipsam minima neque non perspiciatis quae qui soluta!</div>
            <div className={styles.container}>
                <div
                    className={styles.title}
                    //style={{ flex: 'nowrap' }}
                >
                    {
                        highlightHashTags("Classic quote with quote as background text", { color: '#2ECC71', fontSize: "14px", alpha: 1, fontWeight: 600 })
                    }
                </div>
                <div className={styles.source}>
                    <div>
                        <i>author</i>
                    </div>
                    <div>
                        book name
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BackgroundText;
