import React from 'react';
import { Progress } from 'antd';
import styles from './CreateProgress.module.scss';

interface CreateProgressProps {
  current: number;
  pages: any[];
  existingId?: string;
}

function CreateProgress(props: CreateProgressProps) {
  const { current, pages } = props;

  return (
    <div className={styles.CreateProgress}>
      <div className={styles.progress}>
        <Progress
          type="circle"
          width={50}
          strokeColor={{
            '0%': '#2ECC71',
            '100%': '#2ECC71',
          }}
          percent={((current / pages.length) * 100)}
          format={(percent) => `${current} of ${pages.length}`}
        />
      </div>
      <div className={styles.progressInfo}>
        <div className={styles.title}>
          {pages[current - 1]?.title}
        </div>
        <div className={styles.subtitle}>
          {pages[current] ? `NEXT: ${pages[current]?.title}` : 'DONE'}
        </div>
      </div>
    </div>
  );
}

export default CreateProgress;
