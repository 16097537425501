import React, {useState} from 'react';
import styles from './club-stories-tab.module.scss';
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {TopicUser} from "../../../models/TopicUser";
import TopicCard from "../../club-stories-screen/topic-card/topic-card";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {Button} from "antd";
import Empty from "../../../components/Empty/Empty";

function ClubStoriesTab() {

    const currentId = useSelector<StoreState, string>((state) => state.currentId);
    const { id, storiesId } = useParams<{ id: string, storiesId: string }>();
    const [searchQuery, setSearchQuery] = useState('');
    const [limit] = useState(12);
    const [isAtTheEndYours, setIsAtTheEndYours] = useState(false);
    const [timeStamp] = useState(new Date());
    const history = useHistory();

    const {
        data: yourData,
        isFetching: yourIsFetching,
        isLoading,
        fetchMore: yourFetchMore,
    } = useInfiniteQuery(
        `club-stories-screen-your-${id}-${searchQuery}`,
        async (key, nextId = 0) => {
            const { data } = await axios.get(`/api/clubs/topics/club/yours/${id}?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}&type=STORY`);
            if (data?.topicUsers?.length < limit) {
                setIsAtTheEndYours(true);
            }
            return data;
        },
        {
            getFetchMore: (lastGroup) => lastGroup.nextId,
        },
    );

    const onTopicClick = (topicId: string) => {
        history.push(`/storyclubs/${id}/stories/${topicId}`);
    };

    return (
        <div className={styles.ClubStoriesTab}>
            {
                yourIsFetching === false && yourData?.map((page) => page.topicUsers).flat().length === 0 &&
                <Empty
                    title="Join your first story"
                    subTitle="Join stories"
                    icon="plus"
                    buttonTitle="Join stories"
                    url={`/storyclubs/${id}/stories`}
                    screen="posts"
                />
            }
            {
                isLoading === false && yourData?.map((page) => page.topicUsers).flat().length !== 0 &&
                <div className={styles.viewAllStoriesContainer}>
                    <Button
                        className={styles.viewAllStories}
                        size="large"
                        onClick={() => history.push(`/storyclubs/${id}/stories`)}
                    >
                        View all stories
                    </Button>
                </div>
            }
            {
                yourData?.map((page) => page.topicUsers).flat()
                    .map((item: TopicUser, index: number) => (
                        <TopicCard
                            key={`topic-card-${index}`}
                            topic={item.topic}
                            notificationCount={item.notificationCount}
                            onTopicClick={(id: string) => onTopicClick(id)}
                            type="yours"
                            isLoading={isLoading}
                            currentId={currentId}
                        />
                    ))
            }
        </div>
    );
}

export default ClubStoriesTab;