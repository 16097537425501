import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button, Form,
} from 'antd';
import styles from './create-quiz-screen.module.scss';
import MyMediumList from '../../components/MyMediumList/MyMediumList';
import { Medium } from '../../models/Medium';
import { ActionTypes } from '../../actions/types';
import { StoreState } from '../../reducers/_RootReducers';
import CreateProgress from '../../components/CreateProgress/CreateProgress';
import QuizInfo from './quiz-info/quiz-info';
import { CreateQuiz } from '../../models/create-models/CreateQuiz';
import PreviewQuiz from './preview-quiz/preview-quiz';
import { createNewQuizPost} from '../../services-api/quizService';
import QuizQuestions from "./quiz-questions/quiz-questions";
import {useQueryCache} from "react-query";

function CreateQuizScreen() {
  const [form] = Form.useForm();
  const createQuiz = useSelector<StoreState, CreateQuiz>((state) => state.createQuiz);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const onMyMediumClick = (medium: Medium) => {
    if (createQuiz.currentPage < pages.length) {
      const newCreateQuiz = {
        ...createQuiz, medium, currentPage: createQuiz.currentPage + 1, totalPages: pages.length, type: 'quiz',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: newCreateQuiz });
    }
  };

  const pages = [
    {
      page: 1,
      showNavigation: false,
      title: 'select a story',
      content: <MyMediumList onMyMediumClick={onMyMediumClick} />,
    },
    {
      page: 2,
      showNavigation: false,
      title: 'quiz info',
      content: <QuizInfo />,
    },
    {
      page: 3,
      showNavigation: false,
      title: 'quiz questions',
      content: <QuizQuestions />,
    },
    {
      page: 4,
      showNavigation: true,
      title: 'preview quiz',
      content: <PreviewQuiz />,
    },
  ];

  const onPreviousClick = () => {
    if (createQuiz.currentPage > 1) {
      const newCreateQuiz = {
        ...createQuiz, currentPage: createQuiz.currentPage - 1, totalPages: pages.length, type: 'quiz',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: newCreateQuiz });
    } else {
      history.replace('/create')
    }
  };

  const onFinish = (values: any) => {
    if (createQuiz.currentPage < pages.length) {
      const newCreateQuiz = {
        ...createQuiz, currentPage: createQuiz.currentPage + 1, totalPages: pages.length, type: 'quiz',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUIZ, value: newCreateQuiz });
    } else if (createQuiz.currentPage === pages.length) {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
      dispatch(createNewQuizPost(createQuiz, () => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        queryCache.invalidateQueries('home-screen').then(r => {
          history.replace('/home');
        });
      }));
    }
  };

  const onFinishFailed = (error: any) => {

  };

  return (
    <div className={styles.CreateQuizScreen}>
      <Form
        className={styles.container}
        form={form}
        action="create-quiz"
        name="create-quiz"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <CreateProgress existingId={createQuiz.quizId} current={createQuiz.currentPage} pages={pages} />
        <div className={styles.listContainer}>
          {
            pages[createQuiz.currentPage - 1].content
          }
        </div>
        <div className={styles.inputContainer}>
          <Button
              className={styles.previousButton}
              onClick={onPreviousClick}
          >
            Previous
          </Button>
          <Form.Item style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <Button
                className={styles.nextButton}
                type="primary"
                htmlType="submit"
                formAction="create-quiz"
                loading={isLoading}
            >
              {createQuiz.currentPage === pages.length ? 'Post' : 'Next'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default CreateQuizScreen;
