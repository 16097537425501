import React from 'react';
import { Button, Tag, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styles from './notes-card.module.scss';
import { Note } from '../../../models/Note';
import { convertTimeToDaysAgo } from '../../../helpers/helpers';

type NotesCardProps = {
  note: Note;
  onNotesCardClick: (event: any, note: Note) => void;
  showClose?: boolean;
  onCloseAction?: () => void;
  hideTimeStamp?: boolean;
  hideBorder?: boolean;
};

const { Paragraph } = Typography;
function NotesCard(props: NotesCardProps) {
  const {
    note, showClose, onNotesCardClick, onCloseAction, hideTimeStamp, hideBorder,
  } = props;

  const getLocationText = (note: Note) => {
    if (note.location) {
      return `Page ${note.location}`;
    }

    if (note.start && note.start.minutes && note.end && note.end.minutes) {
      return `${note.start.minutes}min - ${note.end.minutes}min`;
    }

    if (note.start && note.start.minutes) {
      return `${note.start.minutes}min`;
    }

    return '';
  };

  const getPreviewText = (note: Note) => {
    if (note.type === 'TEXT') {
      return note.text;
    }

    if (note.type === 'QUOTE_THOUGHT') {
      return note.quoteText;
    }

    if (note.type === 'QUESTION_ANSWER') {
      return note.questionText;
    }

    return '';
  };

  const getBackgroundColor = (tempNote: Note) => {
    switch (tempNote.type) {
      case 'QUESTION_ANSWER':
        return styles.green;
      case 'QUOTE_THOUGHT':
        return styles.yellow;
      case 'TEXT':
        return styles.blue;
      default:
        return styles.blue;
    }
  };

  return (
    <div className={`${styles.NotesCard} ${hideBorder ? styles.hideAround : styles.borderAround}`}
         onClick={(e) => onNotesCardClick(e, note)}
    >
      <div className={`${styles.backgroundContainer} ${getBackgroundColor(note)}`}>
        <div className={styles.container}>
          <div className={styles.locationContainer}>
            <div className={styles.value}>
              {note.locationType === 'TIME' ? `${note.start.minutes}-${note.end.minutes}` : `${note.location}`}
            </div>
            <div className={styles.title}>
              {note.locationType === 'TIME' ? 'min' : 'page no.'}
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.titleCloseContainer}>
              <Paragraph
                className={styles.message}
                ellipsis={{ rows: 1, expandable: false }}
              >
                {note.title}
              </Paragraph>
              {
                showClose
                && (
                <Button
                  type="link"
                  icon={<CloseOutlined />}
                  onClick={onCloseAction}
                />
                )
              }
            </div>
            <Paragraph
              className={styles.subtitle}
              ellipsis={{ rows: 2, expandable: false }}
            >
              {getPreviewText(note)}
            </Paragraph>
            <div className={styles.footer}>
              <div className={styles.tags}>
                {
                  note?.noteCategories?.map((noteCategory, index) => <Tag key={`noteCategory-${index}`}>{noteCategory.displayName}</Tag>)
                }
              </div>
              {
                !hideTimeStamp
                && <div className={styles.timeStamp}>{convertTimeToDaysAgo(note.updatedAt)}</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotesCard;
