import { ActionTypes } from '../actions/types';

interface setNotifications {
  type: typeof ActionTypes.SET_NOTIFICATIONS;
  notifications: any[];
}

type notificationsActions = setNotifications;

export const notifications = (state = [], action: notificationsActions) => {
  if (action.type === ActionTypes.SET_NOTIFICATIONS) {
    return action.notifications;
  }
  return state;
};
