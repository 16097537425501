import React, {useState} from 'react';
import styles from "./club-home-tab.module.scss";
import {TeamOutlined} from "@ant-design/icons";
import {Button, Spin} from "antd";
import StoryCard from "./story-card/story-card";
import {useInfiniteQuery, useQueryCache} from "react-query";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {TopicPost} from "../../../models/TopicPost";
import {Topic} from "../../../models/Topic";
import {TopicUser} from "../../../models/TopicUser";
import TopicDiscussionCard from "../../club-discussions-screen/topic-discussion-card/topic-discussion-card";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import Empty from "../../../components/Empty/Empty";
import RecentCardSkeleton from "../../clubs-screen/club-recent-activity/recent-card-skeleton/recent-card-skeleton";
import {RecentActivity as RecentActivityModel} from "../../../models/RecentActivity";
import RecentActivityCard from "../../clubs-screen/club-recent-activity/recent-activity-card/recent-activity-card";
import InfiniteScroll from "react-infinite-scroll-component";
import {ActionTypes} from "../../../actions/types";
import {joinTopic} from "../../../services-api/clubTopicsService";
import TopicCard from "../../club-stories-screen/topic-card/topic-card";

type ClubHomeTabProps = {

}

function ClubHomeTab(props: ClubHomeTabProps) {
    const { id, storiesId } = useParams<{ id: string, storiesId: string }>();
    const [timeStamp] = useState(new Date());
    const [limit] = useState(12);
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [yourSortBy] = useState('topic.updatedAt');
    const [isAtTheEndYours, setIsAtTheEndYours] = useState(false);
    const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
    const currentId = useSelector<StoreState, string>((state) => state.currentId);
    const history = useHistory();
    const dispatch = useDispatch();
    const queryCache = useQueryCache();

    const {
        data,
        isFetching,
        fetchMore,
    } = useInfiniteQuery(
        `club-stories-home-tab-screen-${id}`,
        async (key, nextId = 0) => {
            const { data } = await axios.get(`/api/clubs/topics/club/${id}?search=${searchQuery}&limit=${limit}&page=${nextId}&type=STORY&timestamp=${timeStamp}`);
            if (data?.topics?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getFetchMore: (lastGroup) => lastGroup.nextId,
        },
    );

    const {
        data: yourData,
        isFetching: yourIsFetching,
        fetchMore: yourFetchMore,
    } = useInfiniteQuery(
        `club-topics-screen-your-${id}-${searchQuery}`,
        async (key, nextId = 0) => {
            const { data } = await axios.get(`/api/clubs/topics/club/yours/${id}?search=${searchQuery}&sortBy=${yourSortBy}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}&type=DISCUSSION`);
            if (data?.topicUsers?.length < limit) {
                setIsAtTheEndYours(true);
            }
            return data;
        },
        {
            getFetchMore: (lastGroup) => lastGroup.nextId,
        },
    );

    const onStoryTopicClick = (topicId: string) => {
        history.push(`/storyclubs/${id}/stories/${topicId}`);
    };

    const onTopicClick = (topicId: string) => {
        history.push(`/storyclubs/${id}/discussions/${topicId}`);
    };

    const onJoinClick = (e: any, topicId: string) => {
        e.stopPropagation();
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
        dispatch({ type: ActionTypes.SET_CURRENT_ID, value: topicId });
        dispatch(joinTopic(topicId, () => {
            dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
            dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'x' });
            queryCache.invalidateQueries(`club-stories-home-tab-screen-${id}`);
            // queryCache.invalidateQueries(`club-stories-screen-all-${id}-${searchQuery}`);
        }));
    }

    return (
        <div className={styles.ClubHomeTab}>
            {
                isFetching === false &&  data?.map((page) => page.topics).flat().length === 0 &&
                <Empty
                    title="Join your first story"
                    subTitle="Join fun stories"
                    icon="plus"
                    buttonTitle="Join Story"
                    url="/createclubs"
                    screen="posts"
                />
            }
            {
                isFetching && !data ?
                    <div className={styles.container}>
                        {
                            [1, 1, 1, 1, 1, 1].map((item, index) => <RecentCardSkeleton key={`${index}`} />)
                        }
                    </div>
                    :
                    ((data?.map((page) => page.topics).flat().length || 0) > 0) &&
                    <div className={styles.contentContainer}>
                        <div className={styles.title}>
                            Recent stories
                        </div>
                        <div className={styles.storyCardsContainer}>
                            <div className={styles.storyCards}>
                                {
                                    data?.map((page) => page.topics).flat().map((topic: Topic, index) => {
                                        return <StoryCard
                                            key={`topic-${index}`}
                                            topic={topic}
                                            onTopicClick={onStoryTopicClick}
                                            onJoinClick={onJoinClick}
                                            currentUsersTopicIds={data?.map((page) => page.currentUsersTopicIds).reduce((result, current) => Object.assign(result, current), {})}
                                        />
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
            {
                yourIsFetching && !yourData ?
                    <div className={styles.container}>
                        {
                            [1, 1, 1, 1, 1, 1].map((item, index) => <RecentCardSkeleton key={`${index}`} />)
                        }
                    </div>
                    :
                    ((yourData?.map((page) => page.topicUsers).flat().length || 0) > 0) &&
                    <div className={styles.contentContainer}>
                        <div className={styles.title}>
                            Recent discussions
                        </div>
                        <div>
                            {
                                yourData?.map((page) => page.topicUsers).flat()
                                    .map((item: TopicUser, index: number) => (
                                        <TopicDiscussionCard
                                            key={`topic-card-${index}`}
                                            topic={item.topic}
                                            notificationCount={item.notificationCount}
                                            onTopicClick={(id: string) => onTopicClick(id)}
                                            type="yours"
                                            isLoading={isLoading}
                                            currentId={currentId}
                                        />
                                    ))
                            }
                        </div>
                    </div>
            }
        </div>
    );
}

export default ClubHomeTab;