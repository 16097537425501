import React, { useState, useRef } from 'react';
import {
  Button, List, Modal, Progress,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styles from './InterestModal.module.scss';
import InterestCard from '../../screens/HomeScreen/InterestCard/InterestCard';
import moviesJson from '../../helpers/data/movies.json';
import booksJson from '../../helpers/data/books_actual.json';
import seriesJson from '../../helpers/data/series.json';
import podcastsJson from '../../helpers/data/podcasts.json';
import YesNoCard from '../../screens/HomeScreen/YesNoCard/YesNoCard';
import { InterestMovieGenre } from '../../models/InterestMovieGenre';
import { StoreState } from '../../reducers/_RootReducers';
import { SetupInterests } from '../../models/setupInterests';
import { ActionTypes } from '../../actions/types';
import DoneCard from '../../screens/HomeScreen/DoneCard/DoneCard';
import { InterestBookGenre } from '../../models/InterestBookGenre';
import MultipleSelect from '../../screens/HomeScreen/MultipleSelect/MultipleSelect';
import { InterestSeriesGenre } from '../../models/InterestSeriesGenre';
import { InterestPodcastsGenre } from '../../models/InterestPodcastsGenre';
import InterestMediumCard2 from '../../screens/HomeScreen/InterestMediumCard2/InterestMediumCard2';
import Welcome from '../../screens/HomeScreen/Welcome/Welcome';
import { User } from '../../models/User';
import { updateUser } from '../../services-api/userService';
import { addMediumIdToShelf } from '../../services-api/mediumService';

interface InterestModalProps {

}

let setupInterests: SetupInterests = {
  skip: false,
  complete: false,
  books: true,
  series: true,
  movies: true,
  podcasts: true,
  bookGenres: [],
  seriesGenres: [],
  movieGenres: [],
  podcastGenres: [],
  bookConsumptionTypes: [],
  seriesConsumptionTypes: [],
  movieConsumptionTypes: [],
  podcastConsumptionTypes: [],
};

function InterestModal() {
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const currentId = useSelector<StoreState, string>((state) => state.currentId);
  const currentUser = useSelector<StoreState, User >((state) => state.currentUser);
  const isInterestModalVisible = useSelector<StoreState, boolean>((state) => state.isInterestModalVisible);
  const [activeQuestion, setActiveQuestion] = useState('welcome');
  const [likesBooks, setLikesBooks] = useState<any>({
    uniqueName: 'books', imageUrl: 'https://i.ibb.co/h8hLg7Y/books.jpg', answer: null, displayName: 'Do you enjoy reading books or listening to audiobooks?',
  });
  const [likesSeries, setLikesSeries] = useState<any>({
    uniqueName: 'series', imageUrl: 'https://i.ibb.co/hdyk8kc/series.jpg', answer: null, displayName: 'Do you enjoy watching series?',
  });
  const [likesMovies, setLikesMovies] = useState<any>({
    uniqueName: 'movies', imageUrl: 'https://i.ibb.co/h8hLg7Y/books.jpg', answer: null, displayName: 'Do you enjoy watching movies?',
  });
  const [likesPodcasts, setLikesPodcasts] = useState<any>({
    uniqueName: 'podcasts', imageUrl: 'https://i.ibb.co/h8hLg7Y/books.jpg', answer: null, displayName: 'Do you enjoy listening to podcasts?',
  });
  const [temp, setTemp] = useState<any[]>([]);
  const refContainer = useRef<any>(null);

  const setFinal = (item: any) => {
    setTemp([...temp, item]);
  };

  const [seriesBasedOnGenre, setSeriesBasedOnGenre] = useState<any>([]);
  const [series, setSeries] = useState<InterestSeriesGenre[]>(seriesJson.map((x) => ({
    ...x,
    selected: false,
  })));

  const [moviesBasedOnGenre, setMoviesBasedOnGenre] = useState<any>([]);
  const [movies, setMovies] = useState<InterestMovieGenre[]>(moviesJson.map((x) => ({
    ...x,
    selected: false,
  })));

  const [podcastsBasedOnGenre, setPodcastsBasedOnGenre] = useState<any>([]);
  const [podcasts, setPodcasts] = useState<InterestPodcastsGenre[]>(podcastsJson.map((x) => ({
    ...x,
    selected: false,
  })));

  const [booksConsumptionOptions, setBooksConsumptionOptions] = useState([{ uniqueName: 'books', displayName: <span style={{ textAlign: 'left' }}>I only read books/ebooks</span>, selected: false },
    { uniqueName: 'audiobooks', displayName: <span style={{ textAlign: 'left' }}>I only listen to audiobooks</span>, selected: false },
    {
      uniqueName: 'books_audiobooks',
      displayName:
  <span style={{ textAlign: 'left' }}>
    I like both reading books/ebooks
    <br />
    and listening to audiobooks
  </span>,
      selected: false,
    }]);

  const [seriesConsumptionOptions, setSeriesConsumptionOptions] = useState([{ uniqueName: 'netflix', displayName: <span style={{ textAlign: 'left' }}>Netflix</span>, selected: false },
    { uniqueName: 'hulu', displayName: <span style={{ textAlign: 'left' }}>Hulu</span>, selected: false },
    { uniqueName: 'hbo', displayName: <span style={{ textAlign: 'left' }}>HBO</span>, selected: false },
    { uniqueName: 'disney', displayName: <span style={{ textAlign: 'left' }}>Disney</span>, selected: false },
    { uniqueName: 'other', displayName: <span style={{ textAlign: 'left' }}>Other</span>, selected: false },
  ]);

  const [moviesConsumptionOptions, setMoviesConsumptionOptions] = useState([{ uniqueName: 'netflix', displayName: <span style={{ textAlign: 'left' }}>Netflix</span>, selected: false },
    { uniqueName: 'hulu', displayName: <span style={{ textAlign: 'left' }}>Hulu</span>, selected: false },
    { uniqueName: 'hbo', displayName: <span style={{ textAlign: 'left' }}>HBO</span>, selected: false },
    { uniqueName: 'disney', displayName: <span style={{ textAlign: 'left' }}>Disney</span>, selected: false },
    { uniqueName: 'other', displayName: <span style={{ textAlign: 'left' }}>Other</span>, selected: false },
  ]);

  const [podcastsConsumptionOptions, setPodcastsConsumptionOptions] = useState([{ uniqueName: 'itunes', displayName: <span style={{ textAlign: 'left' }}>iTunes</span>, selected: false },
    { uniqueName: 'spotify', displayName: <span style={{ textAlign: 'left' }}>Spotify</span>, selected: false },
    { uniqueName: 'youtube', displayName: <span style={{ textAlign: 'left' }}>Youtube</span>, selected: false },
    { uniqueName: 'other', displayName: <span style={{ textAlign: 'left' }}>Other</span>, selected: false },
  ]);

  const [booksBasedOnGenre, setBooksBasedOnGenre] = useState<any>([]);
  const [books, setBooks] = useState<InterestBookGenre[]>(booksJson.map((x) => ({
    ...x,
    selected: false,
  })));

  const dispatch = useDispatch();
  const onNoClick = (uniqueName: string) => {
    switch (uniqueName) {
      case 'books':
        setupInterests = { ...setupInterests, books: false };
        setLikesBooks({ ...likesBooks, answer: false });
        break;
      case 'series':
        setupInterests = { ...setupInterests, series: false };
        setLikesSeries({ ...likesSeries, answer: false });
        break;
      case 'movies':
        setupInterests = { ...setupInterests, movies: false };
        setLikesMovies({ ...likesMovies, answer: false });
        break;
      case 'podcasts':
        setupInterests = { ...setupInterests, podcasts: false };
        setLikesPodcasts({ ...likesPodcasts, answer: false });
        break;
      default:
        break;
    }
  };

  const onYesClick = (uniqueName: string) => {
    switch (uniqueName) {
      case 'books':
        setupInterests = { ...setupInterests, books: true };
        setLikesBooks({ ...likesBooks, answer: true });
        break;
      case 'series':
        setupInterests = { ...setupInterests, series: true };
        setLikesSeries({ ...likesSeries, answer: true });
        break;
      case 'movies':
        setupInterests = { ...setupInterests, movies: true };
        setLikesMovies({ ...likesMovies, answer: true });
        break;
      case 'podcasts':
        setupInterests = { ...setupInterests, podcasts: true };
        setLikesPodcasts({ ...likesPodcasts, answer: true });
        break;
      default:
        break;
    }
  };

  const onBookSelect = (uniqueName: string) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: uniqueName });
    let externalId = '';
    let type = '';
    const updatedBooks = [...booksBasedOnGenre.map((x:any) => ({
      ...x,
      books: x.books.map((y:any) => {
        if (y.title === uniqueName) {
          const itemSplit = y.link.split('/');
          externalId = itemSplit[4];
          type = itemSplit[5];
          return { ...y, selected: true };
        }
        return y;
      }),
    }))];
    dispatch(addMediumIdToShelf(type, externalId, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
      setBooksBasedOnGenre(updatedBooks);
    }));
  };

  const onSeriesSelect = (uniqueName: string) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: uniqueName });
    let externalId = '';
    let type = '';
    const updatedSeries = [...seriesBasedOnGenre.map((x:any) => ({
      ...x,
      series: x.series.map((y:any) => {
        if (y.title === uniqueName) {
          const itemSplit = y.link.split('/');
          externalId = itemSplit[4];
          type = itemSplit[5];
          return { ...y, selected: true };
        }
        return y;
      }),
    }))];

    dispatch(addMediumIdToShelf(type, externalId, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
      setSeriesBasedOnGenre(updatedSeries);
    }));
  };

  const onMoviesSelect = (uniqueName: string) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: uniqueName });
    let externalId = '';
    let type = '';
    const updatedMovies = [...moviesBasedOnGenre.map((x:any) => ({
      ...x,
      movies: x.movies.map((y:any) => {
        if (y.title === uniqueName) {
          const itemSplit = y.link.split('/');
          externalId = itemSplit[4];
          type = itemSplit[5];
          return { ...y, selected: true };
        }
        return y;
      }),
    }))];

    dispatch(addMediumIdToShelf(type, externalId, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
      setMoviesBasedOnGenre(updatedMovies);
    }));
  };

  const onPodcastsSelect = (uniqueName: string) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: uniqueName });
    let externalId = '';
    let type = '';
    const updatedPodcasts = [...podcastsBasedOnGenre.map((x:any) => ({
      ...x,
      podcasts: x.podcasts.map((y:any) => {
        if (y.title === uniqueName) {
          const itemSplit = y.link.split('/');
          externalId = itemSplit[4];
          type = itemSplit[5];
          return { ...y, selected: true };
        }
        return y;
      }),
    }))];

    dispatch(addMediumIdToShelf(type, externalId, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
      setPodcastsBasedOnGenre(updatedPodcasts);
    }));
  };

  const onMovieGenreSelect = (uniqueName: string) => {
    setMovies(movies.map((x) => {
      if (x.uniqueName === uniqueName) {
        if (x.selected) {
          setupInterests = { ...setupInterests, movieGenres: [...setupInterests.movieGenres.filter((y) => y !== uniqueName)] };
          setMoviesBasedOnGenre([...moviesBasedOnGenre.filter((y:any) => y.uniqueName !== uniqueName)]);
        } else {
          setupInterests = { ...setupInterests, movieGenres: [...setupInterests.movieGenres, uniqueName] };
          setMoviesBasedOnGenre([...moviesBasedOnGenre, {
            uniqueName,
            movies: x.movies.map((y) => ({ ...y, selected: false })),
          }]);
        }
        return { ...x, selected: !x.selected };
      }
      return x;
    }));
  };

  const onSeriesGenreSelect = (uniqueName: string) => {
    setSeries(series.map((x) => {
      if (x.uniqueName === uniqueName) {
        if (x.selected) {
          setupInterests = { ...setupInterests, seriesGenres: [...setupInterests.seriesGenres.filter((y) => y !== uniqueName)] };
          setSeriesBasedOnGenre([...seriesBasedOnGenre.filter((y:any) => y.uniqueName !== uniqueName)]);
        } else {
          setupInterests = { ...setupInterests, seriesGenres: [...setupInterests.seriesGenres, uniqueName] };
          setSeriesBasedOnGenre([...seriesBasedOnGenre, {
            uniqueName,
            series: x.series.map((y) => ({ ...y, selected: false })),
          }]);
        }
        return { ...x, selected: !x.selected };
      }
      return x;
    }));
  };

  const onPodcastsGenreSelect = (uniqueName: string) => {
    setPodcasts(podcasts.map((x) => {
      if (x.uniqueName === uniqueName) {
        if (x.selected) {
          setupInterests = { ...setupInterests, podcastGenres: [...setupInterests.podcastGenres.filter((y) => y !== uniqueName)] };
          setPodcastsBasedOnGenre([...podcastsBasedOnGenre.filter((y:any) => y.uniqueName !== uniqueName)]);
        } else {
          setupInterests = { ...setupInterests, podcastGenres: [...setupInterests.podcastGenres, uniqueName] };
          setPodcastsBasedOnGenre([...podcastsBasedOnGenre, {
            uniqueName,
            podcasts: x.podcasts.map((y) => ({ ...y, selected: false })),
          }]);
        }
        return { ...x, selected: !x.selected };
      }
      return x;
    }));
  };

  const onBookGenreSelect = (uniqueName: string) => {
    setBooks(books.map((x) => {
      if (x.uniqueName === uniqueName) {
        if (x.selected) {
          setupInterests = { ...setupInterests, bookGenres: [...setupInterests.bookGenres.filter((y) => y !== uniqueName)] };
          setBooksBasedOnGenre([...booksBasedOnGenre.filter((y:any) => y.uniqueName !== uniqueName)]);
        } else {
          setupInterests = { ...setupInterests, bookGenres: [...setupInterests.bookGenres, uniqueName] };
          setBooksBasedOnGenre([...booksBasedOnGenre, {
            uniqueName,
            books: x.books.map((y) => ({ ...y, selected: false })),
          }]);
        }
        return { ...x, selected: !x.selected };
      }
      return x;
    }));
  };

  const onSelectAction = (uniqueName: string) => {
    setBooksConsumptionOptions(booksConsumptionOptions.map((x) => {
      if (x.uniqueName === uniqueName) {
        setupInterests = { ...setupInterests, bookConsumptionTypes: [uniqueName] };
        return {
          ...x,
          selected: true,
        };
      }
      return {
        ...x,
        selected: false,
      };
    }));
  };

  const onMoviesSelectAction = (uniqueName: string) => {
    setMoviesConsumptionOptions(moviesConsumptionOptions.map((x) => {
      if (x.uniqueName === uniqueName) {
        if (x.selected) {
          setupInterests = { ...setupInterests, movieConsumptionTypes: [...setupInterests.movieConsumptionTypes.filter((x) => x !== uniqueName)] };
        } else {
          setupInterests = { ...setupInterests, movieConsumptionTypes: [...setupInterests.movieConsumptionTypes, uniqueName] };
        }
        return { ...x, selected: !x.selected };
      }
      return x;
    }));
  };

  const onPodcastsSelectAction = (uniqueName: string) => {
    setPodcastsConsumptionOptions(podcastsConsumptionOptions.map((x) => {
      if (x.uniqueName === uniqueName) {
        if (x.selected) {
          setupInterests = { ...setupInterests, podcastConsumptionTypes: [...setupInterests.podcastConsumptionTypes.filter((x) => x !== uniqueName)] };
        } else {
          setupInterests = { ...setupInterests, podcastConsumptionTypes: [...setupInterests.podcastConsumptionTypes, uniqueName] };
        }
        return { ...x, selected: !x.selected };
      }
      return x;
    }));
  };

  const onSeriesSelectAction = (uniqueName: string) => {
    setSeriesConsumptionOptions(seriesConsumptionOptions.map((x) => {
      if (x.uniqueName === uniqueName) {
        if (x.selected) {
          setupInterests = { ...setupInterests, seriesConsumptionTypes: [...setupInterests.seriesConsumptionTypes.filter((x) => x !== uniqueName)] };
        } else {
          setupInterests = { ...setupInterests, seriesConsumptionTypes: [...setupInterests.seriesConsumptionTypes, uniqueName] };
        }
        return { ...x, selected: !x.selected };
      }
      return x;
    }));
  };

  const onYesAction = () => {
    setActiveQuestion('books');
  };

  const onSkipAction = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    const updatedSetupInterests = { ...setupInterests, skip: true };
    dispatch(updateUser({ setupInterests: updatedSetupInterests }, (user) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_INTEREST_MODAL_VISIBILITY, value: false });
      dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, ...user, setupInterests: { ...user.setupInterests, skip: true, complete: true } } });
    }));
  };

  const onSaveAction = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    const updatedSetupInterests = { ...setupInterests, complete: true };
    dispatch(updateUser({ setupInterests: updatedSetupInterests }, (user) => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_INTEREST_MODAL_VISIBILITY, value: true });
      setActiveQuestion('done');
      dispatch({ type: ActionTypes.SET_CURRENT_USER, user: { ...currentUser, ...user } });
    }));
  };

  const questions = new Map([
    ['welcome', {
      content: <Welcome onYesAction={onYesAction} onSkipAction={onSkipAction} key="welcome" />,
      title: 'Welcome',
      hideNext: false,
      page: 1,
      hideHeader: true,
      hideFooter: true,
    }],
    ['books', {
      content: <YesNoCard key="books" item={likesBooks} onNoClick={onNoClick} onYesClick={onYesClick} />,
      title: 'Books - Yes/No?',
      hideNext: likesBooks.answer === null,
      page: 2,
      hideHeader: false,
      hideFooter: false,
    }],
    ['bookConsumptionTypes', {
      content: <MultipleSelect
        key="bookConsumptionTypes"
        options={booksConsumptionOptions}
        onSelect={onSelectAction}
        question="Select the one that applies to you"
      />,
      title: 'Book - Where?',
      hideNext: setupInterests.bookConsumptionTypes.length === 0,
      page: 3,
      hideHeader: false,
      hideFooter: false,
    }],
    ['bookGenres', {
      content: <List
        key="bookGenres"
        className={styles.postsList}
        grid={{ gutter: 8, column: 2 }}
        dataSource={books}
        header={<div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Select your favorite book genres</div>}
        renderItem={(item, index) => (
          <List.Item key={`${index}`}>
            <InterestCard
              item={item}
              onMovieGenreSelect={onBookGenreSelect}
            />
          </List.Item>
        )}
      />,
      hideNext: setupInterests.bookGenres.length === 0,
      title: 'Book genres',
      page: 4,
      hideHeader: false,
      hideFooter: false,
    }],
    ['bookShelf', {
      content: <List
        key="bookShelf"
        itemLayout="vertical"
        className={styles.mediumsList}
        grid={{ gutter: 0, column: 2 }}
        dataSource={_.uniqBy(booksBasedOnGenre.map((x:any) => x.books).flat(), 'title')}
        header={<div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Which books have you read or listened to?</div>}
        renderItem={(item, index) => (
          <List.Item style={{ display: 'flex', height: '224px', background: 'transparent' }} key={`crazy${index}`}>
            <InterestMediumCard2
              setFinal={setFinal}
              currentId={currentId}
              item={item}
              isLoading={isLoading}
              onBookSelect={onBookSelect}
            />
          </List.Item>
        )}
      />,
      title: 'Popular books',
      hideNext: false,
      page: 5,
      hideHeader: false,
      hideFooter: false,
    }],
    ['series', {
      content: <YesNoCard
        key="series"
        item={likesSeries}
        onNoClick={onNoClick}
        onYesClick={onYesClick}
      />,
      hideNext: likesSeries.answer === null,
      title: 'Series - Yes/No?',
      page: 6,
      hideHeader: false,
      hideFooter: false,
    }],
    ['seriesConsumptionTypes', {
      content: <MultipleSelect
        key="seriesConsumptionTypes"
        options={seriesConsumptionOptions}
        onSelect={onSeriesSelectAction}
        question="Where do you watch series?"
      />,
      title: 'Series - Where?',
      hideNext: setupInterests.seriesConsumptionTypes.length === 0,
      page: 7,
      hideHeader: false,
      hideFooter: false,
    }],
    ['seriesGenres', {
      content: <List
        key="seriesGenres"
        className={styles.postsList}
        grid={{ gutter: 8, column: 2 }}
        dataSource={series}
        header={<div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Select your favorite series genres</div>}
        renderItem={(item, index) => (
          <List.Item key={`${index}`}>
            <InterestCard
              item={item}
              onMovieGenreSelect={onSeriesGenreSelect}
            />
          </List.Item>
        )}
      />,
      hideNext: setupInterests.seriesGenres.length === 0,
      title: 'Series genres',
      page: 8,
      hideHeader: false,
      hideFooter: false,
    }],
    ['seriesShelf', {
      content: <List
        key="seriesShelf"
        itemLayout="vertical"
        className={styles.mediumsList}
        grid={{ gutter: 0, column: 2 }}
        dataSource={_.uniqBy(seriesBasedOnGenre.map((x:any) => x.series).flat(), 'title')}
        header={<div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Which series have you watched?</div>}
        renderItem={(item, index) => (
          <List.Item style={{ display: 'flex', height: '224px', background: 'transparent' }} key={`series${index}`}>
            <InterestMediumCard2
              item={item}
              currentId={currentId}
              isLoading={isLoading}
              onBookSelect={onSeriesSelect}
            />
          </List.Item>
        )}
      />,
      title: 'Popular series',
      hideNext: false,
      page: 9,
      hideHeader: false,
      hideFooter: false,
    }],
    ['movies', {
      content: <YesNoCard
        key="movies"
        item={likesMovies}
        onNoClick={onNoClick}
        onYesClick={onYesClick}
      />,
      hideNext: likesMovies.answer === null,
      title: 'Movies - Yes/No?',
      page: 10,
      hideHeader: false,
      hideFooter: false,
    }],
    ['moviesConsumptionTypes', {
      content: <MultipleSelect
        key="moviesConsumptionTypes"
        options={moviesConsumptionOptions}
        onSelect={onMoviesSelectAction}
      />,
      title: 'Movies - Where?',
      hideNext: setupInterests.movieConsumptionTypes.length === 0,
      page: 11,
      hideHeader: false,
      hideFooter: false,
    }],
    ['moviesGenres', {
      content: <List
        key="moviesGenres"
        className={styles.postsList}
        grid={{ gutter: 8, column: 2 }}
        dataSource={movies}
        header={<div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Select your favorite movie genres</div>}
        renderItem={(item, index) => (
          <List.Item key={`${index}`}>
            <InterestCard
              item={item}
              onMovieGenreSelect={onMovieGenreSelect}
            />
          </List.Item>
        )}
      />,
      hideNext: setupInterests.movieGenres.length === 0,
      title: 'Movie genres',
      page: 12,
      hideHeader: false,
      hideFooter: false,
    }],
    ['moviesShelf', {
      content: <List
        key="moviesShelf"
        itemLayout="vertical"
        className={styles.mediumsList}
        grid={{ gutter: 0, column: 2 }}
        dataSource={_.uniqBy(moviesBasedOnGenre.map((x:any) => x.movies).flat(), 'title')}
        header={<div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Which movies have you watched?</div>}
        renderItem={(item, index) => (
          <List.Item style={{ display: 'flex', height: '224px', background: 'transparent' }} key={`movies${index}`}>
            <InterestMediumCard2
              item={item}
              currentId={currentId}
              isLoading={isLoading}
              onBookSelect={onMoviesSelect}
            />
          </List.Item>
        )}
      />,
      title: 'Popular movies',
      hideNext: false,
      page: 13,
      hideHeader: false,
      hideFooter: false,
    }],
    ['podcasts', {
      content: <YesNoCard
        key="podcasts"
        item={likesPodcasts}
        onNoClick={onNoClick}
        onYesClick={onYesClick}
      />,
      hideNext: likesPodcasts.answer === null,
      title: 'Podcasts - Yes/No?',
      page: 14,
      hideHeader: false,
      hideFooter: false,
    }],
    ['podcastsConsumptionTypes', {
      content: <MultipleSelect
        key="podcastsConsumptionTypes"
        options={podcastsConsumptionOptions}
        onSelect={onPodcastsSelectAction}
      />,
      title: 'Podcasts - Where?',
      hideNext: setupInterests.podcastConsumptionTypes.length === 0,
      page: 15,
      hideHeader: false,
      hideFooter: false,
    }],
    ['podcastsGenres', {
      content: <List
        key="podcastsGenres"
        className={styles.postsList}
        grid={{ gutter: 8, column: 2 }}
        dataSource={podcasts}
        header={<div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Select your favorite podcast genres</div>}
        renderItem={(item, index) => (
          <List.Item key={`${index}`}>
            <InterestCard
              item={item}
              onMovieGenreSelect={onPodcastsGenreSelect}
            />
          </List.Item>
        )}
      />,
      hideNext: setupInterests.podcastGenres.length === 0,
      title: 'Podcast genres',
      page: 16,
      hideHeader: false,
      hideFooter: false,
    }],
    ['podcastsShelf', {
      content: <List
        key="podcastsShelf"
        itemLayout="vertical"
        className={styles.mediumsList}
        grid={{ gutter: 0, column: 2 }}
        dataSource={_.uniqBy(podcastsBasedOnGenre.map((x:any) => x.podcasts).flat(), 'title')}
        header={<div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>Which podcasts do you listen to?</div>}
        renderItem={(item, index) => (
          <List.Item style={{ display: 'flex', height: '224px', background: 'transparent' }} key={`podcasts${index}`}>
            <InterestMediumCard2
              item={item}
              currentId={currentId}
              isLoading={isLoading}
              onBookSelect={onPodcastsSelect}
            />
          </List.Item>
        )}
      />,
      title: 'Popular podcasts',
      hideNext: false,
      page: 17,
      hideHeader: false,
      hideFooter: false,
    }],
    ['done', {
      header: <div />,
      content: <DoneCard key="done" />,
      title: 'Done',
      hideNext: true,
      page: 18,
      hideHeader: false,
      hideFooter: true,
    }],
  ]);

  const onNextClick = () => {
    switch (activeQuestion) {
      case 'books':
        if (setupInterests.books) {
          setActiveQuestion('bookConsumptionTypes');
        } else {
          setActiveQuestion('series');
        }
        break;
      case 'bookConsumptionTypes':
        setActiveQuestion('bookGenres');
        break;
      case 'bookGenres':
                refContainer.current?.scrollTo(0, 0);
        setActiveQuestion('bookShelf');
        break;
      case 'bookShelf':
        setActiveQuestion('series');
        break;
      case 'series':
        if (setupInterests.series) {
          setActiveQuestion('seriesConsumptionTypes');
        } else {
          setActiveQuestion('movies');
        }
        break;
      case 'seriesConsumptionTypes':
        setActiveQuestion('seriesGenres');
        break;
      case 'seriesGenres':
                refContainer.current?.scrollTo(0, 0);
        setActiveQuestion('seriesShelf');
        break;
      case 'seriesShelf':
        setActiveQuestion('movies');
        break;
      case 'movies':
        if (setupInterests.movies) {
          setActiveQuestion('moviesConsumptionTypes');
        } else {
          setActiveQuestion('podcasts');
        }
        break;
      case 'moviesConsumptionTypes':
        setActiveQuestion('moviesGenres');
        break;
      case 'moviesGenres':
                refContainer.current?.scrollTo(0, 0);
        setActiveQuestion('moviesShelf');
        break;
      case 'moviesShelf':
        setActiveQuestion('podcasts');
        break;
      case 'podcasts':
        if (setupInterests.podcasts) {
          setActiveQuestion('podcastsConsumptionTypes');
        } else {
          onSaveAction();
        }
        break;
      case 'podcastsConsumptionTypes':
        setActiveQuestion('podcastsGenres');
        break;
      case 'podcastsGenres':
                refContainer.current?.scrollTo(0, 0);
        setActiveQuestion('podcastsShelf');
        break;
      case 'podcastsShelf':
        onSaveAction();
        break;
      case 'done':
        setActiveQuestion('done');
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      visible={currentUser && ((!currentUser?.setupInterests?.skip && !currentUser?.setupInterests?.complete) || isInterestModalVisible)}
      bodyStyle={{ padding: 0, height: !questions.get(activeQuestion)?.hideFooter ? '500px' : 'auto' }}
      footer={null}
      centered
      onCancel={onSkipAction}
      closable={false}
      className={styles.InterestModal}
    >
      <div className={styles.content}>
        {
                    !questions.get(activeQuestion)?.hideHeader
                    && (
                    <div className={styles.header}>
                      <div className={styles.progressContainer}>
                        <Progress
                          type="circle"
                          width={40}
                          strokeColor={{
                            '0%': '#2ECC71',
                            '100%': '#2ECC71',
                          }}
                          percent={(((questions.get(activeQuestion)?.page || 1) / Array.from(questions.keys()).length) * 100)}
                          format={() => `${Math.floor(((questions.get(activeQuestion)?.page || 1) / Array.from(questions.keys()).length) * 100)}%`}
                        />
                        <div className={styles.title}>
                          {questions.get(activeQuestion)?.title}
                        </div>
                      </div>
                    </div>
                    )
        }
        <div ref={refContainer} className={styles.interests}>
          {
            questions.get(activeQuestion)?.content
          }
        </div>
        {
                    !questions.get(activeQuestion)?.hideFooter
                    && (
                    <div className={styles.footer}>
                      <Button className={styles.skipButton} type="link" onClick={onSkipAction}>skip</Button>
                      <Button className={styles.nextButton} type="primary" disabled={questions.get(activeQuestion)?.hideNext} onClick={onNextClick}>Next</Button>
                    </div>
                    )
        }
      </div>
    </Modal>
  );
}

export default InterestModal;
