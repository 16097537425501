import React from 'react';
import { Button } from 'antd';
import styles from './podcasts-grid-card2.module.scss';
import {LazyLoadImage} from "react-lazy-load-image-component";

type PodcastsGridCardProps = {
  item: any;
  isEditing: boolean;
  onMediumClick: (medium: any) => void;
  tempOnRemoveMediumClick: (medium: any) => void;
};

function PodcastsGridCard2(props: PodcastsGridCardProps) {
  const {
    item, isEditing, onMediumClick, tempOnRemoveMediumClick,
  } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      key={item?._id}
      className={styles.PodcastsGridCard2}
      style={{ overflow: 'hidden' }}
      onClick={() => onMediumClick(item)}
    >
      <LazyLoadImage
          className={styles.image}
          src={item.mediumImage}
          alt={item.mediumImage}
          effect="opacity"
          placeholderSrc={require('../../../../assets/images/ph-rect.webp')}
      />
      {
        item.typeValue === 4
        && (
        <div className={styles.episodeTitle}>
          {item.mediumTitle}
        </div>
        )
      }
      {
        isEditing
        && <div className={styles.mediumBackground} />
      }
      {
        isEditing
        && (
        <div className={styles.removeContainer}>
          <Button
            className={styles.removeButton}
            onClick={() => tempOnRemoveMediumClick(item)}
          >
            Remove
          </Button>
        </div>
        )
      }
    </div>
  );
}

export default PodcastsGridCard2;
