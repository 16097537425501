import { Dispatch } from 'redux';
import axios from 'axios';
import { Post } from '../models/Post';
/* eslint-disable @typescript-eslint/no-unused-vars */
export const like = (username: string, post: Post, typeText: string, callBack: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/reactions/like', {
    postId: post._id, postUser: post.user._id, username, typeText,
  })
    .then((response) => {
      console.log('response.home: ', response);
      callBack();
    }).catch((error) => {
      console.log('error: ', error);
    });
};

export const unlike = (username: string, post: Post, callBack: Function) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/reactions/unlike', { postId: post._id, username })
    .then((response) => {
      console.log('response.home: ', response);
      callBack();
    }).catch((error) => {
      console.log('error: ', error);
    });
};
