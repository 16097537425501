import React from 'react';
import { List } from 'antd';
import { useHistory } from 'react-router-dom';
import styles from './PostCard.module.scss';
import { Post } from '../../../models/Post';
import PostHeader from '../PostHeader/PostHeader';
import PostSource from '../PostSource/PostSource';
import PostDivider from '../PostDivider/PostDivider';
import PostFooter from '../PostFooter/PostFooter';
import {LazyLoadImage} from "react-lazy-load-image-component";

interface PostCard2Props {
  post: Post;
  likePost: Function;
  unlikePost: Function;
  reactionIds: any;
  shelfIds: { [key: string]: string }
}

function PostCard(props: PostCard2Props) {
  const {
    reactionIds, post, likePost, unlikePost, shelfIds,
  } = props;
  const {
    user, image, medium, createdAt, title,
  } = post;
  const history = useHistory();

  const onDoubleClick = () => {
    // likeOrUnlikeAction(typeText: string);
  };

  // const onAddToShelf = () => {
  //   console.log('onAddToShelf: ');
  // };
  //
  // const onAddToWishListAction = () => {
  //   console.log('onAddToWishListAction: ');
  // };
  //
  //   const menu = (
  //       <Menu onClick={handleMenuClick}>
  //           <Menu.Item key="1">Add to WishList</Menu.Item>
  //           <Menu.Item key="2">Add to Shelf</Menu.Item>
  //       </Menu>
  //   );
  //
  // const handleMenuClick = () => {
  //
  // };

  const onMediumClick = () => {
    history.push(`/mediums/${post.medium.external.id}/${post.medium.typeValue}`);
  };

  return (
    <List.Item className={styles.PostCard}>
      <PostHeader user={user} post={post} createdAt={createdAt} />
      <div className={styles.title}>
        {title}
      </div>
        <LazyLoadImage
            effect="blur"
            className={styles.body}
            src={image?.replace('http://', 'https://')}
            alt={image?.replace('http://', 'https://')}
            onDoubleClick={onDoubleClick}
        />
      <PostSource medium={medium} shelfIds={shelfIds} onMediumClick={onMediumClick} />
      <PostDivider padding="12px" />
      <PostFooter
        createdAt={createdAt}
        post={post}
        reactionIds={reactionIds}
        likePost={likePost}
        unlikePost={unlikePost}
      />
    </List.Item>
  );
}

export default PostCard;
