import React, { useEffect, useState } from 'react';
import {
  Button, Input, Modal, Skeleton, Tabs, Tag, Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory, useParams, useLocation, useRouteMatch,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import { ArrowLeftOutlined, PlusOutlined, EllipsisOutlined } from '@ant-design/icons';
import { User } from '../../models/User';
import { StoreState } from '../../reducers/_RootReducers';
import styles from './club-story-screen.module.scss';
import { ActionTypes } from '../../actions/types';
import { Note } from '../../models/Note';
import TopicTab from '../club-discussions-screen/topic-tab/topic-tab';
import ActivityTab from './activity-tab/activity-tab';
import SectionsTab from './sections-tab/sections-tab';
import MyNotesModal from './my-notes-modal/my-notes-modal';
import PreviewNoteModal from "../../components/preview-note-modal/preview-note-modal";

const { Paragraph } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;
function ClubStoryScreen() {
  const { id, storiesId } = useParams<{ id: string, storiesId: string }>();
  const useLoc = useLocation();
  const useRoute = useRouteMatch();
  const activeStoryTab = useSelector<StoreState, string>((state) => state.activeStoryTab);
  const currentUser = useSelector<StoreState, User | null>((state) => state.currentUser);
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading: isTopicLoading, error: topicError, data: currentTopic } = useQuery(`story-${storiesId}`, () => axios.get(`/api/clubs/topics/${storiesId}`)
    .then((response) => response.data));

  useEffect(() => () => {
    console.log('wow - useLoc: ', useLoc);
    console.log('wow - useRoute: ', useRoute);
  }, []);

  const onBackClick = () => {
    history.push(`/storyclubs/${id}/stories`);
  };

  const onNotesCardClick = (note: Note) => {
    dispatch({ type: ActionTypes.SET_CURRENT_PREVIEW_NOTE, value: note });
    dispatch({ type: ActionTypes.SET_MY_NOTES_VISIBILITY, value: false });
  };

  const onTabChange = (currentTab: string) => {
    dispatch({ type: ActionTypes.SET_CLUB_STORY_ACTIVE_TAB, value: currentTab });
  };

  const onSearch = (data: string) => {
    setSearchQuery(data);
  };

  const onEditClick = () => {
    history.push(`/storyclubs/${id}/stories/${storiesId}/edit`);
  };

  return (currentUser
        && (
        <div className={styles.ClubStoryScreen}>
          <MyNotesModal
            onNotesCardClick={onNotesCardClick}
          />
          <PreviewNoteModal/>
          <div className={styles.headerContainer}>
            <div className={styles.headerWhiteboard}>
              <div className={styles.messageHeader}>
                <div className={styles.topHeader}>
                  <Button
                    className={styles.goBack}
                    icon={<ArrowLeftOutlined />}
                    type="link"
                    onClick={onBackClick}
                  />
                  {
                    currentTopic
                      ? (
                        <Paragraph ellipsis={{ rows: 2, expandable: false }} className={styles.titleHeader}>
                          {currentTopic?.name}
                        </Paragraph>
                      )
                      : (
                        <div className={styles.titleHeader}>
                          <Skeleton.Input style={{ width: 200 }} active size="large" />
                        </div>
                      )
                  }
                  <div className={styles.create}>
                    <Button
                      shape="circle"
                      type="primary"
                      onClick={onEditClick}
                      icon={<EllipsisOutlined />}
                    />
                  </div>
                </div>
                <div className={styles.searchContainer}>
                  <Search
                    className={styles.searchInput}
                    placeholder="Search for stories"
                    allowClear
                    size="large"
                    onSearch={onSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <Tabs
            tabBarGutter={0}
            className={styles.tabs}
            defaultActiveKey={activeStoryTab}
            onChange={onTabChange}
          >
            <TabPane
              tab={<TopicTab key="activity" name="Recent Activity" />}
              key="activity"
            >
              <ActivityTab
                id={id}
                storiesId={storiesId}
              />
            </TabPane>
            <TabPane
              tab={<TopicTab key="sections" name="Sections" />}
              key="sections"
            >
              <SectionsTab />
            </TabPane>
          </Tabs>
        </div>
        )
  );
}

export default ClubStoryScreen;
