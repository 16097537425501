import { ActionTypes } from '../actions/types';
import { AddPost } from '../models/AddPost';
import { CreateWilt } from '../models/CreateWilt';
import { CreateSummary } from '../models/CreateSummary';
import { CreateQuote } from '../models/create-models/CreateQuote';
import {ClassicTemplate, classicTemplatePreview} from '../helpers/templates/ClassicTemplate';
import { WiltItem } from '../models/WiltItem';
import { CreateTemplate } from '../models/create-models/CreateTemplate';
import { CreateQuiz } from '../models/create-models/CreateQuiz';
import {CreateList} from "../models/create-models/create-list";

interface updateAddPost {
  type: typeof ActionTypes.UPDATE_ADD_POST;
  addPost: AddPost;
}

interface updateAddPostThought {
  type: typeof ActionTypes.UPDATE_ADD_POST_THOUGHT;
  thought: string;
}

interface updateCreateWilt {
  type: typeof ActionTypes.UPDATE_CREATE_WILT;
  value: CreateWilt;
}

interface updateCreateSummary {
  type: typeof ActionTypes.UPDATE_CREATE_SUMMARY;
  value: CreateSummary;
}

interface setCreateQuote {
  type: typeof ActionTypes.SET_CREATE_QUOTE;
  value: CreateQuote;
}

interface setCreateTemplate {
  type: typeof ActionTypes.SET_CREATE_TEMPLATE;
  value: CreateTemplate;
}

interface setCreateQuiz {
  type: typeof ActionTypes.SET_CREATE_QUIZ;
  value: CreateQuiz;
}

interface setQuizCounter {
  type: typeof ActionTypes.SET_CREATE_QUIZ_COUNTER;
  value: number;
}

interface setQuizAnswersCounter {
  type: typeof ActionTypes.SET_CREATE_QUIZ_ANSWERS_COUNTER;
  value: { [key: string]: number };
}

interface setCreateList {
  type: typeof ActionTypes.SET_CREATE_LIST;
  value: CreateList;
}

type createActions = updateAddPost | updateAddPostThought | updateCreateWilt
| updateCreateSummary | setCreateQuote | setCreateTemplate | setCreateQuiz | setQuizCounter
| setQuizAnswersCounter | setCreateList;

const defaultAddPost: AddPost = {
  backgroundColor: '#b47efe',
  backgroundColorRGB: '180,126,254',
  backgroundOpacity: 0.8,
  textColor: '#000000',
  textAlign: 'left',
  fontSize: 20,
  text: 'The greatest stories are shared by everyone',
  author: 'author',
  title: 'mediumTitle',
  medium: '',
  thought: '',
};

export const addPost = (state = defaultAddPost, action: createActions) => {
  if (action.type === ActionTypes.UPDATE_ADD_POST) {
    return action.addPost;
  }
  return state;
};

export const thought = (state = '', action: createActions) => {
  if (action.type === ActionTypes.UPDATE_ADD_POST_THOUGHT) {
    return action.thought;
  }
  return state;
};

export const defaultCreateWilt: CreateWilt = {
  mediumTitle: 'author',
  mediumAuthor: 'mediumTitle',
  mediumImage: '',
  mediumId: '',
  title: 'Enter title here...',
  wiltItems: [{
    id: '0', title: 'Enter title...', body: 'Enter body...', editing: false,
  }],
};

const defaultCreateSummary: CreateSummary = {
  mediumTitle: 'author',
  mediumAuthor: 'mediumTitle',
  mediumImage: '',
  mediumId: '',
  title: 'Enter title here...',
  summaryItems: [{ id: '0', text: 'Start typing summary here...' }],
};

const wiltItemsDefault:WiltItem[] = [{
  id: '0', title: 'Enter title...', body: undefined, editing: false,
}];

export const defaultCreateQuote: CreateQuote = {
  currentPage: 1,
  totalPages: 4,
  text: 'Click here to edit this quote',
  type: 'unknown',
  template: ClassicTemplate,
  wiltTitle: 'Enter title here',
  wiltItems: wiltItemsDefault,
  wiltIdCounter: 1,
  summaryTitle: 'Enter title here...',
  summaryItems: [{ id: '0', text: 'Start typing summary here...' }],
  summaryText: 'Enter text here...',
  summaryIdCounter: 1,
};

export const defaultCreateTemplate: CreateTemplate = {
  currentPage: 1,
  totalPages: 4,
  title: '',
  length: 1,
  type: 'unknown',
  version: 1,
  year: 2020,
  isAudio: false,
  privacy: 'public',
  language: 'English',
  sections: [],
};

export const createWilt = (state = defaultCreateWilt, action: createActions) => {
  if (action.type === ActionTypes.UPDATE_CREATE_WILT) {
    return action.value;
  }
  return state;
};

export const createSummary = (state = defaultCreateSummary, action: createActions) => {
  if (action.type === ActionTypes.UPDATE_CREATE_SUMMARY) {
    return action.value;
  }
  return state;
};

export const createQuote = (state = defaultCreateQuote, action: createActions) => {
  if (action.type === ActionTypes.SET_CREATE_QUOTE) {
    return { ...action.value };
  }
  return state;
};

export const createTemplate = (state = defaultCreateTemplate, action: createActions) => {
  if (action.type === ActionTypes.SET_CREATE_TEMPLATE) {
    return { ...action.value };
  }
  return state;
};

export const defaultCreateQuiz: CreateQuiz = {
  currentPage: 1,
  totalPages: 4,
  title: '',
  visibility: 'public',
  questions: [],
};

export const createQuiz = (state: CreateQuiz = defaultCreateQuiz, action: createActions) => {
  if (action.type === ActionTypes.SET_CREATE_QUIZ) {
    return { ...action.value };
  }
  return state;
};

export const createQuizCounter = (state: number = 1, action: createActions) => {
  if (action.type === ActionTypes.SET_CREATE_QUIZ_COUNTER) {
    return action.value;
  }
  return state;
};

export const createQuizAnswersCounter = (state: { [key: string]: number } = {}, action: createActions) => {
  if (action.type === ActionTypes.SET_CREATE_QUIZ_ANSWERS_COUNTER) {
    return action.value;
  }
  return state;
};

export const defaultCreateList: CreateList = {
  currentPage: 1,
  totalPages: 3,
  visibility: 'public',
  values: [],
  stories: [],
  text: '',
  type: 'list',
  template: ClassicTemplate,
};

export const createList = (state: CreateList = defaultCreateList, action: createActions) => {
  if (action.type === ActionTypes.SET_CREATE_LIST) {
    return { ...action.value };
  }
  return state;
};