import React from 'react';
import styles from './SettingsModal.module.scss';
import {Button, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers/_RootReducers";
import {ActionTypes} from "../../../actions/types";
import cookies from "js-cookie";

function SettingsModal(props:any) {

    const isSettingsVisible = useSelector<StoreState, boolean>(state => state.isSettingsVisible);
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch({ type: ActionTypes.TOGGLE_SETTINGS_MODAL });
    };

    const onLogoutAction = () => {
        cookies.remove('goodlores-token');
        window.location.reload();
    };

    const onCancelAction = () => {
        dispatch({ type: ActionTypes.TOGGLE_SETTINGS_MODAL });
    };

    return (
        <Modal
            className={styles.SettingsModal}
            visible={isSettingsVisible}
            onCancel={handleCancel}
            centered={true}
            title={<div className={styles.title}>Settings</div>}
            footer={null}
        >
            <div className={styles.modalBody}>
                <div>
                    <Button size="large"
                            type="link"
                            className={styles.logout}
                            onClick={onLogoutAction}>
                        Logout
                    </Button>
                </div>
                <div>
                    <Button
                        size="large"
                        type="link"
                        className={styles.cancel}
                        onClick={onCancelAction}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default SettingsModal;
