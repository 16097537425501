import React, { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './DraftScreen.module.scss';
import { Post } from '../../models/Post';
import { ActionTypes } from '../../actions/types';
import { deleteDraft } from '../../services-api/createPostService';
import { StoreState } from '../../reducers/_RootReducers';
import { CreateQuote } from '../../models/create-models/CreateQuote';
import DraftsGrid from './drafts-grid/drafts-grid';

const { confirm, error } = Modal;
function DraftsScreen() {
  const [isEditing, setIsEditing] = useState(false);
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);
  const history = useHistory();
  const dispatch = useDispatch();

  const onEditingClick = () => {
    setIsEditing(!isEditing);
  };

  const onPrivatePostClick = (post: any) => {
    if (!isEditing) {
      if (post.typeValue === 0) {
        const newCreateQuote = {
          ...post.quote, image: post.image, draftId: post._id, currentPage: 4,
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
        history.push('/create/quote');
      } else if (post.typeValue === 1) {
        const newCreateQuote: CreateQuote = {
          ...createQuote,
          currentPage: 3,
          totalPages: 3,
          medium: post.medium,
          wiltTitle: post.title,
          wiltItems: post.wilt,
          wiltIdCounter: post.wilt.length,
          wiltId: post._id,
          type: 'wilt',
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
        history.push('/create/wilt');
      } else if (post.typeValue === 2) {
        const newCreateQuote: CreateQuote = {
          ...createQuote,
          currentPage: 3,
          totalPages: 3,
          medium: post.medium,
          summaryTitle: post.title,
          summaryText: post.summary,
          summaryIdCounter: post.summary.length,
          summaryId: post._id,
          type: 'summary',
        };
        dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
        history.push('/create/summary');
      }
    }
  };

  const onRemovePostClick = (post: Post, callback: Function) => {
    confirm({
      title: 'Remove item?',
      content: 'Are you sure you want to remove this item?',
      onOk() {
        return new Promise((resolve, reject) => {
          dispatch(deleteDraft(post._id, (success:boolean) => {
            if (success) {
              callback(post._id);
              resolve('');
            } else {
              error({
                title: 'Error',
                content: 'An error has occurred. Please try again later',
                onOk() {
                  reject();
                },
              });
            }
          }));
        }).catch(() => console.log('Oops errors! :'));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <div className={styles.DraftsScreen}>
      <div className={styles.container}>
        <DraftsGrid
          isEditing={isEditing}
          isCurrentUser
          onEditingClick={onEditingClick}
          onRemovePostClick={onRemovePostClick}
          onPostClick={onPrivatePostClick}
        />
      </div>
    </div>
  );
}

export default DraftsScreen;
