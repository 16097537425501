import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Spin } from 'antd';
import {
  LeftOutlined, RightOutlined, UploadOutlined, SaveOutlined,
} from '@ant-design/icons';
import styles from './CreateWiltScreen.module.scss';
import CreateProgress from '../../components/CreateProgress/CreateProgress';
import MyMediumList from '../../components/MyMediumList/MyMediumList';
import { Medium } from '../../models/Medium';
import { ActionTypes } from '../../actions/types';
import { StoreState } from '../../reducers/_RootReducers';
import { CreateQuote } from '../../models/create-models/CreateQuote';
import WiltTimeline from './WiltTimeline/WiltTimeline';
import WiltTimelinePreview from './WiltTimelinePreview/WiltTimelinePreview';
import { createDraftWilt, createWilt, updateDraftWilt } from '../../services-api/createPostService';

function CreateWiltScreen() {
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const onMyMediumClick = (medium: Medium) => {
    if (createQuote.currentPage < pages.length) {
      const newCreateQuote = {
        ...createQuote, medium, currentPage: createQuote.currentPage + 1, totalPages: pages.length, type: 'wilt',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  const pages = [
    {
      page: 1,
      showNavigation: false,
      title: 'select a story',
      content: <MyMediumList onMyMediumClick={onMyMediumClick} />,
    },
    {
      page: 2,
      showNavigation: true,
      title: 'create wilt',
      content: <WiltTimeline />,
    },
    {
      page: 3,
      showNavigation: true,
      title: 'preview',
      content: <WiltTimelinePreview />,
    },
  ];

  const onPrevClick = () => {
    if (createQuote.currentPage > 1) {
      const newCreateQuote = {
        ...createQuote, currentPage: createQuote.currentPage - 1, totalPages: pages.length, type: 'wilt',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  const onSaveAsDraft = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    if (createQuote.wiltId) {
      dispatch(updateDraftWilt(createQuote, () => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        history.replace('/drafts');
        dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
        dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
      }));
    } else {
      dispatch(createDraftWilt(createQuote, () => {
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
        history.replace('/drafts');
        dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
        dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
      }));
    }
  };

  const onPostClick = () => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
    dispatch(createWilt(createQuote, () => {
      dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
      dispatch({ type: ActionTypes.SET_POSTS, posts: [] });
      history.replace('/');
      dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
      dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
    }));
  };

  const onNextClick = () => {
    if (createQuote.currentPage < pages.length) {
      const newCreateQuote = {
        ...createQuote, currentPage: createQuote.currentPage + 1, totalPages: pages.length, type: 'wilt',
      };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  return (
    <div className={styles.CreateWiltScreen}>
      <Spin spinning={isLoading}>
        <div
          className={styles.container}
        >
          <CreateProgress existingId={createQuote.wiltId} current={createQuote.currentPage} pages={pages} />
          <div>
            {
                            pages[createQuote.currentPage - 1].content
                        }
          </div>
          {
                        pages[createQuote.currentPage - 1].showNavigation
                        && (
                        <div>
                          {
                                createQuote.currentPage === createQuote.totalPages
                                  ? (
                                    <div className={styles.actions}>
                                      <Button className={styles.navigationBtn} size="large" onClick={onPrevClick}>
                                        <LeftOutlined type="left" />
                                        back
                                      </Button>
                                      <div>
                                        <Button className={styles.saveAsDraft} size="large" type="primary" ghost onClick={onSaveAsDraft}>
                                          save draft
                                          <SaveOutlined type="save" />
                                        </Button>
                                        <Button className={styles.postButton} size="large" type="primary" onClick={onPostClick}>
                                          POST
                                          <UploadOutlined type="upload" />
                                        </Button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className={styles.actions}>
                                      <Button className={styles.navigationBtn} size="large" onClick={onPrevClick}>
                                        <LeftOutlined type="left" />
                                        back
                                      </Button>
                                      <Button className={styles.nextButton} size="large" type="primary" onClick={onNextClick}>
                                        next
                                        <RightOutlined type="right" />
                                      </Button>
                                    </div>
                                  )
}
                        </div>
                        )
                    }
        </div>
      </Spin>
    </div>
  );
}

export default CreateWiltScreen;
