import React, {useState} from 'react';
import styles from "../../screens/club-story-screen/club-story-screen.module.scss";
import {Button, Modal, Skeleton, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers/_RootReducers";
import {Note} from "../../models/Note";
import {ActionTypes} from "../../actions/types";

type PreviewNoteModalProps = {

}

function PreviewNoteModal(props: PreviewNoteModalProps) {
    const [isAnswerVisible, setIsAnswerVisible] = useState(false);
    const currentDisplayNote = useSelector<StoreState, Note | null>((state) => state.currentDisplayNote);
    const dispatch = useDispatch();

    const renderCorrectNote = (type: string) => {
        switch (type) {
            case 'TEXT':
                return <div className={styles.text}>{currentDisplayNote?.text}</div>;
            case 'QUOTE_THOUGHT':
                return (
                    <div className={styles.quoteThoughtContainer}>
                        <div className={styles.quoteContainer}>
                            <div className={styles.title}>quote</div>
                            <div className={styles.body}>{currentDisplayNote?.quoteText}</div>
                        </div>
                        <div className={styles.quoteContainer}>
                            <div className={styles.title}>thought</div>
                            <div className={styles.body}>{currentDisplayNote?.thoughtText}</div>
                        </div>
                    </div>
                );
            case 'QUESTION_ANSWER':
                return (
                    <div className={styles.questionAnswerContainer}>
                        <div className={styles.questionContainer}>
                            <div className={styles.title}>question</div>
                            <div className={styles.body}>{currentDisplayNote?.questionText}</div>
                        </div>
                        {
                            isAnswerVisible
                                ? (
                                    <div className={styles.answerContainer}>
                                        <div className={styles.title}>answer</div>
                                        <div className={styles.body}>{currentDisplayNote?.answerText}</div>
                                    </div>
                                ) : (
                                    <div className={styles.answerContainer}>
                                        <Button
                                            className={styles.showAnswer}
                                            type="primary"
                                            size="large"
                                            onClick={() => setIsAnswerVisible(true)}
                                        >
                                            show answer
                                        </Button>
                                    </div>
                                )
                        }
                    </div>
                );
            default:
                return <div />;
        }
    };

    const onDisplayNoteCancel = () => {
        dispatch({ type: ActionTypes.SET_DISPLAY_NOTE, value: null });
    };

    return (
        <Modal
            title={null}
            style={{ overflow: 'hidden' }}
            bodyStyle={{ padding: '0px' }}
            visible={currentDisplayNote !== null}
            closable={false}
            onCancel={onDisplayNoteCancel}
            centered
            footer={null}
        >
            <div className={styles.displayNote}>
                <div className={styles.container}>
                    {
                        currentDisplayNote?.type
                            ? (
                                <div className={styles.header}>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title}>
                                            {currentDisplayNote?.title}
                                        </div>
                                    </div>
                                    <div className={styles.subtitle}>
                                        {
                                            currentDisplayNote?.locationType === 'PAGE' && (
                                                <div className={styles.location}>
                                                    Page No.
                                                    {' '}
                                                    {currentDisplayNote?.location}
                                                </div>
                                            )
                                        }
                                        {
                                            currentDisplayNote?.locationType === 'TIME' && (
                                                <div className={styles.location}>
                                                    {currentDisplayNote?.start.minutes}
                                                    min
                                                    {' '}
                                                    {currentDisplayNote?.start.seconds}
                                                    s
                                                    {' - '}
                                                    {currentDisplayNote?.end.minutes}
                                                    min
                                                    {' '}
                                                    {currentDisplayNote?.end.seconds}
                                                    s
                                                </div>
                                            )
                                        }
                                        <div className={styles.categories}>
                                            {
                                                currentDisplayNote?.noteCategories.map((val) => <Tag color="#108ee9" className={styles.category}>{val.uniqueName}</Tag>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                            : (
                                <div className={styles.loadingHeader}>
                                    <Skeleton.Input className={styles.loadingItem} style={{ width: '100%' }} active size="large" />
                                </div>
                            )
                    }

                    <div className={styles.messagesContainer}>
                        {
                            currentDisplayNote?.type ? renderCorrectNote(currentDisplayNote?.type)
                                : (
                                    <Skeleton
                                        className={styles.loadingContent}
                                        active
                                        paragraph={{ rows: 8 }}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default PreviewNoteModal;