import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import styles from './preview-template.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';
import { CreateTemplate } from '../../../models/create-models/CreateTemplate';

function PreviewTemplate() {
  const createTemplate = useSelector<StoreState, CreateTemplate>((state) => state.createTemplate);

  return (
    <div className={styles.PreviewTemplate}>
      <div className={styles.templateTitle}>
        {createTemplate.title}
      </div>
      <div className={styles.templateInfo}>
        <div className={styles.infoContainer}>
          <div className={styles.infoTitle}>
            Language
          </div>
          <div className={styles.infoValue}>
            {createTemplate.language}
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.infoTitle}>
            Privacy
          </div>
          <div className={styles.infoValue}>
            {createTemplate.privacy}
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.infoTitle}>
            Year
          </div>
          <div className={styles.infoValue}>
            {createTemplate.year}
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.infoTitle}>
            Version
          </div>
          <div className={styles.infoValue}>
            {createTemplate.version}
          </div>
        </div>
      </div>
      <div className={styles.chapters}>
        {
          createTemplate.sections.map((section, index) => (
            <div className={styles.chapter}>
              <div className={styles.info}>
                <div className={styles.number}>
                  {index + 1}
                  .
                </div>
                <div className={styles.text}>
                  {section.text}
                </div>
                <div className={styles.location}>
                  {
                    createTemplate?.isAudio
                      ? (section.time.minute === -1 || section.time.second === -1) ? '' : `${section.time.minute}min ${section.time.second}s`
                      : section.page === -1 ? '' : `${createTemplate?.medium?.typeValue === 0 ? "page": "min"} ${section.page}`
                  }
                </div>
              </div>
              <div className={styles.subChapterSection}>
                {
                    section.subChapters.map((subSection, subIndex) => (
                      <div key={`subsection-${index}-${subIndex}`} className={styles.subChapter}>
                        <div className={styles.subInfo}>
                          <div className={styles.subNumber}>
                            {index + 1}
                            .
                            {subIndex + 1}
                            .
                          </div>
                          <div className={styles.subChapterInput}>
                            {subSection.text}
                          </div>
                          <div className={styles.location}>
                            {
                              createTemplate?.isAudio
                                ? (subSection.time.minute === -1 || subSection.time.second === -1) ? '' : `${subSection.time.minute}min ${subSection.time.second}s`
                                : subSection.page === -1 ? '' : `${createTemplate?.medium?.typeValue === 0 ? "page": "min"} ${subSection.page}`
                            }
                          </div>
                        </div>
                      </div>
                    ))
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default PreviewTemplate;
