import React from 'react';
import { Button, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TextModal.module.scss';
import { StoreState } from '../../../reducers/_RootReducers';
import { ActionTypes } from '../../../actions/types';
import { CreateQuote } from '../../../models/create-models/CreateQuote';

const { TextArea } = Input;
function TextModal() {
  const isTextModalVisible = useSelector<StoreState, boolean>((state) => state.isTextModalVisible);
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch({ type: ActionTypes.TOGGLE_TEXT_MODAL });
  };

  const onTextChange = (data: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newCreateQuote = { ...createQuote, text: data.target.value };
    dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
  };

  return (
    <Modal
      className={styles.TextModal}
      visible={isTextModalVisible}
      onCancel={handleCancel}
      bodyStyle={{ padding: '24px', paddingTop: '36px', height: '200px' }}
      closable={false}
      centered
      title={null}
      footer={null}
    >
      <div className={styles.textModalBody}>
        <div className={styles.textModalContainer}>
          <TextArea
            className={styles.textArea}
            defaultValue={createQuote.text}
            onChange={onTextChange}
            autoSize={{ minRows: 1, maxRows: 2 }}
            allowClear
          />
        </div>
        <div className={styles.actions}>
          <Button className={styles.saveButton} type="primary" onClick={handleCancel}>Save</Button>
        </div>
      </div>
    </Modal>
  );
}

export default TextModal;
