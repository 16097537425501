import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './GoBack.module.scss';

interface GoBackProps {
  url?: string;
  maxWidth?: string;
}

function GoBack(props:GoBackProps) {
  const { maxWidth } = props;
  const history = useHistory();

  const goBack = () => {
    if (props.url) {
      history.replace(props.url);
    } else {
      history.goBack();
    }
  };

  return (
    <div className={styles.GoBack} style={{ maxWidth: maxWidth || '800px' }}>
      <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
        back
      </Button>
    </div>
  );
}

export default GoBack;
