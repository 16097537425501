import React from "react";
import {Typography} from "antd";
import hexRgb from "hex-rgb";
const { Text } = Typography;

export const highlightHashTags1 = (text:string, hashTagsColor: string, hashTagsAlpha:number, fontSize: number) => {
    const textSplit = text.split(" ");
    let newTextTags = [];
    for (const tempText of textSplit) {
        const background = `rgba(${hashTagsColor}, ${hashTagsAlpha})`;
        if (tempText.replace(/\n/g,"").split("")[0] === "#"){
            newTextTags.push( (<Text key={tempText} style={{ fontSize: fontSize + 2, background, whiteSpace: 'pre-line' }} >{tempText.replace('#', '')}</Text>) );
        } else {
            newTextTags.push((<Text style={{ whiteSpace: 'pre-line' }} >{tempText} </Text>));
        }
        newTextTags.push((<Text style={{ whiteSpace: 'pre-line' }} >{' '}</Text>));
    }
    console.log("newTextTags: ",newTextTags);
    return newTextTags;
};

interface TextItem {

}

export const highlightHashTags = (text:string, highlights: any) => {
    const { color, alpha, fontSize, fontWeight } = highlights;
    const textSplit = text.split(" ");
    let newTextTags:any[] = [];
    for (const tempText of textSplit) {
        if (tempText.replace(/\n/g,"").split("")[0] === "#"){
            newTextTags.push( (
                <span
                    key={tempText}
                    style={{ display: 'flex', alignItems: 'center', fontSize: fontSize, color: hexRgb(color, { format: 'css', alpha }), fontWeight: fontWeight }}
                >
                    {tempText.replace('#', '')}&#160;
                </span>) );
        } else {
            newTextTags.push((<span style={{ display: 'flex', alignItems: 'center'}} >{tempText}&#160;</span>));
        }
    }

    return newTextTags;
};
