import React, { useEffect, useState } from 'react';
import { Button, Input, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  UsergroupAddOutlined, ArrowRightOutlined, ArrowLeftOutlined,
} from '@ant-design/icons';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './new-chat-screen.module.scss';
import FriendUserCardSkeleton from './FriendUserCardSkeleton/FriendUserCardSkeleton';
import FriendUserCard from './FriendUserCard/FriendUserCard';

const { Search } = Input;
function NewChatScreen() {
  const history = useHistory();
  const [isAtTheEnd, setIsAtTheEnd] = useState(false);
  const [timeStamp] = useState(new Date());
  const [limit] = useState(24);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    data,
    isFetching,
    fetchMore,
  } = useInfiniteQuery(
    `new-chat-screen-${searchQuery}`,
    async (key, nextId = 0) => {
      const { data } = await axios.get(`/api/users/friends?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
      if (data?.users?.length < limit) {
        setIsAtTheEnd(true);
      }
      return data;
    },
    {
      getFetchMore: (lastGroup) => lastGroup.nextId,
    },
  );

  const onCreateGroupClick = () => {
    history.push('/newgroupchatusers');
  };

  useEffect(() => {
  }, [data]);

  const onBackClick = () => {
    history.goBack();
  };

  const onSearch = (data: string) => {
    setSearchQuery(data);
  };

  return (
    <div className={styles.NewChatScreen}>
      <div className={styles.headerContainer}>
        <div className={styles.headerWhiteboard}>
          <div className={styles.messageHeader}>
            <div className={styles.topHeader}>
              <Button
                className={styles.goBack}
                size="large"
                icon={<ArrowLeftOutlined />}
                shape="circle"
                onClick={onBackClick}
              />
              <div className={styles.titleHeader}>
                New Chat
              </div>
            </div>
            <div className={styles.searchContainer}>
              <Search
                className={styles.searchInput}
                placeholder="Search for people"
                allowClear
                size="large"
                onSearch={onSearch}
              />
            </div>
          </div>
          <div className={styles.createChatGroup} onClick={onCreateGroupClick}>
            <div className={styles.leftContent}>
              <UsergroupAddOutlined className={styles.createIcon} />
              <div className={styles.createTitle}>
                Create Group
              </div>
            </div>
            <div className={styles.rightContent}>
              <ArrowRightOutlined className={styles.rightArrow} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {
          isFetching && !data
            ? (
              [1, 1, 1, 1].map((item, index) => <FriendUserCardSkeleton key={`${index}`} />)
            ) : (
              <InfiniteScroll
                dataLength={data?.map((page) => page.users).flat().length || 0}
                next={() => fetchMore()}
                hasMore={(data?.map((page) => page.users).flat().length || 0) > 0 && !isAtTheEnd}
                loader={(
                  <div className={styles.spinningContainer}>
                    <Spin />
                  </div>
                      )}
                endMessage={(
                  <p style={{ textAlign: 'center' }} />
                      )}
              >
                <div className={styles.container}>
                  {
                        data?.map((page) => page.users).flat().map((item, index) => (
                          <FriendUserCard
                            key={`FriendCard${index}`}
                            user={item}
                          />
                        ))
                  }
                </div>
              </InfiniteScroll>
            )
        }
      </div>
    </div>
  );
}

export default NewChatScreen;
