import React from 'react';
import { Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import ReactQuill, { Range, UnprivilegedEditor } from 'react-quill';
import { DeltaStatic, Sources } from 'quill';
import { ActionTypes } from '../../../actions/types';
import { CreateQuote } from '../../../models/create-models/CreateQuote';
import { StoreState } from '../../../reducers/_RootReducers';
import { WiltItem } from '../../../models/WiltItem';
import styles from './WiltTimeline.module.scss';

function WiltTimeline() {
  const createQuote = useSelector<StoreState, CreateQuote>((state) => state.createQuote);
  const dispatch = useDispatch();

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCreateQuote = { ...createQuote, wiltTitle: event.target.value };
    dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
  };

  const onAddClick = () => {
    const newItem = {
      id: `${createQuote.wiltIdCounter + 1}`,
      title: 'Enter title...',
      body: undefined,
      editing: false,
    };
    if (createQuote.wiltItems && createQuote.wiltIdCounter) {
      const newCreateQuote = { ...createQuote, wiltIdCounter: createQuote.wiltIdCounter + 1, wiltItems: [...createQuote.wiltItems, newItem] };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  const onFocus = (selection: Range, source: Sources, editor: UnprivilegedEditor, wiltItem: WiltItem) => {
    const updatedWiltItems = createQuote.wiltItems?.map((val) => {
      if (val.id === wiltItem.id) {
        return {
          ...val,
          editing: true,
        };
      }
      return {
        ...val,
        editing: false,
      };
    });
    if (createQuote.wiltItems) {
      const newCreateQuote = { ...createQuote, wiltItems: updatedWiltItems };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  const onDeleteItem = (wiltItem: WiltItem) => {
    if (createQuote.wiltItems) {
      const newCreateQuote = { ...createQuote, wiltItems: createQuote.wiltItems.filter((x) => x.id !== wiltItem.id) };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  const onWiltTitleChange = (event: React.ChangeEvent<HTMLInputElement>, wiltItem: WiltItem) => {
    const updatedWiltItems = createQuote.wiltItems?.map((val) => {
      if (val.id === wiltItem.id) {
        return {
          ...val,
          title: event.target.value,
        };
      }
      return val;
    });
    if (createQuote.wiltItems) {
      const newCreateQuote = { ...createQuote, wiltItems: updatedWiltItems };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  const onWiltBodyChange = (value: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor, wiltItem: WiltItem) => {
    const updatedWiltItems = createQuote.wiltItems?.map((val) => {
      if (val.id === wiltItem.id) {
        return {
          ...val,
          body: value,
        };
      }
      return val;
    });
    if (createQuote.wiltItems) {
      const newCreateQuote = { ...createQuote, wiltItems: updatedWiltItems };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  const onQuillClick = (wiltItem: WiltItem) => {
    // console.log("onQuillClick: ",wiltItem);
    const updatedWiltItems = createQuote.wiltItems?.map((val) => {
      if (val.id === wiltItem.id) {
        return {
          ...val,
          editing: true,
        };
      }
      return {
        ...val,
        editing: false,
      };
    });
    if (createQuote.wiltItems) {
      const newCreateQuote = { ...createQuote, wiltItems: updatedWiltItems };
      dispatch({ type: ActionTypes.SET_CREATE_QUOTE, value: newCreateQuote });
    }
  };

  return (
    <div className={styles.WiltTimeline}>
      <Input className={styles.title} placeholder={createQuote.wiltTitle} size="large" allowClear value={createQuote.wiltTitle} onChange={onTitleChange} />
      <div className={styles.body}>
        <div className={styles.timeline}>
          {
                        createQuote.wiltItems.map((val, index) => (
                          <div
                            key={`timeline-item${index}`}
                            className={styles.wiltItem}
                          >
                            <div>
                              <div className={styles.indexContainer}>
                                <div className={styles.index}>
                                  NO.
                                  {' '}
                                  {index + 1}
                                </div>
                                <Button
                                  className={styles.close}
                                  size="small"
                                  type="primary"
                                  icon={<CloseOutlined type="close" />}
                                  onClick={() => onDeleteItem(val)}
                                />
                              </div>
                              <div className={styles.wiltContainer}>
                                <div className={styles.wiltContent}>
                                  <Input className={styles.wiltTitleInput} placeholder={val.title} size="large" allowClear value={val.title} onChange={(v) => onWiltTitleChange(v, val)} />
                                  <div role="button" tabIndex={0} onClick={() => onQuillClick(val)}>
                                    <ReactQuill
                                      id={`timeline-item${index}`}
                                      theme="bubble"
                                      readOnly={!val.editing}
                                      modules={
                                        {
                                          toolbar: [
                                            ['bold', 'italic', 'underline'],
                                            [{ background: [] }],
                                            ['blockquote'],
                                            [{ list: 'ordered' }, { list: 'bullet' }],
                                          ],
                                        }
                                      }
                                      placeholder="Start typing here..."
                                      value={val.body || undefined}
                                      onChange={(v, de, so, e) => onWiltBodyChange(v, de, so, e, val)}
                                      onFocus={(s, se, e) => onFocus(s, se, e, val)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                    }
        </div>
        <div className={styles.addWiltItem}>
          <Button
            className={styles.addWiltItemButton}
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAddClick}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WiltTimeline;
