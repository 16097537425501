import { Dispatch } from 'redux';
import axios from 'axios';
import {StoryNoteTemplate} from "../models/Template";

export const createNewTemplate = (data: any, callback: (snt: StoryNoteTemplate) => void) => (dispatch: Dispatch, getState: Function) => {
  axios.post('/api/templates', data)
    .then((response) => {
        const tempStoryNoteTemplate = response.data as StoryNoteTemplate;
      callback(tempStoryNoteTemplate);
    }).catch((error) => {
      console.log('error: ', error);
    });
};
