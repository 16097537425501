import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import styles from './DraftScreen.module.scss';
import { StoreState } from '../../reducers/_RootReducers';
import { User } from '../../models/User';
import { Post } from '../../models/Post';
import { ActionTypes } from '../../actions/types';
import WiltFullPost from '../../components/WiltFullPost/WiltFullPost';
import SummaryFullPost from '../../components/SummaryFullPost/SummaryFullPost';
import DraftQuote from './DraftQuote/DraftQuote';

function DraftScreen() {
  const { id, type } = useParams();
  const history = useHistory();
  const currentUser = useSelector<StoreState, User>((state) => state.currentUser);
  const [draft, setDraft] = useState<Post | null>(null);
  const selectedPost = useSelector<StoreState, Post | null>((state) => state.selectedPost);
  const dispatch = useDispatch();

  // const likePost = (post: Post, callBack: Function) => {
  // };
  const likePost = () => {
  };

  // const unlikePost = (post: Post, callBack: Function) => {
  // };
  const unlikePost = () => {
  };

  useEffect(() => {
    if (selectedPost) {
      setDraft(selectedPost);
    } else {
      axios.get(`/api/drafts/${id}`)
        .then((response) => {
          dispatch({ type: ActionTypes.SET_SELECTED_POST, post: response.data });
        }).catch((error) => {
          console.log('PostScreen error: ', error);
        });
    }

    return () => {
      // dispatch({ type: ActionTypes.SET_SELECTED_POST, post: null });
    };
  }, [currentUser]);

  const displayCorrectPost = (type: string | undefined) => {
    switch (type) {
      case '0':
        if (draft) {
          return <DraftQuote post={draft} />;
        }
        break;
      case '1':
        if (draft) {
          return (
            <WiltFullPost
              post={draft}
              likePost={likePost}
              unlikePost={unlikePost}
              reactionIds={{}}
            />
          );
        }
        break;
      case '2':
        if (draft) {
          return (
            <SummaryFullPost
              post={draft}
              likePost={likePost}
              unlikePost={unlikePost}
              reactionIds={{}}
            />
          );
        }
        break;
      default:
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    selectedPost && currentUser
        && (
        <div className={styles.DraftScreen}>
          <div className={styles.header} style={{ maxWidth: type === '0' ? '500px' : '800px' }}>
            <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
              back
            </Button>
            <Button icon={<EditOutlined />} onClick={goBack}>
              edit
            </Button>
          </div>
          {
                displayCorrectPost(type)
            }
        </div>
        )
  );
}

export default DraftScreen;
