import React from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styles from './home-content.module.scss';
import Welcome from './welcome/welcome';
import Timeline from './timeline/timeline';
import StadiumStats from './stadium-stats/stadium-stats';

function HomeContent() {
  return (
    <div className={styles.HomeContent}>
      <div className={styles.container}>
        <Welcome />
        <StadiumStats />
        <Timeline />
      </div>
    </div>
  );
}

export default HomeContent;
