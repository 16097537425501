import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '@brainhubeu/react-carousel/lib/style.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import cookies from 'js-cookie';
import rootReducers from './reducers/_RootReducers';
import * as serviceWorker from './serviceWorker';
import App from './components/App/App';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:5000/';
}

if (cookies.get('goodlores-access')) {
  axios.defaults.headers.common = {
    'goodlores-access': cookies.get('goodlores-access'),
    Authorization: `Bearer ${cookies.get('goodlores-token')}`,
  };
} else {
  axios.defaults.headers.common = { Authorization: `Bearer ${cookies.get('goodlores-token')}` };
}

const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

if (localStorage.getItem('goodlores-theme')) {

    if (localStorage.getItem('goodlores-theme') === "dark") {
        document.documentElement.style.setProperty("--main-background-color", "var(--dark-background-color)");
        document.documentElement.style.setProperty("--main-secondary-background-color", "var(--dark-secondary-background-color)");
        document.documentElement.style.setProperty("--main-border-color", "var(--dark-border-color)");
        document.documentElement.style.setProperty("--main-card-color", "var(--dark-card-color)");
        document.documentElement.style.setProperty("--primary-text-color", "var(--dark-primary-text-color)");
        document.documentElement.style.setProperty("--secondary-text-color", "var(--dark-secondary-text-color)");
        document.documentElement.style.setProperty("--main-skeleton-color", "var(--dark-skeleton-color)");
        document.documentElement.style.setProperty("--main-filter", "var(--dark-filter)");
        document.documentElement.style.setProperty("--main-gradient", "var(--dark-gradient)");
        document.documentElement.style.setProperty("--main-home-divider-color", "var(--dark-home-divider-color)");
    } else {
        document.documentElement.style.setProperty("--main-background-color", "var(--light-background-color)");
        document.documentElement.style.setProperty("--main-secondary-background-color", "var(--light-secondary-background-color)");
        document.documentElement.style.setProperty("--main-border-color", "var(--light-border-color)");
        document.documentElement.style.setProperty("--main-card-color", "var(--light-card-color)");
        document.documentElement.style.setProperty("--primary-text-color", "var(--light-primary-text-color)");
        document.documentElement.style.setProperty("--secondary-text-color", "var(--light-secondary-text-color)");
        document.documentElement.style.setProperty("--main-skeleton-color", "var(--light-skeleton-color)");
        document.documentElement.style.setProperty("--main-filter", "var(--light-filter)");
        document.documentElement.style.setProperty("--main-gradient", "var(--light-gradient)");
        document.documentElement.style.setProperty("--main-home-divider-color", "var(--light-home-divider-color)");
    }

}

const store = createStore(rootReducers, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={localStorage.getItem('goodlores-theme') || "light"} >
            <Suspense fallback={<div>Loading...</div>}>
                <App />
            </Suspense>
        </ThemeSwitcherProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
