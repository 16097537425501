import React, { useState } from 'react';
import { Button, Modal, Progress } from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import { CheckCircleOutlined, CloseCircleOutlined, BorderOutlined } from '@ant-design/icons';
import styles from './QuizModal.module.scss';
import { Post } from '../../../models/Post';
import { Answer } from '../../../models/create-models/Question';
import { ActionTypes } from '../../../actions/types';
import {updateQuiz} from "../../../services-api/quizService";
import {StoreState} from "../../../reducers/_RootReducers";
import {CreateQuiz} from "../../../models/create-models/CreateQuiz";


function QuizModal() {

  const post = useSelector<StoreState, Post | null>(state => state.currentQuiz);
  const quizAnswers = useSelector<StoreState, { [key:string]: string }>(state => state.quizAnswers);
  const isLoading = useSelector<StoreState, boolean>(state => state.isLoading);
  const currentId = useSelector<StoreState, string>(state => state.currentId);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState<Answer | undefined>(undefined);
  const [clickCounter, setClickCounter] = useState(0);
  const totalQuestionsCount = post?.questions.length;
  const dispatch = useDispatch();

  const onSelectClick = (answer: Answer) => {
    setSelectedAnswer(answer);
  };

  const answerExistAndIsCorrect = (answer: Answer) => quizAnswers[`${currentQuestion}`]
        && selectedAnswer
        && post?.questions
          .find((q) => q.id === `${currentQuestion}`)?.correctAnswerId === answer.id;

  const answerExistAndIsIncorrect = (answer: Answer) => {
    if (quizAnswers[`${currentQuestion}`] && selectedAnswer) {
      const dev = post?.questions
        .find((q) => q.id === `${currentQuestion}`);
      return dev?.correctAnswerId !== answer.id && selectedAnswer.id === answer.id;
    }

    return false;
  };

  const answerExistAndIsNotThisOption = (answer: Answer) => {
    if (quizAnswers[`${currentQuestion}`] && selectedAnswer) {
      const dev = post?.questions
        .find((q) => q.id === `${currentQuestion}`);
      if (dev) {
        return dev.correctAnswerId !== answer.id && selectedAnswer.id !== answer.id;
      }
    }
    return false;
  };

  const answerDoesNotExistAndIsSelect = (answer: Answer) => selectedAnswer && selectedAnswer.id === answer.id && !quizAnswers[`${currentQuestion}`];

  const onSubmitAnswer = () => {
    if (clickCounter === 0) {
      const foundAnswer = post?.questions
        .find((q) => q.id === `${currentQuestion}`)?.answers
                .find((a) => a.id === selectedAnswer?.id);
      if (foundAnswer) {

        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
        dispatch(updateQuiz({ post: post?._id, completed: post?.questions.length === currentQuestion, answers: { [`${currentQuestion}`]: foundAnswer.id } }, () => {
          dispatch({ type: ActionTypes.TOGGLE_LOADING, value: false });
          dispatch({ type: ActionTypes.SET_QUIZ_ANSWERS, value: { ...quizAnswers, [`${currentQuestion}`]: foundAnswer.id } });
          setClickCounter(clickCounter + 1);
        }))
      }

    } else if (clickCounter === 1) {
      if ((currentQuestion - 1) === totalQuestionsCount) {
        return;
      }
      setCurrentQuestion(currentQuestion + 1);
      setClickCounter(0);
      setSelectedAnswer(undefined);
    }
  };

  const onCancelQuizModal = () => {
    dispatch({ type: ActionTypes.SET_CURRENT_ID, value: 'xxx' });
    dispatch({ type: ActionTypes.SET_CURRENT_QUIZ, value: null });
  };

  const calculateScore = (post: Post) => {
    let totalPoints = 0;
    post.questions.forEach((question) => {
      if (question.correctAnswerId === quizAnswers[question.id]) {
        totalPoints = totalPoints + 1;
      }
    })
    return totalPoints;
  }

  return (
    <Modal
      visible={post !== null && post?._id === currentId}
      footer={null}
      bodyStyle={{ padding: 0 }}
      centered
      onCancel={onCancelQuizModal}
      closable={false}
      className={styles.QuizModal}
    >
      {
        post
        && (
        <div className={styles.quizContent}>
          <div className={styles.header}>
            <div>
              Question
              {' '}
              {currentQuestion > totalQuestionsCount! ? totalQuestionsCount: currentQuestion}
            </div>
            <div>
              <Progress
                status="active"
                strokeColor={{
                  '0%': '#2ECC71',
                  '100%': '#2ECC71',
                }}
                percent={((currentQuestion / totalQuestionsCount!) * 100)}
                format={(percent) => `${currentQuestion > totalQuestionsCount! ? totalQuestionsCount: currentQuestion}/${totalQuestionsCount}`}
              />
            </div>
          </div>
          {
            (currentQuestion - 1) === totalQuestionsCount ?
                <div className={styles.quizBody}>
                  <div className={styles.congrats}>
                    🎉{' '}congrats{' '}🎉
                  </div>
                  <div className={styles.yourScore}>
                    you scored
                  </div>
                  <div className={styles.scoreContainer}>
                    <span className={styles.score}>{calculateScore(post)}</span>
                    <span className={styles.totalQuestions}>/{totalQuestionsCount}</span>
                  </div>
                </div>
                :
                <div className={styles.quizBody}>
                  <div className={styles.question}>
                    {post.questions.find((q) => q.id === `${currentQuestion}`)?.question}
                  </div>
                  <div className={styles.options}>
                    {
                      post.questions
                          .find((q) => q.id === `${currentQuestion}`)?.answers
                          .map((a, qIndex) => (
                              <div key={`options${qIndex}`}>
                                <Button
                                    type={quizAnswers[`${currentQuestion}`] ? 'primary' : 'default'}
                                    size="large"
                                    icon={answerExistAndIsCorrect(a) ? <CheckCircleOutlined /> : answerExistAndIsIncorrect(a) ? <CloseCircleOutlined /> : <BorderOutlined />}
                                    className={`${styles.option} ${answerDoesNotExistAndIsSelect(a) && styles.selectedOption} ${answerExistAndIsCorrect(a) && styles.correctOption} ${answerExistAndIsIncorrect(a) && styles.incorrectOption}`}
                                    onClick={() => onSelectClick(a)}
                                    disabled={answerExistAndIsNotThisOption(a)}
                                >
                                  {a.text}
                                </Button>
                              </div>
                          ))
                    }
                  </div>
                </div>
          }
          <div className={styles.footer}>
            <Button
              className={styles.nextButton}
              type="link"
              size="large"
            />
            <Button
              className={styles.nextButton}
              type="primary"
              size="large"
              onClick={onSubmitAnswer}
              loading={post?._id === currentId && isLoading}
              disabled={(currentQuestion - 1) === totalQuestionsCount}
            >
              {clickCounter === 0 ? 'Submit' : 'Next'}
            </Button>
          </div>
        </div>
        )
      }
    </Modal>
  );
}

export default QuizModal;
