import React from 'react';
import { Progress } from 'antd';
import styles from './RatingItem.module.scss';
import { convertRatingToColor } from '../../helpers/helpers';
import {LazyLoadImage} from "react-lazy-load-image-component";

interface RatingItemProps {
  item: any;
}

function RatingItem(props: RatingItemProps) {
  const { item } = props;

  return (
    <div className={styles.RatingItem}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <Progress
            className={styles.ratingProgressMobile}
            width={50}
            type="circle"
            strokeColor={{
              '0%': convertRatingToColor(item.rating),
              '100%': convertRatingToColor(item.rating),
            }}
            percent={((item.rating / 10) * 100)}
            format={() => (
              <span>
                <span className={styles.ratingValue}>{item.rating}</span>
                <span className={styles.outOf}>/10</span>
              </span>
            )}
          />
          <Progress
            className={styles.ratingProgressTablet}
            width={60}
            type="circle"
            strokeColor={{
              '0%': convertRatingToColor(item.rating),
              '100%': convertRatingToColor(item.rating),
            }}
            percent={((item.rating / 10) * 100)}
            format={() => (
              <span>
                <span className={styles.ratingValue}>{item.rating}</span>
                <span className={styles.outOf}>/10</span>
              </span>
            )}
          />
        </div>
        <div className={styles.rightContainer}>
            <LazyLoadImage
                effect="blur"
                className={styles.image}
                src={item.medium.imageUrl.replace('http://', 'https://')}
                alt={item.medium.imageUrl.replace('http://', 'https://')}
            />
        </div>
      </div>
    </div>
  );
}

export default RatingItem;
