import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Image } from 'antd';
import styles from './ChatCard.module.scss';
import { Message } from '../../../models/Message';
import { fallBackUrl } from '../../../utils/imageData';

type ChatCardProps = {
  message: Message;
  previousMessage: Message | undefined;
  nextMessage: Message | undefined;
  currentUserId: string;
  otherUserImage?: string | undefined;
  groupChat?: boolean;
  onMoreClick?: () => void;
};

function ChatCard(props: ChatCardProps) {
  const {
    message, currentUserId, previousMessage, nextMessage, groupChat,
  } = props;

  const getMyTextCorrectTextStyle = () => {
    if (!previousMessage || (previousMessage && previousMessage.user._id !== message.user._id)) {
      return styles.textBottom;
    } if (!nextMessage || (nextMessage && nextMessage.user._id !== message.user._id)) {
      return styles.textTop;
    }
    return styles.text;
  };

  const menu = (
    <Menu>
      <Menu.Item disabled>
        Sent:
        {' '}
        {
          new Date(message.createdAt).toLocaleString(undefined, {
            day: 'numeric', weekday: 'long', month: 'short', hour: 'numeric', minute: 'numeric',
          })
        }
      </Menu.Item>
    </Menu>
  );

  const myTextMessage = () => {
    if (message?.file) {
      return (
        <div className={styles.myFileMessageContainer}>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <EllipsisOutlined className={styles.more} />
          </Dropdown>
          <div className={styles.myTextMessage}>
            <div className={getMyTextCorrectTextStyle()}>
              <div>
                <div>
                  <Image
                    className={styles.image}
                    src={message?.file}
                    alt=""
                    fallback={fallBackUrl}
                  />
                </div>
                <div>
                  {
                        message?.message
                  }
                </div>
              </div>
            </div>
            {/* <div className={styles.timeStamp}> */}
            {/*  { */}
            {/*      new Date(message.createdAt).toLocaleString(undefined, { */}
            {/*        day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', */}
            {/*      }) */}
            {/*  } */}
            {/* </div> */}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.myTextMessageContainer}>
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <EllipsisOutlined className={styles.more} />
        </Dropdown>
        <div className={styles.myTextMessage}>
          <div className={getMyTextCorrectTextStyle()}>
            {
                message?.message
            }
          </div>
          {/* <div className={styles.timeStamp}> */}
          {/*  { */}
          {/*      new Date(message.createdAt).toLocaleString(undefined, { */}
          {/*        day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', */}
          {/*      }) */}
          {/*  } */}
          {/* </div> */}
        </div>
      </div>
    );
  };

  const getOtherTextCorrectTextStyle = () => {
    if (!previousMessage || (previousMessage && previousMessage.user._id !== message.user._id)) {
      return styles.textBottom;
    } if (!nextMessage || (nextMessage && nextMessage.user._id !== message.user._id)) {
      return styles.textTop;
    }
    return styles.text;
  };

  const renderGroupChatUserDisplayName = () => {
    if (nextMessage && nextMessage.user._id === message.user._id) {
      return <div />;
    }
    return <div className={styles.displayName}>{message.user.displayName}</div>;
  };

  const otherTextMessage = () => {
    if (message?.file) {
      return (
        <div className={styles.otherFileMessageContainer}>
          <div className={styles.otherTextMessage}>
            {
                groupChat && renderGroupChatUserDisplayName()
            }
            <div className={getOtherTextCorrectTextStyle()}>
              <div>
                <Image
                  className={styles.image}
                  src={message?.file}
                  alt=""
                  fallback={fallBackUrl}
                />
              </div>
              <div>
                {
                  message?.message
                }
              </div>
            </div>
          </div>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
            <EllipsisOutlined className={styles.more} />
          </Dropdown>
        </div>
      );
    }

    return (
      <div className={styles.otherTextMessageContainer}>
        <div className={styles.otherTextMessage}>
          {
              groupChat && renderGroupChatUserDisplayName()
          }
          <div className={getOtherTextCorrectTextStyle()}>
            {
                message?.message
            }
          </div>
        </div>
        <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
          <EllipsisOutlined className={styles.more} />
        </Dropdown>
      </div>
    );
  };

  return (
    <div className={styles.ChatCard}>
      {
        message.user._id === currentUserId
          ? myTextMessage()
          : otherTextMessage()
      }
    </div>
  );
}

export default ChatCard;
