import React from 'react';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './Empty.module.scss';
import search1_blue from '../../assets/images/search1_blue.svg';
import post1_blue from '../../assets/images/post2_blue.svg';
import empty_podcasts from '../../assets/images/empty_podcasts.svg';
import empty_series from '../../assets/images/empty_series.svg';
import empty_movies from '../../assets/images/empty_movies.svg';
import empty_books from '../../assets/images/empty_books.svg';
import empty_users from '../../assets/images/empty_users.svg';
import { ActionTypes } from '../../actions/types';

interface EmptyProps {
  title: string;
  subTitle: string;
  screen?: string;
  icon: string;
  buttonTitle?: string;
  url: string;
  hideButton?: boolean;
}

function Empty(props:EmptyProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { hideButton } = props;

  const renderCorrectImage = (screen?: string) => {
    switch (screen) {
      case 'posts':
        return (
          <img
            className={styles.emptyImage}
            src={post1_blue}
            alt={post1_blue}
          />
        );
      case 'home':
        return (
          <img
            className={styles.emptyImage}
            src={search1_blue}
            alt={search1_blue}
          />
        );
      case 'notification':
        return (
          <img
            className={styles.emptyImage}
            src={search1_blue}
            alt={search1_blue}
          />
        );
      case 'empty_podcasts':
        return (
          <img
            className={styles.emptyImage}
            src={empty_podcasts}
            alt={empty_podcasts}
          />
        );
      case 'empty_movies':
        return (
          <img
            className={styles.emptyImage}
            src={empty_movies}
            alt={empty_movies}
          />
        );
      case 'empty_series':
        return (
          <img
            className={styles.emptyImage}
            src={empty_series}
            alt={empty_series}
          />
        );
      case 'empty_books':
        return (
          <img
            className={styles.emptyImage}
            src={empty_books}
            alt={empty_books}
          />
        );
      case 'empty_users':
        return (
          <img
            className={styles.emptyImage}
            src={empty_users}
            alt={empty_users}
          />
        );
      default:
        return (
          <img
            className={styles.emptyImage}
            src={search1_blue}
            alt={search1_blue}
          />
        );
    }
  };

  const getCorrectImage = (icon: string) => {
    switch (icon) {
      case 'search':
        return <SearchOutlined />;
      case 'plus':
        return <PlusOutlined />;
      default:
        return <div />;
    }
  };

  const onActionClick = () => {
    history.push(props.url);
    dispatch({ type: ActionTypes.SET_MENU_ITEM, location: props.url.replace('/', '') });
    dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: props.url.replace('/', '') });
  };

  return (
    <Result
      className={styles.Empty}
      icon={renderCorrectImage(props.screen)}
      title={props.title}
      subTitle={props.subTitle}
      extra={props.buttonTitle && (!hideButton) && (
      <Button type="primary" onClick={onActionClick}>
        {getCorrectImage(props.icon)}
        {props.buttonTitle}
      </Button>
      )}
    />
  );
}

export default Empty;
